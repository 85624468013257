/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import classnames from 'classnames';

import arrowDown from '../../assets/images/rebranding/triangle-downward.svg';

import styles from './FAQ.css';

const FAQ = ({ questions = [], sources = [] }) => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const selectQuestion = (question, index) => {
    if (index === selectedQuestion) {
      setSelectedQuestion(null);
      return;
    }

    if (question && typeof question.action === 'function') {
      question.action();
    }

    setSelectedQuestion(index);
  };

  return (
    <div className={styles.frequentlyAskedQuestionsContainer}>
      <h5 className={styles.subHeading}>{'Frequently asked questions'}</h5>
      {questions && questions.map((question, index) => (
        <div key={`question-${index}`} className={styles.frequentlyAskedQuestionRow}>
          <button onClick={() => selectQuestion(question, index)} type="button">
            <h6 className={styles.question}>{question.question}</h6>
            <img
              className={classnames(
                styles.arrow,
                { [styles.upArrow]: selectedQuestion === index },
              )}
              src={arrowDown}
              alt="down arrow"
            />
          </button>
          {selectedQuestion === index && question.answers && question.answers.map(
            (answer, answerIndex) => {
              return <p key={`answer-${index}-${answerIndex}`} className={styles.answer}>{answer}</p>;
            },
          )}
        </div>
      ))}
      {sources && sources.length > 0 && (
        <div className={styles.sourcesContainer}>
          <p className={styles.sourceHeading}>{'Sources'}</p>
          {sources.map((source, index) => {
            return (
              <p className={styles.source}>
                <span
                  style={{
                    width: `${9 + (4.5 * (sources.length))}px`,
                    display: 'inline-block',
                  }}
                >
                  {`${'*'.repeat(index + 1)} `}
                </span>
                <a href={source.link}>{`${source.text}`}</a>
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FAQ;
