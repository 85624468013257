import React from 'react';

import NotificationBanner from '../NotificationBanner/NotificationBanner';

const VulnerableCustomerNotification = ({ latestConversationId }) => {
  return (
    <NotificationBanner
      alertCategory={'Red'}
      alertTitle={'Please contact your dedicated Pension Adviser'}
      expandableAlertDescription={'The answers to the questions we have asked you about your mental health and ability to make financial decisions mean that we need to speak to you before we confirm your recommended pension plan. Please contact your Pension Adviser to resolve this.'}
      ctaButtonOptions={{
        size: 'large',
        label: 'Message my Pension Adviser',
        to: latestConversationId ? `/inbox/${latestConversationId}` : 'inbox/new-message',
      }}
    />
  );
};

export default VulnerableCustomerNotification;
