import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { getPensionExpert } from '../../redux/selectors/investmentAdvice';

import ButtonLink from '../ButtonLink/ButtonLink';

import styles from './PensionAdviserWidget.css';

const PensionAdviserWidget = ({ pensionExpert, onClick }) => {
  const adviserImage = get(pensionExpert, 'imageUrl');
  const adviserFirstName = get(pensionExpert, 'firstName', '');
  const adviserLastName = get(pensionExpert, 'lastName', '');

  if (!adviserImage) return null;

  return (
    <div className={styles.pensionAdviserCard}>
      <h2 className={styles.heading}>{'Your dedicated Pension Adviser on hand'}</h2>
      <div className={styles.contentContainer}>
        <div className={styles.adviserProfileContainer}>
          <img className={styles.adviserImage} src={adviserImage} alt="Your Pension Adviser" />
          <h6 className={styles.adviserName}>{`${adviserFirstName} ${adviserLastName}`}</h6>
        </div>
        <p className={styles.adviserMessage}>
          {[
            'If you have any questions, please feel free to ',
            <ButtonLink
              label={'message me'}
              variant="primary"
              to="/inbox/new-message"
              font="inherit"
              onClick={() => {
                if (onClick) {
                  onClick();
                }
              }}
            />,
            '.',
          ]}
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pensionExpert: getPensionExpert(state),
});

export default connect(mapStateToProps)(PensionAdviserWidget);
