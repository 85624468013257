import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import ArrowIcon from '../../assets/images/rebranding/right-arrow-inside-circle.svg';
import styles from './DocumentTable.css';
import { getConversations } from '../../redux/selectors/conversations';
import ButtonLink from '../ButtonLink/ButtonLink';
import arrowDown from '../../assets/images/rebranding/triangle-downward.svg';

class AttachmentsTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onDownload, files, conversations } = this.props;

    const filesByConversation = files.reduce((_filesByConversation, file) => {
      const { originalName } = file;

      const documentId = originalName.split('/')[3];

      const attachmentWithDocumentId = (a) => a.document === documentId;
      const messageWithDocumentId = (m) => (m.attachments || []).find(attachmentWithDocumentId);
      const conversationWithDocumentId = (c) => (c.messages || []).find(messageWithDocumentId);
      const conversation = conversations.find(conversationWithDocumentId);

      if (!conversation) {
        return _filesByConversation;
      }

      const group = _filesByConversation.find(
        (f) => f.conversationId === conversation.conversationId,
      );

      if (group) {
        group.files.push(file);
        return _filesByConversation;
      }

      _filesByConversation.push({
        conversationId: conversation.conversationId,
        subject: conversation.subject,
        createdDate: conversation.createdDate,
        files: [file],
      });
      return _filesByConversation;
    }, []);

    filesByConversation.sort(
      (groupA, groupB) => new Date(groupB.createdDate) - new Date(groupA.createdDate),
    );

    if (!filesByConversation.length) return null;

    return (
      <React.Fragment>
        <h3 className={styles.attachmentsHeading}>{'Attachments'}</h3>
        <p className={styles.attachmentsCopy}>
          {'Below is a list of all documents which have been sent in messages to or from us. You can find all your messages in your '}
          <ButtonLink to="/inbox" label="inbox" font="inherit" underline />
          {'.'}
        </p>
        <div className={styles.attachmentsTable} data-cy="document-table">
          <div
            className={styles.attachmentTableHeading}
          >
            {'Message Subject'}
          </div>
          <div
            className={styles.attachmentTableHeading}
          >
            {'Sent Date'}
          </div>
          <div className={styles.divider} />
          {filesByConversation.map((fileGroup, index) => (
            <AttachmentsTableRow
              onDownload={onDownload}
              isInitiallyExpanded={index === 0}
              fileGroup={fileGroup}
              index={index}
            />
          ))}
          <div className={styles.divider} />
        </div>
      </React.Fragment>

    );
  }
}

const AttachmentsTableRow = ({
  isInitiallyExpanded,
  onDownload,
  fileGroup,
  index,
}) => {
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);
  const isOdd = index % 2 !== 0;
  return (
    <React.Fragment>
      <div
        className={
          classnames(
            styles.messageSubject,
            { [styles.isExpanded]: isExpanded, [styles.isOdd]: isOdd },
          )
        }
        onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
      >
        <ButtonLink
          font="bodyTwo"
          label={fileGroup.subject}
          to={`/inbox/${fileGroup.conversationId}`}
        />

      </div>
      <div
        onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
        className={
          classnames(
            styles.messageDate,
            { [styles.isExpanded]: isExpanded, [styles.isOdd]: isOdd },
          )
        }
      >
        {moment(fileGroup.createdDate).format('DD/MM/yyyy')}
        <img
          alt="Show or hide the list of files for this conversation"
          src={arrowDown}
          className={classnames(styles.expandChevron, { [styles.isExpanded]: isExpanded })}
        />
      </div>
      {isExpanded && (
        <div
          className={
            classnames(
              styles.attachmentsList,
              {
                [styles.isOdd]: isOdd,
              },
            )
          }
        >
          {fileGroup.files.map((f) => (
            <div
              key={f.originalName}
              className={styles.attachmentRow}
              onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
            >
              <img
                className={styles.attachmentDownload}
                src={ArrowIcon}
                alt="download icon"
                onClick={(e) => {
                  onDownload(f.downloadUrl);
                  e.stopPropagation();
                }}
              />
              <div
                className={styles.attachmentName}
                onClick={(e) => {
                  onDownload(f.downloadUrl);
                  e.stopPropagation();
                }}
              >
                {f.name}
              </div>
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

AttachmentsTable.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    updated: PropTypes.string,
    downloadUrl: PropTypes.string,
  })).isRequired,
  onDownload: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  conversations: getConversations(state),
});

export default connect(mapStateToProps)(AttachmentsTable);
