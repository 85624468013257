import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';
import moment from 'moment';
import styles from './AddPensionsPage.css';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import {
  employerDatesStepLandedAction,
  returnLinkClickedAction,
  employerDatesFromChangedAction,
  employerDatesToChangedAction,
  messageMeClickedAction,
  employerDatesSubmitClickedAction,
} from '../../redux/modules/pensions';
import { getDob } from '../../redux/selectors';

const invalidDateCheck = (year, userDob) => {
  return (!(year > (moment(userDob).year() || moment().subtract(100, 'year').year()))
  || (!(year <= new Date().getFullYear()))) || !year;
};

const isOrderIncorrect = (fromDate, toDate, setOrderError) => {
  const endDateBeforeStartDate = (
    fromDate
    && toDate
    && moment(toDate).isBefore(moment(fromDate))
  );

  if (endDateBeforeStartDate) {
    setOrderError('Please enter an employer ‘To’ date that is after the ‘From’ date.');
  }
};

const EmployerDatesStep = ({
  setFormData,
  setStep,
  formData,
  amendMode,
  step,
  totalSteps,
  dispatchEmployerDatesStepLanded,
  isCustomerOngoing,
  dispatchReturnLinkClicked,
  dispatchEmployerDatesFromChanged: _dispatchEmployerDatesFromChanged,
  dispatchEmployerDatesToChanged: _dispatchEmployerDatesToChanged,
  dispatchMessageMeClicked,
  dispatchEmployerDatesSubmitClicked,
  userDob,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchEmployerDatesStepLanded(isCustomerOngoing, formData.transferOption);
  }, []);

  const [
    dispatchEmployerDatesFromChanged,
  ] = useState(() => once(_dispatchEmployerDatesFromChanged));
  const [
    dispatchEmployerDatesToChanged,
  ] = useState(() => once(_dispatchEmployerDatesToChanged));
  const [employmentFromInput, setEmploymentFromInput] = useState(formData.employmentStartDate ? formData.employmentStartDate.slice(0, 4) : '');
  const [employmentToInput, setEmploymentToInput] = useState(formData.employmentEndDate ? formData.employmentEndDate.slice(0, 4) : '');
  const [fromError, setFromError] = useState('');
  const [toError, setToError] = useState('');
  const [orderError, setOrderError] = useState('');

  const errors = [fromError, toError, orderError].filter(Boolean);

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.employerDatesWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
          <ButtonLink
            onClick={(event) => {
              dispatchReturnLinkClicked(isCustomerOngoing, formData.transferOption);
              setStep((_step) => _step - 1);
              event.stopPropagation();
            }}
            label="← Back"
          />
        )}
        <h2 className={styles.employerDatesHeading}>
          {'What were the approximate dates you worked at this company?*'}
        </h2>
        <div
          className={styles.employmentDatesContainer}
        >
          <div
            className={styles.fromContainer}
          >
            <div className={styles.datesLabel}>{'From'}</div>
            <input
              placeholder="YYYY"
              value={employmentFromInput}
              className={styles.estimatedValueInput}
              onChange={(event) => {
                const strippedInput = event.target.value.replace(/\D/g, '');

                if (strippedInput && strippedInput.length > 4) {
                  return;
                }
                setFromError('');
                setOrderError('');

                if (strippedInput) {
                  dispatchEmployerDatesFromChanged(isCustomerOngoing, formData.transferOption);
                  setEmploymentFromInput(strippedInput);

                  if (strippedInput.length === 4) {
                    const isInvalid = invalidDateCheck(strippedInput, userDob);
                    if (isInvalid) {
                      setFromError('Please enter a valid provider/employer ‘From’ date.');
                      return;
                    }
                    isOrderIncorrect(strippedInput, employmentToInput, setOrderError);
                  }
                  return;
                }
                setEmploymentFromInput(strippedInput);
              }}
            />
          </div>
          <div
            className={styles.fromContainer}
          >
            <div className={styles.datesLabel}>{'To'}</div>
            <input
              placeholder="YYYY"
              value={employmentToInput}
              className={styles.estimatedValueInput}
              onChange={(event) => {
                const strippedInput = event.target.value.replace(/\D/g, '');
                if (strippedInput && strippedInput.length > 4) {
                  return;
                }
                setToError('');
                setOrderError('');

                if (strippedInput) {
                  dispatchEmployerDatesToChanged(isCustomerOngoing, formData.transferOption);
                  setEmploymentToInput(strippedInput);

                  if (strippedInput.length === 4) {
                    const isInvalid = invalidDateCheck(strippedInput, userDob);
                    if (isInvalid) {
                      setToError('Please enter a valid provider/employer ‘To’ date.');
                      return;
                    }
                    isOrderIncorrect(employmentFromInput, strippedInput, setOrderError);
                  }

                  return;
                }
                setEmploymentToInput('');
              }}
            />
          </div>
        </div>
        {Boolean(errors.length) && errors.map((m) => (<p className={styles.error} k={m}>{m}</p>))}
        <div
          className={styles.submitContainer}
        >
          <Button
            size="large"
            disabled={
            !employmentToInput
            || !employmentFromInput
            || employmentToInput.length !== 4
            || employmentFromInput.length !== 4
            || errors.length
          }
            label={(amendMode) ? 'Confirm' : 'Submit'}
            onClick={() => {
              dispatchEmployerDatesSubmitClicked(isCustomerOngoing, formData.transferOption);
              setFormData((_formData) => ({
                ..._formData,
                employmentStartDate: moment(employmentFromInput).format(),
                employmentEndDate: moment(employmentToInput).format(),
              }));
              setStep(step + 1);
            }}
          />
        </div>
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'EmployerDatesStep'}
            noExpertContentWrapper={({ children }) => (
              <div
                style={{
                  display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                }}
              >
                {children}
              </div>
            )}
            customMessage={(
              <div
                className={styles.contactUsInnerContent}
              >
                {'We use this to help locate your pension. If you have any questions, please feel free to '}
                <ButtonLink
                  label="message me"
                  variant="primary"
                  to="/inbox/new-message"
                  font="inherit"
                  onClick={() => dispatchMessageMeClicked(
                    isCustomerOngoing,
                    formData.transferOption,
                  )}
                />
                {'.'}
              </div>
                )}
            showButton={false}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
  userDob: getDob(state),
});

const mapDispatchToProps = {
  dispatchEmployerDatesStepLanded: employerDatesStepLandedAction,
  dispatchReturnLinkClicked: returnLinkClickedAction,
  dispatchEmployerDatesFromChanged: employerDatesFromChangedAction,
  dispatchEmployerDatesToChanged: employerDatesToChangedAction,
  dispatchMessageMeClicked: messageMeClickedAction,
  dispatchEmployerDatesSubmitClicked: employerDatesSubmitClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployerDatesStep);
