import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import isNil from 'lodash/isNil';
import addMoneyImg from '../../assets/images/rebranding/add-money.svg';
import styles from './RetirementIncomePage.css';
import Button from '../../components/Button/Button';
import { getVerifiedPersonalContributionsValue } from '../../redux/selectors';
import { currencyString } from '../../util/currency';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import { DisplayError } from './Utils';
import {
  suggestionStepCreateScenarioButtonClickedAction,
  suggestionStepSeeSuggestionButtonClickedAction,
  suggestionStepViewPreviousResultClickedAction,
  suggestionStepPageLanded,
} from '../../redux/modules/projection';

function RetirementIncomeSuggestionStep({
  state,
  errorMessage,
  loading,
  onNextStep,
  onResultsStep,
  hasCompletedFlow,
  currentVerifiedContribution,
  onMonetaryValueChange,
  loadLastProjection,
  dispatchSeeSuggestionButtonClicked,
  dispatchCreateScenarioButtonClicked,
  dispatchViewPreviousResultClicked,
  dispatchSuggestionStepPageLanded,
}) {
  let suggestedAmount = 0;

  const [seeSuggestionClicked, setSeeSuggestionClicked] = useState(false);
  const { scenarios: { monthlyContributions } } = state;

  if (isNil(currentVerifiedContribution) || currentVerifiedContribution < 100) {
    suggestedAmount = 100;
  } else if (currentVerifiedContribution < 150) {
    suggestedAmount = 150;
  } else {
    suggestedAmount = 200;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchSuggestionStepPageLanded();
  }, []);

  useEffect(() => {
    if (monthlyContributions.enabled && seeSuggestionClicked) {
      onResultsStep();
    }
  }, [monthlyContributions, seeSuggestionClicked]);

  useEffect(() => {
    onMonetaryValueChange('monthlyContributions')(suggestedAmount, currencyString(suggestedAmount));
  }, []);

  const handleSeeEffectClick = () => {
    dispatchSeeSuggestionButtonClicked();
    setSeeSuggestionClicked(true);
  };

  return (
    <React.Fragment>
      <div className={styles.suggestionCard}>
        <span className={styles.suggestionCardBadge}>{'What happens if you...'}</span>
        <div className={styles.suggestionCardHeading}>
          <img src={addMoneyImg} alt="Set up a contribution" />
          <h4 className={styles.suggestionCardHeadingText}>{'Set up a contribution'}</h4>
        </div>
        <p className={styles.suggestionCardText}>
          {'Our most typical contribution amount for customers with a similar investor profile and pension balance is'}
          {' '}
          <span className={styles.boldAndGreenText}>
            {'£'}
            {suggestedAmount}
          </span>
          {' '}
          {'a month'}
        </p>
        <Button
          size="large"
          loading={loading}
          label="See the effect"
          onClick={handleSeeEffectClick}
        />
        <DisplayError error={errorMessage} />
      </div>
      <div className={styles.suggestedScenarioButtonWrapper}>
        <div className={styles.createYourOwnScenarioButtonWrapper}>
          <Button
            size="large"
            onClick={() => {
              dispatchCreateScenarioButtonClicked();
              onNextStep();
            }}
            label="Create your own scenario"
          />
        </div>

        {hasCompletedFlow && (
          <ButtonLink
            label="View your previous result"
            underline
            bold
            onClick={() => {
              dispatchViewPreviousResultClicked();
              loadLastProjection();
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}

RetirementIncomeSuggestionStep.propTypes = {};

const mapStateToProps = (state) => ({
  currentVerifiedContribution: getVerifiedPersonalContributionsValue(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSeeSuggestionButtonClicked:
    () => dispatch(suggestionStepSeeSuggestionButtonClickedAction()),
  dispatchCreateScenarioButtonClicked:
    () => dispatch(suggestionStepCreateScenarioButtonClickedAction()),
  dispatchViewPreviousResultClicked:
    () => dispatch(suggestionStepViewPreviousResultClickedAction()),
  dispatchSuggestionStepPageLanded: () => dispatch(suggestionStepPageLanded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RetirementIncomeSuggestionStep);
