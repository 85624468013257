import React from 'react';
import SignaturePad from '../../components/SignaturePad/SignaturePad';

import styles from './PersonalDetails.css';

const AuthoritySignature = (props) => {
  const {
    onChange, signature, signatureTooSmall, dataSentry,
  } = props;

  return (
    <section>
      <br />
      <div className={styles.form}>
        <div className={styles.signaturePadWrapper}>
          <SignaturePad
            largeHeadings
            canvasWidth={515}
            canvasHeight={280}
            onChange={onChange}
            dataSentry={dataSentry}
            signature={signature}
          />
        </div>
        <br />
        {signatureTooSmall && (
          <p className={styles.signatureWarning}>{'The signature you have drawn is too small. Please try again.'}</p>
        )}
      </div>
    </section>
  );
};

export default AuthoritySignature;
