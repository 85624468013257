exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);

// module
exports.push([module.id, ".-ListPensions-heading-2lfup {\n  margin: 0 0 10px;\n}\n", ""]);

// exports
exports.locals = {
	"typography": "\"../../theme/typography.css\"",
	"h4": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h4"] + "",
	"heading": "-ListPensions-heading-2lfup " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h4"] + ""
};