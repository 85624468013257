exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);

// module
exports.push([module.id, ".-WhatMakesUsDifferent-compareSubheading-3bMpt {\n}\n.-WhatMakesUsDifferent-msmHeading-3q45P {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + ";\n  margin: 0;\n}\n.-WhatMakesUsDifferent-uspGrid-3Q-lU {\n  margin: 0;\n}\n.-WhatMakesUsDifferent-msmUspGrid-F2P43 {\n  margin-top: 15px;\n}\n.-WhatMakesUsDifferent-uspRow-2dsYY {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin-bottom: 15px;\n}\n.-WhatMakesUsDifferent-uspText-2CP4i {\n  line-height: 22px;\n  margin-left: 15px;\n  padding-right: 15px;\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n  .-WhatMakesUsDifferent-uspGrid-3Q-lU {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n  }\n\n  .-WhatMakesUsDifferent-msmUspGrid-F2P43 {\n    display: block;\n  }\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["typographyDesktop"] + ") {\n  .-WhatMakesUsDifferent-msmUspGrid-F2P43 {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n  }\n\n  .-WhatMakesUsDifferent-uspText-2CP4i {\n    line-height: 24px;\n  }\n}", ""]);

// exports
exports.locals = {
	"colors": "\"../../theme/colors.css\"",
	"textTitleRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + "",
	"typography": "\"../../theme/typography.css\"",
	"h2": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h2"] + "",
	"h3": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h3"] + "",
	"bodyOne": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"dimensions": "\"../../theme/dimensions.css\"",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"typographyDesktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["typographyDesktop"] + "",
	"compareSubheading": "-WhatMakesUsDifferent-compareSubheading-3bMpt " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h3"] + "",
	"msmHeading": "-WhatMakesUsDifferent-msmHeading-3q45P " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h2"] + "",
	"uspGrid": "-WhatMakesUsDifferent-uspGrid-3Q-lU",
	"msmUspGrid": "-WhatMakesUsDifferent-msmUspGrid-F2P43",
	"uspRow": "-WhatMakesUsDifferent-uspRow-2dsYY",
	"uspText": "-WhatMakesUsDifferent-uspText-2CP4i " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + ""
};