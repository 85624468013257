import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import debounce from 'lodash/debounce';
import once from 'lodash/once';
import get from 'lodash/get';
import { withFirestore } from 'react-redux-firebase';
import classnames from 'classnames';
import sentryException from '../../util/sentryException';

import BasePage from '../../components/BasePage/BasePage';
import CheckBoxGreen from '../../components/CheckBoxGreen/CheckBoxGreen';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import { PENSION_TRANSFER_KEY_FILES } from '../../util/constants';
import styles from './AuthoriseChangePage.css';
import captureScreen from '../../util/captureScreenshot';
import captureHtml from '../../util/captureHtml';
import OngoingRecommendationCard from '../../components/PensionPlanCard/OngoingRecommendationCard';
import {
  acceptOngoingRecommendationAction,
  acceptOngoingSubmitClickedAction,
  acceptOngoingSubmitRejectedAction,
  suitabilityReportClickedAction,
  keyFeaturesClickedAction,
  illustrationClickedAction,
  authoriseImplementPlanSelectedAction,
  authoriseImplementPlanUnselectedAction,
  termsOfBusinessClickedAction,
  authoriseTransferBalanceUnselectedAction,
  authoriseTransferBalanceSelectedAction,
  authorisePageVisitedAction,
} from '../../redux/modules/ongoingAdvice';
import {
  getReadyToAcceptRecommendation,
  getReadyToAcceptSuitabilityReport,
} from '../../redux/selectors/ongoingService';

import Tick from '../../assets/images/icons/smallGreenTick.svg';
import RecommendationDocuments from '../../assets/images/recommendation-pdf.png';

import esgIcon from '../../assets/images/icons/esg-reason.svg';
import riskLevelIcon from '../../assets/images/rebranding/multicolour-circle-tick.svg';
import totalAnnualCostIcon from '../../assets/images/rebranding/three-bar-graph.svg';
import WithOngoingSuitabilityLetter from '../../components/WithDocumentLink/WithOngoingSuitabilityLetter';

const AuthoriseChangePage = ({
  onSubmit,
  pensionPlanRecommendation,
  dispatchAcceptOngoingRecommendation,
  dispatchAcceptOngoingSubmitClicked,
  dispatchAcceptOngoingSubmitRejected,
  dispatchSuitablityReportClicked,
  dispatchKeyFeaturesClicked,
  dispatchIllustrationClicked,
  dispatchAuthoriseImplementPlanUnselected,
  dispatchAuthoriseImplementPlanSelected,
  dispatchTermsOfBusinessClicked,
  dispatchAuthoriseTransferBalanceUnselected,
  dispatchAuthoriseTransferBalanceSelected,
  dispatchAuthorisePageVisitedAction,
  downloadUrl,
}) => {
  const [authoriseImplementPensionPlan, setAuthoriseImplementPensionPlan] = useState(false);
  const [authoriseTransferBalanceOfPension, setAuthoriseTransferBalanceOfPension] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const _dispatchSuitablityReportClicked = once(dispatchSuitablityReportClicked);
  const _dispatchKeyFeaturesClicked = once(dispatchKeyFeaturesClicked);
  const _dispatchAuthoriseImplementPlanUnselected = once(dispatchAuthoriseImplementPlanUnselected);
  const _dispatchAuthoriseImplementPlanSelected = once(dispatchAuthoriseImplementPlanSelected);
  const _dispatchIllustrationClicked = once(dispatchIllustrationClicked);
  const _dispatchTermsOfBusinessClicked = once(dispatchTermsOfBusinessClicked);
  const _dispatchAuthoriseTransferBalanceUnselected = once(
    dispatchAuthoriseTransferBalanceUnselected,
  );
  const _dispatchAuthoriseTransferBalanceSelected = once(dispatchAuthoriseTransferBalanceSelected);
  const _dispatchAuthorisePageVisitedAction = once(dispatchAuthorisePageVisitedAction);

  useEffect(() => {
    _dispatchAuthorisePageVisitedAction();
  }, []);

  const isProviderSwitch = pensionPlanRecommendation.outcome === 'provider-switch';

  const handleSubmit = debounce(async () => {
    dispatchAcceptOngoingSubmitClicked();
    setSubmitError(null);
    try {
      let screenshot;
      let pageHtml;
      try {
        screenshot = await captureScreen();
        pageHtml = captureHtml();
      } catch (e) {
        sentryException(e, {
          section: 'authority-screenshot',
        });
        throw e;
      }
      setSubmitInProgress(true);
      const request = {
        authoriseImplementPensionPlan,
        ...(isProviderSwitch && { authoriseTransferBalanceOfPension }),
        screenshot,
        pageHtml,
        pensionPlanRecommendation,
      };
      await dispatchAcceptOngoingRecommendation(request);
      onSubmit();
    } catch (e) {
      dispatchAcceptOngoingSubmitRejected();
      setSubmitInProgress(false);
      setSubmitError(true);
    }
  }, 3000, { leading: true, trailing: false });

  const authoriseImplementPensionPlanCheckbox = (
    <CheckBoxGreen
      name="authoriseImplementPensionPlan"
      disabled={!downloadUrl}
      label={(
        <span>
          {'I authorise Profile Pensions to implement my new Pension Plan. I have read and understand the '}
          <a
            href={downloadUrl}
            onClick={_dispatchSuitablityReportClicked}
            download
            rel="noopener noreferrer"
            target="_blank"
          >
            {'Recommendation'}
          </a>
          {', '}
          <a
            href={PENSION_TRANSFER_KEY_FILES.keyFeatures}
            onClick={_dispatchKeyFeaturesClicked}
            download
            rel="noopener noreferrer"
            target="_blank"
          >
            {'Key Features'}
          </a>
          {' and '}
          <a
            href={PENSION_TRANSFER_KEY_FILES.illustrationNoArrangementFee}
            onClick={_dispatchIllustrationClicked}
            download
            rel="noopener noreferrer"
            target="_blank"
          >
            {'Illustration'}
          </a>
          {'.'}
        </span>
                )}
      value={authoriseImplementPensionPlan}
      defaultValue={false}
      onChange={() => {
        if (authoriseImplementPensionPlan) {
          setAuthoriseImplementPensionPlan(false);
          _dispatchAuthoriseImplementPlanUnselected();
        } else {
          setAuthoriseImplementPensionPlan(true);
          _dispatchAuthoriseImplementPlanSelected();
        }
      }}
    />
  );

  const portfolioAndFundSwitchOnly = (
    <CheckBoxGreen
      name="portfolioAndFuncSwitchOnly"
      disabled={!downloadUrl}
      label={(
        <span>
          {'I authorise Profile Pensions to implement my new Pension Plan. I have read and understand the '}
          <a
            href={downloadUrl}
            onClick={_dispatchSuitablityReportClicked}
            download
            rel="noopener noreferrer"
            target="_blank"
          >
            {'Recommendation'}
          </a>
          {' and I have received, understood and agree to the '}
          <a
            href={PENSION_TRANSFER_KEY_FILES.termsOfBusiness}
            onClick={_dispatchTermsOfBusinessClicked}
            download
            rel="noopener noreferrer"
            target="_blank"
          >
            {'Terms of Business'}
          </a>
          {'.'}
        </span>
                )}
      value={authoriseImplementPensionPlan}
      defaultValue={false}
      onChange={() => {
        if (authoriseImplementPensionPlan) {
          setAuthoriseImplementPensionPlan(false);
          _dispatchAuthoriseImplementPlanUnselected();
        } else {
          setAuthoriseImplementPensionPlan(true);
          _dispatchAuthoriseImplementPlanSelected();
        }
      }}
    />
  );

  const authoriseTransferBalanceOfPensionCheckbox = (
    <CheckBoxGreen
      name="authoriseTransferBalanceOfPension"
      label={(
        <span>
          {'I authorise Profile Pensions to transfer the balance of my currrent pension to my new Profile Pensions Pension Plan. I have received, understood and agree to the '}
          <a
            href={PENSION_TRANSFER_KEY_FILES.termsOfBusiness}
            onClick={_dispatchTermsOfBusinessClicked}
            download
            rel="noopener noreferrer"
            target="_blank"
          >
            {'Terms of Business'}
          </a>
          {'.'}
        </span>
      )}
      value={authoriseTransferBalanceOfPension}
      defaultValue={false}
      onChange={() => {
        if (authoriseTransferBalanceOfPension) {
          setAuthoriseTransferBalanceOfPension(false);
          _dispatchAuthoriseTransferBalanceUnselected();
        } else {
          setAuthoriseTransferBalanceOfPension(true);
          _dispatchAuthoriseTransferBalanceSelected();
        }
      }}
    />
  );

  const annualCharge = get(pensionPlanRecommendation, 'fees.totalNewFeesPercent');
  const reasonsForRecommendation = get(pensionPlanRecommendation, 'reasonsForRecommendation', {});

  const esgReason = get(reasonsForRecommendation, 'environmentalSocialGovernance');
  const riskLevelReason = get(reasonsForRecommendation, 'riskLevel');
  const totalAnnualCostReason = get(reasonsForRecommendation, 'totalAnnualCost');

  return (
    <BasePage
      messageWidget
    >
      <div>
        <ButtonLink label="← Back" to="/" />
      </div>
      <h2 className={styles.pageHeading}>{'We recommend updating your pension plan. Please read the following and authorise the changes.'}</h2>
      <OngoingRecommendationCard />
      <div className={classnames(styles.container, styles.keyFeatures)}>
        <h3 className={styles.keyFeaturesHeading}>{'Key features'}</h3>
        <div className={styles.keyFeaturesRow}>
          <img src={Tick} alt="Green tick" />
          <p className="body-one">{'Personalised plan updated regularly'}</p>
        </div>
        <div className={styles.keyFeaturesRow}>
          <img src={Tick} alt="Green tick" />
          <p className="body-one">{'Dedicated Pension Adviser'}</p>
        </div>
        <div className={styles.keyFeaturesRow}>
          <img src={Tick} alt="Green tick" />
          <p className="body-one">{'Fund managers from the whole market'}</p>
        </div>
        <div className={styles.keyFeaturesRow}>
          <img src={Tick} alt="Green tick" />
          <p className="body-one">{`Total annual fees of only ${annualCharge}%`}</p>
        </div>
      </div>
      <div className={classnames(styles.container, styles.reasonsForRecommendation)}>
        <h3 className={styles.keyFeaturesHeading}>{'Why we’re recommending these changes for you'}</h3>
        {totalAnnualCostReason && (
          <div className={styles.reasonsForRecommendationRow}>
            <img src={totalAnnualCostIcon} alt="icon" />
            <p className="body-one">{totalAnnualCostReason}</p>
          </div>
        )}
        {esgReason && (
          <div className={styles.reasonsForRecommendationRow}>
            <img src={esgIcon} alt="icon" />
            <p className="body-one">{esgReason}</p>
          </div>
        )}
        {riskLevelReason && (
          <div className={styles.reasonsForRecommendationRow}>
            <img src={riskLevelIcon} alt="icon" />
            <p className="body-one">{riskLevelReason}</p>
          </div>
        )}
        <div className={styles.recommendationPdfDownload}>
          <img src={RecommendationDocuments} alt="pdf" />
          <a className={styles.recommendationPdfDownloadLink} href={downloadUrl} target="_blank" rel="noreferrer">
            {'Download the full details of the recommendation as a PDF →'}
          </a>
        </div>
      </div>
      <div className={classnames(styles.container, styles.confirmationBoxes)}>
        {isProviderSwitch ? [
          authoriseImplementPensionPlanCheckbox,
          <div className={styles.confirmationBoxesDivider} />,
          authoriseTransferBalanceOfPensionCheckbox,
        ] : portfolioAndFundSwitchOnly}
      </div>
      <Button
        size="large"
        disabled={
          !authoriseImplementPensionPlan
          || (isProviderSwitch && !authoriseTransferBalanceOfPension)
          || !downloadUrl
        }
        onClick={handleSubmit}
        label="Submit"
        loading={submitInProgress}
      />
      {submitError && <p className={styles.error}>{'Something went wrong - please try again'}</p>}
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  pensionPlanRecommendation: getReadyToAcceptRecommendation(state),
  reportReference: getReadyToAcceptSuitabilityReport(state),
});

const mapDispatchToProps = {
  dispatchAcceptOngoingRecommendation: acceptOngoingRecommendationAction,
  dispatchAcceptOngoingSubmitClicked: acceptOngoingSubmitClickedAction,
  dispatchAcceptOngoingSubmitRejected: acceptOngoingSubmitRejectedAction,
  dispatchSuitablityReportClicked: suitabilityReportClickedAction,
  dispatchKeyFeaturesClicked: keyFeaturesClickedAction,
  dispatchIllustrationClicked: illustrationClickedAction,
  dispatchAuthoriseImplementPlanSelected: authoriseImplementPlanSelectedAction,
  dispatchAuthoriseImplementPlanUnselected: authoriseImplementPlanUnselectedAction,
  dispatchTermsOfBusinessClicked: termsOfBusinessClickedAction,
  dispatchAuthoriseTransferBalanceUnselected: authoriseTransferBalanceUnselectedAction,
  dispatchAuthoriseTransferBalanceSelected: authoriseTransferBalanceSelectedAction,
  dispatchAuthorisePageVisitedAction: authorisePageVisitedAction,
};

const AuthoriseChangePageWithLetter = (props) => (
  <WithOngoingSuitabilityLetter>
    {
      (downloadUrl) => (<AuthoriseChangePage {...{ ...props, downloadUrl }} />)
    }
  </WithOngoingSuitabilityLetter>
);

export default compose(
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps),
)(AuthoriseChangePageWithLetter);
