import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Loader from '../Loader/Loader';
import WarningCircle from '../../assets/images/icons/Icon_Warning_Circle.svg';
import CloseCircle from '../../assets/images/rebranding/cross-icon-in-circle.svg';
import {
  ATTACHMENT_ACCEPTED,
  ATTACHMENT_ATTACHED,
  ATTACHMENT_REJECTED,
} from '../../util/constants';

import styles from './AttachmentFile.css';

const StatusIcon = ({ type }) => {
  const icons = {
    [ATTACHMENT_ACCEPTED]: CloseCircle,
    [ATTACHMENT_REJECTED]: WarningCircle,
  };

  return (<img src={icons[type]} alt="status" />);
};

const AttachmentFileShell = ({
  isLoading,
  status,
  name,
  errorMessage,
  handleRemoveFile,
  downloadUrl,
}) => {
  const fileExtension = (name || '') && name.split('.').pop().toUpperCase();
  const downloadLinkRef = useRef(null);

  const onClick = () => {
    if (downloadUrl) {
      downloadLinkRef.current.click();
    }
  };

  return (
    <div className={styles.attachmentFileContainer}>
      <div
        className={classnames(styles.attachmentFileWrapper, {
          [styles.attachmentFileWrapperLoading]: isLoading,
          [styles.attachmentFileDownloadable]: downloadUrl,
        })}
        role="button"
        onClick={onClick}
        onKeyUp={() => {}}
        tabIndex="0"
      >
        <span className={styles.attachmentFileName}>
          {status === ATTACHMENT_REJECTED && (
            <StatusIcon type={ATTACHMENT_REJECTED} />
          )}
          {name}
        </span>
        <span className={styles.attachmentFileType}>
          {fileExtension}
        </span>
        {downloadUrl && (
        <span className={styles.attachmentDownload}>
          <a
            href={downloadUrl}
            download
            target="_blank"
            rel="noopener noreferrer"
            ref={downloadLinkRef}
          >
            {'Download'}
          </a>
        </span>
        )}
        {errorMessage && (
        <span className={styles.attachmentFileErrorMessage}>{errorMessage}</span>
        )}
        {!isLoading && status !== ATTACHMENT_ATTACHED && (
        <span
          className={styles.attachmentStatusIcon}
          onClick={handleRemoveFile}
          onKeyUp={() => {}}
          role="button"
          tabIndex="0"
        >
          <StatusIcon type={ATTACHMENT_ACCEPTED} />
        </span>
        )}
      </div>
      {isLoading && (
        <span className={styles.attachmentStatusLoading}>
          <Loader />
        </span>
      )}
    </div>
  );
};

AttachmentFileShell.propTypes = {
  isLoading: PropTypes.bool,
  status: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  handleRemoveFile: PropTypes.func,
  downloadUrl: PropTypes.string,
};

AttachmentFileShell.defaultProps = {
  isLoading: false,
  errorMessage: null,
  handleRemoveFile: () => {},
  downloadUrl: null,
};

export default AttachmentFileShell;
