import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import 'better-dom/dist/better-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { RewriteFrames } from '@sentry/integrations';

import 'better-dateinput-polyfill/dist/better-dateinput-polyfill';
// Global css rules
import './assets/global.css';
// Favicon
import './assets/images/icons/favicon-32x32.png';
import './assets/images/header-shapes.svg';
import './assets/fonts/Lato-Black.ttf';
import './assets/fonts/Lato-BlackItalic.ttf';
import './assets/fonts/Lato-Bold.ttf';
import './assets/fonts/Lato-BoldItalic.ttf';
import './assets/fonts/Lato-Italic.ttf';
import './assets/fonts/Lato-Light.ttf';
import './assets/fonts/Lato-LightItalic.ttf';
import './assets/fonts/Lato-Regular.ttf';
import './assets/fonts/Lato-Thin.ttf';
import './assets/fonts/Lato-ThinItalic.ttf';
import './assets/fonts/Poppins-Black.ttf';
import './assets/fonts/Poppins-BlackItalic.ttf';
import './assets/fonts/Poppins-Bold.ttf';
import './assets/fonts/Poppins-BoldItalic.ttf';
import './assets/fonts/Poppins-ExtraBold.ttf';
import './assets/fonts/Poppins-ExtraBoldItalic.ttf';
import './assets/fonts/Poppins-ExtraLight.ttf';
import './assets/fonts/Poppins-ExtraLightItalic.ttf';
import './assets/fonts/Poppins-Italic.ttf';
import './assets/fonts/Poppins-Light.ttf';
import './assets/fonts/Poppins-LightItalic.ttf';
import './assets/fonts/Poppins-Medium.ttf';
import './assets/fonts/Poppins-MediumItalic.ttf';
import './assets/fonts/Poppins-Regular.ttf';
import './assets/fonts/Poppins-SemiBold.ttf';
import './assets/fonts/Poppins-SemiBoldItalic.ttf';
import './assets/fonts/Poppins-Thin.ttf';
import './assets/fonts/Poppins-ThinItalic.ttf';

import store from './redux/store';
import App from './components/App/App';

try {
  Sentry.init({
    dsn: 'https://eed76180778c4973a88f759bc7a4d363@o452050.ingest.sentry.io/5438916',
    environment: `${process.env.DEPLOYED_ENVIRONMENT}`,
    beforeSend: (event) => {
      const { DEPLOYED_ENVIRONMENT } = process.env;
      /* eslint consistent-return: 0 */
      if (DEPLOYED_ENVIRONMENT === 'staging' || DEPLOYED_ENVIRONMENT === 'production' || DEPLOYED_ENVIRONMENT === 'develop') {
        return event;
      }
    },
    /* eslint no-undef: 0 */
    release: `${process.env.DEPLOYED_ENVIRONMENT}-${__RELEASE_VERSION__}`,
    integrations: [
      new Integrations.BrowserTracing(),
      new RewriteFrames(),
    ],
    tracesSampleRate: 1.0,
  });
} catch (e) {
  console.error('Sentry setup error');
}

const app = document.getElementById('app');

ReactDom.render((
  <Provider store={store}>
    <App />
  </Provider>
), app);
