exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/typography.css"), undefined);
exports.i(require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-AddPensionsOrContributionOption-selectOptionSubheading-3Ds0J {\n  margin: 0 0 20px 0;\n}\n.-AddPensionsOrContributionOption-msmSelectOptionSubheading-FmLoW {\n  color: " + require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/colors.css").locals["textTitleRegular"] + ";\n  margin: 0 0 20px 0;\n}\n.-AddPensionsOrContributionOption-actionsContainer-1cdMl {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n.-AddPensionsOrContributionOption-actionsContainer-1cdMl > * {\n  width: 47%;\n}\n.-AddPensionsOrContributionOption-actionsContainer-1cdMl.-AddPensionsOrContributionOption-singleColumn-BjiJm > * {\n  width: auto;\n  width: initial;\n  margin-bottom: 20px;\n}\n.-AddPensionsOrContributionOption-actionsContainer-1cdMl.-AddPensionsOrContributionOption-singleColumn-BjiJm {\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n}", ""]);

// exports
exports.locals = {
	"typography": "\"../../../theme/typography.css\"",
	"h2": "" + require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/typography.css").locals["h2"] + "",
	"h3": "" + require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/typography.css").locals["h3"] + "",
	"colours": "'../../../theme/colors.css'",
	"textTitleRegular": "" + require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/colors.css").locals["textTitleRegular"] + "",
	"selectOptionSubheading": "-AddPensionsOrContributionOption-selectOptionSubheading-3Ds0J " + require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/typography.css").locals["h3"] + "",
	"msmSelectOptionSubheading": "-AddPensionsOrContributionOption-msmSelectOptionSubheading-FmLoW " + require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/typography.css").locals["h2"] + "",
	"actionsContainer": "-AddPensionsOrContributionOption-actionsContainer-1cdMl",
	"singleColumn": "-AddPensionsOrContributionOption-singleColumn-BjiJm"
};