/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import get from 'lodash/get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

import OngoingBalance from '../OngoingBalance';
import CurrentPlanCard from '../../../components/PensionPlanCard/CurrentPlanCard';
import ContactUs from './ContactUs';
import AddAndListPensions from './AddAndListPensions';
import WithdrawalRow from '../../../components/DashboardRow/WithdrawalRow';
import MentionMeTile from '../../../components/MentionMeTile/MentionMeTile';

import {
  getUser,
  getFirstName,
  getEmail,
  getAuthoritativeRetirementPlan,
} from '../../../redux/selectors';

import {
  getIsCustomerOngoing,
  getIsCustomerOngoingMultipleRps,
  getIsCustomerOptoutFullEncashment,
} from '../../../redux/selectors/experience';

import { getPensions } from '../../../redux/selectors/pensions';
import { getIsAddPensionsAllowed, getIsWithdrawalAllowed } from '../../../redux/selectors/journeys';
import { getPensionExpert, getForceMentionMeModal } from '../../../redux/selectors/investmentAdvice';
import { unsetForceMentionMeModalAction } from '../../../redux/modules/investmentAdvice';

import { MENTION_ME_LINK_CLICKED } from '../../../redux/modules/dashboard';

import styles from '../DashboardPage.css';
import { SHOW_MENTION_ME } from '../../../util/constants';

const OptOutCustomer = (props) => {
  const {
    user,
    authoritativeRp,
    pensionExpert,
    firstName,
    email,
    pensions = [],
    isCustomerOngoingMultipleRps,
    isAddPensionsAllowed,
    isCustomerOngoing,
    isCustomerOptoutFullEncashment,
    isWithdrawalAllowed,
    forceMentionMeModal,
    dispatchUnsetForceMentionMeModal,
    mentionMeLinkClicked,
  } = props;

  const hasPensions = Boolean(pensions.length);
  const rpProvider = get(authoritativeRp, 'provider');

  return (
    <React.Fragment>
      <div className={styles.dashboardContentContainer}>
        <OngoingBalance
          user={user}
          rp={authoritativeRp}
        />
        <CurrentPlanCard />
        <AddAndListPensions
          isCustomerOngoingMultipleRps={isCustomerOngoingMultipleRps}
          isAddPensionsAllowed={isAddPensionsAllowed}
          isCustomerOngoing={isCustomerOngoing}
          isCustomerOptoutFullEncashment={isCustomerOptoutFullEncashment}
          hasPensions={hasPensions}
        />
        {isWithdrawalAllowed && (
        <div className={styles.withdrawalsRowContainer}>
          <WithdrawalRow rpProvider={rpProvider} />
        </div>
        )}
      </div>
      <div className={styles.contactUsContainer}>
        <ContactUs
          pensionExpert={pensionExpert}
          firstName={firstName}
          email={email}
        />
      </div>
      {SHOW_MENTION_ME && (
        <div className={styles.mentionMeContainer}>
          <MentionMeTile />
          <div id="mmWrapper" onClick={forceMentionMeModal ? dispatchUnsetForceMentionMeModal : mentionMeLinkClicked} />
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  pensionExpert: getPensionExpert(state),
  firstName: getFirstName(state),
  email: getEmail(state),
  authoritativeRp: getAuthoritativeRetirementPlan(state),
  pensions: getPensions(state),
  isCustomerOngoingMultipleRps: getIsCustomerOngoingMultipleRps(state),
  isAddPensionsAllowed: getIsAddPensionsAllowed(state),
  isCustomerOngoing: getIsCustomerOngoing(state),
  isCustomerOptoutFullEncashment: getIsCustomerOptoutFullEncashment(state),
  isWithdrawalAllowed: getIsWithdrawalAllowed(state),
  forceMentionMeModal: getForceMentionMeModal(state),
});

const mapDispatchToProps = {
  dispatchUnsetForceMentionMeModal: unsetForceMentionMeModalAction,
  mentionMeLinkClicked: () => ({ type: MENTION_ME_LINK_CLICKED }),
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect(),
)(withRouter(OptOutCustomer));
