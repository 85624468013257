import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './AddPensionsPage.css';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import {
  cannotTransferPensionLandedAction,
  returnLinkClickedAction,
} from '../../redux/modules/pensions';
import { messageMeClickedAction } from '../../redux/modules/conversations';

const CannotTransferPension = ({
  setStep,
  dispatchCannotTransferPensionLanded,
  isCustomerOngoing,
  setJourney,
  journey,
  formData,
  resetFormData,
  dispatchMessageMeClicked,
  dispatchReturnLinkClicked,
  resetInitialFlow,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchCannotTransferPensionLanded(isCustomerOngoing, formData.transferOption);
  }, []);

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.currentlyContributingWrapper}>
        <ButtonLink
          label="← Back"
          onClick={(event) => {
            dispatchReturnLinkClicked(isCustomerOngoing, formData.transferOption);
            resetInitialFlow();
            event.stopPropagation();
          }}
        />
        <div className={styles.stepBody}>
          {'We can’t transfer pensions that employers are currently contributing into but you can come back and add this pension if you move job in the future.'}
        </div>
        <div
          className={styles.submitContainer}
        >
          <Button
            size="large"
            disabled={false}
            label={'Add another pension'}
            onClick={() => {
              resetFormData();
              setJourney(journey);
              setStep(0);
            }}
          />
        </div>
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'PensionProviderStep'}
            noExpertContentWrapper={({ children }) => (
              <div
                style={{
                  display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                }}
              >
                {children}
              </div>
            )}
            showButton={false}
            customMessage={(
              <div
                className={styles.contactUsInnerContent}
              >
                {'If you have any questions, please feel free to '}
                <ButtonLink
                  label="message me"
                  variant="primary"
                  to="/inbox/new-message"
                  font="inherit"
                  onClick={() => dispatchMessageMeClicked(
                    isCustomerOngoing,
                    formData.transferOption,
                  )}
                />
                {'.'}
              </div>
              )}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  dispatchCannotTransferPensionLanded: cannotTransferPensionLandedAction,
  dispatchReturnLinkClicked: returnLinkClickedAction,
  dispatchMessageMeClicked: messageMeClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CannotTransferPension);
