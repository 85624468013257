exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/grid.css"), undefined);

// module
exports.push([module.id, ".-Footer-footer-2dyxG {\n  background-color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["paleSlate"] + ";\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textParagraphRegular"] + ";\n  padding: 50px 10% 0;\n}\n@supports not (-ms-high-contrast: active) {\n  .-Footer-footer-2dyxG {\n    padding: 0;\n  }\n}\n@supports not(display: grid) {\n  .-Footer-footer-2dyxG {\n    padding: 50px 10% 0;\n  }\n}\n.-Footer-footer-2dyxG a {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["accentBaseSecondaryRegular"] + ";\n}\n.-Footer-container-3dfAC {\n}\n", ""]);

// exports
exports.locals = {
	"colors": "\"../../theme/colors.css\"",
	"white": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["white"] + "",
	"textParagraphRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textParagraphRegular"] + "",
	"accentBaseSecondaryRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["accentBaseSecondaryRegular"] + "",
	"paleSlate": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["paleSlate"] + "",
	"footer": "-Footer-footer-2dyxG",
	"container": "-Footer-container-3dfAC " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/grid.css").locals["gridLayout"] + ""
};