function formatAccountDetailsEvent(instructionType, isCustomerOngoing, eventKey) {
  if (instructionType === 'contribution' && isCustomerOngoing) return `pp/${instructionType}/ONGOING_${eventKey}`;
  return `pp/${instructionType}/${eventKey}`;
}

export const accountDetailsVisitedAction = (instructionType, isCustomerOngoing) => (
  { type: formatAccountDetailsEvent(instructionType, isCustomerOngoing, 'ACCOUNT_DETAILS_VISITED') }
);
export const accountHolderNameChangedAction = (instructionType, isCustomerOngoing) => (
  { type: formatAccountDetailsEvent(instructionType, isCustomerOngoing, 'ACCOUNT_DETAILS_ACCOUNT_HOLDER_NAME_CHANGED') }
);
export const sortCodeChangedAction = (instructionType, isCustomerOngoing) => (
  { type: formatAccountDetailsEvent(instructionType, isCustomerOngoing, 'ACCOUNT_DETAILS_SORT_CODE_CHANGED') }
);
export const accountNumberChangedAction = (instructionType, isCustomerOngoing) => (
  { type: formatAccountDetailsEvent(instructionType, isCustomerOngoing, 'ACCOUNT_DETAILS_ACCOUNT_NUMBER_CHANGED') }
);
export const bankNameChangedAction = (instructionType, isCustomerOngoing) => (
  { type: formatAccountDetailsEvent(instructionType, isCustomerOngoing, 'ACCOUNT_DETAILS_BANK_NAME_CHANGED') }
);
export const rollNumberChangedAction = (instructionType, isCustomerOngoing) => (
  { type: formatAccountDetailsEvent(instructionType, isCustomerOngoing, 'ACCOUNT_DETAILS_ROLL_NUMBER_CHANGED') }
);
