import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';
import { firestoreConnect } from 'react-redux-firebase';
import get from 'lodash/get';
import moment from 'moment';
import sentryException from '../../util/sentryException';

import {
  SESSION_DETECTION_FREQUENCY,
  SHOW_SESSION_MODAL_AT_TIME,
  AUTO_LOG_USER_OUT_AT_TIME,
} from '../../config';

import {
  getIsAuthenticated,
  getFirebaseUid,
  getFirestoreErrors,
  getAuthToken,
} from '../../redux/selectors';
import getRecaptchaToken from '../../util/getRecaptchaToken';
import { generateNewIdToken } from '../../util/auth';
import {
  FBLogout,
  FBAutoLogout,
  FBRegenerateIdTokenModalShown,
  FBRegenerateIdTokenModalClosed,
} from '../../redux/modules/auth';
import Modal from '../Modal/Modal';
import GlanceCard from '../GlanceCard/GlanceCard';
import GlanceCardContent from '../GlanceCard/GlanceCardContent';
import Button from '../Button/Button';

import styles from './PrivateRoute.css';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tokenExpiresCustomClaims: null,
      showLogoutModal: false,
      showModalAtRemainingTime: SHOW_SESSION_MODAL_AT_TIME,
      logUserOutAtRemainingTime: AUTO_LOG_USER_OUT_AT_TIME,
      timeLeftUntilLogOut: {
        minutes: '00',
        seconds: '00',
      },
      stayLoggedInLoading: false,
    };
  }

  async componentDidMount() {
    const { firebase } = this.props;
    const user = firebase.auth().currentUser;

    if (user) {
      const idTokenResult = await user.getIdTokenResult();
      this.setState({
        tokenExpiresCustomClaims: get(idTokenResult, 'claims.token_expires'),
      });
    }

    this.trackUsersIdTokenChanges();
    this.sessionTimer();
  }

  componentDidUpdate(prevProps, prevState) {
    const { showLogoutModal } = this.state;
    const { authModalClosed } = this.props;

    if (prevState.showLogoutModal && prevState.showLogoutModal !== showLogoutModal) {
      authModalClosed();
    }

    if (showLogoutModal) {
      this.updateTimeLeftUntilLogout();
    }
  }

  componentWillUnmount() {
    if (this.onTokenChange) {
      this.onTokenChange();
    }

    clearInterval(this.checkSession);
    clearTimeout(this.logoutTimer);
  }

  updateTimeLeftUntilLogout() {
    const { tokenExpiresCustomClaims, logUserOutAtRemainingTime } = this.state;
    const { logoutAutoTimeout } = this.props;

    clearTimeout(this.logoutTimer);
    this.logoutTimer = setTimeout(() => {
      const currentTime = moment.utc();
      const tokenExpires = moment.utc(tokenExpiresCustomClaims);

      const fullTimeRemainingInSeconds = tokenExpires.diff(
        currentTime,
        'seconds',
      ) - (logUserOutAtRemainingTime);

      const minutesRemaining = Math.floor(fullTimeRemainingInSeconds / 60);
      const secondsRemaining = fullTimeRemainingInSeconds - minutesRemaining * 60;

      if (minutesRemaining >= 0 && secondsRemaining >= 0) {
        this.setState({
          timeLeftUntilLogOut: {
            minutes: minutesRemaining.toString(10).padStart(2, '0'),
            seconds: secondsRemaining.toString(10).padStart(2, '0'),
          },
        });
      } else {
        logoutAutoTimeout();
      }
    }, 1000);
  }

  async trackUsersIdTokenChanges() {
    const { firebase } = this.props;
    const { tokenExpiresCustomClaims } = this.state;

    this.onTokenChange = firebase.auth().onIdTokenChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        const newIdToken = get(idTokenResult, 'claims.token_expires');
        this.setState({
          tokenExpiresCustomClaims: newIdToken || tokenExpiresCustomClaims,
          showLogoutModal: false,
        });
      }
    });
  }

  sessionTimer() {
    const {
      authenticated,
      logoutAutoTimeout,
      authModalOpened,
    } = this.props;

    this.checkSession = setInterval(() => {
      const {
        tokenExpiresCustomClaims,
        showLogoutModal,
        showModalAtRemainingTime,
        logUserOutAtRemainingTime,
      } = this.state;

      if (!showLogoutModal
        && authenticated
        && tokenExpiresCustomClaims
        && moment().diff(tokenExpiresCustomClaims, 'seconds') > -showModalAtRemainingTime) {
        this.setState({ showLogoutModal: true }, () => authModalOpened());
      }

      if (authenticated
        && tokenExpiresCustomClaims
        && moment().diff(tokenExpiresCustomClaims, 'seconds') > -logUserOutAtRemainingTime) {
        logoutAutoTimeout();
      }
    }, SESSION_DETECTION_FREQUENCY);
  }

  render() {
    const {
      component,
      authenticated,
      fireStoreErrors,
      logout,
      logoutAuto,
      uid,
      idToken,
      authModalClosed,
      componentProps,
      ...rest
    } = this.props;

    const {
      showLogoutModal,
      timeLeftUntilLogOut,
      stayLoggedInLoading,
    } = this.state;
    const { minutes, seconds } = timeLeftUntilLogOut;

    const userCollectionError = get(fireStoreErrors, `users/${uid}.code`);
    if (userCollectionError === 'permission-denied') {
      logoutAuto();
    }

    return (
      <div>
        <Route
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          render={(props) => {
            const combinedProps = {
              ...props,
              componentProps,
            };

            return (
              authenticated
                ? React.createElement(component, combinedProps)
                : (
                  <Redirect
                    to={{
                      pathname: '/login',
                      state: { from: props.location },
                    }}
                  />
                )
            );
          }}
        />
        <Modal show={showLogoutModal} centerContent>
          <GlanceCard wrapperStyles={{ textAlign: 'center' }} title="Session expired">
            <GlanceCardContent wrapperStyles={{ maxHeight: '500px' }}>
              <p className={styles.sessionExpiredMessage} data-cy="session-message">{`Your session is about to expire and you will be automatically logged out in ${minutes}:${seconds}`}</p>
              <div className={styles.sessionExpiredButtonsContainer}>
                <Button
                  size="medium"
                  label="Stay logged in"
                  loading={stayLoggedInLoading}
                  onClick={async () => {
                    try {
                      this.setState({ stayLoggedInLoading: true });
                      const recaptchaToken = await getRecaptchaToken('generateNewIdToken');
                      await generateNewIdToken(idToken, recaptchaToken);
                      this.setState({ stayLoggedInLoading: false });
                    } catch (error) {
                      sentryException(error, {
                        section: 're-generate-id-token',
                      });
                      this.setState({ stayLoggedInLoading: false });
                    }
                  }}
                />
                <Button
                  size="medium"
                  label="Log out"
                  dataCy="session-logout"
                  onClick={() => {
                    logout();
                  }}
                />
              </div>
            </GlanceCardContent>
          </GlanceCard>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: getIsAuthenticated(state),
  uid: getFirebaseUid(state),
  fireStoreErrors: getFirestoreErrors(state),
  idToken: getAuthToken(state),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(FBLogout()),
  logoutAutoTimeout: () => dispatch(FBAutoLogout(true)),
  logoutAuto: () => dispatch(FBAutoLogout(false)),
  authModalOpened: () => dispatch(FBRegenerateIdTokenModalShown()),
  authModalClosed: () => dispatch(FBRegenerateIdTokenModalClosed()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    const query = [
      {
        collection: 'users',
        doc: props.uid,
        storeAs: 'users',
      },
    ];

    return query;
  }),
)(PrivateRoute);
