import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './SetupContributionPage.css';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import AccountDetails from './AccountDetails';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import {
  contributionBankDetailsStepVisitedAction,
  ongoingContributionBankDetailsStepVisitedAction,
  returnLinkClickedAction,
  ongoingReturnLinkClickedAction,
  nameChangedAction,
  ongoingNameChangedAction,
  sortcodeChangedAction,
  ongoingSortcodeChangedAction,
  accountNumberChangedAction,
  ongoingAccountNumberChangedAction,
  nextClickedAction,
  ongoingNextClickedAction,
  confirmClickedAction,
  ongoingConfirmClickedAction,
} from '../../redux/modules/contributions';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';

import ContributionsFscs from '../../assets/images/rebranding/fscs-protected-card.svg';
import ContributionsAccountDetailsImage from '../../assets/images/contributions/contributions-account-details.svg';

const ContributionBankDetailsStep = ({
  setFormData,
  formData,
  setStep,
  step,
  amendMode,
  totalSteps,
  dispatchContributionBankDetailsStepVisited,
  dispatchReturnLinkClicked,
  dispatchNextClicked,
  dispatchConfirmClicked,
}) => {
  const disableContributionBankDetailsStepNext = !formData.name
    || !/\S/g.test(formData.name)
    || !formData.sortCode
    || formData.sortCode.length !== 8
    || !formData.accountNumber
    || formData.accountNumber.length !== 8;

  useEffect(() => {
    dispatchContributionBankDetailsStepVisited();
    window.scrollTo(0, 0);
  }, []);

  return (
    <BaseGrid pageSectionStyles={{ marginBottom: '200px' }} addPensionsTheme>
      <div className={styles.contributionsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
          <ButtonLink
            secondary
            mid
            onClick={() => {
              dispatchReturnLinkClicked();
              setStep(step - 1);
            }}
            label="← Back"
          />
        )}
        <div className={styles.imageContainer}>
          <img src={ContributionsAccountDetailsImage} alt="debit card" />
        </div>
        <h2 className={styles.contributionsHeading}>{'Account details'}</h2>
        <AccountDetails
          formData={formData}
          setFormData={setFormData}
          instructionType={'contributions'}
          isCustomerOngoing
        />
        <div className={styles.standardContainer}>
          <Button
            size="large"
            disabled={disableContributionBankDetailsStepNext}
            label={amendMode ? 'Confirm' : 'Next'}
            onClick={() => {
              if (amendMode) {
                setStep(4);
                dispatchConfirmClicked();
              } else {
                setStep(step + 1);
                dispatchNextClicked();
              }
            }}
          />
        </div>
        <div className={styles.bodyImageContainer}>
          <img src={ContributionsFscs} alt="FSCS" />
        </div>
      </div>
      <div className={styles.pensionAdviserContainer}>
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'ContributionAmountStep'}
            noExpertContentWrapper={({ children }) => (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexShrink: 'initial',
                padding: '10px',
                flexWrap: 'wrap',
              }}
              >
                {children}
              </div>
            )}
            showButton={false}
            customMessage={(
              <div className={styles.contactUsInnerContent}>
                {'If you have any questions, please feel free to '}
                <ButtonLink
                  label="message me"
                  variant="primary"
                  to="/inbox/new-message"
                  font="inherit"
                />
                {'.'}
              </div>
            )}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  _dispatchOnboardingContributionBankDetailsStepVisited: contributionBankDetailsStepVisitedAction,
  _dispatchOngoingContributionBankDetailsStepVisited:
    ongoingContributionBankDetailsStepVisitedAction,
  _dispatchOnboardingReturnLinkClicked: returnLinkClickedAction,
  _dispatchOngoingReturnLinkClicked: ongoingReturnLinkClickedAction,
  _dispatchOnboardingNameChanged: nameChangedAction,
  _dispatchOngoingNameChanged: ongoingNameChangedAction,
  _dispatchOnboardingSortcodeChanged: sortcodeChangedAction,
  _dispatchOngoingSortcodeChanged: ongoingSortcodeChangedAction,
  _dispatchOnboardingAccountNumberChanged: accountNumberChangedAction,
  _dispatchOngoingAccountNumberChanged: ongoingAccountNumberChangedAction,
  _dispatchOnboardingNextClicked: nextClickedAction,
  _dispatchOngoingNextClicked: ongoingNextClickedAction,
  _dispatchOnboardingConfirmClicked: confirmClickedAction,
  _dispatchOngoingConfirmClicked: ongoingConfirmClickedAction,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isCustomerOngoing } = stateProps;
  const {
    _dispatchOnboardingContributionBankDetailsStepVisited,
    _dispatchOngoingContributionBankDetailsStepVisited,
    _dispatchOnboardingReturnLinkClicked,
    _dispatchOngoingReturnLinkClicked,
    _dispatchOnboardingNextClicked,
    _dispatchOngoingNextClicked,
    _dispatchOnboardingConfirmClicked,
    _dispatchOngoingConfirmClicked,
  } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    dispatchContributionBankDetailsStepVisited:
      isCustomerOngoing ? _dispatchOngoingContributionBankDetailsStepVisited
        : _dispatchOnboardingContributionBankDetailsStepVisited,
    dispatchReturnLinkClicked:
      isCustomerOngoing ? _dispatchOngoingReturnLinkClicked : _dispatchOnboardingReturnLinkClicked,
    dispatchNextClicked:
      isCustomerOngoing ? _dispatchOngoingNextClicked : _dispatchOnboardingNextClicked,
    dispatchConfirmClicked:
      isCustomerOngoing ? _dispatchOngoingConfirmClicked : _dispatchOnboardingConfirmClicked,
  };
};

export default connect(mapStateToProps,
  mapDispatchToProps, mergeProps)(ContributionBankDetailsStep);
