/* eslint-disable import/no-cycle */
import get from 'lodash/get';
import moment from 'moment';
import { getAuthoritativeRetirementPlan, getUser } from './index';
import { getIsCustomerOngoing } from './experience';
import { getInvestmentAdviceOnboarding } from './investmentAdvice';

export const getNotificationTracking = (state) => {
  return get(state, 'firestore.ordered.notificationTracking');
};

export const getAuthoritativePensionPlanNotifications = (state, pensionPlanRecommendation) => {
  let pensionPlanNotifications;
  const notificationTracking = getNotificationTracking(state);
  const currentPensionPlanRecommendationId = get(pensionPlanRecommendation, '_id');

  if (notificationTracking) {
    [pensionPlanNotifications] = notificationTracking.filter((notificationTypes) => notificationTypes.id === 'pensionPlanNotifications');
  }

  if (pensionPlanNotifications && currentPensionPlanRecommendationId) {
    return pensionPlanNotifications[currentPensionPlanRecommendationId];
  }

  return null;
};

export const getHasInstructionBeenSeenNotifications = (state, instructionId) => {
  let instructionNotifications;
  const notificationTracking = getNotificationTracking(state);
  if (notificationTracking) {
    instructionNotifications = notificationTracking.find((notificationTypes) => notificationTypes.id === 'instructionNotifications');
  }

  if (instructionId && instructionNotifications) {
    return get(instructionNotifications, `${instructionId}.intruction-completed`) === 'closed';
  }

  return false;
};

export const getHasInstructionNotificationTimedOut = (state, instructionId) => {
  const NOTIFICATION_TIMEOUT_PERIOD_IN_DAYS = 3;
  const onboarding = getInvestmentAdviceOnboarding(state);
  const instructions = get(onboarding, 'ongoingService.instructions', []);
  const instruction = instructions.find((i) => i._id === instructionId);
  return instruction
  && instruction.completedDate
  && moment().diff(instruction.completedDate, 'days', true) > NOTIFICATION_TIMEOUT_PERIOD_IN_DAYS;
};

export const getShowMarketingPreferencesNotification = (state) => {
  const isOngoing = getIsCustomerOngoing(state);

  if (!isOngoing) return false;

  const user = getUser(state);
  const marketingPreferences = [
    'marketingPreferences.telephone',
    'marketingPreferences.email',
    'marketingPreferences.sms',
    'marketingPreferences.post',
    'marketingPreferences.socialMedia'];
  const isAnyMarketingPreferenceTrue = marketingPreferences.some((key) => get(user, key) === true);

  if (isAnyMarketingPreferenceTrue) { return false; }

  let marketingPreferenceNotification;
  const notificationTracking = getNotificationTracking(state);
  if (notificationTracking) {
    marketingPreferenceNotification = notificationTracking.find((notificationTypes) => notificationTypes.id === 'marketingPreferenceNotification');
  }
  if (marketingPreferenceNotification
    && new Date(get(marketingPreferenceNotification, 'snoozeUntil')) > new Date()) {
    return false;
  }
  return true;
};

export const getHasIntroModalBeenDismissed = (state) => {
  return get(state, 'firestore.data.notificationTracking.introModal.dismissed', false);
};

export const getHasMfmAnnouncementModalBeenDismissed = (state) => {
  return get(state, 'firestore.data.notificationTracking.mfmAnnouncement.dismissed', false);
};

export const getHasLumpSumNotificationBeenDismissed = (state, instructionId) => {
  if (!instructionId) {
    return null;
  }

  return get(state, `firestore.data.notificationTracking.lumpSumNotificationUpdate.${instructionId}`, false);
};

export const getHasMay2023DrawdownUpdateNotificationBeenDismissed = (state) => {
  return !!get(state, 'firestore.data.notificationTracking.portfolioUpdateNotifications.may2023DrawdownUpdate', false);
};

export const getIsNewOngoingCustomerIntroModal = (state) => {
  const LAUNCH_DATE = '2023-04-18'; // We can update this before launch
  const authoritativeRP = getAuthoritativeRetirementPlan(state);
  const authoritativeRPCreatedDate = get(authoritativeRP, 'createdDate', new Date().toISOString());
  return moment(authoritativeRPCreatedDate).isAfter(LAUNCH_DATE);
};

export const getHasViewedPlanActivity = (state) => {
  const notificationTracking = getNotificationTracking(state);
  if (notificationTracking) {
    const hasViewedPlanActivityFirstTime = notificationTracking.find((notificationTypes) => notificationTypes.id === 'planActivityViewedTracking');
    if (hasViewedPlanActivityFirstTime) {
      const { viewed, viewedDate } = hasViewedPlanActivityFirstTime;

      return viewed && viewedDate;
    }
  }
  return false;
};

export const getHasSerpsWaitingForHmrcBeenDismissed = (state) => {
  const notificationTracking = getNotificationTracking(state);
  if (notificationTracking) {
    const hasViewedSERPSTraceUpdate = notificationTracking.find((notificationTypes) => notificationTypes.id === 'serpsWaitingForHmrcNotification');

    if (hasViewedSERPSTraceUpdate) {
      const { viewed, viewedDate } = hasViewedSERPSTraceUpdate;

      return viewed && viewedDate;
    }
  }
  return false;
};

export const getHasSERPSCompleteRequestBeenDismissed = (state) => {
  const notificationTracking = getNotificationTracking(state);
  if (notificationTracking) {
    const hasViewedSERPSCompleteRequestUpdate = notificationTracking.find((notificationTypes) => notificationTypes.id === 'serpsCompleteRequestNotification');

    if (hasViewedSERPSCompleteRequestUpdate) {
      const { viewed, viewedDate } = hasViewedSERPSCompleteRequestUpdate;

      return viewed && viewedDate;
    }
  }
  return false;
};


export const getHasSERPSTraceIneligibleBeenDismissed = (state) => {
  const notificationTracking = getNotificationTracking(state);
  if (notificationTracking) {
    const hasViewedSERPSCompleteRequestUpdate = notificationTracking.find((notificationTypes) => notificationTypes.id === 'serpsTraceIneligibleNotification');

    if (hasViewedSERPSCompleteRequestUpdate) {
      const { viewed, viewedDate } = hasViewedSERPSCompleteRequestUpdate;

      return viewed && viewedDate;
    }
  }
  return false;
};
export const getHasSERPSPensionFoundUpdateBeenDismissed = (state) => {
  const notificationTracking = getNotificationTracking(state);
  if (notificationTracking) {
    const hasViewedSERPSPensionUpdate = notificationTracking.find((notificationTypes) => notificationTypes.id === 'serpsPensionFoundNotification');

    if (hasViewedSERPSPensionUpdate) {
      const { viewed, viewedDate } = hasViewedSERPSPensionUpdate;

      return viewed && viewedDate;
    }
  }
  return false;
};

export const getHasSERPSNoPensionsFoundUpdateBeenDismissed = (state) => {
  const notificationTracking = getNotificationTracking(state);
  if (notificationTracking) {
    const hasViewedSERPSNoPensionsFoundUpdate = notificationTracking.find((notificationTypes) => notificationTypes.id === 'noSerpsPensionsFoundNotification');

    if (hasViewedSERPSNoPensionsFoundUpdate) {
      const { viewed, viewedDate } = hasViewedSERPSNoPensionsFoundUpdate;

      return viewed && viewedDate;
    }
  }
  return false;
};

export const getHasCompletePensionRequested = (state) => {
  return get(state, 'firestore.data.notificationTracking.completeRequestNotification.pension.requested', false);
};

export const getHasCompletePensionRequestedDismissed = (state) => {
  return get(state, 'firestore.data.notificationTracking.completeRequestNotification.pension.dismissed', false);
};

export const getHasCompleteContributionRequested = (state) => {
  return get(state, 'firestore.data.notificationTracking.completeRequestNotification.contribution.requested', false);
};

export const getHasCompleteContributionRequestedDismissed = (state) => {
  return get(state, 'firestore.data.notificationTracking.completeRequestNotification.contribution.dismissed', false);
};

export const getHasCustomerViewedWithdrawalMiniJourney = (state) => {
  return get(state, 'firestore.data.notificationTracking.withdrawalMiniJourney.viewed', false);
};

export const getHasCustomerVisitedSerpsMissingDetails = (state) => {
  return get(state, 'firestore.data.notificationTracking.serpsFirstSession.visited', false);
};

export const getHasAuthorizedSerpsRequest = (state) => {
  return get(getInvestmentAdviceOnboarding(state), 'serpsAuthorityTracking.authoriseSerpsTrace', false);
};
