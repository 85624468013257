import React from 'react';
import styles from './JourneyLayout.css';

const JourneyLayoutSidebar = ({ children }) => (
  <div className={styles.layoutSidebar}>
    {children}
  </div>
);

export default JourneyLayoutSidebar;
