import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getFirebaseUid } from '../../redux/selectors';
import { cds15MonthHardBlockLinkClicked, cds15MonthHardBlockViewed } from '../../redux/modules/dashboard';

import icon from '../../assets/images/cds15MonthHardBlock/cds-15-month-hard-block-icon.svg';

import Button from '../Button/Button';

import styles from './Cds15MonthHardBlock.css';

const Cds15MonthHardBlock = ({
  dispatchCds15MonthHardBlockLinkClicked,
  dispatchCds15MonthHardBlockViewed,
}) => {
  useEffect(() => {
    dispatchCds15MonthHardBlockViewed();
  }, []);

  const handleCtaButton = async () => {
    dispatchCds15MonthHardBlockLinkClicked();
    window.location = '/your-latest-information';
  };

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={icon} alt="" />
      </div>
      <div className={styles.textContainer}>
        <h2 className={styles.heading}>{'Update your goals to access your full account'}</h2>
        <p className={styles.body}>{'To make sure your pension is in the best place, we need you to keep us up to date with your investment goals. You won’t be able to access full account activity until this has been completed.'}</p>
      </div>
      <div className={styles.ctaButtonContainer}>
        <Button size="large" onClick={handleCtaButton} label={'Update my goals'} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
});

const mapDispatchToProps = ({
  dispatchCds15MonthHardBlockLinkClicked: cds15MonthHardBlockLinkClicked,
  dispatchCds15MonthHardBlockViewed: cds15MonthHardBlockViewed,
});

export default connect(mapStateToProps, mapDispatchToProps)(Cds15MonthHardBlock);
