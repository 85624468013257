import React from 'react';
import Button from '../components/Button/Button';


const ForgotPasswordResetEmailSuccess = () => (
  <div>
    <h1 className="h2">{'Forgot password?'}</h1>
    <br />
    <p>
      {'If there’s an account linked to this email address, we’ve sent over instructions to reset your password.'}
    </p>
    <Button to="/" label="Back to login" back />
  </div>
);

export default ForgotPasswordResetEmailSuccess;
