import React from 'react';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import sentryException from '../../util/sentryException';
import { loginUnverifiedUser } from '../../util/auth';
import { setNotificationMessage, externalLoginSuccessfulAction } from '../../redux/modules/auth';

import Loader from '../../components/Loader/Loader';
import styles from './ExternalLoginPage.css';

import {
  sendGaCustomEvent,
  gaCategories,
  gaLabels,
  gaActions,
} from '../../util/googleAnalyticHelpers';

const X_AUTH_LOGIN_FAILED = 'Your sign up was successful. Please log in with your email and password.';

class ExternalLoginPage extends React.Component {
  async componentDidMount() {
    const { history, dispatch, dispatchExternalLoginSuccessful } = this.props;

    const docCookies = get(window, 'docCookies');

    if (!docCookies) {
      return history.replace('/login');
    }

    const authToken = docCookies.getItem('x-auth');

    if (!authToken) {
      return history.replace('/login');
    }

    try {
      await loginUnverifiedUser(authToken);
      docCookies.removeItem('x-auth', '/', window.location.hostname === 'localhost' ? 'localhost' : '.profilepensions.co.uk');
      sendGaCustomEvent({
        category: gaCategories.acquisition,
        label: gaLabels.signupClicks,
        action: gaActions.recordCount,
      });
      dispatchExternalLoginSuccessful();
      return history.replace('/');
    } catch (e) {
      sentryException(e, {
        section: 'external-login',
      });
      dispatch(setNotificationMessage(X_AUTH_LOGIN_FAILED));
      return history.replace('/login');
    }
  }

  render() {
    return <div className={styles.loaderContainer}><Loader /></div>;
  }
}

const mapDispatchToProps = {
  dispatchExternalLoginSuccessful: externalLoginSuccessfulAction,
};

export default compose(connect(() => {}, mapDispatchToProps))(withRouter(ExternalLoginPage));
