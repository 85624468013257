import React, { useState, useEffect } from 'react';
import once from 'lodash/once';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import sentryException from '../../util/sentryException';
import { clearPendingAction, createConversationAction, newConversationMessageBodyTyped } from '../../redux/modules/conversations';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import TextField from '../../components/TextField/TextField';

import styles from './SendMessageSection.css';
import { getConversations } from '../../redux/selectors/conversations';
import { getFirebaseUid, getEmail } from '../../redux/selectors';
import { removeWhitespace } from '../../util/common';
import AddMessageAttachments from '../../components/AddMessageAttachments/AddMessageAttachments';

const SendMessageSection = ({
  subjectFromParams,
  dispatchCreateConversation,
  dispatchNewConversationMessageBodyTyped: _dispatchNewConversationMessageBodyTyped,
  history,
  dispatchClearPending,
  setShowDisclaimer,
}) => {
  const [subject, setSubject] = useState(subjectFromParams || '');
  const [messageBody, setMessageBody] = useState('');
  const [submitError, setSubmitError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const isSubmitDisabled = attachments.length > 0
    ? (
      !attachments.every((file) => file.accepted)
      || !removeWhitespace(subject)
      || !removeWhitespace(messageBody)
    ) : (
      !removeWhitespace(subject) || !removeWhitespace(messageBody)
    );

  const [dispatchNewConversationMessageBodyTyped] = useState(
    () => once(_dispatchNewConversationMessageBodyTyped),
  );
  const onSubmit = async () => {
    setSubmitError(null);
    setSubmitting(true);

    const conversationBody = { subject: subject.trim(), messageBody: messageBody.trim() };

    if (attachments.length > 0) {
      conversationBody.attachmentUploads = attachments.map(({ filePath }) => filePath);
    }

    try {
      await dispatchCreateConversation(conversationBody);
      setShowDisclaimer(true);
      history.push({ pathname: '/inbox' });
    } catch (e) {
      dispatchClearPending();
      setSubmitting(false);
      setSubmitError(true);
      sentryException(e, {
        section: 'new-conversation-error',
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div className={styles.innerWrapper}>
        <div className={styles.inboxLinkWrapper}>
          <ButtonLink variant="primary" label="← View my inbox" to="/inbox" secondary bold />
        </div>
        <h2 className={styles.heading}>{'New message'}</h2>
        <TextField
          wrapperStyles={{ maxWidth: 'none', width: '100%' }}
          name="subject"
          defaultValue="Enter subject here"
          label={<h5 className={styles.fieldHeading}>{'Subject'}</h5>}
          value={subject}
          onChange={(e) => {
            const value = get(e, 'target.value');
            setSubject(value);
          }}
        />
        <TextField
          wrapperStyles={{ maxWidth: 'none', width: '100%', marginBottom: '20px' }}
          name="messageBody"
          multiLine
          multiLineRows={5}
          defaultValue="Enter message here"
          label={<h5 className={styles.fieldHeading}>{'Message'}</h5>}
          value={messageBody}
          onChange={(e) => {
            const value = get(e, 'target.value');
            dispatchNewConversationMessageBodyTyped();
            setMessageBody(value);
          }}
        />
        <AddMessageAttachments
          onAttachmentsChange={(attachmentFiles) => {
            setAttachments(
              attachmentFiles.map(({ accepted, filePath, createId }) => ({ accepted, filePath, createId })),
            );
          }}
        />
        <div className={styles.buttonWrapper}>
          <Button
            loading={submitting}
            label="Send"
            disabled={isSubmitDisabled}
            onClick={onSubmit}
            wrapperStyles={{
              margin: 0,
              ...(isSubmitDisabled && { backgroundColor: '#FFFFFF' }),
            }}
          />
          {(submitError) && (
            <div className={styles.error}>{'Something went wrong submitting this message. Please try again.'}</div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  conversations: getConversations(state),
  uid: getFirebaseUid(state),
  email: getEmail(state),
});

const mapDispatchToProps = ({
  dispatchCreateConversation: createConversationAction,
  dispatchClearPending: clearPendingAction,
  dispatchNewConversationMessageBodyTyped: newConversationMessageBodyTyped,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(SendMessageSection);
