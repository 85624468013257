import React from 'react';
import styles from './PasswordStrength.css';

export default function PasswordStrength() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.tipsWrapper}>
        {'Password tips'}
        <ul className={styles.tipsList}>
          <li className={styles.tips}>{'Please enter a password with at least 8 characters.'}</li>
          <li className={styles.tips}>{'It should contain both numbers and letters.'}</li>
          <li className={styles.tips}>{'Avoid common or easily guessed weaker passwords.'}</li>
        </ul>
      </div>
    </div>
  );
}
