export const removeSpaces = number => number.replace(/\s+/g, '');

export const stripCountryCode = number => number.replace('+44', '0');

export const beautifyLandline = (number) => {
  const llLocal = stripCountryCode(removeSpaces(number));
  const llGroups = [];
  llGroups.push(llLocal.slice(0, 5));
  llGroups.push(llLocal.slice(5, 8));
  llGroups.push(llLocal.slice(8, 11));
  return llGroups.join(' ');
};

export const beautifyMobile = (number) => {
  const mobLocal = stripCountryCode(removeSpaces(number));
  const mobGroups = [];
  mobGroups.push(mobLocal.slice(0, 5));
  mobGroups.push(mobLocal.slice(5, 11));
  return mobGroups.join(' ');
};
