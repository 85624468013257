import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';
import get from 'lodash/get';
import styles from './PersonalDetails.css';
import { getHasOnlyInProgressContributionInstruction, getHasInProgressLumpSumInstructions } from '../../redux/selectors/investmentAdvice';
import Button from '../../components/Button/Button';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import { getPhoneNumber, getNationalInsuranceNumber } from '../../redux/selectors/index';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import { updatePersonalDetails } from '../../redux/modules/investmentAdvice';
import {
  missingDetailsNationalInsuranceNumberTyped,
  missingDetailsNationalInsuranceNumberDeleted,
  missingDetailsNinoSubmitClickedAction,
  missingDetailsPageLoaded,
  missingDetailsCompleteAction,
  missingDetailsMessageMeClickedAction,
} from '../../redux/modules/signupFlow';
import missingDetailsChecklist from '../../assets/images/Missing_details_nino_image.svg';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import TextField from '../../components/TextField/TextField';
import { sendGaPageEvent } from '../../util/googleAnalyticHelpers';
import { statusNotificationMissingDetailsCompleted } from '../../redux/modules/notificationTracking';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';

const NationalInsuranceNumberCard = ({
  heading,
  isSubmitting,
  formData,
  setFormData,
  setStep,
  step,
  totalSteps,
  amendMode,
  flow,
  nino,
  isSubmitLoading,
  setIsSubmitLoading,
  dispatchUpdatePersonalDetails,
  dispatchStatusNotificationMissingDetailsCompleted,
  dispatchMissingDetailsComplete,
  hasOnlyInProgressContributionInstructions,
  hasInProgressLumpSumInstructions,
  dispatchMissingDetailsNationalInsuranceNumberTyped: _dispatchMissingDetailsNationalInsuranceNumberTyped,
  dispatchMissingDetailsNationalInsuranceNumberDeleted: _dispatchMissingDetailsNationalInsuranceNumberDeleted,
  dispatchMissingDetailsNinoSubmitClickedAction,
  dispatchMissingDetailsMessageMeClickedAction,
  dispatchMissingDetailsPageLoaded: _dispatchMissingDetailsPageLoaded,
}) => {
  const [nationalInsuranceNumber, setNationalInsuranceNumber] = useState(formData.nationalInsuranceNumber || '');
  const [error, setError] = useState(false);

  const [dispatchMissingDetailsNationalInsuranceNumberTyped] = useState(() => once(_dispatchMissingDetailsNationalInsuranceNumberTyped || ''));
  const [dispatchMissingDetailsNationalInsuranceNumberDeleted] = useState(() => once(_dispatchMissingDetailsNationalInsuranceNumberDeleted || ''));
  const [dispatchMissingDetailsPageLoaded] = useState(() => once(_dispatchMissingDetailsPageLoaded || ''));

  const removeWhitespace = (string) => {
    return string ? string.replace(/\s/g, '') : '';
  };
  const uppercase = (string) => {
    return string ? string.toUpperCase() : '';
  };

  const NI_REGEX = /^(?!BG|GB|KN|NK|NT|TN|ZZ)[^DFIQUV][^DFIQUVo][0-9]{6}[ABCD]$/img;

  const missingDetailsData = {
    nationalInsuranceNumber,
  };

  async function isUserOnLastStepOfFlow() {
    if (step === totalSteps - 2) {
      setIsSubmitLoading(true);
      await dispatchUpdatePersonalDetails(missingDetailsData);
      dispatchStatusNotificationMissingDetailsCompleted();
      dispatchMissingDetailsComplete();
      setIsSubmitLoading(false);
      setStep((_step) => _step + 1);
    }
    setStep((_step) => _step + 1);
  }

  const hasValidNationalInsuranceNumber = nino !== undefined && NI_REGEX.test(nino);

  const disableSaveButton = !nationalInsuranceNumber || isSubmitLoading || error;

  const shouldShowContributionCopy = (flow === 'missing-details-cta' && (hasOnlyInProgressContributionInstructions || hasInProgressLumpSumInstructions)) || (flow === 'add-contribution' || flow === 'setup-lump-sum');

  useEffect(() => {
    window.scrollTo(0, 0);
    sendGaPageEvent('/missing-details');
    dispatchMissingDetailsPageLoaded();
  }, []);

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.missingDetailsWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
        <ButtonLink
          to="/"
          label="← Back"
        />
        )}
        <div className={styles.checklist}>
          <img src={missingDetailsChecklist} alt="Checklist" />
        </div>
        {(shouldShowContributionCopy ? (
          <div>
            <h1 className={styles.ninoHeading}>
              {heading}
              {'We need some details in order to set up your contribution'}
            </h1>
            <div className={styles.content}>
              {'In order to set up your contribution your National Insurance number is mandatory to proceed with your request.'}
              {' '}
            </div>
          </div>
        ) : (
          <div>
            <h1 className={styles.ninoHeading}>
              {heading}
              {'We need some details in order to transfer your pension'}
            </h1>
            <div className={styles.content}>
              {'In order to locate and transfer your pension, your current provider(s) need some additional details. Providing these could significantly speed up the process.'}
            </div>
          </div>
        ))}
        { hasValidNationalInsuranceNumber === false && (
          <div>
            <form className={styles.form} />
            <h1 className={styles.ninoHeading}>
              {'National Insurance number'}
            </h1>
            <TextField
              type="text"
              className={styles.ninoInput}
              fieldStatusWrapper={{ margin: 0 }}
              blockWrapper={{ margin: 0 }}
              fullWidth
              defaultValue={'Enter your National Insurance number'}
              value={nationalInsuranceNumber}
              touched
              onBlur={() => {
                if (!NI_REGEX.test(nationalInsuranceNumber)) {
                  setError(true);
                }
              }}
              wrapperStyles={{ margin: '0' }}
              onChange={(event) => {
                const value = get(event, 'target.value');
                if (!value) {
                  dispatchMissingDetailsNationalInsuranceNumberDeleted();
                } else {
                  dispatchMissingDetailsNationalInsuranceNumberTyped();
                }
                const formattedNationalInsuranceNumber = uppercase(removeWhitespace(value));
                setNationalInsuranceNumber(formattedNationalInsuranceNumber);
                if (NI_REGEX.test(formattedNationalInsuranceNumber)) {
                  setError(false);
                }
              }}
            />
            { error
        && (
        <div className={styles.errorMessage}>
          {'Please enter a valid National Insurance number'}
        </div>
        )}
          </div>
        )}
        <div>
          <Button
            size="large"
            type="button"
            label={'Next'}
            disabled={hasValidNationalInsuranceNumber ? false : disableSaveButton}
            loading={isSubmitLoading}
            onClick={async () => {
              setFormData((_formData) => ({
                ..._formData,
                nationalInsuranceNumber: hasValidNationalInsuranceNumber ? nino : nationalInsuranceNumber,
              }));
              dispatchMissingDetailsNinoSubmitClickedAction();
              isUserOnLastStepOfFlow();
            }}
            wrapperId="missing-details-submit"
          />
          <br />
        </div>
        {<div>&nbsp;</div>}

        {!hasValidNationalInsuranceNumber ? (
          <div className={styles.pensionAdviserCard}>
            <ContactUsContent
              source={'NationalInsuranceNumber'}
              noExpertContentWrapper={({ children }) => (
                <div
                  style={{
                    display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                  }}
                >
                  {children}
                </div>
              )}
              showButton={false}
              customMessage={(
                <div
                  className={styles.contactUsInnerContent}
                >
                  {'Your National Insurance number can be found on your payslip or letter from the Department for Work and Pensions (DWP). '}
                  <br />
                  <br />
                  {'If you need help finding it, here are '}
                  <ButtonLink
                    variant="primary"
                    label="instructions on the government website."
                    link="https://www.gov.uk/lost-national-insurance-number"
                    font="inherit"
                    target="_blank"
                    onClick={() => dispatchMissingDetailsMessageMeClickedAction()}
                    displayAsHref
                  />
                </div>
              )}
            />
          </div>

        ) : (
          <div className={styles.pensionAdviserCard}>
            <ContactUsContent
              source={'NationalInsuranceNumber'}
              noExpertContentWrapper={({ children }) => (
                <div
                  style={{
                    display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                  }}
                >
                  {children}
                </div>
              )}
              showButton={false}
              customMessage={(
                <div
                  className={styles.contactUsInnerContent}
                >
                  {'If you have any questions, please feel free to '}
                  <ButtonLink
                    label="message me"
                    variant="primary"
                    link="/inbox/new-message"
                    font="inherit"
                    target="_blank"
                    onClick={() => dispatchMissingDetailsMessageMeClickedAction()}
                  />
                  {'.'}
                </div>
              )}
            />
          </div>
        )}
      </div>
    </BaseGrid>
  );
};

const mapDispatchToProps = {
  dispatchMissingDetailsNationalInsuranceNumberTyped: missingDetailsNationalInsuranceNumberTyped,
  dispatchMissingDetailsNationalInsuranceNumberDeleted: missingDetailsNationalInsuranceNumberDeleted,
  dispatchMissingDetailsNinoSubmitClickedAction: missingDetailsNinoSubmitClickedAction,
  dispatchMissingDetailsMessageMeClickedAction: missingDetailsMessageMeClickedAction,
  dispatchMissingDetailsPageLoaded: missingDetailsPageLoaded,
  dispatchUpdatePersonalDetails: updatePersonalDetails,
  dispatchStatusNotificationMissingDetailsCompleted: statusNotificationMissingDetailsCompleted,
  dispatchMissingDetailsComplete: missingDetailsCompleteAction,
};

const mapStateToProps = (state) => ({
  hasOnlyInProgressContributionInstructions: getHasOnlyInProgressContributionInstruction(state),
  mobile: getPhoneNumber(state),
  nino: getNationalInsuranceNumber(state),
  hasInProgressLumpSumInstructions: getHasInProgressLumpSumInstructions(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NationalInsuranceNumberCard);
