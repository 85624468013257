import React from 'react';
import PropTypes from 'prop-types';

import styles from './AssumptionsModal.css';
import Modal from '../Modal/Modal';
import GlanceCard from '../GlanceCard/GlanceCard';
import GlanceCardContent from '../GlanceCard/GlanceCardContent';

import closeIcon from '../../assets/images/rebranding/cross-icon-in-circle.svg';

function AssumptionsModal({ show, onClose, subHeadings }) {
  return (
    <Modal show={show} backDropClick={onClose}>
      <GlanceCard>
        <GlanceCardContent wrapperStyles={{ height: '90vh' }}>
          <div className={styles.closeWrapper} onClick={() => onClose()} onKeyUp={() => {}} role="button" tabIndex="0">
            <img src={closeIcon} alt="Close icon" />
            {' '}
            <span className={styles.closeWrapperText}>{'Close'}</span>
          </div>
          <h3 className={styles.heading}>{'Our assumptions'}</h3>
          {subHeadings && (
            <div className={styles.yourPensionPlan}>
              {subHeadings.map((subHeading) => {
                return (
                  <React.Fragment>
                    <p className={styles.subHeadingTitle}>{subHeading.title}</p>
                    <p className={styles.subHeadingContent}>{subHeading.content}</p>
                  </React.Fragment>
                );
              })}
            </div>
          )}
          <hr />
          <ul className={styles.unorderedList} style={{ paddingInlineStart: '20px' }}>
            <li className={styles.listItem}>{'The calculator is for illustrative purposes only and figures may be higher or lower than those shown. It should not be regarded as personal advice.'}</li>
            <li className={styles.listItem}>{'The estimated income per year has been calculated using stochastic data to simulate the probability that you would be able to maintain that income for the duration of your retirement taking into account an expected 50th percentile (median) net real return up until your retirement age. This is the expected return for the portfolio you currently hold modelled on current market conditions and adjusted for inflation.'}</li>
            <li className={styles.listItem}>{'Once you reach your expected retirement age the estimated income figure calculation uses an expected net return, which does not take account of inflation, therefore, inflation may impact upon the buying power of the estimated income.'}</li>
            <li className={styles.listItem}>{'The return figure includes assumed charges of 0.82% p.a. to account for platform charges and ongoing advice fees, up until your retirement age. Fund charges will also have an impact on the value of your pension.'}</li>
            <li className={styles.listItem}>{'Once you reach your expected retirement age the estimated income figure calculation just uses fund charges. Assumed fees may be higher or lower than actual fees. After you reach your expected retirement age, fees will impact upon the value of your pension and the income you can take in retirement.'}</li>
            <li className={styles.listItem}>{'Your final pension value and the income available will depend on a number of factors including fund performance, contributions made, charges, inflation, your retirement age, the amount you withdraw from your pensions, tax and mortality rates.'}</li>
            <li className={styles.listItem}>{'As with all investments the value can fall and rise, therefore you may get back less than you invest.'}</li>
            <li className={styles.listItem}>{'Other charges may apply which may affect the value of your pension and the amount of income available.'}</li>
            <li className={styles.listItem}>{'The drawdown time period is calculated using your planned retirement age and life expectancy, based on the latest dataset published by the Office of National Statistics (Expectation of Life, 2020).'}</li>
            <li className={styles.listItem}>{'The estimated income is based on an approximately 85% chance that your pension will not run out before you die.'}</li>
            <li className={styles.listItem}>{'It is assumed that no lump sum withdrawals are taken from the pension and the state pension is not included within the income calculation.'}</li>
            <li className={styles.listItem}>{'The estimated income figure does not take account of tax charges which may apply to withdrawals or to contributions that exceed your allowances. Prevailing tax rates and reliefs are dependent on your individual circumstances and are subject to change.'}</li>
            <li className={styles.listItem}>{'This calculation includes both regular personal and employer contributions where these have been set up. Both contribution types are increased in line with inflation. Personal contributions include the tax relief associated with a basic rate taxpayer. If you are a higher or additional rate taxpayer you may be eligible for higher amounts of tax relief.'}</li>
            <li className={styles.listItem}>{'If any of these assumptions do not apply to you, please contact us.'}</li>
            <li className={styles.listItem}>
              <span>{'Before accessing your pension, it is important to consider all of your options. For more information on the government backed free guidance service visit '}</span>
              <a className={styles.listItemLink} href="https://www.pensionwise.gov.uk" target="_blank" rel="noreferrer">{'pensionwise.gov.uk'}</a>
            </li>
          </ul>
        </GlanceCardContent>
      </GlanceCard>
    </Modal>
  );
}

AssumptionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AssumptionsModal;
