import React from 'react';
import PropTypes from 'prop-types';
import withLinkTo from '../../util/withLinkTo';
import styles from './NavigationSubMenu.css';

const MenuLink = ({ iconOnly, icon, label, link, onClick }) => (
  <li
    key={label}
    className={styles.navItem}
  >
    <div onClick={onClick}>{icon && icon}{!iconOnly && label}</div>
  </li>
);


MenuLink.propTypes = {
  iconOnly: PropTypes.bool,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  onClick: PropTypes.func,
};

MenuLink.defaultProps = {
  iconOnly: false,
  icon: null,
  link: null,
  onClick: null,
  options: [],
};

export default withLinkTo(MenuLink);
