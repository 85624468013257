import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';

import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Button from '../../components/Button/Button';
import CheckBoxGreen from '../../components/CheckBoxGreen/CheckBoxGreen';
import { currencyString } from '../../util/currency';
import captureScreen from '../../util/captureScreenshot';
import sentryException from '../../util/sentryException';
import captureHtml from '../../util/captureHtml';

import WithdrawalReviewDetailsImage from '../../assets/images/withdrawals/plant-book.svg';

import styles from './WithdrawalPage.css';

import {
  backClickedAction,
  withdrawalSubmitStepVisitedAction,
  withdrawalSubmitClickedAction,
  setupWithdrawalAction,
  withdrawalSubmitErrorAction,
  changeBankDetailsClickedAction,
  changeWithdrawalAmountClickedAction,
  importantInformationLinkClickedAction,
  confirmWithdrawalSelectedAction,
  confirmWithdrawalUnselectedAction,
  understandWithdrawalSelectedAction,
  understandWithdrawalUnselectedAction,
  confirmWithdrawalAdviceSelectedAction,
  confirmWithdrawalAdviceUnselectedAction,
} from '../../redux/modules/withdrawal';

import {
  WITHDRAWAL_AMOUNT,
  WITHDRAWAL_ACCOUNT_DETAILS,
} from './flows';

import { getPensionExpert } from '../../redux/selectors/investmentAdvice';
import { getFirebaseUid } from '../../redux/selectors';
import { setNotificationCompletePensionOrContributionRequested } from '../../redux/modules/notificationTracking';

const formFieldsToSubmit = [
  'type',
  'healthIssues',
  'withdrawalType',
  'withdrawalAmount',
  'fundsUsedFor',
  'name',
  'accountNumber',
  'sortCode',
  'rollNo',
  'bankName',
  'confirmWithdrawal',
  'balanceAtRequestTime',
];

const AccountDetails = ({
  step,
  totalSteps,
  setStep,
  setStepWithName,
  formData,
  setFormData,
  dispatchWithdrawalSubmitStepVisited,
  dispatchWithdrawalSubmitClicked,
  dispatchSetupWithdrawal,
  dispatchWithdrawalSubmitError,
  dispatchBackClicked,
  dispatchChangeBankDetailsClicked,
  dispatchChangeWithdrawalAmountButtonClicked,
  dispatchConfirmWithdrawalSelected,
  dispatchConfirmWithdrawalUnselected,
  dispatchUnderstandWithdrawalSelected,
  dispatchUnderstandWithdrawalUnselected,
  dispatchCompletePensionOrContributionRequested,
  dispatchConfirmWithdrawalAdviceSelected,
  dispatchConfirmWithdrawalAdviceUnselected,
  uid,
}) => {
  useEffect(() => {
    dispatchWithdrawalSubmitStepVisited();
  }, []);

  const [submitError, setSubmitError] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const disabled = !formData.confirmWithdrawal
    || !formData.understandWithdrawal
    || !formData.confirmWithdrawalAdvice;

  const onSubmit = debounce(async (e) => {
    try {
      e.preventDefault();
      dispatchWithdrawalSubmitClicked();
      setSubmitError(false);

      let screenshot;
      try {
        screenshot = await captureScreen();
      } catch (error) {
        sentryException(error, {
          section: 'setup-withdrawal-capture-screen',
        });
        throw error;
      }

      const pageHtml = captureHtml();
      const requestSubmittableFields = pick(formData, formFieldsToSubmit);

      const request = {
        ...requestSubmittableFields,
        type: `customer-withdrawal-${requestSubmittableFields.withdrawalType}`,
        screenshot,
        pageHtml,
        ongoing: true,
      };

      if (formData.completeContributionRequestSelected) {
        await dispatchCompletePensionOrContributionRequested(uid, 'contribution');
      }
      if (formData.completePensionRequestSelected) {
        await dispatchCompletePensionOrContributionRequested(uid, 'pension');
      }
      setSubmitInProgress(true);
      await dispatchSetupWithdrawal(request);
      setSubmitInProgress(false);
      setStep((_step) => _step + 1);
    } catch (error) {
      setSubmitError(true);
      setSubmitInProgress(false);
      dispatchWithdrawalSubmitError();
      sentryException(error, {
        section: 'setup-withdrawal-submit',
      });
    }
  }, 3000, { leading: true, trailing: false });

  return (
    <BaseGrid addPensionsTheme>
      <div className={styles.withdrawalsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          onClick={(event) => {
            dispatchBackClicked();
            setStep((_step) => _step - 1);
            event.stopPropagation();
          }}
          label="← Back"
        />
        <div className={styles.withdrawalsImageContainer}>
          <img style={{ maxWidth: '105px' }} src={WithdrawalReviewDetailsImage} alt="debit card" />
        </div>
        <h2 className={styles.headingTwo}>{'Withdrawal confirmation'}</h2>
        <p className={styles.body}>
          {'Please check the following details are correct before you continue.'}
        </p>
        <div className={styles.accountDetailsReviewContainer}>
          <div className={styles.accountDetailsReviewHeading}>{'Account Holder Name'}</div>
          <div className={styles.body}>{formData.name}</div>
          <div className={styles.accountDetailsReviewDivider} />
          <div className={styles.accountDetailsReviewHeading}>{'Sort Code'}</div>
          <div className={styles.body}>{formData.sortCode}</div>
          <div className={styles.accountDetailsReviewDivider} />
          <div className={styles.accountDetailsReviewHeading}>{'Account Number'}</div>
          <div className={styles.body}>{formData.accountNumber}</div>
          <div className={styles.accountDetailsReviewDivider} />
          <div className={styles.accountDetailsReviewHeading}>{'Bank Name'}</div>
          <div className={styles.body}>{formData.bankName}</div>
          {formData.rollNo && (
            <React.Fragment>
              <div className={styles.accountDetailsReviewDivider} />
              <div className={styles.accountDetailsReviewHeading}>{'Roll Number'}</div>
              <div className={styles.body}>{formData.rollNo}</div>
            </React.Fragment>
          )}
          <Button
            variant="secondary"
            size="large"
            type="button"
            onClick={() => {
              dispatchChangeBankDetailsClicked();
              setStepWithName(WITHDRAWAL_ACCOUNT_DETAILS);
            }}
            label="Change bank details"
          />
          <div className={styles.accountDetailsReviewDivider} />
          <div className={styles.accountDetailsReviewHeading}>
            {`${formData.withdrawalType.replace('-', ' ') || ''} WITHDRAWAL`}
          </div>
          <div
            className={styles.accountDetailsWithdrawalAmount}
          >
            {currencyString(formData.withdrawalAmount, true)}
          </div>
          <Button
            variant="secondary"
            size="large"
            onClick={() => {
              dispatchChangeWithdrawalAmountButtonClicked();
              setStepWithName(WITHDRAWAL_AMOUNT);
            }}
            label="Amend withdrawal"
          />
        </div>
        <h3 className={styles.headingThree}>{'Please read and confirm the following:'}</h3>
        <CheckBoxGreen
          name="confirmWithdrawal"
          label={'I confirm that I have read and understood the important considerations before asking you to proceed with withdrawing from my pension.'}
          value={formData.confirmWithdrawal}
          defaultValue={false}
          onChange={() => {
            if (formData.confirmWithdrawal) {
              dispatchConfirmWithdrawalUnselected();
            } else {
              dispatchConfirmWithdrawalSelected();
            }
            setFormData({
              ...formData,
              confirmWithdrawal: !formData.confirmWithdrawal,
            });
          }}
          wrapperStyles={{ marginBottom: '13px' }}
        />
        <div className={styles.divider} />
        <CheckBoxGreen
          name="understandWithdrawal"
          label={'I understand that taking money now will reduce the value of my pension pot which means I will have less money to provide an income at retirement.'}
          value={formData.understandWithdrawal}
          defaultValue={false}
          onChange={() => {
            if (formData.understandWithdrawal) {
              dispatchUnderstandWithdrawalUnselected();
            } else {
              dispatchUnderstandWithdrawalSelected();
            }
            setFormData({
              ...formData,
              understandWithdrawal: !formData.understandWithdrawal,
            });
          }}
          wrapperStyles={{ marginBottom: '13px' }}
        />
        <div className={styles.divider} />
        <CheckBoxGreen
          name="confirmWithdrawalAdvice"
          label={'I confirm that I understand that I have been offered the opportunity to take advice/guidance about this withdrawal before making this decision.'}
          value={formData.confirmWithdrawalAdvice}
          defaultValue={false}
          onChange={() => {
            if (formData.confirmWithdrawalAdvice) {
              dispatchConfirmWithdrawalAdviceUnselected();
            } else {
              dispatchConfirmWithdrawalAdviceSelected();
            }
            setFormData({
              ...formData,
              confirmWithdrawalAdvice: !formData.confirmWithdrawalAdvice,
            });
          }}
          wrapperStyles={{ marginBottom: '13px' }}
        />
        <div className={styles.divider} />
        {submitError && <p className={styles.errorMessage}>{'Something went wrong - please try again'}</p>}
        <Button
          size="large"
          disabled={disabled}
          type="button"
          onClick={onSubmit}
          label="Complete request"
          loading={submitInProgress}
        />
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  pensionAdviser: getPensionExpert(state),
  uid: getFirebaseUid(state),
});

const mapDispatchToProps = {
  dispatchWithdrawalSubmitStepVisited: withdrawalSubmitStepVisitedAction,
  dispatchWithdrawalSubmitClicked: withdrawalSubmitClickedAction,
  dispatchSetupWithdrawal: setupWithdrawalAction,
  dispatchWithdrawalSubmitError: withdrawalSubmitErrorAction,
  dispatchChangeBankDetailsClicked: changeBankDetailsClickedAction,
  dispatchChangeWithdrawalAmountButtonClicked: changeWithdrawalAmountClickedAction,
  dispatchImportantInformationLinkClicked: importantInformationLinkClickedAction,
  dispatchConfirmWithdrawalSelected: confirmWithdrawalSelectedAction,
  dispatchConfirmWithdrawalUnselected: confirmWithdrawalUnselectedAction,
  dispatchUnderstandWithdrawalSelected: understandWithdrawalSelectedAction,
  dispatchUnderstandWithdrawalUnselected: understandWithdrawalUnselectedAction,
  dispatchConfirmWithdrawalAdviceSelected: confirmWithdrawalAdviceSelectedAction,
  dispatchConfirmWithdrawalAdviceUnselected: confirmWithdrawalAdviceUnselectedAction,
  dispatchBackClicked: backClickedAction,
  dispatchCompletePensionOrContributionRequested:
    setNotificationCompletePensionOrContributionRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
