import React from 'react';
import classnames from 'classnames';
import styles from './Paper.css';

const Paper = (props) => {
  const {
    children,
    noPadding,
    wrapperStyles,
    innerStyles,
  } = props;

  return (
    <div
      className={classnames(
        styles.wrapper,
        {
          [styles.noPadding]: noPadding,
        },
      )}
      style={wrapperStyles}
    >
      <div className={styles.blockWrapper} style={innerStyles}>
        {children}
      </div>
    </div>
  );
};

export default Paper;
