/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { withRouter } from 'react-router';
import BaseGrid from '../../components/BaseGrid/BaseGrid';

import CheckBoxGreen from '../../components/CheckBoxGreen/CheckBoxGreen';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import GuaranteesAndExitPenaltiesModal from '../AddPensionsPage/PensionTransferOptions/GuaranteesAndExitPenaltiesModal';
import WithInvestmentAdviceLetter from '../../components/WithDocumentLink/WithInvestmentAdviceLetter';
import { THINGS_TO_CONSIDER_URL } from '../../config';

import { PENSION_TRANSFER_KEY_FILES, transferOptions } from '../../util/constants';
import sentryException from '../../util/sentryException';
import captureScreen from '../../util/captureScreenshot';
import captureHtml from '../../util/captureHtml';

import { getSerpsPensions } from '../../redux/selectors/pensions';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import { serpsAuthorityScreenLanded } from '../../redux/modules/serps';
import { getSelectedSerpsToAuthorise } from '../../redux/selectors/serps';

import {
  updatePensionsAction,
  onGuaranteesAndExitPenaltiesModalShow,
} from '../../redux/modules/pensions';
import { getFirebaseUid } from '../../redux/selectors';

import CombinePensions from '../../assets/images/combine-pensions.svg';
import Tick from '../../assets/images/icons/smallGreenTick.svg';

import styles from './SerpsAuthorisePensions.css';

const SerpsAuthorisePensions = ({
  investmentAdviceLetterUrl,
  serpsPensions,
  dispatchUpdateSerpsPension,
  isCustomerOngoing,
  dispatchOnGuaranteesAndExitPenaltiesModalClicked,
  dispatchPensionAuthorityScreenLanded,
  selectedSerpsToAuthorise,
  setStep,
}) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [authPensionPlan, setAuthPensionPlan] = useState(false);
  const [authPensionPlanTAndC, setAuthPensionPlanTAndC] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const pensionsSelectedToBeAuthorised = serpsPensions.filter((item) => selectedSerpsToAuthorise.includes(item._id));

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchPensionAuthorityScreenLanded();
  }, []);

  const isDisabled = ![
    authPensionPlanTAndC,
    authPensionPlan,
  ].every((v) => v === true);

  const onSubmit = async () => {
    try {
      setShowSubmitError(false);
      setIsSubmitLoading(true);

      let screenshot;
      try {
        screenshot = await captureScreen();
      } catch (error) {
        sentryException(error, {
          section: 'update-pension-capture-screen',
        });
        throw error;
      }
      const pageHtml = captureHtml();

      const pensions = pensionsSelectedToBeAuthorised.map((item) => {
        return {
          _id: item._id,
          transferOption: transferOptions.TRANSFER_WITH_CHECK,
          authoriseTransferOptionTransferBasic: false,
          authoriseTransferOptionTransferWithCheck: true,
          authoriseTransferBalanceOfPension: true,
          authoriseImplementPensionPlan: true,
        };
      });

      await dispatchUpdateSerpsPension({
        authoriseImplementPensionPlan: true,
        pensions,
        screenshot,
        pageHtml,
        transferOption: transferOptions.TRANSFER_WITH_CHECK,
        isCustomerOngoing,
        topup: isCustomerOngoing,
      });

      setIsSubmitLoading(false);
      setStep((step) => step + 1);
    } catch (error) {
      setShowSubmitError(true);
      setIsSubmitLoading(false);
    }
  };

  const debouncedOnSubmit = debounce(onSubmit, 3000, { leading: true, trailing: false });

  return (
    <BaseGrid tabletTwelveColumn>
      <div className={styles.serpsAuthPensionsWrapper}>
        <div className={styles.serpsAuthPensionsContainer}>
          <ButtonLink
            secondary
            mid
            back
            label="← Back"
            wrapperStyles={{
              margin: '0 0 19px',
              fontFamily: 'Poppins',
              fontSize: '16px',
            }}
          />
          <div className={styles.headingImages}>
            <img src={CombinePensions} className={styles.treeImage} alt={'Tree'} />
          </div>
          <div className={styles.serpsAuthHeading}>
            {'Transfer these pensions to your pension plan'}
          </div>
          <div className={styles.paragraph}>
            {'HMRC have one or more SERPS pensions on record and we have some basic information. The next step is to use our Find, Check & Transfer service. We’ll contact the providers to find out more information and help you gain access to your hard earned cash.'}
          </div>
          <div className={styles.paragraph}>
            {'Our Find, Check & Transfer service gives you peace of mind and means we do all the hard work for you. We will:'}
          </div>
          <div className={styles.findCheckAndTransferContainer}>
            <div className={styles.flexComp}>
              <div className={styles.tickImg}><img src={Tick} alt={'Tick'} /></div>
              <div>
                {'Find pension and detailed policy information'}
              </div>
            </div>
            <div className={styles.flexComp}>
              <div className={styles.tickImg}><img src={Tick} alt={'Tick'} /></div>
              <div>
                {'Check for guarantees and exit penalties '}
                <ButtonLink
                  variant="primary"
                  onClick={() => {
                    dispatchOnGuaranteesAndExitPenaltiesModalClicked(isCustomerOngoing);
                    setShowModal(true);
                  }}
                  label="(read more)"
                  font="inherit"
                  displayAsHref
                />
              </div>
            </div>
            <div className={styles.flexComp}>
              <div className={styles.tickImg}><img src={Tick} alt={'Tick'} /></div>
              <div>{'Arrange the transfer of your old pension into your new personalised pension plan'}</div>
            </div>
          </div>
          <div className={styles.miniHeading}>
            {'Suitable if'}
          </div>
          <div className={styles.paragraph}>
            {'You would like to transfer your old pension with expert guidance and checks or if you are not confident about the details of your existing pension policies.'}
          </div>
          <div className={styles.miniHeading}>
            {'Cost'}
          </div>
          <div className={styles.paragraph}>
            {'1% arrangement fee for the Find, Check & Transfer service, taken from the pension at point of transfer.'}
          </div>
          <div className={styles.importantConsiderations}>
            <ButtonLink
              label={'Important considerations'}
              href={THINGS_TO_CONSIDER_URL}
              displayAsHref
              font="inherit"
            />
          </div>
          {pensionsSelectedToBeAuthorised.map((item) => (
            <div className={styles.cardItem} key={item._id}>
              <div className={styles.pensionCardHeading}>
                {'HMRC SERPS Pension'}
              </div>
              <div className={styles.providerNameLabel}>
                {'Provider name'}
              </div>
              <div className={styles.providerName}>
                {item.policyName}
              </div>
            </div>
          ))}
          <div className={styles.cardItem}>
            <div className={styles.termsCardHeading}>
              {'Please confirm you have read the following:'}
            </div>
            <CheckBoxGreen
              name="authoriseImplementPensionPlan"
              disabled={false}
              label={(
                <span>
                  {'I authorise Profile Pensions to implement my Pension Plan. I have read and understand the '}
                  <ButtonLink
                    label={'Recommendation'}
                    href={investmentAdviceLetterUrl}
                    displayAsHref
                    font="inherit"
                  />
                  {', '}
                  <ButtonLink
                    label={'Key Features'}
                    href={PENSION_TRANSFER_KEY_FILES.keyFeatures}
                    displayAsHref
                    font="inherit"
                  />
                  {' and '}
                  <ButtonLink
                    label={'Illustration'}
                    href={PENSION_TRANSFER_KEY_FILES.illustration}
                    displayAsHref
                    font="inherit"
                  />
                  {'.'}
                </span>
              )}
              value={authPensionPlan}
              onChange={() => {
                setAuthPensionPlan(!authPensionPlan);
              }}
              wrapperStyles={{ marginTop: '20px' }}
            />
            <div className={styles.divider} />
            <CheckBoxGreen
              name="authoriseTransferBalanceOfPension"
              disabled={false}
              label={(
                <span>
                  {'I authorise Profile Pensions to transfer the balance of this pension to my Profile Pensions Pension Plan. I understand the decision to transfer existing pensions is my own and I have read and understood the '}
                  <ButtonLink
                    label={'Important Considerations'}
                    href={THINGS_TO_CONSIDER_URL}
                    font="inherit"
                    displayAsHref
                  />
                  {' regarding guarantees and exit penalties and have received, understood and agree to the '}
                  <ButtonLink
                    label={'Terms of Business'}
                    href={PENSION_TRANSFER_KEY_FILES.termsOfBusiness}
                    displayAsHref
                    font="inherit"
                  />
                  {'.'}
                </span>
              )}
              value={authPensionPlanTAndC}
              onChange={() => {
                setAuthPensionPlanTAndC(!authPensionPlanTAndC);
              }}
              wrapperStyles={{ marginTop: '20px' }}
            />
          </div>
          {showModal && (
            <GuaranteesAndExitPenaltiesModal
              show={showModal}
              onClose={() => {
                document.body.style.overflowY = 'auto';
                setShowModal(false);
              }}
            />
          )}
          <div className={styles.submitContainer}>
            {showSubmitError && (
              <div
                className={styles.error}
              >
                {'Something went wrong - please try again'}
              </div>
            )}
            <Button
              wrapperStyles={{ margin: 0 }}
              label="Submit"
              disabled={isDisabled}
              loading={isSubmitLoading}
              onClick={debouncedOnSubmit}
              center
              greyDisabled
              fullWidth
            />
          </div>
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
  serpsPensions: getSerpsPensions(state),
  isCustomerOngoing: getIsCustomerOngoing(state),
  selectedSerpsToAuthorise: getSelectedSerpsToAuthorise(state),
});

const mapDispatchToProps = {
  dispatchUpdateSerpsPension: updatePensionsAction,
  dispatchOnGuaranteesAndExitPenaltiesModalClicked: onGuaranteesAndExitPenaltiesModalShow,
  dispatchPensionAuthorityScreenLanded: serpsAuthorityScreenLanded,
};

const SerpsAuthorisePensionsWithLetter = (props) => (
  <WithInvestmentAdviceLetter>
    {(investmentAdviceLetterUrl) => <SerpsAuthorisePensions {...{ ...props, investmentAdviceLetterUrl }} />}
  </WithInvestmentAdviceLetter>
);

const SerpsAuthorisePensionsWithLetterPage = connect(mapStateToProps, mapDispatchToProps)(withRouter(SerpsAuthorisePensionsWithLetter));

export default SerpsAuthorisePensionsWithLetterPage;
