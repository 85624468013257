import React from 'react';
import classnames from 'classnames';

import styles from './DotNavigation.css';

const DotNavigation = ({
  dotCount,
  dotStep,
  onClick,
  whiteDefaultDot,
  grassActiveDot,
  limeActiveDot,
}) => {
  if (!dotCount || dotStep === undefined) {
    return null;
  }

  return (
    <div className={styles.dotNavigationContainer}>
      {Array.from({ length: dotCount }, (_, i) => {
        const active = dotStep === i;
        return (
          <button
            className={
                  classnames(styles.dot,
                    {
                      [styles.whiteDefaultDot]: whiteDefaultDot,
                      [styles.grassActiveDot]: active && grassActiveDot,
                      [styles.limeActiveDot]: active && limeActiveDot,
                    })
                }
            key={i}
            type="button"
            aria-label="dot navigation"
            onClick={() => {
              if (onClick) {
                onClick(i);
              }
            }}
          />
        );
      })}
    </div>
  );
};

export default DotNavigation;
