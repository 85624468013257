export const atrDescriptions = {
  adventurousAtrDescription: 'People in this category have a good understanding of investment risk. They are generally experienced investors. They aim for higher long-term returns and understand that this can also mean some sustained periods of poorer performance. They are prepared to accept significant fluctuations in value to try and achieve better long-term returns.',
  growthAtrDescription: 'People in this category are relatively comfortable with investment risk. They aim for higher long-term returns and understand that this can also mean some sustained periods of poorer performance. They are prepared to accept significant fluctuations in value to try and achieve better long-term returns.',
  balancedAtrDescription: 'People in this category are balanced in their attitude towards risk. They don’t seek risky investments but they don’t avoid them either. They are prepared to accept fluctuations in the value of their investments to try and achieve better long term returns. These portfolios will be subject to frequent and at times significant fluctuations in value.',
  cautiousAtrDescription: 'People in this category are relatively cautious with their investments. They want to try to achieve a reasonable return and are prepared to accept some risk in doing so. Typically these portfolios will exhibit relatively modest yet frequent fluctuations in value.',
  conservativeAtrDescription: 'Based on your attitude to risk and the information you have provided, we recommend a conservative portfolio as being the most suitable investment option for you. As a conservative investor, we recommend a high allocation to global equities aiming to maximise returns and a small allocation to bonds to control risk.',
};

export const atrDescriptionsMSM = {
  adventurousAtrDescription: 'You want to maximise your long term growth prospects. You understand the risk and return balance that comes with investing and you may already be an experienced investor. You are aiming to achieve the highest returns to put yourself in the best position for retirement, and are prepared to accept significant fluctuations in value to try and achieve this.',
  growthAtrDescription: 'This means you are relatively comfortable with taking investment risk and are aiming for higher long-term returns. You are willing to accept fluctuations in value and some sustained periods of poor performance to try and achieve these high returns.',
  balancedAtrDescription: 'You don’t seek out risk nor are you averse to it. You understand that as with most things, risk and reward come hand in hand. Therefore, you are willing to take a measured level of risk, and accept fluctuations in the value of your investments, for the opportunity of better long-term returns.',
  cautiousAtrDescription: 'You are aiming to protect your assets, preserve your purchasing power, and you are willing to accept a limited amount of risk to achieve a reasonable return. Typically when investing this way you will see modest fluctuations in value.',
  conservativeAtrDescription: 'You have a more careful approach to investing and would only like to take a small amount of risk as you are more concerned about protecting your assets. You do accept that there may be some short-term periods of fluctuation in value to achieve modest or relatively stable returns.',
};
