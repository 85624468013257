import classnames from 'classnames';
import React from 'react';
import styles from './MessageCard.css';

const MessageCard = ({ dataCy = '', children, top }) => {
  const cardStyles = classnames(styles.wrapper, {
    [styles.top]: top,
  });

  return (
    <div data-cy={dataCy} className={cardStyles}>{children}</div>
  );
};

export default MessageCard;
