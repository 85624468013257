import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import Button from '../Button/Button';
import PensionRows from '../DashboardRow/PensionRows';
import { getPensions } from '../../redux/selectors/pensions';
import { getHasInProgressContributionInstruction, getHasInProgressLumpSumInstructions } from '../../redux/selectors/investmentAdvice';
import threeCircles from '../../assets/images/rebranding/tri-tone-overlapping-circles.svg';
import styles from './AddPensionCard.css';

const AddPensionCard = ({
  title,
  pensions,
  hasInProgressInstructions,
  hasInProgressLumpSumInstructions,
  showExistingPensions,
  history,
  fullWidth,
  fullHeight,
  onClick,
  retirementIncomeCalculator,
  imageStyles,
}) => {
  const hasPensions = Boolean(pensions && pensions.length);
  const hasPensionOrInstruction = hasPensions
    || hasInProgressInstructions
    || hasInProgressLumpSumInstructions;

  return (
    <div
      className={
        classNames(
          styles.addPensionCard,
          {
            [styles.singleColumn]: hasPensionOrInstruction,
            [styles.retirementIncomeCalculator]: retirementIncomeCalculator,
            [styles.fullHeight]: fullHeight,
          },
        )
      }
    >
      <div
        className={
          classNames(
            styles.actionContainer,
          )
        }
      >
        <div
          className={
            classNames(
              styles.actionIconContainer,
              {
                [styles.alignUnder]: retirementIncomeCalculator && !fullWidth,
                [styles.alignLeft]: fullWidth,
              },
            )
          }
        >
          <img alt="Three circles" src={threeCircles} className={styles.threeCircles} style={imageStyles} />
          <h4 className={styles.pensionsHeading}>
            {title || (
              <React.Fragment>
                <span className={styles.findTransferMobile}>{'Find & combine '}</span>
                <span className={styles.findTransfer}>{'Find and combine '}</span>
                <br className={styles.findOrTransferBreak} />
                {'old pensions'}
              </React.Fragment>
            )}
          </h4>
        </div>
        <div className={classNames({ [styles.smallButtonWrapper]: fullWidth })}>
          <Button
            label="+ Pensions"
            size="large"
            to={'/add-pensions'}
            onClick={onClick}
          />
        </div>
        {showExistingPensions && hasPensions && (
          <React.Fragment>
            <h5 className={styles.pensionsHeader}>{'Pensions'}</h5>
            <PensionRows />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pensions: getPensions(state),
  hasInProgressInstructions: getHasInProgressContributionInstruction(state),
  hasInProgressLumpSumInstructions: getHasInProgressLumpSumInstructions(state),

});

AddPensionCard.propTypes = {
  showExistingPensions: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

AddPensionCard.defaultProps = {
  showExistingPensions: true,
  fullWidth: false,
};

export default connect(mapStateToProps)(withRouter(AddPensionCard));
