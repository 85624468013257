import React from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router';
import get from 'lodash/get';
import sentryException from '../../util/sentryException';
import {
  passwordResetLink, queryPasswordResetRef, passwordReset, resendSMS, passwordResetVerifySms,
} from '../../util/auth';
import JourneyLayout from '../../components/JourneyLayout/JourneyLayout';
import DefaultJourneyLayoutSidebar from '../../components/JourneyLayout/DefaultJourneyLayoutSidebar';
import PasswordJourneyLayoutSidebar from '../../components/JourneyLayout/PasswordJourneyLayoutSidebar';
import ForgotPasswordResetEmail from '../../forms/ForgotPasswordResetEmail';
import ForgotPasswordResetEmailSuccess from '../../forms/ForgotPasswordResetEmailSuccess';
import ForgotPasswordReset from '../../forms/ForgotPasswordReset';
import getRecaptchaToken from '../../util/getRecaptchaToken';
import LoginStep2 from '../../forms/LoginStep2';
import MessageCard from '../../components/MessageCard/MessageCard';
import Loader from '../../components/Loader/Loader';
import WithIntercom from '../../components/WithIntercom/WithIntercom';
import { getVerifySmsErrorMessage } from '../../util/common';

class ForgotPasswordController extends React.Component {
  constructor(props) {
    super(props);
    this.sendResetEmail = this.sendResetEmail.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.resetFlowState = this.resetFlowState.bind(this);

    this.handleResendSMS = this.handleResendSMS.bind(this);
    this.handleVerifySMS = this.handleVerifySMS.bind(this);

    this.initialState = {
      step: 0,
      ref: null,
      refEmail: null,
      refError: null,
      notificationMessage: null,
      uid: null,
      authToken: null,
      pwd: '',
      isLoading: false,
    };
    this.state = {
      ...this.initialState,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.search) {
      const querys = queryString.parse(location.search);
      if (querys.ref) {
        this.setState({ isLoading: true });
        queryPasswordResetRef(querys.ref)
          .then(({ email }) => {
            this.setState({
              ref: querys.ref, refEmail: email, step: 2, isLoading: false,
            });
          })
          .catch((err) => this.setState({ refError: err.response.error, step: 2, isLoading: false }));
      }
    }
  }

  resetFlowState() {
    this.setState({
      ...this.initialState,
    });
  }

  async sendResetEmail({ email }, { setSubmitting, setStatus }) {
    if (!email) {
      setSubmitting(false);
      return setStatus({
        formError: 'Email address can’t be empty',
      });
    }
    try {
      const token = await getRecaptchaToken('password_reset');
      await passwordResetLink(email, token);
      setSubmitting(false);
      setStatus({ formError: null });
      this.setState({ step: 1 });
    } catch (err) {
      if (err.response && err.response.error) {
        setStatus({ formError: 'Oops, something went wrong. Could not reset password' });
        sentryException(err);
      }
    }
  }

  resetPassword({ pwd }, { setSubmitting, setStatus }) {
    const { ref } = this.state;
    this.setState({ pwd });

    passwordReset(pwd, ref)
      .then(({ twoFactorRequired, uid }) => {
        setSubmitting(false);
        setStatus({ formError: null });

        const nextStep = twoFactorRequired ? 3 : 4;
        this.setState({ step: nextStep, uid });
      })
      .catch((err) => {
        setSubmitting(false);
        setStatus({ formError: 'Oops, something went wrong. Could not reset password' });
        sentryException(err, {
          section: 'forgot-password-controller-reset-password',
        });
      });
  }

  async handleVerifySMS(values, { setSubmitting, setStatus }) {
    const { uid, pwd } = this.state;
    const { smsCode } = values;

    setStatus({ formError: null });
    this.setState({ notificationMessage: null });

    if (!uid || !smsCode) {
      setStatus({ formError: 'Could not verify SMS.' });
    } else {
      try {
        const recaptchaToken = await getRecaptchaToken('password_reset_verify_sms');
        await passwordResetVerifySms(uid, smsCode, pwd, recaptchaToken);
        setSubmitting(false);
        setStatus({ formError: null });
        this.setState({ step: 4 });
      } catch (e) {
        setSubmitting(false);
        setStatus({ formError: getVerifySmsErrorMessage(get(e, 'response.error')) });
      }
    }
  }

  async handleResendSMS(setStatus) {
    const { uid } = this.state;
    setStatus({ formError: null });

    if (!uid) {
      setStatus({ formError: 'Could not resend SMS.' });
      this.setState({ notificationMessage: null });
    } else {
      try {
        const token = await getRecaptchaToken('password_reset_resend_sms');
        await resendSMS(uid, token);
        setStatus({ formError: null });
        this.setState({ notificationMessage: 'We\'ve sent you a new verification code, please disregard any old codes.' });
      } catch (e) {
        setStatus({ formError: 'Could not resend SMS.' });
        this.setState({ notificationMessage: null });
      }
    }
  }

  render() {
    const {
      step, refEmail, refError, notificationMessage, isLoading,
    } = this.state;
    let sidebar = <DefaultJourneyLayoutSidebar />;

    if (step === 2) {
      sidebar = <PasswordJourneyLayoutSidebar />;
    }

    return (
      <WithIntercom>
        <JourneyLayout sidebar={sidebar}>
          {notificationMessage && <MessageCard>{notificationMessage}</MessageCard>}

          {isLoading ? (
            <Loader
              wrapperStyles={{ margin: '50% auto' }}
            />
          ) : (
            <React.Fragment>
              { step === 0
              && <ForgotPasswordResetEmail onSubmit={this.sendResetEmail} /> }
              { step === 1
                && <ForgotPasswordResetEmailSuccess /> }
              { step === 2 && (
              <ForgotPasswordReset
                onSubmit={this.resetPassword}
                initialValues={{ email: refEmail }}
                refError={refError}
                resetFlowState={this.resetFlowState}
              />
              )}
              { step === 3 && (
                <React.Fragment>
                  <LoginStep2
                    onSubmit={this.handleVerifySMS}
                    onResendSMS={this.handleResendSMS}
                  />
                </React.Fragment>
              )}
              { step === 4 && <Redirect to="/" /> }
            </React.Fragment>
          )}
        </JourneyLayout>
      </WithIntercom>
    );
  }
}

export default ForgotPasswordController;
