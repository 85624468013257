import React from 'react';
import { connect } from 'react-redux';

import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { getPensionsReadyToAuthorise } from '../../redux/selectors/pensions';
import { getIsCustomerOnboarding } from '../../redux/selectors/experience';

const PensionsReadyToAuthoriseNotification = ({
  pensionsReadyToAuthorise,
  isCustomerOnboarding,
}) => {
  const singlePensionText = 'You have a pension transfer ready to authorise';
  const multiplePensionsText = 'Your pension transfers are ready to authorise';
  const listedSectionText = 'listed under the “Find and combine old pensions” section.';
  const planActivityText = 'go to your plan activity to authorise the transfer';
  let title = singlePensionText;
  let description = isCustomerOnboarding
    ? `${singlePensionText}. It is ${listedSectionText}`
    : `${singlePensionText}, ${planActivityText}.`;
  let ctaLabel = 'Transfer pension';

  if (pensionsReadyToAuthorise.length > 1) {
    title = 'You have pension transfers ready to authorise';
    description = isCustomerOnboarding
      ? `${multiplePensionsText}. These are ${listedSectionText}`
      : `${multiplePensionsText}, ${planActivityText}s.`;
    ctaLabel = 'Transfer pensions';
  }

  return (
    <NotificationBanner
      alertCategory={'Blue'}
      alertTitle={title}
      expandableAlertDescription={description}
       // if scroll to a section is required, use: /plan-activity/#pensionTransfers
      ctaButtonOptions={isCustomerOnboarding ? null : {
        label: ctaLabel,
        link: '/plan-activity',
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  pensionsReadyToAuthorise: getPensionsReadyToAuthorise(state),
  isCustomerOnboarding: getIsCustomerOnboarding(state),
});

export default connect(mapStateToProps)(PensionsReadyToAuthoriseNotification);
