import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './ProgressIndicator.css';

const ProgressIndicator = (props) => {
  const { value, wrapperStyles, grass } = props;

  const className = classnames(styles.loader, {
    [styles.grass]: grass,
  });

  return (
    <div className={styles.wrapper} style={wrapperStyles}>
      <div className={className} style={{ width: `${value}%` }} />
    </div>
  );
};

ProgressIndicator.propTypes = {
  value: PropTypes.number,
};

ProgressIndicator.defaultProps = {
  value: 0,
};

export default ProgressIndicator;
