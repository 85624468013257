import React from 'react';
import { Formik, Field } from 'formik';
import { renderTextField } from '../util/formik-custom-fields';
import FormError from '../components/FormError/FormError';
import Button from '../components/Button/Button';
import ButtonLink from '../components/ButtonLink/ButtonLink';
import ShowablePasswordField from '../components/ShowablePasswordField/ShowablePasswordField';

const LoginStep1 = ({ onSubmit }) => (
  <Formik
    onSubmit={onSubmit}
    render={({
      handleSubmit,
      isSubmitting,
      status,
      setFieldTouched,
      handleChange,
    }) => (
      <form onSubmit={handleSubmit}>
        <Field
          name="email"
          type="email"
          component={renderTextField}
          label="Email address"
          defaultValue="Enter email address"
        />
        <br />
        <ShowablePasswordField
          name="password"
          label="Password"
          defaultValue="Enter password"
          onChange={(e) => {
            setFieldTouched('password');
            handleChange(e);
          }}
        />
        <FormError error={status && status.formError} />
        <ButtonLink label="Reset your password" to="/forgot-password" font="bodyTwo" variant="primary" />
        <br />
        <br />
        <br />
        <Button size="large" dataSentry="login/login" type="submit" label="Login" loading={isSubmitting} disabled={isSubmitting} />
      </form>
    )}
  />
);

export default LoginStep1;
