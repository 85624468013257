import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import BaseLayout from '../BaseLayout/BaseLayout';

import styles from './BaseGrid.css';

const BaseGrid = ({
  children,
  footerContent,
  showFooter,
  showAlerts,
  heading,
  initialSignupTheme,
  inboxTheme,
  greyTheme,
  forestTheme,
  tabletTwelveColumn,
  pageSectionStyles,
  messageWidget,
  addPensionsTheme,
  showHeader,
  showMsmTile,
  noPaddedWrapper,
  showNavMenu = true,
}) => {
  return (
    <Fragment>
      <BaseLayout
        heading={heading}
        showHeader={showHeader}
        showAlerts={showAlerts}
        initialSignupTheme={initialSignupTheme}
        inboxTheme={inboxTheme}
        addPensionsTheme={addPensionsTheme}
        footerContent={footerContent}
        showFooter={showFooter}
        messageWidget={messageWidget}
        showMsmTile={showMsmTile}
        showNavMenu={showNavMenu}
      >
        <div className={
          classnames(
            {
              [styles.basePageSection]: !inboxTheme,
              [styles.inboxThemeSection]: inboxTheme,
              [styles.greyThemeSection]: greyTheme,
              [styles.forestThemeSection]: forestTheme,
            },
          )
        }
        >
          <div
            style={pageSectionStyles}
            className={
            classnames(
              {
                [styles.basePageSectionWrapper]: !inboxTheme,
                [styles.initialSignupTheme]: initialSignupTheme,
                [styles.addPensionsTheme]: addPensionsTheme,
                [styles.tabletTwelveColumn]: tabletTwelveColumn,
                [styles.inboxThemeWrapper]: inboxTheme,
                [styles.greyThemeWrapper]: greyTheme,
                [styles.noPaddedWrapper]: noPaddedWrapper,
              },
            )
          }
          >
            {children}
          </div>
        </div>
      </BaseLayout>
    </Fragment>
  );
};

BaseGrid.propTypes = {
  footerContent: PropTypes.element,
  showFooter: PropTypes.bool,
  showAlerts: PropTypes.bool,
  initialSignupTheme: PropTypes.bool,
  pageSectionStyles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  addPensionsTheme: PropTypes.bool,
  showHeader: PropTypes.bool,
};

BaseGrid.defaultProps = {
  footerContent: null,
  showFooter: true,
  showAlerts: false,
  initialSignupTheme: false,
  pageSectionStyles: {},
  addPensionsTheme: false,
  showHeader: true,
};

export default BaseGrid;
