import React from 'react';
import { connect } from 'react-redux';
import { FBLogout } from '../../redux/modules/auth';

class LogoutPage extends React.Component {
  componentDidMount() {
    const { logout } = this.props;
    logout();
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = ({
  logout: FBLogout,
});

export default connect(null, mapDispatchToProps)(LogoutPage);
