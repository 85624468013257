import React, { useState } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/get';

import fundMap from '../../../components/FundList/fundMap';
import PlanNameBox from '../../../components/PlanNameBox/PlanNameBox';
import { getIVUCollection, getAuthoritativeRetirementPlan } from '../../../redux/selectors';
import { getPensions } from '../../../redux/selectors/pensions';
import getFundsWithRoundedSplits from '../../../util/getFundsWithRoundedSplits';

import smallGreenTick from '../../../assets/images/icons/smallGreenTick.svg';
import aegonLogo from '../../../assets/images/providers/aegon.svg';
import avivaLogo from '../../../assets/images/providers/aviva.svg';
import lvLogo from '../../../assets/images/providers/lv.png';
import metlifeLogo from '../../../assets/images/providers/metlife.svg';
import prudentialLogo from '../../../assets/images/providers/prudential.svg';
import scottishWidowsLogo from '../../../assets/images/providers/scottish-widows.svg';
import arrowDown from '../../../assets/images/rebranding/triangle-downward.svg';
import arrowUp from '../../../assets/images/icons/icon_up_arrow_forest.svg';

import styles from './PlanActivityCurrentPlan.css';

const getProfilePortfolio = (ivuCollection, portfolioReference, mappedFunds) => {
  const profilePortfolio = {
    planName: 'Mixed Plan',
    esg: undefined,
    styleName: 'Generic',
  };

  const recentPortfolio = ivuCollection.find((p) => portfolioReference && portfolioReference === get(p, 'reference'));
  if (recentPortfolio) {
    profilePortfolio.planName = recentPortfolio.displayName;
    profilePortfolio.esg = recentPortfolio.environmentalSocialGovernance;
    profilePortfolio.styleName = recentPortfolio.displayName;
    return profilePortfolio;
  }
  const legacyPortfolio = ivuCollection.find((p) => get(p.funds, '0.isin') === get(mappedFunds, '0.isin'));
  if (mappedFunds.length === 1 && legacyPortfolio) {
    profilePortfolio.planName = legacyPortfolio.displayName;
    profilePortfolio.styleName = legacyPortfolio.displayName;
  }

  return profilePortfolio;
};

const PROVIDER_MAP = {
  aegon: { label: 'Aegon', logo: aegonLogo },
  aviva: { label: 'Aviva', logo: avivaLogo },
  lv: { label: 'LV', logo: lvLogo },
  metlife: { label: 'MetLife', logo: metlifeLogo },
  prudential: { label: 'Prudential', logo: prudentialLogo },
  'scottish-widows': { label: 'Scottish Widows', logo: scottishWidowsLogo },
};

const PlanActivityCurrentPlan = ({
  ivuCollection,
  retirementPlan,
  hasPreviouslyViewedPlanActivity,
}) => {
  const [showDetails, setShowDetails] = useState(!hasPreviouslyViewedPlanActivity);

  const platformName = get(retirementPlan, 'provider');
  const portfolioReference = get(retirementPlan, 'investmentVehicleReference');
  const retirementPlanFunds = get(retirementPlan, 'funds', []);
  const funds = getFundsWithRoundedSplits(retirementPlanFunds);
  const totalFeesPercent = get(retirementPlan, 'totalFeesPercent');

  const { label: providerLabel, logo: providerLogo } = PROVIDER_MAP[platformName.toLowerCase()];

  const mappedFunds = funds.filter((fund) => fund.name !== 'Cash').map((fund) => {
    const fundData = fundMap[fund.isin];
    if (!fundData) return;
    return { name: fundData.name, fundSplit: get(fund, 'fundRoundedSplit'), isin: fund.isin };
  });

  if (mappedFunds.length === 1 && mappedFunds[0]) mappedFunds[0].fundSplit = 100;

  const canShowFundSection = mappedFunds
    && mappedFunds.length > 0
    && mappedFunds.every((fund) => get(fund, 'fundSplit') && get(fund, 'name'));

  const {
    planName,
    esg,
    styleName,
  } = getProfilePortfolio(ivuCollection, portfolioReference, mappedFunds);

  return (
    <div className={styles.currentPlanContainer}>
      <div className={styles.titleRow}>
        <h4 className={styles.pensionPlanHeading}>{'Your pension plan'}</h4>
        <button className={styles.hideShowPlanDetails} onClick={() => setShowDetails(!showDetails)} type="button">
          <span>{`${showDetails ? 'Hide details' : 'Show details'}`}</span>
          <img src={showDetails ? arrowUp : arrowDown} alt="arrow" />
        </button>
      </div>
      {planName && (
        <PlanNameBox planName={planName} esg={esg} styleName={styleName} context="post-cds" />
      )}
      <div className={classnames({
        [styles.hidePlanInfoContainer]: hasPreviouslyViewedPlanActivity && !showDetails,
      })}
      >
        <div className={styles.tickTextContainer}>
          <img src={smallGreenTick} alt="Green tick" className={styles.tick} />
          <div className={styles.tickText}>{'Personalised plan updated regularly'}</div>
        </div>
        <div className={classnames(styles.tickTextContainer, styles.last)}>
          <img src={smallGreenTick} alt="Green tick" className={styles.tick} />
          <div className={styles.tickText}>{'Selected from the whole of the market'}</div>
        </div>
        <div className={styles.divider} />
        {
          totalFeesPercent && (
            <div className={styles.planInfoContainer}>
              <div className={styles.platformHeader}>{'Platform'}</div>
              <div className={styles.feeHeader}>{'All-inclusive annual fee with ongoing advice'}</div>
              <div className={styles.vertDivider} />
              <div className={styles.providerContainer}>
                <img className={styles.providerLogo} src={providerLogo} alt={`${providerLabel} logo`} />
                <div className={styles.providerLabel}>{providerLabel}</div>
              </div>
              <div className={styles.annualCharge}>{`${totalFeesPercent}%`}</div>
            </div>
          )
        }
        {canShowFundSection && (
          <React.Fragment>
            <div className={styles.bottomDivider} />
            <h6 className={styles.fundsHeading}>{'Funds'}</h6>
            {mappedFunds.map(({ name, fundSplit }) => (
              <div className={styles.planFundRow} key={name}>
                <p className="body-one">{name}</p>
                <p className="body-one">{`${fundSplit}%`}</p>
              </div>
            ))}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ivuCollection: getIVUCollection(state),
  retirementPlan: getAuthoritativeRetirementPlan(state),
  pensions: getPensions(state),
});

export default connect(mapStateToProps)(PlanActivityCurrentPlan);
