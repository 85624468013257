import React from 'react';

import Modal from '../../../components/Modal/Modal';
import GlanceCard from '../../../components/GlanceCard/GlanceCard';
import GlanceCardContent from '../../../components/GlanceCard/GlanceCardContent';

import closeIcon from '../../../assets/images/rebranding/cross-icon-in-circle.svg';

import styles from './GuaranteesAndExitPenaltiesModal.css';
import { THINGS_TO_CONSIDER_URL } from '../../../config';

const GuaranteesAndExitPenaltiesModal = ({ show, onClose }) => {
  return (
    <Modal
      show={show}
      backDropClick={onClose}
    >
      <GlanceCard>
        <GlanceCardContent>
          <div className={styles.closeButtonContainer}>
            <button type="button" onClick={onClose} className={styles.closeButton}>
              <img src={closeIcon} alt="Close modal" />
              <span className={styles.closeButtonText}>{'Close'}</span>
            </button>
          </div>
          <h3 className={styles.heading}>{'Guarantees and exit penalties'}</h3>
          <hr />
          <p className={styles.bodyText}>
            {'Some pensions come with guarantees, such as a guaranteed annuity rate or a guaranteed growth rate, and some charge a penalty to exit your pension. Please see the '}
            <a
              href={THINGS_TO_CONSIDER_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              {'Important Considerations page'}
            </a>
            {' to see what’s included in the checks.'}
            <br />
            {'Although we will endeavour to identify any guarantees, or exit fees of more than £50 with our Find, Check & Transfer Service, we cannot guarantee to find all exit fees and guarantees and we do not check certain policies considered very low risk.'}
          </p>
        </GlanceCardContent>
      </GlanceCard>
    </Modal>
  );
};

export default GuaranteesAndExitPenaltiesModal;
