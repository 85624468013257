import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import zxcvbn from 'zxcvbn';
import sentryException from '../../util/sentryException';

import { passwordUpdate } from '../../util/auth';
import { MINIMUM_PASSWORD_STRENGTH } from '../../util/constants';
import { getAuthToken, getFirebaseUid } from '../../redux/selectors';

import ServiceAlertCard from '../AlertCard/ServiceAlertCard';

import Button from '../Button/Button';
import PasswordStrength from '../PasswordStrength/PasswordStrength';
import FormError from '../FormError/FormError';
import ShowablePasswordField from '../ShowablePasswordField/ShowablePasswordField';

const PasswordChangeForm = ({ uid, idToken }) => {
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const handlePasswordUpdate = async (values, { setSubmitting, setStatus, resetForm }) => {
    const { pwdCurrent, pwd } = values;
    setUpdateSuccess(false);

    if (!idToken) {
      setStatus({ formError: 'Could not update password' });
      setSubmitting(false);
      return;
    }

    try {
      await passwordUpdate(idToken, pwdCurrent, pwd);
      resetForm();
      setUpdateSuccess(true);
    } catch (error) {
      setSubmitting(false);
      setStatus({ formError: 'Could not update password' });
      sentryException(error, {
        section: 'password-update',
      });
    }
  };

  const schema = yup.object().shape({
    pwdCurrent: yup.string().required('Required'),
    pwd: yup.string()
      .required('Required')
      // eslint-disable-next-line no-template-curly-in-string
      .min(8, 'Minimum of ${min} characters required')
      .test('password-score', 'Please provide a stronger password', (value) => {
        if (!value) return false;
        return zxcvbn(value).score >= MINIMUM_PASSWORD_STRENGTH;
      }),
    pwdConfirm: yup.string()
      .required('Required')
      .test('matchPassword', 'Passwords do not match', function (value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.parent.pwd === value;
      }),
  });

  return (
    <Formik
      onSubmit={handlePasswordUpdate}
      validationSchema={schema}
      initialValues={{
        pwdCurrent: '',
        pwd: '',
        pwdConfirm: '',
      }}
      render={({
        handleSubmit,
        isSubmitting,
        values,
        status,
        errors,
        setFieldTouched,
        handleChange,
      }) => (
        <form onSubmit={handleSubmit}>
          {updateSuccess && (
            <React.Fragment>
              <ServiceAlertCard
                alertDescription={'Your password has been changed successfully!'}
                alertSeverity="success"
                icon="success"
              />
              <br />
            </React.Fragment>
          )}
          <ShowablePasswordField
            name="pwdCurrent"
            label="Old password"
            defaultValue="Enter old password"
            required
            onChange={(e) => {
              setFieldTouched('pwdCurrent');
              handleChange(e);
            }}
          />
          <ShowablePasswordField
            name="pwd"
            type="password"
            label="Password"
            defaultValue="Enter new password"
            required
            onChange={(e) => {
              setFieldTouched('pwd');
              handleChange(e);
            }}
          />
          {values && values.pwd && values.pwd.length > 0 && <PasswordStrength pwd={values.pwd} />}
          <ShowablePasswordField
            name="pwdConfirm"
            label="Confirm password"
            defaultValue="Confirm password"
            required
            onChange={(e) => {
              setFieldTouched('pwdConfirm');
              handleChange(e);
            }}
          />
          <br />
          <FormError error={status && status.formError} />
          <Button
            type="submit"
            size="medium"
            label="Update password"
            loading={isSubmitting}
            disabled={Boolean(Object.keys(errors).length)}
          />
        </form>
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  idToken: getAuthToken(state),
  uid: getFirebaseUid(state),
});

export default connect(mapStateToProps)(PasswordChangeForm);
