import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';
import get from 'lodash/get';
import classnames from 'classnames';
import styles from './AddPensionsPage.css';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import {
  policyNumberStepLandedAction,
  returnLinkClickedAction,
  messageMeClickedAction,
  policyNumberSkipStepClickedAction,
  policyNumberSubmitClickedAction,
  policyNumberChangedAction,
  tryFindAndCombineServiceClickedAction,
} from '../../redux/modules/pensions';
import { transferOptions } from '../../util/constants';

const POLICY_NUMBER = /^([a-zA-Z0-9-_ /]+)$/;

const PolicyNumberStep = ({
  setFormData,
  setStep,
  amendMode,
  step,
  totalSteps,
  dispatchPolicyNumberStepLanded,
  isCustomerOngoing,
  dispatchReturnLinkClicked,
  dispatchMessageMeClicked,
  dispatchSkipStepClicked,
  dispatchPolicyNumberSubmitClicked,
  dispatchPolicyNumberChanged: _dispatchPolicyNumberChanged,
  dispatchTryFindAndCombineServiceClicked,
  formData,
  resetFormData,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchPolicyNumberStepLanded(isCustomerOngoing, formData.transferOption);
  }, []);

  const [dispatchPolicyNumberChanged] = useState(() => once(_dispatchPolicyNumberChanged));
  const [policyNumber, setPolicyNumber] = useState(formData.policyNumber || '');
  const [error, setError] = useState(undefined);
  const isTransferBasic = formData.transferOption === transferOptions.TRANSFER_BASIC;
  const isTransferWithCheck = formData.transferOption === transferOptions.TRANSFER_WITH_CHECK;

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.policyNumberWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
          <ButtonLink
            onClick={(event) => {
              setStep((_step) => _step - 1);
              event.stopPropagation();
              dispatchReturnLinkClicked(isCustomerOngoing, formData.transferOption);
            }}
            label="← Back"
          />
        )}
        <h3 className={styles.policyNumberHeading}>
          {'Please provide your policy or reference number'}
          {isTransferBasic && ('*')}
        </h3>
        <input
          className={styles.policyNumberInput}
          placeholder={'Policy or reference number'}
          value={policyNumber}
          onChange={(event) => {
            const value = get(event, 'target.value');
            setError(undefined);
            dispatchPolicyNumberChanged(isCustomerOngoing, formData.transferOption);
            setPolicyNumber(value);
          }}
        />
        {error && <p className={styles.error}>{error}</p>}
        <div
          className={styles.submitContainer}
        >
          <div
            className={styles.policySubmitButtonWrapper}
          >
            <Button
              size="large"
              disabled={!policyNumber || error}
              label={(amendMode) ? 'Confirm' : 'Submit'}
              onClick={() => {
                dispatchPolicyNumberSubmitClicked(isCustomerOngoing, formData.transferOption);
                const valid = POLICY_NUMBER.test(policyNumber);

                if (!valid) {
                  setError('Please enter a valid policy number');
                  return;
                }
                setFormData((_formData) => ({
                  ..._formData,
                  policyNumber,
                }));
                setStep(step + 1);
              }}
            />
          </div>
          {isTransferWithCheck && (
            <ButtonLink
              variant="primary"
              label={<span className={styles.employerLinkText}>{'Skip this step'}</span>}
              onClick={() => {
                dispatchSkipStepClicked(isCustomerOngoing, formData.transferOption);
                setFormData((_formData) => ({
                  ..._formData,
                  policyNumber: '',
                }));
                setStep((_step) => _step + 1);
              }}
            />
          )}
          {isTransferBasic && (
            <ButtonLink
              label={(
                <div>
                  <span className={classnames(styles.employerLinkText, styles.inlineBlock)}>{'Don’t have this information?'}</span>
                  <span className={classnames(styles.employerLinkText, styles.inlineBlock)}>{'Try our “find, check and transfer” service'}</span>
                </div>
              )}
              onClick={() => {
                dispatchTryFindAndCombineServiceClicked(isCustomerOngoing, formData.transferOption);
                resetFormData();
                setStep(0);
              }}
            />
          )}
        </div>
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'PolicyNumberStep'}
            noExpertContentWrapper={({ children }) => (
              <div
                style={{
                  display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                }}
              >
                {children}
              </div>
            )}
            showButton={false}
            customMessage={(
              <div
                className={styles.contactUsInnerContent}
              >
                {'This information can be found on your previous statement. If you have any questions, please feel free to '}
                <ButtonLink
                  label="message me"
                  variant="primary"
                  to="/inbox/new-message"
                  font="inherit"
                  onClick={() => dispatchMessageMeClicked(
                    isCustomerOngoing,
                    formData.transferOption,
                  )}
                />
                {'.'}
              </div>
            )}
          />
        </div>
      </div>

    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  dispatchPolicyNumberStepLanded: policyNumberStepLandedAction,
  dispatchReturnLinkClicked: returnLinkClickedAction,
  dispatchMessageMeClicked: messageMeClickedAction,
  dispatchSkipStepClicked: policyNumberSkipStepClickedAction,
  dispatchPolicyNumberSubmitClicked: policyNumberSubmitClickedAction,
  dispatchPolicyNumberChanged: policyNumberChangedAction,
  dispatchTryFindAndCombineServiceClicked: tryFindAndCombineServiceClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PolicyNumberStep);
