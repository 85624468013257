exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);

// module
exports.push([module.id, "/* \n  The Calendly iframe allows overflow content to be visible, so there's no way of predicting from its CSS how large its visible content will be.\n  We need to create a large enough container for the iframe to stop it from requiring its own internal scroller, which means leaving some whitespace under the iframe.\n*/\n.-WithdrawalsAppointmentComponent-container-2E1WY {\n  grid-column: 1 / -1;\n  max-width: 480px;\n  padding-top: 10px;\n}\n.-WithdrawalsAppointmentComponent-section-11QUf {\n  position: relative;\n  height: 800px;\n  padding: 0 12px 0;\n}\n.-WithdrawalsAppointmentComponent-section1--emAK {\n  position: relative;\n  height: 600px;\n  padding: 0 12px 0;\n}\n/* At viewports above 705px, Calendly adds its own top margin. */\n.-WithdrawalsAppointmentComponent-basePage-5_TdX {\n  max-width: none\n}\n@media only screen and (min-width: 705px) {\n.-WithdrawalsAppointmentComponent-basePage-5_TdX {\n    margin-top: 0\n}\n  }\n.-WithdrawalsAppointmentComponent-returnButton-2JAZy {\n  margin-top: 20px;\n  margin-bottom: 10px\n}\n@media only screen and (min-width: 705px) {\n.-WithdrawalsAppointmentComponent-returnButton-2JAZy {\n    margin-bottom: 0\n}\n  }\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n.-WithdrawalsAppointmentComponent-returnButton-2JAZy {\n    margin-left: 3em;\n    margin-right: 3em\n}\n  }", ""]);

// exports
exports.locals = {
	"dimensions": "\"../../theme/dimensions.css\"",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"container": "-WithdrawalsAppointmentComponent-container-2E1WY",
	"section": "-WithdrawalsAppointmentComponent-section-11QUf",
	"section1": "-WithdrawalsAppointmentComponent-section1--emAK",
	"basePage": "-WithdrawalsAppointmentComponent-basePage-5_TdX",
	"returnButton": "-WithdrawalsAppointmentComponent-returnButton-2JAZy"
};