import React, { Component } from 'react';
import { connect } from 'react-redux';

import SelectField from '../SelectField/SelectField';
import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import {
  personalDetailsFindAddressClicked,
  personalDetailsAddressSelected,
  personalDetailsAddressDropdownClicked,
  personalDetailsChangeAddressClicked,
  missingDetailsFindAddressClicked,
  missingDetailsAddressSelected,
  missingDetailsAddressDropdownClicked,
  missingDetailsChangeAddressClicked,
  missingDetailsCompleteAction,
} from '../../redux/modules/signupFlow';
import { statusNotificationMissingDetailsCompleted } from '../../redux/modules/notificationTracking';
import { updatePersonalDetails } from '../../redux/modules/investmentAdvice';

import { MISSING_ADDRESS_ERROR } from '../../util/constants';

class PostcodeAddressLookup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addresses: [],
      selectedAddress: '',
      showSelectField: false,
      loadingAddresses: false,
    };
  }

  formatDisplayedAddress = (addressString) => {
    if (!addressString) { return ''; }

    const regex = /(,)([, ]+)/g;
    const removedDoubleCommas = addressString.replace(regex, (match, p1) => (`${p1} `));
    return removedDoubleCommas.replace(/,\s*$/, '');
  }

  render() {
    const {
      name,
      label,
      value,
      touched,
      errorText,
      onChange,
      onBlur,
      largeHeadings,
      getAddresses,
      selectAddress,
      required,
      dispatchPersonalDetailsFindAddressClicked,
      dispatchPersonalDetailsAddressSelected,
      dispatchPersonalDetailsAddressDropdownClicked,
      dispatchPersonalDetailsChangeAddressClicked,
      secondaryText,
      bodyOneSecondary,
    } = this.props;

    const {
      postcode,
      addresses,
      selectedAddress,
      showSelectField,
      loadingAddresses,
    } = this.state;

    const formatPostcode = () => {
      return postcode ? `${postcode.slice(0, -3)} ${postcode.slice(-3)}` : '';
    };

    const uppercase = (string) => {
      return string ? string.toUpperCase() : '';
    };

    return (
      <React.Fragment>
        {selectedAddress && (
          <React.Fragment>
            <TextField
              name={name}
              label={label}
              value={this.formatDisplayedAddress(selectedAddress)}
              wrapperStyles={{ maxWidth: '100%' }}
              defaultValue={this.formatDisplayedAddress(selectedAddress)}
              largeHeadings={largeHeadings}
              disabled
              required={required}
            />
            <Button
              label="Change Address"
              onClick={() => {
                this.setState({ selectedAddress: null });
                selectAddress();
                dispatchPersonalDetailsChangeAddressClicked();
              }}
            />
          </React.Fragment>
        )}

        {!selectedAddress && (
          <React.Fragment>
            <TextField
              name={name}
              label={label}
              value={value}
              touched={touched}
              errorText={errorText}
              onChange={(e) => {
                onChange(e);
                this.setState({ showSelectField: false });
              }}
              onBlur={onBlur}
              wrapperStyles={{ maxWidth: '100%' }}
              defaultValue={null}
              largeHeadings={largeHeadings}
              uppercase
              required={required}
              secondaryText={secondaryText}
              bodyOneSecondary={bodyOneSecondary}
            />
            <Button
              label="Find UK Address"
              disabled={!value || (typeof errorText === 'string' && errorText.length > 0 && errorText !== MISSING_ADDRESS_ERROR)}
              loading={loadingAddresses}
              onClick={async () => {
                this.setState({ loadingAddresses: true });
                const foundAddresses = await getAddresses(value);
                this.setState({
                  addresses: foundAddresses || [],
                  showSelectField: true,
                  loadingAddresses: false,
                });
                dispatchPersonalDetailsFindAddressClicked();
              }}
            />
            {showSelectField && (
              <SelectField
                label=""
                value={this.formatDisplayedAddress(selectedAddress)}
                options={addresses.map((option) => (
                  { value: option, label: this.formatDisplayedAddress(option) }
                ))}
                onChange={(event) => {
                  this.setState({ selectedAddress: this.formatDisplayedAddress(event.value) });
                  selectAddress(event.value);
                  dispatchPersonalDetailsAddressSelected();
                }}
                onMenuOpen={() => dispatchPersonalDetailsAddressDropdownClicked()}
                wrapperStyles={{ maxWidth: '100%' }}
                placeholder="Select..."
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const personalDetailsMapDispatchToProps = ({
  dispatchPersonalDetailsFindAddressClicked: personalDetailsFindAddressClicked,
  dispatchPersonalDetailsAddressSelected: personalDetailsAddressSelected,
  dispatchPersonalDetailsAddressDropdownClicked: personalDetailsAddressDropdownClicked,
  dispatchPersonalDetailsChangeAddressClicked: personalDetailsChangeAddressClicked,
});

const missingDetailsMapDispatchToProps = {
  dispatchPersonalDetailsFindAddressClicked: missingDetailsFindAddressClicked,
  dispatchPersonalDetailsAddressSelected: missingDetailsAddressSelected,
  dispatchPersonalDetailsAddressDropdownClicked: missingDetailsAddressDropdownClicked,
  dispatchPersonalDetailsChangeAddressClicked: missingDetailsChangeAddressClicked,
  dispatchMissingDetailsComplete: missingDetailsCompleteAction,
  dispatchStatusNotificationMissingDetailsCompleted: statusNotificationMissingDetailsCompleted,
  dispatchUpdatePersonalDetails: updatePersonalDetails,
};

export const PersonalDetailsPostcodeAddressLookup = connect(null, personalDetailsMapDispatchToProps)(PostcodeAddressLookup);
export const MissingDetailsPostcodeAddressLookup = connect(null, missingDetailsMapDispatchToProps)(PostcodeAddressLookup);
