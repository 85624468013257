import { isValidNumber } from 'libphonenumber-js';
import validate from 'validate.js';

// eslint-disable-next-line no-useless-escape
const NI_REGEX = /^$|(([A-CEGHJ-PR-TW-Za-ceghj-pr-tw-z]\s*){2}([0-9]\s*){6}([A-DFMa-dfm])\s*)/;
export const UK_MOBILE_NUMBER_REGEX = /^(\+44?(\(0\)|\s)?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/;
// const UK_TELEPHONE_NUMBER_STARTS_WITH_REGEX = /^(\+44|0)([0-9|\s]*$)/;
const PHONE_NUMBER_REGEX = /^(\+44|0)([0-9|\s]*$)/;
const POSTCODE_REGEX = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})/;

const commonValidationRule = () => ({
  present: (attributeName) => ({ presence: { message: `^Please enter your ${attributeName}` } }),
});

validate.validators.validateMobileNumber = (value) => {
  if (value) {
    const matchRegex = UK_MOBILE_NUMBER_REGEX.test(value);
    if (!matchRegex) {
      return '^Please enter a valid UK mobile number';
    }
  }
};
validate.validators.validateHomeNumber = (value) => {
  if (value) {
    const isOneChar = value.length === 1;
    const validTelephoneNumber = isOneChar ? false : isValidNumber(value, 'GB');
    const matchRegex = PHONE_NUMBER_REGEX.test(value);
    const matchMobileRegex = UK_MOBILE_NUMBER_REGEX.test(value);
    if (!matchRegex || !validTelephoneNumber || matchMobileRegex) {
      return '^Please enter a valid UK landline number';
    }
  }
};
export const personalDetailsConstraints = {
  nationalInsuranceNumber: {
    ...commonValidationRule().present('National Insurance number'),
    format: { pattern: NI_REGEX, message: '^Please enter a valid National Insurance number' },
  },
};

export const addressConstraints = {
  postcode: {
    ...commonValidationRule().present('Postcode'),
    format: { pattern: POSTCODE_REGEX, message: '^Please enter a valid Postcode' },
  },
  'address.line1': {
    presence: { message: '^Please enter the first line of your address' },
  },
  'address.city': {
    ...commonValidationRule().present('City'),
  },
};

export const contactNumberConstraints = {
  mobileNumber: {
    validateMobileNumber: { },
  },
  homeNumber: {
    validateHomeNumber: { },
  },
};

export const missingDetailsConstraints = {
  ...personalDetailsConstraints,
  mobileNumber: {
    ...commonValidationRule().present('Mobile Number'),
    format: { pattern: UK_MOBILE_NUMBER_REGEX, message: '^Please enter a valid UK Mobile Number' },
  },
};

/**
 * The validation library collapses the nested object from the form so it needs to be reverted.
 * @param {Object} flatObject Flat object with string . notation
 * {
 *  "one.two.foo": 'Number'
 *  "one.two.bar": 'string'
 * }
 * @returns {Object} The original object with a nested structure.
 * {
 *  one: {
 *    two: {
 *      foo: 1,
 *      bar: 'string value'
 *    }
 *  }
 * }
 */
export const convertFlattenedObjectToObject = (flatObject = {}) => {
  let nestedObject = {};

  Object.keys(flatObject).forEach((field) => {
    const objectKeys = field.split('.');
    const constructErrorObject = objectKeys.reduceRight(
      (accumulator, currentValue, index, array) => {
        if (index === array.length - 1) {
          return { [currentValue]: flatObject[field][0] };
        }

        return { [currentValue]: accumulator };
      }, {},
    );

    if (nestedObject[objectKeys[0]] && typeof nestedObject[objectKeys[0]] === 'object') {
      const mergedField = {
        ...nestedObject[objectKeys[0]],
        ...constructErrorObject[objectKeys[0]],
      };
      nestedObject = { ...nestedObject, [objectKeys[0]]: mergedField };
    } else {
      nestedObject = { ...nestedObject, ...constructErrorObject };
    }
  });

  return nestedObject;
};
