import moment from 'moment';
import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import _range from 'lodash/range';

import FieldLabel from '../FieldLabel/FieldLabel';
import FieldStatusBlock from '../FieldStatusBlock/FieldStatusBlock';

import styles from './MultiFieldDatePicker.css';

const DAY_ID = 'day';
const MONTH_ID = 'month';
const YEAR_ID = 'year';
const CURRENT_YEAR = moment().year();

const datePartsShape = {
  day: 0,
  month: 0,
  year: 0,
};

const MultiFieldDatePicker = (props) => {
  const {
    name,
    label,
    date,
    onChange,
    range,
    touched,
    errorText,
    errorTextPerField,
    wrapperStyles,
    largeHeadings,
    required,
  } = props;

  /**
   * placeholder i.e 'Day'
   * options i.e [1, 2, 3, 4]
   * key i.e 'day'
   */
  const selectField = ({ placeholder, options }, key) => {
    const errorMessage = errorTextPerField[key] ? errorTextPerField[key] : errorText;
    return (
      <div style={{ width: '31%' }}>
        <FieldStatusBlock
          error={errorMessage}
          touched={touched && !!errorMessage}
          wrapperStyles={wrapperStyles}
          hideMessage
        >
          <Select
            name={`${name}-${key}`}
            value={{
              value: date[key],
              label: date[key],
            }}
            placeholder={placeholder}
            options={options}
            clearable={false}
            searchable={false}
            onChange={(event) => {
              onChange({
                ...date,
                [key]: event.value,
              });
            }}
          />
          <input type="hidden" name={name} value={date[key]} />
        </FieldStatusBlock>
        {
          (touched && errorTextPerField[key])
            && <div className={styles.errorMessage}>{errorTextPerField[key]}</div>
        }
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <FieldLabel label={label} required={required} largeHeadings={largeHeadings} />
      <div className={styles.fieldsWrapper}>
        {selectField({
          placeholder: 'Day',
          options: _range(31).map((day) => (
            { value: day + 1, label: day + 1 }
          )),
        }, DAY_ID)}
        {selectField({
          placeholder: 'Month',
          options: moment.months().map((month) => (
            { value: month, label: month }
          )),
        }, MONTH_ID)}
        {selectField({
          placeholder: 'Year',
          options: _range(range.max, range.min - 1).map((year) => (
            { value: year, label: year }
          )),
        }, YEAR_ID)}
      </div>
      {(touched && errorText) && <div className={styles.errorMessage}>{errorText}</div>}
    </div>
  );
};

export default MultiFieldDatePicker;

MultiFieldDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  date: PropTypes.shape(datePartsShape),
  range: PropTypes.shape({ min: PropTypes.number, max: PropTypes.number }),
  errorText: PropTypes.string,
  errorTextPerField: PropTypes.objectOf(PropTypes.string),
};

MultiFieldDatePicker.defaultProps = {
  date: { day: '', month: '', year: '' },
  range: { min: 1910, max: CURRENT_YEAR },
  errorText: '',
  errorTextPerField: {},
};
