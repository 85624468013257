import moment from 'moment';
import { getFirestore } from 'redux-firestore';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { getApp } from 'firebase/app';
import {
  ATTACHMENT_ACCEPTED_FILE_FORMATS,
  ATTACHMENT_MAX_FILE_COUNT,
  ATTACHMENT_MAX_FILE_SIZE_MB,
  FILE_INVALID_TYPE, FILE_TOO_LARGE, FILE_TOO_SMALL, TOO_MANY_FILES,
  FILE_NAME_INVALID,
} from './constants';
import { ACCOUNT_HUB_ATTACHMENTS_BUCKET } from '../config';

const getAttachmentErrorMessage = (errorCode) => {
  switch (errorCode) {
    case FILE_INVALID_TYPE:
      return `File type not supported (Accepted files: ${ATTACHMENT_ACCEPTED_FILE_FORMATS.join(', ')})`;
    case FILE_TOO_LARGE:
      return `Maximum file size is ${ATTACHMENT_MAX_FILE_SIZE_MB}mb`;
    case FILE_TOO_SMALL:
      return 'File size is too small';
    case TOO_MANY_FILES:
      return `Too many files attached (${ATTACHMENT_MAX_FILE_COUNT} allowed at a time)`;
    case FILE_NAME_INVALID:
      return 'File name is invalid (File name doesn\'t contain alphanumeric characters)';
    default:
      return 'Something went wrong';
  }
};

const TIME_THRESHOLD = 5;

const ATTACHMENT_ALPHANUMERIC_REGEX = /([^a-zA-Z0-9]+)/g;

const isFilenameValid = (filename) => {
  const [head] = filename.split(/(\.[\w]+)(?!.)/, 3);
  const regExp = new RegExp(/([a-zA-Z0-9]+)/g);
  return regExp.test(head);
};

const getSanitizedFilename = (file) => {
  const { name } = file;

  const [head, fileExtension] = name.split(/(\.[\w]+)(?!.)/, 3);

  const sanitized = head.replace(ATTACHMENT_ALPHANUMERIC_REGEX, '-').substring(0, 30);
  return `${sanitized}${fileExtension}`;
};

const convertExtensionToLowerCase = (filePath) => {
  const fileExtRegex = new RegExp(`^(?<name>.+)(?<extension>${ATTACHMENT_ACCEPTED_FILE_FORMATS.join('|')})$`, 'i');
  const match = fileExtRegex.exec(filePath);
  if (match) {
    const { name, extension } = match.groups;
    return `${name}${extension.toLowerCase()}`;
  }
  return filePath;
};

const uploadFileToBucket = async (firebaseId, createId, file, requestTime) => {
  const firebaseApp = getApp();
  const storage = getStorage(firebaseApp, ACCOUNT_HUB_ATTACHMENTS_BUCKET);

  const sanitizedFilename = getSanitizedFilename(file);

  const objectPath = `${createId}/${sanitizedFilename}`;

  const storageRef = ref(storage, objectPath);

  const metadata = {
    customMetadata: {
      firebaseId,
      createId,
      requestTime,
    },
  };

  await uploadBytes(storageRef, file, metadata);
};

const checkRequestAmount = async (uid) => {
  const firestore = getFirestore();

  const now = moment();

  const attachmentUploadRef = await firestore.collection('users').doc(uid).collection('attachmentUploads');
  const attachmentUploadRefQuery = await attachmentUploadRef.where('requestTime', '>=', now.subtract(TIME_THRESHOLD, 'minutes').toDate());
  const { size: numberOfAttachments } = await attachmentUploadRefQuery.get();

  if (numberOfAttachments >= 10) {
    throw Error(`You have exceeded the number of attachments for the last ${TIME_THRESHOLD} minutes. Try again later!`);
  }
};

const addAttachmentRefToUser = async (uid, createId, requestTime) => {
  const firestore = getFirestore();

  const attachmentUploadsColRef = firestore.collection('users').doc(uid).collection('attachmentUploads').doc(createId);
  await attachmentUploadsColRef.set({
    createId,
    requestTime,
  });
};

export {
  getAttachmentErrorMessage,
  checkRequestAmount,
  uploadFileToBucket,
  addAttachmentRefToUser,
  isFilenameValid,
  convertExtensionToLowerCase,
};
