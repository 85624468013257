import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../Button/Button';
import {
  getIsCustomerOngoing,
  getIsCustomerOngoingMultipleRps,
} from '../../redux/selectors/experience';
import { getInProgressContributionInstructions } from '../../redux/selectors/investmentAdvice';
import {
  addMoneyLinkClicked,
  ongoingAddMoneyLinkClicked,
} from '../../redux/modules/dashboard';
import GrayDash from '../../assets/images/icons/Icon_Circle_Gray_Dash.svg';
import styles from './AddPensions.css';
import pensionRowStyles from './PensionRows.css';
import { getIsAddPensionsAllowed } from '../../redux/selectors/journeys';
import ButtonLink from '../ButtonLink/ButtonLink';

function ContributionsRow({
  dispatchAddMoneyLinkClicked,
  dispatchOngoingAddMoneyLinkClicked,
  isCustomerOngoing,
  inProgressContributionInstructions,
  heading,
  isAddPensionsAllowed,
  isCustomerOngoingMultipleRps,
}) {
  const getDisabledMessage = () => {
    if (isCustomerOngoingMultipleRps) { return 'We are currently setting up your new pension plan. You will be able to set up a contribution when this is complete.'; }
    if (!isAddPensionsAllowed) { return 'You need to update your pension plan before you can add regular contributions.'; }
  };
  const isAddMoneyAllowedMessage = 'Grow your pensions balance by setting up regular or one-off contributions.';

  const isAnyInProgress = inProgressContributionInstructions.length >= 1;

  const disabled = !isAddPensionsAllowed || isCustomerOngoingMultipleRps || isAnyInProgress;
  return (
    <div className={styles.addPensions}>
      <h3 className={styles.heading}>{heading}</h3>
      {!disabled ? (
        <p className={styles.body}>{isAddMoneyAllowedMessage}</p>
      ) : (
        <p className={styles.body}>{getDisabledMessage()}</p>
      )}
      {isAnyInProgress && (
        <div className={pensionRowStyles.instructionStatusWrapper}>
          <img src={GrayDash} alt="Pending icon" />
          <p className={pensionRowStyles.subText}>
            {'We are currently setting up your regular contribution'}
          </p>
        </div>
      )}
      {!disabled && (
        <Button
          disabled={disabled}
          link={'/setup-contribution'}
          onClick={() => {
            if (isCustomerOngoing) {
              dispatchOngoingAddMoneyLinkClicked();
            } else {
              dispatchAddMoneyLinkClicked();
            }
          }}
          label="+ Add Money"
        />
      )}
      {isAnyInProgress && (
        <div className={styles.errorMessage}>
          <h3 className={styles.body}>
            {
                'You have already requested a contribution, if you wish to make further requests, '
              }
          </h3>
          <ButtonLink
            variant="primary"
            to="/inbox/new-message"
            font="inherit"
            underline
            label={'please contact your adviser.'}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
  inProgressContributionInstructions: getInProgressContributionInstructions(
    state,
  ),
  isCustomerOngoingMultipleRps: getIsCustomerOngoingMultipleRps(state),
  isAddPensionsAllowed: getIsAddPensionsAllowed(state),
});

const mapDispatchToProps = {
  dispatchAddMoneyLinkClicked: addMoneyLinkClicked,
  dispatchOngoingAddMoneyLinkClicked: ongoingAddMoneyLinkClicked,
};

ContributionsRow.defaultProps = {
  disabled: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContributionsRow);
