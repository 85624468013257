import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createEpicMiddleware } from 'redux-observable';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import { reduxFirestore, getFirestore } from 'redux-firestore';

import { rootReducer } from './modules/root';
import rootEpic from './epics/rootEpic';
import firebase from '../util/firebaseInstance';
import recordEvents from './middleware/recordEvents';

const middleware = [];

const epicMiddleware = createEpicMiddleware();
middleware.push(epicMiddleware);
let composeEnhancers = compose;

if (process.env.NODE_ENV !== 'production') {
  // Logs all actions and states after they are dispatched.
  const logger = (store) => (next) => (action) => {
    if (action.type) {
      console.group('action type', action.type);
      console.info('dispatching', action);
      console.log('next state', store.getState());
      console.groupEnd(action.type);
    }
    return next(action);
  };

  // Enable Redux Devtools extension in browser develop and staging
  composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  middleware.push(logger);
}
middleware.push(thunk.withExtraArgument({ getFirebase, getFirestore }));

const analytics = () => (next) => (action) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: action.type,
  });
  return next(action);
};

middleware.push(analytics);
middleware.push(recordEvents);

const store = createStore(rootReducer,
  composeEnhancers(
    applyMiddleware(...middleware),
    reduxFirestore(firebase),
    reactReduxFirebase(firebase, { useFirestoreForProfile: true, userProfile: 'users' }),
  ));

epicMiddleware.run(rootEpic);

export default store;
