exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-DotNavigation-dotNavigationContainer-3pc9E {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: horizontal;\n    -webkit-box-direction: normal;\n        -ms-flex-direction: row;\n            flex-direction: row;\n    -webkit-box-pack: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    grid-gap: 10px;\n    gap: 10px;\n}\n.-DotNavigation-dot-3QGvj {\n    border-radius: 50%;\n    height: 16px;\n    width: 16px;\n    background: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["lightGrey"] + ";\n    border: none;\n    padding: 0;\n    cursor: pointer;\n}\n.-DotNavigation-whiteDefaultDot-3dky_ {\n    background: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["fog"] + ";\n}\n.-DotNavigation-dot-3QGvj.-DotNavigation-grassActiveDot-QYN5r {\n    background: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["accentBaseSecondaryRegular"] + ";\n}\n.-DotNavigation-dot-3QGvj.-DotNavigation-limeActiveDot-1GBF8 {\n    background: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["accentBaseSecondaryRegular"] + ";\n}", ""]);

// exports
exports.locals = {
	"colors": "\"../../theme/colors.css\"",
	"lightGrey": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["lightGrey"] + "",
	"grass": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["grass"] + "",
	"white": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["white"] + "",
	"lime": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["lime"] + "",
	"accentBaseSecondaryRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["accentBaseSecondaryRegular"] + "",
	"fog": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["fog"] + "",
	"dotNavigationContainer": "-DotNavigation-dotNavigationContainer-3pc9E",
	"dot": "-DotNavigation-dot-3QGvj",
	"whiteDefaultDot": "-DotNavigation-whiteDefaultDot-3dky_",
	"grassActiveDot": "-DotNavigation-grassActiveDot-QYN5r",
	"limeActiveDot": "-DotNavigation-limeActiveDot-1GBF8"
};