import isNil from 'lodash/isNil';
import get from 'lodash/get';
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import { currencyString } from '../../util/currency';
import chevronRight from '../../assets/images/rebranding/chevron-right.svg';
import styles from './RetirementIncomePage.css';

export const getMonthlyContributionsCopy = (enabled, value, currentValue) => {
  if (enabled) {
    if (isNil(currentValue) || currentValue < 0) {
      return (
        <React.Fragment>
          {'I would like to make regular contributions of '}
          <b>
            {currencyString(value)}
          </b>
          {' a month'}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {'I would like to amend my regular contributions from '}
        <b>
          {currencyString(currentValue)}
        </b>
        {' to '}
        <b>
          {currencyString(value)}
        </b>
        {' a month'}
      </React.Fragment>
    );
  }
  if (isNil(currentValue)) {
    return 'You have not set up a contribution';
  }

  return (
    <React.Fragment>
      {'You kept your contributions at '}
      <b>
        {currencyString(currentValue)}
      </b>
      {' '}
      {'/ month'}
    </React.Fragment>
  );
};

export const getTransferPensionCopy = (enabled, value) => {
  if (enabled) {
    return (
      <React.Fragment>
        {'I have one or more existing pensions worth '}
        <b>
          {currencyString(value)}
        </b>
        {' that I would like to combine'}
      </React.Fragment>
    );
  }

  return 'You won’t be transferring a pension';
};

export const getAddLumpSumCopy = (enabled, value) => {
  if (enabled) {
    return (
      <React.Fragment>
        {'I would like to add a one-off contribution of '}
        <b>
          {currencyString(value)}
        </b>
      </React.Fragment>
    );
  }

  return 'You have not added a lump sum';
};

export const getRetirementAgeCopy = (enabled, newRetirementAge, currentRetirementAge) => {
  if (enabled) {
    return (
      <React.Fragment>
        <p className={styles.resultsPageSelectionText} style={{ marginBottom: '8px' }}>
          {'I would like to change my retirement age from '}
          <b>{currentRetirementAge}</b>
          {' to '}
          <b>{newRetirementAge}</b>
          {' years old.'}
        </p>
        <p className={styles.resultsPageSelectionText} style={{ fontFamily: 'Lato', fontWeight: 700 }}>
          {'If you wish to implement this change '}
          <Link
            to="/inbox/new-message"
          >
            {'please contact your Pension Adviser'}
          </Link>
          {'.'}
        </p>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {'Your retirement age has remained at '}
      <b>
        {currentRetirementAge}
      </b>
    </React.Fragment>
  );
};

export const ChangedScenario = ({
  title, icon, getContent, functionProps,
}) => {
  return (
    <div className={styles.resultsPageChangedScenarioWrapper}>
      <img src={icon} alt={title} />
      <div className={styles.resultsPageChangedScenarioTextSection}>
        <h4 className={styles.resultsPageSelectionTitle}>{title}</h4>
        <p className={styles.resultsPageSelectionText}>
          {getContent(...functionProps)}
        </p>
      </div>
    </div>
  );
};

export const UnchangedScenario = ({ getContent, functionProps }) => {
  return (
    <li>
      <p className={styles.resultsPageSelectionText}>{getContent(...functionProps)}</p>
    </li>
  );
};

const ChangedScenarioTaskCardWithoutRouter = ({
  enabled = false,
  icon,
  title,
  children,
  linkTo,
  history,
  isFirstRow = false,
  isLastRow = false,
  onClick = null,
}) => {
  const handleClick = () => {
    if (linkTo) {
      if (typeof onClick === 'function') {
        onClick();
      }
      history.push(linkTo, { hideBackNavigation: true });
    }
  };

  if (!enabled) {
    return null;
  }

  return (
    <div
      className={classNames(styles.changedScenarioTaskCard, {
        [styles.changedScenarioTaskCardAsLink]: linkTo,
        [styles.changedScenarioTaskCardLastRow]: isLastRow,
        [styles.changedScenarioTaskCardFirstRow]: isFirstRow,
      })}
      onClick={handleClick}
      role="link"
      tabIndex="0"
      onKeyUp={() => {}}
    >
      <div>
        <img src={icon} alt={title} className={styles.changedScenarioTaskCardIcon} />
      </div>
      <div className={styles.changedScenarioTaskContentWrapper}>
        <div className={styles.changedScenarioTaskTitleWrapper}>
          <h4 className={styles.changedScenarioTaskCardTitle}>{title}</h4>
          {linkTo && (
            <img src={chevronRight} alt={'Arrow'} className={styles.changedScenarioTaskCardLinkArrow} />
          )}
        </div>
        {children && (
        <div className={styles.changedScenarioTaskCardContent}>
          {children}
        </div>
        )}
      </div>

    </div>
  );
};

export const ChangedScenarioTaskCard = withRouter(ChangedScenarioTaskCardWithoutRouter);

export const DisplayError = ({ error }) => {
  if (!error) {
    return null;
  }

  return (
    <div>
      <small className={styles.errorMessage}>{error}</small>
    </div>
  );
};

export const getEstimatedPensionSavings = (currentPensionSavings, scenarios) => {
  let newPensionSavings = currentPensionSavings;
  const lumpSum = get(scenarios, 'addLumpSum.enabled') && get(scenarios, 'addLumpSum.value');
  if (lumpSum > 0) {
    newPensionSavings += lumpSum;
  }

  const transferPension = get(scenarios, 'transferPension.enabled') && get(scenarios, 'transferPension.value');
  if (transferPension > 0) {
    newPensionSavings += transferPension;
  }

  return newPensionSavings;
};
