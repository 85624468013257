import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { getFirebaseUid, getFiles, getAttachmentFiles } from '../../redux/selectors';
import DocumentView from '../DocumentView/DocumentView';
import DocumentTable from '../DocumentTable/DocumentTable';
import AttachmentsTable from '../DocumentTable/AttachmentsTable';

class UnwrappedDocumentContainerTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.updateFiles = this.updateFiles.bind(this);
    this.storageRef = props.firebase.storage().ref();
    this.state = { files: [] };
  }

  componentDidMount() {
    this.updateFiles();
  }

  componentDidUpdate(prevProps) {
    const { files } = this.props;
    const prevFileLength = prevProps.files.length;
    const fileLength = files.length;

    if (prevFileLength !== fileLength) {
      this.updateFiles();
      return;
    }

    const noChange = prevProps.files.every(({ name, size, updated }, index) => {
      const currentFile = files[index];
      return (name === currentFile.name)
        && (size === currentFile.size)
        && (updated === currentFile.updated);
    });

    if (noChange) return;

    this.updateFiles();
  }

  updateFiles() {
    const { files } = this.props;
    const getDownloadURL = (name) => {
      const userDocRef = this.storageRef.child(name);
      return userDocRef.getDownloadURL()
        .then((url) => url)
        .catch((error) => console.log(error));
    };

    const updateFiles = () => files.forEach((file, index) => {
      const { files: stateFiles } = this.state;
      const { files: propFiles } = this.props;
      if (stateFiles[index] && !stateFiles[index].downloadUrl) {
        getDownloadURL(file.name)
          .then((url) => {
            const items = propFiles;
            items[index].downloadUrl = url;
            this.setState({ files: items });
          });
      }
    });

    this.setState({ files }, updateFiles);
  }

  render() {
    const { files } = this.state;
    const { table, enableMobileTable } = this.props;
    return <DocumentView files={files} table={table} enableMobileTable={enableMobileTable} />;
  }
}

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
});

const DocumentContainerTemplate = compose(
  connect(mapStateToProps),
  firebaseConnect(),
)(UnwrappedDocumentContainerTemplate);

UnwrappedDocumentContainerTemplate.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    updated: PropTypes.string,
  })),
};

UnwrappedDocumentContainerTemplate.defaultProps = {
  files: [],
};

const documentContainerMapStateToProps = (state) => ({
  files: getFiles(state),
});

const UnwrappedDocumentContainer = ({ files }) => (
  <DocumentContainerTemplate files={files} table={DocumentTable} enableMobileTable />
);

export const DocumentContainer = compose(
  connect(documentContainerMapStateToProps),
)(UnwrappedDocumentContainer);

const attachmentContainerMapStateToProps = (state) => ({
  files: getAttachmentFiles(state),
});

const UnwrappedAttachmentContainer = ({ files }) => (
  <DocumentContainerTemplate files={files} table={AttachmentsTable} />
);
export const AttachmentContainer = compose(
  connect(attachmentContainerMapStateToProps),
)(UnwrappedAttachmentContainer);
