exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/shared.css"), undefined);

// module
exports.push([module.id, ".-AddMoneyOption-contributionsContainer-3ERpC {\n  margin: 10px 0 25px 0;\n}\n.-AddMoneyOption-contributionsContainer-3ERpC, .-AddMoneyOption-mentionMeContainer-1pE76, .-AddMoneyOption-pensionAdviserContainer-wS5_a, .-AddMoneyOption-setUpOrAmendCard-3jsFf {\n  max-width: 478px;\n}\n.-AddMoneyOption-pensionAdviserCard-1ItQd {\n  padding: 12px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin: 0;\n  -webkit-box-align: start;\n      -ms-flex-align: start;\n          align-items: flex-start;\n}\n.-AddMoneyOption-pensionAdviserCard-1ItQd > *:first-child {\n  -ms-flex-negative: 0;\n      flex-shrink: 0;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n}\n.-AddMoneyOption-setUpOrAmendCard-3jsFf {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  padding: 12px;\n  margin: 0;\n  background: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["white"] + ";\n  cursor: pointer;\n  border: none;\n  text-align: left;\n}\n.-AddMoneyOption-infoBoxHeading-2_Apq {\n  font-size: 33px;\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + ";\n  margin: 0 0 20px;\n}\n.-AddMoneyOption-infoTextHeading-2zL3d {\n  font-size: 20px;\n  color: black;\n  margin: 0 40px 0 0;\n}\n.-AddMoneyOption-infoText-V-iMu {\n  font-size: 17.5px;\n  margin: 8px 40px 0 0;\n}\n.-AddMoneyOption-vector-17W_1 {\n  min-height: 20px;\n  min-width: 15px;\n}\n.-AddMoneyOption-contactUsInnerContent-3FaDZ {\n  margin: auto 0;\n}\n", ""]);

// exports
exports.locals = {
	"typography": "\"../../theme/typography.css\"",
	"bodyOne": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"bodyTwo": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyTwo"] + "",
	"h1": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h1"] + "",
	"h3": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h3"] + "",
	"h6Website": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h6Website"] + "",
	"bodyTwoOriginal": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyTwoOriginal"] + "",
	"colors": "\"../../theme/colors.css\"",
	"textTitleRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + "",
	"white": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["white"] + "",
	"dimensions": "\"../../theme/dimensions.css\"",
	"shared": "\"../../theme/shared.css\"",
	"card": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/shared.css").locals["card"] + "",
	"contributionsContainer": "-AddMoneyOption-contributionsContainer-3ERpC",
	"mentionMeContainer": "-AddMoneyOption-mentionMeContainer-1pE76",
	"pensionAdviserContainer": "-AddMoneyOption-pensionAdviserContainer-wS5_a",
	"setUpOrAmendCard": "-AddMoneyOption-setUpOrAmendCard-3jsFf " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/shared.css").locals["card"] + " " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h6Website"] + "",
	"pensionAdviserCard": "-AddMoneyOption-pensionAdviserCard-1ItQd " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/shared.css").locals["card"] + "",
	"infoBoxHeading": "-AddMoneyOption-infoBoxHeading-2_Apq " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h1"] + "",
	"infoTextHeading": "-AddMoneyOption-infoTextHeading-2zL3d " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h3"] + "",
	"infoText": "-AddMoneyOption-infoText-V-iMu " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"vector": "-AddMoneyOption-vector-17W_1",
	"contactUsInnerContent": "-AddMoneyOption-contactUsInnerContent-3FaDZ " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyTwoOriginal"] + ""
};