/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import sentryException from '../../util/sentryException';
import { login, loginVerifySMS, resendSMS } from '../../util/auth';
import BasePage from '../../components/BasePage/BasePage';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import getRecaptchaToken from '../../util/getRecaptchaToken';
import MessageCard from '../../components/MessageCard/MessageCard';
import WithIntercom from '../../components/WithIntercom/WithIntercom';
import { getVerifySmsErrorMessage } from '../../util/common';
import {
  getAuthToken,
  getUid,
  getIsAuthenticated,
  getAutoLogout,
  getNotificationMessage,
  getPreAuthEmail,
} from '../../redux/selectors';
import Login1 from '../../forms/LoginStep1';
import Login2 from '../../forms/LoginStep2';
import styles from './LoginController.css';

import { ACCOUNT_EXISTS_MESSAGE } from '../../util/loginPageMessages';

export class LoginController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.handleVerifySMS = this.handleVerifySMS.bind(this);
    this.handleResendSMS = this.handleResendSMS.bind(this);
  }

  handleLogin = async (values, { setSubmitting, setStatus }) => {
    const { email, password } = values;
    setStatus({ formError: null });
    this.setState({ step: 2 });
    try {
      const recaptchaToken = await getRecaptchaToken('login');
      await login(email, password, recaptchaToken);
    } catch (error) {
      setSubmitting(false);
      const errorMessage = get(error, 'response.error');
      if (errorMessage === 'Account Locked. Please reset your password.') {
        setStatus({ formError: errorMessage });
      } else {
        setStatus({ formError: 'Could not verify details' });
        sentryException(error, {
          section: 'login-step-1',
        });
      }
    }
  }

  async handleVerifySMS(values, { setSubmitting, setStatus }) {
    const { uid } = this.props;
    const { smsCode } = values;
    this.setState({ notificationMessage: null });
    if (!uid || !smsCode) {
      setStatus({ formError: 'Could not verify SMS' });
    } else {
      try {
        const token = await getRecaptchaToken('login_verify_sms');
        await loginVerifySMS(uid, smsCode, token);
        setSubmitting(false);
        setStatus({ formError: null });
      } catch (e) {
        setSubmitting(false);
        setStatus({ formError: getVerifySmsErrorMessage(get(e, 'response.error')) });
        sentryException(e, {
          section: 'login-sms-verification',
        });
      }
    }
  }

  async handleResendSMS(setStatus) {
    const { uid } = this.props;
    if (!uid) {
      setStatus({ formError: 'Could not resend SMS' });
      this.setState({ notificationMessage: null });
    } else {
      try {
        const token = await getRecaptchaToken('login_resend_sms');
        await resendSMS(uid, token);
        setStatus({ formError: null });
        this.setState({ notificationMessage: 'We\'ve sent you a new verification code, please disregard any old codes.' });
      } catch (e) {
        setStatus({ formError: 'Could not resend SMS' });
        this.setState({ notificationMessage: null });
      }
    }
  }

  getMessage = () => {
    const { notificationMessage } = this.state;
    const { notification, history } = this.props;

    const searchParams = new URLSearchParams(history.location.search);
    const accountExistsParam = searchParams.get('account-exists');

    if (accountExistsParam) return ACCOUNT_EXISTS_MESSAGE;
    return notificationMessage || notification;
  }

  render() {
    const { step } = this.state;
    const { twoFactorRequired, autoLogout, history } = this.props;

    let section = <h1 className="h2">{'No step'}</h1>;
    const searchParams = new URLSearchParams(history.location.search);
    const autoLogoutParam = searchParams.get('auto-logout');

    const message = this.getMessage();
    if (step === 1 || !twoFactorRequired) {
      section = (
        <React.Fragment>
          <div className={styles.registerLink}>
            {'New to Profile Pensions? '}
            <ButtonLink label="Sign up" to="/signup" font="bodyTwo" variant="primary" />
          </div>
          <Login1 onSubmit={this.handleLogin} />
          <p className={styles.recaptchaTxt}>{'This site is protected by reCAPTCHA and the Google '}
            <a href="https://policies.google.com/privacy">{'Privacy Policy'}</a>
            {' and '}
            <a href="https://policies.google.com/terms">{'Terms of Service'}</a>
            {' apply.'}
          </p>
        </React.Fragment>
      );
    } else if (step === 2 && twoFactorRequired) {
      section = (<Login2 onSubmit={this.handleVerifySMS} onResendSMS={this.handleResendSMS} />);
    }

    return (
      <WithIntercom>
        {
          (autoLogout || autoLogoutParam) && (
            <MessageCard top dataCy="session-expired-message">
              <div style={{ padding: 20 }}>{'Your session expired and you were automatically logged out.'}</div>
            </MessageCard>
          )
        }
        {message && <MessageCard>{message}</MessageCard>}
        <BasePage showFooter={false}>
          <section>
            <div className={styles.formContainer}>
              <h1 className={styles.heading}>{'Sign in'}</h1>
              {section}
              <br />
              {step === 2 && (
                <p className={`light ${styles.subtext}`}>
                  {'Having trouble? Email us at '}
                  <a href="mailto: contact@profilepensions.co.uk">{'contact@profilepensions.co.uk'}</a>
                </p>
              )}
              <br />
            </div>
          </section>
        </BasePage>
      </WithIntercom>
    );
  }
}

const mapStateToProps = (state) => ({
  twoFactorRequired: state.auth.twoFactorRequired,
  preAuthEmail: getPreAuthEmail(state),
  token: getAuthToken(state),
  uid: getUid(state),
  authenticated: getIsAuthenticated(state),
  autoLogout: getAutoLogout(state),
  notification: getNotificationMessage(state),
});

export default connect(mapStateToProps)(LoginController);
