import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import sentryException from '../../util/sentryException';

import { SIGNUP_API_URL } from '../../config';
import { setPreAuthEmail } from './auth';
import SystemError from '../../errors/SystemError';

// DASHBOARD
export const DASHBOARD_INVESTMENT_ADVICE_VIEW_LINK_CLICKED = 'pp/dashboard/INVESTMENT_ADVICE_VIEW_LINK_CLICKED';
const DASHBOARD_START_TRANSFER_CLICKED = 'pp/dashboard/START_TRANSFER_CLICKED';
const DASHBOARD_SERPS_TRANSFER_CLICKED = 'pp/dashboard/SERPS_TRANSFER_CLICKED';
const PLAN_SUMMARY_INVESTMENT_ADVICE_REPORT_CLICK = 'pp/investmentAdvice/PLAN_SUMMARY_INVESTMENT_ADVICE_REPORT_CLICK';
const DASHBOARD_PAGE_FROM_CDS = 'pp/dashboard/DASHBOARD_PAGE_FROM_CDS';
const SET_FORCE_MENTIONME_MODAL = 'pp/dashboard/SET_FORCE_MENTION_ME_MODAL';
const UNSET_FORCE_MENTIONME_MODAL = 'pp/dashboard/UNSET_FORCE_MENTION_ME_MODAL';
const SET_HAS_RECENTLY_ADDED_FIND_CHECK_AND_TRANSFER_PENSION = 'pp/signup/SET_HAS_RECENTLY_ADDED_FIND_CHECK_AND_TRANSFER_PENSION';
const TWO_FACTOR_AUTH_CTA_HAS_BEEN_CLICKED = 'pp/ongoing-dashboard/TWO_FACTOR_AUTH_CTA_HAS_BEEN_CLICKED';
const UNSET_TWO_FACTOR_AUTH_CTA_HAS_BEEN_CLICKED = 'pp/ongoing-dashboard/UNSET_TWO_FACTOR_AUTH_CTA_HAS_BEEN_CLICKED';

// PENSION TRANSFER PAGE
const PENSION_TRANSFER_PAGE_VIEW = 'pp/pensionTransfer/PAGE_VIEW';
const PENSION_TRANSFER_CLICKWRAP_ACCEPTED = 'pp/pensionTransfer/TRANSFER_CLICKWRAP_ACCEPTED';
const PENSION_TRANSFER_CLICKWRAP_OPENED = 'pp/pensionTransfer/PENSION_TRANSFER_CLICKWRAP_OPENED';
// PENSION TRANSFER DOCUMENTS
const PENSION_TRANSFER_INVESTMENT_ADVICE_REPORT_CLICKED = 'pp/pensionTransfer/INVESTMENT_ADVICE_REPORT_CLICKED';
const PENSION_TRANSFER_KEY_FEATURES_CLICKED = 'pp/pensionTransfer/KEY_FEATURES_CLICKED';
const PENSION_TRANSFER_TERMS_OF_BUSINESS_CLICKED = 'pp/pensionTransfer/TERMS_OF_BUSINESS_CLICKED';
const PENSION_TRANSFER_ILLUSTRATION_CLICKED = 'pp/pensionTransfer/ILLUSTRATION_CLICKED';

export const GET_LATEST_ADVICE = 'pp/investmentAdvice/GET_LATEST_ADVICE';
export const GET_LATEST_ADVICE_RECEIVED = 'pp/investmentAdvice/GET_LATEST_ADVICE_RECEIVED';
export const GET_LATEST_ADVICE_ERROR = 'pp/investmentAdvice/GET_LATEST_ADVICE_ERROR';

// Update Contact Details
export const UPDATE_CONTACT_NUMBER_DETAILS_DISPATCHED = 'pp/personalDetails/UPDATE_CONTACT_NUMBER_DETAILS_DISPATCHED';
export const UPDATE_CONTACT_NUMBER_DETAILS_FULFILLED = 'pp/personalDetails/UPDATE_CONTACT_NUMBER_DETAILS_FULFILLED';
export const UPDATE_CONTACT_NUMBER_DETAILS_REJECTED = 'pp/personalDetails/UPDATE_CONTACT_NUMBER_DETAILS_REJECTED';

// Update Address Details
export const UPDATE_ADDRESS_DETAILS_DISPATCHED = 'pp/personalDetails/UPDATE_ADDRESS_DETAILS_DISPATCHED';
export const UPDATE_ADDRESS_DETAILS_FULFILLED = 'pp/personalDetails/UPDATE_ADDRESS_DETAILS_FULFILLED';
export const UPDATE_ADDRESS_DETAILS_REJECTED = 'pp/personalDetails/UPDATE_ADDRESS_DETAILS_REJECTED';
export const UPDATE_ADDRESS_ADD_BUTTON_CLICKED = 'pp/personalDetails/UPDATE_ADDRESS_ADD_BUTTON_CLICKED';
export const UPDATE_ADDRESS_CANCEL_BUTTON_CLICKED = 'pp/personalDetails/UPDATE_ADDRESS_CANCEL_BUTTON_CLICKED';

// Update Previous Addresses
export const UPDATE_PREVIOUS_ADDRESSES_DETAILS_DISPATCHED = 'pp/personalDetails/UPDATE_PREVIOUS_ADDRESSES_DETAILS_DISPATCHED';
export const UPDATE_PREVIOUS_ADDRESSES_DETAILS_FULFILLED = 'pp/personalDetails/UPDATE_PREVIOUS_ADDRESSES_DETAILS_FULFILLED';
export const UPDATE_PREVIOUS_ADDRESSES_DETAILS_REJECTED = 'pp/personalDetails/UPDATE_PREVIOUS_ADDRESSES_DETAILS_REJECTED';
export const PREVIOUS_ADDRESSES_REMOVE_BUTTON_CLICKED = 'pp/personalDetails/PREVIOUS_ADDRESS_REMOVE_BUTTON_CLICKED';
export const PREVIOUS_ADDRESSES_ADD_BUTTON_CLICKED = 'pp/personalDetails/UPDATE_PREVIOUS_ADDRESSES_ADD_BUTTON_CLICKED';
export const PREVIOUS_ADDRESSES_CANCEL_BUTTON_CLICKED = 'pp/personalDetails/UPDATE_PREVIOUS_ADDRESSES_CANCEL_BUTTON_CLICKED';

// Update Signature
export const UPDATE_SIGNATURE_DISPATCHED = 'pp/personalDetails/UPDATE_SIGNATURE_DISPATCHED';
export const UPDATE_SIGNATURE_FULFILLED = 'pp/personalDetails/UPDATE_SIGNATURE_FULFILLED';
export const UPDATE_SIGNATURE_REJECTED = 'pp/personalDetails/UPDATE_SIGNATURE_REJECTED';

// Update National Insurance Number
export const UPDATE_NATIONAL_INSURANCE_NUMBER_DISPATCHED = 'pp/personalDetails/UPDATE_NATIONAL_INSURANCE_NUMBER_DISPATCHED';
export const UPDATE_NATIONAL_INSURANCE_NUMBER_FULFILLED = 'pp/personalDetails/UPDATE_NATIONAL_INSURANCE_NUMBER_FULFILLED';
export const UPDATE_NATIONAL_INSURANCE_NUMBER_REJECTED = 'pp/personalDetails/UPDATE_NATIONAL_INSURANCE_NUMBER_REJECTED';

// Update Marketing Preferences
export const UPDATE_MARKETING_PREFERENCES_DISPATCHED = 'pp/personalDetails/UPDATE_MARKETING_PREFERENCES_DISPATCHED';
export const UPDATE_MARKETING_PREFERENCES_FULFILLED = 'pp/personalDetails/UPDATE_MARKETING_PREFERENCES_FULFILLED';
export const UPDATE_MARKETING_PREFERENCES_REJECTED = 'pp/personalDetails/UPDATE_MARKETING_PREFERENCES_REJECTED';

export const UPDATE_PERSONAL_DETAILS_DISPATCHED = 'pp/missingDetails/UPDATE_PERSONAL_DETAILS_DISPATCHED';
export const UPDATE_PERSONAL_DETAILS_FULFILLED = 'pp/missingDetails/UPDATE_PERSONAL_DETAILS_FULFILLED';
export const UPDATE_PERSONAL_DETAILS_REJECTED = 'pp/missingDetails/UPDATE_PERSONAL_DETAILS_REJECTED';

export const ADD_TOPUP_PENSIONS_DISPATCHED = 'pp/investmentAdvice/ADD_TOPUP_PENSIONS_DISPATCHED';
export const ADD_TOPUP_PENSIONS_FULFILLED = 'pp/investmentAdvice/ADD_TOPUP_PENSIONS_FULFILLED';
export const ADD_TOPUP_PENSIONS_REJECTED = 'pp/investmentAdvice/ADD_TOPUP_PENSIONS_REJECTED';

export const PLAN_SUMMARY_CTA_CLICKED = 'pp/investmentAdvice/PLAN_SUMMARY_CTA_CLICKED';

const ADD_PENSIONS_THANK_YOU_PAGE_VIEW = 'pp/add-pensions/ADD_PENSIONS_THANK_YOU_PAGE_VIEW';

const ADD_MONEY_FROM_DASHBOARD_CLICKED = 'pp/dashboard/ADD_MONEY_FROM_DASHBOARD_CLICKED';
const ADD_PENSION_FROM_DASHBOARD_CLICKED = 'pp/dashboard/ADD_PENSION_FROM_DASHBOARD_CLICKED';

// Recommendation Page (Plan Summary)
const ADD_MONEY_FROM_RECOMMENDATION_DETAIL_CLICKED = 'pp/recommendationDetail/ADD_MONEY_FROM_RECOMMENDATION_DETAIL_CLICKED';
const ADD_PENSION_FROM_RECOMMENDATION_DETAIL_CLICKED = 'pp/recommendationDetail/ADD_PENSION_FROM_RECOMMENDATION_DETAIL_CLICKED';
const RECOMMENDATION_DETAIL_PAGE_LOADED = 'pp/recommendationDetail/RECOMMENDATION_DETAIL_PAGE_LOADED';
const INVESTMENT_ADVICE_REPORT_FROM_RECOMMENDATION_DETAIL_CLICKED = 'pp/recommendationDetail/INVESTMENT_ADVICE_REPORT_CLICKED';
const MESSAGE_ADVISER_FROM_RECOMMENDATION_DETAIL_CLICKED = 'pp/recommendationDetail/MESSAGE_ADVISER_FROM_RECOMMENDATION_DETAIL_CLICKED';
const OPEN_YOUR_RECOMMENDED_PENSION_PLAN_CLICKED = 'pp/recommendationDetail/OPEN_YOUR_RECOMMENDED_PENSION_PLAN_CLICKED';
const COMPARISON_GRID_SCROLL = 'pp/recommendationDetail/COMPARISON_GRID_SCROLL';

// MSM Recommendation Page (Plan Summary MSM)
const RECOMMENDATION_DETAIL_PAGE_LOADED_MSM = 'pp/recommendationDetail/RECOMMENDATION_DETAIL_PAGE_LOADED_MSM';
const ADD_MONEY_FROM_RECOMMENDATION_DETAIL_CLICKED_MSM = 'pp/recommendationDetail/ADD_MONEY_FROM_RECOMMENDATION_DETAIL_CLICKED_MSM';
const ADD_PENSION_FROM_RECOMMENDATION_DETAIL_CLICKED_MSM = 'pp/recommendationDetail/ADD_PENSION_FROM_RECOMMENDATION_DETAIL_CLICKED_MSM';
const INVESTMENT_ADVICE_REPORT_FROM_RECOMMENDATION_DETAIL_CLICKED_MSM = 'pp/recommendationDetail/INVESTMENT_ADVICE_REPORT_CLICKED_MSM';
const MESSAGE_ADVISER_FROM_RECOMMENDATION_DETAIL_CLICKED_MSM = 'pp/recommendationDetail/MESSAGE_ADVISER_FROM_RECOMMENDATION_DETAIL_CLICKED_MSM';
const OPEN_YOUR_RECOMMENDED_PENSION_PLAN_CLICKED_MSM = 'pp/recommendationDetail/OPEN_YOUR_RECOMMENDED_PENSION_PLAN_CLICKED_MSM';
const COMPARISON_GRID_SCROLL_MSM = 'pp/recommendationDetail/COMPARISON_GRID_SCROLL_MSM';

// Vulnerable customer page
export const VULNERABLE_CUSTOMER_PAGE_VIEW = 'pp/vulnerableCustomer/PAGE_VIEW';

export const planSummaryCtaClickedAction = () => ({ type: PLAN_SUMMARY_CTA_CLICKED });

export const addMoneyFromDashboardClickedAction = () => ({
  type: ADD_MONEY_FROM_DASHBOARD_CLICKED,
});
export const addPensionFromDashboardClickedAction = () => ({
  type: ADD_PENSION_FROM_DASHBOARD_CLICKED,
});

// Recommendation Page / MSM Recommendation Page (Plan Summary)
export const addMoneyFromRecommendationDetailClickedAction = (isMSMCustomer) => ({
  type: isMSMCustomer
    ? ADD_MONEY_FROM_RECOMMENDATION_DETAIL_CLICKED_MSM
    : ADD_MONEY_FROM_RECOMMENDATION_DETAIL_CLICKED,
});
export const addPensionFromRecommendationDetailClickedAction = (isMSMCustomer) => ({
  type: isMSMCustomer
    ? ADD_PENSION_FROM_RECOMMENDATION_DETAIL_CLICKED_MSM
    : ADD_PENSION_FROM_RECOMMENDATION_DETAIL_CLICKED,
});
export const recommendationDetailPageLoadedAction = (isMSMCustomer) => ({
  type: isMSMCustomer
    ? RECOMMENDATION_DETAIL_PAGE_LOADED_MSM
    : RECOMMENDATION_DETAIL_PAGE_LOADED,
});
export const reportFromRecommendationDetailClickedAction = (isMSMCustomer) => ({
  type: isMSMCustomer
    ? INVESTMENT_ADVICE_REPORT_FROM_RECOMMENDATION_DETAIL_CLICKED_MSM
    : INVESTMENT_ADVICE_REPORT_FROM_RECOMMENDATION_DETAIL_CLICKED,
});
export const messageAdviserFromRecommendationDetailClickedAction = (isMSMCustomer) => ({
  type: isMSMCustomer
    ? MESSAGE_ADVISER_FROM_RECOMMENDATION_DETAIL_CLICKED_MSM
    : MESSAGE_ADVISER_FROM_RECOMMENDATION_DETAIL_CLICKED,
});
export const openYourRecommendedPensionPlanClickedAction = (isMSMCustomer) => ({
  type: isMSMCustomer
    ? OPEN_YOUR_RECOMMENDED_PENSION_PLAN_CLICKED_MSM
    : OPEN_YOUR_RECOMMENDED_PENSION_PLAN_CLICKED,
});
export const comparisonGridScrollAction = (isMSMCustomer) => ({
  type: isMSMCustomer
    ? COMPARISON_GRID_SCROLL_MSM
    : COMPARISON_GRID_SCROLL,
});

export const setForceMentionMeModalAction = () => ({ type: SET_FORCE_MENTIONME_MODAL });
export const unsetForceMentionMeModalAction = () => ({ type: UNSET_FORCE_MENTIONME_MODAL });

export const setHasRecentlyAddedFindCheckAndTransferPensionAction = () => ({
  type: SET_HAS_RECENTLY_ADDED_FIND_CHECK_AND_TRANSFER_PENSION,
});

export const twoFactorAuthCTAHasBeenClicked = () => ({
  type: TWO_FACTOR_AUTH_CTA_HAS_BEEN_CLICKED,
});
export const unsetTwoFactorAuthCTAHasBeenClicked = () => ({
  type: UNSET_TWO_FACTOR_AUTH_CTA_HAS_BEEN_CLICKED,
});

export const investmentAdviceReportClick = () => ({
  type: PLAN_SUMMARY_INVESTMENT_ADVICE_REPORT_CLICK,
});

export const dispatchDashboardPageFromCds = () => ({
  type: DASHBOARD_PAGE_FROM_CDS,
});

export const vcCustomerPageView = () => ({
  type: VULNERABLE_CUSTOMER_PAGE_VIEW,
});

export const pensionTransferNavigationButtonClicked = () => ({
  type: DASHBOARD_START_TRANSFER_CLICKED,
});

export const serpsPensionsTransferNavigationButtonClicked = () => ({
  type: DASHBOARD_SERPS_TRANSFER_CLICKED,
});

export const pensionTransferPageView = () => ({
  type: PENSION_TRANSFER_PAGE_VIEW,
});

export const pensionTransferClickwrapAccepted = () => ({
  type: PENSION_TRANSFER_CLICKWRAP_ACCEPTED,
});

export const pensionTransferClickwrapOpened = () => ({
  type: PENSION_TRANSFER_CLICKWRAP_OPENED,
});

export const pensionTransferInvestmentAdviceReportClicked = () => ({
  type: PENSION_TRANSFER_INVESTMENT_ADVICE_REPORT_CLICKED,
});

export const pensionTransferKeyFeaturesClicked = () => ({
  type: PENSION_TRANSFER_KEY_FEATURES_CLICKED,
});

export const pensionTransferTermsOfBusinessClicked = () => ({
  type: PENSION_TRANSFER_TERMS_OF_BUSINESS_CLICKED,
});

export const pensionTransferIllustrationClicked = () => ({
  type: PENSION_TRANSFER_ILLUSTRATION_CLICKED,
});

export const addPensionThankYouPageView = () => ({
  type: ADD_PENSIONS_THANK_YOU_PAGE_VIEW,
});

export const updateContactNumberDetails = (data) => (async (dispatch) => {
  const updateDetails = firebase.app().functions('europe-west1').httpsCallable('updatePersonalDetails');

  dispatch({ type: UPDATE_CONTACT_NUMBER_DETAILS_DISPATCHED });

  try {
    await updateDetails(data);
    dispatch({ type: UPDATE_CONTACT_NUMBER_DETAILS_FULFILLED });
  } catch (e) {
    dispatch({ type: UPDATE_CONTACT_NUMBER_DETAILS_REJECTED });
    sentryException(e, {
      section: 'update-personal-details',
    });
    throw e;
  }
});

export const updateAddressAddButtonClicked = () => ({
  type: UPDATE_ADDRESS_ADD_BUTTON_CLICKED,
});

export const updateAddressCancelButtonClicked = () => ({
  type: UPDATE_ADDRESS_CANCEL_BUTTON_CLICKED,
});

export const updateAddressDetails = (data) => (async (dispatch) => {
  const updateDetails = firebase.app().functions('europe-west1').httpsCallable('updatePersonalDetails');

  dispatch({ type: UPDATE_ADDRESS_DETAILS_DISPATCHED });

  try {
    await updateDetails(data);
    dispatch({ type: UPDATE_ADDRESS_DETAILS_FULFILLED });
  } catch (e) {
    dispatch({ type: UPDATE_ADDRESS_DETAILS_REJECTED });
    sentryException(e, {
      section: 'update-personal-details',
    });
    throw e;
  }
});

export const previousAddressesAddButtonClicked = () => ({
  type: PREVIOUS_ADDRESSES_ADD_BUTTON_CLICKED,
});

export const previousAddressesCancelButtonClicked = () => ({
  type: PREVIOUS_ADDRESSES_CANCEL_BUTTON_CLICKED,
});

export const updatePreviousAddresses = (data) => (async (dispatch) => {
  const updateDetails = firebase.app().functions('europe-west1').httpsCallable('updatePersonalDetails');

  dispatch({ type: UPDATE_PREVIOUS_ADDRESSES_DETAILS_DISPATCHED });

  try {
    await updateDetails(data);
    dispatch({ type: UPDATE_PREVIOUS_ADDRESSES_DETAILS_FULFILLED });
  } catch (e) {
    dispatch({ type: UPDATE_PREVIOUS_ADDRESSES_DETAILS_REJECTED });
    sentryException(e, {
      section: 'update-personal-details',
    });
    throw e;
  }
});

export const updateSignature = (data) => (async (dispatch) => {
  const updateDetails = firebase.app().functions('europe-west1').httpsCallable('updatePersonalDetails');

  dispatch({ type: UPDATE_SIGNATURE_DISPATCHED });

  try {
    await updateDetails(data);
    dispatch({ type: UPDATE_SIGNATURE_FULFILLED });
  } catch (e) {
    dispatch({ type: UPDATE_SIGNATURE_REJECTED });
    sentryException(e, {
      section: 'update-personal-details',
    });
    throw e;
  }
});

export const updateNationalInsuranceNumber = (data) => (async (dispatch) => {
  const updateDetails = firebase.app().functions('europe-west1').httpsCallable('updatePersonalDetails');

  dispatch({ type: UPDATE_NATIONAL_INSURANCE_NUMBER_DISPATCHED });

  try {
    await updateDetails(data);
    dispatch({ type: UPDATE_NATIONAL_INSURANCE_NUMBER_FULFILLED });
  } catch (e) {
    dispatch({ type: UPDATE_NATIONAL_INSURANCE_NUMBER_REJECTED});
    sentryException(e, {
      section: 'update-personal-details',
    });
    throw e;
  }
});

export const updateMarketingPreferences = (data) => (async (dispatch) => {
  const updateDetails = firebase.app().functions('europe-west1').httpsCallable('updatePersonalDetails');

  dispatch({ type: UPDATE_MARKETING_PREFERENCES_DISPATCHED });

  try {
    await updateDetails(data);
    dispatch({ type: UPDATE_MARKETING_PREFERENCES_FULFILLED });
  } catch (e) {
    dispatch({ type: UPDATE_MARKETING_PREFERENCES_REJECTED });
    sentryException(e, {
      section: 'update-personal-details',
    });
    throw e;
  }
});

export const updatePersonalDetails = (data) => (async (dispatch) => {
  const updateDetails = firebase.app().functions('europe-west1').httpsCallable('updatePersonalDetails');

  dispatch({ type: UPDATE_PERSONAL_DETAILS_DISPATCHED });

  try {
    await updateDetails(data);
    dispatch({ type: UPDATE_PERSONAL_DETAILS_FULFILLED });
  } catch (e) {
    dispatch({ type: UPDATE_PERSONAL_DETAILS_REJECTED });
    sentryException(e, {
      section: 'update-personal-details',
    });
    throw e;
  }
});

const CREATE_ACCOUNT_CALLED = 'pp/investmentAdvice/CREATE_ACCOUNT';
const CREATE_ACCOUNT_SUCCESS = 'pp/investmentAdvice/CREATE_ACCOUNT_SUCCESS';
const CREATE_ACCOUNT_ERROR = 'pp/investmentAdvice/CREATE_ACCOUNT_ERROR';

export const dispatchCreateAccount = (signup, recaptchaToken) => (async (dispatch) => {
  const {
    firstName, lastName, salutation, email, password, utmFields, partner,
  } = signup;

  const fetchBody = {
    firstName, lastName, salutation, email, password, recaptchaToken, utmFields, partner,
  };

  let responseJson;
  let authToken;

  try {
    dispatch(setPreAuthEmail(email));
    dispatch({ type: CREATE_ACCOUNT_CALLED });
    const response = await fetch(SIGNUP_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'x-auth',
      },
      body: JSON.stringify(fetchBody),
    });

    if (response.ok) {
      responseJson = await response.json();
      authToken = response.headers.get('x-auth');
      dispatch({ type: CREATE_ACCOUNT_SUCCESS });
    } else {
      if (response.status > 499) {
        const errorMessage = await response.text();
        throw new SystemError(response.status, errorMessage);
      }
      throw new Error('customer-error');
    }
  } catch (err) {
    dispatch({ type: CREATE_ACCOUNT_ERROR });
    throw err;
  }
  return { ...responseJson, authToken };
});

const VALIDATE_POSTCODE_DOB_EMAIL_CALLED = 'pp/investmentAdvice/VALIDATE_POSTCODE_DOB_EMAIL_CALLED';
const VALIDATE_POSTCODE_DOB_EMAIL_SUCCESS = 'pp/investmentAdvice/VALIDATE_POSTCODE_DOB_EMAIL_SUCCESS';
const VALIDATE_POSTCODE_DOB_EMAIL_ERROR = 'pp/investmentAdvice/VALIDATE_POSTCODE_DOB_EMAIL_ERROR';

export const dispatchValidatePostcodeDobViaEmail = (
  customerDetails,
  recaptchaToken,
) => (async (dispatch) => {
  const { email, dateOfBirth: dob, postcode } = customerDetails;
  let response;

  const validatePostcodeDobViaEmail = firebase.app().functions('europe-west1').httpsCallable('validatePostcodeDobViaEmail');

  dispatch({ type: VALIDATE_POSTCODE_DOB_EMAIL_CALLED });

  try {
    response = await validatePostcodeDobViaEmail({
      email, dob, postcode, recaptchaToken,
    });
    dispatch({ type: VALIDATE_POSTCODE_DOB_EMAIL_SUCCESS });
  } catch (error) {
    dispatch({ type: VALIDATE_POSTCODE_DOB_EMAIL_ERROR });
    throw error;
  }

  return response;
});

const initialState = {
  letterGenerating: false,
  letterGenerated: false,
  letterGenerationError: false,
  newEvent: false,
  forceMentionMeModal: false,
  setHasRecentlyAddedFindCheckAndTransferPensionAction: false,
  twoFactorAuthCTAHasBeenClicked: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_FORCE_MENTIONME_MODAL:
      return {
        ...state,
        forceMentionMeModal: true,
      };
    case UNSET_FORCE_MENTIONME_MODAL:
      return {
        ...state,
        forceMentionMeModal: false,
      };
    case SET_HAS_RECENTLY_ADDED_FIND_CHECK_AND_TRANSFER_PENSION:
      return {
        ...state,
        setHasRecentlyAddedFindCheckAndTransferPensionAction: true,
      };
    case TWO_FACTOR_AUTH_CTA_HAS_BEEN_CLICKED:
      return {
        ...state,
        twoFactorAuthCTAHasBeenClicked: true,
      };
    case UNSET_TWO_FACTOR_AUTH_CTA_HAS_BEEN_CLICKED:
      return {
        ...state,
        twoFactorAuthCTAHasBeenClicked: false,
      };
    default:
      return state;
  }
}
