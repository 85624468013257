// Signup Flow
export const PENSION_DETAILS_SERPS = 'pp/signup/PENSION_DETAILS_SERPS';
export const PENSION_DETAILS_SERPS_LOA = 'pp/signup/PENSION_DETAILS_SERPS_LOA';
export const PENSION_DETAILS_SERPS_OOC = 'pp/signup/PENSION_DETAILS_SERPS_OOC';
export const PENSION_DETAILS_NON_SERPS_OOC = 'pp/signup/PENSION_DETAILS_NON_SERPS_OOC';
export const PENSION_DETAILS_NON_SERPS = 'pp/signup/PENSION_DETAILS_NON_SERPS';
export const ADVICE_SIGNUP_COMPLETE = 'pp/signup/ADVICE_SIGNUP_COMPLETE';
export const SERPS_SIGNUP_COMPLETE = 'pp/signup/SERPS_SIGNUP_COMPLETE';
export const NEW_SIGNUP_COMPLETE = 'pp/signup/NEW_SIGNUP_COMPLETE';
export const NEW_REGISTER_COMPLETE = 'pp/signup/NEW_REGISTER_COMPLETE';

// Personal Details Page
export const PERSONAL_DETAILS_TITLE_SELECTED = 'pp/personal-details/TITLE_SELECTED';
export const PERSONAL_DETAILS_DOB_DAY_SELECTED = 'pp/personal-details/DOB_DAY_SELECTED';
export const PERSONAL_DETAILS_DOB_MONTH_SELECTED = 'pp/personal-details/DOB_MONTH_SELECTED';
export const PERSONAL_DETAILS_DOB_YEAR_SELECTED = 'pp/personal-details/DOB_YEAR_SELECTED';
export const PERSONAL_DETAILS_MOBILE_NUMBER_TYPED = 'pp/personal-details/MOBILE_NUMBER_TYPED';
export const PERSONAL_DETAILS_HOME_NUMBER_TYPED = 'pp/personal-details/HOME_NUMBER_TYPED';
export const PERSONAL_DETAILS_NATIONAL_INSURANCE_TYPED = 'pp/personal-details/NATIONAL_INSURANCE_TYPED';
export const PERSONAL_DETAILS_NATIONAL_INSURANCE_DELETED = 'pp/personal-details/NATIONAL_INSURANCE_DELETED';
export const PERSONAL_DETAILS_POSTCODE_DELETED = 'pp/personal-details/POSTCODE_DELETED';
export const PERSONAL_DETAILS_POSTCODE_TYPED = 'pp/personal-details/POSTCODE_TYPED';
export const PERSONAL_DETAILS_PREVIOUS_ADDRESSES_POSTCODE_TYPED = 'pp/personal-details/PREVIOUS_ADDRESSES_POSTCODE_TYPED';
export const PERSONAL_DETAILS_PREVIOUS_ADDRESSES_POSTCODE_DELETED = 'pp/personal-details/PREVIOUS_ADDRESSES_POSTCODE_DELETED';
export const PERSONAL_DETAILS_FIND_ADDRESS_CLICKED = 'pp/personal-details/FIND_ADDRESS_CLICKED';
export const PERSONAL_DETAILS_ADDRESS_SELECTED = 'pp/personal-details/ADDRESS_SELECTED';
export const PERSONAL_DETAILS_ADDRESS_DROPDOWN_CLICKED = 'pp/personal-details/ADDRESS_DROPDOWN_CLICKED';
export const PERSONAL_DETAILS_CHANGE_ADDRESS_CLICKED = 'pp/personal-details/CHANGE_ADDRESS_CLICKED';
export const PERSONAL_DETAILS_ENTER_ADDRESS_MANUALLY_CLICKED = 'pp/personal-details/ENTER_ADDRESS_MANUALLY_CLICKED';
export const SIGNATURE_DRAWN = 'pp/personal-details/SIGNATURE_DRAWN';
export const SIGNATURE_SIZE_ERROR = 'pp/personal-details/SIGNATURE_SIZE_ERROR';
export const PERSONAL_DETAILS_COMPLETE = 'pp/personal-details/PERSONAL_DETAILS_COMPLETE';

export const PERSONAL_DETAILS_PASSWORD_TYPED = 'pp/signup/PASSWORD_TYPED';
export const PERSONAL_DETAILS_PASSWORD_DELETED = 'pp/signup/PASSWORD_DELETED';
export const PERSONAL_DETAILS_CONFIRM_PASSWORD_TYPED = 'pp/signup/CONFIRM_PASSWORD_TYPED';
export const PERSONAL_DETAILS_CONFIRM_PASSWORD_DELETED = 'pp/signup/CONFIRM_PASSWORD_DELETED';
export const DPA_PASSED = 'pp/signup/DPA_PASSED';
export const NINO_EXISTS = 'pp/signup/NINO_EXISTS';
export const DPA_FAILED = 'pp/signup/DPA_FAILED';
export const MAX_DPA_ATTEMPTS_REACHED = 'pp/signup/MAX_DPA_ATTEMPTS_REACHED';

export const PENSION_DETAILS_PROVIDER_DROPDOWN_CLICKED = 'pp/signup/PROVIDER_DROPDOWN_CLICKED';
export const PENSION_DETAILS_PROVIDER_DELETED = 'pp/signup/PROVIDER_DELETED';
export const PENSION_DETAILS_PROVIDER_SELECTED = 'pp/signup/PROVIDER_SELECTED';
export const PENSION_DETAILS_PROVIDER_OTHER_SELECTED = 'pp/signup/PROVIDER_OTHER_SELECTED';
export const PENSION_DETAILS_PROVIDER_OTHER_TYPED = 'pp/signup/PROVIDER_OTHER_TYPED';
export const PENSION_DETAILS_PROVIDER_OTHER_DELETED = 'pp/signup/PROVIDER_OTHER_DELETED';
export const PENSION_DETAILS_PROVIDER_TOOLTIP_CLICKED = 'pp/signup/PROVIDER_TOOLTIP_CLICKED';
export const PENSION_DETAILS_PROVIDER_NOT_FOUND = 'pp/signup/PROVIDER_NOT_FOUND';
export const PENSION_DETAILS_NOT_SURE_WHO_PENSION_PROVIDER_IS_LINK = 'pp/signup/NOT_SURE_WHO_PENSION_PROVIDER_IS_LINK';

export const PENSION_DETAILS_PROVIDER_OTHER_IS_OLD_PENSION_PROVIDER_ANSWERED = 'pp/signup/PROVIDER_OTHER_IS_OLD_PENSION_PROVIDER_ANSWERED';

export const PENSION_DETAILS_PROVIDER_OTHER_START_DATE_CLICKED = 'pp/signup/PROVIDER_OTHER_START_DATE_CLICKED';
export const PENSION_DETAILS_PROVIDER_OTHER_START_DATE_DELETED = 'pp/signup/PROVIDER_OTHER_START_DATE_DELETED';
export const PENSION_DETAILS_PROVIDER_OTHER_START_DATE_TYPED = 'pp/signup/PROVIDER_OTHER_START_DATE_TYPED';
export const PENSION_DETAILS_PROVIDER_OTHER_END_DATE_CLICKED = 'pp/signup/PROVIDER_OTHER_END_DATE_CLICKED';
export const PENSION_DETAILS_PROVIDER_OTHER_END_DATE_DELETED = 'pp/signup/PROVIDER_OTHER_END_DATE_DELETED';
export const PENSION_DETAILS_PROVIDER_OTHER_END_DATE_TYPED = 'pp/signup/PROVIDER_OTHER_END_DATE_TYPED';
export const PENSION_DETAILS_PROVIDER_OTHER_LOCATION_CLICKED = 'pp/signup/PROVIDER_OTHER_LOCATION_CLICKED';
export const PENSION_DETAILS_PROVIDER_OTHER_LOCATION_DELETED = 'pp/signup/PROVIDER_OTHER_LOCATION_DELETED';
export const PENSION_DETAILS_PROVIDER_OTHER_LOCATION_TYPED = 'pp/signup/PROVIDER_OTHER_LOCATION_TYPED';
export const PENSION_DETAILS_PROVIDER_OTHER_NAME_TOOLTIP_CLICKED = 'pp/signup/PROVIDER_OTHER_NAME_TOOLTIP_CLICKED';

export const PENSION_DETAILS_PROVIDER_OTHER_EMPLOYMENT_INDUSTRY_DELETED = 'pp/signup/PROVIDER_OTHER_EMPLOYMENT_INDUSTRY_DELETED';
export const PENSION_DETAILS_PROVIDER_OTHER_EMPLOYMENT_INDUSTRY_TYPED = 'pp/signup/PROVIDER_OTHER_EMPLOYMENT_INDUSTRY_TYPED';
export const PENSION_DETAILS_PROVIDER_OTHER_EMPLOYMENT_INDUSTRY_CLICKED = 'pp/signup/PROVIDER_OTHER_EMPLOYMENT_INDUSTRY_CLICKED';
export const PENSION_DETAILS_PROVIDER_OTHER_EMPLOYMENT_ADDITIONAL_INFORMATION_DELETED = 'pp/signup/PROVIDER_OTHER_EMPLOYMENT_ADDITIONAL_INFORMATION_DELETED';
export const PENSION_DETAILS_PROVIDER_OTHER_EMPLOYMENT_ADDITIONAL_INFORMATION_TYPED = 'pp/signup/PROVIDER_OTHER_EMPLOYMENT_ADDITIONAL_INFORMATION_TYPED';
export const PENSION_DETAILS_PROVIDER_OTHER_EMPLOYMENT_ADDITIONAL_INFORMATION_CLICKED = 'pp/signup/PROVIDER_OTHER_EMPLOYMENT_ADDITIONAL_INFORMATION_CLICKED';

export const PENSION_DETAILS_PENSION_REMOVED = 'pp/signup/PENSION_REMOVED';
export const PENSION_DETAILS_PENSION_ADDED = 'pp/signup/PENSION_ADDED';
export const PENSION_DETAILS_PENSION_FAILED = 'pp/signup/PENSION_FAILED';
export const PENSION_DETAILS_PENSION_CONFIRMED = 'pp/signup/PENSION_CONFIRMED';

export const PENSION_DETAILS_POLICY_FIELD_CLICKED = 'pp/signup/POLICY_FIELD_CLICKED';
export const PENSION_DETAILS_POLICY_TYPED = 'pp/signup/POLICY_TYPED';
export const PENSION_DETAILS_POLICY_DELETED = 'pp/signup/POLICY_DELETED';
export const PENSION_DETAILS_TYPED_PROVIDER_MATCHED_IN_LIST = 'pp/signup/TYPED_PROVIDER_MATCHED_IN_LIST';
export const PENSION_DETAILS_POLICY_TOOLTIP_CLICKED = 'pp/signup/POLICY_TOOLTIP_CLICKED';

export const PENSION_DETAILS_WORKPLACE_QUESTION_ANSWERED = 'pp/signup/WORKPLACE_QUESTION_ANSWERED';
export const PENSION_DETAILS_WORKPLACE_TOOLTIP_CLICKED = 'pp/signup/WORKPLACE_TOOLTIP_SELECTED';

export const PENSION_DETAILS_OUT_OF_SCOPE_PROVIDER_SELECTED = 'pp/signup/OUT_OF_SCOPE_PROVIDER_SELECTED';

export const ESTIMATED_VALUE_CLICKED = 'pp/signup/ESTIMATED_VALUE_CLICKED';
export const ESTIMATED_VALUE_TYPED = 'pp/signup/ESTIMATED_VALUE_TYPED';
export const ESTIMATED_VALUE_DELETED = 'pp/signup/ESTIMATED_VALUE_DELETED';

export const AUTHORISE_IMPLEMENT_PENSION_PLAN_SELECTED = 'pp/signup/AUTHORISE_IMPLEMENT_PENSION_PLAN_SELECTED';
export const AUTHORISE_IMPLEMENT_PENSION_PLAN_UNSELECTED = 'pp/signup/AUTHORISE_IMPLEMENT_PENSION_PLAN_UNSELECTED';
export const AUTHORISE_TRANSFER_BALANCE_OF_PENSIONS_SELECTED = 'pp/signup/AUTHORISE_TRANSFER_BALANCE_OF_PENSIONS_SELECTED';
export const AUTHORISE_TRANSFER_BALANCE_OF_PENSIONS_UNSELECTED = 'pp/signup/AUTHORISE_TRANSFER_BALANCE_OF_PENSIONS_UNSELECTED';

export const PENSION_DETAILS_SUBMITTED = 'pp/signup/PENSION_DETAILS_SUBMITTED';

export const MISSING_DETAILS_PAGE_LOADED = 'pp/signup/MISSING_DETAILS_PAGE_LOADED';
export const ADD_PENSION_PAGE_LOADED = 'pp/signup/ADD_PENSION_PAGE_LOADED';

export const PENSION_DETAILS_INVESTMENT_ADVICE_REPORT_CLICKED = 'pp/signup/INVESTMENT_ADVICE_REPORT_CLICKED';
export const PENSION_DETAILS_KEY_FEATURES_CLICKED = 'pp/signup/KEY_FEATURES_CLICKED';
export const PENSION_DETAILS_TERMS_OF_BUSINESS_CLICKED = 'pp/signup/TERMS_OF_BUSINESS_CLICKED';
export const PENSION_DETAILS_ILLUSTRATION_CLICKED = 'pp/signup/ILLUSTRATION_CLICKED';

export const MISSING_DETAILS_COMPLETE = 'pp/missingDetails/COMPLETE';
export const MISSING_DETAILS_MOBILE_NUMBER_TYPED = 'pp/missingDetails/MOBILE_NUMBER_TYPED';
export const MISSING_DETAILS_NINO_TYPED = 'pp/missingDetails/NINO_TYPED';
export const MISSING_DETAILS_NINO_DELETED = 'pp/missingDetails/NINO_DELETED';
export const MISSING_DETAILS_POSTCODE_TYPED = 'pp/missingDetails/POSTCODE_TYPED';
export const MISSING_DETAILS_POSTCODE_DELETED = 'pp/missingDetails_DELETED';
export const MISSING_DETAILS_SIGNATURE_DRAWN = 'pp/missingDetails/SIGNATURE_DRAWN';
export const MISSING_DETAILS_SIGNATURE_SIZE_ERROR = 'pp/missingDetails/SIGNATURE_SIZE_ERROR';

export const MISSING_DETAILS_FIND_ADDRESS_CLICKED = 'pp/missingDetails/FIND_ADDRESS_CLICKED';
export const MISSING_DETAILS_ADDRESS_SELECTED = 'pp/missingDetails/ADDRESS_SELECTED';
export const MISSING_DETAILS_ADDRESS_DROPWDOWN_CLICKED = 'pp/missingDetails/ADDRESS_DROPWDOWN_CLICKED';
export const MISSING_DETAILS_CHANGE_ADDRESS_CLICKED = 'pp/missingDetails/CHANGE_ADDRESS_CLICKED';
export const MISSING_DETAILS_ADDRESS_SAVE_SELECTED = 'pp/missingDetails/MISSING_DETAILS_ADDRESS_SAVE_SELECTED';

export const MISSING_DETAILS_NEXT_CLICKED = 'pp/missingDetails/NEXT_CLICKED';
export const MISSING_DETAILS_NINO_SUBMIT_CLICKED = 'pp/missingDetails/NINO_SUBMIT_CLICKED';
export const MISSING_DETAILS_SIGNATURE_SUBMIT_CLICKED = 'pp/missingDetails/SIGNATURE_SUBMIT_CLICKED';
export const MISSING_DETAILS_MOBILE_NUMBER_SUBMIT_CLICKED = 'pp/missingDetails/MOBILE_NUMBER_SUBMIT_CLICKED';
export const MISSING_DETAILS_RETURN_LINK_CLICKED = 'pp/missingDetails/RETURN_LINK_CLICKED';
export const MISSING_DETAILS_MESSAGE_ME_CLICKED = 'pp/missingDetails/MESSAGE_ME_CLICKED';
export const MISSING_DETAILS_MOBILE_NUMBER_SKIP_STEP_CLICKED = 'pp/missingDetails/MOBILE_NUMBER_SKIP_STEP_CLICKED';
export const MISSING_DETAILS_PREVIOUS_ADDRESS_SKIP_STEP_CLICKED = 'pp/missingDetails/PREVIOUS_ADDRESS_SKIP_STEP_CLICKED';
export const MISSING_DETAILS_PREVIOUS_ADDRESS_SUBMIT_CLICKED = 'pp/missingDetails/PREVIOUS_ADDRESS_SUBMIT_CLICKED';
export const MISSING_DETAILS_ADD_A_PREVIOUS_ADDRESS_STEP_CLICKED = 'pp/missingDetails/ADD_A_PREVIOUS_ADDRESS_STEP_CLICKED';
export const MISSING_DETAILS_EDIT_PREVIOUS_ADDRESS_CLICKED = 'pp/missingDetails/EDIT_PREVIOUS_ADDRESS_CLICKED';
export const MISSING_DETAILS_SAVE_PREVIOUS_ADDRESS_CLICKED = 'pp/missingDetails/SAVE_PREVIOUS_ADDRESS_CLICKED';
export const MISSING_DETAILS_PREVIOUS_ADDRESS_SAVE_CLICKED = 'pp/missingDetails/PREVIOUS_ADDRESS_SAVE_CLICKED';
export const MISSING_DETAILS_THANK_YOU_PAGE_LANDED = 'pp/missingDetails/MISSING_DETAILS_THANK_YOU_PAGE_LANDED';

export const newRegisterCompleteAction = () => ({
  type: NEW_REGISTER_COMPLETE,
});

export const missingDetailsFindAddressClicked = () => ({
  type: MISSING_DETAILS_FIND_ADDRESS_CLICKED,
});

export const missingDetailsAddressSelected = () => ({
  type: MISSING_DETAILS_ADDRESS_SELECTED,
});

export const missingDetailsAddressSaveSelected = () => ({
  type: MISSING_DETAILS_ADDRESS_SAVE_SELECTED,
});

export const missingDetailsAddressDropdownClicked = () => ({
  type: MISSING_DETAILS_ADDRESS_DROPWDOWN_CLICKED,
});

export const missingDetailsChangeAddressClicked = () => ({
  type: MISSING_DETAILS_CHANGE_ADDRESS_CLICKED,
});

export const missingDetailsCompleteAction = () => ({
  type: MISSING_DETAILS_COMPLETE,
});

export const missingDetailsMobileNumberTyped = () => ({
  type: MISSING_DETAILS_MOBILE_NUMBER_TYPED,
});

export const missingDetailsNationalInsuranceNumberTyped = () => ({
  type: MISSING_DETAILS_NINO_TYPED,
});

export const missingDetailsNationalInsuranceNumberDeleted = () => ({
  type: MISSING_DETAILS_NINO_DELETED,
});

export const missingDetailsPostcodeDeleted = () => ({
  type: MISSING_DETAILS_POSTCODE_DELETED,
});

export const missingDetailsPostcodeTyped = () => ({
  type: MISSING_DETAILS_POSTCODE_TYPED,
});

export const missingDetailsSignatureDrawnAction = () => ({
  type: MISSING_DETAILS_SIGNATURE_DRAWN,
});

export const missingDetailsSignatureSizeErrorAction = () => ({
  type: MISSING_DETAILS_SIGNATURE_SIZE_ERROR,
});

export const personalDetailsCompleteAction = () => ({
  type: PERSONAL_DETAILS_COMPLETE,
});

export const pensionDetailsSerpsAction = () => ({
  type: PENSION_DETAILS_SERPS,
});

export const pensionDetailsSerpsLoaAction = () => ({
  type: PENSION_DETAILS_SERPS_LOA,
});

export const pensionDetailsSerpsOocAction = () => ({
  type: PENSION_DETAILS_SERPS_OOC,
});

export const pensionDetailsNonSerpsOocAction = () => ({
  type: PENSION_DETAILS_NON_SERPS_OOC,
});

export const pensionDetailsNonSerpsAction = () => ({
  type: PENSION_DETAILS_NON_SERPS,
});

export const missingDetailsReturnLinkClickedAction = () => ({
  type: MISSING_DETAILS_RETURN_LINK_CLICKED,
});

export const nextClickedAction = () => ({
  type: MISSING_DETAILS_NEXT_CLICKED,
});

export const missingDetailsNinoSubmitClickedAction = () => ({
  type: MISSING_DETAILS_NINO_SUBMIT_CLICKED,
});

export const missingDetailsSignatureSubmitClickedAction = () => ({
  type: MISSING_DETAILS_SIGNATURE_SUBMIT_CLICKED,
});

export const missingDetailsMessageMeClickedAction = () => ({
  type: MISSING_DETAILS_MESSAGE_ME_CLICKED,
});

export const missingDetailsMobileNumberSubmitClickedAction = () => ({
  type: MISSING_DETAILS_MOBILE_NUMBER_SUBMIT_CLICKED,
});

export const missingDetailsMobileNumberSkipStepClickedAction = () => ({
  type: MISSING_DETAILS_MOBILE_NUMBER_SKIP_STEP_CLICKED,
});

export const missingDetailsPreviousAddressSkipStepClickedAction = () => ({
  type: MISSING_DETAILS_PREVIOUS_ADDRESS_SKIP_STEP_CLICKED,
});

export const missingDetailsPreviousAddressSubmitClickedAction = () => ({
  type: MISSING_DETAILS_PREVIOUS_ADDRESS_SUBMIT_CLICKED,
});

export const missingDetailsAddAPreviousAddressStepClickedAction = () => ({
  type: MISSING_DETAILS_ADD_A_PREVIOUS_ADDRESS_STEP_CLICKED,
});

export const missingDetailsEditPreviousAddressClickedAction = () => ({
  type: MISSING_DETAILS_EDIT_PREVIOUS_ADDRESS_CLICKED,
});

export const missingDetailsSavePreviousAddressClickedAction = () => ({
  type: MISSING_DETAILS_SAVE_PREVIOUS_ADDRESS_CLICKED,
});

export const missingDetailsPreviousAddressSaveClickedAction = () => ({
  type: MISSING_DETAILS_PREVIOUS_ADDRESS_SAVE_CLICKED,
});

export const missingDetailsThankYouPageLanded = () => ({
  type: MISSING_DETAILS_THANK_YOU_PAGE_LANDED,
});

// Provider events
export const pensionDetailsProviderDropdownClicked = () => ({
  type: PENSION_DETAILS_PROVIDER_DROPDOWN_CLICKED,
});

export const pensionDetailsProviderDeleted = () => ({
  type: PENSION_DETAILS_PROVIDER_DELETED,
});

export const pensionDetailsProviderSelected = () => ({
  type: PENSION_DETAILS_PROVIDER_SELECTED,
});

export const pensionDetailsProviderOtherSelected = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_SELECTED,
});

export const pensionDetailsProviderTooltipClicked = () => ({
  type: PENSION_DETAILS_PROVIDER_TOOLTIP_CLICKED,
});

export const pensionDetailsProviderNotFound = (value) => ({
  type: PENSION_DETAILS_PROVIDER_NOT_FOUND,
  metadata: value,
});

export const pensionDetailsNotSureWhoPensionProviderIsLink = () => ({
  type: PENSION_DETAILS_NOT_SURE_WHO_PENSION_PROVIDER_IS_LINK,
});

// Pension events
export const pensionDetailsPensionConfirmed = () => ({
  type: PENSION_DETAILS_PENSION_CONFIRMED,
});

export const pensionDetailsPensionRemoved = () => ({
  type: PENSION_DETAILS_PENSION_REMOVED,
});

export const pensionDetailsPensionAdded = () => ({
  type: PENSION_DETAILS_PENSION_ADDED,
});

export const pensionDetailsPensionFailed = () => ({
  type: PENSION_DETAILS_PENSION_FAILED,
});

export const pensionDetailsOutOfScopeProviderSelected = () => ({
  type: PENSION_DETAILS_OUT_OF_SCOPE_PROVIDER_SELECTED,
});

export const pensionDetailsIsOldPensionProviderAnswered = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_IS_OLD_PENSION_PROVIDER_ANSWERED,
});

export const pensionDetailsProviderEmploymentStartDateClicked = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_START_DATE_CLICKED,
});

export const pensionDetailsProviderEmploymentStartDateDeleted = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_START_DATE_DELETED,
});

export const pensionDetailsProviderEmploymentStartDateTyped = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_START_DATE_TYPED,
});

export const pensionDetailsProviderEmploymentEndDateClicked = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_END_DATE_CLICKED,
});

export const pensionDetailsProviderEmploymentEndDateDeleted = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_END_DATE_DELETED,
});

export const pensionDetailsProviderEmploymentEndDateTyped = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_END_DATE_TYPED,
});

export const pensionDetailsProviderLocationClicked = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_LOCATION_CLICKED,
});

export const pensionDetailsProviderLocationDeleted = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_LOCATION_DELETED,
});

export const pensionDetailsProviderLocationTyped = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_LOCATION_TYPED,
});

export const pensionDetailsProviderNameTooltipClicked = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_NAME_TOOLTIP_CLICKED,
});

export const pensionDetailsProviderEmploymentIndustryDeleted = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_EMPLOYMENT_INDUSTRY_DELETED,
});

export const pensionDetailsProviderEmploymentIndustryTyped = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_EMPLOYMENT_INDUSTRY_TYPED,
});

export const pensionDetailsProviderEmploymentIndustryClicked = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_EMPLOYMENT_INDUSTRY_CLICKED,
});

export const pensionDetailsProviderEmploymentAdditionalInformationDeleted = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_EMPLOYMENT_ADDITIONAL_INFORMATION_DELETED,
});
export const pensionDetailsProviderEmploymentAdditionalInformationTyped = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_EMPLOYMENT_ADDITIONAL_INFORMATION_TYPED,
});
export const pensionDetailsProviderEmploymentAdditionalInformationClicked = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_EMPLOYMENT_ADDITIONAL_INFORMATION_CLICKED,
});

// Policy events
export const pensionDetailsPolicyFieldClicked = () => ({
  type: PENSION_DETAILS_POLICY_FIELD_CLICKED,
});

export const pensionDetailsPolicyTyped = () => ({
  type: PENSION_DETAILS_POLICY_TYPED,
});

export const pensionDetailsPolicyDeleted = () => ({
  type: PENSION_DETAILS_POLICY_DELETED,
});

export const pensionDetailsOtherProviderTyped = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_TYPED,
});

export const pensionDetailsOtherProviderDeleted = () => ({
  type: PENSION_DETAILS_PROVIDER_OTHER_DELETED,
});

export const pensionDetailsTypedProviderMatchedInList = () => ({
  type: PENSION_DETAILS_TYPED_PROVIDER_MATCHED_IN_LIST,
});

export const pensionDetailsPolicyTooltipClicked = () => ({
  type: PENSION_DETAILS_POLICY_TOOLTIP_CLICKED,
});

// Estimated value

export const estimatedValueClicked = () => ({
  type: ESTIMATED_VALUE_CLICKED,
});

export const estimatedValueTyped = () => ({
  type: ESTIMATED_VALUE_TYPED,
});

export const estimatedValueDeleted = () => ({
  type: ESTIMATED_VALUE_DELETED,
});

// Workplace events
export const pensionDetailsWorkplaceQuestionAnswered = () => ({
  type: PENSION_DETAILS_WORKPLACE_QUESTION_ANSWERED,
});

export const pensionDetailsWorkplaceTooltipClicked = () => ({
  type: PENSION_DETAILS_WORKPLACE_TOOLTIP_CLICKED,
});

export const authoriseImplementPensionPlanSelected = () => ({
  type: AUTHORISE_IMPLEMENT_PENSION_PLAN_SELECTED,
});

export const authoriseImplementPensionPlanUnselected = () => ({
  type: AUTHORISE_IMPLEMENT_PENSION_PLAN_UNSELECTED,
});

export const authoriseTransferBalanceOfPensionSelected = () => ({
  type: AUTHORISE_TRANSFER_BALANCE_OF_PENSIONS_SELECTED,
});

export const authoriseTransferBalanceOfPensionUnselected = () => ({
  type: AUTHORISE_TRANSFER_BALANCE_OF_PENSIONS_UNSELECTED,
});

export const pensionDetailsSubmitted = () => ({
  type: PENSION_DETAILS_SUBMITTED,
});

export const missingDetailsPageLoaded = () => ({
  type: MISSING_DETAILS_PAGE_LOADED,
});

export const addPensionPageLoaded = () => ({
  type: ADD_PENSION_PAGE_LOADED,
});

export const pensionDetailsInvestmentAdviceReportClicked = () => ({
  type: PENSION_DETAILS_INVESTMENT_ADVICE_REPORT_CLICKED,
});

export const pensionDetailsKeyFeaturesClicked = () => ({
  type: PENSION_DETAILS_KEY_FEATURES_CLICKED,
});

export const pensionDetailsTermsOfBusinessClicked = () => ({
  type: PENSION_DETAILS_TERMS_OF_BUSINESS_CLICKED,
});

export const pensionDetailsIllustrationClicked = () => ({
  type: PENSION_DETAILS_ILLUSTRATION_CLICKED,
});

export const signatureDrawnAction = () => ({
  type: SIGNATURE_DRAWN,
});

export const signatureSizeErrorAction = () => ({
  type: SIGNATURE_SIZE_ERROR,
});

export const adviceSignupCompleteAction = () => ({
  type: ADVICE_SIGNUP_COMPLETE,
});

export const serpsSignupCompleteAction = () => ({
  type: SERPS_SIGNUP_COMPLETE,
});

// personal detail events

export const personalDetailsTitleSelected = () => ({
  type: PERSONAL_DETAILS_TITLE_SELECTED,
});

export const personalDetailsDobDaySelected = () => ({
  type: PERSONAL_DETAILS_DOB_DAY_SELECTED,
});

export const personalDetailsDobMonthSelected = () => ({
  type: PERSONAL_DETAILS_DOB_MONTH_SELECTED,
});

export const personalDetailsDobYearSelected = () => ({
  type: PERSONAL_DETAILS_DOB_YEAR_SELECTED,
});

export const personalDetailsMobileNumberTyped = () => ({
  type: PERSONAL_DETAILS_MOBILE_NUMBER_TYPED,
});

export const personalDetailsHomeNumberTyped = () => ({
  type: PERSONAL_DETAILS_HOME_NUMBER_TYPED,
});

export const personalDetailsNationalInsuranceNumberTyped = () => ({
  type: PERSONAL_DETAILS_NATIONAL_INSURANCE_TYPED,
});

export const personalDetailsNationalInsuranceNumberDeleted = () => ({
  type: PERSONAL_DETAILS_NATIONAL_INSURANCE_DELETED,
});

export const personalDetailsPostcodeDeleted = () => ({
  type: PERSONAL_DETAILS_POSTCODE_DELETED,
});

export const personalDetailsPostcodeTyped = () => ({
  type: PERSONAL_DETAILS_POSTCODE_TYPED,
});

export const personalDetailsFindAddressClicked = () => ({
  type: PERSONAL_DETAILS_FIND_ADDRESS_CLICKED,
});

export const personalDetailsAddressSelected = () => ({
  type: PERSONAL_DETAILS_ADDRESS_SELECTED,
});

export const personalDetailsAddressDropdownClicked = () => ({
  type: PERSONAL_DETAILS_ADDRESS_DROPDOWN_CLICKED,
});

export const personalDetailsChangeAddressClicked = () => ({
  type: PERSONAL_DETAILS_CHANGE_ADDRESS_CLICKED,
});

export const personalDetailsEnterAddressManuallyClicked = () => ({
  type: PERSONAL_DETAILS_ENTER_ADDRESS_MANUALLY_CLICKED,
});

export const personalDetailsPasswordTyped = () => ({
  type: PERSONAL_DETAILS_PASSWORD_TYPED,
});

export const personalDetailsPasswordDeleted = () => ({
  type: PERSONAL_DETAILS_PASSWORD_DELETED,
});

export const personalDetailsConfirmPasswordTyped = () => ({
  type: PERSONAL_DETAILS_CONFIRM_PASSWORD_TYPED,
});

export const personalDetailsConfirmPasswordDeleted = () => ({
  type: PERSONAL_DETAILS_CONFIRM_PASSWORD_DELETED,
});

export const personalDetailsPreviousAddressesPostCodeTyped = () => ({
  type: PERSONAL_DETAILS_PREVIOUS_ADDRESSES_POSTCODE_TYPED,
});

export const personalDetailsPreviousAddressesPostCodeDeleted = () => ({
  type: PERSONAL_DETAILS_PREVIOUS_ADDRESSES_POSTCODE_DELETED,
});

export const dpaPassed = () => ({
  type: DPA_PASSED,
});

export const ninoExists = () => ({
  type: NINO_EXISTS,
});

export const dpaFailed = () => ({
  type: DPA_FAILED,
});

export const maxDpaAttemptsReached = () => ({
  type: MAX_DPA_ATTEMPTS_REACHED,
});

export default function reducer(state = {}) {
  return state;
}

export const newSignupCompleteAction = () => ({ type: NEW_SIGNUP_COMPLETE });
