import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

import get from 'lodash/get';
import sentryException from '../../util/sentryException';

const CDS_DATA_LOADED_STATUS = 'pp/cds/CDS_DATA_LOADED_STATUS';
const CDS_DATA_LOAD_ERROR_STATUS = 'pp/cds/CDS_DATA_LOAD_ERROR_STATUS';
const POST_CDS_ANIMATION_LOADING_STATUS = 'pp/cds/POST_CDS_ANIMATION_LOADING_STATUS';
const PLAN_SUMMARY_PAGE_VISITED = 'pp/cds/PLAN_SUMMARY_PAGE_VISITED';
const PLAN_SUMMARY_PAGE_VISITED_MSM = 'pp/cds/PLAN_SUMMARY_PAGE_VISITED_MSM';

const CDS_UNKNOWN_PAGE_COMPLETE = 'pp/cds/CDS_UNKNOWN_PAGE_COMPLETE';
const CDS_GENDER_COMPLETE = 'pp/cds/CDS_GENDER_COMPLETE';
const CDS_MARITAL_STATUS_COMPLETE = 'pp/cds/CDS_MARITAL_STATUS_COMPLETE';
const CDS_DATE_OF_BIRTH_COMPLETE = 'pp/cds/CDS_DATE_OF_BIRTH_COMPLETE';
const CDS_EMPLOYMENT_STATUS_COMPLETE = 'pp/cds/CDS_EMPLOYMENT_STATUS_COMPLETE';
const CDS_UK_RESIDENT = 'pp/cds/CDS_UK_RESIDENT';
const CDS_UK_RESIDENT_AND_ADDRESS_COMPLETE = 'pp/cds/CDS_UK_RESIDENT_AND_ADDRESS_COMPLETE';
const CDS_CONTACT_NUMBER_COMPLETE = 'pp/cds/CDS_CONTACT_NUMBER_COMPLETE';
const CDS_INTENDED_RETIREMENT_AGE_COMPLETE = 'pp/cds/CDS_INTENDED_RETIREMENT_AGE_COMPLETE';
const CDS_INVESTED_FREQUENCY_COMPLETE = 'pp/cds/CDS_INVESTED_FREQUENCY_COMPLETE';
const CDS_ATR_OBJECTIVE = 'pp/cds/CDS_ATR_OBJECTIVE';
const CDS_DRAWDOWN_INTENTION = 'pp/cds/CDS_DRAWDOWN_INTENTION';
const CDS_LUMP_SUM = 'pp/cds/CDS_LUMP_SUM';
const CDS_LUMP_SUM_AGE = 'pp/cds/CDS_LUMP_SUM_AGE';
const CDS_LUMP_SUM_PERCENT = 'pp/cds/CDS_LUMP_SUM_PERCENT';
const CDS_ATR_RISK = 'pp/cds/CDS_ATR_RISK';
const CDS_ATR_AMOUNT = 'pp/cds/CDS_ATR_AMOUNT';
const CDS_CAPACITY_FOR_LOSS = 'pp/cds/CDS_CAPACITY_FOR_LOSS';
const CDS_ATR_TERM = 'pp/cds/CDS_ATR_TERM';
const CDS_ESG = 'pp/cds/CDS_ESG';
const CDS_ESG_ENVIRONMENT = 'pp/cds/CDS_ESG_ENVIRONMENT';
const CDS_ESG_GOVERNANCE = 'pp/cds/CDS_ESG_GOVERNANCE';
const CDS_ESG_SOCIAL = 'pp/cds/CDS_ESG_SOCIAL';
const CDS_VULNERABLE_CUSTOMER_HELP_NEEDED = 'pp/cds/CDS_VULNERABLE_CUSTOMER_HELP_NEEDED';
const CDS_VULNERABLE_CUSTOMER_SIGNIFICANT_EVENT = 'pp/cds/CDS_VULNERABLE_CUSTOMER_SIGNIFICANT_EVENT';
const CDS_CONFIRM_LOW_RISK = 'pp/cds/CDS_CONFIRM_LOW_RISK';
const CDS_CONFIRM_INCONSISTENT = 'pp/cds/CDS_CONFIRM_INCONSISTENT';
const CDS_INEXPERIENCED_INVESTOR = 'pp/cds/CDS_INEXPERIENCED_INVESTOR';
const CDS_HAS_MIDDLE_NAME = 'pp/cds/CDS_HAS_MIDDLE_NAME';
const CDS_MIDDLE_NAME = 'pp/cds/CDS_MIDDLE_NAME';
const CDS_HAS_PREVIOUS_NAMES = 'pp/cds/CDS_HAS_PREVIOUS_NAMES';
const CDS_PREVIOUS_NAMES = 'pp/cds/CDS_PREVIOUS_NAMES';
const CDS_HAS_PREVIOUS_TAX_RESIDENCY_OUTSIDE_UK = 'pp/cds/CDS_HAS_PREVIOUS_TAX_RESIDENCY_OUTSIDE_UK';
const CDS_COUNTRIES_OF_PREVIOUS_RESIDENCE = 'pp/cds/CDS_COUNTRIES_OF_PREVIOUS_RESIDENCE';
const CDS_BORN_IN_THE_UK = 'pp/cds/CDS_BORN_IN_THE_UK';
const CDS_COUNTRY_OF_BIRTH = 'pp/cds/CDS_COUNTRY_OF_BIRTH';
const ONGOING_CDS_GENDER_COMPLETE = 'pp/cds/ONGOING_CDS_GENDER_COMPLETE';
const ONGOING_CDS_MARITAL_STATUS_COMPLETE = 'pp/cds/ONGOING_CDS_MARITAL_STATUS_COMPLETE';
const ONGOING_CDS_DATE_OF_BIRTH_COMPLETE = 'pp/cds/ONGOING_CDS_DATE_OF_BIRTH_COMPLETE';
const ONGOING_CDS_EMPLOYMENT_STATUS_COMPLETE = 'pp/cds/ONGOING_CDS_EMPLOYMENT_STATUS_COMPLETE';
const ONGOING_CDS_UK_RESIDENT = 'pp/cds/ONGOING_CDS_UK_RESIDENT';
const ONGOING_CDS_UK_RESIDENT_AND_ADDRESS_COMPLETE = 'pp/cds/ONGOING_CDS_UK_RESIDENT_AND_ADDRESS_COMPLETE';
const ONGOING_CDS_CONTACT_NUMBER_COMPLETE = 'pp/cds/ONGOING_CDS_CONTACT_NUMBER_COMPLETE';
const ONGOING_CDS_INTENDED_RETIREMENT_AGE_COMPLETE = 'pp/cds/ONGOING_CDS_INTENDED_RETIREMENT_AGE_COMPLETE';
const ONGOING_CDS_INVESTED_FREQUENCY_COMPLETE = 'pp/cds/ONGOING_CDS_INVESTED_FREQUENCY_COMPLETE';
const ONGOING_CDS_ATR_OBJECTIVE = 'pp/cds/ONGOING_CDS_ATR_OBJECTIVE';
const ONGOING_CDS_DRAWDOWN_INTENTION = 'pp/cds/ONGOING_CDS_DRAWDOWN_INTENTION';
const ONGOING_CDS_LUMP_SUM = 'pp/cds/ONGOING_CDS_LUMP_SUM';
const ONGOING_CDS_LUMP_SUM_AGE = 'pp/cds/ONGOING_CDS_LUMP_SUM_AGE';
const ONGOING_CDS_LUMP_SUM_PERCENT = 'pp/cds/ONGOING_CDS_LUMP_SUM_PERCENT';
const ONGOING_CDS_ATR_RISK = 'pp/cds/ONGOING_CDS_ATR_RISK';
const ONGOING_CDS_ATR_AMOUNT = 'pp/cds/ONGOING_CDS_ATR_AMOUNT';
const ONGOING_CDS_CAPACITY_FOR_LOSS = 'pp/cds/ONGOING_CDS_CAPACITY_FOR_LOSS';
const ONGOING_CDS_ATR_TERM = 'pp/cds/ONGOING_CDS_ATR_TERM';
const ONGOING_CDS_ESG = 'pp/cds/ONGOING_CDS_ESG';
const ONGOING_CDS_ESG_ENVIRONMENT = 'pp/cds/ONGOING_CDS_ESG_ENVIRONMENT';
const ONGOING_CDS_ESG_GOVERNANCE = 'pp/cds/ONGOING_CDS_ESG_GOVERNANCE';
const ONGOING_CDS_ESG_SOCIAL = 'pp/cds/ONGOING_CDS_ESG_SOCIAL';
const ONGOING_CDS_VULNERABLE_CUSTOMER_HELP_NEEDED = 'pp/cds/ONGOING_CDS_VULNERABLE_CUSTOMER_HELP_NEEDED';
const ONGOING_CDS_VULNERABLE_CUSTOMER_SIGNIFICANT_EVENT = 'pp/cds/ONGOING_CDS_VULNERABLE_CUSTOMER_SIGNIFICANT_EVENT';
const ONGOING_CDS_CONFIRM_LOW_RISK = 'pp/cds/ONGOING_CDS_CONFIRM_LOW_RISK';
const ONGOING_CDS_CONFIRM_INCONSISTENT = 'pp/cds/ONGOING_CDS_CONFIRM_INCONSISTENT';
const ONGOING_CDS_INEXPERIENCED_INVESTOR = 'pp/cds/ONGOING_CDS_INEXPERIENCED_INVESTOR';
const ONGOING_CDS_HAS_MIDDLE_NAME = 'pp/cds/ONGOING_CDS_HAS_MIDDLE_NAME';
const ONGOING_CDS_MIDDLE_NAME = 'pp/cds/ONGOING_CDS_MIDDLE_NAME';
const ONGOING_CDS_HAS_PREVIOUS_NAMES = 'pp/cds/ONGOING_CDS_HAS_PREVIOUS_NAMES';
const ONGOING_CDS_PREVIOUS_NAMES = 'pp/cds/ONGOING_CDS_PREVIOUS_NAMES';
const ONGOING_CDS_HAS_PREVIOUS_TAX_RESIDENCY_OUTSIDE_UK = 'pp/cds/ONGOING_CDS_HAS_PREVIOUS_TAX_RESIDENCY_OUTSIDE_UK';
const ONGOING_CDS_COUNTRIES_OF_PREVIOUS_RESIDENCE = 'pp/cds/ONGOING_CDS_COUNTRIES_OF_PREVIOUS_RESIDENCE';
const ONGOING_CDS_BORN_IN_THE_UK = 'pp/cds/ONGOING_CDS_BORN_IN_THE_UK';
const ONGOING_CDS_COUNTRY_OF_BIRTH = 'pp/cds/ONGOING_CDS_COUNTRY_OF_BIRTH';

const CDS_COMPLETE = 'pp/cds/FB_CDS_COMPLETE';
const ONGOING_CDS_COMPLETE = 'pp/cds/ONGOING_FB_CDS_COMPLETE';

const CDS_COMPLETE_FIRE_TRADEDOUBLER_TAG = 'pp/cds/CDS_COMPLETE_FIRE_TRADEDOUBLER_TAG';

const CDS_LOADED = 'pp/cds/CDS_LOADED';
export const CDS_LOADING_ERROR = 'pp/cds/CDS_LOADING_ERROR';

const ONGOING_CDS_LOADED = 'pp/cds/ONGOING_CDS_LOADED';

const ASK_ADDITIONAL_QUESTIONS_DISPATCHED = 'pp/cds/ASK_ADDITIONAL_QUESTIONS_DISPATCHED';
const ASK_ADDITIONAL_QUESTIONS_FULFILLED = 'pp/cds/ASK_ADDITIONAL_QUESTIONS_FULFILLED';
const ASK_ADDITIONAL_QUESTIONS_REJECTED = 'pp/cds/ASK_ADDITIONAL_QUESTIONS_REJECTED';

const SIGN_UP_SERPS_TRACE_YES_CLICKED = 'pp/CDS/SIGN_UP_SERPS_TRACE_YES_CLICKED';

const actionMap = {
  'ongoing-cds-loaded': ONGOING_CDS_LOADED,
  'cds-loaded': CDS_LOADED,
  'ongoing-cds-completed': ONGOING_CDS_COMPLETE,
  'cds-completed': CDS_COMPLETE,
  gender: CDS_GENDER_COMPLETE,
  maritalStatus: CDS_MARITAL_STATUS_COMPLETE,
  dateOfBirth: CDS_DATE_OF_BIRTH_COMPLETE,
  employmentStatus: CDS_EMPLOYMENT_STATUS_COMPLETE,
  ukResident: CDS_UK_RESIDENT,
  ukResidentAndAddress: CDS_UK_RESIDENT_AND_ADDRESS_COMPLETE,
  contactNumber: CDS_CONTACT_NUMBER_COMPLETE,
  intendedRetirementAge: CDS_INTENDED_RETIREMENT_AGE_COMPLETE,
  investedFrequency: CDS_INVESTED_FREQUENCY_COMPLETE,
  atrObjective: CDS_ATR_OBJECTIVE,
  drawdownIntention: CDS_DRAWDOWN_INTENTION,
  lumpSum: CDS_LUMP_SUM,
  lumpSumAge: CDS_LUMP_SUM_AGE,
  lumpSumPercent: CDS_LUMP_SUM_PERCENT,
  atrRisk: CDS_ATR_RISK,
  atrAmount: CDS_ATR_AMOUNT,
  capacityForLoss: CDS_CAPACITY_FOR_LOSS,
  atrTerm: CDS_ATR_TERM,
  environmentalSocialGovernance: CDS_ESG,
  esgEnvironment: CDS_ESG_ENVIRONMENT,
  esgSocial: CDS_ESG_SOCIAL,
  esgGovernance: CDS_ESG_GOVERNANCE,
  vulnerableCustomerHelpNeeded: CDS_VULNERABLE_CUSTOMER_HELP_NEEDED,
  vulnerableCustomerSignificantEvent: CDS_VULNERABLE_CUSTOMER_SIGNIFICANT_EVENT,
  confirmLowRiskScore: CDS_CONFIRM_LOW_RISK,
  confirmInconsistentRiskScore: CDS_CONFIRM_INCONSISTENT,
  confirmInexperiencedInvestor: CDS_INEXPERIENCED_INVESTOR,
  hasMiddleName: CDS_HAS_MIDDLE_NAME,
  middleName: CDS_MIDDLE_NAME,
  hasPreviousNames: CDS_HAS_PREVIOUS_NAMES,
  previousNames: CDS_PREVIOUS_NAMES,
  hasPreviousTaxResidencyOutsideUk: CDS_HAS_PREVIOUS_TAX_RESIDENCY_OUTSIDE_UK,
  countriesOfPreviousResidence: CDS_COUNTRIES_OF_PREVIOUS_RESIDENCE,
  bornInTheUk: CDS_BORN_IN_THE_UK,
  countryOfBirth: CDS_COUNTRY_OF_BIRTH,
  'ongoing-gender': ONGOING_CDS_GENDER_COMPLETE,
  'ongoing-maritalStatus': ONGOING_CDS_MARITAL_STATUS_COMPLETE,
  'ongoing-dateOfBirth': ONGOING_CDS_DATE_OF_BIRTH_COMPLETE,
  'ongoing-employmentStatus': ONGOING_CDS_EMPLOYMENT_STATUS_COMPLETE,
  'ongoing-intendedRetirementAge': ONGOING_CDS_INTENDED_RETIREMENT_AGE_COMPLETE,
  'ongoing-ukResident': ONGOING_CDS_UK_RESIDENT,
  'ongoing-ukResidentAndAddress': ONGOING_CDS_UK_RESIDENT_AND_ADDRESS_COMPLETE,
  'ongoing-contactNumber': ONGOING_CDS_CONTACT_NUMBER_COMPLETE,
  'ongoing-investedFrequency': ONGOING_CDS_INVESTED_FREQUENCY_COMPLETE,
  'ongoing-atrObjective': ONGOING_CDS_ATR_OBJECTIVE,
  'ongoing-drawdownIntention': ONGOING_CDS_DRAWDOWN_INTENTION,
  'ongoing-lumpSum': ONGOING_CDS_LUMP_SUM,
  'ongoing-lumpSumAge': ONGOING_CDS_LUMP_SUM_AGE,
  'ongoing-lumpSumPercent': ONGOING_CDS_LUMP_SUM_PERCENT,
  'ongoing-atrRisk': ONGOING_CDS_ATR_RISK,
  'ongoing-atrAmount': ONGOING_CDS_ATR_AMOUNT,
  'ongoing-capacityForLoss': ONGOING_CDS_CAPACITY_FOR_LOSS,
  'ongoing-atrTerm': ONGOING_CDS_ATR_TERM,
  'ongoing-environmentalSocialGovernance': ONGOING_CDS_ESG,
  'ongoing-esgEnvironment': ONGOING_CDS_ESG_ENVIRONMENT,
  'ongoing-esgSocial': ONGOING_CDS_ESG_SOCIAL,
  'ongoing-esgGovernance': ONGOING_CDS_ESG_GOVERNANCE,
  'ongoing-vulnerableCustomerHelpNeeded': ONGOING_CDS_VULNERABLE_CUSTOMER_HELP_NEEDED,
  'ongoing-vulnerableCustomerSignificantEvent': ONGOING_CDS_VULNERABLE_CUSTOMER_SIGNIFICANT_EVENT,
  'ongoing-confirmLowRiskScore': ONGOING_CDS_CONFIRM_LOW_RISK,
  'ongoing-confirmInconsistentRiskScore': ONGOING_CDS_CONFIRM_INCONSISTENT,
  'ongoing-confirmInexperiencedInvestor': ONGOING_CDS_INEXPERIENCED_INVESTOR,
  'ongoing-hasMiddleName': ONGOING_CDS_HAS_MIDDLE_NAME,
  'ongoing-middleName': ONGOING_CDS_MIDDLE_NAME,
  'ongoing-hasPreviousNames': ONGOING_CDS_HAS_PREVIOUS_NAMES,
  'ongoing-previousNames': ONGOING_CDS_PREVIOUS_NAMES,
  'ongoing-hasPreviousTaxResidencyOutsideUk': ONGOING_CDS_HAS_PREVIOUS_TAX_RESIDENCY_OUTSIDE_UK,
  'ongoing-countriesOfPreviousResidence': ONGOING_CDS_COUNTRIES_OF_PREVIOUS_RESIDENCE,
  'ongoing-bornInTheUk': ONGOING_CDS_BORN_IN_THE_UK,
  'ongoing-countryOfBirth': ONGOING_CDS_COUNTRY_OF_BIRTH,
  'sign-up-serps-trace-yes-clicked': SIGN_UP_SERPS_TRACE_YES_CLICKED,
};

const publishCdsAction = (prefix, actionKey, payload) => {
  const action = actionMap[prefix + actionKey];
  return {
    type: action,
    payload,
  };
};

export const publishNextPageAction = (prefix, actionKey, flatCdsValues, skipSteps) => {
  let actionName;

  const address = get(flatCdsValues, 'answers.address');

  if (actionKey === 'ukResident' && address && !skipSteps.includes('address')) {
    const ukResidentAndAddressKey = 'ukResidentAndAddress';
    actionName = prefix + ukResidentAndAddressKey;
  } else {
    actionName = prefix + actionKey;
  }

  return {
    type: actionMap[actionName] || CDS_UNKNOWN_PAGE_COMPLETE,
  };
};

const publishCdsCompletedAction = (prefix) => (
  publishCdsAction(prefix, 'cds-completed')
);

const publishCdsLoadedAction = (prefix) => (
  publishCdsAction(prefix, 'cds-loaded')
);

const cdsDataLoadedStatusAction = (status) => ({
  type: CDS_DATA_LOADED_STATUS,
  payload: status,
});

const cdsDataLoadErrorStatusAction = (status) => ({
  type: CDS_DATA_LOAD_ERROR_STATUS,
  payload: status,
});

const postCdsAnimationLoadingAction = (isLoading) => ({
  type: POST_CDS_ANIMATION_LOADING_STATUS,
  payload: isLoading,
});

const planSummaryPageVisitedAction = (isLoading, msmCustomer) => ({
  type: msmCustomer ? PLAN_SUMMARY_PAGE_VISITED_MSM : PLAN_SUMMARY_PAGE_VISITED,
  payload: isLoading,
});

export const publishCdsCompletedTradedoublerTagFired = () => async (dispatch) => {
  dispatch({ type: CDS_COMPLETE_FIRE_TRADEDOUBLER_TAG });
};

export const publishCompletedCategory = (prefix, action) => (
  async (dispatch) => {
    dispatch(publishCdsAction(prefix, action));
  }
);

export const publishCdsCompleted = (prefix) => (
  async (dispatch) => {
    dispatch(publishCdsCompletedAction(prefix));
  }
);

export const publishCdsLoaded = (prefix) => (
  async (dispatch) => {
    dispatch(publishCdsLoadedAction(prefix));
  }
);

export const cdsDataLoadedStatus = (status) => (
  async (dispatch) => {
    dispatch(cdsDataLoadedStatusAction(status));
  }
);

export const cdsDataLoadErrorStatus = (status) => (
  async (dispatch) => {
    dispatch(cdsDataLoadErrorStatusAction(status));
  }
);

export const postCdsAnimationLoadingStatus = (status) => (
  async (dispatch) => {
    dispatch(postCdsAnimationLoadingAction(status));
  }
);

export const planSummaryPageVisitedStatus = (status, isMSMCustomer) => (
  async (dispatch) => {
    dispatch(planSummaryPageVisitedAction(status, isMSMCustomer));
  }
);

export const dispatchAskAdditionalQuestions = (cdsAnswers) => (
  async (dispatch) => {
    dispatch({ type: ASK_ADDITIONAL_QUESTIONS_DISPATCHED });
    try {
      const additionalQuestionsFunction = firebase.app().functions('europe-west1').httpsCallable('additionalQuestions');
      const { data } = await additionalQuestionsFunction({ answers: cdsAnswers });
      const { isInexperienced, shouldAskAtrTerm } = data;
      dispatch({ type: ASK_ADDITIONAL_QUESTIONS_FULFILLED });
      return { isInexperienced, shouldAskAtrTerm };
    } catch (error) {
      dispatch({ type: ASK_ADDITIONAL_QUESTIONS_REJECTED });
      sentryException(error, {
        section: 'failed-to-check-additional-questions',
      });
      return null;
    }
  }
);

const initialState = {
  cdsLoadingError: false,
  cdsDataLoaded: false,
  postCdsAnimationLoading: false,
  visitedPlanSummaryPage: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CDS_DATA_LOADED_STATUS:
      return {
        ...state,
        cdsDataLoaded: action.payload,
      };
    case CDS_DATA_LOAD_ERROR_STATUS:
      return {
        ...state,
        cdsLoadingError: action.payload,
      };
    case POST_CDS_ANIMATION_LOADING_STATUS:
      return {
        ...state,
        postCdsAnimationLoading: action.payload,
      };
    case PLAN_SUMMARY_PAGE_VISITED:
      return {
        ...state,
        visitedPlanSummaryPage: action.payload,
      };
    default:
      return state;
  }
}
