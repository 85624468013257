import React from 'react';
import { connect } from 'react-redux';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { ENABLE_TWO_FACTOR_AUTHENTICATION_CTA_CLICKED } from '../../redux/modules/notificationTracking';
import { getFirebaseUid } from '../../redux/selectors';
import { twoFactorAuthCTAHasBeenClicked } from '../../redux/modules/investmentAdvice';

const EnableTwoFactorAuthentication = ({
  dispatchTwoFactorAuthCTAHasBeenClicked,
}) => {
  return (
    <NotificationBanner
      alertCategory={'Blue'}
      alertTitle={'Enhance your account security'}
      expandableAlertDescription={'2-step verification adds an extra layer of security to your account by asking for a verification code when you sign in'}
      ctaButtonOptions={{
        label: 'Set up 2-step verification',
        size: 'medium',
        to: '/account-settings',
        event: ENABLE_TWO_FACTOR_AUTHENTICATION_CTA_CLICKED,
        handleClick: () => {
          dispatchTwoFactorAuthCTAHasBeenClicked();
        },
        dataCy: 'enable-2fa-authentication-link',
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
});

const mapDispatchToProps = ({
  dispatchTwoFactorAuthCTAHasBeenClicked: twoFactorAuthCTAHasBeenClicked,
});

export default connect(mapStateToProps, mapDispatchToProps)(EnableTwoFactorAuthentication);
