import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import AddPensionCard from '../../../components/AddPensionCard/AddPensionCard';
import AddMoneyCard from '../../../components/AddMoneyCard/AddMoneyCard';

import { getPensions } from '../../../redux/selectors/pensions';
import { getHasInProgressContributionInstruction, getHasInProgressLumpSumInstructions } from '../../../redux/selectors/investmentAdvice';

import styles from './AddPensionsOrContributionOption.css';

const AddPensionsOrContributionOption = ({
  heading,
  dispatchAddPensionClicked,
  dispatchAddMoneyClicked,
  pensions,
  hasInProgressInstructions,
  hasInProgressLumpSumInstructions,
  msmStyles,
  ctaRef,
}) => {
  const hasPensions = Boolean(pensions && pensions.length);
  const hasPensionOrInstruction = hasPensions || hasInProgressInstructions || hasInProgressLumpSumInstructions;

  return (
    <div ref={ctaRef}>
      <h3
        className={classNames({
          [styles.selectOptionSubheading]: !msmStyles,
          [styles.msmSelectOptionSubheading]: msmStyles,
        })}
      >
        {heading}
      </h3>
      <div
        className={
              classNames(
                styles.actionsContainer,
                {
                  [styles.singleColumn]: hasPensionOrInstruction,
                },
              )
            }
      >
        <AddPensionCard
          onClick={dispatchAddPensionClicked}
        />
        <AddMoneyCard
          onClick={dispatchAddMoneyClicked}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pensions: getPensions(state),
  hasInProgressInstructions: getHasInProgressLumpSumInstructions(state),
  hasInProgressLumpSumInstructions: getHasInProgressContributionInstruction(state),
});

AddPensionsOrContributionOption.defaultProps = {
  heading: 'Select an option to open your pension plan',
  dispatchAddPensionClicked: () => {},
  dispatchAddMoneyClicked: () => {},
};

export default connect(mapStateToProps)(AddPensionsOrContributionOption);
