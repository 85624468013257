import React from 'react';
import { withRouter } from 'react-router-dom';

function createLinkTo(ChildComponent) {
  class LinkTo extends React.Component {
    handleClick = (e) => {
      const {
        onClick,
        disabled,
        loading,
        to,
        search,
        link,
        back,
        history,
        target,
      } = this.props;
      if (
        (onClick && (!disabled && !loading))
        || (link && (!disabled && !loading))
        || (to && (!disabled && !loading))
      ) {
        e.preventDefault();
        if (onClick) {
          onClick(e);
        }
        if (target && link) {
          window.open(link, target);
        } else if (link) {
          window.location = link;
        } else if (to) {
          history.push({ pathname: to, search });
        }
      }
      if (back) {
        history.goBack();
      }
      return null;
    };

    render() {
      const { onClick, ...otherProps } = this.props;
      return <ChildComponent onClick={this.handleClick} {...otherProps} />;
    }
  }

  LinkTo.defaultProps = {
    to: null,
    link: null,
    back: false,
    disabled: false,
    loading: false,
  };

  return LinkTo;
}

const withLinkTo = (ChildComponent) => withRouter(createLinkTo(ChildComponent));

export default withLinkTo;
