import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import styles from './SetupWithdrawalPage.css';
import Button from '../../components/Button/Button';
import { currencyString } from '../../util/currency';
import CheckBoxGreen from '../../components/CheckBoxGreen/CheckBoxGreen';
import captureScreen from '../../util/captureScreenshot';
import sentryException from '../../util/sentryException';
import captureHtml from '../../util/captureHtml';
import {
  withdrawalSubmitStepVisitedAction,
  withdrawalSubmitClickedAction,
  withdrawalSubmitErrorAction,
  setupWithdrawalAction,
  changeBankDetailsClickedAction,
  changeWithdrawalAmountClickedAction,
  importantInformationLinkClickedAction,
  confirmWithdrawalSelectedAction,
  confirmWithdrawalUnselectedAction,
} from '../../redux/modules/withdrawal';
import ButtonLink from '../../components/ButtonLink/ButtonLink';

const formFieldsToSubmit = [
  'type',
  'healthIssues',
  'withdrawalType',
  'withdrawalAmount',
  'fundsUsedFor',
  'name',
  'accountNumber',
  'sortCode',
  'rollNo',
  'bankName',
  'confirmWithdrawal',
  'balanceAtRequestTime',
];

const WithdrawalReviewDetailsStep = ({
  step,
  formData,
  setFormData,
  setStep,
  setAmendMode,
  // events
  dispatchWithdrawalSubmitStepVisited,
  dispatchWithdrawalSubmitClicked,
  dispatchWithdrawalSubmitError,
  dispatchSetupWithdrawal,
  dispatchChangeBankDetailsClicked,
  dispatchChangeWithdrawalAmountButtonClicked,
  dispatchImportantInformationLinkClicked,
  dispatchConfirmWithdrawalUnselected,
  dispatchConfirmWithdrawalSelected,
}) => {
  const [submitError, setSubmitError] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const disabled = !formData.confirmWithdrawal;

  useEffect(() => {
    dispatchWithdrawalSubmitStepVisited();
    window.scrollTo(0, 0);
    setAmendMode(false);
  }, []);

  const onSubmit = debounce(async (e) => {
    try {
      e.preventDefault();
      dispatchWithdrawalSubmitClicked();
      setSubmitError(false);

      let screenshot;
      try {
        screenshot = await captureScreen();
      } catch (error) {
        sentryException(error, {
          section: 'setup-withdrawal-capture-screen',
        });
        throw error;
      }

      const pageHtml = captureHtml();
      const requestSubmittableFields = pick(formData, formFieldsToSubmit);

      const request = {
        ...requestSubmittableFields,
        type: `customer-withdrawal-${requestSubmittableFields.withdrawalType}`,
        screenshot,
        pageHtml,
        ongoing: true,
      };

      setSubmitInProgress(true);
      await dispatchSetupWithdrawal(request);
      setSubmitInProgress(false);
      setStep(step + 1);
    } catch (error) {
      setSubmitError(true);
      setSubmitInProgress(false);
      dispatchWithdrawalSubmitError();
      sentryException(error, {
        section: 'setup-withdrawal-submit',
      });
    }
  }, 3000, { leading: true, trailing: false });

  return (
    <React.Fragment>
      <h2 className={styles.accountDetailsHeading}>{'Withdrawal confirmation'}</h2>
      <p className={styles.accountDetailsBody}>
        {'Please check the following details are correct before you continue.'}
      </p>

      <div className={styles.confirmation}>
        <div className={styles.confirmationHeading}>{'ACCOUNT HOLDER NAME'}</div>
        <div className={styles.confirmationBody}>{formData.name}</div>
        <div className={styles.confirmationDivider} />
        <div className={styles.confirmationHeading}>{'ACCOUNT NO.'}</div>
        <div className={styles.confirmationBody}>{formData.accountNumber}</div>
        <div className={styles.confirmationDivider} />
        <div className={styles.confirmationHeading}>{'SORT CODE'}</div>
        <div className={styles.confirmationBody}>{formData.sortCode}</div>
        <div className={styles.confirmationDivider} />
        <div className={styles.confirmationHeading}>{'BANK NAME'}</div>
        <div className={styles.confirmationBody}>{formData.bankName}</div>
        {formData.rollNo && (
          <React.Fragment>
            <div className={styles.confirmationDivider} />
            <div className={styles.confirmationHeading}>{'ROLL NUMBER'}</div>
            <div className={styles.confirmationBody}>{formData.rollNo}</div>
          </React.Fragment>
        )}
        <Button
          variant="secondary"
          size="large"
          type="button"
          onClick={() => {
            dispatchChangeBankDetailsClicked();
            setStep(2);
            setAmendMode(true);
          }}
          label="Change bank details"
        />
        <div className={styles.confirmationDivider} />
        <div className={styles.confirmationHeading}>
          {`${formData.withdrawalType.replace('-', ' ') || ''} WITHDRAWAL`}
        </div>
        <div
          className={styles.withdrawal}
        >
          {currencyString(formData.withdrawalAmount, true)}
        </div>
        <Button
          variant="secondary"
          size="large"
          onClick={() => {
            dispatchChangeWithdrawalAmountButtonClicked();
            setStep(1);
            setAmendMode(true);
          }}
          label="Amend withdrawal"
        />
      </div>
      <h3 className={styles.tickboxHeading}>{'Please read and confirm the following:'}</h3>
      <React.Fragment>
        <CheckBoxGreen
          name="confirmWithdrawal"
          label={(
            <span>
              {'I confirm that I have read and understood the '}
              <ButtonLink
                label={'important information'}
                link={'/aegon-withdrawals'}
                onClick={dispatchImportantInformationLinkClicked}
                target="_blank"
                font="inherit"
                underline
              />
              {' and that the above details are correct.'}
            </span>
          )}
          value={formData.confirmWithdrawal}
          defaultValue={false}
          onChange={() => {
            if (formData.confirmWithdrawal) {
              dispatchConfirmWithdrawalUnselected();
            } else {
              dispatchConfirmWithdrawalSelected();
            }
            setFormData({
              ...formData,
              confirmWithdrawal: !formData.confirmWithdrawal,
            });
          }}
          wrapperStyles={{ marginBottom: '13px' }}
        />
        <div className={styles.divider} />
      </React.Fragment>
      {submitError && <p className={styles.submitError}>{'Something went wrong - please try again'}</p>}
      <Button
        size="large"
        disabled={disabled}
        type="button"
        onClick={onSubmit}
        label="Confirm withdrawal"
        wrapperStyles={{ margin: '0 0 40px' }}
        loading={submitInProgress}
      />
    </React.Fragment>
  );
};

WithdrawalReviewDetailsStep.propTypes = {
  step: PropTypes.number.isRequired,
  // eslint-disable-next-line
  formData: PropTypes.object,
  setFormData: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  setAmendMode: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchWithdrawalSubmitStepVisited: withdrawalSubmitStepVisitedAction,
  dispatchWithdrawalSubmitClicked: withdrawalSubmitClickedAction,
  dispatchWithdrawalSubmitError: withdrawalSubmitErrorAction,
  dispatchSetupWithdrawal: setupWithdrawalAction,
  dispatchChangeBankDetailsClicked: changeBankDetailsClickedAction,
  dispatchChangeWithdrawalAmountButtonClicked: changeWithdrawalAmountClickedAction,
  dispatchImportantInformationLinkClicked: importantInformationLinkClickedAction,
  dispatchConfirmWithdrawalSelected: confirmWithdrawalSelectedAction,
  dispatchConfirmWithdrawalUnselected: confirmWithdrawalUnselectedAction,
};

export default connect(null, mapDispatchToProps)(WithdrawalReviewDetailsStep);
