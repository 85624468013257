import React from 'react';

import NotificationBanner from '../NotificationBanner/NotificationBanner';

const RecommendedPensionPlanNotification = () => {
  return (
    <NotificationBanner
      alertCategory={'Green'}
      alertTitle={'We’re preparing our recommendation'}
      alertDescription={'We are working on your pension plan and will email you when our recommendation is ready.'}
    />
  );
};

export default RecommendedPensionPlanNotification;
