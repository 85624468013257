import { getFirestore } from 'redux-firestore';
import { ONGOING_RESULTS } from '../../util/constants';

const firestore = getFirestore();

export const HMRC_RESPONSE_LINK_CLICKED = 'pp/dashboard/HMRC_RESPONSE_LINK_CLICKED';
export const CDS_LINK_CLICKED = 'pp/dashboard/CDS_LINK_CLICKED';
export const INITIAL_SIGNUP_CDS_LINK_CLICKED = 'pp/dashboard/INITIAL_SIGNUP_CDS_LINK_CLICKED';
export const ACCEPT_ADVICE_LINK_CLICKED = 'pp/dashboard/ACCEPT_ADVICE_LINK_CLICKED';
export const BOOK_APPOINTMENT_LINK_CLICKED = 'pp/dashboard/BOOK_APPOINTMENT_LINK_CLICKED';
export const PAGE_VIEW = 'pp/dashboard/PAGE_VIEW';
export const SEND_EMAIL_LINK_CLICKED = 'pp/dashboard/SEND_EMAIL_LINK_CLICKED';
export const COMPLETE_PERSONAL_DETAILS_LINK_CLICKED = 'pp/dashboard/COMPLETE_PERSONAL_DETAILS_LINK_CLICKED';
export const ADD_PENSIONS_LINK_CLICKED = 'pp/dashboard/ADD_PENSIONS_LINK_CLICKED';
export const ONGOING_ADD_PENSIONS_LINK_CLICKED = 'pp/dashboard/ONGOING_ADD_PENSIONS_LINK_CLICKED';

export const INITIAL_SIGNUP_PAGE_VIEW = 'pp/dashboard/INITIAL_SIGNUP_PAGE_VIEW';
export const ONGOING_PAGE_VIEW = 'pp/ongoing-dashboard/PAGE_VIEW';
export const OPTOUT_PAGE_VIEW = 'pp/ongoing-dashboard/OPTOUT_PAGE_VIEW';
export const ONGOING_MISSING_RP_PAGE_VIEW = 'pp/ongoing-dashboard/MISSING_RP_PAGE_VIEW';
export const ONGOING_CDS_LINK_CLICKED = 'pp/ongoing-dashboard/ONGOING_CDS_LINK_CLICKED';
export const CDS_15_MONTH_HARD_BLOCK_MODAL_LINK_CLICKED = 'pp/ongoing-dashboard/CDS_15_MONTH_HARD_BLOCK_MODAL_LINK_CLICKED';
export const CDS_15_MONTH_HARD_BLOCK_MODAL_VIEWED = 'pp/ongoing-dashboard/CDS_15_MONTH_HARD_BLOCK_MODAL_VIEWED';
export const ONGOING_BOOK_APPOINTMENT_LINK_CLICKED = 'pp/ongoing-dashboard/ONGOING_BOOK_APPOINTMENT_LINK_CLICKED';
export const ONGOING_ACCEPT_ADVICE_LINK_CLICKED = {
  [ONGOING_RESULTS.CHANGE_FUND]: 'pp/ongoing-dashboard/ONGOING_CHANGE_FUND_ACCEPT_ADVICE_LINK_CLICKED',
  [ONGOING_RESULTS.REMOVE_LIFESTYLING]: 'pp/ongoing-dashboard/ONGOING_REMOVE_LIFESTYLING_ACCEPT_ADVICE_LINK_CLICKED',
  [ONGOING_RESULTS.CHANGE_PROVIDER]: 'pp/ongoing-dashboard/ONGOING_CHANGE_PROVIDER_ACCEPT_ADVICE_LINK_CLICKED',
};
export const ONGOING_VIEW_ADVICE_CLICKED = {
  [ONGOING_RESULTS.CHANGE_FUND]: 'pp/ongoing-dashboard/ONGOING_CHANGE_FUND_VIEW_LINK_CLICKED',
  [ONGOING_RESULTS.REMOVE_LIFESTYLING]: 'pp/ongoing-dashboard/ONGOING_REMOVE_LIFESTYLING_VIEW_ADVICE_LINK_CLICKED',
  [ONGOING_RESULTS.CHANGE_PROVIDER]: 'pp/ongoing-dashboard/ONGOING_CHANGE_PROVIDER_VIEW_LINK_CLICKED',
  [ONGOING_RESULTS.NO_SWITCH]: 'pp/ongoing-dashboard/ONGOING_NO_SWITCH_VIEW_LINK_CLICKED',
};
export const ONGOING_CLOSE_NO_ACTION_NOTIF_CLICKED = 'pp/ongoingdashboard/ONGOING_CLOSE_NO_ACTION_NOTIF_CLICKED';
export const ONGOING_CLOSE_ADVICE_ACCEPTED_NOTIF_CLICKED = 'pp/ongoingdashboard/ONGOING_CLOSE_ADVICE_ACCEPTED_NOTIF_CLICKED';
export const MENTION_ME_LINK_CLICKED = 'pp/dashboard/MENTION_ME_LINK_CLICKED';
export const MISSING_DETAILS_ALERT_LINK_CLICKED = 'pp/dashboard/MISSING_DETAILS_ALERT_LINK_CLICKED';
export const SERPS_ADD_PENSIONS_LINK_CLICKED = 'pp/dashboard/SERPS_ADD_PENSIONS_LINK_CLICKED';

export const ADD_MONEY_LINK_CLICKED = 'pp/dashboard/ADD_MONEY_LINK_CLICKED';
export const ONGOING_ADD_MONEY_LINK_CLICKED = 'pp/dashboard/ONGOING_ADD_MONEY_LINK_CLICKED';

export const initialSignupCdsLinkClickedAction = () => ({ type: INITIAL_SIGNUP_CDS_LINK_CLICKED });

export const initialSignupPageViewAction = () => ({ type: INITIAL_SIGNUP_PAGE_VIEW });

export const ongoingPageViewAction = () => ({ type: ONGOING_PAGE_VIEW });

export const addPensionsLinkClicked = () => ({
  type: ADD_PENSIONS_LINK_CLICKED,
});

export const ongoingAddPensionsLinkClicked = () => ({
  type: ONGOING_ADD_PENSIONS_LINK_CLICKED,
});

export const missingDetailsAlertLinkClicked = () => ({
  type: MISSING_DETAILS_ALERT_LINK_CLICKED,
});

export const addMoneyLinkClicked = () => ({
  type: ADD_MONEY_LINK_CLICKED,
});

export const ongoingAddMoneyLinkClicked = () => ({
  type: ONGOING_ADD_MONEY_LINK_CLICKED,
});

export const WITHDRAWAL_LINK_CLICKED = 'pp/dashboard/WITHDRAWAL_LINK_CLICKED';

export const withdrawalLinkClicked = () => ({
  type: WITHDRAWAL_LINK_CLICKED,
});

export const mentionMeModalForceShownAction = (isOngoing) => ({
  type: isOngoing ? 'MENTION_ME_MODAL_FORCE_SHOWN_ONGOING' : 'MENTION_ME_MODAL_FORCE_SHOWN_ONBOARDING',
});

// Explainer animation post-sign up dashboard events
export const postSignUpPageView = (isFirstSession) => (dispatch) => dispatch({
  type: isFirstSession ? 'pp/dashboard/POST_SIGN_UP_PAGE_VIEW_FIRST_SESSION' : 'pp/dashboard/POST_SIGN_UP_PAGE_VIEW_RTN_SESSION',
});

export const signUpCdsCTAClicked = (isFirstSession) => (dispatch) => dispatch({
  type: isFirstSession ? 'pp/dashboard/SIGN_UP_CDS_CTA_CLICKED_FIRST_SESSION' : 'pp/dashboard/SIGN_UP_CDS_CTA_CLICKED_RTN_SESSION',
});

export const messageMeCTAClicked = (isFirstSession) => (dispatch) => dispatch({
  type: isFirstSession ? 'pp/dashboard/MESSAGE_ME_CTA_CLICKED_FIRST_SESSION' : 'pp/dashboard/MESSAGE_ME_CTA_CLICKED_RTN_SESSION',
});

export const animationVideoStarted = (isFirstSession) => (dispatch) => dispatch({
  type: isFirstSession ? 'pp/dashboard/ANIMATION_VIDEO_STARTED_FIRST_SESSION' : 'pp/dashboard/ANIMATION_VIDEO_STARTED_RTN_SESSION',
});

export const animationVideoEnded = (isFirstSession, timeElapsed) => (dispatch) => dispatch({
  type: isFirstSession ? 'pp/dashboard/ANIMATION_VIDEO_ENDED_FIRST_SESSION' : 'pp/dashboard/ANIMATION_VIDEO_ENDED_RTN_SESSION',
  metadata: { timeElapsed },
});

export const animationVideoInterrupted = (isFirstSession, timeElapsed) => (dispatch) => dispatch({
  type: isFirstSession ? 'pp/dashboard/ANIMATION_VIDEO_INTERRUPTED_FIRST_SESSION' : 'pp/dashboard/ANIMATION_VIDEO_INTERRUPTED_RTN_SESSION',
  metadata: { timeElapsed },
});

// ONGOING DASHBOARD and PLAN ACTIVITY
export const ONGOING_ASSUMPTIONS_MODAL_CLICKED = 'pp/ongoing-dashboard/ASSUMPTIONS_MODAL_CLICKED';
export const PLAN_ACTIVITY_ASSUMPTIONS_MODAL_CLICKED = 'pp/plan-activity/ASSUMPTIONS_MODAL_CLICKED';
const ONGOING_ESTIMATED_CALCULATOR_CLICKED = 'pp/ongoing-dashboard/ESTIMATED_CALCULATOR_CLICKED';
const ONGOING_CONTRIBUTION_WIDGET_CLICKED = 'pp/ongoing-dashboard/CONTRIBUTION_WIDGET_CLICKED';
const ONGOING_ADD_PENSIONS_WIDGET_CLICKED = 'pp/ongoing-dashboard/ADD_PENSIONS_WIDGET_CLICKED';
const ONGOING_MESSAGE_ME_WIDGET_CLICKED = 'pp/ongoing-dashboard/MESSAGE_ME_WIDGET_CLICKED';
const ONGOING_PLAN_ACTIVITY_WIDGET_CLICKED = 'pp/ongoing-dashboard/PLAN_ACTIVITY_WIDGET_CLICKED';
const ONGOING_WITHDRAWAL_WIDGET_CLICKED = 'pp/ongoing-dashboard/WITHDRAWAL_WIDGET_CLICKED';
export const ONGOING_GET_PROJECTION_DISPATCHED = 'pp/ongoing-dashboard/GET_PROJECTION_DISPATCHED';
export const ONGOING_GET_PROJECTION_FULFILLED = 'pp/ongoing-dashboard/GET_PROJECTION_FULFILLED';
export const ONGOING_GET_PROJECTION_REJECTED = 'pp/ongoing-dashboard/GET_PROJECTION_REJECTED';
export const PLAN_ACTIVITY_GET_PROJECTION_DISPATCHED = 'pp/plan-activity/GET_PROJECTION_DISPATCHED';
export const PLAN_ACTIVITY_GET_PROJECTION_FULFILLED = 'pp/plan-activity/GET_PROJECTION_FULFILLED';
export const PLAN_ACTIVITY_GET_PROJECTION_REJECTED = 'pp/plan-activity/GET_PROJECTION_REJECTED';
const PLAN_ACTIVITY_PAGE_LOADED = 'pp/plan-activity/PLAN_ACTIVITY_PAGE_LOADED';
export const ONGOING_UNDERSTAND_WITHDRAWALS_CLICKED = 'pp/ongoing-dashboard/UNDERSTAND_WITHDRAWALS_CLICKED';

export const assumptionsModalClicked = (type) => ({ type });

export const estimatedIncomeCalculatorClicked = () => ({
  type: ONGOING_ESTIMATED_CALCULATOR_CLICKED,
});

export const contributionWidgetClicked = () => ({
  type: ONGOING_CONTRIBUTION_WIDGET_CLICKED,
});

export const addPensionsWidgetClicked = () => ({
  type: ONGOING_ADD_PENSIONS_WIDGET_CLICKED,
});

export const messageMeWidgetClicked = () => ({
  type: ONGOING_MESSAGE_ME_WIDGET_CLICKED,
});

export const planActivityWidgetClicked = () => ({
  type: ONGOING_PLAN_ACTIVITY_WIDGET_CLICKED,
});

export const withdrawalWidgetClicked = () => ({
  type: ONGOING_WITHDRAWAL_WIDGET_CLICKED,
});

export const planActivityPageLoaded = () => ({
  type: PLAN_ACTIVITY_PAGE_LOADED,
});

export const understandWithdrawalsClicked = () => ({
  type: ONGOING_UNDERSTAND_WITHDRAWALS_CLICKED,
});

export const cds15MonthHardBlockViewed = () => ({
  type: CDS_15_MONTH_HARD_BLOCK_MODAL_VIEWED,
});

export const cds15MonthHardBlockLinkClicked = () => ({
  type: CDS_15_MONTH_HARD_BLOCK_MODAL_LINK_CLICKED,
});
