exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);

// module
exports.push([module.id, ".-MsmPartnershipTile-HeaderSection-2p9jz {\n  width: 100%;\n  height: 70px;\n  overflow-x: hidden;\n  background: rgba(246, 243, 249, 1);\n}\n.-MsmPartnershipTile-HeaderSectionWrapper-CZ_9m {\n  max-width: 1440px;\n  margin: 0 auto;\n  padding: 0 20px;\n  position: relative;\n  top: 50%;\n  -webkit-transform: translateY(-50%);\n          transform: translateY(-50%);\n}\n.-MsmPartnershipTile-msmHeaderSectionWrapper-2sV-n {\n  padding: 0 15px;\n}\n.-MsmPartnershipTile-HeaderSectionWrapper-CZ_9m p {\n  margin: 0;\n  margin: initial;\n  font-family: 'Poppins';\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 18px;\n  letter-spacing: 0em;\n  text-align: left;\n  margin-bottom: 6px;\n}\n.-MsmPartnershipTile-HeaderSectionWrapper-CZ_9m a {\n  display: inline-block;\n}\n.-MsmPartnershipTile-HeaderSectionWrapper-CZ_9m a img {\n  max-height: 23px;\n  display: block;\n}\n@media (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n  .-MsmPartnershipTile-HeaderSectionWrapper-CZ_9m {\n    padding: 0 20px;\n  }\n}\n@media (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["largeDesktop"] + ") {\n  .-MsmPartnershipTile-HeaderSectionWrapper-CZ_9m {\n    padding: 0 80px;\n  }\n\n  .-MsmPartnershipTile-msmHeaderSectionWrapper-2sV-n {\n    padding: 0 20px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"dimensions": "\"../../theme/dimensions.css\"",
	"largeDesktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["largeDesktop"] + "",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"HeaderSection": "-MsmPartnershipTile-HeaderSection-2p9jz",
	"HeaderSectionWrapper": "-MsmPartnershipTile-HeaderSectionWrapper-CZ_9m",
	"msmHeaderSectionWrapper": "-MsmPartnershipTile-msmHeaderSectionWrapper-2sV-n"
};