import React, { Component } from 'react';
import classnames from 'classnames';
import InformationIcon from '../InformationIcon/InformationIcon';
import FieldLabel from '../FieldLabel/FieldLabel';
import styles from './RadioButtons.css';

class RadioButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showHelpText: false,
    };

    this.radioButtonRefs = {};
  }

  setRadioButtonRef = (key, element) => {
    this.radioButtonRefs[key] = element;
  };

  handleChange = (key, buttonConfig) => {
    const { onChange } = this.props;

    Object.keys(this.radioButtonRefs).forEach((refKey) => {
      if (refKey === key) {
        this.radioButtonRefs[refKey].setAttribute('checked', true);
      } else {
        this.radioButtonRefs[refKey].removeAttribute('checked');
      }
    });

    onChange(buttonConfig.value);
  };

  button = (buttonConfig, key) => {
    const { name, bodyTwo } = this.props;

    return (
      <li key={`${name}-${key}`}>
        <input
          ref={(el) => this.setRadioButtonRef(`${name}-${key}`, el)}
          className={styles.radioInput}
          type="radio"
          id={`${name}-${key}`}
          name={name}
          checked={buttonConfig.checked}
          onChange={() => this.handleChange(`${name}-${key}`, buttonConfig)}
        />
        <div className={styles.radioCheck} />
        <label className={classnames(styles.label, { [styles.bodyTwoLabel]: bodyTwo })} htmlFor={`${name}-${key}`}>{buttonConfig.label}</label>
      </li>
    );
  };

  handleTooltipClick = () => {
    const { helpTextCallback } = this.props;
    const { showHelpText } = this.state;
    this.setState({ showHelpText: !showHelpText });
    if (helpTextCallback) {
      helpTextCallback();
    }
  }

  getHelpContent = () => {
    const { helpText } = this.props;

    if (typeof helpText === 'function') {
      const HelpComponent = helpText;
      return <HelpComponent onClick={this.handleTooltipClick} />;
    }

    if (helpText) {
      return <p className={styles.helpText}>{helpText}</p>;
    }
    return null;
  }

  render() {
    const {
      label,
      buttons,
      helpText,
      containerStyles,
      largeHeadings,
    } = this.props;

    const { showHelpText } = this.state;

    const helpContent = this.getHelpContent();
    return (
      <div className={styles.radioContainer} style={containerStyles}>
        {label && (
          <div className={styles.fieldLabelContainer}>
            <FieldLabel label={label} largeHeadings={largeHeadings} />
            {
              helpText && (
                <InformationIcon
                  active={showHelpText}
                  onClick={this.handleTooltipClick}
                />
              )
            }
          </div>
        )}
        { helpContent && showHelpText && helpContent}
        <ul>
          { buttons.map((buttonConfig, index) => this.button(buttonConfig, index)) }
        </ul>
      </div>
    );
  }
}

RadioButtons.defaultProps = {
  bodyTwo: false,
  largeHeadings: true,
};

export default RadioButtons;
