import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { getFirestore } from 'redux-firestore';
import isEqual from 'lodash/isEqual';
import sentryException from '../../util/sentryException';

const INITIAL_STEP_PAGE_LANDED = 'pp/retirementIncomeProjection/INITIAL_STEP_PAGE_LANDED';
const SUGGESTION_STEP_PAGE_LANDED = 'pp/retirementIncomeProjection/SUGGESTION_STEP_PAGE_LANDED';
const RESULTS_STEP_PAGE_LANDED = 'pp/retirementIncomeProjection/RESULTS_STEP_PAGE_LANDED';
const CREATE_SCENARIO_PAGE_LANDED = 'pp/retirementIncomeProjection/CREATE_SCENARIO_PAGE_LANDED';

export const initialStepPageLanded = () => ({ type: INITIAL_STEP_PAGE_LANDED });
export const suggestionStepPageLanded = () => ({ type: SUGGESTION_STEP_PAGE_LANDED });
export const resultsStepPageLanded = () => ({ type: RESULTS_STEP_PAGE_LANDED });
export const createScenarioStepPageLanded = () => ({ type: CREATE_SCENARIO_PAGE_LANDED });

export const BACK_BUTTON_CLICKED = 'pp/retirementIncomeProjection/BACK_BUTTON_CLICKED';

export const backButtonClickedAction = () => ({
  type: BACK_BUTTON_CLICKED,
});

export const WELCOME_STEP_GET_STARTED_BUTTON_CLICKED = 'pp/retirementIncomeProjection/WELCOME_STEP_GET_STARTED_BUTTON_CLICKED';

export const welcomeStepGetStartedButtonClickedAction = () => (
  { type: WELCOME_STEP_GET_STARTED_BUTTON_CLICKED }
);

export const SUGGESTION_STEP_SEE_SUGGESTION_BUTTON_CLICKED = 'pp/retirementIncomeProjection/SUGGESTION_STEP_SEE_SUGGESTION_BUTTON_CLICKED';
export const SUGGESTION_STEP_CREATE_SCENARIO_BUTTON_CLICKED = 'pp/retirementIncomeProjection/SUGGESTION_STEP_CREATE_SCENARIO_BUTTON_CLICKED';
export const SUGGESTION_STEP_VIEW_PREVIOUS_RESULT_CLICKED = 'pp/retirementIncomeProjection/SUGGESTION_STEP_VIEW_PREVIOUS_RESULT_CLICKED';

export const suggestionStepSeeSuggestionButtonClickedAction = () => ({
  type: SUGGESTION_STEP_SEE_SUGGESTION_BUTTON_CLICKED,
});

export const suggestionStepCreateScenarioButtonClickedAction = () => ({
  type: SUGGESTION_STEP_CREATE_SCENARIO_BUTTON_CLICKED,
});

export const suggestionStepViewPreviousResultClickedAction = () => ({
  type: SUGGESTION_STEP_VIEW_PREVIOUS_RESULT_CLICKED,
});

export const CREATE_SCENARIO_VIEW_PREVIOUS_RESULT_CLICKED = 'pp/retirementIncomeProjection/CREATE_SCENARIO_VIEW_PREVIOUS_RESULT_CLICKED';
export const CREATE_SCENARIO_TOGGLE = 'pp/retirementIncomeProjection/CREATE_SCENARIO_TOGGLE_';
export const CREATE_SCENARIO_INPUT = 'pp/retirementIncomeProjection/CREATE_SCENARIO_INPUT_';
export const CREATE_SCENARIO_STEP_CONTINUE_BUTTON_CLICKED = 'pp/retirementIncomeProjection/CREATE_SCENARIO_STEP_CONTINUE_BUTTON_CLICKED';

export const createScenarioToggleClicked = (name, enabled) => ({
  type: `${CREATE_SCENARIO_TOGGLE}${name.toUpperCase()}_${enabled ? 'ENABLED' : 'DISABLED'}`,
});

export const createScenarioInputEntered = (name, value) => ({
  type: `${CREATE_SCENARIO_TOGGLE}${name.toUpperCase()}_${(value || '').toString().length > 0 ? 'ENTERED' : 'DELETED'}`,
});

export const createScenarioStepContinueButtonClickedAction = () => ({
  type: CREATE_SCENARIO_STEP_CONTINUE_BUTTON_CLICKED,
});

export const createScenarioStepViewPreviousResultClickedAction = () => ({
  type: CREATE_SCENARIO_VIEW_PREVIOUS_RESULT_CLICKED,
});

export const createScenarioSetupContributionToggledAction = (enabled) => createScenarioToggleClicked('SETUP_CONTRIBUTION', enabled);
export const createScenarioTransferPensionToggledAction = (enabled) => createScenarioToggleClicked('TRANSFER_PENSION', enabled);
export const createScenarioAddLumpSumToggledAction = (enabled) => createScenarioToggleClicked('ADD_LUMP_SUM', enabled);
export const createScenarioChangeRetirementAgeToggledAction = (enabled) => createScenarioToggleClicked('CHANGE_RETIREMENT_AGE', enabled);

export const createScenarioSetupContributionInputAction = (value) => createScenarioInputEntered('SETUP_CONTRIBUTION', value);
export const createScenarioTransferPensionInputAction = (value) => createScenarioInputEntered('TRANSFER_PENSION', value);
export const createScenarioAddLumpSumInputAction = (value) => createScenarioInputEntered('ADD_LUMP_SUM', value);
export const createScenarioChangeRetirementAgeInputAction = (value) => createScenarioInputEntered('CHANGE_RETIREMENT_AGE', value);

export const RESULTS_STEP_VIEW_ASSUMPTIONS_CLICKED = 'pp/retirementIncomeProjection/RESULTS_STEP_VIEW_ASSUMPTIONS_CLICKED';
export const RESULTS_STEP_TRY_DIFFERENT_SCENARIO_BUTTON_CLICKED = 'pp/retirementIncomeProjection/RESULTS_STEP_TRY_DIFFERENT_SCENARIO_BUTTON_CLICKED';
export const RESULTS_STEP_ADD_PENSION_BUTTON_CLICKED = 'pp/retirementIncomeProjection/RESULTS_STEP_ADD_PENSION_BUTTON_CLICKED';
export const RESULTS_STEP_SETUP_PAYMENTS_BUTTON_CLICKED = 'pp/retirementIncomeProjection/RESULTS_STEP_SETUP_PAYMENTS_BUTTON_CLICKED';
export const RESULTS_STEP_LUMP_SUM_PAYMENT_BUTTON_CLICKED = 'pp/retirementIncomeProjection/RESULTS_STEP_LUMP_SUM_PAYMENT_BUTTON_CLICKED';

export const RESULTS_STEP_HAS_IN_PROGRESS_INSTRUCTIONS_SETUP_PAYMENTS_BUTTON_CLICKED = 'pp/retirementIncomeProjection/RESULTS_STEP_HAS_IN_PROGRESS_INSTRUCTIONS_SETUP_PAYMENTS_BUTTON_CLICKED';
export const RESULTS_STEP_ADD_LUMP_SUM_MESSAGE_ADVISER_CLICKED = 'pp/retirementIncomeProjection/RESULTS_STEP_ADD_LUMP_SUM_MESSAGE_ADVISER_CLICKED';
export const RESULTS_STEP_CHANGE_RETIREMENT_AGE_MESSAGE_ADVISER_CLICKED = 'pp/retirementIncomeProjection/RESULTS_STEP_CHANGE_RETIREMENT_AGE_MESSAGE_ADVISER_CLICKED';
export const RESULTS_STEP_MESSAGE_ADVISOR_CLICKED = 'pp/retirementIncomeProjection/RESULTS_STEP_MESSAGE_ADVISOR_CLICKED';

export const resultsStepViewAssumptionsClickedAction = () => ({
  type: RESULTS_STEP_VIEW_ASSUMPTIONS_CLICKED,
});

export const resultsStepTryDifferentScenarioButtonClickedAction = () => ({
  type: RESULTS_STEP_TRY_DIFFERENT_SCENARIO_BUTTON_CLICKED,
});

export const resultsStepAddPensionButtonClickedAction = () => ({
  type: RESULTS_STEP_ADD_PENSION_BUTTON_CLICKED,
});

export const resultsStepSetupPaymentsButtonClickedAction = () => ({
  type: RESULTS_STEP_SETUP_PAYMENTS_BUTTON_CLICKED,
});

export const resultsStepLumpSumPaymentButtonClickedAction = () => ({
  type: RESULTS_STEP_LUMP_SUM_PAYMENT_BUTTON_CLICKED,
});

export const resultsStepHasInProgressInstructionSetupPaymentsButtonClickedAction = () => ({
  type: RESULTS_STEP_HAS_IN_PROGRESS_INSTRUCTIONS_SETUP_PAYMENTS_BUTTON_CLICKED,
});

export const resultsStepMessageAdvisorButtonClickedAction = () => ({
  type: RESULTS_STEP_MESSAGE_ADVISOR_CLICKED,
});

export const resultsStepAddLumpSumMessageAdvisorButtonClickedAction = () => ({
  type: RESULTS_STEP_ADD_LUMP_SUM_MESSAGE_ADVISER_CLICKED,
});

export const resultsStepChangeRetirementAgeMessageAdvisorButtonClickedAction = () => ({
  type: RESULTS_STEP_CHANGE_RETIREMENT_AGE_MESSAGE_ADVISER_CLICKED,
});

export const SAVE_PROJECTION_DISPATCHED = 'pp/retirementIncomeProjection/SAVE_PROJECTION_DISPATCHED';
export const SAVE_PROJECTION_FULFILLED = 'pp/retirementIncomeProjection/SAVE_PROJECTION_FULFILLED';
export const SAVE_PROJECTION_REJECTED = 'pp/retirementIncomeProjection/SAVE_PROJECTION_REJECTED';

export const saveProjectionUserScenarios = (
  uid,
  scenarios,
  currentProjectedAnnualIncome,
  newProjectedAnnualIncome,
) => {
  const db = getFirestore();

  return async (dispatch) => {
    try {
      dispatch({ type: SAVE_PROJECTION_DISPATCHED });

      const projectionsQuery = await db.collection('users').doc(uid).collection('projections')
        .orderBy('lastModifiedDate')
        .limit(1)
        .get();

      const data = {
        scenarios,
        currentProjectedAnnualIncome,
        newProjectedAnnualIncome,
        lastModifiedDate: new Date(),
      };

      // only save the last document
      if (projectionsQuery.docs[0] && projectionsQuery.docs[0].exists) {
        const { id } = projectionsQuery.docs[0];

        await db.collection('users').doc(uid).collection('projections').doc(id)
          .set(data);
      } else {
        await db.collection('users').doc(uid).collection('projections').doc()
          .set(data);
      }

      dispatch({ type: SAVE_PROJECTION_FULFILLED });
    } catch (e) {
      dispatch({ type: SAVE_PROJECTION_REJECTED });
      sentryException(e, {
        section: 'retirement-projection-income',
      });
    }
  };
};

const setCachedRetirementIncomePlanValues = async (
  uid,
  currentCustomerData,
  currentProjectedAnnualIncome,
) => {
  if (!uid || !currentCustomerData || !currentProjectedAnnualIncome) {
    return;
  }

  const firestore = getFirestore();

  try {
    const cachedRetirementIncomePlanValuesCollection = 'cachedRetirementIncomePlanValues';
    const querySnapshot = await firestore.collection('users').doc(uid).collection(cachedRetirementIncomePlanValuesCollection)
      .orderBy('timestamp')
      .limit(1)
      .get();

    const data = {
      currentCustomerData,
      currentProjectedAnnualIncome,
      timestamp: new Date().toISOString(),
    };

    // overwrite the existing document if it exists; otherwise, create a new document
    if (querySnapshot.docs[0] && querySnapshot.docs[0].exists) {
      const { id } = querySnapshot.docs[0];

      await firestore.collection('users').doc(uid).collection(cachedRetirementIncomePlanValuesCollection).doc(id)
        .set(data);
    } else {
      await firestore.collection('users').doc(uid).collection(cachedRetirementIncomePlanValuesCollection).doc()
        .set(data);
    }
  } catch (error) {
    sentryException(error, {
      section: 'set-cached-retirement-projection-income-failed',
    });
    throw error;
  }
};

export const RETIREMENT_INCOME_GET_CURRENT_PROJECTION_DISPATCHED = 'pp/retirementIncomeProjection/GET_CURRENT_PROJECTION_DISPATCHED';
export const RETIREMENT_INCOME_GET_CURRENT_PROJECTION_FULFILLED = 'pp/retirementIncomeProjection/GET_CURRENT_PROJECTION_FULFILLED';
export const RETIREMENT_INCOME_GET_CURRENT_PROJECTION_REJECTED = 'pp/retirementIncomeProjection/GET_CURRENT_PROJECTION_REJECTED';
export const RETIREMENT_INCOME_GET_NEW_PROJECTION_DISPATCHED = 'pp/retirementIncomeProjection/GET_NEW_PROJECTION_DISPATCHED';
export const RETIREMENT_INCOME_GET_NEW_PROJECTION_FULFILLED = 'pp/retirementIncomeProjection/GET_NEW_PROJECTION_FULFILLED';
export const RETIREMENT_INCOME_GET_NEW_PROJECTION_REJECTED = 'pp/retirementIncomeProjection/GET_NEW_PROJECTION_REJECTED';

export const WITHDRAWAL_JOURNEY_GET_CURRENT_PROJECTION_DISPATCHED = 'pp/withdrawalJourneyProjection/GET_CURRENT_PROJECTION_DISPATCHED';
export const WITHDRAWAL_JOURNEY_GET_CURRENT_PROJECTION_FULFILLED = 'pp/withdrawalJourneyProjection/GET_CURRENT_PROJECTION_FULFILLED';
export const WITHDRAWAL_JOURNEY_GET_CURRENT_PROJECTION_REJECTED = 'pp/withdrawalJourneyProjection/GET_CURRENT_PROJECTION_REJECTED';
export const WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_DISPATCHED = 'pp/withdrawalJourneyProjection/GET_NEW_PROJECTION_DISPATCHED';
export const WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_FULFILLED = 'pp/withdrawalJourneyProjection/GET_NEW_PROJECTION_FULFILLED';
export const WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_REJECTED = 'pp/withdrawalJourneyProjection/GET_NEW_PROJECTION_REJECTED';

export const getProjectedIncome = ({
  gender,
  currentAge,
  retirementAge,
  estimatedPensionSavings,
  portfolio,
  estimatedMonthlyContributions,
  grossMonthlyContributions,
  statePension,
}, {
  DISPATCHED,
  FULFILLED,
  REJECTED,
}) => {
  return async (dispatch) => {
    dispatch({ type: DISPATCHED });
    const getDrawdownProjections = firebase.app().functions('europe-west1').httpsCallable('getDrawdownProjections');
    try {
      const { data } = await getDrawdownProjections({
        gender,
        currentAge,
        retirementAge,
        estimatedPensionSavings,
        portfolio,
        estimatedMonthlyContributions,
        grossMonthlyContributions,
        statePension,
      });

      dispatch({ type: FULFILLED });
      return data;
    } catch (e) {
      console.error(e);
      dispatch({ type: REJECTED });
      sentryException(e, {
        section: 'retirement-projection-income',
      });
      throw e;
    }
  };
};

export const getProjectedIncomeWithCache = (
  uid,
  currentCustomerData,
  cachedValues,
  {
    DISPATCHED,
    FULFILLED,
    REJECTED,
  },
) => {
  // Use the cached values if the cached customer data is the same as the current
  // and the cached data was generated today.
  if (cachedValues
    && isEqual(cachedValues.currentCustomerData, currentCustomerData)
    && new Date(cachedValues.timestamp).toDateString() === new Date().toDateString()
  ) {
    return () => {
      return cachedValues.currentProjectedAnnualIncome;
    };
  }

  return async (dispatch) => {
    try {
      const data = await dispatch(
        getProjectedIncome(currentCustomerData, { DISPATCHED, FULFILLED, REJECTED }),
      );
      await setCachedRetirementIncomePlanValues(uid, currentCustomerData, data);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export default null;
