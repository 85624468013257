
const initialState = {
  blogPosts: [
    {
      title: 'The benefits of pension advice',
      category: 'LIFE ADMIN',
      excerpt: 'Lots of us put off reviewing our pensions, either because they seem too complicated, or because we’re too busy focusing on other things. ',
      url: 'https://www.profilepensions.co.uk/blogs/benefit-of-pension-advice',
      heroUrl: 'https://res.cloudinary.com/profile-pensions/image/upload/v1539860782/Optimised%20Images/Blog/Pension%20Advice/pension-advice-blog_1.jpg',
    },
    {
      title: 'Mind the pension gender gap!',
      category: 'MONEY TIPS',
      excerpt: 'Women are facing a huge pension gender gap, and unless something is done to tackle it, millions risk ending up with retirement incomes which are thousands of pounds lower a year than men. ',
      url: 'https://www.profilepensions.co.uk/blogs/pension-gender-gap',
      heroUrl: 'https://res.cloudinary.com/profile-pensions/image/upload/v1541598249/Optimised%20Images/Blog/Pension%20Gender%20Gap/mind-the-gap-blog_1.jpg',
    },
    {
      title: 'Should I consolidate my pensions?',
      category: 'MONEY TIPS',
      excerpt: 'Many of us will work for several different employers over our lifetime, and so may end up contributing to lots of pension schemes.',
      url: 'https://www.profilepensions.co.uk/blogs/consolidate-my-pensions',
      heroUrl: 'https://res.cloudinary.com/profile-pensions/image/upload/v1539078487/Optimised%20Images/Blog/Consolidate%20Blog/ConsolidateBlog_1.jpg',
    },
    {
      title: 'Transferring your pension – what are the options?',
      category: 'LIFE ADMIN',
      excerpt: 'There’s lots of reasons you might want to consider transferring your pension, but before you do so, make sure you understand exactly what’s involved, and the pros and cons of moving. ',
      url: 'https://www.profilepensions.co.uk/blogs/transferring-your-pension',
      heroUrl: 'https://res.cloudinary.com/profile-pensions/image/upload/v1538731869/Optimised%20Images/Blog/Transferring-Blog/transferring-blog_1.jpg',
    },
  ],
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
