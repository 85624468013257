import React from 'react';
import styles from './FooterSecurity.css';
import EliteLogo from '../../assets/images/rebranding/elite-c-black.svg';
import FSCSLogo from '../../assets/images/rebranding/icon-fscs.svg';

const FooterSecurity = () => (
  <div className={styles.wrapper}>
    <div id="FSCS">
      <a href="https://www.fscs.org.uk/what-we-cover/pensions/" rel="noopener noreferrer" target="_blank">
        <img src={FSCSLogo} width="106" alt="FSCS Protected" />
      </a>
    </div>
    <div id="Elite" className={styles.eliteicon}>
      <a href="https://www.elite-network.com/private-companies/our-companies/profile-pensions" rel="noopener noreferrer" target="_blank">
        <img src={EliteLogo} width="60" alt="Elite Company" />
      </a>
    </div>
  </div>
);

export default FooterSecurity;
