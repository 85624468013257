import validate from 'validate.js';
import { isValidNumber } from 'libphonenumber-js';
import moment from 'moment';

const validDay = (day) => (
  day && day >= 1 && day <= 31
);

const validMonth = (month) => (
  month !== undefined
);

const validYear = (year, minYear = 0, maxYear = 10000) => (
  year && year >= minYear && year <= maxYear
);

validate.validators.validDay = (value) => (
  validDay(value) ? undefined : 'must be a valid day'
);

validate.validators.validMonth = (value) => (
  validMonth(value) ? undefined : 'must be a valid month'
);

validate.validators.validYear = (value, options = {}) => (
  validYear(value, options.minYear, options.maxYear) ? undefined : 'must be a valid year'
);

validate.validators.validDate = (value, options = {}) => {
  if (value
    && validDay(value.day)
    && validMonth(moment().month(value.month).format('M') - 1)
    && validYear(value.year, options.minYear, options.maxYear)
    && moment(`${value.year}-${value.month}-${value.day}`, 'YYYY-MMMM-DD').isValid()) {
    return undefined;
  }
  return 'must be a valid date';
};

validate.validators.validAge = (value, options = {}) => {
  if (value) {
    const today = new Date();
    const birthDate = new Date(value.year, moment().month(value.month).format('M') - 1, value.day);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    let da = today.getDate() - birthDate.getDate();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age -= 1;
    }
    if (m < 0) {
      m += 12;
    }
    if (da < 0) {
      da += 30;
    }
    if (age < options.minAge || age > options.maxAge) {
      return '^Unfortunately we are unable to help if you are under the age of 18';
    }
    return undefined;
  }
  return 'please provide your date of birth';
};

validate.validators.validPhoneNumber = (value, options = {}) => {
  if (options.allowEmpty && !value) { return undefined; }
  return isValidNumber(value, 'GB') ? undefined : '^Please enter a valid Telephone Number';
};
