export const IMPORTANT_CONSIDERATIONS_INFO_JOURNEY = 'IMPORTANT_CONSIDERATIONS_INFO_JOURNEY';
export const IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY = 'IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY';

export const IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY_PATH = '/withdrawal-important-considerations';
export const IMPORTANT_CONSIDERATIONS_INFO_JOURNEY_PATH = '/important-considerations';

export const ASSESSING_PENSION = 'ASSESSING_PENSION';
export const IMPORTANT_CONSIDERATIONS = 'IMPORTANT_CONSIDERATIONS';
export const WITHDRAWAL_ADVICE = 'WITHDRAWAL_ADVICE';
export const TAX_IMPLICATIONS = 'TAX_IMPLICATIONS';
export const WITHDRAWAL_CHARGES = 'WITHDRAWAL_CHARGES';
export const WITHDRAWAL_INVESTMENT = 'WITHDRAWAL_INVESTMENT';
export const BENEFITS = 'BENEFITS';
export const HEALTH = 'HEALTH';
export const DEBT = 'DEBT';
export const ALTERNATIVES = 'ALTERNATIVES';
export const COMBINE_PENSIONS = 'COMBINE_PENSIONS';

export const JOURNEYS = {
  [IMPORTANT_CONSIDERATIONS_INFO_JOURNEY]: [
    ASSESSING_PENSION,
    IMPORTANT_CONSIDERATIONS,
    WITHDRAWAL_ADVICE,
    TAX_IMPLICATIONS,
    WITHDRAWAL_CHARGES,
    WITHDRAWAL_INVESTMENT,
    BENEFITS,
    HEALTH,
    DEBT,
    ALTERNATIVES,
    COMBINE_PENSIONS,
  ],
  [IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY]: [
    IMPORTANT_CONSIDERATIONS,
    WITHDRAWAL_ADVICE,
    TAX_IMPLICATIONS,
    WITHDRAWAL_CHARGES,
    WITHDRAWAL_INVESTMENT,
    BENEFITS,
    DEBT,
    HEALTH,
  ],
};
