const areRequiredProjectionFieldsPresent = (
  gender,
  currentAge,
  retirementAge,
  estimatedPensionSavings,
  portfolio,
) => {
  return Boolean(
    gender
    && currentAge
    && retirementAge
    && estimatedPensionSavings
    && portfolio,
  );
};

export default areRequiredProjectionFieldsPresent;
