import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import RenderHyperlink from '../RenderHyperlink/RenderHyperlink';

function getLinksInText(text) {
  const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
  return text.match(urlRegex) || [];
}

function getNonLinkDataInText(text, links) {
  if (links.length >= 0) {
    const textRegex = new RegExp(
      links.join('|')
        .replaceAll('?', '\\?')
        .replaceAll('+', '\\+'),
    );
    return text.split(textRegex);
  }
  return [text];
}

const HyperlinkSanitizedText = ({ value, className }) => {
  const valueAsString = String(value || '');
  const links = getLinksInText(valueAsString);
  const restOfText = getNonLinkDataInText(valueAsString, links);

  return (
    <React.Fragment>
      <pre className={className} style={{ whiteSpace: 'pre-wrap' }}>
        {restOfText.map((item, index) => (
          <React.Fragment key={uniqueId(item)}>
            {item}
            <RenderHyperlink link={links[index]} />
          </React.Fragment>
        ))}
      </pre>
    </React.Fragment>
  );
};

HyperlinkSanitizedText.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
};

HyperlinkSanitizedText.defaultProps = {
  className: '',
};

export default HyperlinkSanitizedText;
