import React, { useState, useMemo, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { sampleSize, get } from 'lodash';

import TrustBoxWidget from '../TrustBoxWidget/TrustBoxWidget';
import TrustpilotStars from '../TrustpilotStars/TrustpilotStars';
import DotNavigation from '../DotNavigation/DotNavigation';
import {
  serpsTrustPilotReviewCarouselClicked,
  serpsTrustPilotLogoClicked,
} from '../../redux/modules/serps';

import trustpilotDemoData from '../../util/trustpilotDemoResponse';

import ForestStar from '../../assets/images/forest-star.svg';
import trustpilotLogo from '../../assets/images/trustpilot-logo-white.svg';

import styles from './Testimonials.css';

const ReviewCard = ({ review, key }) => {
  const {
    stars,
    consumer,
    text,
  } = review;
  const starElements = Array.from({ length: stars }, (_, i) => (
    <img className={styles.forestStar} key={i} src={ForestStar} alt="" />
  ));

  const displayName = get(consumer, 'displayName');

  return (
    <div className={styles.reviewMessageCard} key={key}>
      <div className={styles.reviewStarsWrapper}>{starElements}</div>
      <p className={styles.reviewMessage}>{text}</p>
      {displayName && (
      <p className={styles.reviewer}>
        <strong>{displayName}</strong>
      </p>
      )}
    </div>
  );
};

const Testimonials = ({
  trustpilot,
  dispatchSerpsTrustPilotReviewCarouselClicked,
  dispatchSerpsTrustPilotLogoClicked,
}) => {
  let reviews = get(trustpilot, 'tpReviews');
  const stars = get(trustpilot, 'tpBusinessUnits.score.stars');
  const trustScore = get(trustpilot, 'tpBusinessUnits.score.trustScore');

  if (!reviews || reviews.length < 1) {
    reviews = trustpilotDemoData.tpReviews;
  }

  const isMobile = !useMediaQuery({ query: '(min-width: 768px)' });
  const [currentIndex, setCurrentIndex] = useState(0);
  const sampledReviews = useMemo(() => sampleSize(reviews, isMobile ? 5 : 6), [isMobile]);
  useEffect(() => {
    setCurrentIndex(0);
  }, [isMobile]);

  const reviewsPerBatch = isMobile ? 1 : 2;
  const sampledReviewsIndex = currentIndex * reviewsPerBatch;
  const dotCount = sampledReviews.length === 5 ? 5 : 3;
  const reviewsToDisplay = isMobile
    ? [sampledReviews[currentIndex]]
    : sampledReviews.slice(sampledReviewsIndex, sampledReviewsIndex + reviewsPerBatch);

  const onClick = (i) => {
    dispatchSerpsTrustPilotReviewCarouselClicked();
    return setCurrentIndex(i);
  };

  return (
    <div className={styles.testimonialContainer}>
      <h1 className={styles.headingOne}>{'What our customers are saying'}</h1>
      <div className={styles.reviewsContainer}>
        {reviewsToDisplay.map((review) => {
          if (!review) {
            return null;
          }
          const key = get(review, 'key');
          return (
            <ReviewCard
              key={key}
              review={review}
              onClick={() => dispatchSerpsTrustPilotLogoClicked()}
            />
          );
        })}
      </div>
      <div className={styles.dotNavigationContainer}>
        <DotNavigation
          dotCount={dotCount}
          dotStep={currentIndex}
          onClick={(i) => onClick(i)}
          whiteDefaultDot
          limeActiveDot
        />
      </div>
      <div className={styles.trustBoxWidgetContainer}>
        {isMobile ? (
          <React.Fragment>
            <TrustpilotStars stars={stars} trustScore={trustScore} />
            <img src={trustpilotLogo} className={styles.trustpilotLogo} alt="Trustpilot logo" />
          </React.Fragment>
        ) : (
          <TrustBoxWidget width={'163px'} height={'90px'} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  trustpilot: state.trustpilot,
});

const mapDispatchToProps = ({
  dispatchSerpsTrustPilotReviewCarouselClicked: serpsTrustPilotReviewCarouselClicked,
  dispatchSerpsTrustPilotLogoClicked: serpsTrustPilotLogoClicked,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Testimonials);
