import { CURRENCY } from '../config';

const options = {
  currencyDisplay: 'symbol',
  style: 'currency',
  currency: CURRENCY,
};

export const currencyString = (number, showDecimal = false) => {
  const curString = number.toLocaleString('en-EN', options);
  return showDecimal ? curString : curString.split('.')[0];
};
