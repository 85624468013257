import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
import FieldLabel from '../FieldLabel/FieldLabel';
import styles from './DatePickerField.css';

registerLocale('en-GB', enGB);

const TimeSpentAtAddressField = ({
  label,
  min,
  max,
  value,
  onChange,
  required = false,
  disabled,
}) => (
  <div className={styles.wrapper}>
    <FieldLabel label={label} required={required} disabled={disabled} />
    <DatePicker
      className={styles.dateInput}
      selected={value}
      onChange={onChange}
      dateFormat="MM/yyyy"
      locale="en-GB"
      placeholderText="MM/YYYY"
      dropdownMode="select"
      minDate={min}
      maxDate={max}
      disabled={disabled}
      showMonthYearPicker
      showFullMonthYearPicker
    />
  </div>
);

export default TimeSpentAtAddressField;
