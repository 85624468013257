import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { statusNotificationCompletedNoChangeClosed } from '../../redux/modules/notificationTracking';
import { getFirebaseUid } from '../../redux/selectors';
import { getCurrentPensionPlanRecommendation } from '../../redux/selectors/ongoingService';

const NoSwitchBannerNotification = ({
  investmentAdviceReportDownloadUrl,
  uid,
  currentPensionPlanRecommendation,
  dispatchStatusNotificationCompletedNoChangeClosed,
}) => {
  let description = 'No changes are required to your pension plan.';
  if (!investmentAdviceReportDownloadUrl) {
    description += ' We are preparing the report, please come back later to view the details.';
  }

  const pensionPlanRecId = get(currentPensionPlanRecommendation, '_id');

  return (
    <NotificationBanner
      alertCategory={'Green'}
      alertTitle={'You’re invested in the most up to date plan for you'}
      expandableAlertDescription={description}
      ctaButtonOptions={investmentAdviceReportDownloadUrl && {
        label: 'View details',
        handleClick: () => window.open(investmentAdviceReportDownloadUrl, '_blank', 'noreferrer'),
      }}
      closeButtonOptions={{
        handleClick: () => {
          dispatchStatusNotificationCompletedNoChangeClosed(uid, pensionPlanRecId);
        },
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const currentPensionPlanRecommendation = getCurrentPensionPlanRecommendation(state);
  return {
    uid: getFirebaseUid(state),
    currentPensionPlanRecommendation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchStatusNotificationCompletedNoChangeClosed: (uid, pensionPlanRecommendationId) => (
      dispatch(statusNotificationCompletedNoChangeClosed(uid, pensionPlanRecommendationId))
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoSwitchBannerNotification);
