import React from 'react';

import NotificationBanner from '../NotificationBanner/NotificationBanner';

const UpdatingPensionPlanNotification = ({
  investmentAdviceReportDownloadUrl,
}) => {
  let description = 'We are updating your pension plan.';
  if (!investmentAdviceReportDownloadUrl) {
    description += ' We are preparing the report, please come back later to view the details.';
  }

  return (
    <NotificationBanner
      alertCategory={'Blue'}
      alertTitle={''}
      expandableAlertDescription={description}
      ctaButtonOptions={investmentAdviceReportDownloadUrl && {
        label: 'View details',
        handleClick: () => window.open(investmentAdviceReportDownloadUrl, '_blank', 'noreferrer'),
      }}
    />
  );
};

export default UpdatingPensionPlanNotification;
