import React from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';

import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';

import {
  nextClickedAction,
  backClickedAction,
} from '../../redux/modules/withdrawal';

import HealthImage from '../../assets/images/withdrawals/health.svg';

import styles from './WithdrawalMiniJourney.css';
import { IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY } from './flows';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';

const Health = ({
  flow,
  step,
  totalSteps,
  setStep,
  dispatchBackClicked,
}) => {
  return (
    <BaseGrid addPensionsTheme>
      <div className={styles.withdrawalsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          onClick={(event) => {
            dispatchBackClicked();
            setStep((_step) => _step - 1);
            event.stopPropagation();
          }}
          label="← Back"
        />
        <div className={styles.withdrawalsImageContainer}>
          <img src={HealthImage} alt="health" />
        </div>
        <h2 className={styles.headingTwo}>{'Are you in good health?'}</h2>
        <div className={styles.withdrawalText}>{'If you are suffering with a serious illness or if there are significant lifestyle factors, you may be eligible for an enhanced annuity if you decide to take an income from your pension.'}</div>
        <div className={styles.greenBox}>{'If you are suffering from a terminal illness, you may be eligible to access the whole of your pot tax free.'}</div>
        {flow === IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY && (
          <div className={styles.pensionAdviserContainer}>
            <div className={styles.pensionAdviserCard}>
              <ContactUsContent
                source={'CombinePensions'}
                noExpertContentWrapper={({ children }) => (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 'initial',
                    padding: '10px',
                    flexWrap: 'wrap',
                  }}
                  >
                    {children}
                  </div>
                )}
                showButton={false}
                customMessage={(
                  <div className={styles.contactUsInnerContent}>
                    {'You can speak to your Pension Adviser to discuss the impact of this on your withdrawal. If you have any questions or want to discuss your withdrawal, '}
                    <span>
                      <ButtonLink
                        variant="primary"
                        to="/inbox/new-message"
                        search={'?subject=Withdrawal%20Request'}
                        displayAsHref
                        font="inherit"
                        label={'message them here'}
                      />
                      {'.'}
                    </span>
                  </div>
                )}
              />
            </div>
          </div>
        )}
        <Button
          size="large"
          label={flow === IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY ? 'I understand' : 'Continue'}
          onClick={() => {
            if (flow !== IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY) {
              setStep((_step) => _step + 1);
            }
          }}
          to={flow === IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY ? '/withdrawals' : null}
        />
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  dispatchNextClicked: nextClickedAction,
  dispatchBackClicked: backClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Health);
