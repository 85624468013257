import React, { useEffect, useState } from 'react';
import { withFirestore } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import sentryException from '../../util/sentryException';
import { refreshSignedUrlsAction } from '../../redux/modules/documents';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';

const DOCUMENT_LINK_TIMEOUT_LATE = 10000;
const DOCUMENT_LINK_TIMEOUT_VERY_LATE = 20000;
const RETRY_LIMIT = 3;
const THRESHOLD = 300000;

const WithDocumentLink = ({
  children,
  fileRef,
  firebase,
  section,
  dispatchRefreshSignedUrls,
  isCustomerOngoing,
  documentType,
}) => {
  const [documentUrlFromDownloadUrl, setDocumentUrlFromDownloadUrl] = useState('');
  const [urlCreationFailed, setUrlCreationFailed] = useState(false);
  const [latencyTimeouts, setLatencyTimeouts] = useState([]);

  const fileHasValidSignedUrl = (file) => {
    return file
    && file.signedUrl
    && file.signedUrlExpiry
    && (new Date(file.signedUrlExpiry) - new Date() > THRESHOLD);
  };

  const documentUrl = fileHasValidSignedUrl(fileRef)
    ? fileRef.signedUrl : documentUrlFromDownloadUrl;

  useEffect(() => {
    if (documentUrl) {
      latencyTimeouts.forEach(clearTimeout);
    }
  }, [documentUrl]);

  const getDownloadUrl = async (referenceName) => {
    if (!referenceName) return;
    const userDocRef = firebase.storage().ref().child(referenceName);
    const downloadUrl = await userDocRef.getDownloadURL();
    setDocumentUrlFromDownloadUrl(downloadUrl);
  };

  const createDownloadUrlWithRetries = async (retryCount, referenceName) => {
    try {
      await getDownloadUrl(referenceName);
    } catch (e) {
      sentryException(e, {
        retryCount,
        downloadUrlGenerationFailed: true,
        ...(section && { section }),
      });

      if (retryCount < RETRY_LIMIT) {
        return createDownloadUrlWithRetries(retryCount + 1, referenceName);
      }
      setUrlCreationFailed(true);
    }
  };

  useEffect(() => {
    const referenceName = get(fileRef, 'name');
    if (!referenceName) return;

    const shouldRefreshSignedUrls = !fileHasValidSignedUrl(fileRef);
    if (!shouldRefreshSignedUrls) return;

    const timeoutIdLate = setTimeout(
      () => {
        sentryException(
          new Error(`Document link not generated after ${DOCUMENT_LINK_TIMEOUT_LATE}ms`),
          {
            ...(section && { section }),
            ...(documentType && { documentType }),
            documentLinkLatency: true,
            bothUrlMethods: true,
          },
        );
      },
      DOCUMENT_LINK_TIMEOUT_LATE,
    );
    const timeoutIdVeryLate = setTimeout(
      () => {
        sentryException(
          new Error(`Document link not generated after ${DOCUMENT_LINK_TIMEOUT_VERY_LATE}ms`),
          {
            ...(section && { section }),
            ...(documentType && { documentType }),
            documentLinkLatency: true,
            bothUrlMethods: true,
          },
        );
      },
      DOCUMENT_LINK_TIMEOUT_LATE,
    );
    const timeoutIds = [timeoutIdLate, timeoutIdVeryLate];
    setLatencyTimeouts(timeoutIds);

    dispatchRefreshSignedUrls(isCustomerOngoing);

    createDownloadUrlWithRetries(0, referenceName);
    return () => {
      timeoutIds.forEach(clearTimeout);
    };
  }, []);

  return <React.Fragment>{children(documentUrl, urlCreationFailed)}</React.Fragment>;
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  dispatchRefreshSignedUrls: refreshSignedUrlsAction,
};

export default compose(
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps),
)(WithDocumentLink);
