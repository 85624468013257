import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import PensionPlanCard from './PensionPlanCard';
import { getCurrentPensionPlanRecommendation } from '../../redux/selectors/ongoingService';
import getFundsWithRoundedSplits from '../../util/getFundsWithRoundedSplits';

const OngoingRecommendationCard = ({
  recommendation,
}) => {
  const platformName = get(recommendation, 'targetInvestment.platformName');
  const portfolioReference = get(recommendation, 'targetInvestment.reference');
  const funds = get(recommendation, 'targetInvestment.funds') || [];
  return (
    <PensionPlanCard
      platformName={platformName}
      portfolioReference={portfolioReference}
      funds={getFundsWithRoundedSplits(funds)}
      title="Your updated pension plan"
      startOpen
    />
  );
};

const mapStateToProps = (state) => ({
  recommendation: getCurrentPensionPlanRecommendation(state),
});

export default connect(mapStateToProps)(OngoingRecommendationCard);
