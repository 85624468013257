/* eslint-disable import/prefer-default-export */
const ACTION_TYPES = {
  // Authenticated route - User requests a new SMS code
  SIGNUP_ACTIVATE_REQUEST: {
    dispatched: 'pp/signup/ACTIVATE_REQUEST_SMS_DISPATCHED',
    fulfilled: 'pp/signup/ACTIVATE_REQUEST_SMS_FULFILLED',
    rejected: 'pp/signup/ACTIVATE_REQUEST_SMS_REJECTED',
  },
  // Authenticated route - User enters an SMS code to verify their number
  ACTIVATE_VERIFY_SMS: {
    dispatched: 'pp/signup/ACTIVATE_VERIFY_SMS_DISPATCHED',
    fulfilled: 'pp/signup/ACTIVATE_VERIFY_SMS_FULFILLED',
    rejected: 'pp/signup/ACTIVATE_VERIFY_SMS_REJECTED',
  },
  // Authenticated route - User requests a new email to verify their account
  VERIFY_EMAIL_REQUEST: {
    dispatched: 'pp/signup/VERIFY_EMAIL_REQUEST_DISPATCHED',
    fulfilled: 'pp/signup/VERIFY_EMAIL_REQUEST_FULFILLED',
    rejected: 'pp/signup/VERIFY_EMAIL_REQUEST_REJECTED',
  },
  // Un authenticated route - User clicks email to verify
  // their account then has to log in to continue
  VERIFY_EMAIL: {
    dispatched: 'pp/signup/VERIFY_EMAIL_DISPATCHED',
    fulfilled: 'pp/signup/VERIFY_EMAIL_FULFILLED',
    rejected: 'pp/signup/VERIFY_EMAIL_REJECTED',
  },
};

export const dispatchEventsWithPromise = (eventPrefix, promise) => {
  return async (dispatch) => {
    dispatch({ type: ACTION_TYPES[eventPrefix].dispatched });

    try {
      const data = await promise();
      dispatch({ type: ACTION_TYPES[eventPrefix].fulfilled, ...data });
      return true;
    } catch (error) {
      dispatch({ type: ACTION_TYPES[eventPrefix].rejected });
    }

    return false;
  };
};
