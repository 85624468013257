import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Button from '../Button/Button';
import ExpandableText from './ExpandableText';
import RedNotification from '../../assets/images/icons/notification/Notification_Alert_Red.svg';
import BlueNotification from '../../assets/images/icons/notification/Notification_Alert_Blue.svg';
import GreenNotification from '../../assets/images/icons/notification/Notification_Alert_Green.svg';
import CloseIcon from '../../assets/images/icons/notification/Notification_Alert_Close.svg';

import styles from './NotificationBanner.css';

const NotificationBanner = ({
  alertCategory,
  alertTitle,
  alertDescription,
  expandableAlertDescription,
  ctaButtonOptions,
  closeButtonOptions,
  dispatch,
  dataCyPrefix,
  wrapperStyles,
}) => {
  const NOTIFICATION_CATEGORIES = {
    RED: 'Red',
    BLUE: 'Blue',
    GREEN: 'Green',
  };

  const borderClassNames = classnames(
    styles.container,
    {
      [styles['border-red']]: alertCategory === NOTIFICATION_CATEGORIES.RED,
      [styles['border-blue']]: alertCategory === NOTIFICATION_CATEGORIES.BLUE,
      [styles['border-green']]: alertCategory === NOTIFICATION_CATEGORIES.GREEN,
    },
  );

  const icons = {
    [NOTIFICATION_CATEGORIES.RED]: RedNotification,
    [NOTIFICATION_CATEGORIES.BLUE]: BlueNotification,
    [NOTIFICATION_CATEGORIES.GREEN]: GreenNotification,
  };

  const iconToDisplay = icons[alertCategory];
  if (!iconToDisplay) {
    return null;
  }

  const CloseButton = () => {
    return (
      (
        <div className={styles.closeButtonContainer}>
          <button
            type="button"
            className={styles.closeButton}
            onClick={() => {
              if (closeButtonOptions.event) {
                dispatch({ type: closeButtonOptions.event });
              }
              if (closeButtonOptions.handleClick) {
                closeButtonOptions.handleClick();
              }
            }}
            data-cy={`${dataCyPrefix}close-notif`}
          >
            <img
              className={styles.closeButtonIcon}
              src={CloseIcon}
              alt="Close Notification Icon"
            />
          </button>
        </div>
      )
    );
  };

  const CTAButton = () => {
    return (
      <div className={styles.ctaButtonContainer}>
        <Button
          variant={`notification${alertCategory}`}
          size={ctaButtonOptions.size ? ctaButtonOptions.size : 'medium'}
          label={ctaButtonOptions.label}
          link={ctaButtonOptions.link}
          to={ctaButtonOptions.to}
          onClick={() => {
            if (ctaButtonOptions.event) {
              dispatch({ type: ctaButtonOptions.event });
            }
            if (ctaButtonOptions.handleClick) {
              ctaButtonOptions.handleClick();
            }
          }}
          dataCy={ctaButtonOptions.dataCy}
        />
      </div>
    );
  };

  return (
    <div data-cy={`${dataCyPrefix}alert-cta`} style={wrapperStyles}>
      <div className={borderClassNames}>
        <div className={styles.contentWrapper}>
          <img src={iconToDisplay} alt="Alert Icon" className={styles.icon} />
          <div className={styles.messageWrapper} data-cy={`${dataCyPrefix}alert-cta-text`}>
            <div className={styles.messageInnerWrapper}>
              {alertTitle && <h6 className={styles.headingText}>{alertTitle}</h6>}
              {closeButtonOptions && <CloseButton />}
            </div>
            {
              (alertDescription && <p className={styles.bodyText}>{alertDescription}</p>)
              || (expandableAlertDescription
              && (
              <ExpandableText
                text={expandableAlertDescription}
                maxCharacters={130}
                mobileOnly
                notificationBannerText
              />
              )
              )
            }
          </div>
        </div>
        {ctaButtonOptions && <CTAButton />}
      </div>
    </div>
  );
};

NotificationBanner.defaultProps = {
  wrapperStyles: {},
  dataCyPrefix: '',
};

export default connect(() => ({}))(NotificationBanner);
