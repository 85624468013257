exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);

// module
exports.push([module.id, ".-PensionsList-pensionListContainer--riWj {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n}\n.-PensionsList-pensionOption-2IgGb {\n  font-weight: 700;\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textParagraphRegular"] + ";\n  margin: 0 0 16px 0;\n}\n", ""]);

// exports
exports.locals = {
	"colours": "'../../theme/colors.css'",
	"textParagraphRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textParagraphRegular"] + "",
	"typography": "\"../../theme/typography.css\"",
	"bodyOne": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"pensionListContainer": "-PensionsList-pensionListContainer--riWj",
	"pensionOption": "-PensionsList-pensionOption-2IgGb " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + ""
};