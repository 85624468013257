/* eslint-disable import/no-cycle */

import get from 'lodash.get'

import { getLastCdsCompletedDate, getSignup } from './index';
import { getOngoingServiceFromUser } from './ongoingService';

const PARTNER_TRADE_DOUBLER = 'tradedoubler';

export const getIsTradeDoublerConversionTagAllowed = (state) => {
  const signUp = getSignup(state);
  const onGoingService = getOngoingServiceFromUser(state);
  const lastCdsCompletedDate = getLastCdsCompletedDate(state);
  const partner = get(signUp, 'partner');

  return !lastCdsCompletedDate && !onGoingService && partner === PARTNER_TRADE_DOUBLER;
};

export default getIsTradeDoublerConversionTagAllowed;
