import React from 'react';
import { currencyString } from '../../util/currency';
import styles from './SetupWithdrawalPage.css';

export const getWithdrawalCopy = (currentValue) => {
  return (
    <React.Fragment>
      {'I would like to make a lump sum withdrawal of '}
      <b>
        {currencyString(currentValue)}
      </b>
    </React.Fragment>
  );
};
export const getContributionCopy = () => {
  return (
    <React.Fragment>
      {'You can keep investing in your pension at any time. Have you considered setting up regular contributions to balance your withdrawal today and to keep earning on your investment?'}
    </React.Fragment>
  );
};

export const getPensionCopy = () => {
  return (
    <React.Fragment>
      {'The average person has 11 workplace pensions in their lifetime. We can help you to Find, Check and Transfer your old pensions then combine them to your pension plan with us to benefit from the whole of the market fund selection and dedicated support along the way.'}
    </React.Fragment>
  );
};

export const ChangedScenario = ({
  title, icon, getContent, functionProps,
}) => {
  return (
    <div className={styles.resultsPageChangedScenarioWrapper}>
      <img src={icon} alt={title} />
      <div className={styles.resultsPageChangedScenarioTextSection}>
        <h4 className={styles.resultsPageSelectionTitle}>{title}</h4>
        <p className={styles.resultsPageSelectionText}>
          {getContent(...functionProps)}
        </p>
      </div>
    </div>
  );
};

export const percentage = (partialValue, totalValue) => {
  return Math.round((100 * partialValue) / totalValue);
};

export const percentSubtraction = (realValue, percent) => {
  return Math.round(realValue - (realValue * (percent / 100)));
};
