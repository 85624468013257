import { isLoaded } from 'react-redux-firebase';
import get from 'lodash/get';

export const getIsProviderNamesLoaded = (state) => isLoaded(get(state, 'firestore.ordered.providers[0]'));

export const getIsRetirementPlansLoaded = (state) => isLoaded(get(state, 'firestore.ordered.retirementPlans'));

export const getIsIvuLoaded = (state) => isLoaded(get(state, 'firestore.ordered.ivuCollection[0]'));

export const getisCdsLoaded = (state) => isLoaded(get(state, 'firestore.ordered.customerDataSets'));

export const getIsSignupLoaded = (state) => isLoaded(get(state, 'firestore.ordered.signups'));

export const getIsAuthLoaded = (state) => get(state, 'firebase.auth.isLoaded');

export const getIsUserLoaded = (state) => get(state, 'firebase.profile.isLoaded');

export const getIsConversationsLoaded = (state) => isLoaded(state.firestore.data.conversations);

export const getIsOnboardingLoaded = (state) => isLoaded(get(state, 'firestore.ordered.investmentAdviceOnboarding[0]'));

export const getIsNotificationsLoaded = (state) => isLoaded(get(state, 'firestore.ordered.notificationTracking'));

export const getIsAttachmentsLoaded = (state) => isLoaded(get(state, 'firestore.ordered.attachmentUploads'));

export const getIsProjectionsLoaded = (state) => isLoaded(get(state, 'firestore.ordered.projections'));

export const getIsCachedRetirementIncomePlanValuesLoaded = (state) => isLoaded(get(state, 'firestore.ordered.cachedRetirementIncomePlanValues'));
