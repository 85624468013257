import React from 'react';
import sentryException from '../../util/sentryException';
import { DEFAULT_CONTACT_NUMBER } from '../../config';
import { beautifyLandline } from '../../util/phone';
import JourneyLayout from '../JourneyLayout/JourneyLayout';
import DefaultJourneyLayoutSidebar from '../JourneyLayout/DefaultJourneyLayoutSidebar';
import Button from '../Button/Button';

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error) {
    try {
      const preAuthEmail = this.props;
      this.setState({ hasError: true });
      sentryException(error, {
        section: 'app-level',
        email: preAuthEmail,
      });
    } catch (e) {
      console.error('Error within top level catch');
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <JourneyLayout sidebar={<DefaultJourneyLayoutSidebar />}>
          <p>
            {'Apologies, something went wrong.'}
          </p>
          <p>
            {'If this problem persists please contact support on '}
            <a href={`tel:${DEFAULT_CONTACT_NUMBER}`}>{beautifyLandline(DEFAULT_CONTACT_NUMBER)}</a>
            {'.'}
          </p>
          <p>
            {'Tap reload to restart.'}
          </p>
          <Button label="Reload" link="/" />
        </JourneyLayout>
      );
    }
    return children;
  }
}

export default AppErrorBoundary;
