import get from 'lodash/get';

export const getLastProjection = (state) => {
  return get(state, 'firestore.ordered.projections[0]');
};

export const getCachedRetirementIncomePlanValues = (state) => {
  return get(state, 'firestore.ordered.cachedRetirementIncomePlanValues[0]');
};

export default null;
