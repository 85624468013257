/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';
import classNames from 'classnames';

import { currencyString } from '../../util/currency';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import TextInput from '../../components/TextInput/TextInput';
import { getPensionExpert } from '../../redux/selectors/investmentAdvice';
import {
  lumpSumAmountStepVisitedAction,
  ongoingLumpSumAmountStepVisitedAction,
  returnLinkClickedAction,
  ongoingReturnLinkClickedAction,
  amountManuallyChangedAction,
  ongoingAmountManuallyChangedAction,
  taxReliefLinkClickedAction,
  ongoingTaxReliefLinkClickedAction,
  nextClickedAction,
  ongoingNextClickedAction,
  lumpSumAmountStepMessageMeClickedAction,
  ongoingLumpSumAmountStepMessageMeClickedAction,
} from '../../redux/modules/lumpSum';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import { TAX_RELIEF_URL } from '../../config';

import LumpSumsAmountImage from '../../assets/images/contributions/contributions-amount.svg';

import styles from './SetupLumpSumPage.css';

const LumpSumAmountStep = ({
  setFormData,
  setStep,
  formData,
  step,
  totalSteps,
  dispatchLumpSumAmountStepVisited,
  dispatchAmountManuallyChanged: _dispatchAmountManuallyChanged,
  dispatchTaxReliefLinkClicked,
  dispatchNextClicked,
  dispatchMessageMeClicked,
  verifiedNetPersonalLumpSums,
  dispatchReturnLinkClicked,
}) => {
  const previousLumpSums = verifiedNetPersonalLumpSums ? parseInt(verifiedNetPersonalLumpSums, 10) : 0;
  const [dispatchAmountManuallyChanged] = useState(() => once(_dispatchAmountManuallyChanged));
  const taxRelief = formData.netAmount ? Math.round(formData.netAmount * 0.25) : 0;
  const totalLumpSum = formData.netAmount + taxRelief;

  const amountInputChanged = (amount) => {
    const netAmount = parseInt(amount, 10);
    const netAmountMask = currencyString(netAmount);
    setFormData({
      ...formData,
      netAmount,
      netAmountMask,
      previousLumpSumAmount: previousLumpSums,
    });
  };

  useEffect(() => {
    function prePopulateAmount() {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('amount')) {
        try {
          const amount = parseInt(urlParams.get('amount'), 10);
          amountInputChanged(amount);
        } catch (e) {
          console.error(e);
        }
      }
    }

    prePopulateAmount();
    window.scrollTo(0, 0);
    dispatchLumpSumAmountStepVisited();
  }, []);

  return (
    <BaseGrid pageSectionStyles={{ marginBottom: '200px' }} addPensionsTheme>
      <div className={styles.contributionsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          secondary
          mid
          onClick={(event) => {
            dispatchReturnLinkClicked();
            setStep((_step) => _step - 1);
            event.stopPropagation();
          }}
          label="← Back"
          wrapperStyles={{
            margin: '0 0 19px',
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '16px',
          }}
        />
        <div className={styles.imageContainer}>
          <img src={LumpSumsAmountImage} alt="watering plants" />
        </div>
        <h2 className={styles.contributionsHeading}>{'How much would you like to contribute into your pension?'}</h2>
        <div className={styles.contributionAmountWrapper}>
          <div className={styles.contributionRowContainer}>
            <TextInput
              value={formData.netAmountMask}
              placeholder={'£ Enter amount'}
              onChange={(event) => {
                dispatchAmountManuallyChanged();
                const strippedInput = event.target.value.replace(/\D/g, '');
                if (strippedInput) {
                  amountInputChanged(strippedInput);
                } else {
                  setFormData({ ...formData, netAmountMask: '', netAmount: 0 });
                }
              }}
            />
          </div>
        </div>
        <div className={styles.standardContainer}>
          <h5 className={styles.summaryHeading}>{'Summary'}</h5>
          <div className={styles.contributionDetailLine}>
            <div className={styles.body}>{'Your contribution'}</div>
            <div className={styles.largeBody}>{currencyString(formData.netAmount)}</div>
          </div>
          <div className={styles.contributionLineDivider} />
          <div className={styles.contributionDetailLine}>
            <div className={styles.body}>{'Tax relief'}</div>
            <div className={styles.largeBody}>{currencyString(taxRelief)}</div>
          </div>
          <div className={styles.contributionLineDivider} />
          <div className={styles.contributionDetailLine}>
            <div className={styles.bodyBold}>{'Total added to your pension'}</div>
            <div className={classNames(styles.bodyBold, styles.largeBody)}>{currencyString(totalLumpSum)}</div>
          </div>
          <div className={styles.contributionLineDivider} />
        </div>
        <div className={styles.standardContainer}>
          <a
            href={TAX_RELIEF_URL}
            className={styles.link}
            onClick={() => dispatchTaxReliefLinkClicked()}
            rel="noopener noreferrer"
            target="_blank"
          >
            {'Read more information on Tax Relief eligibility'}
          </a>
        </div>
        <Button
          size="large"
          disabled={(!formData.netAmount || formData.netAmount === previousLumpSums)}
          label={'Next'}
          onClick={() => {
            dispatchNextClicked();
            setStep(step + 1);
          }}
        />
      </div>
      <div className={styles.pensionAdviserContainer}>
        <div className={styles.pensionAdviserCardWithHeader}>
          <ContactUsContent
            source={'LumpSumAmountStep'}
            heading="Want to set up regular contributions?"
            noExpertContentWrapper={({ children }) => (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexShrink: 'initial',
                padding: '10px',
                flexWrap: 'wrap',
              }}
              >
                {children}
              </div>
            )}
            showButton={false}
            customMessage={(
              <div>
                <div className={styles.contactUsInnerContentWithHeader}>
                  {'If you want to set up regular contributions, '}
                  <ButtonLink
                    variant="primary"
                    label="click here"
                    to="/setup-contribution"
                    font="inherit"
                    onClick={dispatchMessageMeClicked}
                  />
                  {'.'}
                </div>
                <br />
                <div className={styles.contactUsInnerContentWithHeader}>
                  {'For contributions from your business, or if you have any questions, please '}
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <ButtonLink
                      label="message me"
                      variant="primary"
                      to="/inbox/new-message"
                      font="inherit"
                      onClick={dispatchMessageMeClicked}
                    />
                    {'.'}
                  </span>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  pensionAdviser: getPensionExpert(state),
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  _dispatchOnboardingLumpSumAmountStepVisited: lumpSumAmountStepVisitedAction,
  _dispatchOngoingLumpSumAmountStepVisited: ongoingLumpSumAmountStepVisitedAction,
  _dispatchOnboardingReturnLinkClicked: returnLinkClickedAction,
  _dispatchOngoingReturnLinkClicked: ongoingReturnLinkClickedAction,
  _dispatchOnboardingAmountManuallyChanged: amountManuallyChangedAction,
  _dispatchOngoingAmountManuallyChanged: ongoingAmountManuallyChangedAction,
  _dispatchOnboardingTaxReliefLinkClicked: taxReliefLinkClickedAction,
  _dispatchOngoingTaxReliefLinkClicked: ongoingTaxReliefLinkClickedAction,
  _dispatchOnboardingNextClicked: nextClickedAction,
  _dispatchOngoingNextClicked: ongoingNextClickedAction,
  _dispatchOnboardingMessageMeClicked: lumpSumAmountStepMessageMeClickedAction,
  _dispatchOngoingMessageMeClicked: ongoingLumpSumAmountStepMessageMeClickedAction,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isCustomerOngoing } = stateProps;
  const {
    _dispatchOnboardingLumpSumAmountStepVisited,
    _dispatchOngoingLumpSumAmountStepVisited,
    _dispatchOnboardingReturnLinkClicked,
    _dispatchOngoingReturnLinkClicked,
    _dispatchOnboardingAmountManuallyChanged,
    _dispatchOngoingAmountManuallyChanged,
    _dispatchOnboardingTaxReliefLinkClicked,
    _dispatchOngoingTaxReliefLinkClicked,
    _dispatchOnboardingNextClicked,
    _dispatchOngoingNextClicked,
    _dispatchOnboardingMessageMeClicked,
    _dispatchOngoingMessageMeClicked,
  } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    dispatchLumpSumAmountStepVisited: isCustomerOngoing ? _dispatchOngoingLumpSumAmountStepVisited : _dispatchOnboardingLumpSumAmountStepVisited,
    dispatchReturnLinkClicked: isCustomerOngoing ? _dispatchOngoingReturnLinkClicked : _dispatchOnboardingReturnLinkClicked,
    dispatchAmountManuallyChanged: isCustomerOngoing ? _dispatchOngoingAmountManuallyChanged : _dispatchOnboardingAmountManuallyChanged,
    dispatchTaxReliefLinkClicked: isCustomerOngoing ? _dispatchOngoingTaxReliefLinkClicked : _dispatchOnboardingTaxReliefLinkClicked,
    dispatchNextClicked: isCustomerOngoing ? _dispatchOngoingNextClicked : _dispatchOnboardingNextClicked,
    dispatchMessageMeClicked: isCustomerOngoing ? _dispatchOngoingMessageMeClicked : _dispatchOnboardingMessageMeClicked,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LumpSumAmountStep);
