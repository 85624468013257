exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-PensionTransferOptionStep-container-2cnLB {\n  grid-column: 1 / -1;\n}\n@media (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n  .-PensionTransferOptionStep-container-2cnLB {\n    grid-column: 1 / 9;\n  }\n}\n.-PensionTransferOptionStep-tabCardContainer-2QYEp {\n  margin-top: 20px;\n}\n@media (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["typographyDesktop"] + ") {\n  .-PensionTransferOptionStep-tabCardContainer-2QYEp {\n    margin-top: 25px;\n  }\n}\n.-PensionTransferOptionStep-headingContainer-KYn-Y {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n.-PensionTransferOptionStep-heading-3WmEk {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + ";\n  margin: 0;\n}\n@media (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n  .-PensionTransferOptionStep-mobileBreak-37jWE {\n    display: none;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"dimensions": "\"../../theme/dimensions.css\"",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"typographyDesktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["typographyDesktop"] + "",
	"typography": "\"../../theme/typography.css\"",
	"bodyOne": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"h2": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h2"] + "",
	"colors": "'../../theme/colors.css'",
	"textTitleRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + "",
	"container": "-PensionTransferOptionStep-container-2cnLB",
	"tabCardContainer": "-PensionTransferOptionStep-tabCardContainer-2QYEp",
	"headingContainer": "-PensionTransferOptionStep-headingContainer-KYn-Y",
	"heading": "-PensionTransferOptionStep-heading-3WmEk " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h2"] + "",
	"mobileBreak": "-PensionTransferOptionStep-mobileBreak-37jWE"
};