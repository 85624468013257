import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Header.css';
import SystemAlertCard from '../AlertCard/SystemAlertCard';

const Header = (props) => {
  const {
    children,
    showAlerts,
    whiteTheme,
    initialSignupTheme,
    inboxTheme,
    greyTheme,
    addPensionsTheme,
    dashboardTheme,
  } = props;
  const sessionStorageVerificationComplete = sessionStorage.getItem('verificationComplete');

  return (
    <React.Fragment>
      <header className={
        classnames(
          styles.header,
          {
            [styles.whiteTheme]: whiteTheme || initialSignupTheme,
            [styles.inboxTheme]: inboxTheme,
            [styles.greyTheme]: greyTheme,
          },
        )
      }
      >
        <div
          className={classnames(
            styles.headerContent,
            { [styles.initialSignupTheme]: initialSignupTheme },
            { [styles.inboxTheme]: inboxTheme },
            { [styles.addPensionsTheme]: addPensionsTheme },
            { [styles.dashboardTheme]: dashboardTheme },
          )}
        >
          {children}
        </div>
      </header>
      {
        showAlerts && <SystemAlertCard verificationComplete={sessionStorageVerificationComplete} />
      }
    </React.Fragment>
  );
};

Header.propTypes = {
  whiteTheme: PropTypes.bool,
  initialSignupTheme: PropTypes.bool,
};

Header.defaultProps = {
  whiteTheme: false,
  initialSignupTheme: false,
};
export default Header;
