import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPensions } from '../../redux/selectors/pensions';
import Button from '../Button/Button';

import { addPensionsLinkClicked, ongoingAddPensionsLinkClicked } from '../../redux/modules/dashboard';

import styles from './AddPensions.css';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';

function AddPensions({
  onboardingPensions = [],
  disabled,
  dispatchAddPensionsLinkClicked,
  dispatchOngoingAddPensionsLinkClicked,
  message,
  isCustomerOngoing,
}) {
  const hasPensions = Boolean(onboardingPensions.length) || isCustomerOngoing;

  return (
    <div className={styles.addPensions}>
      <h3 className={styles.heading}>{'Transfer old pensions'}</h3>
      <p className={styles.body}>
        {message || (hasPensions
          ? 'Give us the details you know about your old pensions.'
          : 'Tell us the name of your old pension provider or employer. We’ll then take care of transferring this pension for you.')}
      </p>
      {!disabled && (
        <Button
          disabled={disabled}
          link="/add-pensions"
          onClick={
            isCustomerOngoing
              ? dispatchOngoingAddPensionsLinkClicked : dispatchAddPensionsLinkClicked
          }
          label="+ Add Pensions"
          dataSentry={'dashboard/add-pensions'}
        />
      )}
    </div>
  );
}

AddPensions.propTypes = {
  onboardingPensions: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    policyName: PropTypes.string.isRequired,
    mappedProviderContact: PropTypes.string.isRequired,
    authorityToTransfer: PropTypes.bool,
    dueDiligence: PropTypes.shape({
      vetting: PropTypes.shape({
        overallProceed: PropTypes.bool.isRequired,
      }),
      authority: PropTypes.shape({
        overallProceed: PropTypes.bool.isRequired,
      }),
    }).isRequired,
    blockingReasons: PropTypes.shape({
      providerNotRecognised: PropTypes.shape({
        value: PropTypes.bool.isRequired,
      }),
    }),
    suspendedFunds: PropTypes.shape({
      value: PropTypes.bool,
    }),
  })),
  signupPensions: PropTypes.arrayOf(PropTypes.shape({
    commonName: PropTypes.string.isRequired,
    currentWorkplacePension: PropTypes.bool,
    policyNumber: PropTypes.string,
  })),
  disabled: PropTypes.bool,
};

const mapStateToProps = ((state) => ({
  onboardingPensions: getPensions(state),
  isCustomerOngoing: getIsCustomerOngoing(state),
}));

const mapDispatchToProps = ({
  dispatchAddPensionsLinkClicked: addPensionsLinkClicked,
  dispatchOngoingAddPensionsLinkClicked: ongoingAddPensionsLinkClicked,
});

AddPensions.defaultProps = {
  onboardingPensions: [],
  signupPensions: [],
  disabled: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPensions);
