/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';

import BaseGrid from '../../components/BaseGrid/BaseGrid';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';

import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import {
  nextClickedAction,
  ongoingNextClickedAction,
  returnLinkClickedAction,
  ongoingReturnLinkClickedAction,
  lumpSumAmountStepMessageMeClickedAction,
  ongoingLumpSumAmountStepMessageMeClickedAction,
} from '../../redux/modules/lumpSum';

import styles from './SetupLumpSumPage.css';

const LumpSumIntro = ({
  step,
  setStep,
  dispatchNextClicked,
  dispatchReturnLinkClicked,
  dispatchMessageMeClicked,
}) => {
  return (
    <BaseGrid pageSectionStyles={{ marginBottom: '200px' }} addPensionsTheme>
      <div className={styles.contributionsContainer}>
        <ButtonLink
          secondary
          mid
          onClick={(event) => {
            dispatchReturnLinkClicked();
            event.stopPropagation();
          }}
          label="← Back"
          back
          wrapperStyles={{
            margin: '0 0 19px',
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '16px',
          }}
        />
        <h2 className={styles.contributionsHeading}>{'How one-off contributions work'}</h2>
        <div className={styles.introBox}>
          <div className={styles.whatHappensNextRow}>
            <div className={styles.whatHappensNextNumber}>{'1'}</div>
            <p className={styles.whatHappensNextText}>{'Tell us how much you would like to contribute'}</p>
          </div>
          <div className={styles.whatHappensNextRow}>
            <div className={styles.whatHappensNextNumber}>{'2'}</div>
            <p className={styles.whatHappensNextText}>{'Your contribution will be set up with Aegon. We’ll send you the details by secure message to complete your bank transfer'}</p>
          </div>
          <div className={styles.whatHappensNextRow}>
            <div className={styles.whatHappensNextNumber}>{'3'}</div>
            <p className={styles.whatHappensNextText}>{'You complete the bank transfer using the reference number and details provided using online banking or a branch'}</p>
          </div>
        </div>
        <Button
          size="large"
          label={'Next'}
          onClick={() => {
            dispatchNextClicked();
            setStep(step + 1);
          }}
        />
      </div>
      <div className={styles.pensionAdviserContainer}>
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'LumpSumIntroStep'}
            noExpertContentWrapper={({ children }) => (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexShrink: 'initial',
                padding: '10px',
                flexWrap: 'wrap',
              }}
              >
                {children}
              </div>
            )}
            showButton={false}
            customMessage={(
              <div className={styles.contactUsInnerContent}>
                {'If you have any questions, please feel free to '}
                <ButtonLink
                  label="message me"
                  variant="primary"
                  to="/inbox/new-message"
                  font="inherit"
                  onClick={dispatchMessageMeClicked}
                />
                {'.'}
              </div>
            )}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  _dispatchOnboardingNextClicked: nextClickedAction,
  _dispatchOngoingNextClicked: ongoingNextClickedAction,
  _dispatchOnboardingReturnLinkClicked: returnLinkClickedAction,
  _dispatchOngoingReturnLinkClicked: ongoingReturnLinkClickedAction,
  _dispatchOnboardingMessageMeClicked: lumpSumAmountStepMessageMeClickedAction,
  _dispatchOngoingMessageMeClicked: ongoingLumpSumAmountStepMessageMeClickedAction,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isCustomerOngoing } = stateProps;
  const {
    _dispatchOnboardingNextClicked,
    _dispatchOngoingNextClicked,
    _dispatchOnboardingReturnLinkClicked,
    _dispatchOngoingReturnLinkClicked,
    _dispatchOnboardingMessageMeClicked,
    _dispatchOngoingMessageMeClicked,
  } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    dispatchNextClicked: isCustomerOngoing ? _dispatchOngoingNextClicked : _dispatchOnboardingNextClicked,
    dispatchReturnLinkClicked: isCustomerOngoing ? _dispatchOngoingReturnLinkClicked : _dispatchOnboardingReturnLinkClicked,
    dispatchMessageMeClicked: isCustomerOngoing ? _dispatchOngoingMessageMeClicked : _dispatchOnboardingMessageMeClicked,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LumpSumIntro);
