import { getFirestore } from 'redux-firestore';

const firestore = getFirestore();

export const CALENDLY_EVENT_SCHEDULED = 'pp/booking/CALENDLY_EVENT_SCHEDULED';

export const CALENDLY_EVENT_VIEWED = 'pp/booking/CALENDLY_EVENT_VIEWED';

export const ONGOING_CALENDLY_EVENT_SCHEDULED = 'pp/booking/ONGOING_CALENDLY_EVENT_SCHEDULED';

export const calendlyEventScheduledAction = ({
  uid, bookedOn, type, scheduledEvent,
}) => async (dispatch) => {
  dispatch({ type: scheduledEvent });
  const newAppointmentDoc = firestore.collection('users').doc(uid).collection('appointments');
  await newAppointmentDoc.add({ bookedOn, type });
};

export const withdrawalCalendlyEventScheduled = () => ({
  type: CALENDLY_EVENT_SCHEDULED,
});

export const withdrawalCalendlyEventViewed = () => ({
  type: CALENDLY_EVENT_VIEWED,
});
