exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!../theme/typography.css"), undefined);
exports.i(require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-ForgotPasswordReset-heading-2ulL5 {\n}\n.-ForgotPasswordReset-text-CRA_M {\n}\n.-ForgotPasswordReset-link-35YtK {\n}\n.-ForgotPasswordReset-aTagStyle-2rGor {\n  font-family: inherit;\n}\n.-ForgotPasswordReset-recaptchaTxt-2XUut {\n  color: " + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!../theme/colors.css").locals["textParagraphRegular"] + ";\n  margin: 0;\n}", ""]);

// exports
exports.locals = {
	"typography": "\"../theme/typography.css\"",
	"bodyOne": "" + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!../theme/typography.css").locals["bodyOne"] + "",
	"h3": "" + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!../theme/typography.css").locals["h3"] + "",
	"bodyThree": "" + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!../theme/typography.css").locals["bodyThree"] + "",
	"bodyOneLink": "" + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!../theme/typography.css").locals["bodyOneLink"] + "",
	"colors": "\"../theme/colors.css\"",
	"textParagraphRegular": "" + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!../theme/colors.css").locals["textParagraphRegular"] + "",
	"heading": "-ForgotPasswordReset-heading-2ulL5 " + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!../theme/typography.css").locals["h3"] + "",
	"text": "-ForgotPasswordReset-text-CRA_M " + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!../theme/typography.css").locals["bodyOne"] + "",
	"link": "-ForgotPasswordReset-link-35YtK " + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!../theme/typography.css").locals["bodyOneLink"] + "",
	"aTagStyle": "-ForgotPasswordReset-aTagStyle-2rGor",
	"recaptchaTxt": "-ForgotPasswordReset-recaptchaTxt-2XUut " + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!../theme/typography.css").locals["bodyThree"] + ""
};