export const ADD_MONEY_OPTION_PAGE_LANDED = 'pp/add-money-options/ADD_MONEY_OPTION_PAGE_LANDED';
export const SETUP_OR_AMEND_CONTRIBUTION_OPTION_CLICKED = 'pp/add-money-options/SETUP_OR_AMEND_CONTRIBUTION_OPTION_CLICKED';
export const LUMP_SUM_OPTION_CLICKED = 'pp/add-money-options/LUMP_SUM_OPTION_CLICKED';

export const addMoneyOptionPageLanded = () => ({
  type: ADD_MONEY_OPTION_PAGE_LANDED,
});

export const setUpOrAmendContributionOptionClicked = () => ({
  type: SETUP_OR_AMEND_CONTRIBUTION_OPTION_CLICKED,
});

export const lumpSumOptionClicked = () => ({
  type: LUMP_SUM_OPTION_CLICKED,
});
