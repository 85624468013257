import React, { Fragment } from 'react';
import styles from './JourneyLayout.css';
import logoUrlWithMfGroup from '../../assets/images/rebranding/pp-logo-mf-group.svg';

const LogoTitle = ({ title }) => (
  <Fragment>
    <div className={styles.layoutSidebarLogo}>
      <img src={logoUrlWithMfGroup} alt="Profile Pensions Logo" />
    </div>
    <h4 className={styles.sidebarTitle}>{title}</h4>
  </Fragment>
);

export default LogoTitle;
