import { Base64 } from 'js-base64';

const captureHtml = () => {
  let base64PageHtml;

  if (window && window.document) {
    const pageHtml = window.document.getElementById('app').innerHTML;
    base64PageHtml = Base64.encode(pageHtml, true);
  }

  return base64PageHtml;
};

export default captureHtml;
