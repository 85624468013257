import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import get from 'lodash/get';

import {
  getPhoneNumber,
  getTwoFactorAuthenticationEnabled,
} from '../../redux/selectors';
import {
  sendSMSOtp,
  updateTwoFactorAuthenticationFlag,
  dispatchEnableTwoFactorAuthenticationButtonClicked,
  dispatchDisableTwoFactorAuthenticationButtonClicked,
  dispatchSendTwoFactorAuthenticationNewMobileEntered,
  dispatchSendTwoFactorAuthenticationOtpCancelled,
  dispatchVerifyTwoFactorAuthenticationOtpResendCode,
  dispatchVerifyTwoFactorAuthenticationOtpCancelled,
} from '../../redux/modules/account';

import getRecaptchaToken from '../../util/getRecaptchaToken';

import Button from '../Button/Button';
import ButtonLink from '../ButtonLink/ButtonLink';
import FormError from '../FormError/FormError';
import ServiceAlertCard from '../AlertCard/ServiceAlertCard';
import { renderTextField } from '../../util/formik-custom-fields';

import { UK_MOBILE_NUMBER_REGEX } from '../../util/completePersonalDetailsValidation';

import styles from './TwoStepAuthentication.css';

const mobileSchema = yup.object().shape({
  mobileNumber: yup.string().matches(UK_MOBILE_NUMBER_REGEX, 'Please provide a valid UK mobile number').required('Required'),
});

const optSchema = yup.object().shape({
  otpCode: yup.string().matches(/^[0-9]{6}$/, 'The code must be exactly 6 digits').required('Required'),
});

const STEP_INITIAL = 'STEP_INITIAL';
const STEP_ENTER_MOBILE_AND_SEND_OTP = 'STEP_ENTER_MOBILE_AND_SEND_OTP';
const STEP_VALIDATE_OTP = 'STEP_VALIDATE_OTP';

const TwoStepAuthentication = (props) => {
  const {
    mobile,
    twoFactorAuthenticationEnabled,
    dispatchUpdateAndVerifyOtp,
    dispatchSendSMSOtp,
    dispatchInitialEnableButtonClicked,
    dispatchInitialDisableButtonClicked,
    dispatchSendNewMobileEntered,
    dispatchSendOtpCancelled,
    dispatchVerifyOtpResendCode,
    dispatchVerifyOtpCancelled,
  } = props;

  const [currentStep, setCurrentStep] = useState(STEP_INITIAL);
  const [activeMobileNumber, setActiveMobileNumber] = useState(null);
  const [isNewMobileNumber, setIsNewMobileNumber] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  /**
   * Removes whitespace globally and replaces starting zero with the UK country code.
   * This should allow us to successfully send an SMS to a customer.
   * @param {String} mobileNumber The customers mobile number.
   * @returns {String} Sanitised mobile number. e.g. 079000 000 000 > +4479000000000
   */
  const santiseMobileNumber = (mobileNumber) => {
    return mobileNumber ? mobileNumber.replace(/\s/g, '').replace(/^0/, '+44') : '';
  };

  const resetState = () => {
    setUpdateSuccess(false);
    setActiveMobileNumber(null);
    setIsNewMobileNumber(false);
  };

  const advanceStepInitial = () => {
    resetState();
    setCurrentStep(STEP_ENTER_MOBILE_AND_SEND_OTP);

    if (!twoFactorAuthenticationEnabled) {
      dispatchInitialEnableButtonClicked();
    } else {
      dispatchInitialDisableButtonClicked();
    }
  };

  const sendOtpAndMobileChangeHandler = async (values, { setSubmitting, setStatus }) => {
    const { mobileNumber } = values;
    const sanitisedMobileNumber = santiseMobileNumber(mobileNumber);
    setActiveMobileNumber(sanitisedMobileNumber);

    if (isNewMobileNumber) {
      dispatchSendNewMobileEntered();
    }

    setStatus({ formError: null });
    setSubmitting(true);

    const invertTwoFactorAuthenticationEnabled = !twoFactorAuthenticationEnabled;

    try {
      const recaptchaToken = await getRecaptchaToken('sendOtpAndMobileChangeHandler');
      await dispatchSendSMSOtp(
        invertTwoFactorAuthenticationEnabled,
        sanitisedMobileNumber,
        recaptchaToken,
      );

      setCurrentStep(STEP_VALIDATE_OTP);
    } catch (error) {
      setStatus({ formError: 'Failed to send verification code.' });
    }

    setSubmitting(false);
  };

  const verifyOtpHandler = async (values, { setSubmitting, setStatus }) => {
    const { otpCode } = values;

    setStatus({ formError: null });
    setSubmitting(true);

    try {
      const recaptchaToken = await getRecaptchaToken('verifyOtpHandler');
      const verifyOtpResponse = await dispatchUpdateAndVerifyOtp(
        otpCode,
        recaptchaToken,
      );

      const verifySuccess = get(verifyOtpResponse, 'success');
      const expiredCodeError = get(verifyOtpResponse, 'expired');
      const incorrectCodeError = get(verifyOtpResponse, 'incorrectCode');
      const retriesLeft = get(verifyOtpResponse, 'retriesLeft');
      const maxTriesReached = get(verifyOtpResponse, 'maxTriesReached');

      if (verifySuccess) {
        setCurrentStep(STEP_INITIAL);
        setUpdateSuccess(true);
      } else if (maxTriesReached) {
        setStatus({ formError: 'You have no retries left, please press ‘Resend code’ and try again.' });
      } else if (expiredCodeError) {
        setStatus({ formError: 'The code you have entered has expired, please press ‘Resend code’ and try again.' });
      } else if (incorrectCodeError) {
        setStatus({ formError: `You’ve entered the code incorrectly, you have ${retriesLeft} ${retriesLeft > 1 ? 'retries' : 'retry'} left.` });
      } else {
        setStatus({ formError: 'Something went wrong' });
      }
    } catch (error) {
      setStatus({ formError: 'Could not verify the PIN code, try again or press ‘Resend code’ to receive a new code.' });
    }

    setSubmitting(false);
  };

  const renderStep = (newStep) => {
    const validStepKeys = [STEP_INITIAL, STEP_ENTER_MOBILE_AND_SEND_OTP, STEP_VALIDATE_OTP];
    const validatedStep = validStepKeys.includes(newStep) ? newStep : STEP_INITIAL;

    const steps = {
      [STEP_INITIAL]: (
        <div className={styles.twoStepAuthToggleButton}>
          <Button
            size="medium"
            label={twoFactorAuthenticationEnabled ? 'Disable 2-step verification' : 'Enable 2-step verification'}
            onClick={advanceStepInitial}
          />
        </div>
      ),
      [STEP_ENTER_MOBILE_AND_SEND_OTP]: (
        <Formik
          initialValues={{ mobileNumber: mobile || '' }}
          validationSchema={mobileSchema}
          onSubmit={sendOtpAndMobileChangeHandler}
          render={({
            handleSubmit,
            isSubmitting,
            values,
            errors,
            status,
          }) => {
            const { mobileNumber } = values;
            const { mobileNumber: mobileNumberErrors } = errors;

            const sanitisedMobileNumber = santiseMobileNumber(mobileNumber);
            const sanitisedExistingMobileNumber = santiseMobileNumber(mobile);
            if (sanitisedMobileNumber === sanitisedExistingMobileNumber || mobileNumber === mobile) {
              setIsNewMobileNumber(false);
            } else {
              setIsNewMobileNumber(true);
            }

            return (
              <form onSubmit={handleSubmit}>
                <Field
                  name="mobileNumber"
                  type="tel"
                  component={renderTextField}
                  label="Mobile number"
                  defaultValue="Enter new mobile number"
                  disabled={twoFactorAuthenticationEnabled}
                  required
                />
                <br />
                <FormError error={status && status.formError} />
                <div className={styles.twoStepAuthActionButtons}>
                  <Button
                    type="submit"
                    label={!isNewMobileNumber ? 'Send code' : 'Update & Send code'}
                    loading={isSubmitting}
                    disabled={Boolean(mobileNumberErrors)}
                  />
                  <Button
                    label="Cancel"
                    wrapperStyles={{ display: 'block' }}
                    onClick={() => {
                      resetState();
                      setCurrentStep(STEP_INITIAL);
                      dispatchSendOtpCancelled();
                    }}
                  />
                </div>
              </form>
            );
          }}
        />
      ),
      [STEP_VALIDATE_OTP]: (
        <Formik
          onSubmit={verifyOtpHandler}
          validationSchema={optSchema}
          render={({
            handleSubmit,
            isSubmitting: verifyOtpIsSubmitting,
            values,
            status,
          }) => {
            const { otpCode } = values;
            return (
              <form onSubmit={handleSubmit}>
                <p className={styles.body}>
                  {'We’ve just sent you a 6-digit PIN code to '}
                  <b>{activeMobileNumber}</b>
                  {'. Please enter the code in the box below.'}
                </p>
                <Field
                  name="otpCode"
                  type="tel"
                  component={renderTextField}
                  label={'Verification code'}
                  defaultValue="e.g. 123456"
                />
                <br />
                <FormError error={status && status.formError} />
                <ButtonLink
                  onClick={() => {
                    dispatchVerifyOtpResendCode();
                    setCurrentStep(STEP_ENTER_MOBILE_AND_SEND_OTP);
                  }}
                  label="Resend code"
                  font="bodyTwo"
                />
                <br />
                <div className={styles.twoStepAuthActionButtons}>
                  <Button
                    size="small"
                    type="submit"
                    label="Confirm"
                    loading={verifyOtpIsSubmitting}
                    disabled={!otpCode}
                  />
                  <Button
                    size="small"
                    label="Cancel"
                    onClick={() => {
                      resetState();
                      setCurrentStep(STEP_INITIAL);
                      dispatchVerifyOtpCancelled();
                    }}
                  />
                </div>
              </form>
            );
          }}
        />
      ),
    };

    return steps[validatedStep];
  };

  // eslint-disable-next-line no-shadow
  const getServiceAlertText = (twoFactorAuthenticationEnabled, isNewMobileNumber) => {
    if (!twoFactorAuthenticationEnabled) return '2-step login has been disabled successfully!';
    if (twoFactorAuthenticationEnabled && isNewMobileNumber) return 'Your mobile number has been changed and 2-step verification has been setup successfully!';
    return '2-step login has been setup successfully!';
  };

  return (
    <div>
      <div className={styles.value}>
        {'Status: '}
        {twoFactorAuthenticationEnabled
          ? <span className={styles.statusOnText}>{'On'}</span>
          : <span className={styles.statusOffText}>{'Off'}</span>}
      </div>

      {updateSuccess && (
      <ServiceAlertCard
        alertDescription={getServiceAlertText(twoFactorAuthenticationEnabled, isNewMobileNumber)}
        alertSeverity="success"
        icon="success"
      />
      )}
      {renderStep(currentStep)}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatchSendSMSOtp: (
    twoFactorAuthenticationEnabled,
    mobileNumber,
    recaptchaToken,
  ) => dispatch(sendSMSOtp(twoFactorAuthenticationEnabled, mobileNumber, recaptchaToken)),
  dispatchUpdateAndVerifyOtp: (
    otpCode,
    recaptchaToken,
  ) => dispatch(updateTwoFactorAuthenticationFlag(
    otpCode,
    recaptchaToken,
  )),
  dispatchInitialEnableButtonClicked: () => dispatch(dispatchEnableTwoFactorAuthenticationButtonClicked()),
  dispatchInitialDisableButtonClicked: () => dispatch(dispatchDisableTwoFactorAuthenticationButtonClicked()),
  dispatchSendNewMobileEntered: () => dispatch(dispatchSendTwoFactorAuthenticationNewMobileEntered()),
  dispatchSendOtpCancelled: () => dispatch(dispatchSendTwoFactorAuthenticationOtpCancelled()),
  dispatchVerifyOtpResendCode: () => dispatch(dispatchVerifyTwoFactorAuthenticationOtpResendCode()),
  dispatchVerifyOtpCancelled: () => dispatch(dispatchVerifyTwoFactorAuthenticationOtpCancelled()),
});

const mapStateToProps = (state) => ({
  mobile: getPhoneNumber(state),
  twoFactorAuthenticationEnabled: getTwoFactorAuthenticationEnabled(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(TwoStepAuthentication);
