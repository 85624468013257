import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';
import get from 'lodash/get';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import {
  missingDetailsMessageMeClickedAction,
  missingDetailsSignatureDrawnAction,
  missingDetailsReturnLinkClickedAction,
  missingDetailsSignatureSizeErrorAction,
  missingDetailsSignatureSubmitClickedAction,
  missingDetailsCompleteAction,
} from '../../redux/modules/signupFlow';
import AuthoritySignature from './AuthoritySignature';
import { updatePersonalDetails } from '../../redux/modules/investmentAdvice';
import { statusNotificationMissingDetailsCompleted } from '../../redux/modules/notificationTracking';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import Button from '../../components/Button/Button';
import styles from './PersonalDetails.css';
import { getHasDigitalSignature } from '../../redux/selectors';

const MissingDetailsSignature = ({
  step,
  totalSteps,
  amendMode,
  dispatchMissingDetailsReturnLinkClickedAction,
  dispatchMissingDetailsMessageMeClickedAction,
  dispatchMissingDetailsSignatureSubmitClickedAction,
  dispatchMissingDetailsSignatureDrawnAction: _dispatchMissingDetailsSignatureDrawnAction,
  dispatchStatusNotificationMissingDetailsCompleted,
  dispatchMissingDetailsComplete,
  dispatchUpdatePersonalDetails,
  setIsSubmitLoading,
  setStep,
  heading,
  dispatchSignatureSizeError,
  isSubmitting,
  formData,
  setFormData,
  digitalSignature,
}) => {
  const MINIMUM_SIGNATURE_HEIGHT_PX = 30;
  const MINIMUM_SIGNATURE_WIDTH_PX = 70;

  const [signatureTooSmall, setSignatureTooSmall] = useState(false);

  const [signatureDrawn, setSignatureDrawn] = useState(false);

  const [dispatchMissingDetailsSignatureDrawnAction] = useState(() => once(_dispatchMissingDetailsSignatureDrawnAction || ''));

  const signatureHandleChange = (signature) => {
    if (!signature) {
      setSignatureTooSmall(false);
      setSignatureDrawn(false);
      setFormData((_formData) => ({
        ..._formData,
        signature: null,
      }));
      return;
    }
    const signatureBase64 = signature.toDataURL('image/svg+xml');
    const { height, width } = signature;
    const signatureIsTooSmall = height < MINIMUM_SIGNATURE_HEIGHT_PX || width < MINIMUM_SIGNATURE_WIDTH_PX;

    if (signatureIsTooSmall) {
      setSignatureTooSmall(true);
      setSignatureDrawn(true);
      dispatchSignatureSizeError();
    } else {
      setSignatureDrawn(true);
      setSignatureTooSmall(false);
    }
    setFormData((_formData) => ({
      ..._formData,
      signature: signatureBase64,
    }));
    dispatchMissingDetailsSignatureDrawnAction();
  };

  const disableNextButton = isSubmitting || !formData.signature || signatureTooSmall || signatureDrawn === false;

  const missingDetailsData = {
    nationalInsuranceNumber: formData.nationalInsuranceNumber,
    signature: formData.signature,
  };

  async function isUserOnLastStepOfFlow() {
    if (step === totalSteps - 2) {
      setIsSubmitLoading(true);
      await dispatchUpdatePersonalDetails(missingDetailsData);
      dispatchStatusNotificationMissingDetailsCompleted();
      dispatchMissingDetailsComplete();
      setIsSubmitLoading(false);
      setStep((_step) => _step + 1);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.missingDetailsWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
          <ButtonLink
            onClick={() => {
              dispatchMissingDetailsReturnLinkClickedAction();
              setStep(step - 1);
            }}
            label="← Back"
          />
        )}
        <h1 className={styles.ninoHeading}>
          {heading}
          {'Signature'}
        </h1>
        <div className={styles.content}>
          {'Pension providers, including the HMRC, require a signature to release pension information. By signing the box below, you are authorising Profile Pensions to obtain pension information and securely process your personal data in line with our '}
          <a className={styles.privacyPolicy} href="https://www.profilepensions.co.uk/privacy-policy" target="_blank" rel="noreferrer">{'Privacy Policy.'}</a>
        </div>
        <AuthoritySignature
          onChange={signatureHandleChange}
          signatureTooSmall={signatureTooSmall}
          showHeading
          formData={formData.signature}
          dataSentry="/dashboard/missing-details/signature-pad"
        />
        <Button
          size="large"
          type="button"
          label={'Next'}
          disabled={disableNextButton}
          loading={isSubmitting}
          onClick={() => {
            dispatchMissingDetailsSignatureSubmitClickedAction();
            setFormData((_formData) => ({
              ..._formData,
              signature: digitalSignature || formData.signature,
            }));
            isUserOnLastStepOfFlow();
            setStep((_step) => _step + 1);
          }}
          wrapperId="missing-details-submit"
        />
        {<div>&nbsp;</div>}
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'SignatureStep'}
            noExpertContentWrapper={({ children }) => (
              <div
                style={{
                  display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                }}
              >
                {children}
              </div>
            )}
            showButton={false}
            customMessage={(
              <div
                className={styles.contactUsInnerContent}
              >
                {'If you have any questions, please feel free to '}
                <ButtonLink
                  label="message me"
                  variant="primary"
                  link="/inbox/new-message"
                  font="inherit"
                  target="_blank"
                  onClick={() => dispatchMissingDetailsMessageMeClickedAction()}
                />
                {'.'}
              </div>
              )}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapDispatchToProps = {
  dispatchMissingDetailsReturnLinkClickedAction: missingDetailsReturnLinkClickedAction,
  dispatchSignatureSizeError: missingDetailsSignatureSizeErrorAction,
  dispatchMissingDetailsSignatureDrawnAction: missingDetailsSignatureDrawnAction,
  dispatchMissingDetailsMessageMeClickedAction: missingDetailsMessageMeClickedAction,
  dispatchMissingDetailsSignatureSubmitClickedAction: missingDetailsSignatureSubmitClickedAction,
  dispatchUpdatePersonalDetails: updatePersonalDetails,
  dispatchStatusNotificationMissingDetailsCompleted: statusNotificationMissingDetailsCompleted,
  dispatchMissingDetailsComplete: missingDetailsCompleteAction,
};

const mapStateToProps = (state) => ({
  digitalSignature: getHasDigitalSignature(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MissingDetailsSignature);
