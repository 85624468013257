import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './AddPensionsPage.css';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import RadioOptions from '../../components/RadioOptions/RadioOptions';
import {
  currentlyContributingStepLandedAction,
  returnLinkClickedAction,
  currentlyContributingYesSelectedAction,
  currentlyContributingNoSelectedAction,
  messageMeClickedAction,
  currentlyContributingSubmitClickedAction,
} from '../../redux/modules/pensions';
import { transferOptions } from '../../util/constants';

const CurrentlyContributingStep = ({
  setFormData,
  setStep,
  amendMode,
  step,
  totalSteps,
  dispatchCurrentlyContributingStepLanded,
  dispatchReturnLinkClicked,
  dispatchCurrentlyContributingYesSelected,
  dispatchCurrentlyContributingNoSelected,
  dispatchCurrentlyContributingSubmitClicked,
  dispatchMessageMeClicked,
  isCustomerOngoing,
  formData,
  setEmployerContributionFlow,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchCurrentlyContributingStepLanded(isCustomerOngoing, formData.transferOption);
  }, []);
  const defaultValue = typeof formData.currentWorkplacePension === 'boolean' ? formData.currentWorkplacePension : undefined;
  const [currentWorkplacePension, setCurrentWorkplacePension] = useState(defaultValue);
  const isTransferBasic = formData.transferOption === transferOptions.TRANSFER_BASIC;

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.currentlyContributingWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
          <ButtonLink
            onClick={(event) => {
              dispatchReturnLinkClicked(isCustomerOngoing, formData.transferOption);
              setStep((_step) => _step - 1);
              event.stopPropagation();
            }}
            label="← Back"
          />
        )}
        <h3 className={styles.currentlyContributingHeading}>
          {'Is your employer currently contributing to your pension plan?*'}
        </h3>
        <RadioOptions
          optionOneClickHandler={() => {
            dispatchCurrentlyContributingYesSelected(isCustomerOngoing, formData.transferOption);
            setCurrentWorkplacePension(true);
          }}
          isOptionOneSelected={currentWorkplacePension === true}
          optionTwoClickHandler={() => {
            dispatchCurrentlyContributingNoSelected(isCustomerOngoing, formData.transferOption);
            setCurrentWorkplacePension(false);
          }}
          isOptionTwoSelected={currentWorkplacePension === false}
        />
        <div
          className={styles.submitContainer}
        >
          <Button
            size="large"
            disabled={typeof currentWorkplacePension !== 'boolean'}
            label={(amendMode) ? 'Confirm' : 'Submit'}
            onClick={() => {
              setFormData((_formData) => ({
                ..._formData,
                currentWorkplacePension,
              }));
              if (currentWorkplacePension && isTransferBasic) {
                setEmployerContributionFlow();
              } else {
                setStep((_step) => _step + 1);
              }
              dispatchCurrentlyContributingSubmitClicked(
                isCustomerOngoing,
                formData.transferOption,
              );
            }}
          />
        </div>
        {!isTransferBasic && (
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'Contribution'}
            noExpertContentWrapper={({ children }) => (
              <div
                style={{
                  display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                }}
              >
                {children}
              </div>
            )}
            customMessage={(
              <div
                className={styles.contactUsInnerContent}
              >
                {'Sometimes we can’t transfer pensions with active contributions. If you have any questions, please feel free to '}
                <ButtonLink
                  variant="primary"
                  label="message me"
                  to="/inbox/new-message"
                  font="inherit"
                  onClick={() => dispatchMessageMeClicked(
                    isCustomerOngoing,
                    formData.transferOption,
                  )}
                />
                {'.'}
              </div>
                )}
            showButton={false}
          />
        </div>
        )}
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  dispatchCurrentlyContributingStepLanded: currentlyContributingStepLandedAction,
  dispatchReturnLinkClicked: returnLinkClickedAction,
  dispatchCurrentlyContributingYesSelected: currentlyContributingYesSelectedAction,
  dispatchCurrentlyContributingNoSelected: currentlyContributingNoSelectedAction,
  dispatchMessageMeClicked: messageMeClickedAction,
  dispatchCurrentlyContributingSubmitClicked: currentlyContributingSubmitClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentlyContributingStep);
