import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { renderTextField } from '../../util/formik-custom-fields';
import styles from './ShowablePasswordField.css';
import ShowPasswordImage from '../../assets/images/rebranding/eye.svg';
import HidePasswordImage from '../../assets/images/rebranding/eye-off.svg';

const ShowablePasswordField = ({
  onChange,
  required,
  name,
  label,
  defaultValue,
}) => {
  const [showPassword, setShowPassword] = useState(true);
  const handleShowHidePasswordClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <Field
        name={name}
        type={showPassword ? 'password' : 'text'}
        component={renderTextField}
        label={label}
        defaultValue={defaultValue}
        onChange={onChange}
        required={required}
      />
      <br />
      <div className={styles.showHidePasswordContainer} onClick={handleShowHidePasswordClick}>
        <div className={styles.eyeImgContainer}>
          <img
            src={showPassword ? ShowPasswordImage : HidePasswordImage}
            className={styles.eyeImg}
            alt={showPassword ? 'Show Icon' : 'Hide Icon'}
          />
        </div>
        <div className={styles.showHidePasswordText}>{showPassword ? 'Show' : 'Hide'}</div>
      </div>
    </div>
  );
};

ShowablePasswordField.propTypes = {
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
};

ShowablePasswordField.defaultProps = {
  required: false,
  name: 'password',
  label: 'Password',
  defaultValue: 'Enter password',
};

export default ShowablePasswordField;
