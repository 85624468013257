exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-FooterSocialLinks-wrapper-19hCg {\n  grid-row: 2;\n  grid-column: 1 / span 6;\n  margin-top: 50px;\n  width: 100%;\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n  .-FooterSocialLinks-wrapper-19hCg {\n    grid-row: 2;\n    grid-column: 8 / span 3;\n    margin-top: 65px;\n  }\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["largeDesktop"] + ") {\n  .-FooterSocialLinks-wrapper-19hCg {\n    grid-row: 1;\n    grid-column: 10 / span 3;\n    margin-top: 80px;\n  }\n}\n.-FooterSocialLinks-links-1V0cM {\n  font-size: 29px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n}\n.-FooterSocialLinks-icon-2lqAe {\n  position: relative;\n  text-align: center;\n  width: 0px;\n  height: 0px;\n  padding: 16px;\n  border-radius: 16px;\n  color: #FFFFFF;\n  background: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["accentBasePrimaryRegular"] + ";\n}\n.-FooterSocialLinks-icon-2lqAe:not(:last-child) {\n  margin: 0 9px 0 0;\n}\n.-FooterSocialLinks-icon-2lqAe svg {\n  font-size: 16px;\n  position: absolute;\n  left: 9px;\n  top: 8px;\n  fill: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["paleSlate"] + ";\n}\n", ""]);

// exports
exports.locals = {
	"dimensions": "\"../../theme/dimensions.css\"",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"largeDesktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["largeDesktop"] + "",
	"colors": "\"../../theme/colors.css\"",
	"midnight": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["midnight"] + "",
	"accentBasePrimaryRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["accentBasePrimaryRegular"] + "",
	"paleSlate": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["paleSlate"] + "",
	"wrapper": "-FooterSocialLinks-wrapper-19hCg",
	"links": "-FooterSocialLinks-links-1V0cM",
	"icon": "-FooterSocialLinks-icon-2lqAe"
};