import React from 'react';
import PropTypes from 'prop-types';

import ButtonLink from '../ButtonLink/ButtonLink';
import IconInfo from '../../assets/images/rebranding/information-icon-in-circle.svg';
import IconCross from '../../assets/images/rebranding/cross-icon-in-circle.svg';
import styles from './InformationIcon.css';

const InformationIcon = (props) => {
  const {
    onClick,
    active,
    infoIcon,
    closeIcon,
  } = props;

  const informationIcon = infoIcon || IconInfo;
  const closeInformationIcon = closeIcon || IconCross;

  return (
    <ButtonLink
      icon
      onClick={onClick}
      label={(
        <img
          className={styles.helperTextIcon}
          src={active ? closeInformationIcon : informationIcon}
          alt="information"
        />
      )}
      bold
    />
  );
};

export default InformationIcon;

InformationIcon.propTypes = {
  onClick: PropTypes.func,
  infoIcon: PropTypes.instanceOf(Element),
  closeIcon: PropTypes.instanceOf(Element),
};

InformationIcon.defaultProps = {
  onClick: () => { },
  infoIcon: null,
  closeIcon: null,
};
