import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ArrowIcon from '../../assets/images/rebranding/right-arrow-inside-circle.svg';
import styles from './DocumentTable.css';

export default class DocumentTable extends React.Component {
  constructor(props) {
    super(props);
    this.sort = this.sort.bind(this);
    this.state = { sortBy: 'updated', reverse: false };
  }

  sort(sortBy) {
    const lastSort = this.state.sortBy;
    if (lastSort === sortBy) {
      this.setState(({ reverse }) => ({ reverse: !reverse }));
    } else {
      this.setState({ sortBy, reverse: false });
    }
  }

  render() {
    const { onDownload, files } = this.props;
    const { reverse, sortBy } = this.state;

    const sortName = (a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    };

    const toDate = (dateStr) => {
      const [day, month, year] = dateStr.split('/');
      return new Date(year, month - 1, day);
    };

    const sortDate = (a, b) => {
      const aDate = toDate(a.updated);
      const bDate = toDate(b.updated);
      if (aDate > bDate) {
        return -1;
      } if (aDate < bDate) {
        return 1;
      }
      return 0;
    };

    const isActive = (sort) => sort === sortBy;
    const sortClasses = (sort) => [
      styles.cell,
      styles.sort,
      isActive(sort) ? styles.active : null,
      isActive(sort) && reverse ? styles.reverse : null,
    ].join(' ');

    if (sortBy === 'name') {
      files.sort(sortName);
    } else if (sortBy === 'updated') {
      files.sort(sortDate);
    }

    if (reverse) {
      files.reverse();
    }

    return (
      <div className={styles.table} data-cy="document-table">
        <div data-cy="document-table" className={[styles.row, styles.header, styles.headerRowText].join(' ')}>
          <div
            className={sortClasses('name')}
            onClick={() => this.sort('name')}
            role="radio"
            aria-checked={isActive('name')}
            tabIndex={0}
          >
            Name
          </div>
          <div
            className={sortClasses('updated')}
            onClick={() => this.sort('updated')}
            role="radio"
            aria-checked={isActive('updated')}
            tabIndex={0}
          >
            Date
          </div>
          <div className={styles.cell} />
        </div>
        {files.map((file) => (
          <div key={file.name} className={styles.row} data-cy="document-row">
            <div className={classnames(styles.cell, styles.bodyCell)}>{file.name}</div>
            <div className={classnames(styles.cell, styles.bodyCell)}>{file.updated}</div>
            <div className={classnames(styles.cell, styles.bodyCell)}>
              <div
                className={styles.action}
                onClick={() => onDownload(file.downloadUrl)}
                role="button"
                tabIndex="0"
              >
                <img src={ArrowIcon} alt="download icon" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

DocumentTable.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    updated: PropTypes.string,
    downloadUrl: PropTypes.string,
  })).isRequired,
  onDownload: PropTypes.func.isRequired,
};
