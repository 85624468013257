import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import * as yup from 'yup';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirestore } from 'react-redux-firebase';
import trim from 'lodash/trim';
import get from 'lodash/get';
import zxcvbn from 'zxcvbn';
import sentryException from '../../util/sentryException';

import { getAuthError, getFirebaseUid } from '../../redux/selectors';
import { renderTextField, renderSelectField } from '../../util/formik-custom-fields';
import FormError from '../../components/FormError/FormError';
import Button from '../../components/Button/Button';
import LoaderPage from '../../pages/LoaderPage/LoaderPage';
import Loader from '../../components/Loader/Loader';
import PasswordStrength from '../../components/PasswordStrength/PasswordStrength';
import ShowablePasswordField from '../../components/ShowablePasswordField/ShowablePasswordField';
import { loginUnverifiedUser } from '../../util/auth';
import getRecaptchaToken from '../../util/getRecaptchaToken';
import { dispatchCreateAccount } from '../../redux/modules/investmentAdvice';
import { newSignupCompleteAction } from '../../redux/modules/signupFlow';
import { CONSTANTS } from '../copyTexts';
import { CONTACT_US_MESSAGE } from '../../util/loginPageMessages';
import { MINIMUM_PASSWORD_STRENGTH } from '../../util/constants';
import {
  sendGaCustomEvent,
  gaCategories,
  gaLabels,
  gaActions,
} from '../../util/googleAnalyticHelpers';

import { emailRegex } from '../../util/validation';
import SystemError from '../../errors/SystemError';

import styles from './Signup.css';

const MESSAGES = {
  USER_EXISTS: 'already-exists',
  EMAIL_NOT_FOUND: 'email-not-found',
  ACCOUNT_NOT_FOUND: 'online-account-not-found',
  ACCOUNT_FOUND: 'online-account-found',
  INTERNAL_ERROR: 'internal-error',
};

const TITLES = ['mr', 'mrs', 'miss', 'mx', 'dr', 'ms', 'prof', 'rev'];

const schema = yup.object().shape({
  salutation: yup.string().required('Required'),
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
});

const capitalise = (string) => {
  return string ? string.charAt(0).toUpperCase().concat(string.slice(1)) : '';
};

const lowercase = (string) => {
  return string ? string.toLowerCase() : '';
};

class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: undefined,
      loggedIn: false,
      loading: false,
      submitError: false,
    };

    this.headingRef = React.createRef();
  }

  componentDidMount() {
    const { uid } = this.props;

    if (uid) {
      this.setState({ loggedIn: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { loggedIn } = this.state;
    const { history } = this.props;

    if (loggedIn && loggedIn !== prevState.loggedIn) {
      history.push({ pathname: '/' });
    }
  }

  onSubmit = async (formSubmission, { setSubmitting }) => {
    const {
      dispatchCreateAccount: createAccount,
      dispatchNewSignupComplete,
    } = this.props;

    const hiddenFields = {};
    const fields = document.getElementsByClassName('trackingField');

    Array.from(fields).forEach((field) => {
      hiddenFields[field.name] = field.value;
    });

    const validPartners = ['telegraph', 'mumsnet', 'moneysupermarket', 'reach'];

    const utmSource = (hiddenFields.utm_source || '').toLowerCase();

    // Propagate the partner if the utm_source contains a valid string
    const partner = hiddenFields.tduid ? 'tradedoubler' : validPartners.find((_partner) => utmSource.match(_partner));

    const signup = {
      ...formSubmission,
      salutation: formSubmission.salutation,
      email: trim(lowercase(formSubmission.email)),
      firstName: capitalise(formSubmission.firstName),
      lastName: capitalise(formSubmission.lastName),
      ...(partner && { partner }),
      utmFields: {
        utmCampaign: hiddenFields.utm_campaign,
        utmSource: hiddenFields.utm_source,
        utmMedium: hiddenFields.utm_medium,
        utmContent: hiddenFields.utm_content,
        utmTerm: hiddenFields.utm_term,
        gclid: hiddenFields.gclid,
        gaid: hiddenFields.gaid,
        tduid: hiddenFields.tduid,
      },
    };

    this.setState({ loading: true, submitError: false });

    try {
      const createAccountRecaptchaToken = await getRecaptchaToken('signup');

      const signupResponse = await createAccount(signup, createAccountRecaptchaToken);
      const redirectUrl = get(signupResponse, 'redirectUrl');
      const authToken = get(signupResponse, 'authToken');

      if (authToken) {
        await loginUnverifiedUser(authToken);
        sendGaCustomEvent({
          category: gaCategories.acquisition,
          label: gaLabels.signupClicks,
          action: gaActions.recordCount,
        });
        dispatchNewSignupComplete();
        this.setState({ loggedIn: true, loading: false });
      } else if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    } catch (error) {
      if (error instanceof SystemError) {
        sentryException(error, {
          section: 'signup-failed',
        });
      }
      this.setState({ loading: false, submitError: true });
      setSubmitting(false);
    }
  };

  render() {
    const { authError } = this.props;

    const {
      loading,
      submitError,
      redirectTo,
    } = this.state;

    if (loading) {
      const metadata = { context: 'signup', loading };
      return (
        <LoaderPage metadata={metadata} />
      );
    }

    if (redirectTo) {
      return (<Redirect to={redirectTo} />);
    }

    return (
      <Formik
        onSubmit={this.onSubmit}
        validationSchema={schema}
        enableReinitialize
        initialValues={{
          salutation: '',
          firstName: '',
          lastName: '',
          email: '',
          password: '',
        }}
        validate={(values) => {
          const errors = {};

          const customSchema = yup.object().shape({
            email: yup.string().required('Required').matches(emailRegex, 'Please provide a valid email address'),
            password: yup.string()
              .min(8, 'Must be a minimum of 8 characters')
              .test('password-score', 'Please provide a stronger password', () => {
                if (!values.password) return false;
                return zxcvbn(values.password).score >= MINIMUM_PASSWORD_STRENGTH;
              }),
          });

          try {
            customSchema.validateSync({
              email: values.email,
              password: values.password,
            }, { abortEarly: false });
          } catch (err) {
            if (err && err.inner) {
              err.inner.forEach((error) => {
                if (error.path === 'email') {
                  errors.email = error.message;
                }
                if (error.path === 'password') {
                  errors.password = error.message;
                }
              });
            }
          }

          return errors;
        }}
        render={({
          isSubmitting,
          status,
          errors,
          values,
          setFieldValue,
          setFieldTouched,
          handleChange,
        }) => {
          return (
            <section>
              <div className={styles.signupContainer}>
                <h1 ref={this.headingRef} className={styles.heading}>{'Sign up'}</h1>
                <div className={styles.login}>
                  {'Already have an account? '}
                  <Link className={styles.loginLink} to="/login">{'Log in'}</Link>
                </div>
                {submitError && !authError && (
                  <p className={styles.errorText}>
                    {`${CONTACT_US_MESSAGE} Email us at `}
                    <a href="mailto: contact@profilepensions.co.uk">{'contact@profilepensions.co.uk'}</a>
                  </p>
                )}
                {authError && (
                  <div>
                    {
                      authError.code.includes(MESSAGES.USER_EXISTS)
                        ? (
                          <p className={styles.errorText}>
                            {'An account with this email address already exists. If you have forgotten your password, '}
                            <Link className={styles.loginLink} to="/forgot-password">
                              {'click here'}
                            </Link>
                            {' to reset it.'}
                          </p>
                        )
                        : (
                          <p className={styles.errorText}>{'Something went wrong. Please try again.'}</p>
                        )
                    }
                  </div>
                )}
                <Form>
                  <Field
                    name="salutation"
                    type="select"
                    label="Title"
                    value={capitalise(values.salutation)}
                    options={TITLES.map((title) => ({ value: title, label: capitalise(title) }))}
                    onChange={(value) => {
                      setFieldValue('salutation', value.salutation);
                    }}
                    component={renderSelectField}
                  />
                  <Field
                    name="firstName"
                    type="text"
                    component={renderTextField}
                    label="Legal first name*"
                    defaultValue="Enter legal first name as shown on your ID"
                  />
                  <br />
                  <Field
                    name="lastName"
                    type="text"
                    component={renderTextField}
                    label="Legal surname*"
                    defaultValue="Enter legal surname as shown on your ID"
                  />
                  <br />
                  <Field
                    name="email"
                    type="email"
                    component={renderTextField}
                    label="Email address*"
                    defaultValue="Enter email address"
                  />
                  <br />
                  <ShowablePasswordField
                    name="password"
                    label="Create password*"
                    onChange={(e) => {
                      setFieldTouched('password');
                      handleChange(e);
                    }}
                  />
                  {values && values.password ? <PasswordStrength pwd={values.password} /> : <br />}
                  <FormError error={status && status.formError} />
                  <br />
                  <div className={styles.terms}>
                    {CONSTANTS.SIGNUP_PAGE_TEXT}
                    <a className={styles.privacyLink} rel="noopener noreferrer" target="_blank" href="https://www.profilepensions.co.uk/privacy-policy">{'Privacy Policy'}</a>
                    {'.'}
                  </div>
                  <br />
                  <div className={styles.terms}>
                    {'This site is protected by reCAPTCHA and the Google '}
                    <a href="https://policies.google.com/privacy">{'Privacy Policy'}</a>
                    {' and '}
                    <a href="https://policies.google.com/terms">{'Terms of Service'}</a>
                    {' apply.'}
                  </div>
                  <div className={styles.submitContainer}>
                    {isSubmitting
                      ? (
                        <Loader />
                      )
                      : (
                        <Button
                          size="large"
                          type="submit"
                          disabled={!!Object.keys(errors).length}
                          label="Sign up"
                          center
                        />
                      )}
                  </div>
                </Form>
              </div>
            </section>
          );
        }}
      />
    );
  }
}

Signup.propTypes = {
  authError: PropTypes.shape({ message: PropTypes.string.isRequired }),
  dispatchCreateAccount: PropTypes.func.isRequired,
  dispatchNewSignupComplete: PropTypes.func.isRequired,

};

Signup.defaultProps = {
  authError: null,
};

const mapStateToProps = (state) => ({
  authError: getAuthError(state),
  uid: getFirebaseUid(state),
});

const mapDispatchToProps = {
  dispatchCreateAccount,
  dispatchNewSignupComplete: newSignupCompleteAction,
};

export default compose(
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Signup));
