import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { ajax } from 'rxjs/ajax';
import { AUTH_ENVIRONMENT } from '../config';

const PPAuth = {};

const defaultAjaxConfig = {
  method: 'POST',
  crossDomain: true,
  responseType: 'json',
  headers: { 'Content-Type': 'application/json' },
};

const createAjaxPromise = (ajaxConfig) => new Promise(
  (resolve, reject) => ajax({ ...defaultAjaxConfig, ...ajaxConfig })
    .toPromise()
    .then((response) => resolve(response.response))
    .catch((err) => reject(err)),
);

PPAuth.login = (email, pwd, recaptchaToken) => createAjaxPromise({
  url: `${AUTH_ENVIRONMENT}/auth/login`,
  body: { email, pwd, recaptchaToken },
});

PPAuth.loginVerifySMS = (uid, code, recaptchaToken) => createAjaxPromise({
  url: `${AUTH_ENVIRONMENT}/auth/verify-sms`,
  body: { uid, code, recaptchaToken },
});

PPAuth.resendSMS = (uid, recaptchaToken) => createAjaxPromise({
  url: `${AUTH_ENVIRONMENT}/auth/resend-sms`,
  body: { uid, recaptchaToken },
});

PPAuth.verificationRequestSMS = (uid) => createAjaxPromise({
  url: `${AUTH_ENVIRONMENT}/auth/request-signup-sms`,
  body: { uid },
});

PPAuth.passwordUpdate = (authToken, pwdCurrent, pwd) => createAjaxPromise({
  url: `${AUTH_ENVIRONMENT}/auth/password-update`,
  body: { authToken, pwdCurrent, pwd },
});

PPAuth.passwordResetLink = (email, recaptchaToken) => createAjaxPromise({
  url: `${AUTH_ENVIRONMENT}/auth/password-reset-link`,
  body: { email, recaptchaToken },
});

PPAuth.queryPasswordResetRef = (id) => createAjaxPromise({
  url: `${AUTH_ENVIRONMENT}/auth/query-password-reset-ref`,
  body: { id },
});

PPAuth.passwordReset = (pwd, id) => createAjaxPromise({
  url: `${AUTH_ENVIRONMENT}/auth/password-reset`,
  body: { pwd, id },
});

PPAuth.passwordResetVerifySMS = (uid, code, pwd, recaptchaToken) => createAjaxPromise({
  url: `${AUTH_ENVIRONMENT}/auth/password-reset-verify-sms`,
  body: {
    pwd, uid, code, recaptchaToken,
  },
});

PPAuth.verifySMS = (uid, code) => createAjaxPromise({
  url: `${AUTH_ENVIRONMENT}/activate/verify-mobile`,
  body: { uid, code },
});

PPAuth.setUserPassword = (password) => {
  const setPassword = firebase.app().functions('europe-west1').httpsCallable('setUserPassword');

  return setPassword({ password });
};

PPAuth.verifyEmail = (ref) => {
  const verifyEmail = firebase.app().functions('europe-west1').httpsCallable('verifyEmail');
  return verifyEmail(ref);
};

PPAuth.resendEmail = (uid) => {
  const resendEmail = firebase.app().functions('europe-west1').httpsCallable('sendVerification');
  return resendEmail(uid);
};

PPAuth.generateNewIdToken = async (currentAuthToken, recaptchaToken) => {
  const generateCustomTokenFromIdToken = firebase.app().functions('europe-west1').httpsCallable('generateCustomTokenFromIdToken');
  return generateCustomTokenFromIdToken({ currentAuthToken, recaptchaToken });
};

export default PPAuth;
