/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirestore } from 'react-redux-firebase';
import { withFormik } from 'formik';
import { GENERIC_ERROR } from '../../forms/copyTexts';
import { DEFAULT_CONTACT_EMAIL } from '../../config';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import {
  signatureDrawnAction,
  signatureSizeErrorAction,
} from '../../redux/modules/signupFlow';
import {
  getUser, getIsVerified, getFirebaseUid,
} from '../../redux/selectors';
import { updateSignature } from '../../redux/modules/investmentAdvice';
import '../../util/signupValidators';
import styles from './PersonalDetails.css';
import AuthoritySignature from './AuthoritySignature';

const MINIMUM_SIGNATURE_HEIGHT_PX = 30;
const MINIMUM_SIGNATURE_WIDTH_PX = 70;
class UpdateAuthoritySignature extends Component {
  constructor(props) {
    super(props);
    this.state = { signatureTooSmall: false };
    this.state = { inEditMode: false };
  }

  componentDidUpdate(prevProps) {
    const {
      isSubmitting,
      isValid,
      errors,
    } = this.props;
    if (!isValid && prevProps.isSubmitting && !isSubmitting) {
      const [firstError] = Object.keys(errors);
      const errorElement = document.querySelector(`input[name="${firstError}"]`);
      if (errorElement) {
        errorElement.parentNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    if (isValid && !prevProps.isSubmitting && isSubmitting) {
      document
        .querySelector('button[type=submit]')
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  setLocalInEditMode
    = (inEditMode) => {
      this.setState({
        inEditMode,
      });
    }

  constructEmailUsElement = (showOnlyLink) => {
    return (
      <span>
        {(!showOnlyLink) ? 'Please ' : ''}
        <ButtonLink
          variant="primary"
          font="inherit"
          label="message us"
          to="/inbox/new-message"
        >
          {'message us'}
        </ButtonLink>
        {'.'}
      </span>
    );
  }

  signatureHandleChange = (signature) => {
    const { setFieldValue, dispatchSignatureDrawn, dispatchSignatureSizeError } = this.props;

    if (!signature) {
      this.setState({ signatureTooSmall: false });
      setFieldValue('signature', null);
    } else {
      dispatchSignatureDrawn();

      const signatureBase64 = signature.toDataURL('image/svg+xml');
      const { height, width } = signature;
      const signatureTooSmall = height < MINIMUM_SIGNATURE_HEIGHT_PX
        || width < MINIMUM_SIGNATURE_WIDTH_PX;

      if (signatureTooSmall) {
        dispatchSignatureSizeError();
      }

      this.setState({ signatureTooSmall });
      setFieldValue('signature', signatureBase64);
    }
  }

  /* eslint consistent-return: 0 */
  constructErrorMessage = (data) => {
    if (!data) {
      return;
    }
    return (
      <div>
        {GENERIC_ERROR}
        {this.constructEmailUsElement()}
      </div>
    );
  }

  render() {
    const {
      handleSubmit,
      isSubmitting,
      errors,
      values,
      user,
      isValid,
      initialValues,
      handleReset,
      setComponentIsEditing,
      componentIsEditing,
      status,
      setStatus,
    } = this.props;
    const { inEditMode, signatureTooSmall } = this.state;
    const formValuesHaveChanged = !isEqual(initialValues, values);
    const signatureInFirestore = get(user, 'signature');
    const submitSuccessful = get(status, 'submitSuccessful');
    if (submitSuccessful) {
      this.state.inEditMode = false;
      setStatus({ submitSuccessful: false });
    }
    const showUpdate = !inEditMode && !componentIsEditing;
    const showSave = inEditMode && formValuesHaveChanged && componentIsEditing;
    const showCancel = inEditMode && componentIsEditing;
    return (
      <div className={styles.container}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <h1 className={styles.heading}>
            {'Signature'}
          </h1>
          {signatureInFirestore && (
            <img className={styles.alignImage} src={signatureInFirestore} alt="Account Owner's Signature" />
          )}
          {!signatureInFirestore && (
            <p className={styles.content}>{'No signature found.'}</p>
          )}
          {inEditMode && componentIsEditing && (
            <AuthoritySignature
              onChange={this.signatureHandleChange}
              signature={signatureInFirestore}
              signatureTooSmall={signatureTooSmall}
              dataSentry="/dashboard/complete-personal-details/signature-pad"
            />
          )}
          <div className={styles.errorMessage}>{errors && this.constructErrorMessage(errors.submitError)}</div>
          <div className={styles.actionRow}>
            {showUpdate && (
              <Button
                size="small"
                label={signatureInFirestore ? 'Update' : 'Add'}
                onClick={() => {
                  this.setLocalInEditMode(true);
                  setComponentIsEditing(true);
                }}
              />
            )}
            {showSave && values.signature && (
              <Button type="submit" label="Save" mediumFixedWidth disabled={!formValuesHaveChanged || isSubmitting || !isValid || (!values.signature || signatureTooSmall)} loading={isSubmitting} />
            )}
            {showCancel && (
              <Button
                size="small"
                label="Cancel"
                disabled={isSubmitting}
                onClick={() => {
                  this.setLocalInEditMode(false);
                  setComponentIsEditing(false);
                  handleReset();
                }}
              />
            )}
          </div>
        </form>
      </div>
    );
  }
}

/**
 * withFormik is being used to map props to
 * form values which will auto populate the fields.
 */
const FormikEnhancer = withFormik({
  mapPropsToValues: (props) => {
    const { user } = props;
    return {
      signature: get(user, 'signature'),
    };
  },
  handleSubmit: async (values, {
    setSubmitting, props, setStatus, resetForm,
  }) => {
    const { dispatchUpdateDetails, setComponentIsEditing } = props;

    setSubmitting(true);
    const data = {
      signature: values.signature,
    };
    await dispatchUpdateDetails(data);
    setSubmitting(false);
    setComponentIsEditing(false);
    resetForm(values);
    return setStatus({ submitSuccessful: true });
  },
  displayName: 'UpdateAuthoritySignature',
})(UpdateAuthoritySignature);

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
  user: getUser(state),
  isVerified: getIsVerified(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSignatureDrawn: () => dispatch(signatureDrawnAction()),
  dispatchSignatureSizeError: () => dispatch(signatureSizeErrorAction()),
  dispatchUpdateDetails: (data) => dispatch(updateSignature(data)),
});

export default compose(
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps),
)(FormikEnhancer);
