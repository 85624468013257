import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/performance';
import { FB_CONFIG, AUTH_ENVIRONMENT } from '../config';

firebase.initializeApp(FB_CONFIG);
firebase.performance();

if (AUTH_ENVIRONMENT && AUTH_ENVIRONMENT.includes('localhost')) {
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
}

export default firebase;
