import React from 'react';

import BasePage from '../../components/BasePage/BasePage';
import WithIntercom from '../../components/WithIntercom/WithIntercom';
import Signup from '../../forms/Signup/Signup';

const SignupPage = () => (
  <WithIntercom>
    <BasePage showFooter={false}>
      <Signup />
    </BasePage>
  </WithIntercom>
);

export default SignupPage;
