import get from 'lodash/get';

const adviserEmailMapping = {
  'paul.hepplestone@moneyfarm.com': 'paul.hepplestone@moneyfarm.com',
  'samantha.packham@moneyfarm.com': 'samantha.packham@moneyfarm.com',
  'richard.penney@moneyfarm.com': 'richard.penney@moneyfarm.com',
  'mathew.mcdonald@moneyfarm.com': 'mathew.mcdonald@moneyfarm.com',
  'olivia.craig@moneyfarm.com': 'olivia.craig@moneyfarm.com',
  'josefine.jonsson@moneyfarm.com': 'josefine.jonsson@moneyfarm.com',
  'ashleigh.ramsbottom@moneyfarm.com': 'ashleigh.ramsbottom@moneyfarm.com',
};

export default (m, customerAdviserEmail) => {
  const fromUsername = get(m, 'fromUsername');
  if (!fromUsername || !customerAdviserEmail) return false;
  return adviserEmailMapping[fromUsername] === customerAdviserEmail;
};
