import React from 'react';
import withLinkTo from '../../util/withLinkTo';
import ButtonLinkVariants from './ButtonLinkTypes';

import styles from './ButtonLink.css';

const ButtonLink = ({
  href, target, rel, variant = ButtonLinkVariants.secondary, children, label, onClick, disabled = '', ...props
}) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a href={href} target={target} rel={target === '_blank' ? 'noopener noreferrer' : rel} className={`${styles.button} ${styles[variant]} ${disabled ? 'disabled' : ''}`} onClick={onClick} {...props}>
      {label}
      {children}
    </a>
  );
};

export default withLinkTo(ButtonLink);
