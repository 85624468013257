import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classNames from 'classnames';

import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import WithInvestmentAdviceLetter from '../../../components/WithDocumentLink/WithInvestmentAdviceLetter';
import AddPensionCard from '../../../components/AddPensionCard/AddPensionCard';
import AddMoneyCard from '../../../components/AddMoneyCard/AddMoneyCard';
import TrustpilotStars from '../../../components/TrustpilotStars/TrustpilotStars';
import SERPSNextSteps from '../../../components/SERPSCard/SERPSNextSteps';

import styles from './PlanSummaryPage.css';

import providerMap from '../../../util/providerMap';
import getFundsWithRoundedSplits from '../../../util/getFundsWithRoundedSplits';
import { ADVISER_FEE } from '../../../util/constants';
import { getPensions } from '../../../redux/selectors/pensions';
import { getIVUCollection, getVisitedPlanSummaryPage } from '../../../redux/selectors';

import graph from '../../../assets/images/rebranding/three-bar-graph.svg';
import moneyShield from '../../../assets/images/rebranding/money-shield.svg';
import horizontalOverlappingCircles from '../../../assets/images/rebranding/three-horizontal-overlapping-circles.svg';
import chatPerson from '../../../assets/images/rebranding/chat-person.svg';
import fcss from '../../../assets/images/fcss.svg';
import trustpilotLogo from '../../../assets/images/trustpilot-logo.png';
import pplogo from '../../../assets/images/rebranding/pp-logo.svg';
import limeTick from '../../../assets/images/lime-tick.svg';
import greyCross from '../../../assets/images/grey-cross.svg';
import documents from '../../../assets/images/onboardingDashboard/onboarding-documents.png';
import adventurousGauge from '../../../assets/images/rebranding/investorProfileGauge/adventurous-gauge.svg';
import growthGauge from '../../../assets/images/rebranding/investorProfileGauge/growth-gauge.svg';
import balancedGauge from '../../../assets/images/rebranding/investorProfileGauge/balanced-gauge.svg';
import cautiousGauge from '../../../assets/images/rebranding/investorProfileGauge/cautious-gauge.svg';
import conservativeGauge from '../../../assets/images/rebranding/investorProfileGauge/conservative-gauge.svg';
import esgTag from '../../../assets/images/esg-tag.svg';

import {
  addPensionThankYouPageView,
  setForceMentionMeModalAction,
  reportFromRecommendationDetailClickedAction,
  addMoneyFromRecommendationDetailClickedAction,
  addPensionFromRecommendationDetailClickedAction,
  recommendationDetailPageLoadedAction,
} from '../../../redux/modules/investmentAdvice';
import { getTargetInvestment } from '../../../redux/selectors/targetInvestment';
import {
  getPensionExpert,
  getHasInProgressContributionInstruction,
} from '../../../redux/selectors/investmentAdvice';
import { getUserReferredFromMoneySupermarket } from '../../../redux/selectors/experience';
import { getWasSerpsTraceRequested } from '../../../redux/selectors/serps';
import { planSummaryPageVisitedStatus } from '../../../redux/modules/customerDataSet';

const adventurousAtrDescription = 'People in this category have a good understanding of investment risk. They are generally experienced investors. They aim for higher long-term returns and understand that this can also mean some sustained periods of poorer performance. They are prepared to accept significant fluctuations in value to try and achieve better long-term returns.';
const growthAtrDescription = 'People in this category are relatively comfortable with investment risk. They aim for higher long-term returns and understand that this can also mean some sustained periods of poorer performance. They are prepared to accept significant fluctuations in value to try and achieve better long-term returns.';
const balancedAtrDescription = 'People in this category are balanced in their attitude towards risk. They don’t seek risky investments but they don’t avoid them either. They are prepared to accept fluctuations in the value of their investments to try and achieve better long term returns. These portfolios will be subject to frequent and at times significant fluctuations in value.';
const cautiousAtrDescription = 'People in this category are relatively cautious with their investments. They want to try to achieve a reasonable return and are prepared to accept some risk in doing so. Typically these portfolios will exhibit relatively modest yet frequent fluctuations in value.';
const conservativeAtrDescription = 'Based on your attitude to risk and the information you have provided, we recommend a conservative portfolio as being the most suitable investment option for you. As a conservative investor, we recommend a high allocation to global equities aiming to maximise returns and a small allocation to bonds to control risk.';

const atrContentMap = {
  adventurous: {
    label: 'Adventurous',
    atrDescription: adventurousAtrDescription,
    gaugeImage: adventurousGauge,
  },
  growth: {
    label: 'Growth',
    atrDescription: growthAtrDescription,
    gaugeImage: growthGauge,
  },
  balanced: {
    label: 'Balanced',
    atrDescription: balancedAtrDescription,
    gaugeImage: balancedGauge,
  },
  cautious: {
    label: 'Cautious',
    atrDescription: cautiousAtrDescription,
    gaugeImage: cautiousGauge,
  },
  conservative: {
    label: 'Conservative',
    atrDescription: conservativeAtrDescription,
    gaugeImage: conservativeGauge,
  },
};

const PlanSummaryPage = ({
  targetInvestment,
  trustpilot,
  pensionExpert,
  ivuCollection,
  pensions,
  hasInProgressInstructions,
  visitedPlanSummaryPage,
  isUserReferredFromMoneySupermarket,
  dispatchAddMoneyFromRecommendationDetailClicked,
  dispatchAddPensionFromRecommendationDetailClicked,
  dispatchRecommendationDetailPageLoaded,
  dispatchReportFromRecommendationDetailClickedAction,
  dispatchPlanSummaryPageVisitedAction,
  wasSerpsTraceRequested,
}) => {
  const expertImage = get(pensionExpert, 'imageUrl');

  const _funds = get(targetInvestment, 'funds');
  const funds = getFundsWithRoundedSplits(_funds);

  const platformName = get(targetInvestment, 'platformName');
  const atrDescriptor = get(targetInvestment, 'atrDescriptor', '');
  const atrDescription = get(atrContentMap, `${atrDescriptor}.atrDescription`);
  const gaugeImage = get(atrContentMap, `${atrDescriptor}.gaugeImage`);
  const { label, logo } = providerMap[platformName];

  const totalReviews = get(trustpilot, 'tpBusinessUnits.numberOfReviews.total');
  const stars = get(trustpilot, 'tpBusinessUnits.score.stars');
  const trustScore = get(trustpilot, 'tpBusinessUnits.score.trustScore');

  const hasPensions = Boolean(pensions && pensions.length);
  const hasPensionOrInstruction = hasPensions || hasInProgressInstructions;

  const reference = get(targetInvestment, 'reference');
  const { environmentalSocialGovernance } = ivuCollection.find((p) => reference && reference === get(p, 'reference'));

  const fmc = get(targetInvestment, 'fmc');
  const platformFee = get(targetInvestment, 'platformFee');
  const annualCharge = (fmc + platformFee + ADVISER_FEE).toFixed(2);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchRecommendationDetailPageLoaded();

    return () => {
      dispatchPlanSummaryPageVisitedAction(true);
    };
  }, []);

  const isMoneySupermarketUserFirstVisit = !visitedPlanSummaryPage
    && isUserReferredFromMoneySupermarket;

  const mainHeading = isUserReferredFromMoneySupermarket
    ? 'We’ve selected the best pension plan for you from the whole of the market'
    : 'Why we’ve selected this pension plan for you';

  const backToDashboardLabel = isMoneySupermarketUserFirstVisit
    ? '← View dashboard'
    : '← Return to dashboard';

  return (
    <div className={styles.outerContainer}>
      <section className={styles.offWhiteSection}>
        <div className={styles.returnLink}>
          <ButtonLink
            label={backToDashboardLabel}
            to="/"
            secondary
            bold
          />
        </div>
        <div className={styles.planSummaryCard}>
          <h3 className={styles.mainHeading}>{mainHeading}</h3>
          {expertImage && (
            <div className={styles.adviserMessage}>
              <img className={styles.adviserImage} src={expertImage} alt="Pension expert" />
              <div>
                {'If you have any questions, please feel free to '}
                <ButtonLink
                  variant="primary"
                  label="message"
                  font="inherit"
                  to="/inbox/new-message"
                  underline
                />
                {'.'}
              </div>
            </div>
          )}
          <h6 className={styles.atrHeading}>{'Your investor profile is:'}</h6>
          <div className={styles.gaugeImageContainer}>
            <img className={styles.gaugeImage} src={gaugeImage} alt="Gauge" />
            {environmentalSocialGovernance && (
              <img src={esgTag} alt="Environmental social governance icon" />
            )}
          </div>
          <p className={styles.atrDescription}>{atrDescription}</p>
          <h6 className={styles.fundsSubheading}>{'The funds we’ve selected'}</h6>
          <p className={styles.atrDescription}>{'This pension plan will be invested in the following funds which we’ve selected from the whole of the market:'}</p>
          <div className={styles.fundListContainer}>
            {funds.map(({ fundLabel, fundRoundedSplit }) => (
              <div className={styles.fundRow} key={fundLabel}>
                <div className={styles.fundLabel}>{fundLabel}</div>
                <div className={styles.fundSplit}>{`${fundRoundedSplit}%`}</div>
              </div>
            ))}
          </div>
          <h6 className={styles.platformSubheading}>{'The platform we’ve selected'}</h6>
          <div className={styles.platformLogoContainer}>
            <img className={styles.platformLogo} src={logo} alt={`${label} logo`} />
            <div>{label}</div>
          </div>
          <p className={styles.platformText}>{'A global provider of pensions with over £800 billion of assets'}</p>
          <h6 className={styles.feeSubheading}>{'One all-inclusive competitive fee'}</h6>
          <div className={styles.feeRow}>
            <div className={styles.bulletContainer}>
              <div className={styles.bulletCircle} />
            </div>
            <div className={styles.feeText}>
              {'Competitive all-inclusive annual fee '}
              <b>{'with ongoing advice'}</b>
              {` of ${annualCharge}%`}
            </div>
          </div>
          <div className={styles.feeRow}>
            <div className={styles.bulletContainer}>
              <div className={styles.bulletCircle} />
            </div>
            <div className={styles.feeText}>{'No transfer fees for moving your old pensions to this plan'}</div>
          </div>
          <WithInvestmentAdviceLetter
            section="plan-summary"
          >
            {(investmentAdviceLetterUrl) => (
              investmentAdviceLetterUrl ? (
                <div
                  className={styles.documentContainer}
                >
                  <img
                    src={documents}
                    alt="Documents"
                    className={styles.documents}
                  />
                  <a
                    target="_blank"
                    href={investmentAdviceLetterUrl}
                    rel="noreferrer"
                    onClick={dispatchReportFromRecommendationDetailClickedAction}
                  >
                    {'Download your detailed pension plan'}
                  </a>
                </div>
              ) : (
                <p className={styles.loadingText}>{'Loading recommendation documents, please wait...'}</p>
              )
            )}
          </WithInvestmentAdviceLetter>
          <h3 className={styles.compareSubheading}>{'How we compare'}</h3>
          <div>
            <div className={styles.comparisonGridContainer}>
              <div className={styles.comparisonGrid}>
                <div className={styles.gridSpacer} />
                <div className={styles.greenTopGridSpacer} />
                <div className={styles.gridSpacer} />
                <div className={styles.gridSpacer} />
                <div className={styles.headerRowHeader} />
                <div className={styles.profilePensionsHeader}>
                  <img className={styles.ppLogo} src={pplogo} alt="Profile Pensions logo" />
                </div>
                <div className={styles.otherCompaniesHeader}>
                  <div className={styles.headerBody}>{'Other pension companies'}</div>
                </div>
                <div className={styles.independentHeader}>
                  <div className={styles.headerBody}>{'Independent Financial Adviser (IFA)'}</div>
                </div>
                <div className={styles.subheaderRowHeader} />
                <div className={styles.profilePensionsSubheader}>
                  <div className={styles.ppHeaderText}>{'Your recommended pension plan'}</div>
                </div>
                <div className={styles.otherCompaniesSubheader}>
                  <div className={styles.otherHeaderText}>{'eg. Standard Life, Scottish Widows, Pension Bee'}</div>
                </div>
                <div className={styles.independentSubheader}>
                  <div className={styles.otherHeaderText}>{'eg. St. James’s Place, Quilter'}</div>
                </div>
                <div className={styles.rowHeader}>
                  {'Personalised plan'}
                </div>
                <div className={styles.profileColumnCell}>
                  <img className={styles.greenTick} src={limeTick} alt="Green tick" />
                </div>
                <div className={styles.otherColumnCell}>
                  <img className={styles.greyCross} src={greyCross} alt="Grey cross" />
                </div>
                <div className={styles.indepColumnCell}>
                  <img className={styles.greenTick} src={limeTick} alt="Green tick" />
                </div>
                <div className={styles.rowHeader}>
                  {'Ongoing advice'}
                </div>
                <div className={styles.profileColumnCell}>
                  <img className={styles.greenTick} src={limeTick} alt="Green tick" />
                </div>
                <div className={styles.otherColumnCell}>
                  <img className={styles.greyCross} src={greyCross} alt="Grey cross" />
                </div>
                <div className={styles.indepColumnCell}>
                  <img className={styles.greenTick} src={limeTick} alt="Green tick" />
                </div>
                <div className={styles.rowHeader}>
                  {'Whole-of-market fund coverage'}
                </div>
                <div className={styles.profileColumnCell}>
                  <img className={styles.greenTick} src={limeTick} alt="Green tick" />
                </div>
                <div className={styles.otherColumnCell}>
                  <img className={styles.greyCross} src={greyCross} alt="Grey cross" />
                </div>
                <div className={styles.indepColumnCell}>
                  {'Some'}
                </div>

                <div className={styles.rowHeader}>
                  {'Find and consolidate old pensions'}
                </div>
                <div className={styles.profileColumnCell}>
                  <img className={styles.greenTick} src={limeTick} alt="Green tick" />
                </div>
                <div className={styles.otherColumnCell}>
                  {'Some'}
                </div>
                <div className={styles.indepColumnCell}>
                  <img className={styles.greyCross} src={greyCross} alt="Grey cross" />
                </div>

                <div className={styles.rowHeader}>
                  {'No minimum investment'}
                </div>
                <div className={styles.profileColumnCell}>
                  <img className={styles.greenTick} src={limeTick} alt="Green tick" />
                </div>
                <div className={styles.otherColumnCell}>
                  <img className={styles.greenTick} src={limeTick} alt="Green tick" />
                </div>
                <div className={styles.indepColumnCell}>
                  <img className={styles.greyCross} src={greyCross} alt="Grey cross" />
                </div>

                <div className={styles.rowHeaderBottom}>
                  {'Typical total annual cost'}
                </div>
                <div className={styles.profileColumnCellBottom}>
                  {`${annualCharge}%`}
                </div>
                <div className={styles.otherColumnCellBottom}>
                  {'0.50% - 1.10%'}
                </div>
                <div className={styles.indepColumnCellBottom}>
                  {'1.25% - 2.00%'}
                </div>
                <div className={styles.gridSpacer} />
                <div className={styles.greenBottomGridSpacer} />
                <div className={styles.gridSpacer} />
                <div className={styles.gridSpacer} />
              </div>
            </div>
          </div>
          <h3 className={styles.compareSubheading}>{'What makes us different'}</h3>
          <div className={styles.uspGrid}>
            <div className={styles.uspRow}>
              <img src={graph} alt="Graph" />
              <div className={styles.uspText}>{'Best recommended fund on the market'}</div>
            </div>
            <div className={styles.uspRow}>
              <img src={moneyShield} alt="Currency sign on a shield" />
              <div className={styles.uspText}>{'No minimum investment amount'}</div>
            </div>
            <div className={styles.uspRow}>
              <img src={horizontalOverlappingCircles} alt="Horizontal overlapping circles" />
              <div className={styles.uspText}>{'Ongoing advice'}</div>
            </div>
            <div className={styles.uspRow}>
              <img src={chatPerson} alt="Person with speech bubble" />
              <div className={styles.uspText}>{'Dedicated Pension Adviser always at hand'}</div>
            </div>
          </div>
        </div>
        <div className={styles.trustpilotRow}>
          <a
            className={styles.trustpilotCard}
            href="https://uk.trustpilot.com/review/www.profilepensions.co.uk"
            target="_blank"
            rel="nofollow noreferrer noopener"
          >
            <div className={styles.trustpilotCardInner}>
              <div className={styles.trustpilotLine}>
                <div className={styles.totalReviews}>
                  {'Check out our '}
                  <b>{`${totalReviews && totalReviews.toLocaleString()} `}</b>
                  {'reviews'}
                </div>
                <div className={styles.trustpilotStars}>
                  <TrustpilotStars stars={stars} trustScore={trustScore} />
                </div>
              </div>
              <div className={styles.trustpilotLogoContainer}>
                <img src={trustpilotLogo} className={styles.trustpilotLogo} alt="Trustpilot logo" />
              </div>
            </div>
          </a>
          <div className={styles.fcssCard}><img src={fcss} alt="FCSS logo" /></div>
        </div>
        {wasSerpsTraceRequested ? (
          <SERPSNextSteps
            showPensionAdvisorContent={false}
            showReadYourPlanLink={false}
          />
        ) : (
          <div>
            <h3 className={styles.selectOptionSubheading}>
              {'Select an option to open your pension plan'}
            </h3>
            <div
              className={
                classNames(
                  styles.actionsContainer,
                  {
                    [styles.singleColumn]: hasPensionOrInstruction,
                  },
                )
              }
            >
              <AddPensionCard
                onClick={dispatchAddPensionFromRecommendationDetailClicked}
              />
              <AddMoneyCard
                onClick={dispatchAddMoneyFromRecommendationDetailClicked}
              />
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

const mapDispatchToProps = {
  pageLoaded: addPensionThankYouPageView,
  dispatchSetForceMentionMeModal: setForceMentionMeModalAction,
  dispatchReportFromRecommendationDetailClickedAction: reportFromRecommendationDetailClickedAction,
  dispatchAddMoneyFromRecommendationDetailClicked: addMoneyFromRecommendationDetailClickedAction,
  dispatchAddPensionFromRecommendationDetailClicked:
    addPensionFromRecommendationDetailClickedAction,
  dispatchRecommendationDetailPageLoaded: recommendationDetailPageLoadedAction,
  dispatchPlanSummaryPageVisitedAction: planSummaryPageVisitedStatus,
};

const mapStateToProps = (state) => ({
  targetInvestment: getTargetInvestment(state),
  trustpilot: state.trustpilot,
  pensionExpert: getPensionExpert(state),
  ivuCollection: getIVUCollection(state),
  pensions: getPensions(state),
  hasInProgressInstructions: getHasInProgressContributionInstruction(state),
  isUserReferredFromMoneySupermarket: getUserReferredFromMoneySupermarket(state),
  visitedPlanSummaryPage: getVisitedPlanSummaryPage(state),
  wasSerpsTraceRequested: getWasSerpsTraceRequested(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanSummaryPage);
