exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);

// module
exports.push([module.id, ".-FooterDisclaimer-emphasis-5xNcX {\n  font-family: \"Lato\";\n  font-weight: 700;\n}\n.-FooterDisclaimer-disclaimer-3rvva {\n  font-family: \"Lato\";\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 29px;\n  margin: 0;\n}\n.-FooterDisclaimer-wrapper-3Py72 {\n  grid-row: 6;\n  grid-column: 1 / span 6;\n  margin-top: 32px;\n  width: 100%;\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n  .-FooterDisclaimer-wrapper-3Py72 {\n    grid-row: 4;\n    grid-column: 1 / span 10;\n    margin-top: 32px;\n  }\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["largeDesktop"] + ") {\n  .-FooterDisclaimer-wrapper-3Py72 {\n    grid-row: 3;\n    grid-column: 4 / span 9;\n    margin-top: 32px;\n  }\n}", ""]);

// exports
exports.locals = {
	"dimensions": "\"../../theme/dimensions.css\"",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"largeDesktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["largeDesktop"] + "",
	"emphasis": "-FooterDisclaimer-emphasis-5xNcX",
	"disclaimer": "-FooterDisclaimer-disclaimer-3rvva",
	"wrapper": "-FooterDisclaimer-wrapper-3Py72"
};