import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';

import JourneyLandingPages from './JourneyLandingPages';
import {
  getFirebaseUid,
  getIsVerified,
  getPostCdsAnimationLoading,
} from '../../redux/selectors';
import WithCollections, { COLLECTIONS } from '../../components/WithCollections/WithCollections';
import { JOURNEYS } from '../../util/constants';
import {
  getIsOngoingCdsAllowed,
  getIsAuthoriseChangeAllowed,
  getIsInvestmentRecommendationAllowed,
  getIsVulnerableCustomerAllowed,
  getIsFindPensionsAllowed,
  getIsVerifiedJourneyAllowed,
  getIsAddPensionsAllowed,
  getIsNotAUkTaxResident,
  getIsSetupContributionAllowed,
  getIsSetupLumpSumAllowed,
  getIsMissingDetailsAllowed,
  getIsWithdrawalAllowed,
  getIsWithdrawalPagesAllowed,
  getIsPlanSummaryAllowed,
  getIsRetirementIncomeProjectionAllowed,
  getIsPlanActivityAllowed,
  getIsSerpsAuthAllowed,
  getIsSerpsMissingDetailsAllowed,
} from '../../redux/selectors/journeys';
import {
  sendGaCustomEvent,
  gaCategories,
  gaLabels,
  gaActions,
} from '../../util/googleAnalyticHelpers';
import { dispatchDashboardPageFromCds as dashboardPageFromCdsAction } from '../../redux/modules/investmentAdvice';
import { getUserReferredFromMoneySupermarket, getIsCustomerOngoing } from '../../redux/selectors/experience';

const journeySelectorMap = {
  [JOURNEYS.CDS]: () => true,
  [JOURNEYS.DASHBOARD]: () => true,
  [JOURNEYS.ONGOING_CDS]: getIsOngoingCdsAllowed,
  [JOURNEYS.AUTHORISE_CHANGE]: getIsAuthoriseChangeAllowed,
  [JOURNEYS.ADD_PENSIONS]: getIsAddPensionsAllowed,
  [JOURNEYS.FIND_PENSIONS]: getIsFindPensionsAllowed,
  [JOURNEYS.PERSONAL_DETAILS]: () => true,
  [JOURNEYS.VULNERABLE_CUSTOMER]: getIsVulnerableCustomerAllowed,
  [JOURNEYS.VERIFY]: getIsVerifiedJourneyAllowed,
  [JOURNEYS.SETUP_CONTRIBUTION]: getIsSetupContributionAllowed,
  [JOURNEYS.SETUP_LUMP_SUM]: getIsSetupLumpSumAllowed,
  [JOURNEYS.MISSING_DETAILS]: getIsMissingDetailsAllowed,
  [JOURNEYS.SERPS_MISSING_DETAILS]: () => true,
  [JOURNEYS.SETUP_WITHDRAWAL_MONTHLY]: getIsWithdrawalPagesAllowed,
  [JOURNEYS.SETUP_WITHDRAWAL_LUMP_SUM]: getIsWithdrawalPagesAllowed,
  [JOURNEYS.WITHDRAWALS]: getIsWithdrawalAllowed,
  [JOURNEYS.WITHDRAWAL_LUMP_SUM_OVER_LIMIT]: getIsWithdrawalAllowed,
  [JOURNEYS.PLAN_SUMMARY]: getIsPlanSummaryAllowed,
  [JOURNEYS.PLAN_ACTIVITY]: getIsPlanActivityAllowed,
  [JOURNEYS.MARKETING_PREFERENCES]: () => true,
  [JOURNEYS.ADD_MONEY_OPTION]: getIsAddPensionsAllowed,
  [JOURNEYS.RETIREMENT_INCOME_PROJECTION]: getIsRetirementIncomeProjectionAllowed,
  [JOURNEYS.WITHDRAWALS_HUB]: getIsWithdrawalAllowed,
  [JOURNEYS.WITHDRAWAL_MINI_JOURNEY]: getIsWithdrawalAllowed,
};

const JourneyRouter = (journeyFromPath) => {
  class JourneyRouterComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    pageBody = (currentJourney, journeyPath, componentProps) => {
      const { isUserReferredFromMoneySupermarket, isCustomerOngoing } = this.props;

      if (currentJourney === journeyPath) {
        const Body = JourneyLandingPages[currentJourney];
        return <Body {...componentProps} />;
      }

      const journeyDashboardRedirectPath = !isCustomerOngoing && isUserReferredFromMoneySupermarket ? '/plan-summary' : '/';

      return (
        <Fragment>
          {currentJourney === JOURNEYS.ONGOING_CDS && <Redirect to="/your-latest-information" />}
          {currentJourney === JOURNEYS.VULNERABLE_CUSTOMER && <Redirect to="/we-need-to-speak-to-you" />}
          {currentJourney === JOURNEYS.VERIFY && <Redirect to="/verification" />}
          {currentJourney === JOURNEYS.DASHBOARD && <Redirect to={journeyDashboardRedirectPath} />}
        </Fragment>
      );
    }

    getCurrentJourney = () => {
      const {
        isCurrentJourneyAllowed,
        isUserEmailVerified,
        isVulnerableCustomerAllowed,
        isInvestmentRecommendationAllowed,
        dispatchDashboardPageFromCds,
        isNotAUkTaxResident,
        isOngoingCdsAllowed,
        isPostCdsAnimationLoading,
      } = this.props;
      if (!isUserEmailVerified) {
        return JOURNEYS.VERIFY;
      }

      if (
        (journeyFromPath === JOURNEYS.CDS || journeyFromPath === JOURNEYS.ONGOING_CDS)
        && isVulnerableCustomerAllowed && !isOngoingCdsAllowed && !isPostCdsAnimationLoading
      ) {
        return JOURNEYS.VULNERABLE_CUSTOMER;
      }

      if (journeyFromPath === JOURNEYS.CDS
        && isInvestmentRecommendationAllowed
        && !isPostCdsAnimationLoading
      ) {
        dispatchDashboardPageFromCds();
        sendGaCustomEvent({
          category: gaCategories.acquisition,
          label: gaLabels.generateAdvice,
          action: gaActions.adviceGenerated,
        });

        return JOURNEYS.DASHBOARD;
      }
      if (journeyFromPath === JOURNEYS.CDS && isNotAUkTaxResident && !isPostCdsAnimationLoading) {
        return JOURNEYS.DASHBOARD;
      }

      if (isCurrentJourneyAllowed) {
        return journeyFromPath;
      }

      return JOURNEYS.DASHBOARD;
    };

    render() {
      const { componentProps } = this.props;
      const currentJourney = this.getCurrentJourney();

      return (
        <Fragment>
          {this.pageBody(currentJourney, journeyFromPath, componentProps)}
        </Fragment>
      );
    }
  }

  JourneyRouterComponent.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    uid: PropTypes.string.isRequired,
    isCurrentJourneyAllowed: PropTypes.bool.isRequired,
  };

  const mapStateToProps = (state) => ({
    uid: getFirebaseUid(state),
    isCurrentJourneyAllowed: journeySelectorMap[journeyFromPath](state),
    isInvestmentRecommendationAllowed: getIsInvestmentRecommendationAllowed(state),
    isVulnerableCustomerAllowed: getIsVulnerableCustomerAllowed(state),
    isUserEmailVerified: getIsVerified(state),
    isNotAUkTaxResident: getIsNotAUkTaxResident(state),
    isOngoingCdsAllowed: getIsOngoingCdsAllowed(state),
    isPostCdsAnimationLoading: getPostCdsAnimationLoading(state),
    isUserReferredFromMoneySupermarket: getUserReferredFromMoneySupermarket(state),
    isCustomerOngoing: getIsCustomerOngoing(state),
  });

  const mapDispatchToProps = {
    dispatchDashboardPageFromCds: dashboardPageFromCdsAction,
  };

  const JourneyRouterWithCollections = (props) => (
    <WithCollections
      dependencies={[
        COLLECTIONS.USER,
        COLLECTIONS.ONBOARDING,
        COLLECTIONS.IVU,
        COLLECTIONS.RETIREMENTPLANS,
        COLLECTIONS.CDS,
        COLLECTIONS.SIGNUP,
        COLLECTIONS.CONVERSATIONS,
        COLLECTIONS.NOTIFICATIONS,
        COLLECTIONS.CACHED_RETIREMENT_INCOME_PLAN_VALUES,
      ]}
      context="journey-router"
    >
      <JourneyRouterComponent {...props} />
    </WithCollections>
  );

  return compose(
    connect(mapStateToProps, mapDispatchToProps),
  )(JourneyRouterWithCollections);
};

export default JourneyRouter;
