import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PensionRows from './PensionRows';
import styles from './ListPensions.css';
import { getPensions } from '../../redux/selectors/pensions';

function ListPensions({
  pensions = [],
  showHeading,
}) {
  const hasPensions = Boolean(pensions.length);
  return (
    <React.Fragment>
      {
        hasPensions && (
          <div>
            {showHeading && <h4 className={styles.heading}>{'Pensions'}</h4>}
            <PensionRows />
          </div>
        )
      }
    </React.Fragment>
  );
}

ListPensions.propTypes = {
  pensions: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    policyName: PropTypes.string.isRequired,
    mappedProviderContact: PropTypes.string.isRequired,
    authorityToTransfer: PropTypes.bool,
    dueDiligence: PropTypes.shape({
      vetting: PropTypes.shape({
        overallProceed: PropTypes.bool.isRequired,
      }),
      authority: PropTypes.shape({
        overallProceed: PropTypes.bool.isRequired,
      }),
    }).isRequired,
    blockingReasons: PropTypes.shape({
      providerNotRecognised: PropTypes.shape({
        value: PropTypes.bool.isRequired,
      }),
    }),
    suspendedFunds: PropTypes.shape({
      value: PropTypes.bool,
    }),
  })),
};

ListPensions.defaultProps = {
  pensions: [],
  customerDueDiligence: {},
  customerClosureReasons: {},
  overallStatus: '',
  disabled: false,
  showHeading: true,
};

const mapStateToProps = (state) => ({
  pensions: getPensions(state),
});

export default connect(mapStateToProps)(ListPensions);
