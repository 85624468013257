import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';

import { signatureClearButtonClicked } from '../../redux/modules/serps';
import Button from '../Button/Button';
import FieldLabel from '../FieldLabel/FieldLabel';
import Paper from '../Paper/Paper';
import Cross from '../../assets/images/signature_cross.svg';

import styles from './SignaturePad.css';

class SignaturePad extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  signatureChanged = () => {
    const { onChange } = this.props;
    onChange(this.canvas.getTrimmedCanvas());
  };

  clearSignaturePad = () => {
    const { onChange, dispatchSignatureClearButtonClicked } = this.props;

    this.canvas.clear();
    onChange(null);
    dispatchSignatureClearButtonClicked();
  }

  render() {
    const {
      canvasWidth,
      canvasHeight,
      label,
      privacyPolicyComponent,
      largeHeadings,
      dataSentry,
    } = this.props;

    return (
      <div className={styles.wrapper} style={{ maxWidth: canvasWidth }} data-sentry={dataSentry}>
        <FieldLabel
          label={label}
          largeHeadings={largeHeadings}
        />
        {privacyPolicyComponent}
        <Paper
          wrapperStyles={{
            maxWidth: canvasWidth,
            position: 'relative',
            margin: '0',
          }}
          noPadding
        >
          <SignatureCanvas
            ref={(attribute) => {
              this.canvas = attribute;
            }}
            onEnd={this.signatureChanged}
            canvasProps={{
              width: canvasWidth,
              height: canvasHeight,
            }}
          />
          <div className={styles.signatureLine}><img className={styles.crossIcon} src={Cross} alt="X" /></div>
        </Paper>
        <Button
          variant="secondary"
          label="Clear"
          onClick={this.clearSignaturePad}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchSignatureClearButtonClicked: () => dispatch(signatureClearButtonClicked()),
});

export default connect(null, mapDispatchToProps)(SignaturePad);

SignaturePad.propTypes = {
  onChange: PropTypes.func.isRequired,
  canvasWidth: PropTypes.number,
  privacyPolicyComponent: PropTypes.element,
};

SignaturePad.defaultProps = {
  canvasWidth: 335,
  privacyPolicyComponent: <React.Fragment />,
};
