import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Button from '../../components/Button/Button';
import Image from '../../assets/images/transfer-thank-you-add-money-bg.svg';
import styles from './OngoingThankYouPage.css';
import BasePage from '../../components/BasePage/BasePage';

import { setForceMentionMeModalAction } from '../../redux/modules/investmentAdvice';

const OngoingThankYou = ({ dispatchSetForceMentionMeModal }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <BasePage
      messageWidget
    >
      <section className={styles.wrapper}>
        <div className={styles.container}>
          <img className={styles.heroImage} src={Image} alt={'Thank you.'} />
          <h1 className={styles.header}>
            {'Thanks for accepting our recommendation. Want to transfer any old pensions?'}
          </h1>
          <p className={styles.body}>
            {'It’s easy to add additional pensions to your pension plan including any old pensions you ask us to find for you'}
          </p>
          <div className={styles.buttonContainer}>
            <Button size="large" label={'+ Transfer another pension'} to="/add-pensions" />
            <ButtonLink onClick={dispatchSetForceMentionMeModal} wrapperStyles={{ height: 'auto' }} mid secondary label="← Thanks I'm done" to="/" />
          </div>
        </div>
      </section>
    </BasePage>
  );
};

const mapDispatchToProps = ({
  dispatchSetForceMentionMeModal: setForceMentionMeModalAction,
});

export default connect(null, mapDispatchToProps)(OngoingThankYou);
