exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-LegacyPlanModal-closeWrapper-1yWwM {\n  width: 100%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  cursor: pointer;\n}\n.-LegacyPlanModal-closeWrapperText-1bKMc {\n  margin-left: 4px;\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["accentBaseSecondaryRegular"] + ";\n}\n.-LegacyPlanModal-heading-3Dmdi {\n}\n.-LegacyPlanModal-body-YOr_U {\n}\n.-LegacyPlanModal-listItem-3Lino:last-child {\n  margin: 0;\n}\n.-LegacyPlanModal-listItemLink-2rQiM {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textParagraphRegular"] + ";\n  margin: 0;\n}\n.-LegacyPlanModal-subHeadingTitle-3JkYB {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["thickFog"] + ";\n  margin: 0;\n}\n.-LegacyPlanModal-subHeadingContent-3yuDl {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + ";\n  margin: 0 0 12px 0;\n}", ""]);

// exports
exports.locals = {
	"typography": "\"../../theme/typography.css\"",
	"h3": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h3"] + "",
	"subtext": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["subtext"] + "",
	"bodyOne": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"colors": "\"../../theme/colors.css\"",
	"accentBaseSecondaryRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["accentBaseSecondaryRegular"] + "",
	"textParagraphRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textParagraphRegular"] + "",
	"textTitleRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + "",
	"thickFog": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["thickFog"] + "",
	"closeWrapper": "-LegacyPlanModal-closeWrapper-1yWwM",
	"closeWrapperText": "-LegacyPlanModal-closeWrapperText-1bKMc " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["subtext"] + "",
	"heading": "-LegacyPlanModal-heading-3Dmdi " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h3"] + "",
	"body": "-LegacyPlanModal-body-YOr_U " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"listItem": "-LegacyPlanModal-listItem-3Lino",
	"listItemLink": "-LegacyPlanModal-listItemLink-2rQiM " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"subHeadingTitle": "-LegacyPlanModal-subHeadingTitle-3JkYB " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"subHeadingContent": "-LegacyPlanModal-subHeadingContent-3yuDl " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + ""
};