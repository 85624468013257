exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!./dimensions.css"), undefined);

// module
exports.push([module.id, ".-grid-gridLayout-3iNWb {\n  display: grid;\n  grid-template-columns: repeat(6, 1fr);\n  grid-template-rows: auto;\n  grid-column-gap: 0.5rem;\n  justify-items: start;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  padding: 0 10px;\n}\n@media only screen and (min-width: " + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!./dimensions.css").locals["mobile"] + ") {\n  .-grid-gridLayout-3iNWb {\n    padding: 0 20px;\n    grid-template-columns: repeat(6, 1fr);\n    grid-column-gap: 1rem;\n  }\n}\n@media only screen and (min-width: " + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!./dimensions.css").locals["tablet"] + ") {\n  .-grid-gridLayout-3iNWb {\n    padding: 0 30px;\n    margin: 0 auto;\n    grid-template-columns: repeat(10, 1fr);\n    grid-column-gap: 1.5rem;\n  }\n}\n@media only screen and (min-width: " + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!./dimensions.css").locals["largeDesktop"] + ") {\n  .-grid-gridLayout-3iNWb {\n    max-width: 1440px;\n    padding: 0 80px;\n    margin: 0 auto;\n    grid-template-columns: repeat(12, 1fr);\n    grid-column-gap: 2rem;\n  }\n}", ""]);

// exports
exports.locals = {
	"dimensions": "\"./dimensions.css\"",
	"mobile": "" + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!./dimensions.css").locals["mobile"] + "",
	"tablet": "" + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!./dimensions.css").locals["tablet"] + "",
	"largeDesktop": "" + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!./dimensions.css").locals["largeDesktop"] + "",
	"gridLayout": "-grid-gridLayout-3iNWb"
};