/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import RadioOptions from '../../components/RadioOptions/RadioOptions';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import FAQ from '../../components/FAQ/FAQ';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import { getPensionExpert } from '../../redux/selectors/investmentAdvice';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import {
  contributionInflationStepVisitedAction,
  ongoingContributionInflationStepVisitedAction,
  returnLinkClickedAction,
  ongoingReturnLinkClickedAction,
  indexedWithInflationChangedAction,
  ongoingIndexedWithInflationChangedAction,
  nextClickedAction,
  ongoingNextClickedAction,
  confirmClickedAction,
  ongoingConfirmClickedAction,
  contributionFaqWhatIsInflationViewedAction,
  ongoingContributionFaqWhatIsInflationViewedAction,
  contributionFaqWhenWouldMyPensionIncreaseIfISelectYesViewedAction,
  ongoingContributionFaqWhenWouldMyPensionIncreaseIfISelectYesViewedAction,
  contributionFaqCanIAmendMyDecisionViewedAction,
  ongoingContributionFaqCanIAmendMyDecisionViewedAction,
} from '../../redux/modules/contributions';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';

import styles from './SetupContributionPage.css';

const ContributionDetailsStep = ({
  setFormData,
  formData,
  setStep,
  step,
  amendMode,
  totalSteps,
  dispatchContributionInflationStepVisited,
  dispatchReturnLinkClicked,
  dispatchIndexedWithInflationChanged,
  dispatchNextClicked,
  dispatchConfirmClicked,
  dispatchWhatIsInflationViewed,
  dispatchWhenWouldMyPensionIncreaseIfISelectYesViewed,
  dispatchCanIAmendMyDecisionViewed,
}) => {
  useEffect(() => {
    dispatchContributionInflationStepVisited();
    window.scrollTo(0, 0);
  }, []);

  const FAQ_QUESTIONS = [
    {
      question: 'What is inflation?',
      answers: [
        'Inflation is the increase in price of services and goods, like energy bills or your food shopping. It’s measured regularly (RPI- Retail Prices Index) and is important to factor it in with your pension, as inflation can affect your purchasing power in retirement.',
        'Increasing your contribution with inflation ensures you are contributing a consistent value over time.',
      ],
      action: dispatchWhatIsInflationViewed,
    },
    {
      question: 'When would my pension increase if I select yes?',
      answers: [
        'Pension inflationary increases happen every year (on the anniversary) from the date the contribution was set up.',
        'If the inflationary increase is selected it will be automatically applied.',
      ],
      action: dispatchWhenWouldMyPensionIncreaseIfISelectYesViewed,
    },
    {
      question: 'Can I amend my decision?',
      answers: [
        'Inflationary increases on your contributions can be turned on and off at any time by messaging your Pension Adviser.',
        'If you would like to set a fixed amount, you will need to specify the fixed amount and have inflationary increases turned off. You won’t be able to specify your own annual increase %',
      ],
      action: dispatchCanIAmendMyDecisionViewed,
    },
  ];

  return (
    <BaseGrid pageSectionStyles={{ marginBottom: '200px' }} addPensionsTheme>
      <div className={styles.contributionsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          onClick={() => {
            dispatchReturnLinkClicked();
            setStep(step - 1);
          }}
          label="← Back"
          wrapperStyles={{ margin: '0 0 30px' }}
        />
        <h2 className={styles.contributionsHeading}>{'Would you like to increase your contribution each year in line with inflation?'}</h2>
        <div className={styles.radioButtonsContainer}>
          <RadioOptions
            optionOneClickHandler={() => {
              dispatchIndexedWithInflationChanged();
              setFormData({ ...formData, indexedWithInflation: true });
            }}
            isOptionOneSelected={formData.indexedWithInflation === true}
            optionTwoClickHandler={() => {
              dispatchIndexedWithInflationChanged();
              setFormData({ ...formData, indexedWithInflation: false });
            }}
            isOptionTwoSelected={formData.indexedWithInflation === false}
          />
        </div>
        <div className={styles.standardContainer}>
          <Button
            size="large"
            disabled={typeof formData.indexedWithInflation !== 'boolean'}
            label={amendMode ? 'Confirm' : 'Next'}
            onClick={() => {
              if (amendMode) {
                dispatchConfirmClicked();
                setStep(4);
              } else {
                dispatchNextClicked();
                setStep(step + 1);
              }
            }}
          />
        </div>
        <FAQ questions={FAQ_QUESTIONS} />
      </div>
      <div className={styles.pensionAdviserContainer}>
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'ContributionAmountStep'}
            noExpertContentWrapper={({ children }) => (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexShrink: 'initial',
                padding: '10px',
                flexWrap: 'wrap',
              }}
              >
                {children}
              </div>
            )}
            showButton={false}
            customMessage={(
              <div className={styles.contactUsInnerContent}>
                {'If you have any questions, please feel free to '}
                <ButtonLink
                  label="message me"
                  variant="primary"
                  to="/inbox/new-message"
                  font="inherit"
                />
                {'.'}
              </div>
            )}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  pensionAdviser: getPensionExpert(state),
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  _dispatchOnboardingContributionInflationStepVisited: contributionInflationStepVisitedAction,
  _dispatchOngoingContributionInflationStepVisited: ongoingContributionInflationStepVisitedAction,
  _dispatchOnboardingReturnLinkClicked: returnLinkClickedAction,
  _dispatchOngoingReturnLinkClicked: ongoingReturnLinkClickedAction,
  _dispatchOnboardingIndexedWithInflationChanged: indexedWithInflationChangedAction,
  _dispatchOngoingIndexedWithInflationChanged: ongoingIndexedWithInflationChangedAction,
  _dispatchOnboardingNextClicked: nextClickedAction,
  _dispatchOngoingNextClicked: ongoingNextClickedAction,
  _dispatchOnboardingConfirmClicked: confirmClickedAction,
  _dispatchOngoingConfirmClicked: ongoingConfirmClickedAction,
  _dispatchWhatIsInflationViewed: contributionFaqWhatIsInflationViewedAction,
  _dispatchOngoingWhatIsInflationViewed: ongoingContributionFaqWhatIsInflationViewedAction,
  _dispatchWhenWouldMyPensionIncreaseIfISelectYesViewed: contributionFaqWhenWouldMyPensionIncreaseIfISelectYesViewedAction,
  _dispatchOngoingWhenWouldMyPensionIncreaseIfISelectYesViewed: ongoingContributionFaqWhenWouldMyPensionIncreaseIfISelectYesViewedAction,
  _dispatchCanIAmendMyDecisionViewed: contributionFaqCanIAmendMyDecisionViewedAction,
  _dispatchOngoingCanIAmendMyDecisionViewed: ongoingContributionFaqCanIAmendMyDecisionViewedAction,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isCustomerOngoing } = stateProps;
  const {
    _dispatchOnboardingContributionInflationStepVisited,
    _dispatchOngoingContributionInflationStepVisited,
    _dispatchOnboardingReturnLinkClicked,
    _dispatchOngoingReturnLinkClicked,
    _dispatchOnboardingIndexedWithInflationChanged,
    _dispatchOngoingIndexedWithInflationChanged,
    _dispatchOnboardingOtherSourceOfFundsChanged,
    _dispatchOngoingOtherSourceOfFundsChanged,
    _dispatchOnboardingNextClicked,
    _dispatchOngoingNextClicked,
    _dispatchOnboardingConfirmClicked,
    _dispatchOngoingConfirmClicked,
    _dispatchWhatIsInflationViewed,
    _dispatchOngoingWhatIsInflationViewed,
    _dispatchWhenWouldMyPensionIncreaseIfISelectYesViewed,
    _dispatchOngoingWhenWouldMyPensionIncreaseIfISelectYesViewed,
    _dispatchCanIAmendMyDecisionViewed,
    _dispatchOngoingCanIAmendMyDecisionViewed,
  } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    dispatchContributionInflationStepVisited: isCustomerOngoing ? _dispatchOngoingContributionInflationStepVisited : _dispatchOnboardingContributionInflationStepVisited,
    dispatchReturnLinkClicked: isCustomerOngoing ? _dispatchOngoingReturnLinkClicked : _dispatchOnboardingReturnLinkClicked,
    dispatchIndexedWithInflationChanged: isCustomerOngoing ? _dispatchOngoingIndexedWithInflationChanged : _dispatchOnboardingIndexedWithInflationChanged,
    dispatchOtherSourceOfFundsChanged: isCustomerOngoing ? _dispatchOngoingOtherSourceOfFundsChanged : _dispatchOnboardingOtherSourceOfFundsChanged,
    dispatchNextClicked: isCustomerOngoing ? _dispatchOngoingNextClicked : _dispatchOnboardingNextClicked,
    dispatchConfirmClicked: isCustomerOngoing ? _dispatchOngoingConfirmClicked : _dispatchOnboardingConfirmClicked,
    dispatchWhatIsInflationViewed: isCustomerOngoing ? _dispatchOngoingWhatIsInflationViewed : _dispatchWhatIsInflationViewed,
    dispatchWhenWouldMyPensionIncreaseIfISelectYesViewed: isCustomerOngoing ? _dispatchOngoingWhenWouldMyPensionIncreaseIfISelectYesViewed : _dispatchWhenWouldMyPensionIncreaseIfISelectYesViewed,
    dispatchCanIAmendMyDecisionViewed: isCustomerOngoing ? _dispatchOngoingCanIAmendMyDecisionViewed : _dispatchCanIAmendMyDecisionViewed,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ContributionDetailsStep);
