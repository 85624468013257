import get from 'lodash/get';
import set from 'lodash/set';

import { isNotNullOrUndefined } from '../../util/utils';

import { MINIMUM_VALID_CDS_QUESTIONS_VERSION } from '../../util/constants';

import {
  getDob,
  getGender,
  getUkResident,
  getAddress,
  getPreferredPhoneNumber,
  getHasMiddleName,
  getMiddleName,
  getHasPreviousNames,
  getPreviousNames,
  getBornInTheUk,
  getCountryOfBirth,
  getHasPreviousTaxResidencyOutsideUk,
  getCountriesOfPreviousResidence,
  getIntendedRetirementAge,
} from './index';

import { getShouldOfferSerpsTrace } from './serps';

const QUESTIONS_VERSION = 6;

export const getLatestCompleteCds = (state) => {
  const cdss = get(state, 'firestore.ordered.customerDataSets', []);
  return cdss.find((cds) => {
    const validQuestionsVersion = get(cds, 'questionsVersion', 0) >= MINIMUM_VALID_CDS_QUESTIONS_VERSION;
    return (
      cds.complete
      && !cds.earlyExit
      && validQuestionsVersion
    );
  });
};

export const getLatestIncompleteOngoingCds = (state) => {
  const cdss = get(state, 'firestore.ordered.customerDataSets', []);
  return cdss.find((cds) => {
    const validQuestionsVersion = get(cds, 'questionsVersion', 0) >= MINIMUM_VALID_CDS_QUESTIONS_VERSION;
    return cds.ongoing && !cds.complete && validQuestionsVersion;
  });
};

export const getLatestIncompleteOrCompleteInvestmentAdviceCds = (state) => {
  const latestIncompleteCds = getLatestIncompleteOngoingCds(state);

  if (latestIncompleteCds) {
    return latestIncompleteCds;
  }

  const latestCompleteCds = getLatestCompleteCds(state);
  return latestCompleteCds || null;
};

export const getLatestIncompleteInvestmentAdviceCds = (state) => {
  const cdss = get(state, 'firestore.ordered.customerDataSets', []);
  return cdss.find((cds) => {
    const validQuestionsVersion = get(cds, 'questionsVersion', 0) >= MINIMUM_VALID_CDS_QUESTIONS_VERSION;
    return (
      (typeof cds.ongoing === 'undefined' || cds.ongoing === false)
      && !cds.complete
      && validQuestionsVersion
    );
  });
};

const getCDSFlagValue = (cdsValue, pippaValue) => {
  if (isNotNullOrUndefined(cdsValue)) {
    return cdsValue;
  }
  if (isNotNullOrUndefined(pippaValue)) {
    return pippaValue;
  }
  return undefined;
};

const mapToValidCdsAddress = (address) => {
  if (get(address, 'line1')) return address;
  return {
    line1: address.street || null,
    postcode: address.postalCode || null,
    town: address.city || null,
  };
};

export const getPreviousAnswersForOnboarding = (state, cds) => {
  const serpsTrace = get(cds, 'answers.serpsTrace.value');
  const address = get(cds, 'answers.address.value') || getAddress(state);
  const ukResident = getCDSFlagValue(get(cds, 'answers.ukResident.value'), getUkResident(state));
  const contactNumber = get(cds, 'answers.contactNumber.value') || getPreferredPhoneNumber(state);
  const hasMiddleName = getCDSFlagValue(get(cds, 'answers.hasMiddleName.value'), getHasMiddleName(state));
  const middleName = get(cds, 'answers.middleName.value') || getMiddleName(state);
  const hasPreviousNames = getCDSFlagValue(get(cds, 'answers.hasPreviousNames.value'), getHasPreviousNames(state));
  const previousNames = get(cds, 'answers.previousNames.value') || getPreviousNames(state);
  const bornInTheUk = getCDSFlagValue(get(cds, 'answers.bornInTheUk.value'), getBornInTheUk(state));
  const countryOfBirth = get(cds, 'answers.countryOfBirth.value') || getCountryOfBirth(state);
  const intendedRetirementAge = get(cds, 'answers.intendedRetirementAge.value') || getIntendedRetirementAge(state);
  const hasPreviousTaxResidencyOutsideUk = getCDSFlagValue(
    get(cds, 'answers.hasPreviousTaxResidencyOutsideUk.value'),
    getHasPreviousTaxResidencyOutsideUk(state),
  );
  const countriesOfPreviousResidence = get(cds, 'answers.countriesOfPreviousResidence.value')
    || getCountriesOfPreviousResidence(state);

  const previousAnswers = cds || {
    answers: {},
    questionsVersion: QUESTIONS_VERSION,
  };

  if (serpsTrace) set(previousAnswers, 'answers.serpsTrace.value', serpsTrace);
  if (address) set(previousAnswers, 'answers.address.value', mapToValidCdsAddress(address));
  if (isNotNullOrUndefined(ukResident)) set(previousAnswers, 'answers.ukResident.value', ukResident);
  if (contactNumber) set(previousAnswers, 'answers.contactNumber.value', contactNumber);
  if (isNotNullOrUndefined(hasMiddleName)) set(previousAnswers, 'answers.hasMiddleName.value', hasMiddleName);
  if (middleName) set(previousAnswers, 'answers.middleName.value', middleName);
  if (isNotNullOrUndefined(hasPreviousNames)) set(previousAnswers, 'answers.hasPreviousNames.value', hasPreviousNames);
  if (previousNames) set(previousAnswers, 'answers.previousNames.value', previousNames);
  if (isNotNullOrUndefined(hasPreviousTaxResidencyOutsideUk)) set(previousAnswers, 'answers.hasPreviousTaxResidencyOutsideUk.value', hasPreviousTaxResidencyOutsideUk);
  if (countriesOfPreviousResidence) set(previousAnswers, 'answers.countriesOfPreviousResidence.value', countriesOfPreviousResidence);
  if (isNotNullOrUndefined(bornInTheUk)) set(previousAnswers, 'answers.bornInTheUk.value', bornInTheUk);
  if (countryOfBirth) set(previousAnswers, 'answers.countryOfBirth.value', countryOfBirth);
  if (intendedRetirementAge) set(previousAnswers, 'answers.intendedRetirementAge.value', intendedRetirementAge);
  return previousAnswers;
};

export const getQuestionsToSkipForOnboarding = (state, cds) => {
  const shouldOfferSerpsTrace = getShouldOfferSerpsTrace(state);
  const userPreferredPhoneNumber = getPreferredPhoneNumber(state);
  const cdsSkipSteps = get(cds, 'skipSteps');

  const skipSteps = [];
  if (!shouldOfferSerpsTrace) skipSteps.push('serpsTrace');
  if (cds && !cds.complete && cdsSkipSteps && cdsSkipSteps.length > 0) {
    return [...new Set([...cdsSkipSteps, ...skipSteps])];
  }
  if (userPreferredPhoneNumber) skipSteps.push('contactNumber');

  return [...new Set([...(cdsSkipSteps || []), ...skipSteps])];
};

export const getPreviousAnswersForOngoing = (state) => (latestCds) => {
  const previousAnswers = {
    answers: {},
    questionsVersion: 6,
  };

  const dob = getDob(state) || get(latestCds, 'answers.dateOfBirth.value');
  const gender = getGender(state) || get(latestCds, 'answers.gender.value');
  const address = getAddress(state) || get(latestCds, 'answers.address.value');
  const ukResident = getCDSFlagValue(getUkResident(state), get(latestCds, 'answers.ukResident.value'));
  const contactNumber = getPreferredPhoneNumber(state) || get(latestCds, 'answer.contactNumber.value');
  const hasMiddleName = getCDSFlagValue(getHasMiddleName(state), get(latestCds, 'answer.hasMiddleName.value'));
  const middleName = getMiddleName(state) || get(latestCds, 'answer.middleName.value');
  const hasPreviousNames = getCDSFlagValue(getHasPreviousNames(state), get(latestCds, 'answer.hasPreviousNames.value'));
  const previousNames = getPreviousNames(state) || get(latestCds, 'answer.previousNames.value');
  const bornInTheUk = getCDSFlagValue(getBornInTheUk(state), get(latestCds, 'answer.bornInTheUk.value'));
  const countryOfBirth = getCountryOfBirth(state) || get(latestCds, 'answer.countryOfBirth.value');
  const intendedRetirementAge = getIntendedRetirementAge(state) || get(latestCds, 'answer.intendedRetirementAge');
  const hasPreviousTaxResidencyOutsideUk = getCDSFlagValue(
    getHasPreviousTaxResidencyOutsideUk(state),
    get(latestCds, 'answer.hasPreviousTaxResidencyOutsideUk.value'),
  );
  const countriesOfPreviousResidence = getCountriesOfPreviousResidence(state) || get(latestCds, 'answer.countriesOfPreviousResidence.value');

  if (dob) set(previousAnswers, 'answers.dateOfBirth.value', dob);
  if (gender) set(previousAnswers, 'answers.gender.value', gender);
  if (address) set(previousAnswers, 'answers.address.value', mapToValidCdsAddress(address));
  if (isNotNullOrUndefined(ukResident)) set(previousAnswers, 'answers.ukResident.value', ukResident);
  if (contactNumber) set(previousAnswers, 'answers.contactNumber.value', contactNumber);
  if (isNotNullOrUndefined(hasMiddleName)) set(previousAnswers, 'answers.hasMiddleName.value', hasMiddleName);
  if (middleName) set(previousAnswers, 'answers.middleName.value', middleName);
  if (isNotNullOrUndefined(hasPreviousNames)) set(previousAnswers, 'answers.hasPreviousNames.value', hasPreviousNames);
  if (previousNames) set(previousAnswers, 'answers.previousNames.value', previousNames);
  if (isNotNullOrUndefined(hasPreviousTaxResidencyOutsideUk)) set(previousAnswers, 'answers.hasPreviousTaxResidencyOutsideUk.value', hasPreviousTaxResidencyOutsideUk);
  if (countriesOfPreviousResidence) set(previousAnswers, 'answers.countriesOfPreviousResidence.value', countriesOfPreviousResidence);
  if (isNotNullOrUndefined(bornInTheUk)) set(previousAnswers, 'answers.bornInTheUk.value', bornInTheUk);
  if (countryOfBirth) set(previousAnswers, 'answers.countryOfBirth.value', countryOfBirth);
  if (intendedRetirementAge) set(previousAnswers, 'answers.intendedRetirementAge.value', intendedRetirementAge);

  if (latestCds.answers && Object.keys(latestCds.answers).length > 0) {
    const previousAnswersKey = Object.keys(previousAnswers.answers);
    const otherAnswers = Object.keys(latestCds.answers)
      .filter((key) => !previousAnswersKey.includes(key));
    otherAnswers.map((key) => key && set(previousAnswers, `answers.${key}.value`, latestCds.answers[key].value));
  }

  return previousAnswers;
};

export const getQuestionsToSkipForOngoing = (state) => {
  const skipSteps = [];
  const shouldOfferSerpsTrace = getShouldOfferSerpsTrace(state);
  if (!shouldOfferSerpsTrace) skipSteps.push('serpsTrace');
  const userHasPreviousTaxResidencyOutsideUk = getHasPreviousTaxResidencyOutsideUk(state);
  const userCountriesOfPreviousResidence = getCountriesOfPreviousResidence(state);
  const userCountryOfBirth = getCountryOfBirth(state);
  const userBornInTheUk = getBornInTheUk(state);
  const userPreviousNames = getPreviousNames(state);
  const userHasPreviousNames = getHasPreviousNames(state);
  const middleName = getMiddleName(state);
  const userHasMiddleName = getHasMiddleName(state);
  const userPreferredPhoneNumber = getPreferredPhoneNumber(state);
  const userDob = getDob(state);
  const userGender = getGender(state);

  if (userPreferredPhoneNumber) skipSteps.push('contactNumber');
  if (userGender) skipSteps.push('gender');
  if (userDob) skipSteps.push('dateOfBirth');
  if (userHasMiddleName && middleName) skipSteps.push('hasMiddleName');
  if (userBornInTheUk && userCountryOfBirth) skipSteps.push('bornInTheUk');
  if (userHasPreviousNames && userPreviousNames && userPreviousNames.length > 0) {
    skipSteps.push('hasPreviousNames');
  }
  if (userHasPreviousTaxResidencyOutsideUk
    && userCountriesOfPreviousResidence
    && userCountriesOfPreviousResidence.length > 0) {
    skipSteps.push('hasPreviousTaxResidencyOutsideUk');
  }

  return skipSteps;
};
