import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './AddPensionsPage.css';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import RadioOptions from '../../components/RadioOptions/RadioOptions';
import {
  hasBegunWithdrawingStepLandedAction,
  returnLinkClickedAction,
  alreadyWithdrawingYesSelectedAction,
  alreadyWithdrawingNoSelectedAction,
} from '../../redux/modules/pensions';

const HasBegunWithdrawingStep = ({
  setFormData,
  setStep,
  amendMode,
  step,
  totalSteps,
  dispatchHasBegunWithdrawingStepLanded,
  dispatchReturnLinkClicked,
  dispatchAlreadyWithdrawingYesSelected,
  dispatchAlreadyWithdrawingNoSelected,
  isCustomerOngoing,
  formData,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchHasBegunWithdrawingStepLanded(isCustomerOngoing, formData.transferOption);
  }, []);
  const defaultValue = typeof formData.customerAnsweredAlreadyDrawingDown === 'boolean' ? formData.customerAnsweredAlreadyDrawingDown : undefined;
  const [customerAnsweredAlreadyDrawingDown,
    setCustomerAnsweredAlreadyDrawingDown] = useState(defaultValue);

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.currentlyContributingWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
          <ButtonLink
            onClick={(event) => {
              dispatchReturnLinkClicked(isCustomerOngoing, formData.transferOption);
              setStep((_step) => _step - 1);
              event.stopPropagation();
            }}
            label="← Back"
          />
        )}
        <h3 className={styles.currentlyContributingHeading}>
          {'Have you begun withdrawing from this pension?*'}
        </h3>
        <RadioOptions
          optionOneClickHandler={() => {
            dispatchAlreadyWithdrawingYesSelected(isCustomerOngoing, formData.transferOption);
            setCustomerAnsweredAlreadyDrawingDown(true);
          }}
          isOptionOneSelected={customerAnsweredAlreadyDrawingDown === true}
          optionTwoClickHandler={() => {
            dispatchAlreadyWithdrawingNoSelected(isCustomerOngoing, formData.transferOption);
            setCustomerAnsweredAlreadyDrawingDown(false);
          }}
          isOptionTwoSelected={customerAnsweredAlreadyDrawingDown === false}
        />
        <div
          className={styles.submitContainer}
        >
          <Button
            size="large"
            disabled={typeof customerAnsweredAlreadyDrawingDown !== 'boolean'}
            label={(amendMode) ? 'Confirm' : 'Submit'}
            onClick={() => {
              setFormData((_formData) => ({
                ..._formData,
                customerAnsweredAlreadyDrawingDown,
              }));
              setStep((_step) => _step + 1);
            }}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  dispatchHasBegunWithdrawingStepLanded: hasBegunWithdrawingStepLandedAction,
  dispatchReturnLinkClicked: returnLinkClickedAction,
  dispatchAlreadyWithdrawingYesSelected: alreadyWithdrawingYesSelectedAction,
  dispatchAlreadyWithdrawingNoSelected: alreadyWithdrawingNoSelectedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(HasBegunWithdrawingStep);
