import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';
import get from 'lodash/get';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import styles from './PersonalDetails.css';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Button from '../../components/Button/Button';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import {
  missingDetailsReturnLinkClickedAction,
  missingDetailsMobileNumberTyped,
  missingDetailsMobileNumberSubmitClickedAction,
  missingDetailsMobileNumberSkipStepClickedAction,
  missingDetailsCompleteAction,
} from '../../redux/modules/signupFlow';
import { updatePersonalDetails } from '../../redux/modules/investmentAdvice';
import { statusNotificationMissingDetailsCompleted } from '../../redux/modules/notificationTracking';
import TextField from '../../components/TextField/TextField';
import { getHasDigitalSignature, getUser } from '../../redux/selectors';
import { getHasOnlyInProgressContributionInstruction, getHasInProgressLumpSumInstructions } from '../../redux/selectors/investmentAdvice';

const MobilePhoneNumberStep = ({
  totalSteps,
  step,
  amendMode,
  dispatchMissingDetailsReturnLinkClickedAction,
  dispatchMissingDetailsMobileNumberTyped: _dispatchMissingDetailsMobileNumberTyped,
  dispatchMissingDetailsMobileNumberSubmitClickedAction,
  dispatchMissingDetailsMobileNumberSkipStepClickedAction,
  dispatchStatusNotificationMissingDetailsCompleted,
  dispatchMissingDetailsComplete,
  dispatchUpdatePersonalDetails,
  setStep,
  heading,
  formData,
  setFormData,
  isSubmitLoading,
  user,
  setIsSubmitLoading,
  flow,
  hasOnlyInProgressContributionInstructions,
  hasInProgressLumpSumInstructions,
}) => {
  const [mobileNumber, setMobileNumber] = useState(formData.mobileNumber || '');

  const [dispatchMissingDetailsMobileNumberTyped] = useState(() => once(_dispatchMissingDetailsMobileNumberTyped || ''));

  const UK_MOBILE_NUMBER_REGEX = /^(\+44?(\(0\)|\s)?7\d{3}|\(?(07\d{3})\)?)\s?\d{3}\s?\d{3}$/;

  const [error, setError] = useState(false);

  const removeWhitespace = (string) => {
    return string ? string.replace(/\s/g, '') : '';
  };

  const removeNonNumericCharacters = (string) => {
    return string ? string.replace(/\D/g, '') : '';
  };

  const mobileFromUser = get(user, 'contact.mobile');

  const hasValidMobileNumber = mobileFromUser !== undefined;

  const customerSignature = get(user, 'signature');

  const hasValidSignature = customerSignature !== undefined;

  const shouldShowContributionCopy = (flow === 'missing-details-cta' && (hasOnlyInProgressContributionInstructions || hasInProgressLumpSumInstructions)) || (flow === 'add-contribution' || flow === 'setup-lump-sum');

  const missingDetailsData = {
    nationalInsuranceNumber: formData.nationalInsuranceNumber,
    signature: hasValidSignature ? customerSignature : formData.signature,
    mobileNumber,
  };

  async function isUserOnLastStepOfFlow() {
    if (step === totalSteps - 2) {
      setIsSubmitLoading(true);
      await dispatchUpdatePersonalDetails(missingDetailsData);
      dispatchStatusNotificationMissingDetailsCompleted();
      dispatchMissingDetailsComplete();
      setIsSubmitLoading(false);
      setStep((_step) => _step + 1);
    }
    setStep((_step) => _step + 1);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.missingDetailsWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
          <ButtonLink
            onClick={() => {
              dispatchMissingDetailsReturnLinkClickedAction();
              setStep(step - 1);
            }}
            label="← Back"
          />
        )}
        <h1 className={styles.ninoHeading}>
          {heading}
          {'Mobile number'}
          {<div>&nbsp;</div>}
          <div className={styles.content}>
            {`A contact number will help us as we may need to speak to you about your ${shouldShowContributionCopy ? 'contribution' : 'pension(s)'}`}
            {' '}
          </div>
        </h1>
        <TextField
          type="text"
          name="mobileNumber"
          label="Mobile Number"
          className={styles.ninoInput}
          fieldStatusWrapper={{ margin: 0 }}
          blockWrapper={{ margin: 0 }}
          fullWidth
          defaultValue={'Enter your mobile number'}
          value={mobileNumber}
          touched
          onBlur={(event) => {
            if (!UK_MOBILE_NUMBER_REGEX.test(mobileNumber)) {
              setError(true);
            }
          }}
          wrapperStyles={{ margin: '0' }}
          onChange={(event) => {
            const value = get(event, 'target.value');
            dispatchMissingDetailsMobileNumberTyped();
            const formatMobilePhoneNumber = removeNonNumericCharacters(removeWhitespace(value));
            const validateMobileNumber = UK_MOBILE_NUMBER_REGEX.test(formatMobilePhoneNumber);

            setMobileNumber(formatMobilePhoneNumber);
            if (validateMobileNumber) {
              setError(false);
            }
          }}
        />
        {error
          && (
            <div className={styles.errorMessage}>
              {'Please enter a valid UK Mobile Number'}
            </div>
          )}
        <div>
          <Button
            size="large"
            type="button"
            label={'Submit'}
            disabled={error || isSubmitLoading || UK_MOBILE_NUMBER_REGEX.test(mobileNumber) === false}
            loading={isSubmitLoading}
            onClick={async () => {
              dispatchMissingDetailsMobileNumberSubmitClickedAction();
              setFormData((_formData) => ({
                ..._formData,
                mobileNumber: hasValidMobileNumber ? mobileFromUser : mobileNumber,
              }));
              isUserOnLastStepOfFlow();
            }}
            wrapperId="missing-details-submit"
          />
        </div>
        {<div>&nbsp;</div>}
        <ButtonLink
          variant="primary"
          label={<span className={styles.skipThisStep}>{'Skip this step'}</span>}
          onClick={async () => {
            dispatchMissingDetailsMobileNumberSkipStepClickedAction();
            setFormData((_formData) => ({
              ..._formData,
              mobileNumber: undefined,
            }));
            isUserOnLastStepOfFlow();
          }}
        />
      </div>
    </BaseGrid>
  );
};

const mapDispatchToProps = {
  dispatchMissingDetailsReturnLinkClickedAction: missingDetailsReturnLinkClickedAction,
  dispatchMissingDetailsMobileNumberTyped: missingDetailsMobileNumberTyped,
  dispatchMissingDetailsMobileNumberSubmitClickedAction: missingDetailsMobileNumberSubmitClickedAction,
  dispatchMissingDetailsMobileNumberSkipStepClickedAction: missingDetailsMobileNumberSkipStepClickedAction,
  dispatchUpdatePersonalDetails: updatePersonalDetails,
  dispatchStatusNotificationMissingDetailsCompleted: statusNotificationMissingDetailsCompleted,
  dispatchMissingDetailsComplete: missingDetailsCompleteAction,
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  digitalSignature: getHasDigitalSignature(state),
  hasOnlyInProgressContributionInstructions: getHasOnlyInProgressContributionInstruction(state),
  hasInProgressLumpSumInstructions: getHasInProgressLumpSumInstructions(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MobilePhoneNumberStep);
