import React from 'react';
import { connect } from 'react-redux';
import WithdrawalsAppoinmentComponent from '../../components/WithdrawalsAppoinmentComponent/WithdrawalsAppoinmentComponent';
import Loader from '../../components/Loader/Loader';
import {
  getUserProfile, getFirebaseUid, getCustomerReference, getPhoneNumber,
} from '../../redux/selectors';
import WithCollections, { COLLECTIONS } from '../../components/WithCollections/WithCollections';
import { CALENDLY_EVENT_SCHEDULED } from '../../redux/modules/booking';
import { getCalendlyUrl } from '../../redux/selectors/investmentAdvice';

const WithdrawalsAppointmentPage = (props) => {
  const {
    user, a1, a2, baseCalendlyUrl, scheduledEvent, type = 'withdrawals', duration = 30,
  } = props;
  const { contact, personal } = user;
  return (
    user.isLoaded ? (
      <WithdrawalsAppoinmentComponent
        firstName={personal.name.first}
        lastName={personal.name.last}
        a1={a1}
        a2={a2}
        email={contact.email}
        baseCalendlyUrl={baseCalendlyUrl}
        scheduledEvent={scheduledEvent}
        type={type}
        duration={duration}
      />
    )
      : <Loader centred page />
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
  user: getUserProfile(state),
  a1: getCustomerReference(state),
  a2: getPhoneNumber(state),
  baseCalendlyUrl: getCalendlyUrl(state),
  scheduledEvent: CALENDLY_EVENT_SCHEDULED,
});

const AppointmentPageWithRedux = connect(mapStateToProps)(WithdrawalsAppointmentPage);

const AppointmentPageWithCollections = (props) => (
  <WithCollections
    context="withdrawals-appointment-page"
    dependencies={[
      COLLECTIONS.USER,
      COLLECTIONS.ONBOARDING,
    ]}
  >
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <AppointmentPageWithRedux {...props} />
  </WithCollections>
);

export default AppointmentPageWithCollections;
