exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);

// module
exports.push([module.id, ".-DocumentView-largeView-1LFlr {\n  display: none;\n  padding-bottom: 3rem;\n}\n.-DocumentView-smallView-2VL8r {\n  padding-bottom: 3rem;\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n  .-DocumentView-smallView-2VL8r {\n    display: none;\n  }\n\n  .-DocumentView-largeView-1LFlr {\n    display: block;\n    padding-bottom: 3rem;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"dimensions": "\"../../theme/dimensions.css\"",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"largeView": "-DocumentView-largeView-1LFlr",
	"smallView": "-DocumentView-smallView-2VL8r"
};