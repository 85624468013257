import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import welcomeImage from '../../assets/images/retirementIncome/mountains-with-sun.svg';
import Button from '../../components/Button/Button';
import { welcomeStepGetStartedButtonClickedAction, initialStepPageLanded } from '../../redux/modules/projection';

import styles from './RetirementIncomePage.css';

const RetirementIncomeInitialStep = ({
  onNextStep,
  dispatchWelcomeStepGetStartedButtonClicked,
  dispatchInitialStepPageLanded,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchInitialStepPageLanded();
  }, []);

  return (
    <div className={styles.roundedDiv}>
      <div className={styles.roundedDivHeading}>
        <img src={welcomeImage} alt="Estimated pension income calculator" />
      </div>
      <div className={styles.initialStepHeadingWrapper}>
        <h1
          className={styles.initialStepHeading}
        >
          {'Estimated pension income calculator'}
        </h1>
      </div>
      <p className={styles.initialStepText}>{'Discover what your estimated pension income may look like based on your account balance, additional contributions or by changing your chosen retirement age.'}</p>
      <Button
        size="large"
        label="Get started"
        onClick={() => {
          dispatchWelcomeStepGetStartedButtonClicked();
          onNextStep();
        }}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatchWelcomeStepGetStartedButtonClicked:
    () => dispatch(welcomeStepGetStartedButtonClickedAction()),
  dispatchInitialStepPageLanded: () => dispatch(initialStepPageLanded()),
});

export default connect(null, mapDispatchToProps)(RetirementIncomeInitialStep);
