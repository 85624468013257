exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);

// module
exports.push([module.id, ".-FooterQuicklinks-wrapper-2wRKq {\n  line-height: 28px;\n  grid-row: 3;\n  grid-column: 1 / span 6;\n  margin-top: 48px;\n  width: 100%;\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n  .-FooterQuicklinks-wrapper-2wRKq {\n    grid-row: 2;\n    grid-column: 1 / span 3;\n    margin-top: 65px;\n  }\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["largeDesktop"] + ") {\n  .-FooterQuicklinks-wrapper-2wRKq {\n    grid-row: 1;\n    grid-column: 4 / span 3;\n    margin-top: 80px;\n  }\n}\n.-FooterQuicklinks-heading-JcxBr {\n  font-family: \"Poppins\";\n  font-weight: 500;\n  margin: 0;\n  font-size: 18px;\n  margin-bottom: 14px;\n  line-height: 28px;\n}\n.-FooterQuicklinks-list-17duj {\n  list-style-type: none;\n  padding: 0;\n  margin: 0;\n}\n.-FooterQuicklinks-linkItem-3JJdQ {\n  line-height: 28px;\n}\n.-FooterQuicklinks-link-3ewIA.-FooterQuicklinks-link-3ewIA {\n  font-size: 18px;\n  font-family: \"Lato\";\n  font-weight: 400;\n  text-decoration: none;\n  cursor: pointer;\n}", ""]);

// exports
exports.locals = {
	"dimensions": "\"../../theme/dimensions.css\"",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"largeDesktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["largeDesktop"] + "",
	"wrapper": "-FooterQuicklinks-wrapper-2wRKq",
	"heading": "-FooterQuicklinks-heading-JcxBr",
	"list": "-FooterQuicklinks-list-17duj",
	"linkItem": "-FooterQuicklinks-linkItem-3JJdQ",
	"link": "-FooterQuicklinks-link-3ewIA"
};