import React from 'react';
import styles from './FooterQuicklinks.css';

import { PENSION_TRANSFER_KEY_FILES } from '../../util/constants';

const links = [
  { text: 'FAQ', href: 'https://www.profilepensions.co.uk/pensions-faqs' },
  { text: 'What it costs', href: 'https://www.profilepensions.co.uk/pension-advice-cost' },
  { text: 'Awards', href: 'https://www.profilepensions.co.uk/awards' },
  { text: 'Blog', href: 'https://www.profilepensions.co.uk/blogs' },
  { text: 'Our Company', href: 'https://www.profilepensions.co.uk/our-company' },
  { text: 'Terms Of Business', href: PENSION_TRANSFER_KEY_FILES.termsOfBusiness },
  { text: 'Privacy Policy', href: 'https://www.profilepensions.co.uk/privacy-policy' },
  { text: 'Cookies Policy', href: '/cookies-policy' },
  { text: 'Pension transfer forms', href: 'https://res.cloudinary.com/profile-pensions/image/upload/v1611570086/Downloadable%20Docs/0121LOAF_Editable.pdf' },
  { text: 'Contact Us', href: 'https://www.profilepensions.co.uk/contact-us' },
];

const FooterQuicklinks = () => (
  <div className={styles.wrapper}>
    <h5 className={styles.heading}>{'QUICK LINKS'}</h5>
    <ul className={styles.list}>
      {links.map((link) => (
        <li className={styles.linkItem} key={link.text}>
          <a className={styles.link} href={link.href} rel="noopener noreferrer" target="_blank">{link.text}</a>
        </li>
      ))}
      <li className={styles.linkItem} key="cookies-preferences">
        <a
          className={styles.link}
          onClick={() => {
            window.CookieControl.hide();
            window.CookieControl.open();
          }}>
          {'Managing Cookies Preferences'}
        </a>
      </li>
    </ul>
  </div>
);

export default FooterQuicklinks;
