import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';
import get from 'lodash/get';

import styles from './SerpMissingDetails.css';
import Button from '../../components/Button/Button';
import BaseGrid from '../../components/BaseGrid/BaseGrid';

import { getNationalInsuranceNumber, getFirebaseUid } from '../../redux/selectors/index';
import { customerVisitedSerpsMissingDetails } from '../../redux/modules/notificationTracking';
import { getIsSerpsFirstSession } from '../../redux/selectors/serps';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import {
  serpsMissingDetailsNinoProvided,
  serpsMissingDetailsMessageMeClickedAction,
  serpsMissingDetailsNinoPageLoaded,
} from '../../redux/modules/serps';

import ButtonLink from '../../components/ButtonLink/ButtonLink';
import TextField from '../../components/TextField/TextField';
import { sendGaPageEvent } from '../../util/googleAnalyticHelpers';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';

import greenTickUpdate from '../../assets/images/serps/green-tick-update-cds.svg';

const NationalInsuranceNumberCard = ({
  heading,
  isSubmitting,
  formData,
  setStep,
  setFormData,
  step,
  totalSteps,
  amendMode,
  nino,
  isSubmitLoading,
  dispatchCustomerVisitedSerpsMissingDetails,
  isSerpsFirstSession,
  dispatchSerpsMissingDetailsMessageMeClicked,
  dispatchSerpsMissingDetailsNinoPageLoaded: _dispatchSerpsMissingDetailsNinoPageLoaded,
  dispatchSerpsMissingDetailsNinoProvided,
  uid,
}) => {
  const [dispatchSerpsMissingDetailsNinoPageLoaded] = useState(() => once(_dispatchSerpsMissingDetailsNinoPageLoaded || ''));

  const [nationalInsuranceNumber, setNationalInsuranceNumber] = useState(formData.nationalInsuranceNumber || '');
  const [error, setError] = useState(false);

  const removeWhitespace = (string) => {
    return string ? string.replace(/\s/g, '') : '';
  };
  const uppercase = (string) => {
    return string ? string.toUpperCase() : '';
  };

  const NI_REGEX = /^(?!BG|GB|KN|NK|NT|TN|ZZ)[^DFIQUV][^DFIQUVo][0-9]{6}[ABCD]$/img;

  const hasValidNationalInsuranceNumber = nino !== undefined && NI_REGEX.test(nino);

  const disableSaveButton = !nationalInsuranceNumber || isSubmitLoading || error;

  useEffect(() => {
    window.scrollTo(0, 0);
    sendGaPageEvent('/serps-request');
    dispatchSerpsMissingDetailsNinoPageLoaded();
  }, []);

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.missingDetailsWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {(!amendMode && !isSerpsFirstSession) && (
          <ButtonLink
            secondary
            mid
            to="/"
            label="← Back"
            wrapperStyles={{
              margin: '0 0 10px',
              fontFamily: 'Poppins',
              fontWeight: 500,
              fontSize: '16px',
              cursor: isSubmitting ? 'none' : 'hover',
            }}
          />
        )}
        <div className={styles.checklist}>
          <img src={greenTickUpdate} alt="greenTickUpdate" />
        </div>
        <div>
          <h1 className={styles.ninoHeading}>
            {heading}
            {'Great news, you are eligible for a SERPS trace!'}
          </h1>
          <div className={styles.content}>
            {'In order to trace your SERPS pension, HMRC require some additional details. This information is mandatory to proceed with your request.'}
          </div>
        </div>
        {hasValidNationalInsuranceNumber === false && (
          <div>
            <form className={styles.form} />
            <h1 className={styles.ninoHeading}>
              {'National Insurance number'}
            </h1>
            <TextField
              type="text"
              className={styles.ninoInput}
              fieldStatusWrapper={{ margin: 0 }}
              blockWrapper={{ margin: 0 }}
              fullWidth
              defaultValue={'Enter your National Insurance number'}
              value={nationalInsuranceNumber}
              touched
              onBlur={() => {
                if (!NI_REGEX.test(nationalInsuranceNumber)) {
                  setError(true);
                }
              }}
              wrapperStyles={{ margin: '0' }}
              onChange={(event) => {
                const value = get(event, 'target.value');
                const formattedNationalInsuranceNumber = uppercase(removeWhitespace(value));
                setNationalInsuranceNumber(formattedNationalInsuranceNumber);
                if (NI_REGEX.test(formattedNationalInsuranceNumber)) {
                  setError(false);
                }
              }}
            />
            {error
              && (
                <div className={styles.errorMessage}>
                  {'Please enter a valid National Insurance number'}
                </div>
              )}
          </div>
        )}
        <div>
          <Button
            type="button"
            label={'Continue'}
            disabled={hasValidNationalInsuranceNumber ? false : disableSaveButton}
            loading={isSubmitLoading}
            onClick={async () => {
              setFormData((_formData) => ({
                ..._formData,
                nationalInsuranceNumber:
                  hasValidNationalInsuranceNumber ? nino : nationalInsuranceNumber,
              }));
              dispatchCustomerVisitedSerpsMissingDetails(uid);
              dispatchSerpsMissingDetailsNinoProvided();
              setStep((_step) => _step + 1);
            }}
            wrapperId="missing-details-submit"
            fullWidth
          />
          <br />
        </div>
        {<div>&nbsp;</div>}
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'NationalInsuranceNumber'}
            noExpertContentWrapper={({ children }) => (
              <div
                style={{
                  display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                }}
              >
                {children}
              </div>
            )}
            showButton={false}
            customMessage={(
              <div
                className={styles.contactUsInnerContent}
              >
                {'HMRC require this information to help us trace your SERPS pension. '}
                <br />
                <br />
                {'Your National Insurance number can be found on your payslip or letter from the Department for Work and Pensions (DWP).'}
                <br />
                <br />
                {'If you need help finding it, here are '}
                <ButtonLink
                  variant="primary"
                  label="instructions on the government website."
                  link="https://www.gov.uk/lost-national-insurance-number"
                  font="inherit"
                  underline
                  target="_blank"
                  onClick={() => dispatchSerpsMissingDetailsMessageMeClicked()}
                  displayAsHref
                />
              </div>
            )}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapDispatchToProps = {
  dispatchSerpsMissingDetailsNinoProvided: serpsMissingDetailsNinoProvided,
  dispatchSerpsMissingDetailsMessageMeClicked: serpsMissingDetailsMessageMeClickedAction,
  dispatchSerpsMissingDetailsNinoPageLoaded: serpsMissingDetailsNinoPageLoaded,
  dispatchCustomerVisitedSerpsMissingDetails: customerVisitedSerpsMissingDetails,
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
  isSerpsFirstSession: getIsSerpsFirstSession(state),
  nino: getNationalInsuranceNumber(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NationalInsuranceNumberCard);
