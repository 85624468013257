import React from 'react';
import Button from '../Button/Button';

import GlanceCard from '../GlanceCard/GlanceCard';
import GlanceCardContent from '../GlanceCard/GlanceCardContent';
import Modal from '../Modal/Modal';
import styles from './ErrorModal.css';

class ErrorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }

  render() {
    const { showModal } = this.state;
    return (
      <Modal show={showModal} centerContent>
        <GlanceCard wrapperStyles={{ textAlign: 'center' }} title="Error">
          <GlanceCardContent wrapperStyles={{ maxHeight: '500px' }}>
            <p>{'Something went wrong. Please try again.'}</p>
            <div className={styles.buttonsContainer}>
              <Button
                label="Close"
                onClick={() => {
                  this.setState({ showModal: false });
                }}
              />
            </div>
          </GlanceCardContent>
        </GlanceCard>
      </Modal>
    );
  }
}

export default ErrorModal;
