export const INTRO_STEP = 'INTRO_STEP';
export const PENSION_TRANSFER_OPTION_STEP = 'PENSION_TRANSFER_OPTION_STEP';
export const PENSION_PROVIDER_STEP = 'PENSION_PROVIDER_STEP';
export const POLICY_NUMBER_STEP = 'POLICY_NUMBER_STEP';
export const OTHER_PENSION_PROVIDER_STEP = 'OTHER_PENSION_PROVIDER_STEP';
export const EMPLOYER_PENSION_STEP = 'EMPLOYER_PENSION_STEP';
export const EMPLOYER_NAME_STEP = 'EMPLOYER_NAME_STEP';
export const EMPLOYER_LOCATION_STEP = 'EMPLOYER_LOCATION_STEP';
export const EMPLOYMENT_SECTOR_STEP = 'EMPLOYMENT_SECTOR_STEP';
export const EMPLOYMENT_DATES_STEP = 'EMPLOYMENT_DATES_STEP';
export const PENSION_VALUE_STEP = 'PENSION_VALUE_STEP';
export const CURRENTLY_CONTRIBUTING_STEP = 'CURRENTLY_CONTRIBUTING_STEP';
export const HAS_BEGUN_WITHDRAWING = 'HAS_BEGUN_WITHDRAWING';
export const SUBMIT_STEP = 'SUBMIT_STEP';
export const THANKYOU_STEP = 'THANKYOU_STEP';
export const CANNOT_TRANSFER_PENSION = 'CANNOT_TRANSFER_PENSION';

export const commonSteps = [
  CURRENTLY_CONTRIBUTING_STEP,
  SUBMIT_STEP,
  THANKYOU_STEP,
];

export const employerDetailsSteps = [
  EMPLOYER_LOCATION_STEP,
  EMPLOYMENT_SECTOR_STEP,
  EMPLOYMENT_DATES_STEP,
];

export const initialFlow = [
  PENSION_TRANSFER_OPTION_STEP,
  PENSION_PROVIDER_STEP,
  POLICY_NUMBER_STEP,
  PENSION_VALUE_STEP,
  ...commonSteps,
];

export const authorityOnlyFlow = [
  SUBMIT_STEP,
  THANKYOU_STEP,
];

export const otherProviderFlow = [
  PENSION_TRANSFER_OPTION_STEP,
  PENSION_PROVIDER_STEP,
  OTHER_PENSION_PROVIDER_STEP,
  POLICY_NUMBER_STEP,
  PENSION_VALUE_STEP,
  ...commonSteps,
];

export const otherProviderUnmatchedFlow = [
  PENSION_TRANSFER_OPTION_STEP,
  PENSION_PROVIDER_STEP,
  OTHER_PENSION_PROVIDER_STEP,
  EMPLOYER_PENSION_STEP,
  POLICY_NUMBER_STEP,
  PENSION_VALUE_STEP,
  ...commonSteps,
];

export const otherProviderUnmatchedFlowEmployerPension = [
  PENSION_TRANSFER_OPTION_STEP,
  PENSION_PROVIDER_STEP,
  OTHER_PENSION_PROVIDER_STEP,
  EMPLOYER_PENSION_STEP,
  ...employerDetailsSteps,
  POLICY_NUMBER_STEP,
  PENSION_VALUE_STEP,
  ...commonSteps,
];

export const employerFlow = [
  EMPLOYER_NAME_STEP,
  ...employerDetailsSteps,
  ...commonSteps,
];

export const employerContributingFlow = [
  CANNOT_TRANSFER_PENSION,
];
