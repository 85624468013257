export const addCountryCodeToNumber = (phoneNumber) => {
  return phoneNumber ? phoneNumber.replace(/^0/, '+44') : '';
};

export const uppercase = (string) => {
  return string ? string.toUpperCase() : '';
};

export const formatPostcode = (postcode) => {
  return postcode ? `${postcode.slice(0, -3)} ${postcode.slice(-3)}` : '';
};

export const removeWhitespace = (string) => {
  return string ? string.replace(/\s/g, '') : '';
};

export const getVerifySmsErrorMessage = (errorMessage) => {
  if (errorMessage === 'Incorrect code') {
    return 'You’ve entered the code incorrectly, try again or press ‘Resend code’ to receive a new code.';
  } if (errorMessage === 'Expired code') {
    return 'The code you have entered has expired, please press ‘Resend code’ and try again.';
  }
  return 'Could not verify the entered code, try again or press ‘Resend code’ to receive a new code.';
};
