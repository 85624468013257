import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProgressStepper.css';
import ProgressIndicator from '../ProgressIndicator/ProgressIndicator';

const ProgressStepper = ({
  maxStep, step, padding, showMessage,
}) => {
  const remainingSteps = maxStep - step;
  let message;
  if (remainingSteps === 1) {
    message = `${remainingSteps} step remaining`;
  } else if (remainingSteps === 0) {
    message = 'almost done!';
  } else {
    message = `${remainingSteps} steps remaining`;
  }

  return (
    <React.Fragment>
      <ProgressIndicator value={(100 / maxStep) * step} />
      {showMessage && <div className={[styles.stepper, padding && styles.padding].join(' ')}>{message}</div>}
    </React.Fragment>
  );
};

ProgressStepper.propTypes = {
  step: PropTypes.number,
  maxStep: PropTypes.number,
  padding: PropTypes.bool,
  showMessage: PropTypes.bool,
};

ProgressStepper.defaultProps = {
  step: 0,
  maxStep: 5,
  padding: false,
  showMessage: false,
};

export default ProgressStepper;
