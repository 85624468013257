import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import auth, { FB_LOGOUT_FULFILL } from './auth';
import blog from './blog';
import customerDataSet from './customerDataSet';
import signupFlow from './signupFlow';
import investmentAdvice from './investmentAdvice';
import notificationTracking from './notificationTracking';
import conversations from './conversations';
import withdrawal from './withdrawal';
import trustpilot from './trustpilot';
import serps from './serps';

export const appReducer = combineReducers({
  auth,
  blog,
  signupFlow,
  customerDataSetStatuses: customerDataSet,
  investmentAdvice,
  conversations,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  notificationTracking,
  withdrawal,
  trustpilot,
  serps,
});

export const rootReducer = (state, action) => {
  if (action.type === FB_LOGOUT_FULFILL) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return appReducer(state, action);
};
