exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".-WithdrawalRow-contactPensionAdviserText-2kx9b {\n  margin-top: 15px;\n}\n", ""]);

// exports
exports.locals = {
	"contactPensionAdviserText": "-WithdrawalRow-contactPensionAdviserText-2kx9b"
};