exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".-CheckBox-wrapper-3G4Tq {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: reverse;\n      -ms-flex-direction: row-reverse;\n          flex-direction: row-reverse;\n}\n\n.-CheckBox-fieldLabelContainer-2ZRBA {\n  margin: 0 0 0 10px;\n}\n\n.-CheckBox-fieldLabelContainer-2ZRBA > div {\n  margin: 0;\n}", ""]);

// exports
exports.locals = {
	"wrapper": "-CheckBox-wrapper-3G4Tq",
	"fieldLabelContainer": "-CheckBox-fieldLabelContainer-2ZRBA"
};