import React from 'react';
import { connect } from 'react-redux';
import { returnLinkClickedAction, messageMeClickedAction } from '../../redux/modules/pensions';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import styles from './AddPensionsPage.css';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';

const UnknownEmployerContent = ({
  amendMode,
  setUnknownEmployerMode,
  isCustomerOngoing,
  dispatchReturnLinkClicked,
  dispatchMessageMeClicked,
}) => (
  <React.Fragment>
    <div className={styles.employerNameWrapper}>
      {!amendMode && (
        <ButtonLink
          onClick={(event) => {
            setUnknownEmployerMode(false);
            dispatchReturnLinkClicked(isCustomerOngoing);
            event.stopPropagation();
          }}
          label="← Back"
        />
      )}
      <h3 className={styles.needMoreDetailsHeading}>{'We need more details from you'}</h3>
      <p className={styles.unknownEmployerBody}>{'Unfortunately at this time we would be unable to help you find or transfer a pension. In order to help us find your pension we would need either your pension provider or employer name. We will be here to help if you’re able to tell us the registered details.'}</p>
    </div>
    <div
      className={styles.unknownEmployerBottom}
    >
      <div className={styles.unknownEmployerAdviserCard}>
        <ContactUsContent
          source={'UnknownEmployerContent'}
          showButton={false}
          customMessage={(
            <div
              className={styles.contactUsInnerContent}
            >
              {'If you have any questions, please feel free to '}
              <ButtonLink
                label="message me"
                variant="primary"
                to="/inbox/new-message"
                font="inherit"
                onClick={() => dispatchMessageMeClicked(isCustomerOngoing)}
              />
              {'.'}
            </div>
          )}
        />
      </div>
      <ButtonLink
        label={<span className={styles.unknownEmployerDashboardLink}>{'Back to Dashboard'}</span>}
        to="/"
      />
    </div>
  </React.Fragment>
);

const mapDispatchToProps = {
  dispatchReturnLinkClicked: returnLinkClickedAction,
  dispatchMessageMeClicked: messageMeClickedAction,
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnknownEmployerContent);
