import React from 'react';

import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { ONGOING_CDS_LINK_CLICKED } from '../../redux/modules/dashboard';

const UpdateCDSNotification = () => {
  return (
    <NotificationBanner
      alertCategory={'Blue'}
      alertTitle={'We need some information'}
      expandableAlertDescription={'To make sure your pension is in the best place, we need you to keep us up to date with your investment goals.'}
      ctaButtonOptions={{
        label: 'Complete',
        link: '/your-latest-information',
        event: ONGOING_CDS_LINK_CLICKED,
        dataCy: 'cds-dashboard-link',
      }}
    />
  );
};

export default UpdateCDSNotification;
