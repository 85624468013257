import React from 'react';
import { connect } from 'react-redux';

import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { getFirebaseUid } from '../../redux/selectors';
import {
  may2023DrawdownUpdateNotificationClicked,
  PORTFOLIO_2023_UPDATE_NOTIFICATION_CLOSED,
} from '../../redux/modules/notificationTracking';

const May2023DrawdownUpdateNotification = ({
  uid,
  dispatchMay2023DrawdownUpdateNotificationClicked,
}) => {
  return (
    <NotificationBanner
      alertCategory={'Blue'}
      alertTitle={'Why has my projected retirement income changed?'}
      expandableAlertDescription={'Your projected retirement income is not based on a fixed growth rate, our assumptions include market expectations that are updated frequently. Previously we had the expected income based on an approximate 75% chance that your pension will not run out before you die. We have changed this to 85% to make our projections more prudent.'}
      closeButtonOptions={{
        event: PORTFOLIO_2023_UPDATE_NOTIFICATION_CLOSED,
        handleClick: () => dispatchMay2023DrawdownUpdateNotificationClicked(uid),
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
});

const mapDispatchToProps = ({
  dispatchMay2023DrawdownUpdateNotificationClicked: may2023DrawdownUpdateNotificationClicked,
});

export default connect(mapStateToProps, mapDispatchToProps)(May2023DrawdownUpdateNotification);
