import React from 'react';
import PropTypes from 'prop-types';
import styles from './JourneyLayoutSidebarItem.css';


const JourneyLayoutSidebarItem = (props) => {
  const { icon, light, children } = props;
  const wrapperClasses = [styles.wrapper, light && styles.light].join(' ');

  return (
    <div className={wrapperClasses}>
      {icon &&
        <div className={styles.icon}>{icon}</div>
      }
      <div className={styles.content}>{children}</div>
    </div>
  );
};

JourneyLayoutSidebarItem.propTypes = {
  icon: PropTypes.node,
  light: PropTypes.bool,
};

JourneyLayoutSidebarItem.defaultProps = {
  icon: null,
  light: false,
};

export default JourneyLayoutSidebarItem;
