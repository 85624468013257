import React, { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player';

import styles from './VideoPlayer.css';

import MuteIcon from '../../assets/images/mute-icon.svg';
import UnmuteIcon from '../../assets/images/unmute-icon.svg';
import PlayIcon from '../../assets/images/play-button-icon.svg';
import ReplayIcon from '../../assets/images/replay-icon.svg';

const VideoPlayer = ({
  url,
  previewImage,
  playIcon,
  muted,
  withCustomControls,
  onStartEvent,
  onPlayEvent,
  onEndedEvent,
  onPauseEvent,
  onInterruptEvent,
}) => {
  const [playerControlsState, setPlayerControlsState] = useState({
    isPlaying: true,
    isMuted: muted,
    showPauseButton: false,
    showPlayButton: false,
    showReplayButton: false,
  });

  const {
    isPlaying,
    isMuted,
    showPauseButton,
    showPlayButton,
    showReplayButton,
  } = playerControlsState;

  const videoStartedRef = useRef(false);
  const videoEndedRef = useRef(false);
  const timeElapsedRef = useRef(0);
  const durationRef = useRef(0);
  const playerRef = useRef(null);

  const isFunction = (func) => {
    return typeof func === 'function';
  };

  const handleOnDuration = (duration) => {
    durationRef.current = duration;
  };

  const handleOnStart = () => {
    videoStartedRef.current = true;
    setPlayerControlsState((prevState) => ({
      ...prevState,
      isPlaying: true,
      showPauseButton: true,
    }));
    if (isFunction(onStartEvent)) {
      onStartEvent();
    }
  };

  const handleOnPlay = () => {
    if (isFunction(onPlayEvent)) {
      onPlayEvent();
    }
  };

  const handleOnPause = () => {
    if (isFunction(onPauseEvent)) {
      onPauseEvent(timeElapsedRef.current);
    }
  };

  const handleOnEnded = () => {
    videoEndedRef.current = true;
    setPlayerControlsState((prevState) => ({
      ...prevState,
      isPlaying: false,
      showPauseButton: true,
      showReplayButton: true,
      showPlayButton: false,
    }));
    if (isFunction(onEndedEvent)) {
      onEndedEvent(durationRef.current);
    }
  };

  useEffect(() => {
    const handleInterrupt = () => {
      if (isFunction(onInterruptEvent)) {
        if (videoStartedRef.current && !videoEndedRef.current) {
          onInterruptEvent(timeElapsedRef.current);
        }
      }
    };
    return () => {
      handleInterrupt();
    };
  }, []);

  const handlePauseButton = () => {
    setPlayerControlsState((prevState) => ({
      ...prevState,
      isPlaying: false,
      showPauseButton: false,
      showPlayButton: true,
    }));
  };

  const handlePlayingButton = () => {
    setPlayerControlsState((prevState) => ({
      ...prevState,
      isPlaying: true,
      showPauseButton: true,
      showPlayButton: false,
      showReplayButton: false,
    }));
  };

  const handleMuteButton = () => {
    setPlayerControlsState((prevState) => ({
      ...prevState,
      isMuted: !isMuted,
    }));
  };

  const handleReplayButton = () => {
    setPlayerControlsState((prevState) => ({
      ...prevState,
      isPlaying: true,
      showReplayButton: false,
      showPlayButton: false,
    }));
    playerRef.current.seekTo(0);
  };

  const pauseButton = (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={styles.pauseButton}
      onClick={handlePauseButton}
    />
  );

  const playButton = (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={styles.playButton}
      onClick={handlePlayingButton}
    >
      <img src={PlayIcon} alt="play button" />
    </div>
  );

  const muteUnmuteButton = (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={styles.muteUnmuteButton}
      onClick={handleMuteButton}
    >
      {isMuted
        ? <img src={UnmuteIcon} alt="unmute button" />
        : <img src={MuteIcon} alt="mute button" />}
    </div>
  );

  const replayButton = (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={styles.replayButton}
      onClick={handleReplayButton}
    >
      <img src={ReplayIcon} alt="replay button" />
    </div>
  );

  const config = {
    attributes: {
      disablePictureInPicture: true,
      controlsList: 'disablepictureinpicture nodownload noplaybackrate',
    },
  };

  return (
    <div className={styles.videoPlayerContainer}>
      <ReactPlayer
        ref={playerRef}
        url={url}
        light={previewImage}
        playsinline
        playIcon={playIcon}
        controls={!withCustomControls}
        config={config}
        width="100%"
        height="100%"
        volume={1}
        muted={isMuted}
        playing={isPlaying}
        onDuration={handleOnDuration}
        onStart={handleOnStart}
        onPlay={handleOnPlay}
        onProgress={(progress) => {
          timeElapsedRef.current = progress.playedSeconds;
        }}
        onPause={handleOnPause}
        onEnded={handleOnEnded}
      />
      {withCustomControls
        && (
          <React.Fragment>
            {showPauseButton && pauseButton}
            {showPlayButton && playButton}
            {muteUnmuteButton}
            {showReplayButton && replayButton}
          </React.Fragment>
        )}
    </div>
  );
};

export default VideoPlayer;
