import get from 'lodash/get';
import { getFirestore } from 'redux-firestore';
import { getFirebase } from 'react-redux-firebase';

import moment from 'moment';
import sentryException from '../../util/sentryException';

// 2fa:
export const AUTH_EMAIL_PWD = 'pp/auth/AUTH_EMAIL_PWD_DISPATCHED';
export const TWO_FACTOR_AUTH_EMAIL_PWD_FULFILL = 'pp/auth/TWO_FACTOR_AUTH_EMAIL_PWD_FULFILLED';
export const AUTH_EMAIL_PWD_REJECT = 'pp/auth/AUTH_EMAIL_PWD_REJECTED';

export const TWO_FACTOR_AUTH_SMS = 'pp/auth/TWO_FACTOR_AUTH_SMS_DISPATCHED';
export const TWO_FACTOR_AUTH_SMS_FULFILL = 'pp/auth/TWO_FACTOR_AUTH_SMS_FULFILLED';
export const TWO_FACTOR_AUTH_SMS_REJECT = 'pp/auth/TWO_FACTOR_AUTH_SMS_REJECTED';

export const SINGLE_FACTOR_AUTH_LOGIN_FULFILL = 'pp/auth/SINGLE_FACTOR_AUTH_LOGIN_FULFILLED';
export const SINGLE_FACTOR_AUTH_LOGIN_REJECTED = 'pp/auth/SINGLE_FACTOR_AUTH_LOGIN_REJECTED';

export const authEmailPwdDispatch = (email, pwd) => ({ type: AUTH_EMAIL_PWD, email, pwd });
export const twoFactorAuthEmailPwdFulfill = (uid) => ({
  type: TWO_FACTOR_AUTH_EMAIL_PWD_FULFILL,
  uid,
});
export const authEmailPwdReject = (error) => ({ type: AUTH_EMAIL_PWD_REJECT, error });
// verify sms code and get fb auth token
export const twoFactorAuthSMSDispatch = (uid, code) => ({ type: TWO_FACTOR_AUTH_SMS, uid, code });
export const twoFactorAuthSMSFulfill = (authToken) => ({
  type: TWO_FACTOR_AUTH_SMS_FULFILL,
  authToken,
});
export const twoFactorAuthSMSReject = (error) => ({ type: TWO_FACTOR_AUTH_SMS_REJECT, error });

export const singleFactorAuthLoginFulfill = (authToken, uid) => ({
  type: SINGLE_FACTOR_AUTH_LOGIN_FULFILL,
  authToken,
  uid,
});
export const singleFactorAuthLoginReject = (error) => ({
  type: SINGLE_FACTOR_AUTH_LOGIN_REJECTED,
  error,
});

export const RESEND_EMAIL_DISPATCH = 'pp/auth/SIGNUP_RESEND_EMAIL_DISPATCH';
export const RESEND_EMAIL_REJECT = 'pp/auth/SIGNUP_RESEND_EMAIL_REJECT';
export const RESEND_EMAIL_FULFILL = 'pp/auth/SIGNUP_RESEND_EMAIL_FULFILL';

export const NOTIFICATION_MESSAGE = 'pp/auth/NOTIFICATION_MESSAGE';

export const setNotificationMessage = (message) => ({
  type: NOTIFICATION_MESSAGE,
  notificationMessage: message,
});

// firebase auth:
export const FB_LOGIN_FULFILL = 'pp/auth/FB_LOGIN_FULFILLED';
export const FB_LOGIN_REJECT = 'pp/auth/FB_LOGIN_REJECTED';
export const FB_LOGOUT_FULFILL = 'pp/auth/FB_LOGOUT_FULFILLED';
export const FB_LOGOUT_SOFT_FULFILL = 'pp/auth/FB_LOGOUT_SOFT_FULFILLED';
export const FB_AUTO_LOGOUT_FULFILL = 'pp/auth/FB_AUTO_LOGOUT_FULFILL';
export const FB_LOGOUT_REJECT = 'pp/auth/FB_LOGOUT_REJECTED';
export const FB_REGENERATE_ID_TOKEN_FULFILL = 'pp/auth/FB_REGENERATE_ID_TOKEN_FULFILL';
export const FB_REGENERATE_ID_TOKEN_REJECT = 'pp/auth/FB_REGENERATE_ID_TOKEN_REJECT';
export const FB_REGENERATE_ID_TOKEN_MODAL_SHOWN = 'pp/auth/FB_REGENERATE_ID_TOKEN_MODAL_SHOWN';
export const FB_REGENERATE_ID_TOKEN_MODAL_CLOSED = 'pp/auth/FB_REGENERATE_ID_TOKEN_MODAL_CLOSED';
export const EXTERNAL_LOGIN_SUCCESSFUL = 'pp/auth/EXTERNAL_LOGIN_SUCCESSFUL';

export const externalLoginSuccessfulAction = () => ({ type: EXTERNAL_LOGIN_SUCCESSFUL });

/**
 * Returns the current token if it has not expired.
 * Otherwise, it will refresh the token and return a new one.
 */
export const refreshToken = () => () => {
  const firebase = getFirebase();
  return firebase
    .auth()
    .currentUser
    .getIdToken(true);
};

export const setLastLoggedInDate = async () => {
  const firestore = getFirestore();
  const firebase = getFirebase();
  const { uid } = firebase.auth().currentUser;

  const userDetailsColRef = firestore.collection('users').doc(uid).collection('userDetails');
  const userDetailsColSnapshot = await userDetailsColRef.get();

  const userDetailsDocId = get(userDetailsColSnapshot, 'docs[0].id');

  const update = { lastLoggedInDate: new Date().toISOString(), lastModifiedByUsername: 'account-hub' };

  try {
    if (userDetailsDocId) {
      await userDetailsColRef
        .doc(userDetailsDocId)
        .set(update, { merge: true });
    } else {
      await userDetailsColRef
        .doc()
        .set(update);
    }
  } catch (e) {
    sentryException(e, {
      section: 'setting-last-login-date-failed',
    });
  }
};

export const FBRegenerateIdTokenModalShown = () => ({ type: FB_REGENERATE_ID_TOKEN_MODAL_SHOWN });
export const FBRegenerateIdTokenModalClosed = () => ({ type: FB_REGENERATE_ID_TOKEN_MODAL_CLOSED });
export const FBRegenerateIdTokenFulfill = () => ({ type: FB_REGENERATE_ID_TOKEN_FULFILL });
export const FBRegenerateIdTokenReject = () => ({ type: FB_REGENERATE_ID_TOKEN_REJECT });
export const FBLoginFulfill = () => ({ type: FB_LOGIN_FULFILL });
export const FBLoginReject = (error) => ({ type: FB_LOGIN_REJECT, error });
export const FBLogin = (authToken) => (dispatch) => {
  const firebase = getFirebase();
  // Firebase session persistence
  // Existing and future Auth states are now persisted in the current
  // session only. Closing the window would clear any existing state even
  // if a user forgets to sign out.
  // New sign-in will be persisted with session persistence.

  return new Promise((resolve, reject) => {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => firebase.auth().signInWithCustomToken(authToken))
      .then(() => {
        dispatch(FBLoginFulfill());
      })
      .then(() => setLastLoggedInDate())
      .then(resolve)
      .catch((error) => {
        dispatch(FBLoginReject(error));
        reject(error);
      });
  });
};
export const FBRegenerateLogin = (authToken) => (dispatch) => {
  const firebase = getFirebase();
  return new Promise((resolve, reject) => {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => firebase.auth().signInWithCustomToken(authToken))
      .then(() => dispatch(FBLoginFulfill()))
      .then(() => firebase.reloadAuth())
      .then(resolve)
      .catch((error) => {
        dispatch(FBLoginReject(error));
        reject(error);
      });
  });
};
export const FBLogoutFulfill = () => ({ type: FB_LOGOUT_FULFILL });
export const FBLogoutSoftFulfill = () => ({ type: FB_LOGOUT_SOFT_FULFILL });
export const FBAutoLogoutFulfill = () => ({ type: FB_AUTO_LOGOUT_FULFILL });
export const FBLogoutReject = (error) => ({ type: FB_LOGOUT_REJECT, error });
export const FBLogout = () => (dispatch) => {
  const firebase = getFirebase();
  firebase.auth().signOut()
    .then(() => dispatch(FBLogoutFulfill()))
    .then(() => window.location.replace('/'))
    .catch((error) => dispatch(FBLogoutReject(error)));
};
export const FBLogoutSoft = () => (dispatch) => {
  const firebase = getFirebase();
  firebase.auth().signOut()
    .then(() => dispatch(FBLogoutSoftFulfill()))
    .catch((error) => dispatch(FBLogoutReject(error)));
};
export const FBAutoLogout = (timeout = true) => async (dispatch) => {
  const firestore = getFirestore();
  const firebase = getFirebase();

  const { uid } = firebase.auth().currentUser;
  if (uid) {
    const event = {
      type: 'account-hub-action',
      key: FB_AUTO_LOGOUT_FULFILL,
      date: moment.utc().format(),
      createdBy: 'account-hub',
    };

    if (timeout) {
      try {
        const newEvent = firestore.collection('users').doc(uid).collection('events');
        await newEvent.add(event);
      } catch (error) {
        const code = get(error, 'code') || '';
        if (code.includes('permission-denied')) return;
        sentryException(error, {
          section: 'auto-logout-event',
        });
      }
    }
  }

  firebase.auth().signOut()
    .then(() => dispatch(FBLogoutFulfill()))
    .then(() => dispatch(FBAutoLogoutFulfill()))
    .then(() => window.location.replace('/login?auto-logout=true'))
    .catch((error) => dispatch(FBLogoutReject(error)));
};
export const SET_PRE_AUTH_EMAIL = 'SET_PRE_AUTH_EMAIL';
export const setPreAuthEmail = (email) => ({ type: SET_PRE_AUTH_EMAIL, email });

// activation:
export const ACTIVATE_EMAIL_REQUEST = 'pp/auth/ACTIVATE_EMAIL_REQUEST';
export const ACTIVATE_EMAIL_REQUEST_FULFILL = 'pp/auth/ACTIVATE_EMAIL_REQUEST_FULFILLED';
export const ACTIVATE_EMAIL_REQUEST_REJECT = 'pp/auth/ACTIVATE_EMAIL_REQUEST_REJECTED';

export const ACTIVATE_REF_DISPATCH = 'pp/auth/ACTIVATE_REF_DISPATCHED';
export const ACTIVATE_REF_FULFILL = 'pp/auth/ACTIVATE_REF_FULFILLED';
export const ACTIVATE_REF_REJECT = 'pp/auth/ACTIVATE_REF_REJECTED';

export const ACTIVATE_REQUEST_SMS_DISPATCH = 'pp/auth/ACTIVATE_REQUEST_SMS_DISPATCHED';
export const ACTIVATE_REQUEST_SMS_FULFILL = 'pp/auth/ACTIVATE_REQUEST_SMS_FULFILLED';
export const ACTIVATE_REQUEST_SMS_REJECT = 'pp/auth/ACTIVATE_REQUEST_SMS_REJECTED';

export const ACTIVATE_VERIFY_SMS_DISPATCH = 'pp/auth/ACTIVATE_VERIFY_SMS_DISPATCHED';
export const ACTIVATE_VERIFY_SMS_FULFILL = 'pp/auth/ACTIVATE_VERIFY_SMS_FULFILLED';
export const ACTIVATE_VERIFY_SMS_REJECT = 'pp/auth/ACTIVATE_VERIFY_SMS_REJECTED';

export const ACTIVATE_VERIFY_POSTCOE_DOB_DISPATCH = 'pp/auth/ACTIVATE_VERIFY_POSTCOE_DOB_DISPATCHED';
export const ACTIVATE_VERIFY_POSTCOE_DOB_FULFILL = 'pp/auth/ACTIVATE_VERIFY_POSTCOE_DOB_FULFILLED';
export const ACTIVATE_VERIFY_POSTCOE_DOB_REJECT = 'pp/auth/ACTIVATE_VERIFY_POSTCOE_DOB_REJECTED';

export const SET_PASSWORD_DISPATCH = 'pp/auth/SET_PASSWORD_DISPATCHED';
export const SET_PASSWORD_FULFILL = 'pp/auth/SET_PASSWORD_FULFILLED';
export const SET_PASSWORD_REJECT = 'pp/auth/SET_PASSWORD_REJECTED';

export const setPasswordDispatch = (pwd) => ({ type: SET_PASSWORD_DISPATCH, pwd });
export const setPasswordFulfill = () => ({ type: SET_PASSWORD_FULFILL });
export const setPasswordReject = (error) => ({ type: SET_PASSWORD_REJECT, error });

export const RESET_PASSWORD_FULFILL = 'pp/auth/RESET_PASSWORD_FULFILL';
export const resetPasswordFulfill = (authToken) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(FBLogin(authToken))
      .then(() => {
        dispatch({ type: RESET_PASSWORD_FULFILL });
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Verification
export const VERIFY_EMAIL_DISPATCHED = 'pp/signup/VERIFY_EMAIL_DISPATCHED';
export const VERIFY_EMAIL_FULFILLED = 'pp/signup/VERIFY_EMAIL_FULFILLED';
export const VERIFY_EMAIL_REJECTED = 'pp/signup/VERIFY_EMAIL_REJECTED';

const initialState = {
  token: null,
  uid: null,
  mobilePartial: null,
  emailPartial: null,
  smsSent: false,
  smsCodeVerified: false,
  postcodeVerified: false,
  passwordSet: false,
  passwordReset: false,
  dobVerified: false,
  isFetching: false,
  isFetched: false,
  isAuthenticating: false,
  error: null,
  twoFactorRequired: false,
  autoLogout: false,
  notificationMessage: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVATE_REF_DISPATCH:
      return {
        ...state,
        isFetching: true,
      };

    case ACTIVATE_REF_FULFILL:
      return {
        ...state,
        uid: action.uid,
        mobilePartial: action.mobilePartial,
        emailPartial: action.emailPartial,
        isFetching: false,
        isFetched: true,
        error: null,
      };

    case ACTIVATE_REF_REJECT:
      return {
        ...state,
        error: action.error.response.error,
        isFetching: false,
      };

    case ACTIVATE_REQUEST_SMS_FULFILL:
      return {
        ...state,
        smsSent: true,
      };

    case ACTIVATE_REQUEST_SMS_REJECT:
      return {
        ...state,
        smsSent: false,
      };

    case ACTIVATE_VERIFY_SMS_FULFILL:
      return {
        ...state,
        smsCodeVerified: true,
      };

    case ACTIVATE_VERIFY_SMS_REJECT:
      return {
        ...state,
        smsCodeVerified: false,
      };

    case ACTIVATE_VERIFY_POSTCOE_DOB_FULFILL:
      return {
        ...state,
        postcodeVerified: true,
        dobVerified: true,
      };

    case ACTIVATE_VERIFY_POSTCOE_DOB_REJECT:
      return {
        ...state,
        postcodeVerified: false,
        dobVerified: false,
      };

    case TWO_FACTOR_AUTH_EMAIL_PWD_FULFILL:
      return {
        ...state,
        uid: action.uid,
        twoFactorRequired: true,
      };

    case TWO_FACTOR_AUTH_SMS:
      return {
        ...state,
        isFetching: true,
        passwordReset: false,
      };

    case TWO_FACTOR_AUTH_SMS_FULFILL:
      return {
        ...state,
        token: action.authToken,
        isFetching: false,
        isFetched: true,
        isAuthenticating: true,
        error: null,
      };

    case SINGLE_FACTOR_AUTH_LOGIN_REJECTED:
      return {
        ...state,
        isFetching: false,
        isAuthenticating: false,
        error: action.error,
      };

    case SINGLE_FACTOR_AUTH_LOGIN_FULFILL:
      return {
        ...state,
        token: action.authToken,
        uid: action.uid,
        isFetching: false,
        isFetched: true,
        error: null,
      };

    case TWO_FACTOR_AUTH_SMS_REJECT:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isAuthenticating: false,
      };

    case FB_LOGIN_FULFILL:
      return {
        ...state,
        isAuthenticating: false,
        error: null,
        autoLogout: false,
      };

    case FB_LOGOUT_SOFT_FULFILL:
      return {
        ...state,
        token: null,
        uid: null,
      };

    case FB_LOGIN_REJECT:
      return {
        ...state,
        error: action.error,
        isAuthenticating: false,
      };

    case RESET_PASSWORD_FULFILL:
      return {
        ...state,
        error: null,
        token: action.authToken,
        passwordReset: true,
        isFetching: false,
        isFetched: true,
        isAuthenticating: true,
      };
    case VERIFY_EMAIL_DISPATCHED:
      return {
        ...state,
        isFetching: true,
        isEmailVerified: undefined,
        verifiedEmail: undefined,
      };
    case VERIFY_EMAIL_FULFILLED:
      return {
        ...state,
        isFetching: false,
        isEmailVerified: true,
        verifiedEmail: get(action, 'data.email'),
      };
    case VERIFY_EMAIL_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case FB_AUTO_LOGOUT_FULFILL:
      return {
        ...state,
        autoLogout: true,
      };
    case NOTIFICATION_MESSAGE:
      return {
        ...state,
        notificationMessage: get(action, 'notificationMessage'),
      };
    case SET_PRE_AUTH_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    default:
      return state;
  }
}
