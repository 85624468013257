/* eslint-disable import/no-cycle */

import get from 'lodash/get';
import moment from 'moment';

import { getSerpsTrace } from './investmentAdvice';
import { getUser, getSignup, getDob, getHasNationalInsuranceNumber } from './index';
import { getHasAuthorizedSerpsRequest, getHasCustomerVisitedSerpsMissingDetails } from './notificationTracking';
import { getAuthorisedSerpsPensions, getPensions, getIsThereNonAuthorisedSerpsPensions } from './pensions';

export const getIsSerpsResponseSynced = (state) => {
  const files = get(getUser(state), 'files') || [];
  return files.some((file) => file.name.includes('hmrc-response.pdf'));
};

export const getHasRequestedTrace = (state) => {
  return Boolean(get(getSignup(state), 'hmrcRequest') || (getSerpsTrace(state) && !getCustomerAgeEligibleForSerps(state)) || getIsSerpsResponseSynced(state));
};

export const getHasMinimumSerpsData = (state) => {
  const signup = getSignup(state);
  const user = getUser(state);

  const nino = get(user, 'personal.nationalInsuranceNumber') || get(signup, 'customer.nationalInsuranceNumber');
  const postcode = get(user, 'contact.address.postalCode') || get(signup, 'customer.address.postcode');
  return Boolean(nino && postcode);
};

export const getShouldOfferSerpsTrace = (state) => {
  return !getHasRequestedTrace(state);
};

export const getWasSerpsTraceRequested = (state) => {
  return getSerpsTrace(state);
};

export const getHasSerpsAuthority = (state) => {
  return getHasAuthorizedSerpsRequest(state);
};

export const getPensionsWithHmrcResponseEligibleYes = (state) => {
  const pensions = getPensions(state);
  return pensions.filter((pension) => pension.hmrcResponseEligible === 'yes' && !pension.authorityToTransfer);
};

export const getAreAllPensionsWithHmrcResponseEligibleNo = (state) => {
  const pensions = getPensions(state);
  return pensions.every((pension) => pension.hmrcResponseEligible === 'no');
};

export const getHasPensionsWithHmrcResponseEligibleCheck = (state) => {
  const pensions = getPensions(state);
  return pensions.filter((pension) => pension.hmrcResponseEligible === 'check').length > 0;
};

export const getHasPensionsWithHmrcSource = (state) => {
  const pensions = getPensions(state);
  return pensions.filter((pension) => pension.source === 'hmrc').length > 0;
};

export const getCustomerHmrcResponseFields = (state) => {
  const user = getUser(state);
  return {
    hmrcResponse: user.hmrcResponse,
    hmrcResponseReceivedDate: user.hmrcResponseReceivedDate,
  };
};

export const getCustomerAgeEligibleForSerps = (state) => {
  const customerDob = getDob(state);
  const eligibleAge = customerDob
    && new Date('1955-01-01') - new Date(customerDob) <= 0
    && new Date('1981-01-01') - new Date(customerDob) >= 0;

  return eligibleAge;
};

export const getCustomerEligibleForSerps = (state) => {
  const requestedSerps = getWasSerpsTraceRequested(state);
  const customerDob = getDob(state);

  if (!requestedSerps) {
    return false;
  }

  const eligibleAge = customerDob
    && new Date('1955-01-01') - new Date(customerDob) <= 0
    && new Date('1981-01-01') - new Date(customerDob) >= 0;

  return eligibleAge;
};

export const getIsSerpsFirstSession = (state) => {
  return getShouldOfferSerpsTrace(state)
  && getCustomerEligibleForSerps(state)
  && !getHasNationalInsuranceNumber(state)
  && !getHasSerpsAuthority(state)
  && !getHasCustomerVisitedSerpsMissingDetails(state);
};

export const getEligibleHmrcPensions = (state) => {
  const pensionsWithHmrcResponseEligibleYes = getPensionsWithHmrcResponseEligibleYes(state);
  return pensionsWithHmrcResponseEligibleYes.length > 0;
};

export const getCustomerHasAuthorisedSerpsPensions = (state) => {
  const customerHasAuthorisedSerpsPensions = getAuthorisedSerpsPensions(state);
  return customerHasAuthorisedSerpsPensions.length > 0;
};

export const getHmrcResponseReceivedDate = (state) => {
  const hmrcResponse = getCustomerHmrcResponseFields(state);
  return get(hmrcResponse, 'hmrcResponseReceivedDate');
};

export const getHasHmrcResponse = (state) => {
  const hmrcResponseFields = getCustomerHmrcResponseFields(state);
  return Boolean(get(hmrcResponseFields, 'hmrcResponse'));
};

export const getSerpsMissingDetailsComplete = (state) => {
  return getWasSerpsTraceRequested(state) && getHasSerpsAuthority(state);
};

export const getNegativeHmrcResponseReceived = (state) => {
  const hmrcResponseReceivedDate = getHmrcResponseReceivedDate(state);
  return getHasHmrcResponse(state)
  && hmrcResponseReceivedDate
  && moment.utc().diff(hmrcResponseReceivedDate, 'days') > 5
  && (!getHasPensionsWithHmrcSource(state) || getAreAllPensionsWithHmrcResponseEligibleNo(state));
};

export const getCustomerHasUnauthorisedSerpsPensions = (state) => {
  const nonAuthorisedSerpsPensions = getIsThereNonAuthorisedSerpsPensions(state);
  return Boolean(nonAuthorisedSerpsPensions.length && nonAuthorisedSerpsPensions.length > 0);
};

export const getEligibleForSerpsAndMissingDetailsIncomplete = (state) => {
  return !getSerpsMissingDetailsComplete(state) && getCustomerEligibleForSerps(state);
};

export const getHmrcResponseReceivedAndPensionsAreEligible = (state) => {
  return getCustomerEligibleForSerps(state)
  && getSerpsMissingDetailsComplete(state)
  && getEligibleHmrcPensions(state)
  && !getNegativeHmrcResponseReceived(state)
  && getCustomerHasUnauthorisedSerpsPensions(state);
};

export const getWaitingForHmrcResponse = (state) => {
  return getCustomerEligibleForSerps(state)
  && getSerpsMissingDetailsComplete(state)
  && !getNegativeHmrcResponseReceived(state)
  && (!getHmrcResponseReceivedDate(state) || getHasPensionsWithHmrcResponseEligibleCheck(state))
  && !getHmrcResponseReceivedAndPensionsAreEligible(state);
};

export const getHmrcResponseReceivedAndCustomerAuthorisedPensions = (state) => {
  return getCustomerEligibleForSerps(state)
  && getSerpsMissingDetailsComplete(state)
  && !getNegativeHmrcResponseReceived(state)
  && getEligibleHmrcPensions(state)
  && getCustomerHasAuthorisedSerpsPensions(state);
};

export const getMissingDetailsCompleteAndNegativeHmrcResponseReceived = (state) => {
  return getCustomerEligibleForSerps(state)
  && getSerpsMissingDetailsComplete(state)
  && getNegativeHmrcResponseReceived(state);
};

export const getSelectedSerpsToAuthorise = (state) => get(state, 'serps.selectedSerpsToAuthorise');
