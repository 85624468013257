/* eslint-disable max-len */
/* eslint-disable import/no-cycle */

import get from 'lodash/get';
import {
  getRecentlyCompletedCds,
  getSignup,
  getUser,
  getIsVerified,
  getEmail,
  getIsNewOngoingCdsRequiredAndAllowed,
  getIsNewOngoingCdsMandatory,
  getAge,
  getIntendedRetirementAge,
  getLatestPensionValue,
  getAuthoritativeRetirementPlan,
} from './index';

import {
  getIsVulnerableCustomer,
} from './vulnerableCustomer';

import {
  isAdviceGenerated,
  getInvestmentAdviceOnboarding,
  getIsWithdrawalLumpSumWithinCoolOffPeriod,
  getInProgressWithdrawalLumpSumInstructions,
  getIsWithdrawalRequestInProgress,
  getInProgressContributionInstructions,
} from './investmentAdvice';

import {
  getRecentlyAcceptedRecommendation,
  getHasRecommendationToAcceptAndReport,
} from './ongoingService';

import {
  getIsCustomerOnboarding,
  getIsCustomerOngoing,
} from './experience';

import {
  getHasRequestedTrace,
  getWasSerpsTraceRequested,
} from './serps';

import { getIsThereNonAuthorisedSerpsPensions } from './pensions';

import { WITHDRAWAL_AGE } from '../../util/constants';

export const getIsInvestmentRecommendationAllowed = (state) => {
  const vulnerableCustomer = getIsVulnerableCustomer(state);
  return Boolean(!vulnerableCustomer && isAdviceGenerated(state));
};

export const getIsOngoingCdsAllowed = (state) => {
  const isCustomerOngoing = getIsCustomerOngoing(state);
  const requiredAndAllowed = getIsNewOngoingCdsRequiredAndAllowed(state);
  return Boolean(isCustomerOngoing && requiredAndAllowed && !getRecentlyCompletedCds(state));
};

export const getIsOngoingCdsMandatory = (state) => {
  const isCustomerOngoing = getIsCustomerOngoing(state);
  const requiredAndAllowed = getIsNewOngoingCdsRequiredAndAllowed(state);
  const mandatory = getIsNewOngoingCdsMandatory(state);
  return Boolean(isCustomerOngoing && requiredAndAllowed && mandatory && !getRecentlyCompletedCds(state));
};

export const getIsSignupAllowed = (state) => {
  const signup = getSignup(state);
  const user = getUser(state);
  return Boolean(!get(user, 'accountHubSetup') && signup && get(signup, 'progress') !== 'complete');
};

export const getIsFindPensionsAllowed = (state) => {
  return getHasRequestedTrace(state) && !getIsCustomerOngoing(state);
};

export const getIsVerifiedJourneyAllowed = (state) => {
  return !getIsVerified(state) && getEmail(state);
};

export const getIsAddPensionsAllowed = (state) => {
  return Boolean(get(getInvestmentAdviceOnboarding(state), 'isAddPensionsAllowed'));
};

export const getIsAddMoneyAllowed = (state) => {
  return Boolean(get(getInvestmentAdviceOnboarding(state), 'isAddPensionsAllowed')) && getInProgressContributionInstructions(state).length === 0;
};

export const getIsAuthoriseChangeAllowed = (state) => {
  return Boolean(
    getHasRecommendationToAcceptAndReport(state) || getRecentlyAcceptedRecommendation(state),
  );
};

export const getIsNotAUkTaxResident = (state) => {
  const investmentAdviceOnboarding = getInvestmentAdviceOnboarding(state);
  const ukTaxResidencyDueDiligence = get(investmentAdviceOnboarding, 'customerDueDiligence.ukTaxResidency.proceed') === false;
  return ukTaxResidencyDueDiligence;
};

export const getIsOnboardingCdsAllowed = (state) => {
  return !getIsAddPensionsAllowed(state)
    && !getIsCustomerOngoing(state)
    && !getIsNotAUkTaxResident(state);
};

export const getIsVulnerableCustomerAllowed = (state) => (
  getIsVulnerableCustomer(state)
);

export const getIsSetupContributionAllowed = (state) => Boolean(get(getInvestmentAdviceOnboarding(state), 'isAddPensionsAllowed'));

export const getIsSetupLumpSumAllowed = (state) => Boolean(get(getInvestmentAdviceOnboarding(state), 'isAddPensionsAllowed'));

export const getIsMissingDetailsAllowed = (state) => Boolean(get(getInvestmentAdviceOnboarding(state), 'isAddPensionsAllowed'));

export const getIsWithdrawalAllowed = (state) => {
  const isCustomerOngoing = getIsCustomerOngoing(state);
  const age = getAge(state);
  const latestPensionValue = getLatestPensionValue(state);
  return isCustomerOngoing && (age >= WITHDRAWAL_AGE) && latestPensionValue >= 0;
};

export const getIsPlanSummaryAllowed = (state) => {
  return getIsAddPensionsAllowed(state) && getIsCustomerOnboarding(state);
};

export const getIsPlanActivityAllowed = (state) => {
  return getIsCustomerOngoing(state);
};

export const getIsWithdrawalPagesAllowed = (state) => {
  const isWithdrawalAllowed = getIsWithdrawalAllowed(state);
  const isWithdrawalLumpSumWithinCoolOffPeriod = getIsWithdrawalLumpSumWithinCoolOffPeriod(state);
  const inProgressWithdrawalLumpSumInstructions = getInProgressWithdrawalLumpSumInstructions(state);
  const isWithdrawalRestricted = Boolean(isWithdrawalAllowed
    && !(isWithdrawalLumpSumWithinCoolOffPeriod || inProgressWithdrawalLumpSumInstructions.length));
  return getIsWithdrawalRequestInProgress(state) || isWithdrawalRestricted;
};

export const getIsRetirementIncomeProjectionAllowed = (state) => {
  const rp = getAuthoritativeRetirementPlan(state);
  const pensionValue = get(rp, 'latestPensionValue') || 0;

  const notPastRetirementAge = getAge(state) < getIntendedRetirementAge(state);

  return getIsCustomerOngoing(state)
    && pensionValue > 0
    && rp.modelDescriptor
    && notPastRetirementAge;
};

export const getIsSerpsAuthAllowed = (state) => {
  return (getIsThereNonAuthorisedSerpsPensions(state) || []).length > 0;
};

export const getIsSerpsMissingDetailsAllowed = (state) => {
  return getWasSerpsTraceRequested(state);
};
