import React from 'react';
import get from 'lodash/get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { getFirebaseUid, getUser } from '../../redux/selectors';
import styles from './AlertCard.css';
import { VERIFY_LINK_CLICKED } from '../../redux/modules/account';
import ServiceAlertCard from './ServiceAlertCard';

class SystemAlertCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getAlertProps() {
    const { dispatch, user, verificationComplete } = this.props;

    const emailVerified = get(user, 'verification.email.verified');

    const verificationText = ' to verify your email address.';
    let alertTitle = '';
    let alertDescription = '';

    if (!emailVerified) {
      alertTitle = 'Please verify your account';
    }

    if (verificationComplete && emailVerified) {
      alertTitle = 'Account successfully verified';
      alertDescription = 'Your account details have now been verified, giving you full access to your account.';
    }

    return {
      alertTitle,
      alertBody: (!emailVerified ? (
        <p className="body-two">
          {'To gain full access to your account and to login securely in the future, please follow the simple steps '}
          <a href="/verification" className={styles.verifyLink} onClick={() => { dispatch({ type: VERIFY_LINK_CLICKED }); }}>{'here'}</a>
          { verificationText }
        </p>
      ) : <p className="body-two">{alertDescription}</p>
      ),
    };
  }

  render() {
    const { user } = this.props;

    const emailVerified = get(user, 'verification.email.verified');

    const {
      alertTitle,
      alertBody,
    } = this.getAlertProps();

    const alertBodyPropsChildren = get(alertBody, 'props.children', '');
    if (!(alertTitle || alertBodyPropsChildren)) {
      return (null);
    }

    return (
      <ServiceAlertCard
        alertTitle={alertTitle}
        alertBody={alertBody}
        alertSeverity={emailVerified ? 'success' : 'warning'}
        dataCyPrefix="verification-"
      />
    );
  }
}

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
  user: getUser(state),
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => ([
    {
      collection: 'users',
      doc: props.uid,
    },
  ])),
)(SystemAlertCard);
