import firebase from 'firebase/compat/app';
import sentryException from '../../util/sentryException';

function formatWithdrawalEvent(eventKey) {
  return `pp/withdrawal/${eventKey}`;
}

const SETUP_WITHDRAWAL_DISPATCHED = formatWithdrawalEvent('SETUP_WITHDRAWAL_DISPATCHED');
const SETUP_WITHDRAWAL_FULFILLED = formatWithdrawalEvent('SETUP_WITHDRAWAL_FULFILLED');
const SETUP_WITHDRAWAL_REJECTED = formatWithdrawalEvent('SETUP_WITHDRAWAL_REJECTED');

export const withdrawalFormVisitedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_FORM_VISITED') }
);

export const withdrawalAmountStepVisitedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_AMOUNT_VISITED') }
);

export const withdrawalReasonsStepVisitedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_REASONS_VISITED') }
);

export const withdrawalOtherOptionsStepVisitedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_OTHER_OPTIONS_VISITED') }
);

export const withdrawalAccountDetailsStepVisitedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_ACCOUNT_DETAILS_VISITED') }
);

export const withdrawalSubmitStepVisitedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_REVIEW_DETAILS_VISITED') }
);

export const withdrawalCompleteVisitedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_COMPLETE_VISITED') }
);

export const withdrawalImportantInformationVisitedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_IMPORTANT_INFORMATION_VISITED') }
);

export const lumpSumWithdrawalAdviserPageVisitedAction = () => (
  { type: formatWithdrawalEvent('LUMP_SUM_WITHDRAWAL_ADVISER_PAGE_VISITED') }
);

export const nextClickedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_NEXT_CLICKED') }
);

export const backClickedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_BACK_CLICKED') }
);

export const withdrawalOtherImportantInformationClickedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_OTHER_IMPORTANT_INFORMATION_CLICKED') }
);

export const withdrawalSubmitClickedAction = () => (
  { type: formatWithdrawalEvent('SUBMIT_CLICKED') }
);

export const withdrawalSubmitErrorAction = () => (
  { type: formatWithdrawalEvent('SUBMIT_ERROR') }
);

export const changeBankDetailsClickedAction = () => (
  { type: formatWithdrawalEvent('CHANGE_BANK_DETAILS_CLICKED') }
);

export const changeWithdrawalAmountClickedAction = () => (
  { type: formatWithdrawalEvent('CHANGE_WITHDRAWAL_AMOUNT_CLICKED') }
);

export const importantInformationLinkClickedAction = () => (
  { type: formatWithdrawalEvent('IMPORTANT_INFORMATION_LINK_CLICKED') }
);

export const confirmWithdrawalSelectedAction = () => (
  { type: formatWithdrawalEvent('CONFIRM_WITHDRAWAL_SELECTED') }
);

export const confirmWithdrawalUnselectedAction = () => (
  { type: formatWithdrawalEvent('CONFIRM_WITHDRAWAL_UNSELECTED') }
);

export const understandWithdrawalSelectedAction = () => (
  { type: formatWithdrawalEvent('UNDERSTAND_WITHDRAWAL_SELECTED') }
);

export const understandWithdrawalUnselectedAction = () => (
  { type: formatWithdrawalEvent('UNDERSTAND_WITHDRAWAL_UNSELECTED') }
);

export const withdrawalHealthIssuesChangedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_HEALTH_ISSUES_CHANGED') }
);

export const withdrawalTypeChangedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_TYPE_CHANGED') }
);

export const withdrawalFundsUsedForChangedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_FUNDS_USED_FOR_CHANGED') }
);

export const withdrawalAmountChangedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_AMOUNT_CHANGED') }
);

export const backToDashboardAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_BACK_TO_DASHBOARD_CLICKED') }
);

export const referFriendClickedAction = () => (
  { type: formatWithdrawalEvent('REFER_FRIEND_CLICKED') }
);

export const setupLumpSumWithdrawalClickedAction = () => (
  { type: formatWithdrawalEvent('SETUP_LUMP_SUM_WITHDRAWAL_CLICKED') }
);

export const setupMonthlyWithdrawalClickedAction = () => (
  { type: formatWithdrawalEvent('SETUP_MONTHLY_WITHDRAWAL_CLICKED') }
);

export const withdrawalCompleteAddPensionsButtonClicked = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_COMPLETE_ADD_PENSIONS_BUTTON_CLICKED') }
);

export const withdrawalCompleteAddContributionsButtonClicked = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_COMPLETE_ADD_CONTRIBUTIONS_BUTTON_CLICKED') }
);

export const withdrawalAppointmentBookClickedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_APPOINTMENT_BOOK_CLICKED') }
);

export const withdrawalMessageAdvisorButtonClickedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_MESSAGE_ADVISOR_CLICKED') }
);

export const withdrawalImportantConsiderationsClickedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_IMPORTANT_CONSIDERSATIONS_CLICKED') }
);

export const withdrawalHelpfulInformationClickedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_HELPFUL_INFORMATION_CLICKED') }
);

export const withdrawalImpactIncomeClickedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_IMPACT_INCOME_CLICKED') }
);

export const withdrawalFromMyPensionClickedAction = () => (
  { type: formatWithdrawalEvent('WITHDRAWAL_FROM_MY_PENSION_CLICKED') }
);

export const completeAddPensionRequestSelectedAction = () => (
  { type: formatWithdrawalEvent('COMPLETE_ADD_PENSION_REQUEST_CHECKBOX_SELECTED') }
);

export const completeAddPensionRequestUnselectedAction = () => (
  { type: formatWithdrawalEvent('COMPLETE_ADD_PENSION_REQUEST_CHECKBOX_UNSELECTED') }
);

export const completeAddContributionRequestSelectedAction = () => (
  { type: formatWithdrawalEvent('COMPLETE_ADD_CONTRIBUTION_REQUEST_CHECKBOX_SELECTED') }
);

export const completeAddContributionRequestUnselectedAction = () => (
  { type: formatWithdrawalEvent('COMPLETE_ADD_CONTRIBUTION_REQUEST_CHECKBOX_UNSELECTED') }
);

export const confirmWithdrawalAdviceSelectedAction = () => (
  { type: formatWithdrawalEvent('CONFIRM_WITHDRAWAL_ADVICE_SELECTED') }
);

export const confirmWithdrawalAdviceUnselectedAction = () => (
  { type: formatWithdrawalEvent('CONFIRM_WITHDRAWAL_ADVICE_UNSELECTED') }
);

export const setupWithdrawalAction = (
  withdrawalData,
) => async (dispatch) => {
  const addWithdrawalInstruction = firebase.app().functions('europe-west1').httpsCallable('addInstruction');

  dispatch({ type: SETUP_WITHDRAWAL_DISPATCHED });
  try {
    await addWithdrawalInstruction(withdrawalData);
    dispatch({ type: SETUP_WITHDRAWAL_FULFILLED });
  } catch (error) {
    dispatch({ type: SETUP_WITHDRAWAL_REJECTED });
    sentryException(error, {
      section: 'setup-withdrawal',
    });
    throw error;
  }
};

const initialState = {
  withdrawalRequestInProgress: false,
  appointmentPending: false,
};

const WITHDRAWAL_REQUEST_IN_PROGRESS_STATUS = 'pp/withdrawal/WITHDRAWAL_REQUEST_IN_PROGRESS_STATUS';
const SET_WITHDRAWAL_APPOINTMENT_PENDING = 'pp/withdrawal/SET_WITHDRAWAL_APPOINTMENT_PENDING';
const UNSET_WITHDRAWAL_APPOINTMENT_PENDING = 'pp/withdrawal/UNSET_WITHDRAWAL_APPOINTMENT_PENDING';

const withdrawalRequestInProgressAction = (isLoading) => ({
  type: WITHDRAWAL_REQUEST_IN_PROGRESS_STATUS,
  payload: isLoading,
});

export const withdrawalRequestInProgress = (status) => (
  async (dispatch) => {
    dispatch(withdrawalRequestInProgressAction(status));
  }
);

const setWithdrawalAppointmentPendingAction = () => ({
  type: SET_WITHDRAWAL_APPOINTMENT_PENDING,
});

export const setWithdrawalAppointmentPending = () => (
  async (dispatch) => {
    dispatch(setWithdrawalAppointmentPendingAction());
  }
);

const unsetWithdrawalAppointmentPendingAction = () => ({
  type: UNSET_WITHDRAWAL_APPOINTMENT_PENDING,
});

const SET_IMPORTANT_CONSIDERATIONS_ROUTING = 'SET_IMPORTANT_CONSIDERATIONS_ROUTING';

export const setImportantConsiderationsRoutingAction = ({ back, forward, from = '' }) => ({
  type: SET_IMPORTANT_CONSIDERATIONS_ROUTING,
  payload: {
    back,
    forward,
    from,
  },
});

export const unsetWithdrawalAppointmentPending = () => (
  async (dispatch) => {
    dispatch(unsetWithdrawalAppointmentPendingAction());
  }
);

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case WITHDRAWAL_REQUEST_IN_PROGRESS_STATUS:
      return {
        ...state,
        withdrawalRequestInProgress: action.payload,
      };
    case SET_WITHDRAWAL_APPOINTMENT_PENDING:
      return {
        ...state,
        appointmentPending: true,
      };
    case UNSET_WITHDRAWAL_APPOINTMENT_PENDING:
      return {
        ...state,
        appointmentPending: false,
      };
    case SET_IMPORTANT_CONSIDERATIONS_ROUTING:
      return {
        ...state,
        importantConsiderationsRouting: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
