exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/typography.css"), undefined);
exports.i(require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-GuaranteesAndExitPenaltiesModal-closeButtonContainer-2e1Gw {\n  width: 100%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n}\n.-GuaranteesAndExitPenaltiesModal-closeButton-2TLWL {\n  border: none;\n  background: none;\n  cursor: pointer;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.-GuaranteesAndExitPenaltiesModal-closeButtonText-3Pbct {\n  margin-left: 4px;\n  color: " + require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/colors.css").locals["accentBaseSecondaryRegular"] + ";\n}\n.-GuaranteesAndExitPenaltiesModal-heading-hflWz {\n}\n.-GuaranteesAndExitPenaltiesModal-bodyText-1OaMc {\n  color: " + require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/colors.css").locals["textParagraphRegular"] + ";\n}", ""]);

// exports
exports.locals = {
	"typography": "\"../../../theme/typography.css\"",
	"h3": "" + require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/typography.css").locals["h3"] + "",
	"subtext": "" + require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/typography.css").locals["subtext"] + "",
	"bodyOne": "" + require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/typography.css").locals["bodyOne"] + "",
	"colors": "\"../../../theme/colors.css\"",
	"accentBaseSecondaryRegular": "" + require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/colors.css").locals["accentBaseSecondaryRegular"] + "",
	"textParagraphRegular": "" + require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/colors.css").locals["textParagraphRegular"] + "",
	"thickFog": "" + require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/colors.css").locals["thickFog"] + "",
	"closeButtonContainer": "-GuaranteesAndExitPenaltiesModal-closeButtonContainer-2e1Gw",
	"closeButton": "-GuaranteesAndExitPenaltiesModal-closeButton-2TLWL",
	"closeButtonText": "-GuaranteesAndExitPenaltiesModal-closeButtonText-3Pbct " + require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/typography.css").locals["subtext"] + "",
	"heading": "-GuaranteesAndExitPenaltiesModal-heading-hflWz " + require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/typography.css").locals["h3"] + "",
	"bodyText": "-GuaranteesAndExitPenaltiesModal-bodyText-1OaMc " + require("-!../../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/src/index.js!../../../theme/typography.css").locals["bodyOne"] + ""
};