import React from 'react';
import { connect } from 'react-redux';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { SERPS_PENSION_TRACE_DISMISSED, dispatchSerpsWaitingForHmrcNotificationDismissed } from '../../redux/modules/notificationTracking';
import { getFirebaseUid } from '../../redux/selectors';

const serpsWaitingForHmrcNotification = ({
  uid,
  dispatchSerpsTraceNotificationDismissed,
}) => {
  return (
    <NotificationBanner
      alertCategory={'Blue'}
      alertTitle={'SERPS Trace'}
      alertDescription={'We’re processing your SERPS request which normally takes around 30 days. We\'ll update the pension status in your account and we\'ll send you a message when the transfer can be processed.'}
      closeButtonOptions={{
        event: SERPS_PENSION_TRACE_DISMISSED,
        handleClick: () => dispatchSerpsTraceNotificationDismissed(uid),
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
});

const mapDispatchToProps = ({
  dispatchSerpsTraceNotificationDismissed: dispatchSerpsWaitingForHmrcNotificationDismissed,
});

export default connect(mapStateToProps, mapDispatchToProps)(serpsWaitingForHmrcNotification);
