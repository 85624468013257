import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { getActivityPlanInstructions } from '../../../redux/selectors/investmentAdvice';

import InstructionsIcon from '../../../assets/images/rebranding/pencil-edit-icon.svg';
import GreenTick from '../../../assets/images/icons/Icon_Circle_Green_Tick_Rounded.svg';
import BlueDash from '../../../assets/images/icons/Icon_Circle_Blue_Dash_Rounded.svg';

import styles from './PlanActivityInstructions.css';

const DD_SETUP_OR_AMENDMENT = [
  'new-customer-contribution-regular',
  'contribution-regular',
  'contribution-amend-regular',
];

const LUMP_SUM = [
  'contribution-lump-sum',
  'new-customer-contribution-lump-sum',
];

const IN_PROGRESS_STATUS = [
  'new',
  'in-progress',
];

const COMPLETE_STATUS = ['completed'];

const PlanActivityInstructions = ({ activityPlanInstructions }) => {
  const instructions = [...activityPlanInstructions];

  instructions.sort((a, b) => {
    return new Date(b.createdDate) - new Date(a.createdDate);
  });

  return (
    <div className={styles.planActivityInstructionsContainer}>
      {instructions.map((instruction) => {
        let label;
        let status;
        let statusIcon;

        if (DD_SETUP_OR_AMENDMENT.includes(instruction.type)) {
          label = 'Direct debit setup or amendment requested';
        } else if (LUMP_SUM.includes(instruction.type)) {
          label = 'Lump sum request';
        }

        if (IN_PROGRESS_STATUS.includes(instruction.status)) {
          status = `In progress since ${moment.utc(instruction.statusDate).format('DD/MM/YY')}`;
          statusIcon = BlueDash;
        } else if (COMPLETE_STATUS.includes(instruction.status)) {
          status = `Completed on ${moment.utc(instruction.statusDate).format('DD/MM/YY')}`;
          statusIcon = GreenTick;
        }

        if (!label || !status) return;

        return (
          <div key={instruction._id} className={styles.instructionRow}>
            <div className={styles.instructionIconWrapper}>
              <img className={styles.instructionIcon} src={InstructionsIcon} alt="Instructions icon" />
            </div>
            <div className={styles.instructionInfoWrapper}>
              <p className={styles.instructionLabel}>{label}</p>
              <p className={styles.instructionStatus}>{status}</p>
            </div>
            <div className={styles.statusIconWrapper}>
              <img className={styles.statusIcon} src={statusIcon} alt="Status icon" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activityPlanInstructions: getActivityPlanInstructions(state),
});

export default connect(mapStateToProps, null)(PlanActivityInstructions);
