import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirestore } from 'react-redux-firebase';
import { withFormik, Field } from 'formik';
import { validate } from 'validate.js';
import once from 'lodash/once';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import get from 'lodash/get';
import firebase from 'firebase/compat/app';
import { convertFlattenedObjectToObject, addressConstraints } from '../../util/completePersonalDetailsValidation';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import styles from './PersonalDetails.css';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import {
  missingDetailsReturnLinkClickedAction,
  missingDetailsMessageMeClickedAction,
  missingDetailsFindAddressClicked,
  missingDetailsAddressDropdownClicked,
  missingDetailsPostcodeDeleted,
  missingDetailsPostcodeTyped,
  missingDetailsAddressSelected,
  missingDetailsAddressSaveSelected,
  missingDetailsCompleteAction,
} from '../../redux/modules/signupFlow';
import { updatePersonalDetails } from '../../redux/modules/investmentAdvice';
import { statusNotificationMissingDetailsCompleted } from '../../redux/modules/notificationTracking';
import { renderAddressFields, renderMissingDetailsPostcodeAddressLookup } from '../../util/formik-custom-fields';
import { getHasDigitalSignature, getUser, getHasMobileNumber } from '../../redux/selectors';
import Button from '../../components/Button/Button';
import { GENERIC_ERROR } from '../../forms/copyTexts';
import sentryException from '../../util/sentryException';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import { getHasInProgressLumpSumInstructions, getHasOnlyInProgressContributionInstruction } from '../../redux/selectors/investmentAdvice';

const ADDRESS_FIELDS = ['line1', 'line2', 'line3', 'line4', 'locality', 'city', 'county', 'postcode'];

const removeWhitespace = (string) => {
  return string ? string.replace(/\s/g, '') : '';
};
const uppercase = (string) => {
  return string ? string.toUpperCase() : '';
};
const formatPostcode = (postcode) => {
  return postcode ? `${postcode.slice(0, -3)} ${postcode.slice(-3)}` : '';
};

const renderField = ({
  type,
  name,
  label,
  value,
  options,
  onChange,
  component,
  ...custom
}) => (
  <Field
    type={type}
    name={name}
    label={label}
    value={value}
    options={options}
    onChange={onChange}
    component={component}
    largeHeadings
    {...custom}
  />
);

class CurrentAddressStep extends Component {
  constructor(props) {
    super(props);

    const {
      dispatchMissingDetailsPostcodeDeleted,
      dispatchMissingDetailsPostcodeTyped,
      dispatchMissingDetailsAddressSaveSelected,
    } = this.props;
    this.dispatchMissingDetailsPostcodeDeleted = once(dispatchMissingDetailsPostcodeDeleted);
    this.dispatchMissingDetailsPostcodeTyped = once(dispatchMissingDetailsPostcodeTyped);
    this.dispatchMissingDetailsAddressSaveSelected = once(dispatchMissingDetailsAddressSaveSelected);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    const {
      isSubmitting,
      isValid,
      errors,
    } = this.props;

    if (!isValid && prevProps.isSubmitting && !isSubmitting) {
      const [firstError] = Object.keys(errors);
      let errorElement = document.querySelector(`input[name="${firstError}"]`);
      if (firstError === 'address') {
        const addressKeys = Object.keys(errors[firstError]);
        errorElement = document.querySelector(`input[name="${firstError}-${addressKeys[0]}"]`);
      }
      if (errorElement && errorElement.parentNode) {
        errorElement.parentNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    if (isValid && !prevProps.isSubmitting && isSubmitting) {
      document
        .querySelector('#missing-details-submit')
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  defaultHandleChange = (event, fieldName) => {
    const { handleChange } = this.props;
    handleChange(event);
    const postalCode = get(event, 'target.value');

    if (fieldName === 'postcode') {
      if (!postalCode) {
        this.dispatchMissingDetailsPostcodeDeleted();
      } else {
        this.dispatchMissingDetailsPostcodeTyped();
      }
    }
  };

  addressHandleChange = (fieldValue) => {
    const {
      setFieldValue,
      setTouched,
      touched,
      setInEditMode,
      setEditingComponent,
      values,
    } = this.props;

    setFieldValue('address', fieldValue);

    if (fieldValue.line1 && fieldValue.city) {
      setTouched({ ...touched, address: true });
    }

    if (fieldValue.line1 && fieldValue.city && values.postcode) {
      setInEditMode(true);
      setEditingComponent(true);
    }
  };

  getAddresses = async (postcode) => {
    let addresses = [];
    const getAddresses = firebase.app().functions('europe-west1').httpsCallable('addressLookup');

    try {
      ({ data: addresses } = await getAddresses({ postcode }));
    } catch (error) {
      console.log(error);
      sentryException(error, {
        section: 'missing-details',
      });
    }

    return addresses;
  };

  selectAddress = async (address = '') => {
    const {
      setFieldValue,
      setTouched,
      touched,
      setStep,
      setFormData,
      values,
      setEditingComponent,
      setIsSubmitLoading,
      dispatchUpdatePersonalDetails,
      step,
      totalSteps,
      dispatchStatusNotificationMissingDetailsCompleted,
      dispatchMissingDetailsComplete,
      user,
    } = this.props;

    const currentAddress = {
      line1: '',
      line2: '',
      line3: '',
      line4: '',
      locality: '',
      city: '',
      county: '',
      postCode: formatPostcode(uppercase(removeWhitespace(values.postcode))),
    };

    address.split(',').forEach((field, index) => {
      if (ADDRESS_FIELDS && ADDRESS_FIELDS[index]) {
        currentAddress[ADDRESS_FIELDS[index]] = field.trim();
      }
    });

    setFieldValue('address', currentAddress);
    if (currentAddress.line1 && currentAddress.city) {
      setTouched({ ...touched, address: false });
      setEditingComponent(true);
    }

    const mobileFromUser = get(user, 'contact.mobile');

    const hasValidMobileNumber = mobileFromUser !== undefined;

    const customerSignature = get(user, 'signature');

    const hasValidSignature = customerSignature !== undefined;

    const formattedStreet = [currentAddress.line1, currentAddress.line2, currentAddress.line3].join('');

    const formattedPostcode = formatPostcode(uppercase(removeWhitespace(currentAddress.postCode)));

    const missingDetailsData = {
      nationalInsuranceNumber: get(values, 'formData.nationalInsuranceNumber'),
      signature: hasValidSignature ? customerSignature : get(values, 'formData.signature'),
      mobileNumber: hasValidMobileNumber ? mobileFromUser : get(values, 'formData.mobileNumber'),
      street: formattedStreet,
      city: get(currentAddress, 'city'),
      county: get(currentAddress, 'county'),
      postCode: formattedPostcode,
    };

    setFormData((_formData) => ({
      ..._formData,
      nationalInsuranceNumber: missingDetailsData.nationalInsuranceNumber,
      signature: missingDetailsData.signature,
      street: missingDetailsData.street,
      city: missingDetailsData.city,
      county: missingDetailsData.county,
      postCode: missingDetailsData.postCode,
    }));
    if (step === totalSteps - 2) {
      setIsSubmitLoading(true);
      await dispatchUpdatePersonalDetails(missingDetailsData);
      dispatchStatusNotificationMissingDetailsCompleted();
      dispatchMissingDetailsComplete();
      setIsSubmitLoading(false);
    }
  };

  fieldsConfig = () => {
    const {
      values,
      isSubmitLoading,
    } = this.props;

    return {
      postCode: {
        type: 'text',
        name: 'postcode',
        label: 'Postcode',
        value: values.postcode,
        onChange: (event) => this.defaultHandleChange(event, 'postcode'),
        component: renderMissingDetailsPostcodeAddressLookup,
        disabled: isSubmitLoading,
        getAddresses: this.getAddresses,
        selectAddress: this.selectAddress,
      },
      address: {
        type: null,
        name: 'address',
        label: 'Address',
        value: values.address,
        onChange: this.addressHandleChange,
        component: renderAddressFields,
        wrapperStyles: { marginBottom: '15px' },
      },
    };
  };

  constructErrorMessage = (data) => {
    if (!data) {
      return;
    }

    return (
      <div>
        {GENERIC_ERROR}
        {this.constructEmailUsElement()}
      </div>
    );
  }

  render() {
    const {
      step,
      totalSteps,
      amendMode,
      setStep,
      dispatchMissingDetailsReturnLinkClickedAction,
      dispatchMissingDetailsMessageMeClickedAction,
      dispatchMissingDetailsAddressSaveSelected,
      dispatchStatusNotificationMissingDetailsCompleted,
      dispatchMissingDetailsComplete,
      values,
      initialValues,
      errors,
      isValid,
      editingComponent,
      setFormData,
      isSubmitLoading,
      setIsSubmitLoading,
      dispatchUpdatePersonalDetails,
      setInEditMode,
      user,
      mob,
      flow,
      hasOnlyInProgressContributionInstructions,
      hasInProgressLumpSumInstructions,
    } = this.props;
    const fieldsConfig = this.fieldsConfig();

    const formValuesHaveChanged = !isEqual(initialValues.fieldValue, values.address);

    const showSave = formValuesHaveChanged && editingComponent;

    const disableSaveButton = (formValuesHaveChanged && !isValid) || isSubmitLoading;

    const UK_MOBILE_NUMBER_REGEX = /^(\+44?(\(0\)|\s)?7\d{3}|\(?(07\d{3})\)?)\s?\d{3}\s?\d{3}$/;

    const customerSignature = get(user, 'signature');

    const hasValidSignature = customerSignature !== undefined;

    const hasValidMobileNumber = (mob !== undefined || '') && UK_MOBILE_NUMBER_REGEX.test(mob);

    const userStreet = get(user[0], 'contact.address.street');
    const userCity = get(user[0], 'contact.address.city');
    const userPostalCode = get(user[0], 'contact.address.postalCode');

    const hasAStreetName = userStreet !== undefined;
    const hasACityName = userCity !== undefined;
    const hasAPostalCode = userPostalCode !== undefined;

    const formattedPostcode = formatPostcode(uppercase(removeWhitespace(values.postcode)));

    const shouldShowContributionCopy = (flow === 'missing-details-cta' && (hasOnlyInProgressContributionInstructions || hasInProgressLumpSumInstructions)) || (flow === 'add-contribution' || flow === 'setup-lump-sum');

    const missingDetailsData = {
      nationalInsuranceNumber: get(values, 'formData.nationalInsuranceNumber'),
      signature: hasValidSignature ? customerSignature : get(values, 'formData.signature'),
      mobileNumber: hasValidMobileNumber ? mob : get(values, 'formData.mobileNumber'),
      street: hasAStreetName ? userStreet : get(values, 'address.line1'),
      city: hasACityName ? userCity : get(values, 'address.city'),
      postCode: hasAPostalCode ? userPostalCode : formattedPostcode,
    };

    async function isUserOnLastStepOfFlow() {
      if (step === totalSteps - 2) {
        setIsSubmitLoading(true);
        await dispatchUpdatePersonalDetails(missingDetailsData);
        dispatchStatusNotificationMissingDetailsCompleted();
        dispatchMissingDetailsComplete();
        setIsSubmitLoading(false);
        setStep((_step) => _step + 1);
      }
      setStep((_step) => _step + 1);
    }

    return (
      <BaseGrid tabletTwelveColumn addPensionsTheme>
        <div className={styles.missingDetailsWrapper}>
          <div className={styles.progressContainer}>
            <ProgressStepper maxStep={totalSteps} step={step + 1} />
          </div>
          {!amendMode && (
            <ButtonLink
              onClick={(event) => {
                dispatchMissingDetailsReturnLinkClickedAction();
                event.stopPropagation();
                setStep((_step) => _step - 1);
              }}
              label="← Back"
            />
          )}
          <h1 className={styles.ninoHeading}>
            {'Add your current address'}
          </h1>
          <div className={styles.content}>
            {shouldShowContributionCopy ? 'To help us set up your contributions, please provide your current address.' : 'To help us trace your pensions, please provide your current address.'}
            {' '}
          </div>
          <br />
          {Object.keys(fieldsConfig).map((field) => {
            return !fieldsConfig[field].hidden
              && (
                <div className="field" key={fieldsConfig[field].name}>
                  {renderField(fieldsConfig[field])}
                </div>
              );
          })}
          <div className={styles.errorMessage}>{errors && this.constructErrorMessage(errors.submitError)}</div>
          {showSave && (
            <Button
              size="medium"
              type="button"
              label="Save"
              loading={isSubmitLoading}
              disabled={disableSaveButton}
              onClick={(event) => {
                dispatchMissingDetailsAddressSaveSelected();
                setFormData((_formData) => ({
                  ..._formData,
                  street: hasAStreetName ? userStreet : missingDetailsData.street,
                  city: hasACityName ? userCity : missingDetailsData.city,
                  postCode: hasAPostalCode ? userPostalCode : missingDetailsData.postCode,
                }));
                isUserOnLastStepOfFlow();
                setInEditMode(false);
              }}
            />
          )}
          {<div>&nbsp;</div>}
          <div className={styles.pensionAdviserCard}>
            <ContactUsContent
              source={'CurrentAddressStep'}
              noExpertContentWrapper={({ children }) => (
                <div
                  style={{
                    display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                  }}
                >
                  {children}
                </div>
              )}
              showButton={false}
              customMessage={(
                <div
                  className={styles.contactUsInnerContent}
                >
                  {'You can add your address history in your '}
                  <ButtonLink
                    variant="primary"
                    label="Personal Details"
                    link="/personal-details"
                    font="inherit"
                    target="_blank"
                    rel="noreferrer"
                  />
                  {' page. If you have any questions, please feel free to '}
                  <ButtonLink
                    label="message me."
                    variant="primary"
                    link="/inbox/new-message"
                    font="inherit"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => dispatchMissingDetailsMessageMeClickedAction()}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </BaseGrid>
    );
  }
}

const FormikEnhancer = withFormik({
  validate: (formValues) => {
    const valuesToValidate = {};

    // Create a new object which matches the forms object but strings are set to null.
    Object.keys(formValues).forEach((value) => {
      valuesToValidate[value] = formValues[value] || null;
    });

    // Validate the current values from the form object against the constraints
    const validationResult = validate(
      pick(valuesToValidate, Object.keys(addressConstraints)),
      addressConstraints,
    );

    if (!validationResult) {
      return {};
    }

    return convertFlattenedObjectToObject(validationResult);
  },
  displayName: 'CurrentAddressStep',
})(CurrentAddressStep);

const mapDispatchToProps = {
  dispatchMissingDetailsPostcodeDeleted: missingDetailsPostcodeDeleted,
  dispatchMissingDetailsPostcodeTyped: missingDetailsPostcodeTyped,
  dispatchMissingDetailsFindAddressClicked: missingDetailsFindAddressClicked,
  dispatchMissingDetailsAddressDropdownClicked: missingDetailsAddressDropdownClicked,
  dispatchMissingDetailsAddressSelected: missingDetailsAddressSelected,
  dispatchMissingDetailsReturnLinkClickedAction: missingDetailsReturnLinkClickedAction,
  dispatchMissingDetailsMessageMeClickedAction: missingDetailsMessageMeClickedAction,
  dispatchMissingDetailsAddressSaveSelected: missingDetailsAddressSaveSelected,
  dispatchUpdatePersonalDetails: updatePersonalDetails,
  dispatchStatusNotificationMissingDetailsCompleted: statusNotificationMissingDetailsCompleted,
  dispatchMissingDetailsComplete: missingDetailsCompleteAction,
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  signature: getHasDigitalSignature(state),
  mob: getHasMobileNumber(state),
  hasOnlyInProgressContributionInstructions: getHasOnlyInProgressContributionInstruction(state),
  hasInProgressLumpSumInstructions: getHasInProgressLumpSumInstructions(state),
});

export default compose(
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps),
)(FormikEnhancer);
