import * as Sentry from '@sentry/react';
import get from 'lodash/get';

const sentryException = (error, tags) => {
  const code = get(error, 'code');
  const errorMessage = get(error, 'message');
  Sentry.captureException(error, {
    tags: {
      ...tags,
      ...(code && { code }),
      ...(errorMessage && { errorMessage }),
      systemTime: new Date().toString(),
    },
  });
};

export default sentryException;
