import React, { useState } from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import GuaranteesAndExitPenaltiesModal from '../AddPensionsPage/PensionTransferOptions/GuaranteesAndExitPenaltiesModal';

import { onGuaranteesAndExitPenaltiesModalShow } from '../../redux/modules/pensions';

import GreenTick from '../../assets/images/icons/smallGreenTick.svg';

import { THINGS_TO_CONSIDER_URL } from '../../config';

import styles from './GettingStarted.css';

const GettingStarted = ({ dispatchOnGuaranteesAndExitPenaltiesModalClicked }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styles.gettingStartedWrapper}>
      <div className={styles.gettingStartedButtonContainer}>
        <Button
          size="large"
          label="Get Started"
          to={'/getting-to-know-you'}
          onClick={() => {}}
        />
      </div>
      <div className={styles.gettingStartedBannerContainer}>
        <h2 className={styles.gettingStartedBannerHeading}>{'£31K'}</h2>
        <p className={styles.gettingStartedBannerContent}>{'‘The average UK consumer who takes financial advice will increase their pension wealth by £30,991’*'}</p>
      </div>
      <h2 className={styles.gettingStartedHeading}>{'Benefits of finding and combing lost pensions and pension advice'}</h2>
      <div className={styles.gettingStartedMainPoints}>
        <div className={styles.gettingStartedPoint}>
          <div className={styles.gettingStartedPointHeadingContainer}>
            <img className={styles.gettingStartedPointTick} src={GreenTick} alt="Green tick" />
            <h4 className={styles.gettingStartedPointHeading}>{'Avoid expensive charges'}</h4>
          </div>
          <p className={styles.gettingStartedContent}>{'Old pensions charge on average 5 x more than you can find today, costing an estimated £23,000 extra over 20 years **'}</p>
        </div>
        <div className={styles.gettingStartedPoint}>
          <div className={styles.gettingStartedPointHeadingContainer}>
            <img className={styles.gettingStartedPointTick} src={GreenTick} alt="Green tick" />
            <h4 className={styles.gettingStartedPointHeading}>{'Improve investment choices'}</h4>
          </div>
          <p className={styles.gettingStartedContent}>{'Pensions are invested to grow and help achieve your retirement goals. Poorly invested old pensions affect 52% of UK savers, costing £13,000 over 20 years ***'}</p>
        </div>
        <div className={styles.gettingStartedPoint}>
          <div className={styles.gettingStartedPointHeadingContainer}>
            <img className={styles.gettingStartedPointTick} src={GreenTick} alt="Green tick" />
            <h4 className={styles.gettingStartedPointHeading}>{'Flexibly access your pension'}</h4>
          </div>
          <p className={styles.gettingStartedContent}>{'90% of pensions don’t offer full flexibility when you want to withdraw from your pension from 55 years old (57 by 2028****)'}</p>
        </div>
        <div className={styles.gettingStartedPoint}>
          <div className={styles.gettingStartedPointHeadingContainer}>
            <img className={styles.gettingStartedPointTick} src={GreenTick} alt="Green tick" />
            <h4 className={styles.gettingStartedPointHeading}>{'A dedicated Pension Adviser'}</h4>
          </div>
          <p className={styles.gettingStartedContent}>{'You’ll be assigned a dedicated Pension Adviser who is on hand to answer any questions you may have such as:'}</p>
          <ul className={styles.gettingStartedList}>
            <li><p className={styles.gettingStartedContent}>{'‘When can I access my pension?’'}</p></li>
            <li><p className={styles.gettingStartedContent}>{'‘How much of pension drawdown is tax-free?’'}</p></li>
            <li><p className={styles.gettingStartedContent}>{'‘What happens when I start pension drawdown?’'}</p></li>
          </ul>
        </div>
      </div>
      <div className={styles.ourServiceContainer}>
        <h2 className={styles.gettingStartedSubHeading}>{'Our service'}</h2>
        <p className={styles.gettingStartedContent}>{'We’ll track down your old pensions, check for exit penalties and guarantees and arrange the transfer for you'}</p>
        <div className={styles.ourServicePoints}>
          <div className={styles.ourServicePoint}>
            <img className={styles.ourServicePointTick} src={GreenTick} alt="Green tick" />
            <p className={styles.gettingStartedContent}>{'Find pension and detailed policy information'}</p>
          </div>
          <div className={styles.ourServicePoint}>
            <img className={styles.ourServicePointTick} src={GreenTick} alt="Green tick" />
            <p className={styles.gettingStartedContent}>
              {'Check for guarantees and exit penalties '}
              <span>
                {'('}
                <ButtonLink
                  variant="primary"
                  label="read more"
                  font={'bodyOne'}
                  onClick={() => {
                    dispatchOnGuaranteesAndExitPenaltiesModalClicked(false);
                    setShowModal(true);
                  }}
                />
                {')'}
              </span>
            </p>
          </div>
          <div className={styles.ourServicePoint}>
            <img className={styles.ourServicePointTick} src={GreenTick} alt="Green tick" />
            <p className={styles.gettingStartedContent}>{'Arrange the transfer of your pension'}</p>
          </div>
        </div>
      </div>
      <div className={styles.ourServiceContainer}>
        <h2 className={styles.gettingStartedHeading}>{'Do you know where your pensions are?'}</h2>
        <div className={styles.gettingStartedBannerContainer}>
          <h2 className={styles.gettingStartedBannerHeading}>{'£26.6bn'}</h2>
          <p className={styles.gettingStartedBannerContent}>{'‘Estimated value of lost pensions in the UK’'}</p>
          <p className={styles.gettingStartedBannerSubContent}>{'Association of British Insurers October 2022'}</p>
        </div>
        <p className={styles.gettingStartedContent}>{'We specialise in finding old, lost pensions, including SERPS, with our Find, Check and Transfer service.'}</p>
      </div>
      <h2 className={styles.gettingStartedSubHeading}>{'Suitable if'}</h2>
      <p className={styles.gettingStartedContent}>{'You want to combine your pensions into one place or to find out if you have any old SERPS or workplace pensions that you‘ve lost track of and transfer them into a modern flexible pension, with expert guidance and checks to make sure you don‘t lose any guarantees or incur big penalties.'}</p>
      <br />
      <h2 className={styles.gettingStartedSubHeading}>{'Cost'}</h2>
      <p className={styles.gettingStartedContent}>{'There’s no fee to combine or transfer pensions you already know about. If you need help tracking down old workplace or SERPS pensions or if you want us to check for any guarantees, penalties or protected benefits there’s a 1% fee of the pension value when transferred.'}</p>
      <a
        href={THINGS_TO_CONSIDER_URL}
        rel="noopener noreferrer"
        target="_blank"
        className={styles.thingsToConsiderLink}
      >
        {'Important Considerations'}
      </a>
      <br />
      <p className={styles.source}>{'Sources'}</p>
      <p className={styles.source}>{'* International Longevity Centre, Revisiting the Value of Financial Advice, November 2019'}</p>
      <p className={styles.source}>{'** 3069 pensions reviewed between January - July 2020'}</p>
      <p className={styles.source}>{'*** CFA-Institute Financial Market History 2016'}</p>
      <p className={styles.source}>{'**** 3833 pensions reviewed between January 2020 - July 2020'}</p>
      {showModal && (
        <GuaranteesAndExitPenaltiesModal
          show={showModal}
          onClose={() => {
            document.body.style.overflowY = 'auto';
            setShowModal(false);
          }}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = {
  dispatchOnGuaranteesAndExitPenaltiesModalClicked: onGuaranteesAndExitPenaltiesModalShow,
};

export default connect(null, mapDispatchToProps)(GettingStarted);
