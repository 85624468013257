exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);

// module
exports.push([module.id, ".-WhyChooseUs-whyChooseUsHeading-2EhIT {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + ";\n  margin: 0;\n}\n.-WhyChooseUs-contentContainer-2Tdsk {\n  margin-top: 15px;\n}\n.-WhyChooseUs-whyChooseUsRow-2GWHS {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin: 0;\n  -webkit-box-align: start;\n      -ms-flex-align: start;\n          align-items: flex-start;\n}\n.-WhyChooseUs-whyChooseUsRow-2GWHS:nth-child(n+2) {\n  margin-top: 15px;\n}\n.-WhyChooseUs-whyChooseUsText-1dCBG {\n  line-height: 22px;\n  margin: 0;\n  margin-left: 10px;\n}\n@media only screen and (min-width: typographyDesktop) {\n  .-WhyChooseUs-whyChooseUsText-1dCBG {\n    line-height: 24px;\n  }\n}\n.-WhyChooseUs-whyChooseUsIcon-2P3QI {\n  width: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"colors": "\"../../theme/colors.css\"",
	"textTitleRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + "",
	"typography": "\"../../theme/typography.css\"",
	"bodyOne": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"whyChooseUsHeading": "-WhyChooseUs-whyChooseUsHeading-2EhIT",
	"contentContainer": "-WhyChooseUs-contentContainer-2Tdsk",
	"whyChooseUsRow": "-WhyChooseUs-whyChooseUsRow-2GWHS",
	"whyChooseUsText": "-WhyChooseUs-whyChooseUsText-1dCBG " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"whyChooseUsIcon": "-WhyChooseUs-whyChooseUsIcon-2P3QI"
};