import aegonLogo from '../assets/images/providers/aegon.svg';
import avivaLogo from '../assets/images/providers/aviva.svg';
import lvLogo from '../assets/images/providers/lv.png';
import metlifeLogo from '../assets/images/providers/metlife.svg';
import prudentialLogo from '../assets/images/providers/prudential.svg';
import scottishWidowsLogo from '../assets/images/providers/scottish-widows.svg';

const PROVIDER_MAP = {
  aegon: { label: 'Aegon', logo: aegonLogo },
  aviva: { label: 'Aviva', logo: avivaLogo },
  lv: { label: 'LV', logo: lvLogo },
  metlife: { label: 'MetLife', logo: metlifeLogo },
  prudential: { label: 'Prudential', logo: prudentialLogo },
  'scottish-widows': { label: 'Scottish Widows', logo: scottishWidowsLogo },
};

export default PROVIDER_MAP;
