import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import ButtonLink from '../../components/ButtonLink/ButtonLink';
import RadioButtons from '../../components/RadioButtons/RadioButtons';
import HtmlSelectField from '../../components/HtmlSelectField/HtmlSelectField';
import TextField from '../../components/TextField/TextField';

import ControlPlus from '../../assets/images/icons/control-plus.svg';
import ControlMinus from '../../assets/images/icons/control-minus.svg';
import { getLatestPensionValue } from '../../redux/selectors';
import styles from './SetupWithdrawalPage.css';

import {
  withdrawalFormVisitedAction,
  withdrawalHealthIssuesChangedAction,
  withdrawalFundsUsedForChangedAction,
  withdrawalAmountChangedAction,
} from '../../redux/modules/withdrawal';

const getLabelForWithdrawalType = (type) => {
  if (type === 'lump-sum') return 'One-off lump sum';
  if (type === 'monthly') return 'Monthly';
  return ' - ';
};

const WithdrawalsDetailsFormStep = ({
  formData,
  setFormData,
  latestPensionValue,
  dispatchFormVisited,
  dispatchHealthIssuesChanged,
  dispatchFundsUsedForChanged,
  dispatchWithdrawalAmountChanged,
}) => {
  useEffect(() => {
    dispatchFormVisited();
    window.scrollTo(0, 0);
  }, []);

  const PensionWithdrawalText = () => {
    return (
      <React.Fragment>
        <div className={styles.withdrawalTypeInfoContainer}>
          {formData.withdrawalType === 'lump-sum' ? (
            <div>
              {`Where your pension is partially or entirely uncrystallised, we will use the Tax Free
             Cash element first. If you want to access your lump sum any differently, `}
              <ButtonLink
                variant="primary"
                label={'please contact your Pension Adviser'}
                font="inherit"
                to={'/inbox/new-message'}
                search={'?subject=Withdrawal%20Request'}
              />
              {'.'}
            </div>
          ) : (
            <div>
              {`Payments are usually made on 9th, 18th or 27th of the month. Allow 10
              working days from the date you request the withdrawal before the first 
              payment can be made.  Payments are made by BACS and it can take up 
              to 3 working days before the funds show in your bank account.`}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  const changeWithDrawalAmountValue = (withdrawalAmount) => {
    dispatchWithdrawalAmountChanged();
    if (withdrawalAmount > 0) {
      if ((withdrawalAmount > (2 ** 31 - 1)) || (withdrawalAmount > latestPensionValue)) {
        return;
      }
      const withdrawalAmountMask = `£${withdrawalAmount}`;
      setFormData({
        ...formData,
        withdrawalAmount,
        withdrawalAmountMask,
      });
    } else {
      setFormData({ ...formData, withdrawalAmountMask: '£0', withdrawalAmount: 0 });
    }
  };

  const fundsUsedForOptions = [
    { value: 'income', label: 'Income' },
    { value: 'pay-off-mortgage-dept', label: 'Pay off mortgage/debt' },
    { value: 'home-improvements', label: 'Home improvements' },
    { value: 'to-invest-elsewhere', label: 'To invest elsewhere' },
    { value: 'other', label: 'Other (please specify)' },
  ];

  const [otherTextValue, setOtherTextValue] = useState('');
  return (
    <React.Fragment>
      <h1 className={styles.heading}>
        {formData.withdrawalType === 'lump-sum' ? 'How much money are you looking to take from your pension?'
          : 'How much do you want to take as a monthly amount?'}
      </h1>
      <div className={styles.withdrawalAmountComponent}>
        <PensionWithdrawalText />
        <div className={styles.withdrawalAmountLabel} />
        <div className={styles.withdrawalAmountContainer}>
          <button
            className={styles.withdrawalAmountControlMinus}
            type="button"
            onClick={() => changeWithDrawalAmountValue(formData.withdrawalAmount - 10)}
          >
            <img src={ControlMinus} alt="Decrease withdrawal amount" />
          </button>
          <input
            style={{ ...(get(formData, 'withdrawalAmount.length') > 6 && { width: 'min-content' }) }}
            className={styles.withdrawalAmountInput}
            value={formData.withdrawalAmountMask}
            max={latestPensionValue}
            onChange={(event) => {
              const strippedInput = event.target.value.replace(/\D/g, '');
              const withdrawalAmount = parseInt(strippedInput, 10);
              changeWithDrawalAmountValue(withdrawalAmount);
            }}
          />
          <button
            className={styles.withdrawalAmountControlPlus}
            type="button"
            onClick={() => changeWithDrawalAmountValue(formData.withdrawalAmount + 10)}
          >
            <img src={ControlPlus} alt="Increase withdrawal amount" />
          </button>
        </div>
        <div className={styles.withdrawalInstructions}>{'Select digits to manually input the amount'}</div>

        <div className={styles.withdrawalDetailLine}>
          <div className={styles.body}>{'Your withdrawal'}</div>
          <div className={styles.withdrawalFigure}>{formData.withdrawalAmountMask}</div>
        </div>
        <div className={styles.withdrawalLineDivider} />
        <div className={styles.withdrawalDetailLine}>
          <div className={styles.body}>{'Withdrawal type'}</div>
          <div className={styles.withdrawalFigure}>
            {getLabelForWithdrawalType(formData.withdrawalType)}
          </div>
        </div>
        <div className={styles.withdrawalLineDivider} />

        <div className={styles.withdrawalTypeInfoContainer}>
          {`Any withdrawals other than those that form part of your Tax Free Cash may be subject
             to tax at your marginal rate. You should take this into consideration when selecting
              the amount you require and should input the gross amount (amount before tax). 
              If you need help with this, `}
          <ButtonLink
            variant="primary"
            label={'please contact your Pension Adviser'}
            font="inherit"
            to={'/inbox/new-message'}
            search={'?subject=Withdrawal%20Request'}
          />
          {'.'}
        </div>
      </div>
      <HtmlSelectField
        fullWidth
        wrapperStyles={{ margin: '0 0 15px' }}
        value={formData.fundsUsedForSelectedOption}
        options={fundsUsedForOptions}
        placeholder={'Select'}
        label={'What are you using the money for?'}
        onChange={(event) => {
          dispatchFundsUsedForChanged();
          const selectedValue = event.target.value;
          if (selectedValue === 'other') {
            setFormData({
              ...formData,
              fundsUsedForSelectedOption: selectedValue,
              fundsUsedFor: otherTextValue,
            });
          } else {
            setFormData({
              ...formData,
              fundsUsedForSelectedOption: selectedValue,
              fundsUsedFor: event.target.options[event.target.selectedIndex].innerText,
            });
          }
        }}
      />
      {formData.fundsUsedForSelectedOption === 'other' && (
        <React.Fragment>
          <TextField
            tallInput
            wrapperStyles={{ margin: '-10px 0 15px 0' }}
            inputWrapperStyles={{ marginBottom: '20px', maxWidth: 'none' }}
            name="otherFundsUsedFor"
            value={formData.fundsUsedFor}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setFormData({ ...formData, fundsUsedFor: selectedValue });
              setOtherTextValue(selectedValue);
            }}
            defaultValue={'Please state what you are using the money for'}
          />
        </React.Fragment>
      )}
      <RadioButtons
        name="healthIssues"
        containerStyles={{ margin: '0 0 27px' }}
        label="Do you have any health issues that may impact on your life expectancy?"
        buttons={[
          {
            label: 'Yes',
            value: true,
            checked: formData.healthIssues === true,
          },
          {
            label: 'No',
            value: false,
            checked: formData.healthIssues === false,
          },
        ]}
        largeHeadings={false}
        onChange={(value) => {
          dispatchHealthIssuesChanged();
          setFormData({ ...formData, healthIssues: value });
        }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  latestPensionValue: getLatestPensionValue(state),
});

const mapDispatchToProps = {
  dispatchFormVisited: withdrawalFormVisitedAction,
  dispatchHealthIssuesChanged: withdrawalHealthIssuesChangedAction,
  dispatchFundsUsedForChanged: withdrawalFundsUsedForChangedAction,
  dispatchWithdrawalAmountChanged: withdrawalAmountChangedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalsDetailsFormStep);
