import React, { useEffect, useState } from 'react';
import once from 'lodash/once';
import { connect } from 'react-redux';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import Button from '../../components/Button/Button';
import MentionMeTile from '../../components/MentionMeTile/MentionMeTile';
import WithMentionMe from '../../components/WithMentionMe/WithMentionMe';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import {
  serpsPensionAuthorityThankYouPageAddContributionClickedAction,
  serpsPensionAuthorityThankYouPageAddPensionClickedAction,
  serpsPensionAuthorityThankYouPageLoaded,
  serpsPensionAuthorityThankYouPageViewDashboardClickedAction,
} from '../../redux/modules/serps';

import { getIsCustomerOngoing } from '../../redux/selectors/experience';

import { MENTION_ME_LINK_CLICKED } from '../../redux/modules/dashboard';

import missingDetailsSubmitted from '../../assets/images/missing-details-submitted.svg';
import AddPensions from '../../assets/images/rebranding/tri-tone-overlapping-circles.svg';
import styles from './ThankYou.css';
import { SHOW_MENTION_ME } from '../../util/constants';

const ThankYouStep = ({
  dispatchSerpsPensionAuthorityThankYouPageAddContributionClicked,
  dispatchSerpsPensionAuthorityThankYouPageAddPensionClicked,
  dispatchSerpsPensionAuthorityThankYouPageLoaded: _dispatchThankYouPageLanded,
  dispatchSerpsPensionAuthorityThankYouPageViewDashboardClicked,
  mentionMeLinkClicked,
  isCustomerOngoing,
}) => {
  const [dispatchSerpsPensionAuthorityThankYouPageLoaded] = useState(() => once(_dispatchThankYouPageLanded || ''));

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchSerpsPensionAuthorityThankYouPageLoaded();
  }, []);

  return (
    <BaseGrid>
      <WithMentionMe>
        <div className={styles.serpsThanksWrapper}>
          <div className={styles.submitted}>
            <img src={missingDetailsSubmitted} alt="Serps Auth Submitted" className={styles.thankYouImage} />
          </div>
          <div>
            <h1 className={styles.thankYouHeading}>
              {'Thank you, your SERPS Find, Check & Transfer request has been received'}
            </h1>
            <div className={styles.next}>
              {'What happens next?'}
            </div>
            <div className={styles.content}>
              <div className={styles.contentPoint}>{'1'}</div>
              {`We’ll locate the policy that HMRC identified and we’ll check for any benefits or penalties. Then we will start the transfer into your ${isCustomerOngoing ? '' : 'new '}personalised pension plan.`}
            </div>
            <div className={styles.content}>
              <div className={styles.contentPoint}>{'2'}</div>
              {'Take other steps that could increase your income at retirement - you can find and combine other pensions into your new pension plan or set up regular or one-off contributions.'}
            </div>
          </div>
          <div className={styles.pensionAdviserCard}>
            <div className={styles.tagDisplay}>
              <img alt="add pensions" src={AddPensions} className={styles.imageMargin} />
              <div>{'Find & combine old pensions'}</div>
            </div>
            <div>
              <div className={styles.cardContents}>{'Do you have other pensions you’d like us to Find & combine.'}</div>
            </div>
            <Button
              wrapperStyles={{ margin: '0' }}
              label={'Add pensions'}
              onClick={dispatchSerpsPensionAuthorityThankYouPageAddPensionClicked}
              to={'/add-pensions'}
              center
              greyDisabled
              fullWidth
            />
          </div>
          <div className={styles.pensionAdviserCard}>
            <div className={styles.tagDisplay}>
              <img alt="set up contributions" className={styles.imageMargin} src={AddPensions} />
              <div>{'Make a contribution'}</div>
            </div>
            <div className={styles.cardContents}>{'Set up one-off or regular pension contributions.'}</div>
            <Button
              size="large"
              label={'Add money'}
              onClick={dispatchSerpsPensionAuthorityThankYouPageAddContributionClicked}
              to={'/add-money-option'}
            />
          </div>
          {SHOW_MENTION_ME && (
            <div className={styles.mentionMeContainer}>
              <div className={styles.mentionMeCard}>
                <MentionMeTile />
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  id="mmWrapper"
                  className="setup-contribution-mm"
                  onClick={mentionMeLinkClicked}
                />
              </div>
            </div>
          )}
          <ButtonLink
            label={<span className={styles.employerLinkText}>{'Return to dashboard'}</span>}
            to="/"
            onClick={() => {
              dispatchSerpsPensionAuthorityThankYouPageViewDashboardClicked();
            }}
          />
        </div>
      </WithMentionMe>
    </BaseGrid>
  );
};

const mapDispatchToProps = {
  dispatchSerpsPensionAuthorityThankYouPageAddContributionClicked:
    serpsPensionAuthorityThankYouPageAddContributionClickedAction,
  dispatchSerpsPensionAuthorityThankYouPageAddPensionClicked:
    serpsPensionAuthorityThankYouPageAddPensionClickedAction,
  dispatchSerpsPensionAuthorityThankYouPageLoaded:
    serpsPensionAuthorityThankYouPageLoaded,
  dispatchSerpsPensionAuthorityThankYouPageViewDashboardClicked:
    serpsPensionAuthorityThankYouPageViewDashboardClickedAction,
  mentionMeLinkClicked: () => ({ type: MENTION_ME_LINK_CLICKED }),
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThankYouStep);
