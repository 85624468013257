import get from 'lodash/get';

export const ENVIRONMENT = process.env.DEPLOYED_ENVIRONMENT;
export const AUTH_ENVIRONMENT = process.env.ACCOUNT_HUB_AUTH_ENVIRONMENT;

export const FB_CONFIG = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: `${process.env.PROJECT_ID}.firebaseapp.com`,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.ACCOUNT_HUB_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_ID,
  appId: process.env.FIREBASE_APP_ID,
};

export const CURRENCY = 'GBP';
export const DEFAULT_CONTACT_NUMBER = '+441772804404';
export const DEFAULT_CONTACT_EMAIL = 'contact@profilepensions.co.uk';

export const ACCOUNT_HUB_ATTACHMENTS_BUCKET = process.env.ACCOUNT_HUB_ATTACHMENTS_BUCKET || 'gs://profile-account-hub-develop-attachments';

export const CLICKWRAP_CONFIG = {
  accountId: process.env.DEPLOYED_ENVIRONMENT === 'production' ? '1413249c-417c-45bf-88ed-7b0360263200' : '5eae2137-7db3-4cf4-85e1-495ee1c7347f',
  fundSwitchId: process.env.DEPLOYED_ENVIRONMENT === 'production' ? 'd9b0f8ef-ed03-4d9b-9cd8-2d6651b5eefa' : '244d081a-1ad4-4c27-aacd-293b8738c516',
  removeLifestylingId: process.env.DEPLOYED_ENVIRONMENT === 'production' ? '962ef66b-3a46-4a9c-bccd-3d5ff65cfb9b' : 'f731ed2d-82bf-42b5-846a-b639360108a0',
  pensionTransfer: process.env.DEPLOYED_ENVIRONMENT === 'production' ? '25ce95fd-0bc1-4ce8-8b2b-2b676fee5996' : 'fe177c77-714b-4d6a-9399-12ed42456a57',
};

export const THINGS_TO_CONSIDER_URL = process.env.DEPLOYED_ENVIRONMENT === 'production' ? 'https://profilepensions.co.uk/things-to-consider-when-transferring-old-pensions' : 'https://staging.profilepensions.co.uk/things-to-consider-when-transferring-old-pensions';

export const TAX_RELIEF_URL = process.env.DEPLOYED_ENVIRONMENT === 'production' ? 'https://www.profilepensions.co.uk/pension-contributions-and-tax-relief' : 'https://staging.profilepensions.co.uk/pension-contributions-and-tax-relief';

export const MENTIONME_CONFIG = {
  referrerTagUrl: process.env.DEPLOYED_ENVIRONMENT === 'production' ? 'tag.mention-me.com' : 'tag-demo.mention-me.com',
  key: process.env.MENTION_ME_KEY,
};

const onboardingPensionExperts = [
  {
    value: 'ashleigh.ramsbottom@moneyfarm.com',
    calendlyUrl: 'https://calendly.com/profile-service-team-self-booking/arrange-an-appointment-with-ashleigh-ramsbottom',
  },
  {
    value: 'olivia.craig@moneyfarm.com',
    calendlyUrl: 'https://calendly.com/profile-service-team-self-booking/arrange-an-appointment-with-olivia-craig',
  },
  {
    value: 'paul.hepplestone@moneyfarm.com',
    calendlyUrl: 'https://calendly.com/profile-service-team-self-booking/arrange-an-appointment-with-paul-hepplestone',
  },
  {
    value: 'josefine.jonsson@moneyfarm.com',
    calendlyUrl: 'https://calendly.com/profile-service-team-self-booking/arrange-an-appointment-with-josefine-jonsson',
  },
  {
    value: 'samantha.packham@moneyfarm.com',
    calendlyUrl: 'https://calendly.com/profile-service-team-self-booking/arrange-an-appointment-with-sam-packham',
  },
  {
    value: 'richard.penney@moneyfarm.com',
    calendlyUrl: 'https://calendly.com/profile-service-team-self-booking/arrange-an-appointment-with-richard-penney',
  },
];

export const withdrawalCalendlyUrl = (pensionExpert) => {
  let calendlyUrl = '';
  const userExpert = onboardingPensionExperts.find((e) => e.value === pensionExpert.email) || '';

  switch (ENVIRONMENT) {
    case 'staging':
      calendlyUrl = 'https://calendly.com/profile-testing/staging-arrange-an-appointment-with-adviser1';
      break;
    case 'production':
      calendlyUrl = get(userExpert, 'calendlyUrl', '');
      break;
    default:
      calendlyUrl = 'https://calendly.com/profile-testing/dev-arrange-an-appointment-with-adviser1';
  }

  return calendlyUrl;
};

const transferCalendlyUrl = {
  production: 'https://calendly.com/profile-pension-team-self-booking/pension-review-self-booking',
  staging: 'https://calendly.com/profile-testing/staging-pension-review-self-booking',
  develop: 'https://calendly.com/profile-testing/develop-pension-review-self-booking',
};

const ongoingCalendlyUrl = {
  production: 'https://calendly.com/profile-service-team-self-booking/periodic-adviser-review-phone-call',
  staging: 'https://calendly.com/profile-testing/staging-periodic-adviser-review-phone-call',
  develop: 'https://calendly.com/profile-testing/develop-periodic-adviser-review-phone-call',
};

export const CALENDLY_URL = transferCalendlyUrl[process.env.DEPLOYED_ENVIRONMENT]
  || transferCalendlyUrl.develop;
export const ONGOING_CALENDLY_URL = ongoingCalendlyUrl[process.env.DEPLOYED_ENVIRONMENT]
  || ongoingCalendlyUrl.develop;

let signUpApiUrl;

if (process.env.DEPLOYED_ENVIRONMENT === 'local') {
  signUpApiUrl = '/api';
} else if (process.env.DEPLOYED_ENVIRONMENT === 'production') {
  signUpApiUrl = 'https://signup.profilepensions.co.uk/api';
} else {
  signUpApiUrl = 'https://signup-test.profilepensions.co.uk/api';
}

export const SIGNUP_API = signUpApiUrl;

// Check the token every 20 (default) seconds
export const SESSION_DETECTION_FREQUENCY = (process.env.SESSION_DETECTION_FREQUENCY
  ? parseInt(process.env.SESSION_DETECTION_FREQUENCY, 10) : 20) * 1000; // convert to milliseconds

// Show the modal when there is 120 (default) seconds remaining on their token
export const SHOW_SESSION_MODAL_AT_TIME = process.env.SHOW_SESSION_MODAL_AT_TIME
  ? parseInt(process.env.SHOW_SESSION_MODAL_AT_TIME, 10) : 120; // seconds

// Logs the user out with 30 (default) seconds remaining on their token
export const AUTO_LOG_USER_OUT_AT_TIME = process.env.AUTO_LOG_USER_OUT_AT_TIME
  ? parseInt(process.env.AUTO_LOG_USER_OUT_AT_TIME, 10) : 30; // seconds

const signupApiUrl = {
  production: 'https://europe-west1-profile-account-hub.cloudfunctions.net/createAccount',
  staging: 'https://europe-west1-profile-account-hub-staging.cloudfunctions.net/createAccount',
  develop: 'https://europe-west1-profile-account-hub-develop.cloudfunctions.net/createAccount',
};

export const SIGNUP_API_URL = signupApiUrl[process.env.DEPLOYED_ENVIRONMENT]
  || signupApiUrl.develop;

export const TRUSTPILOT_URL = process.env.DEPLOYED_ENVIRONMENT === 'production' ? 'https://www.profilepensions.co.uk/trustpilot-data' : 'https://staging.profilepensions.co.uk/trustpilot-data';
