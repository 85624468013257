import React from 'react';
import { connect } from 'react-redux';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { setNotificationCompletePensionOrContributionRequestDismissed, COMPLETE_ADD_CONTRIBUTION_REQUEST_DISMISSED } from '../../redux/modules/notificationTracking';
import { getFirebaseUid } from '../../redux/selectors';

const AddContributionRequestNotification = ({
  uid,
  dispatchSetNotificationCompleteContributionRequestDismissed,
}) => {
  return (
    <NotificationBanner
      alertCategory={'Blue'}
      alertTitle={'Complete your request'}
      alertDescription={'You started a request to set up making pension contributions, complete your request today to give yourself a brighter future at retirement.'}
      ctaButtonOptions={{
        label: 'Add money',
        to: '/setup-contribution',
        event: COMPLETE_ADD_CONTRIBUTION_REQUEST_DISMISSED,
        handleClick: () => {
          dispatchSetNotificationCompleteContributionRequestDismissed(uid, 'contribution');
        },
      }}
      closeButtonOptions={{
        event: COMPLETE_ADD_CONTRIBUTION_REQUEST_DISMISSED,
        handleClick: () => dispatchSetNotificationCompleteContributionRequestDismissed(uid, 'contribution'),
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
});

const mapDispatchToProps = ({
  dispatchSetNotificationCompleteContributionRequestDismissed:
    setNotificationCompletePensionOrContributionRequestDismissed,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddContributionRequestNotification);
