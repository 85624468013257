import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import HtmlSelectField from '../../components/HtmlSelectField/HtmlSelectField';
import styles from './AddPensionsPage.css';
import {
  getProviderNames, getProviderStatuses,
} from '../../redux/selectors';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import { getPensionExpert } from '../../redux/selectors/investmentAdvice';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import { transferOptions } from '../../util/constants';
import {
  returnLinkClickedAction,
  messageMeClickedAction,
  pensionProviderStepLandedAction,
  pensionProviderSubmitClickedAction,
  unknownProviderLinkClickedAction,
  inScopeProviderSelectedAction,
  outOfScopeProviderSelectedAction,
  otherProviderSelectedAction,
  tryFindAndCombineServiceClickedAction,
} from '../../redux/modules/pensions';

const OTHER_PROVIDER = 'Other';

const PensionProviderStep = ({
  setProviderOtherFlow,
  setEmployerFlow,
  providers,
  providerStatuses,
  setFormData,
  setStep,
  amendMode,
  step,
  totalSteps,
  dispatchPensionProviderStepLanded,
  isCustomerOngoing,
  dispatchReturnLinkClicked,
  dispatchMessageMeClicked,
  dispatchPensionProviderSubmitClicked,
  dispatchUnknownProviderLinkClicked,
  dispatchInScopeProviderSelected,
  dispatchOutOfScopeProviderSelected,
  dispatchOtherProviderSelected,
  dispatchTryFindAndCombineServiceClicked,
  formData,
  hideBackNavigation,
  resetFormData,
}) => {
  const defaultValue = (!formData.freeTextProvider && formData.commonName)
    ? formData.commonName : undefined;
  const [selectedProvider, setSelectedProvider] = useState(defaultValue);
  const isOtherSelected = selectedProvider === OTHER_PROVIDER;

  const isTransferBasic = formData.transferOption === transferOptions.TRANSFER_BASIC;
  const isTransferWithCheck = formData.transferOption === transferOptions.TRANSFER_WITH_CHECK;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchPensionProviderStepLanded(isCustomerOngoing, formData.transferOption);
  }, []);

  const longestProviderName = providers.reduce(
    (previous, current) => {
      return current.length > previous.length ? current : previous;
    },
    providers[0],
  );

  const divider = Array(longestProviderName.length).fill('-').join('');

  const providerSelectOptions = providers
    .sort()
    .map((provider) => ({ value: provider, label: provider }))
    .concat({ value: divider, label: divider })
    .concat({ value: OTHER_PROVIDER, label: OTHER_PROVIDER });

  const isProviderOutOfScope = (provider) => providerStatuses[provider.replace(/\s+/g, '')] === 2;
  const isSelectedProviderOutOfScope = selectedProvider && isProviderOutOfScope(selectedProvider);
  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.providerStepWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {(!amendMode && !hideBackNavigation) && (
          <ButtonLink
            onClick={(event) => {
              dispatchReturnLinkClicked(isCustomerOngoing, formData.transferOption);
              setStep((_step) => _step - 1);
              event.stopPropagation();
            }}
            label="← Back"
          />
        )}
        <h2 className={styles.pensionProviderHeading}>
          {'Who is the pension provider?'}
          {isTransferBasic && ('*')}
        </h2>
        <p className={styles.pensionProviderBody}>
          {'If your provider is not listed, select ‘'}
          <b>{'Other'}</b>
          {'’.'}
        </p>
        <div
          className={styles.providerSelectWrapper}
        >
          <HtmlSelectField
            fieldStatusWrapper={{ margin: 0 }}
            blockWrapper={{ margin: 0 }}
            label={''}
            value={selectedProvider}
            options={providerSelectOptions}
            placeholder={'Select provider'}
            fullWidth
            onChange={(event) => {
              const selectedValue = event.target.value;
              if (selectedValue === divider) {
                setSelectedProvider(undefined);
                return;
              }
              setSelectedProvider(selectedValue);

              const otherSelected = selectedValue === OTHER_PROVIDER;

              if (otherSelected) {
                dispatchOtherProviderSelected(isCustomerOngoing, formData.transferOption);
                return;
              }
              const providerOutOfScope = isProviderOutOfScope(selectedValue);

              if (providerOutOfScope) {
                dispatchOutOfScopeProviderSelected(isCustomerOngoing, formData.transferOption);
                return;
              }
              dispatchInScopeProviderSelected(isCustomerOngoing, formData.transferOption);
            }}
            touched
            wrapperStyles={{ margin: '0' }}
          />
        </div>
        {isSelectedProviderOutOfScope && (
          <p className={styles.providerError}>{'Sorry, we are unable to transfer pensions from the selected provider. This may be because they offer defined or safeguarded benefits.'}</p>
        )}
        <div
          className={styles.submitContainer}
        >
          <div
            className={styles.providerSubmitButtonWrapper}
          >
            <Button
              size="large"
              disabled={!selectedProvider || isSelectedProviderOutOfScope}
              label={(amendMode) ? 'Confirm' : 'Submit'}
              onClick={() => {
                dispatchPensionProviderSubmitClicked(isCustomerOngoing, formData.transferOption);
                if (isOtherSelected) {
                  setFormData((_formData) => ({
                    ..._formData,
                    commonName: '',
                  }));
                  setProviderOtherFlow();
                  return;
                }

                setFormData((_formData) => ({
                  ..._formData,
                  commonName: selectedProvider,
                }));
                setStep((_step) => _step + 1);
              }}
            />
          </div>
          { isTransferBasic && (
          <ButtonLink
            label={(
              <div>
                <span className={classnames(styles.employerLinkText, styles.inlineBlock)}>{'Don’t have this information?'}</span>
                <span className={classnames(styles.employerLinkText, styles.inlineBlock)}>{'Try our “find, check and transfer” service'}</span>
              </div>
            )}
            onClick={() => {
              dispatchTryFindAndCombineServiceClicked(isCustomerOngoing, formData.transferOption);
              resetFormData();
              setStep(0);
            }}
          />
          )}
          { isTransferWithCheck && (
          <ButtonLink
            variant="primary"
            label={<span className={styles.employerLinkText}>{'I don’t know my pension provider'}</span>}
            onClick={() => {
              dispatchUnknownProviderLinkClicked(isCustomerOngoing, formData.transferOption);
              setEmployerFlow();
            }}
          />
          )}
        </div>
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'PensionProviderStep'}
            noExpertContentWrapper={({ children }) => (
              <div
                style={{
                  display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                }}
              >
                {children}
              </div>
            )}
            showButton={false}
            customMessage={(
              <div
                className={styles.contactUsInnerContent}
              >
                {'If you have any questions, please feel free to '}
                <ButtonLink
                  label="message me"
                  variant="primary"
                  to="/inbox/new-message"
                  font="inherit"
                  onClick={() => dispatchMessageMeClicked(
                    isCustomerOngoing,
                    formData.transferOption,
                  )}
                />
                {'.'}
              </div>
              )}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  providers: getProviderNames(state),
  providerStatuses: getProviderStatuses(state),
  pensionAdviser: getPensionExpert(state),
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  dispatchReturnLinkClicked: returnLinkClickedAction,
  dispatchMessageMeClicked: messageMeClickedAction,
  dispatchPensionProviderStepLanded: pensionProviderStepLandedAction,
  dispatchPensionProviderSubmitClicked: pensionProviderSubmitClickedAction,
  dispatchUnknownProviderLinkClicked: unknownProviderLinkClickedAction,
  dispatchInScopeProviderSelected: inScopeProviderSelectedAction,
  dispatchOutOfScopeProviderSelected: outOfScopeProviderSelectedAction,
  dispatchOtherProviderSelected: otherProviderSelectedAction,
  dispatchTryFindAndCombineServiceClicked: tryFindAndCombineServiceClickedAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PensionProviderStep);
