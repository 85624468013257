import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube, FaInstagram } from 'react-icons/fa';
import React from 'react';
import styles from './FooterSocialLinks.css';


const FooterSocialLinks = () => (
  <div className={styles.wrapper}>
    <div className={styles.links}>
      <a className={`${styles.icon} social fb`} href="https://www.facebook.com/ProfilePensions/" rel="noopener noreferrer" target="_blank" >
        <FaFacebookF />
      </a>
      <a className={`${styles.icon} social tw`} href="https://twitter.com/profilepensions" rel="noopener noreferrer" target="_blank" >
        <FaTwitter />
      </a>
      <a className={`${styles.icon} social li`} href="https://www.linkedin.com/company/profile-financial-solutions-ltd/" rel="noopener noreferrer" target="_blank" >
        <FaLinkedinIn />
      </a>
      <a className={`${styles.icon} social yt`} href="https://www.youtube.com/channel/UCKHQgf2MbLUDhI5mJztgcpA/" rel="noopener noreferrer" target="_blank" >
        <FaYoutube />
      </a>
      <a className={`${styles.icon} social in`} href="https://www.instagram.com/profilepensions/" rel="noopener noreferrer" target="_blank" >
        <FaInstagram />
      </a>
    </div>
  </div>
);

export default FooterSocialLinks;
