exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/base.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/shared.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../InboxPage/InboxPage.css"), undefined);

// module
exports.push([module.id, "@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["typographyDesktop"] + ") {\n  .-SendMessageSection-inboxLinkWrapper-12Nfd {\n    display: none;\n  }\n}\n.-SendMessageSection-fieldHeading-3jswY {\n  margin: 0;\n}\n.-SendMessageSection-buttonWrapper-Muger button {\n  width: 147px;\n}\n.-SendMessageSection-buttonWrapper-Muger {\n  position: static;\n  display: block;\n  border: none;\n  -webkit-box-shadow: none;\n          box-shadow: none;\n  -webkit-box-shadow: initial;\n          box-shadow: initial;\n  padding: 0;\n  margin: 0 0 40px;\n}\n.-SendMessageSection-innerWrapper-1KILS {\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../InboxPage/InboxPage.css").locals["inboxTwoColumnThreshold"] + ") {\n  .-SendMessageSection-heading-251JZ {\n    margin-top: 0;\n  }\n  \n  .-SendMessageSection-inboxLinkWrapper-12Nfd {\n    display: none;\n  }\n\n  .-SendMessageSection-buttonWrapper-Muger {\n    background-color: #FFFFFF;\n  }\n}\n.-SendMessageSection-error-2N7BH {\n  color: red;\n  margin: 10px 0;\n}", ""]);

// exports
exports.locals = {
	"colors": "\"../../theme/colors.css\"",
	"white": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["white"] + "",
	"primary": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["primary"] + "",
	"dimensions": "\"../../theme/dimensions.css\"",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"desktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["desktop"] + "",
	"gutter": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["gutter"] + "",
	"gutterLarge": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["gutterLarge"] + "",
	"typographyDesktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["typographyDesktop"] + "",
	"typography": "\"../../theme/typography.css\"",
	"h3": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h3"] + "",
	"h5": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h5"] + "",
	"h6": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h6"] + "",
	"bodyTwo": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyTwo"] + "",
	"container": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/base.css").locals["container"] + "",
	"shared": "\"../../theme/shared.css\"",
	"buttonOverlay": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/shared.css").locals["buttonOverlay"] + "",
	"inboxStyles": "\"../InboxPage/InboxPage.css\"",
	"inboxTwoColumnThreshold": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../InboxPage/InboxPage.css").locals["inboxTwoColumnThreshold"] + "",
	"rightInnerWrapper": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../InboxPage/InboxPage.css").locals["rightInnerWrapper"] + "",
	"mobileWrapperPadding": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../InboxPage/InboxPage.css").locals["mobileWrapperPadding"] + "",
	"inboxLinkWrapper": "-SendMessageSection-inboxLinkWrapper-12Nfd",
	"fieldHeading": "-SendMessageSection-fieldHeading-3jswY " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h5"] + "",
	"buttonWrapper": "-SendMessageSection-buttonWrapper-Muger",
	"innerWrapper": "-SendMessageSection-innerWrapper-1KILS " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../InboxPage/InboxPage.css").locals["rightInnerWrapper"] + "",
	"heading": "-SendMessageSection-heading-251JZ",
	"error": "-SendMessageSection-error-2N7BH " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyTwo"] + ""
};