exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-PensionAdviserWidget-pensionAdviserCard-1hyfp {\n  background-color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["white"] + ";\n  border-radius: 10px;\n  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);\n          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);\n  padding: 15px;\n  height: 100%;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n}\n.-PensionAdviserWidget-heading-3Mzjr {\n  margin: 0;\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + ";\n}\n.-PensionAdviserWidget-contentContainer-1IbTz {\n  margin-top: 15px;\n}\n.-PensionAdviserWidget-adviserProfileContainer-2eLYt {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.-PensionAdviserWidget-adviserName-t1sTD {\n  margin: 0;\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + ";\n}\n.-PensionAdviserWidget-adviserImage-1TnYX {\n  width: 70px;\n  height: 70px;\n  margin-right: 15px;\n}\n.-PensionAdviserWidget-adviserMessage-h_ylw {\n  line-height: 22px;\n  margin: 15px 0 0 0;\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["typographyDesktop"] + ") {\n  .-PensionAdviserWidget-adviserMessage-h_ylw {\n    line-height: 24px;\n  }\n}", ""]);

// exports
exports.locals = {
	"dimensions": "\"../../theme/dimensions.css\"",
	"gutter": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["gutter"] + "",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"desktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["desktop"] + "",
	"typographyDesktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["typographyDesktop"] + "",
	"typography": "\"../../theme/typography.css\"",
	"h2": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h2"] + "",
	"bodyOne": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"colors": "\"../../theme/colors.css\"",
	"textTitleRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + "",
	"white": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["white"] + "",
	"contentMargin": "15px",
	"pensionAdviserCard": "-PensionAdviserWidget-pensionAdviserCard-1hyfp",
	"heading": "-PensionAdviserWidget-heading-3Mzjr",
	"contentContainer": "-PensionAdviserWidget-contentContainer-1IbTz",
	"adviserProfileContainer": "-PensionAdviserWidget-adviserProfileContainer-2eLYt",
	"adviserName": "-PensionAdviserWidget-adviserName-t1sTD",
	"adviserImage": "-PensionAdviserWidget-adviserImage-1TnYX",
	"adviserMessage": "-PensionAdviserWidget-adviserMessage-h_ylw " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + ""
};