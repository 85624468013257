import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';

import WithdrawalOptions from './WithdrawalOptions';
import WithdrawalAmount from './WithdrawalAmount';
import WithdrawalReason from './WithdrawalReason';
import WithdrawalOtherOption from './WithdrawalsOtherOptions';
import WithdrawalInfluenced from './WithdrawalInfluenced';
import WithdrawalAccountDetails from './WithdrawalAccountDetails';
import WithdrawalReviewDetails from './WithdrawalReviewDetails';
import WithdrawalRequestComplete from './WithdrawalRequestComplete';
import WithdrawalResultsStep from './WithdrawalResults';

import {
  getLatestPensionValue,
  getAuthoritativeRetirementPlan,
  getCanFetchEstimatedIncome,
} from '../../redux/selectors';
import { getIsWithdrawalPagesAllowed } from '../../redux/selectors/journeys';

import {
  JOURNEYS,
  ONE_OFF_WITHDRAWAL_FLOW,
  ONE_OFF_WITHDRAWAL_FLOW_NO_WHAT_IF,
  REGULAR_WITHDRAWAL_FLOW,
  WITHDRAWAL_OPTIONS,
  WITHDRAWAL_AMOUNT,
  WITHDRAWAL_REASON,
  WITHDRAWAL_RESULTS_STEP,
  WITHDRAWAL_OTHER_OPTIONS,
  WITHDRAWAL_INFLUENCED,
  WITHDRAWAL_ACCOUNT_DETAILS,
  WITHDRAWAL_REVIEW_DETAILS,
  WITHDRAWAL_REQUEST_COMPLETE,
} from './flows';

const WITHDRAWAL_TYPE = {
  [ONE_OFF_WITHDRAWAL_FLOW]: 'lump-sum',
  [ONE_OFF_WITHDRAWAL_FLOW_NO_WHAT_IF]: 'lump-sum',
  [REGULAR_WITHDRAWAL_FLOW]: 'monthly',
};

const WithdrawalPage = ({
  history,
  latestPensionValue,
  canFetchEstimatedIncome,
}) => {
  const [currentJourney, setCurrentJourney] = useState(canFetchEstimatedIncome
    ? ONE_OFF_WITHDRAWAL_FLOW
    : ONE_OFF_WITHDRAWAL_FLOW_NO_WHAT_IF);

  const [formData, setFormData] = useState({
    withdrawalAmount: 0,
    withdrawalAmountMask: '£0',
    withdrawalType: WITHDRAWAL_TYPE[currentJourney],
    balanceAtRequestTime: latestPensionValue,
    fundsUsedFor: null,
    fundsUsedForSelectedOption: null,
    accountNumber: null,
    bankName: null,
    name: null,
    sortCode: null,
    rollNo: '',
    confirmWithdrawal: false,
    understandWithdrawal: false,
    healthIssues: false,
  });
  const [totalSteps, setTotalSteps] = useState(JOURNEYS[currentJourney].length);
  const [step, setStep] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    setTotalSteps(JOURNEYS[currentJourney].length);
  }, [currentJourney]);

  const setStepWithName = (name) => {
    if (!name) return;

    const stepIndex = JOURNEYS[currentJourney].findIndex((element) => element === name);

    if (Number.isInteger(stepIndex)) {
      setStep(stepIndex);
    }
  };

  const hideBackNavigation = get(history, 'location.state.hideBackNavigation');

  const stepsMap = {
    [WITHDRAWAL_OPTIONS]: (
      <WithdrawalOptions
        setStep={setStep}
        setCurrentJourney={setCurrentJourney}
        hideBackNavigation={hideBackNavigation}
      />
    ),
    [WITHDRAWAL_AMOUNT]: (
      <WithdrawalAmount
        step={step}
        totalSteps={totalSteps}
        setStep={setStep}
        formData={formData}
        setFormData={setFormData}
      />
    ),
    [WITHDRAWAL_REASON]: (
      <WithdrawalReason
        step={step}
        totalSteps={totalSteps}
        setStep={setStep}
        formData={formData}
        setFormData={setFormData}
      />
    ),
    [WITHDRAWAL_RESULTS_STEP]: (
      <WithdrawalResultsStep
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setStep={setStep}
        setFormData={setFormData}
      />
    ),
    [WITHDRAWAL_OTHER_OPTIONS]: (
      <WithdrawalOtherOption
        step={step}
        totalSteps={totalSteps}
        setStep={setStep}
      />
    ),
    [WITHDRAWAL_INFLUENCED]: (
      <WithdrawalInfluenced
        step={step}
        totalSteps={totalSteps}
        setStep={setStep}
      />
    ),
    [WITHDRAWAL_ACCOUNT_DETAILS]: (
      <WithdrawalAccountDetails
        step={step}
        totalSteps={totalSteps}
        setStep={setStep}
        formData={formData}
        setFormData={setFormData}
      />
    ),
    [WITHDRAWAL_REVIEW_DETAILS]: (
      <WithdrawalReviewDetails
        step={step}
        totalSteps={totalSteps}
        setStep={setStep}
        setStepWithName={setStepWithName}
        formData={formData}
        setFormData={setFormData}
      />
    ),
    [WITHDRAWAL_REQUEST_COMPLETE]: (
      <WithdrawalRequestComplete
        step={step}
        totalSteps={totalSteps}
      />
    ),
  };

  return (
    stepsMap[JOURNEYS[currentJourney][step]]
  );
};

const mapStateToProps = (state) => ({
  latestPensionValue: getLatestPensionValue(state),
  isWithdrawalPagesAllowed: getIsWithdrawalPagesAllowed(state),
  authoritativeRp: getAuthoritativeRetirementPlan(state),
  canFetchEstimatedIncome: getCanFetchEstimatedIncome(state),
});

export default connect(mapStateToProps)(withRouter(WithdrawalPage));
