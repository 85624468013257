import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import ListItem from '../ListItem/ListItem';
import styles from './DocumentView.css';

const DocumentView = ({ files, table, enableMobileTable }) => {
  const docList = files ? files.map((file) => ({
    ...file,
    updated: moment(file.updated).format('DD/MM/YYYY'),
    name: file.displayName || file.name.match(RegExp('([^/]+)$'))[0],
    originalName: file.name,
  })) : [];
  const downloadFile = (fileUrl) => window.open(fileUrl, '_blank');
  const Table = table;
  return (
    docList.length < 1
      ? <div>{'You have no documents'}</div>
      : (
        <section>
          {enableMobileTable && (
            <div className={styles.smallView}>
              {docList.map((file) => (
                <ListItem
                  key={file.name}
                  title={file.name}
                  subtitle={file.updated}
                  onClick={() => downloadFile(file.downloadUrl)}
                />
              ))}
            </div>
          )}
          <div className={classnames({ [styles.largeView]: enableMobileTable })}>
            <Table files={docList} onDownload={downloadFile} />
          </div>
        </section>
      )
  );
};

export default DocumentView;

DocumentView.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    updated: PropTypes.string,
    downloadUrl: PropTypes.string,
  })),
};

DocumentView.defaultProps = {
  files: [],
};
