import React, { useState } from 'react';
import { connect } from 'react-redux';

import Button from '../../../components/Button/Button';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import GuaranteesAndExitPenaltiesModal from './GuaranteesAndExitPenaltiesModal';

import { getIsCustomerOngoing } from '../../../redux/selectors/experience';
import { onGuaranteesAndExitPenaltiesModalShow } from '../../../redux/modules/pensions';

import { THINGS_TO_CONSIDER_URL } from '../../../config';

import styles from './PensionTransferOption.css';

const PensionTransferOption = ({
  pensionTransferOptionContent,
  ctaButtonOptions,
  isCustomerOngoing,
  dispatchOnGuaranteesAndExitPenaltiesModalClicked,
}) => {
  const [showModal, setShowModal] = useState(false);

  const {
    summary, featureItems, suitableIfBody, costBody,
  } = pensionTransferOptionContent;

  return (
    <div className={styles.mainContainer}>
      <div className={styles.textWrapper}>
        <p className={styles.summary}>{summary}</p>
        <div className={styles.featureItemsContainer}>
          <div className={styles.featureItemWrapper}>
            <img
              src={featureItems[0].icon}
              alt=""
              className={styles.featureItemIcon}
            />
            <span className={styles.featureItemText}>{featureItems[0].text}</span>
          </div>
          <div className={styles.featureItemWrapper}>
            <img
              src={featureItems[1].icon}
              alt=""
              className={styles.featureItemIcon}
            />
            <span className={styles.featureItemText}>
              {featureItems[1].text}
              {' '}
              <span className={styles.featureItemWithLink}>
                {'('}
                <ButtonLink
                  variant="primary"
                  label="read more"
                  font={'bodyOne'}
                  onClick={() => {
                    dispatchOnGuaranteesAndExitPenaltiesModalClicked(isCustomerOngoing);
                    setShowModal(true);
                  }}
                />
                {')'}
              </span>
            </span>
          </div>
          <div className={styles.featureItemWrapper}>
            <img
              src={featureItems[2].icon}
              alt=""
              className={styles.featureItemIcon}
            />
            <span className={styles.featureItemText}>{featureItems[2].text}</span>
          </div>
        </div>
        <div className={styles.suitableIfContainer}>
          <h4 className={styles.suitableIfHeader}>{'Choose this option if'}</h4>
          <p className={styles.suitableIfBody}>{suitableIfBody}</p>
        </div>
        <div className={styles.costContainer}>
          <h4 className={styles.costHeader}>{'Cost'}</h4>
          <p className={styles.costBody}>{costBody}</p>
        </div>
        <div className={styles.documentLinkContainer}>
          <a
            href={THINGS_TO_CONSIDER_URL}
            target={'_blank'}
            rel="noopener noreferrer"
            className={styles.documentLinkText}
          >
            {'Important considerations'}
          </a>
        </div>

      </div>
      <div className={styles.ctaButtonContainer}>
        <Button
          size="small"
          label={'Get started'}
          onClick={() => {
            if (ctaButtonOptions.handleClick) {
              ctaButtonOptions.handleClick();
            }
          }}
        />
      </div>
      {showModal && (
        <GuaranteesAndExitPenaltiesModal
          show={showModal}
          onClose={() => {
            document.body.style.overflowY = 'auto';
            setShowModal(false);
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  dispatchOnGuaranteesAndExitPenaltiesModalClicked: onGuaranteesAndExitPenaltiesModalShow,
};

export default connect(mapStateToProps, mapDispatchToProps)(PensionTransferOption);
