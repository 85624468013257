import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ATTACHMENT_ACCEPTED,
  ATTACHMENT_LOADING,
  ATTACHMENT_PENDING,
  ATTACHMENT_REJECTED,
  ATTACHMENT_STATUSES,
} from '../../util/constants';
import { getFirebaseUid } from '../../redux/selectors';
import {
  attachmentRemoveButtonClickedAction,
  attachmentStatusAcceptedAction,
  attachmentStatusLoadingAction,
  attachmentStatusRejectedAction,
  processAttachmentFile,
} from '../../redux/modules/conversations';
import { getAttachmentByCreatedId } from '../../redux/selectors/conversations';
import { getAttachmentErrorMessage } from '../../util/attachments';
import AttachmentFileShell from './AttachmentFileShell';
import sentryException from '../../util/sentryException';

const SYNC_TIMEOUT_SECONDS = 60;

/**
 * An attachment file could be in 4 statuses:
 * - `ATTACHMENT_PENDING` - attachment has been selected from user's device an uploaded to the UI
 * - `ATTACHMENT_LOADING` - attachment passed to uploadAttachment account hub function
 * - `ATTACHMENT_ACCEPTED` - attachment synced back and has passed validation checks
 * - `ATTACHMENT_REJECTED `- attachment synced back and didn't pass validation checks
 *                         OR the attachment didn't pass the UI file validation
 */
const AttachmentFile = ({
  createId,
  name,
  status,
  errors,
  onRemoveHandler,
  onAccepted,
  file,
  uid,
  dispatchProcessAttachmentFile,
  dispatchAttachmentRemoveButtonClickedAction,
  dispatchAttachmentStatusAcceptedAction,
  dispatchAttachmentStatusRejectedAction,
  dispatchAttachmentStatusLoadingAction,
  attachmentChecksPassed,
  attachmentFilePath,
}) => {
  const [internalStatus, setInternalStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const showLoadingSpinner = [ATTACHMENT_LOADING, ATTACHMENT_PENDING].includes(internalStatus);

  const initErrorMessage = () => setErrorMessage((errors).map(({ code }) => getAttachmentErrorMessage(code)).join('. '));
  useEffect(initErrorMessage, [errors]);

  const setAttachmentRejected = (message) => {
    setErrorMessage(message || getAttachmentErrorMessage());
    setInternalStatus(ATTACHMENT_REJECTED);
    dispatchAttachmentStatusRejectedAction();
  };

  // Attachments that don't pass checks after SYNC_TIMEOUT_SECONDS are regarded as rejected
  const initTimeout = () => {
    setInternalStatus(status);
    if (status !== ATTACHMENT_REJECTED) {
      const timeoutRef = setTimeout(() => {
        sentryException(
          new Error(`Attachment failed to be checked in ${SYNC_TIMEOUT_SECONDS} seconds`),
          {
            section: 'conversation-add-attachment-initTimeout',
          },
        );
        setAttachmentRejected();
      }, SYNC_TIMEOUT_SECONDS * 1000);
      setTimeoutId(timeoutRef);
    }
  };
  useEffect(initTimeout, [status]);

  const uploadPendingAttachment = async () => {
    setInternalStatus(ATTACHMENT_LOADING);
    dispatchAttachmentStatusLoadingAction();

    try {
      await dispatchProcessAttachmentFile(uid, createId, file);
    } catch (e) {
      setAttachmentRejected(e && e.message);
    }
  };

  const onAttachmentInit = () => {
    if (internalStatus === ATTACHMENT_PENDING) {
      uploadPendingAttachment();
    }
  };
  useEffect(onAttachmentInit, [internalStatus, createId]);

  const clearTimeoutOnAccepted = () => {
    if (internalStatus === ATTACHMENT_ACCEPTED) {
      clearTimeout(timeoutId);
    }
  };
  useEffect(clearTimeoutOnAccepted, [internalStatus, timeoutId]);

  const onAttachmentAccepted = () => {
    if (internalStatus === ATTACHMENT_ACCEPTED) {
      dispatchAttachmentStatusAcceptedAction();
      onAccepted(true, attachmentFilePath);
    }
  };
  useEffect(onAttachmentAccepted, [internalStatus]);

  const attachmentChecksPassedListener = () => {
    if (attachmentChecksPassed !== undefined) {
      if (attachmentChecksPassed) {
        setInternalStatus(ATTACHMENT_ACCEPTED);
        setErrorMessage(null);
      } else {
        setAttachmentRejected();
      }
    }
  };
  useEffect(attachmentChecksPassedListener, [attachmentChecksPassed]);

  const handleRemoveFile = () => {
    dispatchAttachmentRemoveButtonClickedAction();
    onRemoveHandler();
  };

  return (
    <AttachmentFileShell
      isLoading={showLoadingSpinner}
      status={internalStatus}
      name={name}
      errorMessage={errorMessage}
      handleRemoveFile={handleRemoveFile}
    />
  );
};

AttachmentFile.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(ATTACHMENT_STATUSES)).isRequired,
  createId: PropTypes.string.isRequired,
  onRemoveHandler: PropTypes.func.isRequired,
  onAccepted: PropTypes.func.isRequired,
  file: PropTypes.shape({
    path: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  errors: PropTypes.arrayOf({
    code: PropTypes.string,
  }),
};

AttachmentFile.defaultProps = {
  errors: [],
};

const mapStateToProps = (state, ownProps) => {
  const { createId } = ownProps;

  const [attachmentChecksPassed, filePath] = getAttachmentByCreatedId(state, createId);

  return ({
    uid: getFirebaseUid(state),
    attachmentChecksPassed,
    attachmentFilePath: filePath,
  });
};

const mapDispatchToProps = {
  dispatchProcessAttachmentFile: processAttachmentFile,
  dispatchAttachmentRemoveButtonClickedAction: attachmentRemoveButtonClickedAction,
  dispatchAttachmentStatusAcceptedAction: attachmentStatusAcceptedAction,
  dispatchAttachmentStatusRejectedAction: attachmentStatusRejectedAction,
  dispatchAttachmentStatusLoadingAction: attachmentStatusLoadingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentFile);
