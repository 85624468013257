import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import styles from './PersonalDetails.css';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Button from '../../components/Button/Button';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import {
  missingDetailsReturnLinkClickedAction,
  missingDetailsMessageMeClickedAction,
  missingDetailsPreviousAddressSkipStepClickedAction,
  missingDetailsAddAPreviousAddressStepClickedAction,
  missingDetailsCompleteAction,
  missingDetailsPreviousAddressSubmitClickedAction,
} from '../../redux/modules/signupFlow';
import { updatePersonalDetails } from '../../redux/modules/investmentAdvice';
import { statusNotificationMissingDetailsCompleted } from '../../redux/modules/notificationTracking';
import { getUser, getHasDigitalSignature, getHasMobileNumber } from '../../redux/selectors';
import Loader from '../../components/Loader/Loader';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import closeCircle from '../../assets/images/rebranding/cross-icon-in-circle.svg';
import { getHasOnlyInProgressContributionInstruction, getHasInProgressLumpSumInstructions } from '../../redux/selectors/investmentAdvice';

const AddressHistoryStep = ({
  totalSteps,
  step,
  amendMode,
  dispatchMissingDetailsReturnLinkClickedAction,
  dispatchMissingDetailsMessageMeClickedAction,
  dispatchMissingDetailsPreviousAddressSkipStepClickedAction,
  dispatchMissingDetailsPreviousAddressSubmitClickedAction,
  dispatchUpdatePersonalDetails,
  dispatchStatusNotificationMissingDetailsCompleted,
  dispatchMissingDetailsComplete,
  dispatchMissingDetailsAddAPreviousAddressStepClickedAction,
  setStep,
  user,
  formData,
  setFormData,
  isSubmitLoading,
  setIsSubmitLoading,
  flow,
  hasOnlyInProgressContributionInstructions,
  hasInProgressLumpSumInstructions,
}) => {
  const address = {
    street: get(user, 'contact.address.street'),
    city: get(user, 'contact.address.city'),
    county: get(user, 'contact.address.county'),
    postCode: get(user, 'contact.address.postalCode'),
  };

  const customerCurrentAddress = {
    street: get(formData, 'street'),
    city: get(formData, 'city'),
    county: get(formData, 'county'),
    postCode: get(formData, 'postCode'),
  };

  const [previousAddresses, setPreviousAddresses] = useState(get(formData, 'previousAddresses', []));

  function previousAddressTitle() {
    return formData.previousAddresses.length === 1 ? 'Previous Address' : 'Previous Addresses';
  }

  const mobileFromUser = get(user, 'contact.mobile');

  const hasValidMobileNumber = mobileFromUser !== undefined;

  const customerSignature = get(user, 'signature');

  const hasValidSignature = customerSignature !== undefined;

  const shouldShowContributionCopy = (flow === 'missing-details-cta' && (hasOnlyInProgressContributionInstructions || hasInProgressLumpSumInstructions)) || (flow === 'add-contribution' || flow === 'setup-lump-sum');

  const missingDetailsData = {
    mobileNumber: hasValidMobileNumber ? mobileFromUser : get(formData, 'mobileNumber'),

    nationalInsuranceNumber: get(formData, 'nationalInsuranceNumber'),

    signature: hasValidSignature ? customerSignature : get(formData, 'signature'),

    street: get(formData, 'street', address.street),
    city: get(formData, 'city', address.city),
    county: get(formData, 'county', address.county),
    postCode: get(formData, 'postCode', address.postCode),
    previousAddresses: (get(formData, 'previousAddresses', [])),
  };

  const removePreviousAddress = (index) => {
    const newPreviousAddresses = [...previousAddresses];
    newPreviousAddresses.splice(index, 1);

    setPreviousAddresses(newPreviousAddresses);
  };

  async function userIsOnLastStepOfFlow() {
    setIsSubmitLoading(true);
    await dispatchUpdatePersonalDetails(missingDetailsData);
    dispatchStatusNotificationMissingDetailsCompleted();
    dispatchMissingDetailsComplete();
    setIsSubmitLoading(false);
    setStep(totalSteps - 1);
  }

  const previousAddressesIsEmpty = previousAddresses.length === 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setFormData((_formData) => ({
      ..._formData,
      previousAddresses,
    }));
  }, [previousAddresses]);

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.missingDetailsWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
          <ButtonLink
            onClick={() => {
              dispatchMissingDetailsReturnLinkClickedAction();
              setStep(step - 1);
            }}
            label="← Back"
          />
        )}
        <form className={styles.form} />
        <h1 className={styles.ninoHeading}>
          {'Address history'}
        </h1>
        <div className={styles.content}>
          {`To help us ${shouldShowContributionCopy ? 'set up your contribution,' : 'trace your pensions,'} please provide your previous address. You can add more addresses at any time.`}
          {' '}
        </div>
        {<div>&nbsp;</div>}
        <div className={styles.addressContainer}>
          <div className={styles.addressHeading} font="bold">
            {'Current address'}
            {<div>&nbsp;</div>}
            <div className={styles.addressContent}>
              <div>{customerCurrentAddress.street || address.street}</div>
              <div>{customerCurrentAddress.city || address.city}</div>
              <div>{customerCurrentAddress.county || address.county}</div>
              <div>{customerCurrentAddress.postCode || address.postCode}</div>
            </div>
          </div>
        </div>
        {<div>&nbsp;</div>}
        {previousAddresses.length > 0 && (
          <div className={styles.addressContainer}>
            <div className={styles.addressHeading}>
              {previousAddressTitle()}
              {<div>&nbsp;</div>}
              <div className={styles.addressContent}>
                {previousAddresses.map(
                  (previousAddress, divider) => {
                    const isLast = previousAddresses.length - 1 === divider;
                    return (
                      <Fragment key={previousAddress.line1}>
                        <div className={styles.previousAddressWrapper}>
                          <span
                            className={styles.removePreviousAddressesIcon}
                            onClick={() => removePreviousAddress(divider)}
                            onKeyUp={() => { }}
                            role="button"
                            tabIndex="0"
                          >
                            <img src={closeCircle} alt="Close icon" />
                          </span>
                        </div>
                        <div>{previousAddress.line1}</div>
                        <div>{previousAddress.line2}</div>
                        <div>{previousAddress.line3}</div>
                        <div>{previousAddress.line4}</div>
                        <div>{previousAddress.locality}</div>
                        <div>{previousAddress.city}</div>
                        <div>{previousAddress.county}</div>
                        <div>{previousAddress.postCode}</div>
                        {previousAddress.livedAtAddressDateFrom
                          && previousAddress.livedAtAddressDateTo
                          && (
                            <div>
                              <div>&nbsp;</div>
                              <div className={styles.addressDates}>{'Time spent at this address'}</div>
                              <div>
                                {moment(previousAddress.livedAtAddressDateFrom).format('MM/YYYY')}
                                {' - '}
                                {moment(previousAddress.livedAtAddressDateTo).format('MM/YYYY')}
                              </div>
                            </div>
                          )}
                        {!isLast && <hr className={styles.hr} />}
                      </Fragment>
                    );
                  },
                )}
              </div>
            </div>
          </div>
        )}
        <div>&nbsp;</div>
        <div>
          <Button
            size="large"
            type="button"
            label={'Add a previous address'}
            disabled={isSubmitLoading && previousAddressesIsEmpty}
            onClick={() => {
              dispatchMissingDetailsAddAPreviousAddressStepClickedAction();
              setFormData((_formData) => ({
                ..._formData,
              }));
              setStep((_step) => _step + 1);
            }}
            wrapperId="missing-details-submit"
          />
        </div>
        <br />
        <div>
          <Button
            size="large"
            type="button"
            label={'Submit'}
            disabled={previousAddressesIsEmpty || isSubmitLoading}
            loading={!previousAddressesIsEmpty && isSubmitLoading}
            onClick={async () => {
              dispatchMissingDetailsPreviousAddressSubmitClickedAction();
              setIsSubmitLoading(true);
              userIsOnLastStepOfFlow();
            }}
            wrapperId="missing-details-submit"
          />
        </div>
        {<div>&nbsp;</div>}
        {previousAddressesIsEmpty && (!isSubmitLoading ? (
          <ButtonLink
            variant="primary"
            label={<span className={styles.skipThisStep}>{'Skip this step'}</span>}
            onClick={async () => {
              dispatchMissingDetailsPreviousAddressSkipStepClickedAction();
              setIsSubmitLoading(true);
              setFormData((_formData) => ({
                ..._formData,
              }));
              userIsOnLastStepOfFlow();
            }}
            disabled={!previousAddressesIsEmpty || isSubmitLoading}
          />
        ) : (<Loader />))}

        {<div>&nbsp;</div>}
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'AddressHistoryStep'}
            noExpertContentWrapper={({ children }) => (
              <div
                style={{
                  display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                }}
              >
                {children}
              </div>
            )}
            showButton={false}
            customMessage={(
              <div
                className={styles.contactUsInnerContent}
              >
                {'You can add your address history in your '}
                <ButtonLink
                  variant="primary"
                  label="Personal Details"
                  link="/personal-details"
                  font="inherit"
                  target="_blank"
                  rel="noreferrer"
                />
                {' page. If you have any questions, please feel free to '}
                <ButtonLink
                  label="message me."
                  variant="primary"
                  link="/inbox/new-message"
                  font="inherit"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => dispatchMissingDetailsMessageMeClickedAction()}
                />
              </div>
            )}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapDispatchToProps = {
  dispatchMissingDetailsReturnLinkClickedAction: missingDetailsReturnLinkClickedAction,
  dispatchMissingDetailsMessageMeClickedAction: missingDetailsMessageMeClickedAction,
  dispatchMissingDetailsPreviousAddressSkipStepClickedAction:
    missingDetailsPreviousAddressSkipStepClickedAction,
  dispatchMissingDetailsAddAPreviousAddressStepClickedAction:
    missingDetailsAddAPreviousAddressStepClickedAction,
  dispatchMissingDetailsPreviousAddressSubmitClickedAction:
    missingDetailsPreviousAddressSubmitClickedAction,
  dispatchUpdatePersonalDetails: updatePersonalDetails,
  dispatchStatusNotificationMissingDetailsCompleted: statusNotificationMissingDetailsCompleted,
  dispatchMissingDetailsComplete: missingDetailsCompleteAction,
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  mob: getHasMobileNumber(state),
  signature: getHasDigitalSignature(state),
  hasOnlyInProgressContributionInstructions: getHasOnlyInProgressContributionInstruction(state),
  hasInProgressLumpSumInstructions: getHasInProgressLumpSumInstructions(state),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddressHistoryStep);
