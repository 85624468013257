import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FieldLabel from '../FieldLabel/FieldLabel';
import FieldStatusBlock from '../FieldStatusBlock/FieldStatusBlock';
import InformationIcon from '../InformationIcon/InformationIcon';
import CalloutText from '../CalloutText/CalloutText';
import styles from './TextField.css';

export default class TextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      textAreaHeight: 'auto',
      showHelpText: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { onChange, multiLine } = this.props;

    onChange(event);
    // update textarea height
    if (multiLine) {
      window.setTimeout(() => {
        this.setState({ textAreaHeight: 'auto' });
        this.setState({ textAreaHeight: `${this.textArea.scrollHeight}px` });
      }, 0);
    }
  }

  render() {
    const {
      value,
      name,
      required,
      disabled,
      type,
      defaultValue,
      errorText,
      multiLine,
      onBlur,
      onFocus,
      inputRef,
      label,
      uppercase,
      touched,
      maxLength,
      min,
      max,
      autoComplete,
      wrapperStyles,
      fieldStyles,
      largeHeadings,
      helpText,
      helpTextCallback,
      inputWrapperStyles,
      secondaryText,
      tallInput,
      bodyOneSecondary,
      multiLineRows,
    } = this.props;

    const { textAreaHeight, focused, showHelpText } = this.state;

    const handleFocus = (e) => {
      this.setState({ focused: true });

      if (onFocus) {
        onFocus(e);
      }
    };

    const handleBlur = (e) => {
      if (onBlur) {
        onBlur(e);
      }
      this.setState({ focused: false });
    };

    const inputClass = classnames(styles.textInput, {
      [styles.uppercase]: uppercase && value,
      [styles.disabled]: disabled,
      [styles.tallInput]: tallInput,
    });

    const fieldType = () => {
      if (multiLine) {
        return (
          <textarea
            rows={multiLineRows}
            value={value}
            name={name}
            placeholder={disabled ? '' : defaultValue}
            onChange={(event) => this.handleChange(event)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={(el) => { this.textArea = el; }}
            style={{ ...fieldStyles, height: textAreaHeight }}
            className={inputClass}
            disabled={disabled}
          />
        );
      }
      return (
        <input
          style={fieldStyles}
          value={value}
          name={name}
          type={type && type}
          placeholder={disabled ? '' : defaultValue}
          onChange={(event) => this.handleChange(event)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={inputRef}
          maxLength={maxLength}
          className={inputClass}
          disabled={disabled}
          min={min}
          max={max}
          autoComplete={autoComplete}
        />
      );
    };

    return (
      <div className={styles.wrapper} style={wrapperStyles} ref={(el) => { this.wrapper = el; }}>
        <div className={styles.fieldLabelContainer}>
          <FieldLabel
            label={label}
            required={required}
            disabled={disabled}
            largeHeadings={largeHeadings}
          />
          {
            helpText && (
              <InformationIcon
                active={showHelpText}
                onClick={() => {
                  this.setState({ showHelpText: !showHelpText });
                  if (helpTextCallback) {
                    helpTextCallback();
                  }
                }}
              />
            )
          }
        </div>
        {secondaryText && (
          <div
            className={
              classnames(
                {
                  [styles.bodyOneSecondary]: bodyOneSecondary,
                  [styles.secondaryText]: !bodyOneSecondary,
                },
              )
            }
          >
              {secondaryText}
          </div>
        )}
        { helpText && showHelpText && <CalloutText text={helpText} /> }
        <FieldStatusBlock
          error={errorText}
          focused={focused}
          touched={touched}
          wrapperStyles={inputWrapperStyles}
        >
          {fieldType()}
        </FieldStatusBlock>
      </div>
    );
  }
}

TextField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  multiLine: PropTypes.bool,
  errorText: PropTypes.node,
  touched: PropTypes.bool,
  uppercase: PropTypes.bool,
  maxLength: PropTypes.number,
  min: PropTypes.string,
  max: PropTypes.string,
  autoComplete: PropTypes.string,
  wrapperStyles: PropTypes.shape({}),
  // eslint-disable-next-line react/forbid-prop-types
  inputWrapperStyles: PropTypes.object,
  largeHeadings: PropTypes.bool,
  helpText: PropTypes.node,
  tallInput: PropTypes.bool,
  multiLineRows: PropTypes.number,
};

TextField.defaultProps = {
  helpText: null,
  value: '',
  required: false,
  disabled: false,
  label: null,
  errorText: null,
  multiLine: false,
  multiLineRows: 1,
  defaultValue: 'Type...',
  touched: false,
  uppercase: false,
  maxLength: 524288,
  min: null,
  max: null,
  autoComplete: 'on',
  wrapperStyles: {},
  inputWrapperStyles: { maxWidth: '100%' },
  largeHeadings: false,
  tallInput: false,
};
