/* eslint-disable import/no-cycle */

import moment from 'moment';
import get from 'lodash/get';
import { getFiles } from './index';
import { PENSION_PLAN_RECOMMENDATION_CURRENT_STEPS, PENSION_PLAN_OVERALL_STATUSES } from '../../util/constants';
import { getIsOngoingCdsAllowed } from './journeys';

const PENSION_PLAN_RECOMMENDATION_OPEN_STATUSES = [
  'blocked',
  'in-progress',
];

export const getOngoingServiceFromUser = (state) => {
  return get(state, 'firestore.ordered.users[0].onGoingService', false);
};

export const getOngoingServiceFromOnboarding = (state) => {
  return get(state, 'firestore.ordered.investmentAdviceOnboarding[0].ongoingService');
};

export const getMostRecentNoChangeCompletedRecommendation = (state) => {
  const ongoingService = getOngoingServiceFromOnboarding(state);
  const recommendations = get(ongoingService, 'pensionPlanRecommendations', []);

  const noChangeCompletedRecs = recommendations.filter((rec) => (
    rec.overallStatus === PENSION_PLAN_OVERALL_STATUSES.COMPLETED
    && rec.currentStep === PENSION_PLAN_RECOMMENDATION_CURRENT_STEPS.NO_CHANGE_COMPLETED
  ));

  if (noChangeCompletedRecs.length === 0) {
    return undefined;
  }
  if (noChangeCompletedRecs.length === 1) {
    return noChangeCompletedRecs[0];
  }

  const sortedRecs = noChangeCompletedRecs.sort((a, b) => (
    new Date(b.createdDate) - new Date(a.createdDate)));
  return sortedRecs[0];
};

export const getCurrentPensionPlanRecommendation = (state) => {
  const ongoingService = getOngoingServiceFromOnboarding(state);
  const recommendations = get(ongoingService, 'pensionPlanRecommendations', []);

  const pendingRecs = recommendations.filter((rec) => {
    return PENSION_PLAN_RECOMMENDATION_OPEN_STATUSES.includes(get(rec, 'overallStatus'));
  });

  if (pendingRecs.length > 1) {
    return undefined;
  }

  if (pendingRecs.length === 0) {
    return getMostRecentNoChangeCompletedRecommendation(state);
  }

  const pendingRec = pendingRecs[0];

  return pendingRec;
};

export const getMostRecentlyCompletedPensionPlanRecommendation = (state) => {
  const ongoingService = getOngoingServiceFromOnboarding(state);
  const recommendations = get(ongoingService, 'pensionPlanRecommendations', []);

  const completedRecs = recommendations.filter((rec) => get(rec, 'completed.value'));

  const latestCompletedRec = completedRecs.sort((a, b) => {
    return new Date(b.completed.value) - new Date(a.completed.value);
  });

  return latestCompletedRec[0];
};

export const getReadyToAcceptRecommendation = (state) => {
  const ongoingService = getOngoingServiceFromOnboarding(state);
  const recommendations = get(ongoingService, 'pensionPlanRecommendations', []);

  const readyToAcceptRecs = recommendations.filter((rec) => {
    return get(rec, 'currentStep') === PENSION_PLAN_RECOMMENDATION_CURRENT_STEPS.CHANGE_READY_TO_ACCEPT
      && (get(rec, 'overallStatus') === PENSION_PLAN_OVERALL_STATUSES.IN_PROGRESS || get(rec, 'overallStatus') === PENSION_PLAN_OVERALL_STATUSES.BLOCKED);
  });

  if (readyToAcceptRecs.length > 1) {
    return undefined;
  }
  if (readyToAcceptRecs.length === 0) {
    return undefined;
  }

  const readyToAcceptRec = readyToAcceptRecs[0];

  return readyToAcceptRec;
};

export const getRecentlyAcceptedRecommendation = (state) => {
  const ongoingService = getOngoingServiceFromOnboarding(state);
  const recommendations = get(ongoingService, 'pensionPlanRecommendations', []);

  const acceptedRec = recommendations.find((rec) => {
    const acceptedDate = get(rec, 'authorityTracking.acceptanceDate');
    return get(rec, 'currentStep') === PENSION_PLAN_RECOMMENDATION_CURRENT_STEPS.CHANGE_ACCEPTED
      && (get(rec, 'overallStatus') === PENSION_PLAN_OVERALL_STATUSES.IN_PROGRESS || get(rec, 'overallStatus') === PENSION_PLAN_OVERALL_STATUSES.BLOCKED)
      && acceptedDate
      && moment().diff(acceptedDate, 'minutes') < 15;
  });

  return acceptedRec;
};

const _getSuitabilityReportFromRecommendation = (rec, state) => {
  if (!rec) return undefined;

  const { _id, adviceGeneratedDate } = rec;
  const date = moment(adviceGeneratedDate).utc().format('YYYY-MM-DD-hh-mm-ss');
  const files = getFiles(state);

  const ongoingSuitReport = files.find((file) => {
    const fileName = get(file, 'name', '');
    return fileName.includes(_id) && fileName.includes(date);
  });

  return ongoingSuitReport;
};

export const getReadyToAcceptSuitabilityReport = (state) => {
  const rec = getReadyToAcceptRecommendation(state);
  return _getSuitabilityReportFromRecommendation(rec, state);
};

export const getCurrentRecommendationSuitabilityReport = (state) => {
  const rec = getCurrentPensionPlanRecommendation(state);
  return _getSuitabilityReportFromRecommendation(rec, state);
};

export const getHasRecommendationToAcceptAndReport = (state) => (
  Boolean(getReadyToAcceptRecommendation(state) && getReadyToAcceptSuitabilityReport(state))
);

export const getHasRecommendationToAcceptAndNoReport = (state) => (
  Boolean(getReadyToAcceptRecommendation(state) && !getReadyToAcceptSuitabilityReport(state))
);

export const getIsVulnerableCustomerFromOngoing = (state) => {
  const rec = getCurrentPensionPlanRecommendation(state);
  const isOngoingCdsAllowed = getIsOngoingCdsAllowed(state);
  return Boolean(get(rec, 'blockingReasons.vulnerable.value') && !isOngoingCdsAllowed);
};

export const getUserBookings = (state) => {
  return get(state, 'firestore.ordered.investmentAdviceOnboarding[0].bookings', []);
};
