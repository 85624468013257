import React from 'react';

import withdrawalPlantImg from '../../assets/images/retirementIncome/withdrawal-plant.svg';
import styles from './SERPSAtRetirementCard.css';

const AtRetirementCard = () => {
  return (
    <div>
      <img src={withdrawalPlantImg} alt={'Withdrawal plant'} />
      <h4 className={styles.heading}>{'At retirement'}</h4>
      <div className={styles.text}>{'Withdrawing from your pension is a major decision that will have an impact on your income in retirement. We’re with you every step of the way. By becoming a Profile Pensions customer, you’ll be able to speak to your dedicated Pension Adviser to help you plan your retirement.'}</div>
    </div>
  );
};

export default AtRetirementCard;
