import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import styles from './NoBalanceModal.css';
import Modal from '../Modal/Modal';
import GlanceCard from '../GlanceCard/GlanceCard';
import GlanceCardContent from '../GlanceCard/GlanceCardContent';

import closeIcon from '../../assets/images/rebranding/cross-icon-in-circle.svg';

function NoBalanceModal({ show, onClose, subHeadings }) {
  return (
    <Modal show={show} backDropClick={onClose}>
      <GlanceCard>
        <GlanceCardContent wrapperStyles={{ maxHeight: '90vh' }}>
          <div className={styles.closeWrapper} onClick={() => onClose()} onKeyUp={() => {}} role="button" tabIndex="0">
            <img src={closeIcon} alt="Close icon" />
            {' '}
            <span className={styles.closeWrapperText}>{'Close'}</span>
          </div>
          <h3 className={styles.heading}>{'Potential reasons why we cannot show your latest balance'}</h3>
          {subHeadings && (
            <div className={styles.yourPensionPlan}>
              {subHeadings.map((subHeading) => {
                return (
                  <React.Fragment>
                    <p className={styles.subHeadingTitle}>{subHeading.title}</p>
                    <p className={styles.subHeadingContent}>{subHeading.content}</p>
                  </React.Fragment>
                );
              })}
            </div>
          )}
          <ul className={styles.unorderedList} style={{ paddingInlineStart: '20px' }}>
            <li className={styles.listItem}>{'You have set up a regular payment and we are waiting for your first payment to come through.'}</li>
            <li className={styles.listItem}>{'We are waiting for your first pension transfer to complete.'}</li>
            <li className={styles.listItem}>
              {'If none of the reasons above applies, please '}
              <Link to="/inbox/new-message">{'contact us'}</Link>
              {'.'}
            </li>
          </ul>
        </GlanceCardContent>
      </GlanceCard>
    </Modal>
  );
}

NoBalanceModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NoBalanceModal;
