/* eslint-disable import/no-cycle */

import get from 'lodash/get';
import moment from 'moment';
import {
  RECOMMENDATION_FULFILMENT_STATUSES,
  MINIMUM_VALID_CDS_QUESTIONS_VERSION,
  PERSONAL_DETAILS_FIELDS,
  SHOW_MENTION_ME,
} from '../../util/constants';

import { getOngoingServiceFromOnboarding } from './ongoingService';
import { getIsCustomerOngoing } from './experience';
import {
  getHasOnlyInProgressContributionInstruction,
  getHasInProgressLumpSumInstructions,
} from './investmentAdvice';
import { getIsAuthLoaded, getIsUserLoaded } from './loaded';

const findLatestDate = (dates) => {
  const definedDates = dates.filter((d) => d);
  return Math.max(...definedDates.map((d) => new Date(d)));
};

export const getNotificationMessage = (state) => {
  return get(state, 'auth.notificationMessage');
};
export const getIsCdsDataLoaded = (state) => get(state, 'customerDataSetStatuses.cdsDataLoaded', false);
export const getCdsLoadingError = (state) => get(state, 'customerDataSetStatuses.cdsLoadingError', false);
export const getPostCdsAnimationLoading = (state) => get(state, 'customerDataSetStatuses.postCdsAnimationLoading');
export const getVisitedPlanSummaryPage = (state) => get(state, 'customerDataSetStatuses.visitedPlanSummaryPage', false);

export const getAutoLogout = (state) => get(state, 'auth.autoLogout');

export const getAuthToken = (state) => get(state, 'firebase.auth.stsTokenManager.accessToken');

export const getAuthError = (state) => get(state, 'auth.error');

export const getUser = (state) => get(state, 'firestore.ordered.users[0]');

export const getPhoneNumber = (state) => get(state, 'firestore.ordered.users[0].contact.mobile');

export const getEmail = (state) => get(state, 'firebase.auth.email');

export const getUserProfile = (state) => get(state, 'firebase.profile');

export const getFirebaseUid = (state) => state.firebase.auth.uid;

export const getFirestoreErrors = (state) => get(state, 'firestore.errors.byQuery');

export const getUid = (state) => state.auth.uid;

export const getAllCds = (state) => get(state, 'firestore.ordered.customerDataSets', []);

export const getUtmParams = (state) => {
  const signup = get(state, 'firestore.ordered.signups', [])[0];
  return {
    utmTerm: get(signup, 'utmFields.utmTerm'),
    utmMedium: get(signup, 'utmFields.utmMedium'),
    utmSource: get(signup, 'utmFields.utmSource'),
  };
};

export const getRecentlyCompletedCds = (state) => {
  const wasCompletedRecently = (date) => moment().subtract(5, 'minutes').isBefore(moment(date));
  return getAllCds(state).find(
    (cds) => cds.complete
      && cds.completedDate
      && wasCompletedRecently(cds.completedDate),
  );
};

export const getLastCdsCompletedDate = (state) => get(state, 'firestore.ordered.users[0].personal.lastCdsCompletedDate', null);

export const getLatestCompleteInvestmentAdviceCds = (state) => {
  const cdss = get(state, 'firestore.ordered.customerDataSets', []);
  return cdss.find((cds) => {
    const validQuestionsVersion = get(cds, 'questionsVersion', 0) >= MINIMUM_VALID_CDS_QUESTIONS_VERSION;
    return (
      (typeof cds.ongoing === 'undefined' || cds.ongoing === false)
      && cds.complete
      && validQuestionsVersion
    );
  });
};

export const getUserDetails = (state) => get(state, 'firestore.ordered.userDetails[0]');
export const getSignup = (state) => get(state, 'firestore.ordered.signups[0]');
export const getUserData = (state) => get(state, 'firestore.ordered.userDetails[0]');

export const getCustomerReference = (state) => get(state, 'firebase.profile.personal.customerReference');

export const getLatestRecommendation = (state) => {
  const recommendations = get(state, 'firestore.ordered.recommendations', []);
  const openRecs = recommendations.filter((rec) => {
    return (
      !rec.fulfilmentStatus
      || ![
        RECOMMENDATION_FULFILMENT_STATUSES.CLOSED,
        RECOMMENDATION_FULFILMENT_STATUSES.CANCELLED,
      ].includes(rec.fulfilmentStatus)
    );
  });
  if (!openRecs.length) {
    return undefined;
  }
  return openRecs.reduce((acc, rec) => {
    const {
      sendLetterDate,
      callbackSendLetterDate,
      digitalAdviceSentDate,
    } = rec;
    const currentDate = findLatestDate([
      sendLetterDate,
      callbackSendLetterDate,
      digitalAdviceSentDate,
    ]);
    const prevDate = findLatestDate([
      acc.sendLetterDate,
      acc.callbackSendLetterDate,
      acc.digitalAdviceSentDate,
    ]);
    if (new Date(currentDate) > new Date(prevDate)) {
      return rec;
    }
    return acc;
  }, recommendations[0]);
};

export const getBlogPosts = (state) => get(state, 'blog.blogPosts');

export const getHasFiles = (state) => getIsUserLoaded(state)
  && state.firebase.profile.files
  && !!state.firebase.profile.files.length;

export const getIsAuthenticated = (state) => getIsAuthLoaded(state)
  && !get(state, 'firebase.auth.isEmpty')
  && !!get(state, 'firebase.auth.uid');

export const getFiles = (state) => {
  const files = get(state, 'firebase.profile.files') || [];
  const filesWithoutAttachments = files.filter(
    (f) => !get(f, 'name', '').includes('message-attachments'),
  );
  return filesWithoutAttachments;
};

export const getAttachmentFiles = (state) => {
  const files = get(state, 'firebase.profile.files') || [];
  const attachments = files.filter((f) => get(f, 'name', '').includes('message-attachments'));
  return attachments;
};

export const getPensionConfig = (state) => get(state, 'firestore.data.pensionConfig');

export const getName = (state) => {
  const user = getUserProfile(state);
  const firstName = get(state, 'user.personal.name.first');
  const lastName = get(state, 'user.personal.name.last');
  return user.isLoaded && !user.isEmpty && firstName && lastName
    ? `${user.personal.name.first} ${user.personal.name.last}`
    : null;
};

export const getNameWithSalutation = (state) => {
  const user = getUserProfile(state);
  const firstName = get(user, 'personal.name.first');
  const lastName = get(user, 'personal.name.last');
  const salutation = get(user, 'personal.name.title');
  return { firstName, lastName, salutation };
};

export const getFirstName = (state) => {
  const user = getUserProfile(state);
  const firstName = get(user, 'personal.name.first');
  return firstName;
};

export const getLastName = (state) => {
  const user = getUserProfile(state);
  const lastName = get(user, 'personal.name.last');
  return lastName;
};

export const getPippaId = (state) => {
  const user = getUserProfile(state);
  const pippaId = get(user, 'pippaId');
  return pippaId;
};

const _findLatestCompleteCds = (cdss) => {
  const latestCompleteCds = cdss.reduce((acc, cds) => {
    const currentCdsCompleteDate = cds.completedDate;
    if (currentCdsCompleteDate > acc.completedDate) {
      return cds;
    }
    return acc;
  }, cdss[0]);
  return latestCompleteCds;
};

export const getLatestCompleteCds = (state) => {
  return _findLatestCompleteCds(getAllCds(state));
};

export const getDob = (state) => {
  const user = getUserProfile(state);
  const signup = getSignup(state);
  const cds = getLatestCompleteInvestmentAdviceCds(state);
  return (
    get(user, 'personal.dob')
    || get(signup, 'customer.dateOfBirth')
    || get(cds, 'answers.dateOfBirth.value')
  );
};

export const getGender = (state) => {
  const cds = getLatestCompleteInvestmentAdviceCds(state);
  const user = getUserProfile(state);

  return get(user, 'personal.gender') || get(cds, 'answers.gender.value');
};

export const getAge = (state) => {
  return moment().diff(getDob(state), 'years');
};

export const getIntendedRetirementAge = (state) => {
  const user = getUserProfile(state);
  const intendedRetirementAge = get(user, 'retirement.intendedRetirementAge');
  if (!intendedRetirementAge) return get(user, 'retirement.stateRetirementAge');
  return intendedRetirementAge;
};

export const getAddress = (state) => {
  const user = getUserProfile(state);
  return get(user, 'contact.address');
};

export const getPreferredPhoneNumber = (state) => {
  const user = getUserProfile(state);
  const mobileFromUser = get(user, 'contact.mobile');
  const landlinefromUser = get(user, 'contact.landline');

  const signup = getSignup(state);

  const preferredNumberFromUser = mobileFromUser || landlinefromUser;
  const preferredPhoneNumberFromSignup = get(
    signup,
    'customer.preferredPhoneNumber',
  );

  return preferredNumberFromUser || preferredPhoneNumberFromSignup;
};

export const getPasswordReset = (state) => state.auth.passwordReset;

export const getProviderNames = (state) => {
  const providerNamesObject = get(state, 'firestore.ordered.providers[0]');
  if (providerNamesObject) {
    // The path in firestore is /providers/names, so we need to filter out the { id: names } field
    // for this document
    const activeProviders = Object.keys(providerNamesObject).filter(
      (pippaId) => pippaId !== 'id'
        && providerNamesObject[pippaId].showToCustomers !== false,
    );
    const providerNamesList = activeProviders.map(
      (pippaId) => providerNamesObject[pippaId].name,
    );
    return providerNamesList;
  }
  return [];
};

export const getProviderStatuses = (state) => {
  const providerNamesObject = get(state, 'firestore.ordered.providers[0]');
  if (providerNamesObject) {
    const providerStatuses = {};

    const activeProviders = Object.keys(providerNamesObject).filter(
      (pippaId) => pippaId !== 'id'
        && providerNamesObject[pippaId].showToCustomers !== false,
    );

    activeProviders.forEach((pippaId) => {
      const providerName = providerNamesObject[pippaId].name.replace(/\s+/g, '');
      providerStatuses[providerName] = providerNamesObject[pippaId].status;
    });

    return providerStatuses;
  }
  return {};
};

export const getIsVerified = (state) => {
  const user = getUser(state);
  const emailVerified = get(user, 'verification.email.verified', false);
  // const mobileVerified = get(user, 'verification.mobile.verified');
  return Boolean(emailVerified);
};

export const getRequestedVerification = (state) => {
  const user = getUser(state);
  const verficationRequestedDate = get(
    user,
    'verification.email.verficationRequestedDate',
  );
  return verficationRequestedDate;
};

export const getHasRecentlyBookedOngoingAppointment = (state) => {
  const appointments = get(state, 'firestore.ordered.appointments', []);
  return appointments.some(({ type, bookedOn }) => {
    return type === 'ongoing' && moment().diff(bookedOn, 'days') <= 7;
  });
};

export const getOnboardingCds = (state) => {
  return getAllCds(state).find((cds) => cds.onboarding);
};

export const getNationalInsuranceNumber = (state) => {
  const user = getUser(state);

  return get(user, 'personal.nationalInsuranceNumber');
};

export const getSignupNationalInsuranceNumber = (state) => {
  const signup = getSignup(state);

  return get(signup, 'customer.nationalInsuranceNumber');
};

export const getHasNationalInsuranceNumber = (state) => Boolean(
  getNationalInsuranceNumber(state)
      || getSignupNationalInsuranceNumber(state),
);

export const getHasRequiredAddressFields = (state) => {
  const user = getUser(state);
  const signup = getSignup(state);

  const userCity = get(user, 'contact.address.city');
  const userPostalCode = get(user, 'contact.address.postalCode');
  const userStreet = get(user, 'contact.address.street');
  const userHasAllAddressFields = Boolean(
    userCity && userPostalCode && userStreet,
  );

  const signupCity = get(signup, 'customer.address.line1');
  const signupPostcode = get(signup, 'customer.address.postcode');
  const signupStreet = get(signup, 'customer.address.town');
  const signupHasAllAddressFields = Boolean(
    signupCity && signupPostcode && signupStreet,
  );

  return userHasAllAddressFields || signupHasAllAddressFields;
};

export const getDigitalSignature = (state) => {
  const user = getUser(state);
  const signup = getSignup(state);
  const signupSignature = get(signup, 'customer.signature');
  const customerSignature = get(user, 'signature');
  return customerSignature || signupSignature;
};

export const getHasDigitalSignature = (state) => {
  const user = getUser(state);
  const signup = getSignup(state);
  const signupSignature = get(signup, 'customer.signature');
  const customerSignature = get(user, 'signature');
  return Boolean(customerSignature) || Boolean(signupSignature);
};

export const getHasMobileNumber = (state) => {
  const user = getUser(state);
  const signup = getSignup(state);

  return Boolean(
    get(user, 'contact.mobile') || get(signup, 'customer.preferredPhoneNumber'),
  );
};

export const getHasPreviousAddresses = (state) => {
  const user = getUser(state);
  const userPreviousAddress = get(user, 'personal.previousAddresses');

  return userPreviousAddress;
};

export const getOpenRetirementPlans = (state) => {
  const retirementPlans = get(state, 'firestore.ordered.retirementPlans', []);
  const pippaId = getPippaId(state);
  const retirementPlansMatchingCustomer = retirementPlans.filter(
    (rp) => rp.customerId === pippaId,
  );
  const openRetirementPlans = retirementPlansMatchingCustomer.filter((rp) => [1, 2, 3, 4, 5].includes(rp.status));

  return openRetirementPlans;
};

export const getAuthoritativeRetirementPlan = (state) => {
  const openRetirementPlans = getOpenRetirementPlans(state);
  if (openRetirementPlans.length === 1) return openRetirementPlans[0];
  return undefined;
};

export const getLatestClosedEncashmentRetirementPlan = (state) => {
  const retirementPlans = get(state, 'firestore.ordered.retirementPlans') || [];
  const copyOfRetirementPlans = [...retirementPlans];
  const sortedRetirementPlans = copyOfRetirementPlans.sort(
    (a, b) => new Date(b.createdDate) - new Date(a.createdDate),
  );
  const latestRetirementPlan = sortedRetirementPlans[0];
  const retirementPlanStatus = get(latestRetirementPlan, 'status');
  const latestRetirementPlanCancellationReason = get(
    latestRetirementPlan,
    'onGoingServiceCancellationReason',
  );

  if (
    retirementPlanStatus === 6
    && latestRetirementPlanCancellationReason === 'full-encashment'
  ) {
    return latestRetirementPlan;
  }
  return undefined;
};

export const getLatestPensionValue = (state) => {
  const authoritativeRetirementPlan = getAuthoritativeRetirementPlan(state);
  const latestPensionValue = get(
    authoritativeRetirementPlan,
    'latestPensionValue',
  );
  return latestPensionValue;
};

export const getModelDescriptor = (state) => {
  const authoritativeRetirementPlan = getAuthoritativeRetirementPlan(state);
  const modelDescriptor = get(authoritativeRetirementPlan, 'modelDescriptor');
  return modelDescriptor;
};

export const getHasMiddleName = (state) => {
  const user = getUserProfile(state);
  const hasMiddleName = get(user, 'personal.hasMiddleName');
  return hasMiddleName;
};

export const getUkResident = (state) => {
  const user = getUserProfile(state);
  const ukResident = get(user, 'personal.ukResident');
  return ukResident;
};

export const getMiddleName = (state) => {
  const user = getUserProfile(state);
  const middleName = get(user, 'personal.name.middle');
  return middleName;
};

export const getHasPreviousNames = (state) => {
  const user = getUserProfile(state);
  const hasPreviousNames = get(user, 'personal.hasPreviousNames');
  return hasPreviousNames;
};

export const getPreviousNames = (state) => {
  const user = getUserProfile(state);
  const previousNames = get(user, 'personal.name.previousNames');
  return previousNames;
};

export const getBornInTheUk = (state) => {
  const user = getUserProfile(state);
  const bornInTheUk = get(user, 'personal.bornInTheUk');
  return bornInTheUk;
};

export const getCountryOfBirth = (state) => {
  const user = getUserProfile(state);
  const countryOfBirth = get(user, 'personal.countryOfBirth');
  return countryOfBirth;
};

export const getHasPreviousTaxResidencyOutsideUk = (state) => {
  const user = getUserProfile(state);
  const hasPreviousTaxResidencyOutsideUk = get(
    user,
    'personal.hasPreviousTaxResidencyOutsideUk',
  );
  return hasPreviousTaxResidencyOutsideUk;
};

export const getCountriesOfPreviousResidence = (state) => {
  const user = getUserProfile(state);
  const countriesOfPreviousResidence = get(
    user,
    'personal.countriesOfPreviousResidence',
  );
  return countriesOfPreviousResidence;
};

export const getMissingDetailsRequiredFields = (state, currentFlow = null) => {
  const PREVENT_ONLY_MOBILE = true;

  const hasNationalInsuranceNumber = getHasNationalInsuranceNumber(state);
  const hasRequiredAddressFields = getHasRequiredAddressFields(state);
  const hasDigitalSignature = getHasDigitalSignature(state);
  const hasMobileNumber = getHasMobileNumber(state);
  const hasPreviousAddresses = getHasPreviousAddresses(state);

  const shouldSkipSignature = (currentFlow === 'add-contribution'
      || getHasOnlyInProgressContributionInstruction(state))
    && currentFlow !== 'add-pension';

  const {
    NINO,
    ADDRESS,
    MOBILE_NUMBER,
    SIGNATURE,
    PREVIOUS_ADDRESSES,
  } = PERSONAL_DETAILS_FIELDS;

  const requiredFieldsMap = {
    ...(!shouldSkipSignature && {
      [SIGNATURE]: { hasField: hasDigitalSignature, mandatory: true },
    }),
    [NINO]: { hasField: hasNationalInsuranceNumber, mandatory: true },
    [ADDRESS]: { hasField: hasRequiredAddressFields, mandatory: true },
    [MOBILE_NUMBER]: { hasField: hasMobileNumber, mandatory: false },
    [PREVIOUS_ADDRESSES]: { hasField: hasPreviousAddresses, mandatory: false },
  };

  // Creates a list of all required fields in the form [{ fieldName: 'NINO', mandatory: true }]
  const requiredFields = Object.entries(requiredFieldsMap)
    .filter(([, { hasField }]) => !hasField)
    .map(([fieldName, { mandatory }]) => ({ fieldName, mandatory }));

  if (!requiredFields || requiredFields.length === 0) {
    return [];
  }

  const onlyMobileRequired = requiredFields.length === 1 && requiredFields[0].fieldName === MOBILE_NUMBER;

  if (PREVENT_ONLY_MOBILE && onlyMobileRequired) {
    return [];
  }

  // Creates a list of all required fields in the form ['NINO', 'SIGNATURE']
  const requiredFieldsArray = requiredFields.map(({ fieldName }) => fieldName);

  return requiredFieldsArray;
};

// Checks which required personal details are missing and then adds
// the relevant steps to the journey. If all steps in the journey are a 'nice-to-have' requirement
// the journey is set to an [].

export const getHasRecentlyAddedFindCheckAndTransferPension = (state) => get(state, 'investmentAdvice.setHasRecentlyAddedFindCheckAndTransferPensionAction');

const getInitialJourney = (state, flow) => {
  const customerHasNino = getHasNationalInsuranceNumber(state);
  const customerHasMobile = getPhoneNumber(state);
  const hasDigitalSignature = getHasDigitalSignature(state);
  const customerHasGivenPreviousAddresses = getHasPreviousAddresses(state);
  const hasRequiredAddressFields = getHasRequiredAddressFields(state);
  const contributionSteps = (flow === 'missing-details-cta' && getHasOnlyInProgressContributionInstruction(state)) || flow === 'add-contribution';
  const lumpSumContributionSteps = (flow === 'missing-details-cta' && getHasInProgressLumpSumInstructions(state)) || flow === 'setup-lump-sum';
  const getAllPensions = get(state, 'firestore.ordered.investmentAdviceOnboarding[0].pensions', []);

  const pensionsWithTransferChecksOrLegacy = getAllPensions.some((p) => p.transferOption === 'legacy' || p.transferOption === 'transfer-with-check');

  const journey = [];

  if (contributionSteps || lumpSumContributionSteps) {
    if (!customerHasNino) {
      journey.push({
        NATIONAL_INSURANCE_NUMBER: { fieldRequired: 'required' },
      });
    }
    if (!hasRequiredAddressFields) {
      journey.push({
        CURRENT_ADDRESS: { fieldRequired: 'required' },
      });
    }
    if (!customerHasMobile) {
      journey.push({
        MOBILE_NUMBER: { fieldRequired: 'nice-to-have' },
      });
    }
    if (!customerHasGivenPreviousAddresses || customerHasGivenPreviousAddresses.length === 0) {
      journey.push({
        PREVIOUS_ADDRESSES: { fieldRequired: 'nice-to-have' },
      });
    }
    journey.push({
      THANK_YOU: { fieldRequired: 'nice-to-have' },
    });
    return journey;
  }

  if (!customerHasNino) {
    journey.push({
      NATIONAL_INSURANCE_NUMBER: { fieldRequired: 'required' },
    });
  }
  if (!hasDigitalSignature && (!getHasOnlyInProgressContributionInstruction(state) && !getHasInProgressLumpSumInstructions(state))) {
    journey.push({
      SIGNATURE: { fieldRequired: 'required' },
    });
  }
  if (!customerHasMobile) {
    journey.push({
      MOBILE_NUMBER: { fieldRequired: 'nice-to-have' },
    });
  }
  if (!hasRequiredAddressFields) {
    journey.push({
      CURRENT_ADDRESS: { fieldRequired: 'required' },
    });
  }
  if ((!customerHasGivenPreviousAddresses || customerHasGivenPreviousAddresses.length === 0)
  && (pensionsWithTransferChecksOrLegacy || getHasRecentlyAddedFindCheckAndTransferPension(state))) {
    journey.push({
      PREVIOUS_ADDRESSES: { fieldRequired: 'nice-to-have' },
    });
  }
  journey.push({
    THANK_YOU: { fieldRequired: 'nice-to-have' },
  });
  return journey;
};

export const isNinoIncluded = (state, flow) => {
  const journey = getInitialJourney(state, flow);
  const insertNinoIntroStep = journey.every((step) => {
    if (Object.keys(step)[0] !== 'NATIONAL_INSURANCE_NUMBER') {
      journey.unshift({ NATIONAL_INSURANCE_NUMBER: { fieldRequired: 'nice-to-have' } });
    }
    return insertNinoIntroStep;
  });
  return journey;
};

export const getStepWithRequiredFields = (state, flow) => {
  const journey = getInitialJourney(state, flow);
  const isAllNiceToHave = journey.every((step) => {
    return Object.values(step)[0].fieldRequired === 'nice-to-have';
  });

  const finalJourney = journey.map((step) => {
    const missingDetailsStep = Object.keys(step)[0];
    if (missingDetailsStep === 'PREVIOUS_ADDRESSES') {
      return [
        'ADDRESS_HISTORY',
        'ADD_A_PREVIOUS_ADDRESS',
        'CONFIRM_ADDRESS',
      ];
    }
    return missingDetailsStep;
  });

  if (isAllNiceToHave) {
    return [];
  }
  if (!finalJourney.includes('NATIONAL_INSURANCE_NUMBER')) {
    finalJourney.unshift('NATIONAL_INSURANCE_NUMBER');
  }

  return finalJourney.flat();
};

export const getIsPersonalDetailsRequired = (state, flow) => {
  return getStepWithRequiredFields(state, flow).length > 0;
};

export const getIsNewOngoingCdsRequiredAndAllowed = (state) => {
  const ongoingService = getOngoingServiceFromOnboarding(state);
  const requiredAndAllowed = Boolean(
    get(ongoingService, 'isNewCdsRequired')
      && get(ongoingService, 'isNewCdsAllowed'),
  );
  return requiredAndAllowed;
};

export const getIsNewOngoingCdsMandatory = (state) => {
  const ongoingService = getOngoingServiceFromOnboarding(state);
  const mandatoryAndAllowed = Boolean(get(ongoingService, 'isNewCdsMandatory'));
  return mandatoryAndAllowed;
};

export const getShouldShowMentionMe = (state) => (Boolean(getLatestCompleteCds(state) || getIsCustomerOngoing(state)) && SHOW_MENTION_ME);

export const getIVUCollection = (state) => {
  return get(
    state,
    'firestore.ordered.ivuCollection[0].investmentVehicleUniverse',
  );
};

export const getPreAuthEmail = (state) => get(state, 'auth.email');

export const getTwoFactorAuthenticationEnabled = (state) => {
  const user = getUserProfile(state);
  const twoFactorAuthenticationEnabled = get(
    user,
    'verification.mobile.verified',
  );
  return twoFactorAuthenticationEnabled;
};

export const getHasTwoFactorAuthCTABeenClicked = (state) => get(state, 'investmentAdvice.twoFactorAuthCTAHasBeenClicked');

export const getUserProfileCreatedOn = (state) => {
  const user = getUserProfile(state);
  const createdOn = get(user, 'createdOn');
  return createdOn && createdOn.toDate();
};

export const getUserProfileCreatedBeforeMillis = (state, millis) => {
  const userProfileCreatedOn = getUserProfileCreatedOn(state);
  return Math.abs(userProfileCreatedOn - new Date().getTime()) < millis;
};

export const getOverallContributions = (state) => {
  const retirementPlan = getAuthoritativeRetirementPlan(state);
  return get(retirementPlan, 'overallContributions', null);
};

export const getVerifiedNetPersonalContributions = (state) => {
  const retirementPlan = getAuthoritativeRetirementPlan(state);
  const personalContributionsStatus = get(retirementPlan, 'personalContributions.status');
  const personalContributionsNet = get(retirementPlan, 'personalContributions.netValue');

  if (personalContributionsStatus !== 'verified' || !personalContributionsNet) return undefined;
  return personalContributionsNet;
};

export const getVerifiedGrossEmployerContributions = (state) => {
  const retirementPlan = getAuthoritativeRetirementPlan(state);
  const employerContributionsStatus = get(retirementPlan, 'employerContributions.status');
  const employerContributionsGross = get(retirementPlan, 'employerContributions.grossValue');

  if (employerContributionsStatus !== 'verified' || !employerContributionsGross) return undefined;
  return employerContributionsGross;
};

export const getVerifiedPersonalContributionsValue = (state) => {
  const overallContributions = getOverallContributions(state);

  const verified = get(overallContributions, 'status', false) === 'verified';
  if (!verified) {
    return undefined;
  }

  return get(overallContributions, 'totalGrossValue', undefined);
};

export const getImportantConsiderationsRouting = (state) => state
  .withdrawal.importantConsiderationsRouting;

export const getCanFetchEstimatedIncome = (state) => {
  const retirementPlan = getAuthoritativeRetirementPlan(state);
  const modelDescriptor = get(retirementPlan, 'modelDescriptor');
  if (!modelDescriptor) return false;
  const currentAge = getAge(state);
  const retirementAge = getIntendedRetirementAge(state);
  const atOrPastRetirementAge = currentAge >= retirementAge;
  if (atOrPastRetirementAge) return false;

  const pensionValue = get(retirementPlan, 'latestPensionValue');
  const valuationDate = get(retirementPlan, 'valuationDate');

  const balanceUnavailable = typeof pensionValue !== 'number' || !valuationDate;
  return !balanceUnavailable;
};
