export function formatContributionEvent(eventKey) {
  return `pp/contributions/${eventKey}`;
}

export function formatOngoingContributionEvent(eventKey) {
  return `pp/contributions/ONGOING_${eventKey}`;
}

export function formatLumpSumEvent(eventKey) {
  return `pp/lumpSum/${eventKey}`;
}

export function formatOngoingLumpSumEvent(eventKey) {
  return `pp/lumpSum/ONGOING_${eventKey}`;
}

export default {
  formatContributionEvent,
  formatOngoingContributionEvent,
};
