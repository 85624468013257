/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';
import HtmlSelectField from '../../components/HtmlSelectField/HtmlSelectField';
import { currencyString } from '../../util/currency';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import TextField from '../../components/TextField/TextField';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import { getPensionExpert } from '../../redux/selectors/investmentAdvice';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import {
  lumpSumSourceOfFundsStepVisitedAction,
  ongoingLumpSumSourceOfFundsStepVisitedAction,
  returnLinkClickedAction,
  ongoingReturnLinkClickedAction,
  sourceOfFundsChangedAction,
  ongoingSourceOfFundsChangedAction,
  otherSourceOfFundsChangedAction,
  ongoingOtherSourceOfFundsChangedAction,
  occupationChangedAction,
  ongoingOccupationChangedAction,
  salaryChangedAction,
  ongoingSalaryChangedAction,
  nextClickedAction,
  ongoingNextClickedAction,
  confirmClickedAction,
  ongoingConfirmClickedAction,
} from '../../redux/modules/lumpSum';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import { lumpSumSourceOfMoneyOptions } from '../../util/constants';

import styles from './SetupLumpSumPage.css';

const LumpSumSourceOfFundsStep = ({
  setFormData,
  formData,
  setStep,
  step,
  amendMode,
  totalSteps,
  dispatchLumpSumSourceOfFundsStepVisited,
  dispatchReturnLinkClicked,
  dispatchSourceOfFundsChanged,
  dispatchOtherSourceOfFundsChanged: _dispatchOtherSourceOfFundsChanged,
  dispatchOccupationChanged: _dispatchOccupationChanged,
  dispatchSalaryChanged: _dispatchSalaryChanged,
  dispatchNextClicked,
  dispatchConfirmClicked,
}) => {
  useEffect(() => {
    dispatchLumpSumSourceOfFundsStepVisited();
    window.scrollTo(0, 0);
  }, []);

  const [dispatchOtherSourceOfFundsChanged] = useState(
    () => once(_dispatchOtherSourceOfFundsChanged),
  );
  const [dispatchOccupationChanged] = useState(
    () => once(_dispatchOccupationChanged),
  );
  const [dispatchSalaryChanged] = useState(
    () => once(_dispatchSalaryChanged),
  );

  const shouldAskAdditionalAmlQuestions = formData.netAmount > 10000;
  const disableLumpSumDetailsStepNext = !formData.sourceOfFunds
    || !/\S/g.test(formData.sourceOfFunds)
    || (
      shouldAskAdditionalAmlQuestions
      && (!formData.occupation || !/\S/g.test(formData.occupation) || (!formData.salary && formData.salary !== 0))
    );

  return (
    <BaseGrid pageSectionStyles={{ marginBottom: '200px' }} addPensionsTheme>
      <div className={styles.contributionsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          secondary
          mid
          onClick={() => {
            dispatchReturnLinkClicked();
            setStep(step - 1);
          }}
          label="← Back"
          wrapperStyles={{ margin: '0 0 30px' }}
        />
        <h2 className={styles.contributionsHeading}>{'Source of money'}</h2>
        <HtmlSelectField
          fullWidth
          wrapperStyles={{ margin: '20px 0 20px 0' }}
          value={formData.sourceOfFundsDropdown}
          options={lumpSumSourceOfMoneyOptions}
          placeholder={'Select from list'}
          onChange={(event) => {
            dispatchSourceOfFundsChanged();
            const selectedValue = event.target.value;
            if (selectedValue === 'other') {
              setFormData({
                ...formData,
                sourceOfFundsDropdown: selectedValue,
                sourceOfFunds: undefined,
              });
            } else {
              setFormData({
                ...formData,
                sourceOfFundsDropdown: selectedValue,
                sourceOfFunds: selectedValue,
              });
            }
          }}
        />
        {formData.sourceOfFundsDropdown === 'other' && (
          <React.Fragment>
            <TextField
              wrapperStyles={{ margin: '-10px 0 15px' }}
              fieldStyles={{ height: '55px' }}
              name="otherSourceOfFunds"
              fullWidth
              value={formData.sourceOfFunds}
              defaultValue={'Please state source of money'}
              onChange={(event) => {
                dispatchOtherSourceOfFundsChanged();
                const selectedValue = event.target.value;
                setFormData({ ...formData, sourceOfFunds: selectedValue });
              }}
            />
          </React.Fragment>
        )}
        {shouldAskAdditionalAmlQuestions && (
          <React.Fragment>
            <div className={styles.contributionDivider} />
            <TextField
              wrapperStyles={{ margin: '0 0 15px' }}
              fieldStyles={{ height: '55px' }}
              name="occupation"
              fullWidth
              value={formData.occupation}
              defaultValue={'Eg. Solicitor'}
              label={'Occupation'}
              onChange={(event) => {
                dispatchOccupationChanged();
                const selectedValue = event.target.value;
                setFormData({ ...formData, occupation: selectedValue });
              }}
            />
            <div className={styles.contributionDivider} />
            <TextField
              wrapperStyles={{ margin: 0 }}
              name="salary"
              label="Salary"
              defaultValue="Eg. £70,000"
              value={formData.salaryMask}
              fieldStyles={{ height: '55px' }}
              onChange={(event) => {
                dispatchSalaryChanged();
                const strippedInput = event.target.value.replace(/\D/g, '');
                if (strippedInput) {
                  const salary = parseInt(strippedInput, 10);
                  const salaryMask = currencyString(salary);
                  setFormData({
                    ...formData,
                    salary,
                    salaryMask,
                  });
                } else {
                  setFormData({ ...formData, salary: undefined, salaryMask: '' });
                }
              }}
            />
            <p className={styles.regulation}>{'We ask this question as it forms part of our regulatory checks, we do not use this information to check whether you are eligible for tax relief on contributions.'}</p>
          </React.Fragment>
        )}
        <Button
          size="large"
          disabled={disableLumpSumDetailsStepNext}
          label={amendMode ? 'Confirm' : 'Next'}
          onClick={() => {
            if (amendMode) {
              dispatchConfirmClicked();
              setStep(4);
            } else {
              dispatchNextClicked();
              setStep(step + 1);
            }
          }}
        />
      </div>
      <div className={styles.pensionAdviserContainer}>
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'LumpSumAmountStep'}
            noExpertContentWrapper={({ children }) => (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexShrink: 'initial',
                padding: '10px',
                flexWrap: 'wrap',
              }}
              >
                {children}
              </div>
            )}
            showButton={false}
            customMessage={(
              <div className={styles.contactUsInnerContent}>
                {'We need to ask you how you accumulated the money you are investing into your pension to comply with anti-money laundering regulations. If you have any questions, please feel free to '}
                <span>
                  <ButtonLink
                    variant="primary"
                    to="/inbox/new-message"
                    font="inherit"
                    label={'message me'}
                  />
                  {'.'}
                </span>
              </div>
            )}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  pensionAdviser: getPensionExpert(state),
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  _dispatchOnboardingLumpSumSourceOfFundsStepVisited: lumpSumSourceOfFundsStepVisitedAction,
  _dispatchOngoingLumpSumSourceOfFundsStepVisited: ongoingLumpSumSourceOfFundsStepVisitedAction,
  _dispatchOnboardingReturnLinkClicked: returnLinkClickedAction,
  _dispatchOngoingReturnLinkClicked: ongoingReturnLinkClickedAction,
  _dispatchOnboardingSourceOfFundsChanged: sourceOfFundsChangedAction,
  _dispatchOngoingSourceOfFundsChanged: ongoingSourceOfFundsChangedAction,
  _dispatchOnboardingOtherSourceOfFundsChanged: otherSourceOfFundsChangedAction,
  _dispatchOngoingOtherSourceOfFundsChanged: ongoingOtherSourceOfFundsChangedAction,
  _dispatchOnboardingOccupationChanged: occupationChangedAction,
  _dispatchOngoingOccupationChanged: ongoingOccupationChangedAction,
  _dispatchOnboardingSalaryChanged: salaryChangedAction,
  _dispatchOngoingSalaryChanged: ongoingSalaryChangedAction,
  _dispatchOnboardingNextClicked: nextClickedAction,
  _dispatchOngoingNextClicked: ongoingNextClickedAction,
  _dispatchOnboardingConfirmClicked: confirmClickedAction,
  _dispatchOngoingConfirmClicked: ongoingConfirmClickedAction,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isCustomerOngoing } = stateProps;
  const {
    _dispatchOnboardingLumpSumSourceOfFundsStepVisited,
    _dispatchOngoingLumpSumSourceOfFundsStepVisited,
    _dispatchOnboardingReturnLinkClicked,
    _dispatchOngoingReturnLinkClicked,
    _dispatchOnboardingSourceOfFundsChanged,
    _dispatchOngoingSourceOfFundsChanged,
    _dispatchOnboardingOtherSourceOfFundsChanged,
    _dispatchOngoingOtherSourceOfFundsChanged,
    _dispatchOnboardingOccupationChanged,
    _dispatchOngoingOccupationChanged,
    _dispatchOnboardingSalaryChanged,
    _dispatchOngoingSalaryChanged,
    _dispatchOnboardingNextClicked,
    _dispatchOngoingNextClicked,
    _dispatchOnboardingConfirmClicked,
    _dispatchOngoingConfirmClicked,
  } = dispatchProps;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    dispatchLumpSumSourceOfFundsStepVisited: isCustomerOngoing ? _dispatchOngoingLumpSumSourceOfFundsStepVisited : _dispatchOnboardingLumpSumSourceOfFundsStepVisited,
    dispatchReturnLinkClicked: isCustomerOngoing ? _dispatchOngoingReturnLinkClicked : _dispatchOnboardingReturnLinkClicked,
    dispatchSourceOfFundsChanged: isCustomerOngoing ? _dispatchOngoingSourceOfFundsChanged : _dispatchOnboardingSourceOfFundsChanged,
    dispatchOtherSourceOfFundsChanged: isCustomerOngoing ? _dispatchOngoingOtherSourceOfFundsChanged : _dispatchOnboardingOtherSourceOfFundsChanged,
    dispatchOccupationChanged: isCustomerOngoing ? _dispatchOngoingOccupationChanged : _dispatchOnboardingOccupationChanged,
    dispatchSalaryChanged: isCustomerOngoing ? _dispatchOngoingSalaryChanged : _dispatchOnboardingSalaryChanged,
    dispatchNextClicked: isCustomerOngoing ? _dispatchOngoingNextClicked : _dispatchOnboardingNextClicked,
    dispatchConfirmClicked: isCustomerOngoing ? _dispatchOngoingConfirmClicked : _dispatchOnboardingConfirmClicked,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LumpSumSourceOfFundsStep);
