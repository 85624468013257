import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Modal from '../Modal/Modal';
import DotNavigation from '../DotNavigation/DotNavigation';
import Button from '../Button/Button';
import mfmAnnouncementContent from './MfmAnnouncementContent';

import { getFirebaseUid } from '../../redux/selectors';
import { getHasMfmAnnouncementModalBeenDismissed } from '../../redux/selectors/notificationTracking';
import {
  mfmAnnouncementModalViewAction,
  mfmAnnouncementModalClosed,
} from '../../redux/modules/notificationTracking';

import styles from './MfmAnnouncementModal.css';

const MfmAnnouncementModal = ({
  uid,
  dispatchMfmAnnouncementModalView,
  dispatchMfmAnnouncementModalClosed,
  hasMfmAnnouncementModalBeenDismissed,
}) => {
  const [showModal, setShowModal] = useState(!hasMfmAnnouncementModalBeenDismissed);
  const [step, setStep] = useState(0);

  useEffect(() => {
    dispatchMfmAnnouncementModalView();
    window.forceIntroModal = () => {
      setShowModal(true);
      setStep(0);
    };
  }, []);

  const modalSteps = mfmAnnouncementContent();
  const {
    icon,
    iconWidth,
    heading,
    body,
    ctaLabel,
  } = modalSteps[step];

  const handleCtaButton = async () => {
    if (step < modalSteps.length - 1) {
      setStep(step + 1);
    } else {
      dispatchMfmAnnouncementModalClosed(uid);
      setShowModal(false);
    }
  };

  return (
    <Modal
      show={showModal}
      centerContent
      introModal
      modalFullMaxWidth
      foggyBackdrop
    >
      <div className={styles.mfmAnnouncementModalContainer}>
        <div className={styles.imageContainer}>
          <img className={styles.image} src={icon} alt="" style={{ maxWidth: iconWidth }} />
        </div>
        <div className={styles.textContainer}>
          <h2 className={styles.heading}>{heading}</h2>
          <p className={styles.body}>{body}</p>
        </div>
        <div className={styles.navigationAndButtonWrapper}>
          <DotNavigation
            dotCount={modalSteps.length}
            dotStep={step}
            onClick={setStep}
            grassActiveDot
          />
          <div className={styles.ctaButtonContainer}>
            <Button size="large" onClick={handleCtaButton} label={ctaLabel} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
  hasMfmAnnouncementModalBeenDismissed: getHasMfmAnnouncementModalBeenDismissed(state),
});

const mapDispatchToProps = ({
  dispatchMfmAnnouncementModalView: mfmAnnouncementModalViewAction,
  dispatchMfmAnnouncementModalClosed: mfmAnnouncementModalClosed,
});

export default connect(mapStateToProps, mapDispatchToProps)(MfmAnnouncementModal);
