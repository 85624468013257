import React from 'react';

import BasePage from '../../components/BasePage/BasePage';
import WithIntercom from '../../components/WithIntercom/WithIntercom';
import Register from '../../forms/Register/Register';

const RegisterPage = () => (
  <WithIntercom>
    <BasePage showFooter={false}>
      <Register />
    </BasePage>
  </WithIntercom>
);

export default RegisterPage;
