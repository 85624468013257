import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';

export const VALUE_CHANGE = 'VALUE_CHANGE';
export const LOAD_PROJECTION = 'LOAD_PROJECTION';
export const TOGGLE_SCENARIO = 'TOGGLE_SCENARIO';
export const POPULATE_ANNUAL_INCOME = 'POPULATE_ANNUAL_INCOME';
export const RESET_FLOW = 'RESET_FLOW';

export const MIN_MONETARY_VALUE = 1;
export const MAX_MONETARY_VALUE = 1000000000;

export const MIN_RETIREMENT_AGE = 55;
export const MAX_RETIREMENT_AGE = 80;

export const initialState = {
  currentProjectedAnnualIncome: 0,
  newProjectedAnnualIncome: 0,
  scenarios: {
    transferPension: {
      enabled: false,
      value: '',
      valueMask: '',
      error: null,
    },
    monthlyContributions: {
      enabled: false,
      value: '',
      valueMask: '',
      error: null,
    },
    addLumpSum: {
      enabled: false,
      value: '',
      valueMask: '',
      error: null,
    },
    changeRetirementAge: {
      enabled: false,
      value: null,
      error: null,
    },
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case VALUE_CHANGE: {
      return {
        ...state,
        scenarios: {
          ...state.scenarios,
          [action.scenario]: {
            ...state.scenarios[action.scenario],
            ...(isNil(action.valueMask) ? '' : { valueMask: action.valueMask }),
            enabled: true,
            value: isUndefined(action.value) ? null : action.value,
            error: isUndefined(action.error) ? null : action.error,
          },
        },
      };
    }
    case LOAD_PROJECTION: {
      return {
        ...state,
        scenarios: action.scenarios,
        currentProjectedAnnualIncome: action.currentProjectedAnnualIncome,
        newProjectedAnnualIncome: action.newProjectedAnnualIncome,
      };
    }
    case TOGGLE_SCENARIO:
      return {
        ...state,
        scenarios: {
          ...state.scenarios,
          [action.scenario]: {
            ...state.scenarios[action.scenario],
            enabled: !state.scenarios[action.scenario].enabled,
          },
        },
      };
    case POPULATE_ANNUAL_INCOME:
      return {
        ...state,
        currentProjectedAnnualIncome: action.currentProjectedAnnualIncome,
        newProjectedAnnualIncome: action.newProjectedAnnualIncome,
      };
    case RESET_FLOW: {
      return {
        ...initialState,
      };
    }
    default: return state;
  }
};
