import firebase from 'firebase/compat/app';

// eslint-disable-next-line import/prefer-default-export
export const refreshSignedUrlsAction = (isCustomerOngoing) => async (dispatch) => {
  dispatch({ type: `pp/documents/${isCustomerOngoing ? 'ONGOING_' : ''}REFRESH_SIGNED_URLS_DISPATCHED` });
  const refreshSignedUrls = firebase.app().functions('europe-west1').httpsCallable('refreshSignedUrls');
  try {
    await refreshSignedUrls();
    dispatch({ type: `pp/documents/${isCustomerOngoing ? 'ONGOING_' : ''}REFRESH_SIGNED_URLS_FULFILLED` });
  } catch (e) {
    dispatch({ type: `pp/documents/${isCustomerOngoing ? 'ONGOING_' : ''}REFRESH_SIGNED_URLS_REJECTED` });
  }
};
