import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import styles from './AlertCard.css';
import WarningTriangle from '../../assets/images/icons/Icon_Warning_Triangle.svg';
import WarningCircle from '../../assets/images/icons/Icon_Warning_Circle.svg';
import SuccessTick from '../../assets/images/icons/Icon_Alert_Success.svg';
import CloseNotification from '../../assets/images/icons/Icon_Close_Notification.svg';
import ErrorCross from '../../assets/images/icons/Icon_Status_Cross.svg';
import Button from '../Button/Button';

class ServiceAlertCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      dispatch,
      alertTitle,
      alertSeverity,
      alertDescription,
      alertBody,
      detached,
      button,
      icon,
      closeButton,
      closeButtonCallback,
      dataCyPrefix = '',
      wrapperStyles,
    } = this.props;

    const classNames = classnames(
      styles.alertContainer,
      styles[alertSeverity],
      {
        [styles.detached]: detached,
      },
    );
    const icons = {
      warningTriangle: WarningTriangle,
      warningCircle: WarningCircle,
      success: SuccessTick,
      errorCross: ErrorCross,
    };
    let iconToDisplay = icons[icon];

    // Icon defaulting to support parents that don't care
    if (!iconToDisplay) {
      const defaultIconsFromSeverity = {
        warning: WarningTriangle,
        success: SuccessTick,
      };
      iconToDisplay = defaultIconsFromSeverity[alertSeverity];
    }

    if (!iconToDisplay || (!alertBody && !alertDescription && !alertTitle)) {
      return null;
    }

    return (
      <div data-cy={`${dataCyPrefix}alert-cta`} style={wrapperStyles}>
        <div className={classNames}>
          <div className={styles.messageContainer}>
            <div className={styles.icon}>
              <img src={iconToDisplay} alt="Alert Icon" />
            </div>
            <div className={styles.text} data-cy={`${dataCyPrefix}alert-cta-text`}>
              <h6>{alertTitle}</h6>
              {
                alertBody || (
                  <p className="body-one">
                    {alertDescription}
                  </p>
                )
              }
            </div>
            {closeButton && closeButtonCallback && (
              <div>
                <button type="button" className={styles.closeButton} onClick={closeButtonCallback} data-cy={`${dataCyPrefix}close-notif`}>
                  <img src={CloseNotification} alt="Close Notification Icon" />
                </button>
              </div>
            )}
          </div>
          <div className={styles.buttonContainer}>
            {button && (
              <Button
                size="large"
                label={button.label}
                link={button.link}
                to={button.to}
                onClick={() => {
                  dispatch({ type: button.event });
                  if (button.handleClick) {
                    button.handleClick();
                  }
                }}
                dataCy={button.dataCy}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

ServiceAlertCard.defaultProps = {
  wrapperStyles: {},
  handleClick: () => {},
};

export default connect(() => ({}))(ServiceAlertCard);
