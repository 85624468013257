import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import closeIcon from '../../assets/images/rebranding/cross-icon-in-circle.svg';

import styles from './GlanceCard.css';

const GlanceCard = (props) => {
  const {
    hero,
    title,
    onClose,
    primary,
    children,
    bodyOne,
    bodyTwo,
    h5,
    h4,
    h3,
    black,
    wrapperStyles,
  } = props;

  const cardStyles = classnames(styles.glanceCard, { [styles.primary]: primary });

  const titleStyles = classnames(styles.title, {
    [styles.bodyTwo]: bodyTwo,
    [styles.bodyOne]: bodyOne,
    [styles.h5]: h5,
    [styles.h4]: h4,
    [styles.h3]: h3,
    [styles.dark]: black,
  });

  return (
    <div className={cardStyles} style={wrapperStyles} data-cy="glance-card">
      {hero && hero}
      {(title || onClose)
        && (
          <div className={styles.header}>
            {onClose && (
              <div className={styles.closeButton} onClick={onClose} onKeyUp={() => { }} role="button" tabIndex="0">
                <img src={closeIcon} alt="close icon" />
                {' '}
                <span className={styles.closeWrapperText}>{'Close'}</span>
              </div>
            )}
            {title && <h2 className={titleStyles}>{title}</h2>}
          </div>
        )}
      <div className={styles.cardMain}>
        {children}
      </div>
    </div>
  );
};

GlanceCard.propTypes = {
  hero: PropTypes.element,
  title: PropTypes.string,
  onClose: PropTypes.func,
  primary: PropTypes.bool,
  wrapperStyles: PropTypes.shape({}),
  bodyOne: PropTypes.bool,
  bodyTwo: PropTypes.bool,
  h5: PropTypes.bool,
  h4: PropTypes.bool,
  h3: PropTypes.bool,
};

GlanceCard.defaultProps = {
  hero: null,
  title: null,
  onClose: null,
  primary: false,
  wrapperStyles: {},
  bodyOne: false,
  bodyTwo: false,
  h5: false,
  h4: false,
  h3: false,
};

export default GlanceCard;
