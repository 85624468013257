import * as htmlToImage from 'html-to-image';

const captureScreen = () => {
  return new Promise((resolve, reject) => {
    const node = document.getElementById('app');
    htmlToImage.toPng(node)
      .then((dataUrl) => {
        if (!dataUrl || dataUrl.length < 10000) {
          return reject(new Error('Failed to capture screenshot!'));
        }
        resolve(dataUrl);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default captureScreen;
