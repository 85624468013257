import moment from 'moment';
import { ENVIRONMENT } from '../config';

// eslint-disable-next-line import/prefer-default-export
export const JOURNEYS = {
  CDS: 'cds',
  BOOK: 'book',
  ERROR: 'error',
  SIGNUP: 'signup',
  VERIFY: 'verify',
  DASHBOARD: 'dashboard',
  ONGOING_CDS: 'ongoing-cds',
  AUTHORISE_CHANGE: 'authorise-change',
  PENSION_TRANSFER: 'pensions-transfer',
  ADD_PENSIONS: 'add-pensions',
  FIND_PENSIONS: 'find-pensions',
  PERSONAL_DETAILS: 'personal-details',
  VULNERABLE_CUSTOMER: 'vulnerable-customer',
  ADD_MONEY_OPTION: 'add-money-option',
  SETUP_CONTRIBUTION: 'setup-contribution',
  SETUP_LUMP_SUM: 'setup-lump-sum',
  MISSING_DETAILS: 'missing-details',
  SERPS_MISSING_DETAILS: 'serps-request',
  WITHDRAWALS: 'withdrawals',
  SETUP_WITHDRAWAL_MONTHLY: 'setup-withdrawal-monthly',
  SETUP_WITHDRAWAL_LUMP_SUM: 'setup-withdrawal-lump-sum',
  WITHDRAWAL_LUMP_SUM_OVER_LIMIT: 'withdrawal-lump-sum-over-limit',
  MARKETING_PREFERENCES: 'marketing-preferences',
  PLAN_SUMMARY: 'plan-summary',
  PLAN_ACTIVITY: 'plan-activity',
  RETIREMENT_INCOME_PROJECTION: 'estimated-income-calculator',
  WITHDRAWALS_HUB: 'withdrawals-hub',
  WITHDRAWAL_MINI_JOURNEY: 'withdrawal-mini-journey',
  SERPS_AUTH: 'serps-auth',
  SERPS_AUTH_THANK_YOU: 'serps-thank-you',
};

export const EXPERIENCES = {
  ONBOARDING: 'onboarding',
  ONGOING: 'ongoing',
  ONGOING_MISSING_RP: 'ongoing-missing-rp',
  OPTOUT: 'opt-out',
  ONGOING_MULTIPLE_RPS: 'ongoing-multiple-rps',
  FULL_ENCASHMENT: 'full-encashment',
};

export const CLOSURE_DO_NOT_SHOW = 'do not show';
export const CLOSURE_DEFAULT = 'We’re unable to help with this pension';

export const CLOSURE_REASON_MAPPING = {
  'final-salary-pension': 'We can’t advise you on this because it’s a defined benefit pension',
  'no-response-from-provider-within-time-limit': 'Provider did not respond',
  'workplace-pension': 'We can’t advise you on this because it’s an active workplace pension',
  'unresolved-customer-details-issue-with-provider': 'Please contact provider',
  'no-pension': 'No pension found',
  'out-of-criteria-to-switch': 'Sorry, we can’t advise you on this pension',
  'already-drawing': 'We can’t advise you on this pension because it’s already in drawdown',
  'customer-agreed-not-to-review': 'You asked us not to review this pension',
  duplicate: CLOSURE_DO_NOT_SHOW,
};

/* CUSTOMER JOURNEY STATES */

export const ONGOING_STATES = {
  APPOINTMENT_REQUIRED: 'appointment-required',
  CDS_REQUIRED: 'cds-required',
  ACCEPTANCE_REQUIRED: 'acceptance-required',
  NO_ACTION: 'no-action',
};

export const ONGOING_RESULTS = {
  CHANGE_FUND: 'change-fund',
  REMOVE_LIFESTYLING: 'remove-lifestyle',
  CHANGE_PROVIDER: 'change-provider',
  NO_CHANGE: 'no-change',
};

export const ONGOING_REC_RESULT_TEXT_MAPPING = {
  [ONGOING_RESULTS.CHANGE_FUND]: 'We recommend you change your funds to stay in line with your investment objectives. ',
  [ONGOING_RESULTS.REMOVE_LIFESTYLING]: 'We recommend you make a change to your pension that removes an option called lifestyling. ',
  [ONGOING_RESULTS.CHANGE_PROVIDER]: 'We recommend you change your provider to stay in line with your investment objectives. ',
};

export const PROVIDERS = {
  METLIFE: 'metlife',
};

export const PROVIDER_TEXT = {
  aegon: 'Your pension is invested using the Aegon platform. Aegon manages over €898bn in assets for customers worldwide.',
  aviva: 'Your pension is invested with Aviva, a reputable pension provider offering financial strength and competitive charges.',
  'scottish-widows': 'Your pension is invested with Scottish Widows, a reputable pension provider offering financial strength and competitive charges.',
  lv: 'Your pension is invested with LV, a reputable pension provider offering financial strength and competitive charges.',
  metlife: 'Your pension is invested with Met Life, one of the leading providers in pension funds offering guarantees.',
  prudential: 'Your pension is invested with Prudential, a reputable pension provider offering financial strength and competitive charges.',
};

export const PROFILE_PORTFOLIOS = {
  CONSERVATIVE: 'portfolio-6-conservative',
  CAUTIOUS: 'portfolio-7-cautious',
  BALANCED: 'portfolio-3-balanced',
  GROWTH: 'portfolio-4-growth',
  ADVENTUROUS: 'portfolio-5-adventurous',
  CONSERVATIVE_ESG: 'portfolio-8',
  CAUTIOUS_ESG: 'portfolio-9',
  BALANCED_ESG: 'portfolio-10',
  GROWTH_ESG: 'portfolio-11',
  ADVENTUROUS_ESG: 'portfolio-12',
  CONSERVATIVE_21: 'portfolio-13',
  CAUTIOUS_21: 'portfolio-14',
  BALANCED_21: 'portfolio-15',
  GROWTH_21: 'portfolio-16',
  ADVENTUROUS_21: 'portfolio-17',
  CONSERVATIVE_ESG_21: 'portfolio-18',
  CAUTIOUS_ESG_21: 'portfolio-19',
  BALANCED_ESG_21: 'portfolio-20',
  GROWTH_ESG_21: 'portfolio-21',
  ADVENTUROUS_ESG_21: 'portfolio-22',
};

export const VANGUARD_LIFESTRATEGY = {
  CONSERVATIVE: 'aegon-vanguard-20',
  CAUTIOUS: 'aegon-vanguard-40',
  BALANCED: 'aegon-vanguard-60',
  GROWTH: 'aegon-vanguard-80',
  ADVENTUROUS: 'aegon-vanguard-100',
};

export const ADVISER_FEE = 0.6;

export const ONGOING_PENSION_BALANCE_MAX_MONTHS = 1;

export const RECOMMENDATION_FULFILMENT_STATUSES = {
  CLOSED: 'closed',
  CANCELLED: 'cancelled',
};

export const PENSION_TRANSFER_KEY_FILES = {
  termsOfBusiness: ENVIRONMENT === 'production' ? 'https://www.profilepensions.co.uk/terms-of-business' : 'https://staging.profilepensions.co.uk/terms-of-business',
  keyFeatures: 'https://res.cloudinary.com/profile-pensions/image/upload/v1608043457/Downloadable%20Docs/Aegon_Key-features_May_2020.pdf',
  illustration: 'https://res.cloudinary.com/profile-pensions/image/upload/v1616675978/Downloadable%20Docs/Pre-sales_illustration.pdf',
  illustrationNoArrangementFee: 'https://res.cloudinary.com/profile-pensions/image/upload/v1685450531/transfer-only-illustration.pdf',
  illustrationOnePercentArrangementFee: 'https://res.cloudinary.com/profile-pensions/image/upload/v1685450531/find-check-and-transfer-illustration.pdf',
};

export const MINIMUM_VALID_CDS_QUESTIONS_VERSION = 6;
export const RECENT_INVESTMENT_CDS_SECONDS = 10;

export const PERSONAL_DETAILS_FIELDS = {
  NINO: 'NINO',
  ADDRESS: 'ADDRESS',
  MOBILE_NUMBER: 'MOBILE_NUMBER',
  SIGNATURE: 'SIGNATURE',
  PREVIOUS_ADDRESSES: 'PREVIOUS_ADDRESSES',
};

export const PENSION_PLAN_RECOMMENDATION_CURRENT_STEPS = {
  OPEN: 'open',
  CHANGE_READY_TO_ACCEPT: 'change-ready-to-accept',
  CHANGE_ACCEPTED: 'change-accepted',
  CHANGE_SUBMITTED: 'change-submitted',
  CHANGE_COMPLETED: 'change-completed',
  NO_CHANGE_COMPLETED: 'no-change-completed',
};

export const PENSION_PLAN_OVERALL_STATUSES = {
  CLOSED: 'closed',
  BLOCKED: 'blocked',
  COMPLETED: 'completed',
  IN_PROGRESS: 'in-progress',
};

export const MISSING_ADDRESS_ERROR = 'Please find or enter your address';

export const NO_PENSION_EXPERT_MESSAGE = 'Sorry, we could not show your dedicated adviser details, we have been notified and will fix this shortly.';
export const NO_PENSION_EXPERT_TIMEOUT = 10000; // ms

/**
* 0: 'Weak!',
* 1: 'Easily cracked',
* 2: 'Could be stronger',
* 3: 'Not bad',
* 4: 'Strong!',
*/
export const MINIMUM_PASSWORD_STRENGTH = 2;

export const INDUSTRIES_LIST = [
  'Advertising and marketing',
  'Aerospace',
  'Agriculture',
  'Computer and technology',
  'Construction',
  'Education',
  'Energy',
  'Entertainment',
  'Fashion',
  'Finance and economic',
  'Food and beverage',
  'Health care',
  'Hospitality',
  'Manufacturing',
  'Media and news',
  'Mining',
  'Pharmaceutical',
  'Telecommunication',
  'Transportation',
  'Other',
];

export const WITHDRAWAL_AGE = moment().year() >= 2028 ? 57 : 55;
export const WITHDRAWAL_LUMP_SUM_COOL_OFF_PERIOD = 90; // in days
export const WITHDRAWAL_LUMP_SUM_PERCENTAGE_LIMIT = 0.25;

export const DOES_ONLINE_ACOUNT_EXIST_RESPONSES = {
  CUSTOMER_HAS_FIREBASEID: 'CUSTOMER_HAS_FIREBASEID',
  NO_USER_FOUND: 'NO_USER_FOUND',
  MULTIPLE_USERS_FOUND: 'MULTIPLE_USERS_FOUND',
  ONLINE_ACCOUNT_FOUND: 'ONLINE_ACCOUNT_FOUND',
  ONLINE_ACCOUNT_NOT_FOUND: 'ONLINE_ACCOUNT_NOT_FOUND',
};

// attachment related

export const ATTACHMENT_ACCEPTED_FILE_FORMATS = ['.pdf', '.jpg', '.jpeg', '.png'];
export const ATTACHMENT_MAX_FILE_SIZE_MB = 10;
export const ATTACHMENT_MAX_FILE_COUNT = 1;

export const ATTACHMENT_PENDING = 'ATTACHMENT_PENDING';
export const ATTACHMENT_LOADING = 'ATTACHMENT_LOADING';
export const ATTACHMENT_ACCEPTED = 'ATTACHMENT_ACCEPTED';
export const ATTACHMENT_ATTACHED = 'ATTACHMENT_ATTACHED';
export const ATTACHMENT_REJECTED = 'ATTACHMENT_REJECTED';

export const ATTACHMENT_STATUSES = {
  [ATTACHMENT_PENDING]: ATTACHMENT_PENDING,
  [ATTACHMENT_LOADING]: ATTACHMENT_LOADING,
  [ATTACHMENT_ACCEPTED]: ATTACHMENT_ACCEPTED,
  [ATTACHMENT_REJECTED]: ATTACHMENT_REJECTED,
};

export const FILE_INVALID_TYPE = 'file-invalid-type';
export const FILE_TOO_LARGE = 'file-too-large';
export const FILE_TOO_SMALL = 'file-too-small';
export const TOO_MANY_FILES = 'too-many-files';
export const FILE_NAME_INVALID = 'file-name-invalid';

// Post sign-up animated dashboard
export const twoHoursInMilliseconds = 1000 * 60 * 60 * 2;

// address and previous addresses for personal details

export const CURRENT_ADDRESS = 'current-address';
export const PREVIOUS_ADDRESSES = 'previous-addresses';

export const transferOptions = {
  TRANSFER_BASIC: 'transfer-basic',
  TRANSFER_WITH_CHECK: 'transfer-with-check',
};

export const transferOptionMapper = {
  'transfer-basic': 'Transfer only',
  'transfer-with-check': 'Find, Check and Transfer',
};

export const sourceOfMoneyOptions = [
  { value: 'property-sales', label: 'Sale of property' },
  { value: 'inheritance', label: 'Inheritance' },
  { value: 'divorce-settlement', label: 'Divorce settlement' },
  { value: 'investment', label: 'Proceeds from investment' },
  { value: 'pension-fund', label: 'Pension fund' },
  { value: 'gift', label: 'Gift' },
  { value: 'winnings', label: 'Lottery & other winnings' },
  { value: 'other', label: 'Other (please state)' },
];

export const lumpSumSourceOfMoneyOptions = [
  { value: 'savings', label: 'Savings from income' },
  ...sourceOfMoneyOptions,
];

export const regContributionsSourceOfMoneyOptions = [
  { value: 'regular-income', label: 'Regular income' },
  ...sourceOfMoneyOptions,
];

/**
 * PEN-83 - disabling mention-me feature until it has been redesigned for future cross-sell use.
 * SHOW_MENTION_ME here is to toggle this feature in case it needs to be enabled later
 */

export const SHOW_MENTION_ME = false;