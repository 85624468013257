import React from 'react';
import styles from './FooterSmallprint.css';

const FooterSmallprint = () => (
  <div className={styles.wrapper}>
    <p className={styles.smallprint}>
      {'Profile Pensions is a trading name of Profile Financial Solutions Ltd which is Authorised and Regulated by the Financial Conduct Authority. FCA Number 596398. Registered in England & Wales, Company Number 07731925. Registered Office address: Norwest Court, Guildhall Street, Preston, PR1 3NU. Trading address: Norwest Court, Guildhall Street, Preston, PR1 3NU. © 2017 Profile Financial Solutions Ltd.'}
    </p>
    <br />
  </div>
);

export default FooterSmallprint;
