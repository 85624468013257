exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);

// module
exports.push([module.id, ".-DiscoverEstimatedIncome-estimatedIncomeImageContainer-IetWO {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n.-DiscoverEstimatedIncome-estimatedIncomeImage-12mlr {\n  width: 232px;\n}\n.-DiscoverEstimatedIncome-estimatedIncomeHeading-1At3W {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + ";\n}\n.-DiscoverEstimatedIncome-contentContainer-3QDFO {\n  margin-top: 15px;\n}\n.-DiscoverEstimatedIncome-estimatedIncomeText-2VOOy {\n  line-height: 22px;\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["typographyDesktop"] + ") {\n  .-DiscoverEstimatedIncome-estimatedIncomeText-2VOOy {\n    line-height: 24px;\n  }\n}\n.-DiscoverEstimatedIncome-estimatedIncomeHeading-1At3W {\n  margin: 20px 0 0 0;\n}\n.-DiscoverEstimatedIncome-estimatedIncomeText-2VOOy {\n  margin: 0;\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n  .-DiscoverEstimatedIncome-estimatedIncomeImageContainer-IetWO {\n    -webkit-box-pack: left;\n        -ms-flex-pack: left;\n            justify-content: left;\n    margin-left: 60px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"colors": "\"../../theme/colors.css\"",
	"textTitleRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + "",
	"typography": "\"../../theme/typography.css\"",
	"bodyOne": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"dimensions": "\"../../theme/dimensions.css\"",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"typographyDesktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["typographyDesktop"] + "",
	"estimatedIncomeImageContainer": "-DiscoverEstimatedIncome-estimatedIncomeImageContainer-IetWO",
	"estimatedIncomeImage": "-DiscoverEstimatedIncome-estimatedIncomeImage-12mlr",
	"estimatedIncomeHeading": "-DiscoverEstimatedIncome-estimatedIncomeHeading-1At3W",
	"contentContainer": "-DiscoverEstimatedIncome-contentContainer-3QDFO",
	"estimatedIncomeText": "-DiscoverEstimatedIncome-estimatedIncomeText-2VOOy " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + ""
};