import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './NavigationMenuIcon.css';

const NavigationMenuIcon = ({
  onClick,
  open,
  themeDark,
  inboxTheme,
}) => (
  <button className={styles.container} onClick={onClick} type="button" aria-label="open menu">
    {!open && (
      <span
        className={
          classnames(
            styles.myAccount,
            { [styles.myAccountForest]: themeDark },
            { [styles.inboxTheme]: inboxTheme },
          )
        }
      >
        {'My account'}
      </span>
    )}
    <div
      className={
        classnames(
          styles.wrapper,
          {
            [styles.themeDark]: themeDark,
            [styles.inboxTheme]: inboxTheme,
            [styles.open]: open,
          },
        )
      }
    >
      <span />
      <span />
      <span />
      <span />
    </div>
  </button>
);

NavigationMenuIcon.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  themeDark: PropTypes.bool,
};

NavigationMenuIcon.defaultProps = {
  open: false,
  onClick: null,
  themeDark: false,
};

export default NavigationMenuIcon;
