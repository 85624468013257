import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import Button from '../Button/Button';
import AddMoney from '../../assets/images/rebranding/add-money.svg';
import { getPensions } from '../../redux/selectors/pensions';
import { getInProgressContributionInstructions } from '../../redux/selectors/investmentAdvice';
import { getIsAddMoneyAllowed } from '../../redux/selectors/journeys';
import styles from './AddMoneyCard.css';

const AddMoneyCard = ({
  title,
  prefillAmount,
  pensions,
  inProgressInstructions,
  history,
  isAddMoneyAllowed,
  onClick,
  retirementIncomeCalculator,
  fullWidth,
  fullHeight,
  existingContributionContent,
  imageStyles,
}) => {
  const hasPensions = Boolean(pensions && pensions.length);
  const hasInProgressInstructions = Boolean(inProgressInstructions.length);
  const hasPensionOrInstruction = hasPensions || hasInProgressInstructions;

  const _onClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }

    let setupContributionUrl = '/add-money-option';
    if (prefillAmount) {
      setupContributionUrl += `?amount=${prefillAmount}`;
    }

    history.push(setupContributionUrl);
  };

  return (
    <div
      className={
        classNames(
          styles.paymentsCard,
          {
            [styles.singleColumn]: hasPensionOrInstruction,
            [styles.pointer]: isAddMoneyAllowed,
            [styles.retirementIncomeCalculator]: retirementIncomeCalculator,
            [styles.marginLeft]: !fullWidth,
            [styles.fullHeight]: fullHeight,
          },
        )
      }
    >
      <div className={styles.actionContainer}>
        <div
          className={
            classNames(styles.actionIconContainer,
              {
                [styles.alignUnder]: retirementIncomeCalculator && !fullWidth,
                [styles.alignLeft]: fullWidth,
              })
          }
          onClick={_onClick}
          onKeyUp={() => {}}
          role="button"
          tabIndex="0"
        >
          <img alt="Cycle of arrows" src={AddMoney} className={styles.cycle} style={imageStyles} />
          <h4 className={styles.paymentsHeading}>{title}</h4>
        </div>
        <Button
          label="+ Money"
          size="large"
          onClick={_onClick}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pensions: getPensions(state),
  inProgressInstructions: getInProgressContributionInstructions(state),
  isAddMoneyAllowed: getIsAddMoneyAllowed(state),
});

AddMoneyCard.propTypes = {
  title: PropTypes.string,
  prefillAmount: PropTypes.number,
  fullWidth: PropTypes.bool,
  existingContributionContent: PropTypes.element,
};

AddMoneyCard.defaultProps = {
  title: 'Make a contribution',
  fullWidth: false,
  prefillAmount: null,
  existingContributionContent: (
    <React.Fragment>
      <h3 className={styles.body}>
        {'You have already requested a contribution, if you wish to make further requests, '}
      </h3>
      <Link
        to="/inbox/new-message"
      >
        {'please contact your Pension Adviser.'}
      </Link>
    </React.Fragment>
  ),
};

export default connect(mapStateToProps)(withRouter(AddMoneyCard));
