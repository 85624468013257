import React from 'react';

import styles from './PageGrid.css';

const PageGrid = ({ children }) => (
  <div className={styles.pageContainer}>
    <div className={styles.pageGrid}>
      {children}
    </div>
  </div>
);

export default PageGrid;
