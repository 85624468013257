import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import WhiteEmail from '../../assets/images/icons/white_email.svg';
import styles from './MessageWidget.css';
import { messageWidgetClickedAction } from '../../redux/modules/conversations';

const MessageWidget = ({
  history,
  dispatchMessageWidgetClicked,
}) => {
  return (
    <button
      className={styles.button}
      type="button"
      onClick={() => {
        dispatchMessageWidgetClicked();
        history.push('/inbox/new-message');
      }}
    >
      <img
        className={styles.emailIcon}
        src={WhiteEmail}
        alt="Message us"
      />
      <div className={styles.message}>{'Message us'}</div>
    </button>
  );
};

const mapDispatchToProps = {
  dispatchMessageWidgetClicked: messageWidgetClickedAction,
};

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
)(MessageWidget);
