/* eslint-disable import/no-cycle */

import get from 'lodash/get';
import {
  getIsCustomerOnboarding,
  getIsCustomerOngoing,
} from './experience';
import {
  getInvestmentAdviceLetter,
  getInvestmentAdviceOnboarding,
} from './investmentAdvice';

export const getPensions = (state) => {
  return get(getInvestmentAdviceOnboarding(state), 'pensions') || [];
};

/**
 * Returns all pensions ready to authorise.
 * @param {*} state
 * @returns {[]} Returns pensions array, else an empty array.
 */
export const getPensionsReadyToAuthorise = (state) => {
  const onboardingPensions = getPensions(state);
  const investmentAdviceReport = getInvestmentAdviceLetter(state);
  const customerIsOnboarding = getIsCustomerOnboarding(state);
  const customerIsOngoing = getIsCustomerOngoing(state);
  const pensionsReadyToAuthorise = onboardingPensions.filter((pensions) => {
    return pensions.canGiveTransferAuthority
    && ((investmentAdviceReport && customerIsOnboarding) || customerIsOngoing);
  });
  return pensionsReadyToAuthorise;
};

export const getSerpsPensions = (state) => {
  const serpPensions = getPensions(state)
    .filter((pension) => pension.hmrcResponseEligible === 'yes') || [];
  return serpPensions;
};

export const getAuthorisedPensions = (state) => {
  const onboardingPensions = getPensions(state);
  const authorisedPensions = onboardingPensions.filter((pensions) => {
    return pensions.authorityToTransfer;
  });
  return authorisedPensions;
};

export const getIsThereNonAuthorisedSerpsPensions = (state) => {
  const onboardingPensions = getPensions(state);
  const nonAuthorisedPensions = onboardingPensions.filter((pension) => {
    return !pension.authorityToTransfer && pension.hmrcResponseEligible === 'yes';
  });
  return nonAuthorisedPensions;
};

export const getAuthorisedSerpsPensions = (state) => {
  const onboardingPensions = getPensions(state);
  const authorisedSerpsPensions = onboardingPensions.filter((pension) => {
    return pension.authorityToTransfer && pension.hmrcResponseEligible === 'yes';
  });
  return authorisedSerpsPensions;
};

export const getAllEligibleSerpsPensions = (state) => {
  const onboardingPensions = getPensions(state);
  const allEligibleSerpsPensions = onboardingPensions.filter((pension) => {
    return pension.source === 'hmrc' && pension.hmrcResponseEligible === 'yes';
  });
  return allEligibleSerpsPensions;
};

export const getAllNonSerpsPensionsAndAuthorisedSerpsPensions = (state) => {
  const findAllNonSerpsPensionsAndAuthorisedSerpsPensions = [];
  const onboardingPensions = getPensions(state);

  const authorisedSerpsPensions = onboardingPensions.filter((pension) => {
    return pension.authorityToTransfer && pension.source === 'hmrc';
  });
  const nonSerpsPensions = onboardingPensions.filter((pension) => {
    return pension.hmrcResponseEligible !== 'yes' && pension.source !== 'hmrc';
  });

  findAllNonSerpsPensionsAndAuthorisedSerpsPensions.push(
    ...authorisedSerpsPensions, ...nonSerpsPensions,
  );
  return findAllNonSerpsPensionsAndAuthorisedSerpsPensions;
};
