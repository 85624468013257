import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import sodium from 'libsodium-wrappers';
import { MENTIONME_CONFIG } from '../../config';
import {
  getLatestCompleteCds,
  getFirstName,
  getLastName,
  getEmail,
  getPippaId,
  getShouldShowMentionMe,
} from '../../redux/selectors';
import {
  getIsCustomerOngoing,
} from '../../redux/selectors/experience';

const WithMentionMe = ({
  children,
  firstName,
  lastName,
  email,
  pippaId,
  shouldShowMentionMe,
}) => {
  useEffect(() => {
    if (!document || !MENTIONME_CONFIG.key) return;
    const situation = 'dashboardpostcds';
    const implementation = 'link';

    const mentionMeScript = document.getElementById('mentionme-script');
    if (mentionMeScript) {
      mentionMeScript.remove();
    }

    /**
     * Workaround for PRO-593
     * Without this deletion of the campaign key in the global mentionme object mentionme
     * will stop the tag from firing and print a log message
     * "A tag has already fired with identifier [linkdashboardpostcds] on this page.
     * Suppressing tag"
     * They've confirmed it is safe to remove this guard for our usecase where a customer
     * should always see the link.
     */
    if (typeof window.MentionMeFiredTags !== 'undefined' && window.MentionMeFiredTags !== '') {
      if (window.MentionMeFiredTags[implementation + situation] === true) {
        delete window.MentionMeFiredTags[implementation + situation];
      }
    }

    sodium.ready.then(() => {
      const nonce = sodium.randombytes_buf(24);
      const nonceHex = sodium.to_hex(nonce);
      const fieldsToEncrypt = `firstname=${encodeURIComponent(firstName)}&surname=${encodeURIComponent(lastName)}&email=${encodeURIComponent(email)}`;
      const key = sodium.from_hex(MENTIONME_CONFIG.key);
      const encryptedFields = sodium.to_hex(
        sodium.crypto_secretbox_easy(fieldsToEncrypt, nonce, key),
      );

      if (shouldShowMentionMe) {
        const mentionMeReferScript = document.createElement('script');
        mentionMeReferScript.type = 'text/javascript';
        mentionMeReferScript.setAttribute('id', 'mentionme-script');
        mentionMeReferScript.src = `https://${MENTIONME_CONFIG.referrerTagUrl}/api/v2/referreroffer/mmeed6bbf1?mm_e=${encryptedFields}&nonce=${nonceHex}&signup_id=${encodeURIComponent(pippaId)}&situation=${situation}&order_total=1&order_number=${encodeURIComponent(pippaId)}&order_currency=GBP&locale=en_GB&implementation=${implementation}`;
        document.getElementsByTagName('body')[0].append(mentionMeReferScript);
      }
    });
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};

const mapStateToProps = (state) => ({
  firstName: getFirstName(state),
  lastName: getLastName(state),
  email: getEmail(state),
  pippaId: getPippaId(state),
  latestCompleteCds: getLatestCompleteCds(state),
  isCustomerOngoing: getIsCustomerOngoing(state),
  shouldShowMentionMe: getShouldShowMentionMe(state),
});

export default compose(
  connect(mapStateToProps),
)(WithMentionMe);
