import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import styles from './PensionTransferOptionStep.css';
import { getProviderNames, getProviderStatuses } from '../../redux/selectors';
import { setImportantConsiderationsRoutingAction } from '../../redux/modules/withdrawal';

import ButtonLink from '../../components/ButtonLink/ButtonLink';
import { getPensionExpert } from '../../redux/selectors/investmentAdvice';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import { transferOptions } from '../../util/constants';
import {
  returnLinkClickedAction,
  messageMeClickedAction,
  pensionTransferOptionStepLandedAction,
  pensionProviderSubmitClickedAction,
  unknownProviderLinkClickedAction,
  inScopeProviderSelectedAction,
  outOfScopeProviderSelectedAction,
  otherProviderSelectedAction,
  pensionTransferOptionSelectedAction,
  pensionTransferOptionTabSelectedAction,
} from '../../redux/modules/pensions';

import TabCard from '../../components/TabCard/TabCard';
import PensionTransferOption from './PensionTransferOptions/PensionTransferOption';
import pensionTransferOptionsContent from './PensionTransferOptions/pensionTransferOptionsContent';

const PensionTransferOptionStep = ({
  setFormData,
  setStep,
  amendMode,
  dispatchPensionTransferOptionStepLanded,
  isCustomerOngoing,
  dispatchReturnLinkClicked,
  dispatchPensionTransferOptionSelectedAction,
  dispatchPensionTransferOptionTabSelectedAction,
  hideBackNavigation,
  resetFormData,
  location,
  dispatchSetImportantConsiderationsRouting,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchPensionTransferOptionStepLanded(isCustomerOngoing);
  }, []);

  const MainHeading = () => {
    return (
      <div className={styles.headingContainer}>
        <h2 className={styles.heading}>{'Select an option'}</h2>
        <div className={styles.providerStepWrapper}>
          {(!amendMode && !hideBackNavigation) && (
          <ButtonLink
            onClick={(event) => {
              dispatchReturnLinkClicked(isCustomerOngoing);
              dispatchSetImportantConsiderationsRouting({ from: 'pension-options' });
              setStep((_step) => _step - 1);
              event.stopPropagation();
            }}
            label="← Back"
          />
          )}
        </div>
      </div>
    );
  };

  const transferOptionData = [
    {
      tabHeading: <span>{'Find, Check & Transfer'}</span>,
      key: 'find-check-and-transfer',
      content: <PensionTransferOption
        pensionTransferOptionContent={pensionTransferOptionsContent[0]}
        ctaButtonOptions={{
          handleClick: () => {
            dispatchPensionTransferOptionSelectedAction(isCustomerOngoing, transferOptions.TRANSFER_WITH_CHECK);
            resetFormData();
            setFormData((_formData) => ({
              ..._formData,
              transferOption: transferOptions.TRANSFER_WITH_CHECK,
            }));
            setStep((_step) => _step + 1);
          },
        }}
      />,
      tabHeadingOnClickEvent: () => dispatchPensionTransferOptionTabSelectedAction(
        isCustomerOngoing,
        transferOptions.TRANSFER_WITH_CHECK,
      ),
    },
    {
      tabHeading: (
        <span>
          {'Transfer'}
          {' '}
          <br className={styles.mobileBreak} />
          {'only'}
        </span>
      ),
      key: 'transfer-only',
      content: <PensionTransferOption
        pensionTransferOptionContent={pensionTransferOptionsContent[1]}
        ctaButtonOptions={{
          handleClick: () => {
            dispatchPensionTransferOptionSelectedAction(isCustomerOngoing, transferOptions.TRANSFER_BASIC);
            resetFormData();
            setFormData((_formData) => ({
              ..._formData,
              transferOption: transferOptions.TRANSFER_BASIC,
            }));
            setStep((_step) => _step + 1);
          },
        }}
      />,
      tabHeadingOnClickEvent: () => dispatchPensionTransferOptionTabSelectedAction(
        isCustomerOngoing,
        transferOptions.TRANSFER_BASIC,
      ),
    },
  ];

  const urlParams = new URLSearchParams(location.search);
  const pensionTransferOptionType = urlParams.get('type');

  return (
    <BaseGrid greyTheme tabletTwelveColumn>
      <div className={styles.container}>
        <MainHeading />
        <div className={styles.tabCardContainer}>
          <TabCard
            tabs={transferOptionData}
            transferOption
            defaultTab={pensionTransferOptionType === 'transfer-only' ? 1 : 0}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  providers: getProviderNames(state),
  providerStatuses: getProviderStatuses(state),
  pensionAdviser: getPensionExpert(state),
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  dispatchReturnLinkClicked: returnLinkClickedAction,
  dispatchMessageMeClicked: messageMeClickedAction,
  dispatchPensionTransferOptionStepLanded: pensionTransferOptionStepLandedAction,
  dispatchPensionProviderSubmitClicked: pensionProviderSubmitClickedAction,
  dispatchUnknownProviderLinkClicked: unknownProviderLinkClickedAction,
  dispatchInScopeProviderSelected: inScopeProviderSelectedAction,
  dispatchOutOfScopeProviderSelected: outOfScopeProviderSelectedAction,
  dispatchOtherProviderSelected: otherProviderSelectedAction,
  dispatchPensionTransferOptionSelectedAction: pensionTransferOptionSelectedAction,
  dispatchPensionTransferOptionTabSelectedAction: pensionTransferOptionTabSelectedAction,
  dispatchSetImportantConsiderationsRouting: setImportantConsiderationsRoutingAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PensionTransferOptionStep));
