exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);

// module
exports.push([module.id, ".-WithdrawalAdviserPage-body-If9um {\n}\n.-WithdrawalAdviserPage-container-3T664 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  text-align: center;\n}\n.-WithdrawalAdviserPage-heading-28A2w {\n}\n.-WithdrawalAdviserPage-expertImage-2RSmw {\n  height: 60px;\n  width: 60px;\n  margin-right: 15px;\n  margin: auto;\n}\n.-WithdrawalAdviserPage-contactRow-3hq1u {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin: 0 auto 20px;\n}", ""]);

// exports
exports.locals = {
	"typography": "\"../../theme/typography.css\"",
	"h2": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h2"] + "",
	"bodyOne": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"body": "-WithdrawalAdviserPage-body-If9um " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"container": "-WithdrawalAdviserPage-container-3T664",
	"heading": "-WithdrawalAdviserPage-heading-28A2w " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h2"] + "",
	"expertImage": "-WithdrawalAdviserPage-expertImage-2RSmw",
	"contactRow": "-WithdrawalAdviserPage-contactRow-3hq1u"
};