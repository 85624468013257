import { TRUSTPILOT_URL } from '../../config';
import demoTrustpilotData from '../../util/trustpilotDemoResponse';

const FETCH_TRUSTPILOT_DATA_DISPATCHED = 'FETCH_TRUSTPILOT_DATA_DISPATCHED';
const FETCH_TRUSTPILOT_DATA_FULFILLED = 'FETCH_TRUSTPILOT_DATA_FULFILLED';
const FETCH_TRUSTPILOT_DATA_REJECTED = 'FETCH_TRUSTPILOT_DATA_REJECTED';

export const fetchTrustpilotDataDispatchedAction = () => ({
  type: FETCH_TRUSTPILOT_DATA_DISPATCHED,
});

export const fetchTrustpilotDataFulfilledAction = (data) => ({
  type: FETCH_TRUSTPILOT_DATA_FULFILLED,
  ...data,
});

export const fetchTrustpilotDataRejectedAction = () => ({
  type: FETCH_TRUSTPILOT_DATA_REJECTED,
});

export const fetchTrustpilotDataAction = () => async (dispatch) => {
  dispatch(fetchTrustpilotDataDispatchedAction());

  if (process.env.DEPLOYED_ENVIRONMENT === 'local') {
    return dispatch(fetchTrustpilotDataFulfilledAction({
      tpReviews: demoTrustpilotData.tpReviews,
      tpBusinessUnits: demoTrustpilotData.tpBusinessUnits,
    }));
  }

  try {
    const {
      tpReviews,
      tpBusinessUnits,
    } = await fetch(
      TRUSTPILOT_URL,
      { timeout: 1000 },
    ).then((res) => res.json()).then((json) => json);

    dispatch(fetchTrustpilotDataFulfilledAction({ tpReviews, tpBusinessUnits }));
  } catch (e) {
    dispatch(fetchTrustpilotDataRejectedAction());
    dispatch(fetchTrustpilotDataFulfilledAction({
      tpReviews: demoTrustpilotData.tpReviews,
      tpBusinessUnits: demoTrustpilotData.tpBusinessUnits,
    }));
  }
};

const initialState = {
  tpReviews: [],
  tpBusinessUnits: [],
  tpFetchError: null,
};

const trustpilotReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRUSTPILOT_DATA_FULFILLED:
      return {
        ...state,
        tpReviews: action.tpReviews,
        tpBusinessUnits: action.tpBusinessUnits,
        tpFetchError: null,
      };
    case FETCH_TRUSTPILOT_DATA_REJECTED:
      return {
        ...state,
        tpFetchError: true,
      };
    case FETCH_TRUSTPILOT_DATA_DISPATCHED:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default trustpilotReducer;
