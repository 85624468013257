import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import logoUrlWithMfGroup from '../../assets/images/rebranding/pp-logo-mf-group.svg';
import ProgressStepper from '../ProgressStepper/ProgressStepper';
import styles from './JourneyLayout.css';

const JourneyLayout = (props) => {
  const {
    progress,
    sidebar,
    children,
    reverse,
    jumpingLogo,
  } = props;

  return (
    <div className={
      classnames(
        styles.layout,
        {
          [styles.layoutReverse]: reverse,
        },
      )
    }
    >
      {sidebar}
      <div className={styles.layoutMain}>
        {progress && <ProgressStepper padding step={progress[0]} maxStep={progress[1]} />}
        <div className={styles.layoutContent}>
          <div className={classnames(styles.logo, {
            [styles.jumpingLogo]: jumpingLogo,
          })}
          >
            <img src={logoUrlWithMfGroup} alt="Profile Pensions Logo" />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

JourneyLayout.propTypes = {
  sidebar: PropTypes.element,
  progress: PropTypes.arrayOf(PropTypes.number),
  reverse: PropTypes.bool,
  jumpingLogo: PropTypes.bool,
};

JourneyLayout.defaultProps = {
  sidebar: null,
  progress: null,
  reverse: false,
  jumpingLogo: true,
};

export default JourneyLayout;
