/* eslint-disable max-len */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import pickBy from 'lodash/pickBy';
import get from 'lodash/get';
import * as Sentry from '@sentry/react';
import sentryException from '../../util/sentryException';
import PreAuthRoute from '../PreAuthRoute/PreAuthRoute';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import { appInit } from '../../redux/modules/app';
import styles from './App.css';
import AppErrorBoundary from '../AppErrorBoundary/AppErrorBoundary';
import LoginControllerContainer from '../../pages/LoginController/LoginController';
import VerificationPage from '../../pages/VerificationPage/VerificationPage';
import VerifyPage from '../../pages/VerifyPage/VerifyPage';
import LogoutPage from '../LogoutPage/LogoutPage';
import LoaderPage from '../../pages/LoaderPage/LoaderPage';
import DocumentsPage from '../../pages/DocumentsPage/DocumentsPage';
import InboxPage from '../../pages/InboxPage/InboxPage';
import SettingsPage from '../../pages/SettingsPage/SettingsPage';
import ForgotPasswordController from '../../pages/ForgotPasswordController/ForgotPasswordController';
import WithdrawalsAppointmentPage from '../../pages/WithdrawalsAppoinmentPage/WithdrawalsAppointmentPage';
import ExternalLoginPage from '../../pages/ExternalLoginPage/ExternalLoginPage';
import JourneyRouter from '../../pages/JourneyRouter/JourneyRouter';
import SignupPage from '../../pages/SignupPage/SignupPage';
import RegisterPage from '../../pages/RegisterPage/RegisterPage';
import {
  IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY_PATH,
  IMPORTANT_CONSIDERATIONS_INFO_JOURNEY_PATH,
} from '../../pages/WithdrawalMiniJourney/flows';
import SerpsAuthorisePensionsSteps from '../../pages/SerpsAuthorisePensions/SerpsAuthorisePensionsSteps';

import {
  getEmail,
  getPreAuthEmail,
  getUid,
  getIsAuthenticated,
} from '../../redux/selectors';
import { getIsAuthLoaded } from '../../redux/selectors/loaded';
import { fetchTrustpilotDataAction } from '../../redux/modules/trustpilot';
import { JOURNEYS } from '../../util/constants';
import AegonWithdrawals from '../../pages/AegonWithdrawals/AegonWitdrawals';
import redirects from './redirects';

class App extends Component {
  constructor(props) {
    super(props);

    props.appInit();
  }

  componentDidMount() {
    const { dispatchFetchTrustpilotData } = this.props;
    dispatchFetchTrustpilotData();
    if (window) {
      // eslint-disable-next-line no-underscore-dangle
      window._version = {
        commit: __COMMIT_HASH__,
        tags: __TAGS__,
      };

      window.onunhandledrejection = (e) => {
        if (e.name === 'NetworkError') {
          e.stopPropagation();
        }
        if (!(e instanceof Error) && e.reason && e.reason.name === 'FirebaseError') {
          console.log(e);
          sentryException(new Error(e.reason), {
            section: 'firebase-error',
          });
          e.stopPropagation();
          return;
        }
        return e;
      };
    }
  }

  componentDidUpdate(prevProps) {
    const sentryUserProps = ['email', 'preAuthEmail', 'uid'];
    const shouldResetSentryUser = sentryUserProps.some((propName) => get(this, `props.${propName}`) !== get(prevProps, propName));
    if (shouldResetSentryUser) {
      const { email, preAuthEmail, uid } = this.props;
      Sentry.configureScope((scope) => scope.setUser(null));
      const userTags = pickBy({
        email: email || preAuthEmail,
        id: uid,
      }, Boolean);
      Sentry.setUser(userTags);
    }
  }

  render() {
    const { isLoaded, preAuthEmail } = this.props;

    const routes = (
      <Switch>
        <PrivateRoute exact path="/" component={JourneyRouter(JOURNEYS.DASHBOARD)} />
        <PrivateRoute exact path="/getting-to-know-you" component={JourneyRouter(JOURNEYS.CDS)} />
        <PrivateRoute exact path="/your-latest-information" component={JourneyRouter(JOURNEYS.ONGOING_CDS)} />
        <PrivateRoute exact path="/documents" component={DocumentsPage} />
        <PrivateRoute exact path="/account-settings" component={SettingsPage} />
        <PrivateRoute exact path="/logout" component={LogoutPage} />
        <PrivateRoute exact path="/withdrawals-appointment" component={WithdrawalsAppointmentPage} />
        <PrivateRoute exact path="/verification" component={VerifyPage} />
        <PrivateRoute exact path="/add-pensions" component={JourneyRouter(JOURNEYS.ADD_PENSIONS)} />
        <PrivateRoute exact path="/missing-details" component={JourneyRouter(JOURNEYS.MISSING_DETAILS)} />
        <PrivateRoute exact path="/serps-request" component={JourneyRouter(JOURNEYS.SERPS_MISSING_DETAILS)} />
        <PrivateRoute exact path="/serps-auth" component={SerpsAuthorisePensionsSteps} />
        <PrivateRoute exact path="/plan-summary" component={JourneyRouter(JOURNEYS.PLAN_SUMMARY)} />
        <PrivateRoute exact path="/plan-activity" component={JourneyRouter(JOURNEYS.PLAN_ACTIVITY)} />
        <PrivateRoute exact path="/find-pensions" component={JourneyRouter(JOURNEYS.FIND_PENSIONS)} />
        <PrivateRoute exact path="/personal-details" component={JourneyRouter(JOURNEYS.PERSONAL_DETAILS)} />
        <PrivateRoute exact path="/we-need-to-speak-to-you" component={JourneyRouter(JOURNEYS.VULNERABLE_CUSTOMER)} />
        <PrivateRoute exact path="/authorise-change" component={JourneyRouter(JOURNEYS.AUTHORISE_CHANGE)} />
        <PrivateRoute exact path="/add-money-option" component={JourneyRouter(JOURNEYS.ADD_MONEY_OPTION)} />
        <PrivateRoute exact path="/setup-contribution" component={JourneyRouter(JOURNEYS.SETUP_CONTRIBUTION)} />
        <PrivateRoute exact path="/setup-lump-sum" component={JourneyRouter(JOURNEYS.SETUP_LUMP_SUM)} />
        <PrivateRoute exact path="/withdrawals" component={JourneyRouter(JOURNEYS.WITHDRAWALS)} />
        <PrivateRoute exact path="/estimated-income-calculator" component={JourneyRouter(JOURNEYS.RETIREMENT_INCOME_PROJECTION)} />
        <PrivateRoute exact path="/inbox" component={InboxPage} />
        <PrivateRoute path="/inbox/:activePanel" component={InboxPage} />
        <PrivateRoute path="/aegon-withdrawals" component={AegonWithdrawals} />
        <PrivateRoute exact path="/withdrawals-we-need-to-speak-to-you" component={JourneyRouter(JOURNEYS.WITHDRAWAL_LUMP_SUM_OVER_LIMIT)} />
        <PrivateRoute exact path="/withdrawals-hub" component={JourneyRouter(JOURNEYS.WITHDRAWALS_HUB)} />
        <PrivateRoute exact path={IMPORTANT_CONSIDERATIONS_INFO_JOURNEY_PATH} component={JourneyRouter(JOURNEYS.WITHDRAWAL_MINI_JOURNEY)} />
        <PrivateRoute exact path={IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY_PATH} component={JourneyRouter(JOURNEYS.WITHDRAWAL_MINI_JOURNEY)} />
        <PrivateRoute path="/marketing-preferences" component={JourneyRouter(JOURNEYS.MARKETING_PREFERENCES)} />
        <PreAuthRoute exact path="/forgot-password" component={ForgotPasswordController} />
        <PreAuthRoute exact path="/login" component={LoginControllerContainer} />
        <Route exact path="/signup" component={SignupPage} />
        <Route exact path="/setup-account" component={RegisterPage} />
        <Route exact path="/email-verified" component={VerificationPage} />
        <Route exact path="/external-login" component={ExternalLoginPage} />
        {redirects.map(({ from, to }) => (
          <Route key={from} path={from} component={() => <Redirect to={to} />} />
        ))}
        <Route component={() => <Redirect to="/" />} />
      </Switch>
    );

    if (!isLoaded) {
      const metadata = {
        context: 'app',
        isLoaded,
      };
      return <LoaderPage metadata={metadata} />;
    }

    return (
      <Router baseline="/">
        <AppErrorBoundary preAuthEmail={preAuthEmail}>
          <div className={styles.appWrapper}>
            <main className={styles.main}>
              {routes}
            </main>
          </div>
        </AppErrorBoundary>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: getIsAuthenticated(state),
  preAuthEmail: getPreAuthEmail(state),
  isLoaded: getIsAuthLoaded(state),
  email: getEmail(state),
  uid: getUid(state),
  trustpilot: state.trustpilot,
});

const mapDispatchToProps = {
  appInit,
  dispatchFetchTrustpilotData: fetchTrustpilotDataAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
