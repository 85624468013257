import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import PensionPlanCard from './PensionPlanCard';
import getFundsWithRoundedSplits from '../../util/getFundsWithRoundedSplits';
import { getAuthoritativeRetirementPlan } from '../../redux/selectors';
import { getPensions } from '../../redux/selectors/pensions';

const CurrentPlanCard = ({
  retirementPlan,
  pensions,
}) => {
  const platformName = get(retirementPlan, 'provider');
  const portfolioReference = get(retirementPlan, 'investmentVehicleReference');
  const funds = get(retirementPlan, 'funds', []);
  const startOpen = pensions && pensions.length > 0;
  return (
    <PensionPlanCard
      platformName={platformName}
      portfolioReference={portfolioReference}
      funds={getFundsWithRoundedSplits(funds)}
      title="Your pension plan"
      startOpen={startOpen}
    />
  );
};

const mapStateToProps = (state) => ({
  retirementPlan: getAuthoritativeRetirementPlan(state),
  pensions: getPensions(state),
});

export default connect(mapStateToProps)(CurrentPlanCard);
