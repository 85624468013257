import React from 'react';
import get from 'lodash/get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

import OngoingBalance from '../OngoingBalance';
import AddAndListPensions from './AddAndListPensions';
import ContactUs from './ContactUs';
import ContributionsRow from '../../../components/DashboardRow/ContributionsRow';
import WithdrawalRow from '../../../components/DashboardRow/WithdrawalRow';

import {
  getUser,
  getFirstName,
  getEmail,
  getAuthoritativeRetirementPlan,
} from '../../../redux/selectors';

import {
  getIsCustomerOngoing,
  getIsCustomerOngoingMultipleRps,
  getIsCustomerOptoutFullEncashment,
} from '../../../redux/selectors/experience';

import { getPensions } from '../../../redux/selectors/pensions';
import { getIsAddPensionsAllowed, getIsWithdrawalAllowed } from '../../../redux/selectors/journeys';
import { getPensionExpert, getHasInProgressContributionInstruction } from '../../../redux/selectors/investmentAdvice';

import styles from '../DashboardPage.css';

const OngoingCustomerMultipleRP = (props) => {
  const {
    user,
    pensions = [],
    isAddPensionsAllowed,
    isCustomerOngoing,
    authoritativeRp,
    isCustomerOptoutFullEncashment,
    hasInProgressContributionInstruction,
    isCustomerOngoingMultipleRps,
    isWithdrawalAllowed,
    pensionExpert,
    firstName,
    email,
  } = props;

  const hasPensions = Boolean(pensions.length);
  const rpProvider = get(authoritativeRp, 'provider');

  const contributionsHeading = () => {
    if (hasPensions || hasInProgressContributionInstruction || isCustomerOngoing) {
      return 'Set up or amend regular payments';
    }
    return 'Start saving into a new pension';
  };

  return (
    <React.Fragment>
      <div className={styles.dashboardContentContainer}>
        <OngoingBalance user={user} rp={authoritativeRp} />
        <AddAndListPensions
          isCustomerOngoingMultipleRps={isCustomerOngoingMultipleRps}
          isAddPensionsAllowed={isAddPensionsAllowed}
          isCustomerOngoing={isCustomerOngoing}
          isCustomerOptoutFullEncashment={isCustomerOptoutFullEncashment}
          hasPensions={hasPensions}
        />
        <div className={styles.contributionsRowContainer}>
          <ContributionsRow
            heading={contributionsHeading()}
          />
        </div>
        {isWithdrawalAllowed && (
        <div className={styles.withdrawalsRowContainer}>
          <WithdrawalRow rpProvider={rpProvider} />
        </div>
        )}
      </div>
      <div className={styles.contactUsContainer}>
        <ContactUs
          pensionExpert={pensionExpert}
          firstName={firstName}
          email={email}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  pensionExpert: getPensionExpert(state),
  firstName: getFirstName(state),
  email: getEmail(state),
  authoritativeRp: getAuthoritativeRetirementPlan(state),
  pensions: getPensions(state),
  isAddPensionsAllowed: getIsAddPensionsAllowed(state),
  isCustomerOngoing: getIsCustomerOngoing(state),
  isCustomerOptoutFullEncashment: getIsCustomerOptoutFullEncashment(state),
  hasInProgressContributionInstruction: getHasInProgressContributionInstruction(state),
  isCustomerOngoingMultipleRps: getIsCustomerOngoingMultipleRps(state),
  isWithdrawalAllowed: getIsWithdrawalAllowed(state),
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect(),
)(withRouter(OngoingCustomerMultipleRP));
