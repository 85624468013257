import React, { useEffect, useState } from 'react';
import once from 'lodash/once';
import { connect } from 'react-redux';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import styles from './SerpMissingDetails.css';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Button from '../../components/Button/Button';
import {
  serpsMissingDetailsThankYouPageLoaded,
  serpsMissingDetailsThankYouPageViewDashboardClickedAction,
  serpsMissingDetailsSuccessFindPensionsClicked,
  serpsMissingDetailsSuccessAddContributionsClicked,
} from '../../redux/modules/serps';
import missingDetailsSubmitted from '../../assets/images/missing-details-submitted.svg';
import AddMoney from '../../assets/images/rebranding/add-money.svg';
import AddPensions from '../../assets/images/rebranding/tri-tone-overlapping-circles.svg';

const ThankYouStep = ({
  dispatchSerpsMissingDetailsThankYouPageLoaded: _dispatchMissingDetailsThankYouPageLanded,
  dispatchSerpsMissingDetailsThankYouPageViewDashboardClicked,
  dispatchSerpsMissingDetailsSuccessAddContributions,
  dispatchSerpsMissingDetailsSuccessFindPensionsClicked,
}) => {
  const [dispatchSerpsMissingDetailsThankYouPageLoaded] = useState(() => once(_dispatchMissingDetailsThankYouPageLanded || ''));

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchSerpsMissingDetailsThankYouPageLoaded();
  }, []);

  return (
    <BaseGrid>
      <div className={styles.missingDetailsWrapper}>
        <div className={styles.submitted}>
          <img src={missingDetailsSubmitted} alt="Missing Details Submitted" />
        </div>
        <div>
          <h1 className={styles.ninoHeading}>
            {'Thank you for providing these details'}
          </h1>
          <div className={styles.content}>
            {'We can now start your SERPS trace with HMRC. We usually expect to hear back from HMRC within 30 days, but this can take longer.'}
          </div>
          <br />
          <div className={styles.content}>
            {'Once we hear back from HMRC, we will give you your results in your secure online account. If a potential SERPS pension has been identified then you can use our Find, check & transfer service to locate these pensions, find their value and any guarantees.'}
          </div>
          <br />
          <div className={styles.content}>
            {'We’ll do all the hard work - we’ll check for any benefits or penalties, and then complete the transfer. (One-off 1% arrangement fee applies)'}
          </div>
        </div>
        <br />
        <div className={styles.thankYouNextBlock}>
          <div className={styles.thankYouNextRow}>
            <img className={styles.thankYouNextWidgetImg} alt="add pensions" src={AddPensions} />
            <p className={styles.sectionSummaryHeading}>{'Find & Combine a pension'}</p>
          </div>
          <p className={styles.content}>{'The average person has 11 workplace jobs in their lifetime. We can help you to find and combine any other old pensions and consolidate into your Profile Pension plan, making sure you benefit from ongoing investment advice, dedicated support and whole of market fund selection.'}</p>
          <Button
            size="large"
            label={'Find & Combine a pension'}
            onClick={() => {
              dispatchSerpsMissingDetailsSuccessFindPensionsClicked();
            }}
            to={'/add-pensions'}
          />
        </div>
        <br />
        <div className={styles.thankYouNextBlock}>
          <div className={styles.thankYouNextRow}>
            <img className={styles.thankYouNextWidgetImg} alt="set up contributions" src={AddMoney} />
            <p className={styles.sectionSummaryHeading}>{'Make contributions'}</p>
          </div>
          <p className={styles.content}>{'The more you add into your pension now, the more you’ll have for your retirement. If you can afford it, you may want to consider setting up regular contributions. You could also benefit from tax relief (25% top-up for non or basic rate taxpayers).'}</p>
          <Button
            size="large"
            label={'Make a contribution'}
            onClick={() => {
              dispatchSerpsMissingDetailsSuccessAddContributions();
            }}
            to={'/add-money-option'}
          />
        </div>
        <ButtonLink
          label={<span className={styles.employerLinkText}>{'View dashboard'}</span>}
          to="/"
          onClick={() => {
            dispatchSerpsMissingDetailsThankYouPageViewDashboardClicked();
          }}
        />
      </div>
    </BaseGrid>
  );
};

const mapDispatchToProps = {
  dispatchSerpsMissingDetailsThankYouPageLoaded:
    serpsMissingDetailsThankYouPageLoaded,
  dispatchSerpsMissingDetailsThankYouPageViewDashboardClicked:
    serpsMissingDetailsThankYouPageViewDashboardClickedAction,
  dispatchSerpsMissingDetailsSuccessAddContributions:
    serpsMissingDetailsSuccessAddContributionsClicked,
  dispatchSerpsMissingDetailsSuccessFindPensionsClicked:
    serpsMissingDetailsSuccessFindPensionsClicked,
};

export default connect(
  null,
  mapDispatchToProps,
)(ThankYouStep);
