import React from 'react';
import PropTypes from 'prop-types';
import styles from './GlanceCard.css';

const GlanceCardContent = ({ children, wrapperStyles }) => (
  <div className={styles.glanceCardContent} style={wrapperStyles} data-cy="glance-card-content">
    {children}
  </div>
);

GlanceCardContent.propTypes = {
  wrapperStyles: PropTypes.shape({}),
};

GlanceCardContent.defaultProps = {
  wrapperStyles: {},
};

export default GlanceCardContent;
