import React from 'react';
import PropTypes from 'prop-types';
import ArrowIcon from '../../assets/images/rebranding/right-arrow-inside-circle.svg';
import styles from './ListItem.css';

const ListItem = ({ title, subtitle, onClick }) => (
  <div className={styles.wrapper} onClick={onClick} role="button" tabIndex={0}>
    <div className={styles.titles}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
    <div className={styles.action}>
      <img src={ArrowIcon} alt="download icon" />
    </div>
  </div>
);

export default ListItem;

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
};

ListItem.defaultProps = {
  subtitle: null,
  onClick: null,
};
