import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import * as yup from 'yup';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirestore } from 'react-redux-firebase';
import firebase from 'firebase/compat/app';
import moment from 'moment';
import zxcvbn from 'zxcvbn';
import trim from 'lodash/trim';
import sentryException from '../../util/sentryException';

import getRecaptchaToken from '../../util/getRecaptchaToken';
import { getAuthError, getFirebaseUid } from '../../redux/selectors';
import { renderTextField, renderMultiDOBFields } from '../../util/formik-custom-fields';
import FormError from '../../components/FormError/FormError';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import PasswordStrength from '../../components/PasswordStrength/PasswordStrength';
import { dispatchValidatePostcodeDobViaEmail } from '../../redux/modules/investmentAdvice';
import { FBLogoutSoft, setNotificationMessage } from '../../redux/modules/auth';
import { CONSTANTS } from '../copyTexts';
import { loginUnverifiedUser } from '../../util/auth';
import { MINIMUM_PASSWORD_STRENGTH, DOES_ONLINE_ACOUNT_EXIST_RESPONSES } from '../../util/constants';

import styles from './Register.css';
import { newRegisterCompleteAction } from '../../redux/modules/signupFlow';
import ShowablePasswordField from '../../components/ShowablePasswordField/ShowablePasswordField';
import { emailRegex } from '../../util/validation';

const MESSAGES = {
  USER_EXISTS: 'already-exists',
  EMAIL_NOT_FOUND: 'email-not-found',
  ACCOUNT_NOT_FOUND: 'online-account-not-found',
  ACCOUNT_FOUND: 'online-account-found',
  INCORRECT_DPA: 'incorrect-dpa',
  NO_MATCHING_USER: 'no-matching-user-found',
  MAX_ATTEMPTS_REACHED: 'max-number-of-attempts-reached',
};

const ACCOUNT_EXISTS_MESSAGE = 'An account with this email address already exists. If you have forgotten your password, click the `Reset your password` link below';
const CURRENT_YEAR = moment().year();
const POSTCODE_REGEX = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})/;

const schema = yup.object().shape({
  email: yup.string().required('Required').matches(emailRegex, 'Please provide a valid email address'),
  dateOfBirth: yup.object({
    day: yup.string().required('Required'),
    month: yup.string().required('Required'),
    year: yup.string().required('Required')
      // eslint-disable-next-line prefer-arrow-callback
      .test('minMaxValue', 'Must be a valid date', function minMaxYear(value) {
        return value > 1910 && value <= CURRENT_YEAR;
      }),
  }),
  postcode: yup.string()
    .required('Required')
    .matches(POSTCODE_REGEX, { message: 'Please enter a valid Postcode' }),
});

const uppercase = (string) => {
  return string ? string.toUpperCase() : '';
};

const formatPostcode = (postcode) => {
  return postcode ? `${postcode.slice(0, -3)} ${postcode.slice(-3)}` : '';
};

const removeWhitespace = (string) => {
  return string ? string.replace(/\s/g, '') : '';
};

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      loading: false,
      submitError: false,
      registerError: '',
      redirectTo: null,
    };

    this.headingRef = React.createRef();
  }

  componentDidMount() {
    const { uid } = this.props;

    if (uid) {
      this.setState({ loggedIn: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { loggedIn } = this.state;
    const { history } = this.props;

    if (loggedIn && loggedIn !== prevState.loggedIn) {
      history.push({ pathname: '/' });
    }
  }

  doesOnlineAccountExist = async (email) => {
    const fn = firebase.app().functions('europe-west1').httpsCallable('doesOnlineAccountExist');
    const recaptchaToken = await getRecaptchaToken('accountExists');
    const res = await fn({ email, recaptchaToken });
    return res.data;
  }

  setUserPassword = async (password) => {
    const setPassword = firebase.app().functions('europe-west1').httpsCallable('setUserPassword');

    try {
      await setPassword({ password });
    } catch (error) {
      sentryException(error, {
        section: 'register-failed-to-set-user-password',
      });
      throw error;
    }
  }

  convertDateObjectToString = (fieldValue) => {
    const momentValues = {
      day: fieldValue.day,
      month: moment().month(fieldValue.month).format('M') - 1,
      year: fieldValue.year,
    };

    return moment.utc(momentValues).toISOString();
  };

  onSubmit = async (formSubmission, { setSubmitting, resetForm }) => {
    const {
      dispatchValidatePostcodeDobViaEmail: validatePostcodeDobViaEmail,
      dispatch,
      dispatchNewRegisterComplete,
    } = this.props;
    let userId;
    let firebaseAuthToken;

    const {
      email,
      dateOfBirth,
      dateOfBirthString,
      postcode,
      password,
    } = formSubmission;

    const reset = () => {
      resetForm({
        email,
        dateOfBirth,
        dateOfBirthString,
        postcode,
        password: '',
      });

      setSubmitting(false);
      this.headingRef.current.scrollIntoView();
    };

    try {
      const { message } = await this.doesOnlineAccountExist(trim(email));
      if ([
        DOES_ONLINE_ACOUNT_EXIST_RESPONSES.ONLINE_ACCOUNT_FOUND,
        DOES_ONLINE_ACOUNT_EXIST_RESPONSES.CUSTOMER_HAS_FIREBASEID,
      ].includes(message)) {
        this.setState({ redirectTo: '/login?account-exists=true', loading: false });
        dispatch(setNotificationMessage(ACCOUNT_EXISTS_MESSAGE));
        return;
      }
      if (message === DOES_ONLINE_ACOUNT_EXIST_RESPONSES.MULTIPLE_USERS_FOUND) {
        const errorMessage = (
          <React.Fragment>
            {'We are unable to set up your online account. Please contact us by emailing '}
            <a style={{ color: 'inherit' }} href="mailto: contact@profilepensions.co.uk">{'contact@profilepensions.co.uk'}</a>
            {'.'}
          </React.Fragment>
        );
        this.setState({ loading: false, submitError: true, registerError: errorMessage });
        reset();
        return;
      }
      if (message === DOES_ONLINE_ACOUNT_EXIST_RESPONSES.NO_USER_FOUND) {
        const noMatchMessage = 'The details you have provided did not match our records, please check them and try again';
        this.setState({ loading: false, submitError: true, registerError: noMatchMessage });
        reset();
        return;
      }
    } catch (error) {
      sentryException(error, {
        section: 'setup-does-online-account-exist-failed',
      });
    }

    try {
      const validatePostcodeDobRecaptcha = await getRecaptchaToken();
      const formattedPostcode = formatPostcode(uppercase(removeWhitespace(postcode)));
      const { data: { firebaseUserId, authToken } } = await validatePostcodeDobViaEmail(
        {
          email,
          dateOfBirth: dateOfBirthString,
          postcode: formattedPostcode,
        },
        validatePostcodeDobRecaptcha,
      );
      userId = firebaseUserId;
      firebaseAuthToken = authToken;
    } catch (error) {
      const noMatchMessage = 'The details you have provided did not match our records, please check them and try again';
      if (error && error.message === MESSAGES.INCORRECT_DPA) {
        this.setState({ loading: false, submitError: true, registerError: noMatchMessage });
      } else if (error && error.message === MESSAGES.MAX_ATTEMPTS_REACHED) {
        this.setState({ loading: false, submitError: true, registerError: 'max-attempts' });
      } else if (error && error.message === MESSAGES.NO_MATCHING_USER) {
        this.setState({ loading: false, submitError: true, registerError: noMatchMessage });
      } else {
        this.setState({ loading: false, submitError: true });
        sentryException(error, {
          section: 'register-failed-dpa',
        });
      }

      reset();
      return;
    }

    this.setState({ loading: true, submitError: false });

    try {
      await loginUnverifiedUser(firebaseAuthToken);
      await this.setUserPassword(password, userId);
      dispatchNewRegisterComplete();
      this.setState({ loggedIn: true, loading: false });
    } catch (error) {
      sentryException(error, {
        section: 'register-failed',
      });
      this.setState({ loading: false, submitError: true });
      dispatch(FBLogoutSoft());
      this.headingRef.current.scrollIntoView();
    }

    setSubmitting(false);
  };

  render() {
    const { loading, submitError, redirectTo } = this.state;

    if (loading) {
      return (
        <div className={styles.fixedLoading}>
          <Loader centred />
        </div>
      );
    }

    if (redirectTo) {
      return (<Redirect to={redirectTo} />);
    }

    return (
      <Formik
        onSubmit={this.onSubmit}
        validationSchema={schema}
        initialValues={{
          dateOfBirth: {
            day: '',
            month: '',
            year: '',
          },
          dateOfBirthString: '',
          email: '',
          postcode: '',
          password: '',
        }}
        validate={(values) => {
          const errors = {};

          const passwordSchema = yup.string().required('Required')
            .min(8, 'Minimum of ${min} characters required')
            .test('password-score', 'Please provide a stronger password', (value) => {
              if (!value) return false;
              return zxcvbn(value).score >= MINIMUM_PASSWORD_STRENGTH;
            });
          const dateOfBirthSchema = yup.string().test(
            // eslint-disable-next-line prefer-arrow-callback
            'Age', 'Unfortunately we are unable to help if you are under the age of 18', function checkAge(value) {
              return moment().diff(moment(value), 'years') >= 18;
            },
          );

          try {
            passwordSchema.validateSync(values.password);
          } catch (error) {
            errors.password = error.message;
          }

          try {
            dateOfBirthSchema.validateSync(values.dateOfBirthString);
          } catch (error) {
            errors.dateOfBirth = error.message;
          }

          return errors;
        }}
        render={({
          isSubmitting,
          status,
          errors,
          touched,
          values,
          setTouched,
          setFieldValue,
          setFieldTouched,
          handleChange,
        }) => {
          const { registerError } = this.state;
          return (
            <section>
              <div className={styles.signupContainer}>
                <h1 ref={this.headingRef} className={styles.heading}>{'Access your account'}</h1>
                <h2 ref={this.headingRef} className={styles.subHeading}>{'We already have your email on our records. Please enter your details below so we can verify you and give you access to your account.'}</h2>
                {submitError && registerError && registerError !== 'max-attempts' && (
                  <p className={styles.errorTextInBox}>{registerError}</p>
                )}
                {submitError && registerError === 'max-attempts' && (
                  <p className={styles.errorTextInBox}>
                    {'Unfortunately we have not been able to validate your details and your account has been locked. Please contact us at '}
                    <a style={{ color: 'white' }} href="mailto: contact@profilepensions.co.uk">{'contact@profilepensions.co.uk'}</a>
                  </p>
                )}
                <Form>
                  <Field
                    name="email"
                    type="email"
                    component={renderTextField}
                    label="Enter the email address you gave us"
                  />
                  <br />
                  <Field
                    type={null}
                    name="dateOfBirth"
                    label="Date of birth"
                    component={renderMultiDOBFields}
                    onChange={(fieldValue) => {
                      setFieldValue('dateOfBirth', fieldValue);

                      const momentDate = this.convertDateObjectToString(fieldValue);

                      if (fieldValue.day && fieldValue.month && fieldValue.year) {
                        setTouched({ ...touched, dateOfBirth: true });
                        setFieldValue('dateOfBirthString', momentDate);
                      } else {
                        setFieldValue('dateOfBirthString', '');
                      }
                    }}
                  />
                  <br />
                  <Field
                    name="postcode"
                    type="text"
                    component={renderTextField}
                    label="Postcode"
                  />
                  <br />
                  <ShowablePasswordField
                    name="password"
                    label="Create password"
                    onChange={(e) => {
                      setFieldTouched('password');
                      handleChange(e);
                    }}
                  />
                  {values && values.password && <PasswordStrength pwd={values.password} />}
                  <FormError error={status && status.formError} />
                  {submitError && !registerError && (
                    <p className={styles.errorText}>
                      {'Having trouble? You can contact us at '}
                      <a style={{ color: 'red' }} href="mailto: contact@profilepensions.co.uk">{'contact@profilepensions.co.uk'}</a>
                    </p>
                  )}
                  <br />
                  <div className={styles.terms}>
                    {CONSTANTS.REGISTER_PAGE_TEXT}
                    <a className={styles.privacyLink} rel="noopener noreferrer" target="_blank" href="https://www.profilepensions.co.uk/privacy-policy">{'Privacy Policy'}</a>
                    {'.'}
                  </div>
                  <br />
                  <div className={styles.terms}>
                    {'This site is protected by reCAPTCHA and the Google '}
                    <a href="https://policies.google.com/privacy">{'Privacy Policy'}</a>
                    {' and '}
                    <a href="https://policies.google.com/terms">{'Terms of Service'}</a>
                    {' apply.'}
                  </div>
                  <div className={styles.submitContainer}>
                    <Button
                      size="large"
                      type="submit"
                      disabled={!!Object.keys(errors).length}
                      label="Access Account"
                      loading={isSubmitting}
                    />
                  </div>
                  <div className={styles.login}>
                    {'Already have access? '}
                    <Link className={styles.loginLink} to="/login">{'Login'}</Link>
                  </div>
                </Form>
              </div>
            </section>
          );
        }}
      />
    );
  }
}

Register.propTypes = {
  authError: PropTypes.shape({ message: PropTypes.string.isRequired }),
};

Register.defaultProps = {
  authError: null,
};

const mapStateToProps = (state) => ({
  authError: getAuthError(state),
  uid: getFirebaseUid(state),
});

const mapDispatchToProps = {
  dispatchValidatePostcodeDobViaEmail,
  dispatchNewRegisterComplete: newRegisterCompleteAction,
};

export default compose(
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Register));
