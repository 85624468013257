import React from 'react';
import { connect } from 'react-redux';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { SERPS_PENSION_FOUND_UPDATE_DISMISSED, serpsCompleteRequestNotificationDismissed } from '../../redux/modules/notificationTracking';
import { getFirebaseUid } from '../../redux/selectors';

const SerpsCompleteRequestNotification = ({
  uid,
  dispatchSerpsCompleteRequestNotificationDismissed,
}) => {
  return (
    <NotificationBanner
      alertCategory={'Blue'}
      alertTitle={'SERPS Trace'}
      alertDescription={'Great news! You are eligible for a SERPS pension trace. In order for us to contact HMRC on your behalf, we require some personal details to complete your request.'}
      ctaButtonOptions={{
        label: 'Start Trace',
        link: '/serps-request',
        event: SERPS_PENSION_FOUND_UPDATE_DISMISSED,
        handleClick: () => dispatchSerpsCompleteRequestNotificationDismissed(uid),
      }}
      closeButtonOptions={{
        handleClick: () => dispatchSerpsCompleteRequestNotificationDismissed(uid),
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
});

const mapDispatchToProps = ({
  dispatchSerpsCompleteRequestNotificationDismissed: serpsCompleteRequestNotificationDismissed,
});

export default connect(mapStateToProps, mapDispatchToProps)(SerpsCompleteRequestNotification);
