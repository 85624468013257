import React from 'react';
import { Formik, Field } from 'formik';
import { renderTextField } from '../util/formik-custom-fields';
import FormError from '../components/FormError/FormError';
import Button from '../components/Button/Button';
import ButtonLink from '../components/ButtonLink/ButtonLink';
import styles from './LoginStep1.css';

const LoginStep2 = ({ onSubmit, onResendSMS }) => (
  <Formik
    onSubmit={onSubmit}
    render={({
      handleSubmit,
      isSubmitting,
      status,
      setStatus,
    }) => (
      <form onSubmit={handleSubmit}>
        <p className={styles.instructionText}>
          {'Please enter the verification code we’ve just sent you via SMS in the box below to verify your identity.'}
        </p>
        <Field
          name="smsCode"
          type="tel"
          component={renderTextField}
          label="Verification code"
          defaultValue="Enter verification code"
        />
        <br />
        <FormError error={status && status.formError} />
        <ButtonLink onClick={() => onResendSMS(setStatus)} label="Resend code" font="bodyTwo" />
        <br />
        <br />
        <Button size="large" type="submit" label="Confirm" loading={isSubmitting} />
      </form>
    )}
  />
);

export default LoginStep2;
