import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '../../../components/Button/Button';

import PensionsList from '../../../components/PensionsList/PensionsList';
import { pensionTransferNavigationButtonClicked, serpsPensionsTransferNavigationButtonClicked } from '../../../redux/modules/investmentAdvice';
import { setSelectedSerpsToAuthoriseAction } from '../../../redux/modules/serps';
import { getPensions } from '../../../redux/selectors/pensions';
import { getPensionsWithHmrcResponseEligibleYes } from '../../../redux/selectors/serps';

import GreenTick from '../../../assets/images/icons/Icon_Circle_Green_Tick_Rounded.svg';
import BlueDash from '../../../assets/images/icons/Icon_Circle_Blue_Dash_Rounded.svg';
import RedCross from '../../../assets/images/icons/Icon_Circle_Red_Cross_Rounded.svg';
import IconArrowWhite from '../../../assets/images/icons/Icon_Arrow_White.svg';
import AegonIconRound from '../../../assets/images/pensionIcons/Aegon_Icon_Round.svg';
import AvivaIconRound from '../../../assets/images/pensionIcons/Aviva_Icon_Round.svg';
import NestIconRound from '../../../assets/images/pensionIcons/Nest_Icon_Round.svg';
import NowIconRound from '../../../assets/images/pensionIcons/Now_Icon_Round.svg';
import PeoplesIconRound from '../../../assets/images/pensionIcons/Peoples_Pension_Icon_Round.svg';
import RoyalIconRound from '../../../assets/images/pensionIcons/Royal_London_Icon_Round.svg';
import ScottishWidowsIconRound from '../../../assets/images/pensionIcons/Scottish_Widows_Icon_Round.svg';
import StandardLifeIconRound from '../../../assets/images/pensionIcons/Standard_Life_Icon_Round.svg';
import BlueTick from '../../../assets/images/icons/Tick_Pension_Selected.svg';
import TickPensionUnSelected from '../../../assets/images/icons/Tick_Pension_Unselected_Large.svg';

import styles from './PlanActivityPensionRows.css';

const pensionIconMap = {
  aegon: AegonIconRound,
  aviva: AvivaIconRound,
  nest: NestIconRound,
  nowpensions: NowIconRound,
  thepeoplespension: PeoplesIconRound,
  royallondon: RoyalIconRound,
  scottishwidows: ScottishWidowsIconRound,
  standardlife: StandardLifeIconRound,
};

const instructionIconMap = [
  {
    key: 'complete', icon: GreenTick, altText: 'Green tick - Pension transfer complete',
  },
  {
    key: 'inProgress', icon: BlueDash, altText: 'Dotted dash - Pension transfer in progress',
  },
  {
    key: 'closed', icon: RedCross, altText: 'Red cross - Pension transfer closed',
  },
];

const getInstructionIcon = (instructionIcon) => {
  return instructionIconMap.find(
    (icon) => icon.key === instructionIcon,
  );
};

const PlanActivityPensionRows = ({
  onboardingPensions = [],
  history,
  pensionTransferClicked,
  serpsPensionsTransferClicked,
  dispatchSetSelectedSerpsToAuthoriseAction,
  serpsUnauthorisedPensions,
}) => {
  const [selectedSerpsPensions, setSelectedSerpsPensions] = useState(serpsUnauthorisedPensions.map((p) => p._id) || []);
  const openAddOrTransferPensionPage = (pension) => {
    history.push(`/add-pensions?pension=${pension._id}`);
  };

  const transferSerpsPensionsPage = () => {
    history.push('/serps-auth');
  };

  if (!onboardingPensions.length) {
    return (<p className={styles.subText}>{'You have no pension transfers in progress'}</p>);
  }

  const providerIcon = ({
    _id,
    formatProviderContact,
    displayName,
    serpsText,
    authority,
  }) => {
    if (serpsText && !authority) {
      const handleClick = () => {
        const updatedSelection = selectedSerpsPensions.includes(_id)
          ? selectedSerpsPensions.filter((p) => p !== _id)
          : [...selectedSerpsPensions, _id];

        setSelectedSerpsPensions(updatedSelection);
      };

      return (
        <img
          className={styles.selectedTick}
          onClick={handleClick}
          src={selectedSerpsPensions.includes(_id) ? BlueTick : TickPensionUnSelected}
          alt="Serps selected tick"
        />
      );
    }
    if (formatProviderContact && pensionIconMap[formatProviderContact]) {
      return (
        <img
          className={styles.pensionIconImage}
          src={pensionIconMap[formatProviderContact]}
          alt={displayName}
        />
      );
    }

    if (displayName) {
      return (
        <div className={styles.pensionIconTextContainer}>
          <p className={styles.pensionIconText}>
            {displayName[0].toUpperCase()}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={styles.pensionRowsContainer}>
      <PensionsList fromPlanActivity>
        {({
          _id,
          displayName,
          instructionIcon,
          instructionSubText,
          serpsText,
          hideTransferButton,
          pension,
          enabledTransferButton,
          pensionListIndex,
          mappedProviderContact,
          serpsPensionInProgress,
          enableTransferPensionsButton,
          authority,
        }) => {
          const formatProviderContact = mappedProviderContact && mappedProviderContact.replace(/[^A-Z0-9]+/ig, '').toLowerCase();
          const getProviderIcons = providerIcon({
            _id,
            formatProviderContact,
            displayName,
            serpsText,
            authority,
          });

          return (
            <div className={styles.pensionRowContainer} key={_id}>
              {!serpsPensionInProgress && (
              <div className={styles.pensionIconWrapper}>
                {getProviderIcons}
              </div>
              )}
              <div className={styles.pensionNameWrapper}>
                <p className={styles.pensionName}>
                  {displayName}
                </p>
                <p className={styles.subText}>{serpsText}</p>
                <p className={styles.subText}>{instructionSubText}</p>
                {!hideTransferButton && (
                <div className={styles.buttonContainer}>
                  <Button
                    variant="tertiary"
                    size="xsmall"
                    label={(
                      <div className={styles.transferButtonContents}>
                        <img className={styles.transferButtonIcon} src={IconArrowWhite} alt="transfer pension" />
                        <span>{'Transfer pension'}</span>
                      </div>
                      )}
                    onClick={(e) => {
                      pensionTransferClicked();
                      openAddOrTransferPensionPage(pension);
                      e.stopPropagation();
                    }}
                    dataCy="pension-dashboard-link"
                    disabled={!enabledTransferButton}
                  />
                </div>
                )}
                {enableTransferPensionsButton && (
                  <div className={styles.buttonContainer}>
                  <Button
                    variant="tertiary"
                    size="xsmall"
                    label={(
                      <div className={styles.transferButtonContents}>
                        <img className={styles.transferButtonIcon} src={IconArrowWhite} alt="transfer pension" />
                        <span>{selectedSerpsPensions.length > 1 ? 'Transfer pensions' : 'Transfer pension'}</span>
                      </div>
                      )}
                    onClick={(e) => {
                      dispatchSetSelectedSerpsToAuthoriseAction(selectedSerpsPensions);
                      serpsPensionsTransferClicked();
                      transferSerpsPensionsPage();
                      e.stopPropagation();
                    }}
                    dataCy="pension-dashboard-link"
                    disabled={!enabledTransferButton || selectedSerpsPensions.length === 0}
                  />
                </div>
                )}
              </div>
              <div className={styles.pensionStatusWrapper}>
                <img
                  className={styles.pensionStatusIcon}
                  src={getInstructionIcon(instructionIcon).icon}
                  alt={getInstructionIcon(instructionIcon).altText}
                />
              </div>
            </div>
          );
        }}
      </PensionsList>
    </div>
  );
};

const mapStateToProps = (state) => ({
  onboardingPensions: getPensions(state),
  serpsUnauthorisedPensions: getPensionsWithHmrcResponseEligibleYes(state),
});

const mapDispatchToProps = (dispatch) => ({
  pensionTransferClicked: () => dispatch(pensionTransferNavigationButtonClicked()),
  serpsPensionsTransferClicked: () => dispatch(serpsPensionsTransferNavigationButtonClicked()),
  dispatchSetSelectedSerpsToAuthoriseAction: (selectedPensions) => dispatch(setSelectedSerpsToAuthoriseAction({ selectedPensions })),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlanActivityPensionRows));
