import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirestore } from 'react-redux-firebase';
import { withFormik, Field } from 'formik';
import { validate } from 'validate.js';
import once from 'lodash/once';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import get from 'lodash/get';
import firebase from 'firebase/compat/app';
import { convertFlattenedObjectToObject, addressConstraints } from '../../util/completePersonalDetailsValidation';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import styles from './PersonalDetails.css';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import {
  missingDetailsReturnLinkClickedAction,
  missingDetailsMessageMeClickedAction,
  missingDetailsFindAddressClicked,
  missingDetailsAddressDropdownClicked,
  missingDetailsPostcodeDeleted,
  missingDetailsPostcodeTyped,
  missingDetailsAddressSelected,
  missingDetailsAddressSaveSelected,
} from '../../redux/modules/signupFlow';
import { renderAddressFields, renderMissingDetailsPostcodeAddressLookup } from '../../util/formik-custom-fields';
import { getUser } from '../../redux/selectors';
import Button from '../../components/Button/Button';
import { GENERIC_ERROR } from '../../forms/copyTexts';
import sentryException from '../../util/sentryException';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';

const ADDRESS_FIELDS = ['line1', 'line2', 'line3', 'line4', 'locality', 'city', 'county', 'postcode'];

const removeWhitespace = (string) => {
  return string ? string.replace(/\s/g, '') : '';
};
const uppercase = (string) => {
  return string ? string.toUpperCase() : '';
};
const formatPostcode = (postcode) => {
  return postcode ? `${postcode.slice(0, -3)} ${postcode.slice(-3)}` : '';
};

const renderField = ({
  type,
  name,
  label,
  value,
  options,
  onChange,
  component,
  ...custom
}) => (
  <Field
    type={type}
    name={name}
    label={label}
    value={value}
    options={options}
    onChange={onChange}
    component={component}
    largeHeadings
    {...custom}
  />
);

class AddAPreviousAddressStep extends Component {
  constructor(props) {
    super(props);

    const {
      dispatchMissingDetailsPostcodeDeleted,
      dispatchMissingDetailsPostcodeTyped,
      dispatchMissingDetailsAddressSaveSelected,
    } = this.props;
    this.dispatchMissingDetailsPostcodeDeleted = once(dispatchMissingDetailsPostcodeDeleted);
    this.dispatchMissingDetailsPostcodeTyped = once(dispatchMissingDetailsPostcodeTyped);
    this.dispatchMissingDetailsAddressSaveSelected = once(dispatchMissingDetailsAddressSaveSelected);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    const {
      isSubmitting,
      isValid,
      errors,
    } = this.props;

    if (!isValid && prevProps.isSubmitting && !isSubmitting) {
      const [firstError] = Object.keys(errors);
      let errorElement = document.querySelector(`input[name="${firstError}"]`);
      if (firstError === 'address') {
        const addressKeys = Object.keys(errors[firstError]);
        errorElement = document.querySelector(`input[name="${firstError}-${addressKeys[0]}"]`);
      }
      if (errorElement && errorElement.parentNode) {
        errorElement.parentNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    if (isValid && !prevProps.isSubmitting && isSubmitting) {
      document
        .querySelector('#missing-details-submit')
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  defaultHandleChange = (event, fieldName) => {
    const { handleChange } = this.props;
    handleChange(event);
    const postalCode = get(event, 'target.value');

    if (fieldName === 'postcode') {
      if (!postalCode) {
        this.dispatchMissingDetailsPostcodeDeleted();
      } else {
        this.dispatchMissingDetailsPostcodeTyped();
      }
    }
  };

  addressHandleChange = (fieldValue) => {
    const {
      setFieldValue,
      setTouched,
      touched,
      setInEditMode,
      setEditingComponent,
      values,
    } = this.props;

    setFieldValue('address', fieldValue);

    if (fieldValue.line1 && fieldValue.city) {
      setTouched({ ...touched, address: true });
    }

    if (fieldValue.line1 && fieldValue.city && values.postcode) {
      setInEditMode(true);
      setEditingComponent(true);
    }
  };

  getAddresses = async (postcode) => {
    let addresses = [];
    const getAddresses = firebase.app().functions('europe-west1').httpsCallable('addressLookup');

    try {
      ({ data: addresses } = await getAddresses({ postcode }));
    } catch (error) {
      console.log(error);
      sentryException(error, {
        section: 'missing-details',
      });
    }

    return addresses;
  };

  selectAddress = (address = '') => {
    const {
      setFieldValue,
      setTouched,
      touched,
      setStep,
      setFormData,
      values,
      setEditingComponent,
    } = this.props;

    const updatedAddress = {
      line1: '',
      line2: '',
      line3: '',
      line4: '',
      locality: '',
      city: '',
      county: '',
      postCode: formatPostcode(uppercase(removeWhitespace(values.postcode))),
      livedAtAddressDateFrom: '',
      livedAtAddressDateTo: '',
    };

    address.split(',').forEach((field, index) => {
      if (ADDRESS_FIELDS && ADDRESS_FIELDS[index]) {
        updatedAddress[ADDRESS_FIELDS[index]] = field.trim();
      }
    });

    setFieldValue('address', updatedAddress);
    if (updatedAddress.line1 && updatedAddress.city) {
      setTouched({ ...touched, address: false });
      setEditingComponent(true);
    }
    setFormData((_formData) => ({
      ..._formData,
      updatedAddress,
    }));
    setStep((_step) => _step + 1);
  };

  fieldsConfig = () => {
    const {
      values,
    } = this.props;

    return {
      postCode: {
        type: 'text',
        name: 'postcode',
        label: 'Postcode',
        value: values.postcode,
        onChange: (event) => this.defaultHandleChange(event, 'postcode'),
        component: renderMissingDetailsPostcodeAddressLookup,
        getAddresses: this.getAddresses,
        selectAddress: this.selectAddress,
      },
      address: {
        type: null,
        name: 'address',
        label: 'Address',
        value: values.address,
        onChange: this.addressHandleChange,
        component: renderAddressFields,
        wrapperStyles: { marginBottom: '15px' },
      },
    };
  };

  constructErrorMessage = (data) => {
    if (!data) {
      return;
    }

    return (
      <div>
        {GENERIC_ERROR}
        {this.constructEmailUsElement()}
      </div>
    );
  }

  render() {
    const {
      step,
      totalSteps,
      amendMode,
      setStep,
      dispatchMissingDetailsReturnLinkClickedAction,
      dispatchMissingDetailsMessageMeClickedAction,
      dispatchMissingDetailsAddressSaveSelected,
      values,
      initialValues,
      errors,
      isValid,
      editingComponent,
      setFormData,
    } = this.props;
    const fieldsConfig = this.fieldsConfig();

    const { address } = values;
    const { postcode } = values;

    const formValuesHaveChanged = !isEqual(initialValues.fieldValue, !values.address);

    const showSave = formValuesHaveChanged && editingComponent;

    return (
      <BaseGrid tabletTwelveColumn addPensionsTheme>
        <div className={styles.missingDetailsWrapper}>
          <div className={styles.progressContainer}>
            <ProgressStepper maxStep={totalSteps} step={step + 1} />
          </div>
          {!amendMode && (
            <ButtonLink
              onClick={(event) => {
                dispatchMissingDetailsReturnLinkClickedAction();
                event.stopPropagation();
                setStep((_step) => _step - 1);
              }}
              label="← Back"
            />
          )}
          <h1 className={styles.ninoHeading}>
            {'Add a previous address'}
          </h1>
          {Object.keys(fieldsConfig).map((field) => {
            return !fieldsConfig[field].hidden
              && (
                <div className="field" key={fieldsConfig[field].name}>
                  {renderField(fieldsConfig[field])}
                </div>
              );
          })}
          <div className={styles.errorMessage}>{errors && this.constructErrorMessage(errors.submitError)}</div>
          {showSave && (
            <Button
              type="button"
              label="Save"
              mediumFixedWidth
              disabled={!formValuesHaveChanged || !isValid}
              onClick={() => {
                dispatchMissingDetailsAddressSaveSelected();
                setFormData((_formData) => ({
                  ..._formData,
                  updatedAddress: { ...address, postCode: formatPostcode(uppercase(postcode)) },
                }));
                setStep((_step) => _step + 1);
              }}
            />
          )}
          {<div>&nbsp;</div>}
          <div className={styles.pensionAdviserCard}>
            <ContactUsContent
              source={'AddAPreviousAddressStep'}
              noExpertContentWrapper={({ children }) => (
                <div
                  style={{
                    display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                  }}
                >
                  {children}
                </div>
              )}
              showButton={false}
              customMessage={(
                <div
                  className={styles.contactUsInnerContent}
                >
                  {'If you have any questions, please feel free to '}
                  <ButtonLink
                    label="message me"
                    variant="primary"
                    link="/inbox/new-message"
                    font="inherit"
                    target="_blank"
                    onClick={() => dispatchMissingDetailsMessageMeClickedAction()}
                  />
                  {'.'}
                </div>
              )}
            />
          </div>
        </div>
      </BaseGrid>
    );
  }
}

const FormikEnhancer = withFormik({
  validate: (formValues) => {
    const valuesToValidate = {};

    // Create a new object which matches the forms object but strings are set to null.
    Object.keys(formValues).forEach((value) => {
      valuesToValidate[value] = formValues[value] || null;
    });

    // Validate the current values from the form object against the constraints
    const validationResult = validate(
      pick(valuesToValidate, Object.keys(addressConstraints)),
      addressConstraints,
    ) || {};

    return convertFlattenedObjectToObject(validationResult);
  },
  displayName: 'AddAPreviousAddressStep',
})(AddAPreviousAddressStep);

const mapDispatchToProps = {
  dispatchMissingDetailsPostcodeDeleted: missingDetailsPostcodeDeleted,
  dispatchMissingDetailsPostcodeTyped: missingDetailsPostcodeTyped,
  dispatchMissingDetailsFindAddressClicked: missingDetailsFindAddressClicked,
  dispatchMissingDetailsAddressDropdownClicked: missingDetailsAddressDropdownClicked,
  dispatchMissingDetailsAddressSelected: missingDetailsAddressSelected,
  dispatchMissingDetailsReturnLinkClickedAction: missingDetailsReturnLinkClickedAction,
  dispatchMissingDetailsMessageMeClickedAction: missingDetailsMessageMeClickedAction,
  dispatchMissingDetailsAddressSaveSelected: missingDetailsAddressSaveSelected,
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

export default compose(
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps),
)(FormikEnhancer);
