exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"tablet": "768px",
	"desktop": "1020px",
	"midDesktop": "1000px",
	"typographyDesktop": "1280px",
	"mobile": "375px",
	"largeDesktop": "1440px",
	"gutterSmall": "10px",
	"gutter": "20px",
	"gutterLarge": "40px",
	"sideDrawerWidth": "75px",
	"radius": "8px",
	"radiusSmall": "3px",
	"radiusMed": "6px",
	"radiusLarge": "10px",
	"shadow": "0 0 6px 0 rgba(0,0,0,0.08)",
	"footerSpacing": "110px",
	"headerHeight": "100px"
};