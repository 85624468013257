
export const CONSTANTS = {
  SIGNUP_PAGE_TEXT: 'By clicking ‘Sign up’ below, you’re agreeing to us processing your personal data to allow us to deliver our service. Information on how we use your data can be found in our ',
  REGISTER_PAGE_TEXT: 'By clicking ‘Access account’ below, you’re agreeing to us processing your personal data to allow us to deliver our service. Information on how we use your data can be found in our ',
  MARKETING_PREFERENCES_TEXT: 'Profile Pensions may also send you information about other services that we think you might be interested in, which you can choose to receive by:',
};

export const PREVIOUSLY_SIGNED_UP = 'We have identified an existing account with National Insurance number you have provided. Please verify with the following email that you provided us {PARTIAL_EMAIL}, or alternatively ';
export const WRONG_DPA_ANSWERS = 'We have detected the details you provided match some of the information we have in record. Please try again by providing the correct postcode and date of birth.';
export const BLOCKED_ACCOUNT = 'Your account has been blocked because of 3 consecutive failed attempts to provide the correct information. ';
export const GENERIC_ERROR = 'Oops! Something is wrong with your account. ';
