import React from 'react';
import styles from './FooterLogo.css';
import logoUrlWithMfGroup from '../../assets/images/rebranding/pp-logo-mf-group.svg';

const FooterLogo = () => (
  <div className={styles.logoContainer}>
    <img className={styles.logo} src={logoUrlWithMfGroup} alt="Profile Pensions Logo" />
  </div>
);

export default FooterLogo;
