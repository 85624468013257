exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-MessageWidget-emailIcon-3mvql {\n  height: 27px;\n}\n.-MessageWidget-message-maYg4 {\n  display: none;\n  color: white;\n  font-size: 18px;\n  width: -webkit-max-content;\n  width: -moz-max-content;\n  width: max-content;\n  text-align: center;\n  white-space: nowrap;\n}\n.-MessageWidget-button-2Pd8X {\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  padding: 0;\n  margin: 0;\n  border-radius: 40px;\n  height: 60px;\n  width: 60px;\n  -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);\n          box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);\n  background-color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["accentBasePrimaryRegular"] + ";\n  border: none;\n  outline: none;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-transition: width 0.25s ease;\n  transition: width 0.25s ease;\n  cursor: pointer;\n  z-index: 10;\n}\n.-MessageWidget-button-2Pd8X, .-MessageWidget-button-2Pd8X:hover, .-MessageWidget-border-2BOzP:active {\n  border: none;\n  outline: none;\n}\n.-MessageWidget-button-2Pd8X:hover {\n  width: 130px;\n  border-radius: 40px;\n}\n.-MessageWidget-button-2Pd8X:hover .-MessageWidget-emailIcon-3mvql {\n  display: none;\n}\n.-MessageWidget-button-2Pd8X:hover .-MessageWidget-message-maYg4 {\n  display: inline;\n  display: initial;\n}", ""]);

// exports
exports.locals = {
	"colors": "\"../../theme/colors.css\"",
	"accentBasePrimaryRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["accentBasePrimaryRegular"] + "",
	"emailIcon": "-MessageWidget-emailIcon-3mvql",
	"message": "-MessageWidget-message-maYg4",
	"button": "-MessageWidget-button-2Pd8X",
	"border": "-MessageWidget-border-2BOzP"
};