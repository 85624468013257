exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);

// module
exports.push([module.id, ".-FooterSmallprint-wrapper-1biij {\n  grid-row: 7;\n  grid-column: 1 / span 6;\n  margin: 32px 0 72px 0;\n  width: 100%;\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n  .-FooterSmallprint-wrapper-1biij {\n    grid-row: 5;\n    grid-column: 1 / span 10;\n    margin: 43px 0 74px 0;\n  }\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["largeDesktop"] + ") {\n  .-FooterSmallprint-wrapper-1biij {\n    grid-row: 4;\n    grid-column: 4 / span 9;\n    margin: 32px 0 60px 0;\n  }\n}\n.-FooterSmallprint-smallprint-36JoO {\n  font-family: \"Lato\";\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 29px;\n  margin: 0;\n}", ""]);

// exports
exports.locals = {
	"dimensions": "\"../../theme/dimensions.css\"",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"largeDesktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["largeDesktop"] + "",
	"wrapper": "-FooterSmallprint-wrapper-1biij",
	"smallprint": "-FooterSmallprint-smallprint-36JoO"
};