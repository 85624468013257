import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import pickBy from 'lodash/pickBy';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import styles from './AddPensionsPage.css';
import { currencyString } from '../../util/currency';
import initialCapitalEachWord from '../../util/initialCapitalEachWord';
import IconInfo from '../../assets/images/rebranding/information-icon-in-circle.svg';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import CheckBoxGreen from '../../components/CheckBoxGreen/CheckBoxGreen';
import {
  getAuthoriseImplementPensionPlan,
} from '../../redux/selectors/investmentAdvice';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import Loader from '../../components/Loader/Loader';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import { PENSION_TRANSFER_KEY_FILES, transferOptions } from '../../util/constants';
import sentryException from '../../util/sentryException';
import captureScreen from '../../util/captureScreenshot';
import captureHtml from '../../util/captureHtml';
import {
  returnLinkClickedAction,
  submitStepLandedAction,
  recommendationReportLinkClickedAction,
  keyDetailsClickedAction,
  illustrationClickedAction,
  termsOfBusinessClickedAction,
  authoriseTransferBasicCheckboxClickedAction,
  authoriseImplementCheckboxClickedAction,
  addPensionAction,
} from '../../redux/modules/pensions';
import { setHasRecentlyAddedFindCheckAndTransferPensionAction } from '../../redux/modules/investmentAdvice';
import { THINGS_TO_CONSIDER_URL } from '../../config';

const {
  illustrationOnePercentArrangementFee, illustrationNoArrangementFee,
} = PENSION_TRANSFER_KEY_FILES;

const SubmitStep = ({
  setFormData,
  setStep,
  formData,
  amendMode,
  step,
  totalSteps,
  isCustomerOngoing,
  authoriseImplementPensionPlan,
  dispatchAddPension,
  dispatchSubmitStepLanded,
  dispatchReturnLinkClicked,
  dispatchRecommendationReportLinkClicked,
  dispatchKeyDetailsClicked,
  dispatchIllustrationClicked,
  dispatchTermsOfBusinessClicked,
  dispatchAuthoriseTransferBasicCheckboxClicked,
  dispatchAuthoriseImplementCheckboxClicked,
  dispatchSetHasRecentlyAddedFindCheckAndTransferPensionAction,
  investmentAdviceLetterUrl,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchSubmitStepLanded(isCustomerOngoing, formData.transferOption);
  }, []);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);

  const commonName = get(formData, 'commonName');
  const employerName = get(formData, 'employerName');
  const employmentStartDate = get(formData, 'employmentStartDate');
  const employmentEndDate = get(formData, 'employmentEndDate');
  const employerLocation = get(formData, 'employerLocation');
  const employerIndustry = get(formData, 'employerIndustry');
  const estimatedValue = get(formData, 'estimatedValue');
  const customerAnsweredAlreadyDrawingDown = get(formData, 'customerAnsweredAlreadyDrawingDown');
  const currentWorkplacePension = get(formData, 'currentWorkplacePension');
  const policyNumber = get(formData, 'policyNumber');
  const isTransferBasic = get(formData, 'transferOption') === transferOptions.TRANSFER_BASIC;
  const isTransferWithCheck = formData.transferOption === transferOptions.TRANSFER_WITH_CHECK;
  const shouldAskAuthoriseImplement = !authoriseImplementPensionPlan && !isCustomerOngoing;
  const isDisabled = ![
    formData.authoriseTransferBalanceOfPension,
    (formData.authoriseTransferOptionTransferBasic
      || isTransferWithCheck),
    (formData.authoriseImplementPensionPlan || !shouldAskAuthoriseImplement),
  ].every((v) => v === true);

  const providerNameCapitalised = initialCapitalEachWord(commonName);

  const onSubmit = async () => {
    try {
      setShowSubmitError(false);
      setIsSubmitLoading(true);
      let screenshot;
      try {
        screenshot = await captureScreen();
      } catch (error) {
        sentryException(error, {
          section: 'add-pension-capture-screen',
        });
        throw error;
      }
      const pageHtml = captureHtml();

      const pensions = [pickBy(formData, (v, k) => [
        'commonName',
        'freeTextProvider',
        'isOldEmployerPension',
        'employmentStartDate',
        'employmentEndDate',
        'employerLocation',
        'employerIndustry',
        'employerAdditionalInformation',
        'currentWorkplacePension',
        'policyNumber',
        'estimatedValue',
        '_id',
        'authoriseTransferBalanceOfPension',
        'employerName',
        'transferOption',
        'customerAnsweredAlreadyDrawingDown',
      ].includes(k) && typeof v !== 'undefined' && (v !== '' || k === 'policyNumber'))];

      if (!pensions[0].policyNumber) {
        pensions[0].policyNumber = '';
      }
      if (pensions[0].employerName) {
        pensions[0].commonName = employerName;
      }
      if (pensions[0].transferOption === transferOptions.TRANSFER_BASIC) {
        pensions[0].authoriseTransferOptionTransferBasic = true;
      }
      if (pensions[0].transferOption === transferOptions.TRANSFER_WITH_CHECK) {
        pensions[0].authoriseTransferOptionTransferWithCheck = true;
      }
      await dispatchAddPension({
        pensions,
        screenshot,
        pageHtml,
        topup: formData.topup,
        authoriseImplementPensionPlan: Boolean(
          formData.authoriseImplementPensionPlan || authoriseImplementPensionPlan,
        ),
        isCustomerOngoing,
        transferOption: formData.transferOption,
      });
      setIsSubmitLoading(false);
      if (isTransferWithCheck) { dispatchSetHasRecentlyAddedFindCheckAndTransferPensionAction(); }
      setStep((_step) => _step + 1);
    } catch (error) {
      setShowSubmitError(true);
      setIsSubmitLoading(false);
    }
  };
  const debouncedOnSubmit = debounce(onSubmit, 3000, { leading: true, trailing: false });

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.submitStepWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
          <ButtonLink
            onClick={(event) => {
              dispatchReturnLinkClicked(isCustomerOngoing, formData.transferOption);
              setStep((_step) => _step - 1);
              event.stopPropagation();
            }}
            label="← Back"
            disabled={isSubmitLoading}
          />
        )}
        <h2 className={styles.submitHeading}>
          {'Transfer this pension to your pension plan'}
        </h2>
        <div
          className={styles.pensionDetailsCard}
        >
          <div
            className={styles.firstPensionNameRow}
          >
            {employerName ? 'Unknown pension' : providerNameCapitalised}
          </div>
          <div
            className={styles.pensionDetailsRow}
          >
            {employerName ? 'Employer Name' : 'Provider Name'}
            <br />
            {employerName || providerNameCapitalised}
          </div>
          { employerLocation && (
            <div
              className={styles.pensionDetailsRow}
            >
              {'Employer location'}
              <br />
              {employerLocation}
            </div>
          )}
          { employerIndustry && (
            <div
              className={styles.pensionDetailsRow}
            >
              {'Employer sector'}
              <br />
              {employerIndustry}
            </div>
          )}
          { (employmentStartDate && employmentEndDate) && (
            <div
              className={styles.pensionDetailsRow}
            >
              {'Approximate dates worked at the company'}
              <br />
              {`${employmentStartDate.slice(0, 4)} - ${employmentEndDate.slice(0, 4)}`}
            </div>
          )}
          { policyNumber && (
            <div
              className={styles.pensionDetailsRow}
            >
              {'Policy Number'}
              <br />
              {policyNumber}
            </div>
          )}
          { estimatedValue && (
            <div
              className={styles.pensionDetailsRow}
            >
              {'Approximate Pension Value'}
              <br />
              {currencyString(estimatedValue)}
            </div>
          )}
          {
            typeof currentWorkplacePension === 'boolean' && (
              <div
                className={styles.pensionDetailsRow}
              >
                {'Employer currently contributing to your pension?'}
                <br />
                {currentWorkplacePension ? 'Yes' : 'No'}
              </div>
            )
          }
          { typeof customerAnsweredAlreadyDrawingDown === 'boolean' && (
          <div
            className={styles.pensionDetailsRow}
          >
            {'Have you begun withdrawing from your pension?'}
            <br />
            {customerAnsweredAlreadyDrawingDown ? 'Yes' : 'No'}
          </div>
          )}
        </div>
        {isTransferWithCheck && (
        <div>
          <div className={styles.confirmationBoxes}>
            <div className={styles.infoHeadingContainer}>
              <img src={IconInfo} alt="information" className={styles.info} />
              <h3 className={styles.infoboxHeading}>{'Important Information'}</h3>
            </div>
            <div className={styles.infoText}>{'We will charge you a 1% fee to arrange the transfer of your pension which will be deducted from your pension. We will not transfer your pension if we find any guarantees or exit penalties over £50 until we have spoken to you.'}</div>
          </div>
          <div className={styles.confirmationDivider} />
        </div>
        )}
        <div
          className={styles.confirmationBoxes}
        >
          <h6 className={styles.infoboxHeading}>{'Please confirm you have read the following'}</h6>
          {
              (investmentAdviceLetterUrl || isCustomerOngoing) ? (
                <React.Fragment>
                  {shouldAskAuthoriseImplement && (
                    <React.Fragment>
                      <CheckBoxGreen
                        wrapperStyles={{ margin: '0 0 30px' }}
                        label={(
                          <React.Fragment>
                            <span>
                              {'I authorise Profile Pensions to implement my Pension Plan. I have read and understand the '}
                              <a
                                className={styles.documentsDownloadLink}
                                href={investmentAdviceLetterUrl}
                                onClick={() => dispatchRecommendationReportLinkClicked(
                                  isCustomerOngoing,
                                )}
                                download
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {'Recommendation'}
                              </a>
                              {', '}
                              <a
                                href={PENSION_TRANSFER_KEY_FILES.keyFeatures}
                                onClick={() => dispatchKeyDetailsClicked(
                                  isCustomerOngoing,
                                  formData.transferOption,
                                )}
                                download
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {'Key Features'}
                              </a>
                              {' and '}
                              <a
                                href={
                                  isTransferWithCheck
                                    ? illustrationOnePercentArrangementFee
                                    : illustrationNoArrangementFee
                                }
                                onClick={() => dispatchIllustrationClicked(
                                  isCustomerOngoing,
                                  formData.transferOption,
                                )}
                                download
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {'Illustration'}
                              </a>
                              {'.'}
                            </span>
                          </React.Fragment>
                      )}
                        value={formData.authoriseImplementPensionPlan}
                        onChange={() => {
                          dispatchAuthoriseImplementCheckboxClicked(
                            isCustomerOngoing,
                            formData.transferOption,
                          );
                          setFormData((_formData) => ({
                            ..._formData,
                            authoriseImplementPensionPlan: !_formData.authoriseImplementPensionPlan,
                          }));
                        }}
                      />
                      <div className={styles.confirmationDivider} />
                    </React.Fragment>
                  )}
                  { isTransferBasic && (
                  <div>
                    <CheckBoxGreen
                      wrapperStyles={{ margin: '0 0 30px' }}
                      label={'I understand that Profile Pensions will not check for guarantees and penalties when they transfer this pension.'}
                      value={formData.authoriseTransferOptionTransferBasic}
                      onChange={() => {
                        dispatchAuthoriseTransferBasicCheckboxClicked(
                          isCustomerOngoing,
                          formData.transferOption,
                        );
                        setFormData((_formData) => ({
                          ..._formData,
                          authoriseTransferOptionTransferBasic: !_formData.authoriseTransferOptionTransferBasic,
                        }));
                      }}
                    />
                    <div className={styles.confirmationDivider} />
                  </div>
                  )}
                  <CheckBoxGreen
                    label={(
                      <React.Fragment>
                        <span>
                          {'I authorise Profile Pensions to transfer the balance of this pension to my Profile Pensions Pension Plan. I understand the decision to transfer existing pensions is my own and I have read and understood the '}
                          <a
                            href={THINGS_TO_CONSIDER_URL}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {'Important Considerations'}
                          </a>
                          {' regarding guarantees and exit penalties and have received, understood and agree to the '}
                          <a
                            href={PENSION_TRANSFER_KEY_FILES.termsOfBusiness}
                            onClick={() => dispatchTermsOfBusinessClicked(
                              isCustomerOngoing,
                              formData.transferOption,
                            )}
                            download
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {'Terms of Business'}
                          </a>
                          {'.'}
                        </span>
                      </React.Fragment>
                    )}
                    value={formData.authoriseTransferBalanceOfPension}
                    onChange={() => {
                      dispatchAuthoriseImplementCheckboxClicked(
                        isCustomerOngoing,
                        formData.transferOption,
                      );
                      setFormData((_formData) => ({
                        ..._formData,
                        authoriseTransferBalanceOfPension:
                        !_formData.authoriseTransferBalanceOfPension,
                      }));
                    }}
                  />
                </React.Fragment>
              ) : <Loader />
            }
        </div>
      </div>
      <div
        className={styles.submitContainer}
      >
        {showSubmitError && (
          <div
            className={styles.error}
          >
            {'Something went wrong - please try again'}
          </div>
        )}
        <Button
          size="large"
          label="Submit"
          disabled={isDisabled}
          loading={isSubmitLoading}
          onClick={debouncedOnSubmit}
        />
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
  authoriseImplementPensionPlan: getAuthoriseImplementPensionPlan(state),
});

const mapDispatchToProps = {
  dispatchAddPension: addPensionAction,
  dispatchSubmitStepLanded: submitStepLandedAction,
  dispatchReturnLinkClicked: returnLinkClickedAction,
  dispatchRecommendationReportLinkClicked: recommendationReportLinkClickedAction,
  dispatchKeyDetailsClicked: keyDetailsClickedAction,
  dispatchIllustrationClicked: illustrationClickedAction,
  dispatchTermsOfBusinessClicked: termsOfBusinessClickedAction,
  dispatchAuthoriseTransferBasicCheckboxClicked: authoriseTransferBasicCheckboxClickedAction,
  dispatchAuthoriseImplementCheckboxClicked: authoriseImplementCheckboxClickedAction,
  dispatchSetHasRecentlyAddedFindCheckAndTransferPensionAction: setHasRecentlyAddedFindCheckAndTransferPensionAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitStep);
