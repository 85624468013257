exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-AssumptionsModal-closeWrapper-1gHHb {\n  width: 100%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n\n  cursor: pointer;\n}\n.-AssumptionsModal-closeWrapperText-2iDVr {\n  margin-left: 4px;\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["accentBaseSecondaryRegular"] + ";\n}\n.-AssumptionsModal-heading-2pOHz {\n}\n.-AssumptionsModal-listItem-2rnPP {\n  margin-bottom: 20px;\n}\n.-AssumptionsModal-listItem-2rnPP:last-child {\n  margin: 0;\n}\n.-AssumptionsModal-listItemLink-1YfHn {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textParagraphRegular"] + ";\n  margin: 0;\n}\n.-AssumptionsModal-subHeadingTitle-3euHP {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["thickFog"] + ";\n  margin: 0;\n}\n.-AssumptionsModal-subHeadingContent-3XuUK {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textParagraphRegular"] + ";\n  margin: 0 0 12px 0;\n}", ""]);

// exports
exports.locals = {
	"typography": "\"../../theme/typography.css\"",
	"h3": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h3"] + "",
	"subtext": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["subtext"] + "",
	"bodyOne": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"colors": "\"../../theme/colors.css\"",
	"accentBaseSecondaryRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["accentBaseSecondaryRegular"] + "",
	"textParagraphRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textParagraphRegular"] + "",
	"thickFog": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["thickFog"] + "",
	"closeWrapper": "-AssumptionsModal-closeWrapper-1gHHb",
	"closeWrapperText": "-AssumptionsModal-closeWrapperText-2iDVr " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["subtext"] + "",
	"heading": "-AssumptionsModal-heading-2pOHz " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h3"] + "",
	"listItem": "-AssumptionsModal-listItem-2rnPP " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"listItemLink": "-AssumptionsModal-listItemLink-1YfHn " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"subHeadingTitle": "-AssumptionsModal-subHeadingTitle-3euHP " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"subHeadingContent": "-AssumptionsModal-subHeadingContent-3XuUK " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + ""
};