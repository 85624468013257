import firebase from 'firebase/compat/app';
import { getFirestore } from 'redux-firestore';
import sentryException from '../../util/sentryException';
import { checkRequestAmount, uploadFileToBucket, addAttachmentRefToUser } from '../../util/attachments';

const CREATE_CONVERSATION_DISPATCHED = 'pp/conversations/CREATE_CONVERSATION_DISPATCHED';
const CREATE_CONVERSATION_FULFILLED = 'pp/conversations/CREATE_CONVERSATION_FULFILLED';
const CREATE_CONVERSATION_REJECTED = 'pp/conversations/CREATE_CONVERSATION_REJECTED';
const ADD_MESSAGE_DISPATCHED = 'pp/conversations/ADD_MESSAGE_DISPATCHED';
const ADD_MESSAGE_FULFILLED = 'pp/conversations/ADD_MESSAGE_FULFILLED';
const ADD_MESSAGE_REJECTED = 'pp/conversations/ADD_MESSAGE_REJECTED';
const UPDATE_MESSAGE_DISPATCHED = 'pp/conversations/UPDATE_MESSAGE_DISPATCHED';
const UPDATE_MESSAGE_FULFILLED = 'pp/conversations/UPDATE_MESSAGE_FULFILLED';
const UPDATE_MESSAGE_REJECTED = 'pp/conversations/UPDATE_MESSAGE_REJECTED';
const CLEAR_PENDING = 'pp/conversations/CLEAR_PENDING';
const INBOX_PAGE_LANDED = 'pp/conversations/INBOX_PAGE_LANDED';
const CONVERSATION_CLICKED = 'pp/conversations/CONVERSATION_CLICKED';
const NEW_CONVERSATION_MESSAGE_BODY_TYPED = 'pp/conversations/NEW_CONVERSATION_MESSAGE_BODY_TYPED';
const REPLY_MESSAGE_BODY_TYPED = 'pp/conversations/REPLY_MESSAGE_BODY_TYPED';
const NEW_MESSAGE_DASHBOARD_CTA_CLICKED = 'pp/conversations/NEW_MESSAGE_DASHBOARD_CTA_CLICKED';
const NEW_MESSAGE_MENU_ITEM_CLICKED = 'pp/conversations/NEW_MESSAGE_MENU_ITEM_CLICKED';
const MESSAGE_ME_CLICKED = 'pp/conversations/MESSAGE_ME_CLICKED';
const MESSAGE_WIDGET_CLICKED = 'pp/conversation/MESSAGE_WIDGET_CLICKED';

const ATTACHMENT_ADD_BUTTON_CLICKED = 'pp/conversation/ATTACHMENT_ADD_BUTTON_CLICKED';
const ATTACHMENT_REMOVE_BUTTON_CLICKED = 'pp/conversation/ATTACHMENT_REMOVE_BUTTON_CLICKED';
const ATTACHMENT_STATUS_ACCEPTED = 'pp/conversation/ATTACHMENT_STATUS_ACCEPTED';
const ATTACHMENT_STATUS_PENDING = 'pp/conversation/ATTACHMENT_STATUS_PENDING';
const ATTACHMENT_STATUS_LOADING = 'pp/conversation/ATTACHMENT_STATUS_LOADING';
const ATTACHMENT_STATUS_REJECTED = 'pp/conversation/ATTACHMENT_STATUS_REJECTED';
const ATTACHMENT_TOO_MANY_ATTACHMENTS_DURING_THRESHOLD = 'pp/conversation/ATTACHMENT_TOO_MANY_ATTACHMENTS_DURING_THRESHOLD';
const ATTACHMENT_UPLOAD_UNSUCCESSFUL = 'pp/conversation/ATTACHMENT_UPLOAD_UNSUCCESSFUL';

export const attachmentAddButtonClickedAction = () => ({
  type: ATTACHMENT_ADD_BUTTON_CLICKED,
});

export const attachmentRemoveButtonClickedAction = () => ({
  type: ATTACHMENT_REMOVE_BUTTON_CLICKED,
});

export const attachmentStatusAcceptedAction = () => ({
  type: ATTACHMENT_STATUS_ACCEPTED,
});

export const attachmentStatusPendingAction = () => ({
  type: ATTACHMENT_STATUS_PENDING,
});

export const attachmentStatusLoadingAction = () => ({
  type: ATTACHMENT_STATUS_LOADING,
});

export const attachmentStatusRejectedAction = () => ({
  type: ATTACHMENT_STATUS_REJECTED,
});

export const createConversationAction = (conversationData) => (
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_CONVERSATION_DISPATCHED,
        conversationData: {
          subject: conversationData.subject,
          pending: true,
          messages: [{ messageBody: conversationData.messageBody }],
        },
      });
      const createConversation = firebase.app().functions('europe-west1').httpsCallable('createConversation');
      await createConversation(conversationData);
      dispatch({ type: CREATE_CONVERSATION_FULFILLED });
    } catch (e) {
      dispatch({ type: CREATE_CONVERSATION_REJECTED });
      throw e;
    }
  }
);

export const newMessageDashboardCtaClickedAction = () => ({
  type: NEW_MESSAGE_DASHBOARD_CTA_CLICKED,
});

export const messageWidgetClickedAction = () => ({
  type: MESSAGE_WIDGET_CLICKED,
});

export const newMessageMenuItemClickedAction = () => ({
  type: NEW_MESSAGE_MENU_ITEM_CLICKED,
});

export const messageMeClickedAction = (source) => ({
  type: source ? `${MESSAGE_ME_CLICKED}_FROM_${source.toUpperCase()}` : MESSAGE_ME_CLICKED,
});

export const newConversationMessageBodyTyped = () => ({
  type: NEW_CONVERSATION_MESSAGE_BODY_TYPED,
});

export const replyMessageBodyTyped = () => ({
  type: REPLY_MESSAGE_BODY_TYPED,
});

export const conversationClicked = () => ({
  type: CONVERSATION_CLICKED,
});

export const inboxPageLanded = () => ({
  type: INBOX_PAGE_LANDED,
});

export const addMessageAction = (messageData) => (
  async (dispatch) => {
    try {
      dispatch({ type: ADD_MESSAGE_DISPATCHED, messageData });
      const addMessage = firebase.app().functions('europe-west1').httpsCallable('addMessageToConversation');
      await addMessage(messageData);
      dispatch({ type: ADD_MESSAGE_FULFILLED });
    } catch (e) {
      dispatch({ type: ADD_MESSAGE_REJECTED });
      throw e;
    }
  }
);

export const updateMessagesAction = (messageData) => (
  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_MESSAGE_DISPATCHED });
      const updateMessage = firebase.app().functions('europe-west1').httpsCallable('updateConversationMessageMetadata');
      await updateMessage(messageData);
      dispatch({ type: UPDATE_MESSAGE_FULFILLED });
    } catch (e) {
      dispatch({ type: UPDATE_MESSAGE_REJECTED });
      throw e;
    }
  }
);

export const clearPendingAction = () => ({ type: CLEAR_PENDING });

export const processAttachmentFile = (uid, createId, file) => {
  return async (dispatch) => {
    try {
      await checkRequestAmount(uid);
    } catch (e) {
      sentryException(e, {
        section: 'conversation-add-attachment-checkRequestAmount',
      });
      dispatch({ type: ATTACHMENT_TOO_MANY_ATTACHMENTS_DURING_THRESHOLD });
      throw e;
    }

    try {
      const requestTime = new Date();
      await addAttachmentRefToUser(uid, createId, requestTime);
    } catch (e) {
      sentryException(e, {
        section: 'conversation-add-attachment-addAttachmentRefToUser',
      });
      dispatch({ type: ATTACHMENT_UPLOAD_UNSUCCESSFUL });
      throw Error('Something went wrong while calling firebase API. Please try again later.');
    }

    try {
      const requestTime = new Date();
      await uploadFileToBucket(uid, createId, file, requestTime);
    } catch (e) {
      console.log('uploadFileToBucket error', e);
      sentryException(e, {
        section: 'conversation-add-attachment-uploadFileToBucket',
      });
      dispatch({ type: ATTACHMENT_UPLOAD_UNSUCCESSFUL });
      throw Error('Something went wrong while uploading file. Please try again later.');
    }
  };
};

const initialState = {
  pendingConversations: [],
  pendingMessages: [],
  openConversationId: null,
};

const conversationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CONVERSATION_DISPATCHED:
      return {
        ...state,
        pendingConversations: [
          ...state.pendingConversations,
          action.conversationData,
        ],
      };
    case ADD_MESSAGE_DISPATCHED:
      return {
        ...state,
        pendingMessages: [
          ...state.pendingMessages,
          action.messageData,
        ],
      };
    case CLEAR_PENDING:
      return {
        ...state,
        pendingConversations: [],
        pendingMessages: [],
      };
    default:
      return state;
  }
};

export default conversationsReducer;
