/* eslint-disable import/no-cycle */

import get from 'lodash/get';
import {
  EXPERIENCES,
} from '../../util/constants';
import {
  getAuthoritativeRetirementPlan, getOpenRetirementPlans, getLatestClosedEncashmentRetirementPlan, getUtmParams, getSignup,
} from './index';
import { getOngoingServiceFromUser } from './ongoingService';

export const getCustomerExperience = (state) => {
  const rp = getAuthoritativeRetirementPlan(state);
  const openRps = getOpenRetirementPlans(state).length;
  const ongoing = getOngoingServiceFromUser(state);
  const isLatestClosedEncashmentRetirementPlan = getLatestClosedEncashmentRetirementPlan(state);

  if (openRps === 0 && ongoing) {
    return EXPERIENCES.ONGOING_MISSING_RP;
  }
  if (openRps > 1 && ongoing) {
    return EXPERIENCES.ONGOING_MULTIPLE_RPS;
  }
  if (isLatestClosedEncashmentRetirementPlan) {
    return EXPERIENCES.FULL_ENCASHMENT;
  }
  if (rp && ongoing) {
    return EXPERIENCES.ONGOING;
  }
  if (rp && !ongoing) {
    return EXPERIENCES.OPTOUT;
  }
  return EXPERIENCES.ONBOARDING;
};

export const getIsCustomerOngoing = (state) => {
  return getCustomerExperience(state) === EXPERIENCES.ONGOING;
};
export const getIsCustomerOngoingMissingRp = (state) => {
  return getCustomerExperience(state) === EXPERIENCES.ONGOING_MISSING_RP;
};
export const getIsCustomerOptout = (state) => {
  return getCustomerExperience(state) === EXPERIENCES.OPTOUT;
};
export const getIsCustomerOnboarding = (state) => {
  return getCustomerExperience(state) === EXPERIENCES.ONBOARDING;
};
export const getIsCustomerOngoingMultipleRps = (state) => {
  return getCustomerExperience(state) === EXPERIENCES.ONGOING_MULTIPLE_RPS;
};
export const getIsCustomerOptoutFullEncashment = (state) => {
  return getCustomerExperience(state) === EXPERIENCES.FULL_ENCASHMENT;
};
export const getUserReferredFromMoneySupermarket = (state) => {
  const signUp = getSignup(state);
  const partner = get(signUp, 'partner');
  return partner === 'moneysupermarket';
};

export const getShouldShowMSMBanner = (state) => {
  return !getIsCustomerOngoing(state) && getUserReferredFromMoneySupermarket(state);
};
