exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-ErrorModal-container-E3ewW {\n  text-align: center;\n}\n.-ErrorModal-title-7uYQB {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + ";\n}\n.-ErrorModal-message-20n57 {\n  text-align: center;\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textParagraphRegular"] + ";\n}\n.-ErrorModal-buttonsContainer-fyWly {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-pack: space-evenly;\n      -ms-flex-pack: space-evenly;\n          justify-content: space-evenly;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n.-ErrorModal-modal-3HjXJ {\n  z-index: 9999;\n}", ""]);

// exports
exports.locals = {
	"colours": "\"../../theme/colors.css\"",
	"textTitleRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + "",
	"textParagraphRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textParagraphRegular"] + "",
	"container": "-ErrorModal-container-E3ewW",
	"title": "-ErrorModal-title-7uYQB",
	"message": "-ErrorModal-message-20n57",
	"buttonsContainer": "-ErrorModal-buttonsContainer-fyWly",
	"modal": "-ErrorModal-modal-3HjXJ"
};