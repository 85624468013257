import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './TabCard.css';

const TabCard = ({
  tabs,
  transferOption,
  defaultTab,
  updateParent,
}) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  const handleClick = (tabIndex) => {
    setActiveTab(tabIndex);
    if (typeof updateParent === 'function') {
      updateParent(tabIndex);
    }
  };

  return (
    <div className={styles.tabCardContainer}>
      <div className={classnames(styles.tabsContainer, {
        [styles.tabContainerTransferOptionFixedHeight]: transferOption,
      })}
      >
        {tabs.map(({ tabHeading, tabHeadingOnClickEvent, key }, index) => {
          const isActive = activeTab === index;
          return (
            <div key={key} className={styles.tabButtonContainer}>
              <button
                type="button"
                onClick={() => {
                  handleClick(index);
                  if (tabHeadingOnClickEvent) {
                    tabHeadingOnClickEvent();
                  }
                }}
                className={classnames(styles.tabButton, {
                  [styles.activeTabButtonGrass]: isActive,
                  [styles.activeTabButtonTarmac]: isActive && index === 1 && transferOption,
                })}
              >
                {tabHeading}
              </button>
              <span
                className={classnames(styles.tabBar, {
                  [styles.activeBarGrass]: isActive,
                  [styles.activeBarTarmac]: isActive && index === 1 && transferOption,
                })}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.tabContentContainer}>
        {tabs[activeTab].content}
      </div>
    </div>
  );
};

TabCard.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabHeading: PropTypes.string,
      tabHeadingOnClickEvent: PropTypes.func,
      content: PropTypes.element,
    }),
  ).isRequired,
  transferOption: PropTypes.bool,
  defaultTab: PropTypes.number,
};

TabCard.defaultProps = {
  transferOption: false,
  defaultTab: 0,
};

export default TabCard;
