import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getValidInvestmentAdviceLetter } from '../../redux/selectors/investmentAdvice';
import WithDocumentLink from './WithDocumentLink';

const WithInvestmentAdviceLetter = ({
  investmentAdviceLetter,
  ...otherProps
}) => {
  return (
    <WithDocumentLink
      documentType="investment-advice-report"
      fileRef={investmentAdviceLetter}
      {...otherProps}
    />
  );
};

const mapStateToProps = (state) => ({
  investmentAdviceLetter: getValidInvestmentAdviceLetter(state),
});

export default compose(
  connect(mapStateToProps),
)(WithInvestmentAdviceLetter);
