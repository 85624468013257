exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!../theme/typography.css"), undefined);

// module
exports.push([module.id, ".-LoginStep1-instructionText-3rDeh {\n}\n", ""]);

// exports
exports.locals = {
	"typography": "\"../theme/typography.css\"",
	"bodyOne": "" + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!../theme/typography.css").locals["bodyOne"] + "",
	"bodyTwo": "" + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!../theme/typography.css").locals["bodyTwo"] + "",
	"instructionText": "-LoginStep1-instructionText-3rDeh " + require("-!../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../node_modules/postcss-loader/src/index.js!../theme/typography.css").locals["bodyTwo"] + ""
};