import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MissingDetails from '../PersonalDetails/MissingDetails';
import {
  getMissingDetailsRequiredFields, getStepWithRequiredFields,
} from '../../redux/selectors';
import ThankYouPage from '../ThankYouPage/ThankYouPage';

const PENSION_HEADING = 'We need some details in order to transfer your pension';

const MisssingDetailsControllerTemplate = ({
  destination,
  requiredFields,
  stepWithRequiredFields,
  personalDetailsHeading,
  onBack,
  flow,
}) => {
  const [destinationPageComplete, setDestinationPageComplete] = useState(false);
  const [personalDetailsRequired, setPersonalDetailsRequired] = useState(stepWithRequiredFields.length);

  const DestinationPage = destination;

  if (!destinationPageComplete && DestinationPage) {
    return (
      <DestinationPage
        onSubmit={() => setDestinationPageComplete(true)}
      />
    );
  }

  if (personalDetailsRequired) {
    return (
      <MissingDetails
        heading={personalDetailsHeading}
        requiredFields={requiredFields}
        stepWithRequiredFields={stepWithRequiredFields}
        onSubmit={() => setPersonalDetailsRequired(false)}
        flow={flow}
      />
    );
  }
  return (
    <ThankYouPage
      onBack={onBack}
    />
  );
};

const mapStateToProps = (state, ownProps) => ({
  requiredFields: getMissingDetailsRequiredFields(state, ownProps.flow),
  stepWithRequiredFields: getStepWithRequiredFields(state, ownProps.flow),
});

const WrappedMissingDetailsController = connect(mapStateToProps)(
  withRouter(MisssingDetailsControllerTemplate),
);

export const AddPensionsController = (props) => (
  // For customers who add a pension and could be missing details
  <WrappedMissingDetailsController
  // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    destination={null}
    personalDetailsHeading={PENSION_HEADING}
    stepWithRequiredFields
    flow={'add-pension'}
  />
);

export const MissingDetailsController = (props) => (
  // For customers who have added a pension and click the missing details CTA on the dashboard
  <WrappedMissingDetailsController
      // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    destination={null}
    personalDetailsHeading={PENSION_HEADING}
  />
);
