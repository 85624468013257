import React from 'react';
import { connect } from 'react-redux';

import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { getFirebaseUid } from '../../redux/selectors';
import {
  marketingPreferenceNotificationClicked,
  MARKETING_PREFERENCES_NOTIFICATION_CLOSED,
} from '../../redux/modules/notificationTracking';

const MarketingPreferencesAlert = ({
  uid,
  dispatchMarketingPreferenceNotificationClicked,
}) => {
  return (
    <NotificationBanner
      alertCategory={'Blue'}
      alertTitle={'Marketing preferences'}
      expandableAlertDescription={'We would like to keep you updated on other offers and services.'}
      ctaButtonOptions={{
        size: 'small',
        label: 'Update Preferences',
        to: '/marketing-preferences',
        event: MARKETING_PREFERENCES_NOTIFICATION_CLOSED,
        handleClick: () => dispatchMarketingPreferenceNotificationClicked(uid),
        dataCy: 'marketing-preferences-link',
      }}
      closeButtonOptions={{
        event: MARKETING_PREFERENCES_NOTIFICATION_CLOSED,
        handleClick: () => dispatchMarketingPreferenceNotificationClicked(uid),
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
});

const mapDispatchToProps = ({
  dispatchMarketingPreferenceNotificationClicked: marketingPreferenceNotificationClicked,
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketingPreferencesAlert);
