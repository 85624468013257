import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SelectIcon from '../../assets/images/rebranding/select-down-arrow.svg';
import SelectIconPurple from '../../assets/images/rebranding/triangle-downward.svg';
import SelectIconLime from '../../assets/images/icons/icon_down_arrow_lime.svg';
import MenuLink from './MenuLink';
import Divider from '../Divider/Divider';
import styles from './NavigationSubMenu.css';

export default class NavigationSubMenu extends React.Component {
  constructor(props) {
    super(props);
    const { label } = this.props;
    this.state = {
      open: {
        [label]: true,
      },
    };
  }

  toggleSubMenu = () => {
    const { label } = this.props;

    this.setState((state) => ({
      ...state,
      open: {
        [label]: state.open[label],
      },
    }));
  };

  renderSubMenu = ({
    className,
    label,
    options,
    icon,
    iconOnly,
    themeDark,
    inboxTheme,
    greyTheme,
    to,
    link,
    target,
    onClick,
  }) => {
    const { open } = this.state;
    const labelClassNames = classnames(
      styles.title,
      {
        [styles.themeDark]: themeDark,
        [styles.inboxTheme]: inboxTheme,
        [styles.greyTheme]: greyTheme,
        [styles.iconOnly]: iconOnly,
        [styles.open]: open,
      },
    );

    return (!options || Object.keys(options).length === 0) ? (
      <MenuLink
        key={label}
        onClick={onClick}
        iconOnly={iconOnly}
        icon={icon}
        label={label}
        link={link}
        target={target}
        to={to}
      />
    ) : (
      <li
        key={label}
        className={className}
        data-cy="menu-item"
        role="menuitem"
        onClick={this.toggleSubMenu}
      >
        <div className={styles.header}>
          <div className={labelClassNames}>
            {label}
          </div>
          {
            (options && Object.keys(options).length > 0) && (
              <Fragment>
                <div className={[styles.suffixDesktop, iconOnly && styles.iconOnly].join(' ')}>
                  <img
                    className={
                      classnames(
                        styles.forestIcon,
                        {
                          [styles.themeDark]: themeDark,
                          [styles.inboxTheme]: inboxTheme,
                        },
                      )
                    }
                    src={SelectIconPurple}
                    alt="Menu dropdown icon"
                  />
                  <img
                    className={
                      classnames(
                        styles.whiteIcon,
                        {
                          [styles.themeDark]: themeDark,
                          [styles.inboxTheme]: inboxTheme,
                        },
                      )
                    }
                    src={greyTheme ? SelectIconLime : SelectIcon}
                    alt="Menu dropdown icon"
                  />
                </div>
              </Fragment>
            )
          }
        </div>
        <ul className={styles.navSub}>
          {Object.keys(options).map((category, index) => {
            return (
              <Fragment>
                {index !== 0 && (
                  <li className={styles.navDivider} key={category}>
                    <Divider />
                  </li>
                )}
                {options[category].map((option) => {
                  return this.renderSubMenu({
                    ...option,
                    onClick: () => {
                      if (onClick) onClick();
                      if (option.onClick) option.onClick();
                    },
                    className: styles.deepSubMenu,
                  });
                })}
              </Fragment>
            );
          })}
        </ul>
      </li>
    );
  };

  render() {
    const {
      options,
      label,
      themeDark,
      icon,
      iconOnly,
      to,
      link,
      target,
      onClick,
      inboxTheme,
      greyTheme,
    } = this.props;
    const { open } = this.state;

    const menuItemClasses = [
      styles.navItem, options && styles.navParentItem,
      open[label] && styles.open,
    ].join(' ');

    return this.renderSubMenu({
      className: menuItemClasses,
      themeDark,
      inboxTheme,
      greyTheme,
      label,
      options,
      icon,
      iconOnly,
      to,
      link,
      target,
      onClick,
    });
  }
}

const navOption = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
  link: PropTypes.string,
};

navOption.options = PropTypes.arrayOf(PropTypes.shape(navOption));

NavigationSubMenu.propTypes = {
  themeDark: PropTypes.bool,
  iconOnly: PropTypes.bool,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
  link: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  options: PropTypes.shape({}),
};

NavigationSubMenu.defaultProps = {
  iconOnly: false,
  icon: null,
  themeDark: false,
  to: null,
  link: null,
  target: null,
  onClick: null,
  options: {},
};
