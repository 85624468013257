import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import BasePage from '../../components/BasePage/BasePage';
import ChangeableEmail from '../../components/ChangeableEmail/ChangeableEmail';
import TwoStepAuthentication from '../../components/TwoStepAuthentication/TwoStepAuthentication';
import PasswordChangeForm from '../../components/PasswordChangeForm/PasswordChangeForm';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import { getHasTwoFactorAuthCTABeenClicked } from '../../redux/selectors';
import { unsetTwoFactorAuthCTAHasBeenClicked } from '../../redux/modules/investmentAdvice';

import styles from './SettingsPage.css';

const SettingsPage = ({
  hasTwoFactorAuthCTABeenClicked,
  dispatchUnsetTwoFactorAuthClicked,
}) => {
  const ctaRef = React.createRef();

  useEffect(() => {
    if (hasTwoFactorAuthCTABeenClicked) {
      ctaRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    dispatchUnsetTwoFactorAuthClicked();
  }, []);

  return (
    <BasePage showAlerts messageWidget>
      <section>
        <div data-cy="header-title">
          <div className={styles.returnButton}>
            <ButtonLink label="← Return to dashboard" to="/" />
          </div>
          <h1 className={styles.h1}>{'Account settings'}</h1>
        </div>
        <div className={styles.container}>
          <div className={styles.card}>
            <h3 className={styles.heading}>{'Registered email address'}</h3>
            <ChangeableEmail />
          </div>
          <div ref={ctaRef} className={styles.card}>
            <h3 className={styles.heading}>{'2-step verification'}</h3>
            <TwoStepAuthentication />
          </div>
          <div className={styles.card}>
            <h3 className={styles.heading}>{'Change password'}</h3>
            <PasswordChangeForm />
          </div>
        </div>
      </section>
    </BasePage>
  );
};

const mapDispatchToProps = {
  dispatchUnsetTwoFactorAuthClicked: unsetTwoFactorAuthCTAHasBeenClicked,
};

const mapStateToProps = (state) => ({
  hasTwoFactorAuthCTABeenClicked: getHasTwoFactorAuthCTABeenClicked(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
