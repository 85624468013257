import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import get from 'lodash/get';
import classnames from 'classnames';
import BasePage from '../../components/BasePage/BasePage';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Button from '../../components/Button/Button';
import FormError from '../../components/FormError/FormError';
import GreenEllipse from '../../assets/images/marketing-preferences/green-ellipse.svg';
import Rectangle from '../../assets/images/marketing-preferences/rectangle.svg';
import TopHalfEllipse from '../../assets/images/marketing-preferences/top-half-ellipse.svg';
import TopLeftRectangle from '../../assets/images/marketing-preferences/top-left-rectangle.svg';
import WhiteEllipse from '../../assets/images/marketing-preferences/white-ellipse.svg';
import { renderToggleSlideCheckBox } from '../../util/formik-custom-fields';
import { getUser } from '../../redux/selectors';
import { updateMarketingPreferences } from '../../redux/modules/investmentAdvice';

import styles from './MarketingPreferences.css';

const MarketingPreferences = ({ user, dispatchUpdateMarketingPreferences }) => {
  const profileBar = () => (
    <div className={styles.profileBar}>
      <div className={styles.profilePhoto}>
        <div className={styles.profileImage}>
          <img src={GreenEllipse} className={styles.ellipsePhoto} alt="" />
          <img src={TopHalfEllipse} className={styles.marginTopHalfEllipse} alt="" />
        </div>
        <div className={styles.profileRectangle}>
          <div>
            <img src={Rectangle} className={styles.block} alt="" />
            <img src={WhiteEllipse} className={styles.dot1} alt="" />
            <img src={WhiteEllipse} className={styles.dot2} alt="" />
            <img src={WhiteEllipse} className={styles.dot3} alt="" />
          </div>
          <div>
            <img src={TopLeftRectangle} className={styles.topLeftRectangle} alt="" />
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [marketingPreferences, setMarketingPreferences] = useState({
    phone: get(user, 'marketingPreferences.telephone') || false,
    email: get(user, 'marketingPreferences.email') || false,
    sms: get(user, 'marketingPreferences.sms') || false,
    post: get(user, 'marketingPreferences.post') || false,
    socialMedia: get(user, 'marketingPreferences.socialMedia') || false,
  });

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  const handleChange = (fieldName, checked) => {
    setIsSaveButtonDisabled(false);
    setMarketingPreferences({ ...marketingPreferences, [fieldName]: checked });
  };

  const saveMarketingPreferences = async (values, { setSubmitting, setStatus }) => {
    setStatus({ formError: null });
    setStatus({ updateSuccess: null });
    const data = {
      marketingPreferences: {
        email: values.email,
        post: values.post,
        sms: values.sms,
        telephone: values.phone,
        socialMedia: values.socialMedia,
      },
    };
    try {
      await dispatchUpdateMarketingPreferences(data);
      setStatus({ updateSuccess: true });
    } catch (err) {
      setStatus({ formError: 'Error updating your details, please try again after sometime.' });
    }
    setSubmitting(false);
    setIsSaveButtonDisabled(true);
  };

  const changePreferencesSection = () => {
    const items = [
      { label: 'Phone', name: 'phone' },
      { label: 'Email', name: 'email' },
      { label: 'SMS', name: 'sms' },
      { label: 'Post', name: 'post' },
      { label: 'Social Media and Networks ', name: 'socialMedia' },
    ];

    return (
      <Formik
        initialValues={{
          phone: marketingPreferences.phone,
          email: marketingPreferences.email,
          sms: marketingPreferences.sms,
          post: marketingPreferences.post,
          socialMedia: marketingPreferences.socialMedia,
        }}
        onSubmit={saveMarketingPreferences}
        render={({
          isSubmitting,
          setFieldValue,
          status,
        }) => (
          <Form>
            <div>
              <div className={styles.changePreferencesSection}>
                <div>
                  <h3 className={styles.marketingPreferenceHeader}>
                    {'Choose how you’d like to hear from us'}
                  </h3>
                </div>
                <div>
                  <div className={styles.mpItems}>
                    {items.map((item) => (
                      <div key={item.name} className={styles.mpItem}>
                        <div>{item.label}</div>
                        <Field
                          name={item.name}
                          type="checkbox"
                          component={renderToggleSlideCheckBox}
                          isChecked={marketingPreferences[item.name]}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.linebreak} />
            <div className={styles.saveButton}>
              <Button disabled={isSaveButtonDisabled} fullWidth type="submit" label="Save" loading={isSubmitting} />
              {status && status.updateSuccess && (
                <div className={styles.updateSuccess}>
                  <span>{'Your details have been updated successfully.'}</span>
                </div>
              )}
            </div>
            <FormError error={status && status.formError} />
          </Form>
        )}
      />
    );
  };

  return (
    <BasePage messageWidget>
      <div className={styles.returnButton}>
        <ButtonLink label="← Return to dashboard" to="/" />
      </div>
      <div className={styles.container}>
        {profileBar()}
        <h3 className={classnames(styles.keepInTouchHeader, styles.marketingPreferenceHeader)}>{'Let’s keep in touch'}</h3>
        <p className={styles.descriptionFontFamily}>
          {`We have lots of exciting and important things to share with you, 
          like improvements to our services and new products. But to be told 
          about these things, you need to opt in so we can keep you up to date
           and make sure you don’t miss any important announcements.`}
        </p>
        <p className={styles.descriptionFontFamily}>
          {`We’ll take the same level of care with your personal information as
           we take with your pension - you can be confident we’ll always work in
            your best interests.`}
        </p>
        <div className={styles.linebreak} />
        {changePreferencesSection()}

      </div>
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateMarketingPreferences: (data) => dispatch(updateMarketingPreferences(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketingPreferences);
