import React from 'react';
import PropTypes from 'prop-types';

import Switch from 'react-switch';
import styles from './ToggleCard.css';

function ToggleCard({
  open, setOpen, icon, title, children,
}) {
  return (
    <div className={styles.card}>
      <div className={styles.cardHeaderWrapper}>
        <div className={styles.cardHeader}>
          <img src={icon} alt={title} />
          <h4 className={styles.cardTitle}>{title}</h4>
        </div>
        <Switch
          checked={open}
          onChange={() => setOpen((o) => !o)}
          onColor="#e3f6d8"
          offColor="#f0f0f0"
          offHandleColor="#c3c3c3"
          onHandleColor="#169B62"
          handleDiameter={20}
          uncheckedIcon={false}
          checkedIcon={false}
          height={14}
          width={42}
          className="react-switch"
          id="material-switch"
        />
      </div>
      {open && (
      <div className={styles.cardContent}>
        {children}
      </div>
      )}

    </div>
  );
}

ToggleCard.propTypes = {};

export default ToggleCard;
