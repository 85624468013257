import React from 'react';
import * as yup from 'yup';
import { connect } from 'react-redux';
import moment from 'moment';

import { Formik, Field } from 'formik';
import { renderTextField } from '../../util/formik-custom-fields';

import * as actions from '../../redux/modules/eventDispatch';
import * as accountActions from '../../redux/modules/account';

import getRecaptchaToken from '../../util/getRecaptchaToken';
import { sendGaPageEvent } from '../../util/googleAnalyticHelpers';

import Button from '../../components/Button/Button';
import FormError from '../../components/FormError/FormError';
import ButtonLink from '../../components/ButtonLink/ButtonLink';

import styles from './Verify.css';

class VerifyEmail extends React.Component {
  constructor() {
    super();
    this.state = {
      sendEmailOtpIsSubmitting: false,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    sendGaPageEvent('/verify-email');
  }

  sendEmailOtpHandler = async () => {
    const { dispatchSendEmailOtp, uid } = this.props;

    this.setState({ sendEmailOtpIsSubmitting: true });
    const recaptchaToken = await getRecaptchaToken('sendEmailOtpToken');
    await dispatchSendEmailOtp(uid, recaptchaToken);

    this.setState({ sendEmailOtpIsSubmitting: false });
  };

  verifyOtpHandler = async (values, { setSubmitting, setStatus }) => {
    const { otpCode } = values;
    const { dispatchVerifyOtp, uid } = this.props;

    setStatus({ formError: null });
    setSubmitting(true);

    try {
      const recaptchaToken = await getRecaptchaToken('verifyOtpToken');
      const verifyOtpResponse = await dispatchVerifyOtp(uid, otpCode, recaptchaToken);

      if (verifyOtpResponse.expired) {
        setStatus({ formError: 'The code you have entered has expired, please press ‘Resend code’ and try again.' });
      } else if (!verifyOtpResponse.otpCorrect) {
        setStatus({ formError: 'You’ve entered the code incorrectly, try again or press ‘Resend code’ to receive a new code.' });
      }
    } catch (error) {
      setStatus({ formError: 'Could not verify the PIN code, try again or press ‘Resend code’ to receive a new code.' });
    }

    setSubmitting(false);
  }

  showSendOtp() {
    const { user: { contact } } = this.props;
    const { sendEmailOtpIsSubmitting } = this.state;

    return (
      <React.Fragment>
        <p className={styles.body}>
          {' '}
          {`We need to verify your email address (${contact.email}).`}
          {' '}
        </p>
        <p className={styles.body}>
          {' '}
          {'Click the button below to send a PIN code to your email.'}
          {' '}
        </p>
        <Button
          onClick={this.sendEmailOtpHandler}
          label={'Send'}
          loading={sendEmailOtpIsSubmitting}
        />
      </React.Fragment>
    );
  }

  showVerifyOtp() {
    return (
      <Formik
        onSubmit={this.verifyOtpHandler}
        validationSchema={
          yup.object().shape({ otpCode: yup.string().required('Required') })
        }
        render={({
          handleSubmit,
          status,
          isSubmitting: verifyOtpIsSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <p className={styles.body}>
              {'We’ve just sent you an email with a 4-digit PIN code. Please enter the code in the box below.'}
            </p>
            <p className={styles.body}>{'If you can’t find the email please check your spam folder to see if it’s there.'}</p>
            <Field
              name="otpCode"
              type="text"
              component={renderTextField}
              label="Verification code"
              defaultValue="e.g 1234"
              validate={this.validateOtpCode}
            />
            <br />
            <FormError error={status && status.formError} />
            <ButtonLink onClick={this.sendEmailOtpHandler} label="Resend code" font="bodyTwo" />
            <br />
            <br />
            <Button type="submit" label="Confirm" loading={verifyOtpIsSubmitting} />
          </form>
        )}
      />
    );
  }

  render() {
    const { verificationRequested } = this.props;
    const emailVerificationRequested = verificationRequested ? moment().diff(moment.unix(verificationRequested.seconds), 'hours') < 1 : false;

    return (
      <section>
        <div className={styles.container}>
          <h3 className={styles.heading}>{'Verify your email'}</h3>
          {emailVerificationRequested ? this.showVerifyOtp() : this.showSendOtp()}
        </div>
        <div className={styles.recaptchaTxt}>
          {'This site is protected by reCAPTCHA and the Google '}
          <a href="https://policies.google.com/privacy">{'Privacy Policy'}</a>
          {' and '}
          <a href="https://policies.google.com/terms">{'Terms of Service'}</a>
          {' apply.'}
        </div>
      </section>
    );
  }
}

export default connect(null, { ...actions, ...accountActions })(VerifyEmail);
