import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import sentryException from '../../util/sentryException';

export const transferOptionsList = {
  'transfer-basic': 'TRANSFER_ONLY',
  'transfer-with-check': 'FIND_CHECK_TRANSFER',
};

export const returnLinkClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}RETURN_LINK_CLICKED` }
);

export const messageMeClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}MESSAGE_ME_CLICKED` }
);

export const employerSectorSkipStepClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_SECTOR_SKIP_STEP_CLICKED` }
);

export const tryFindAndCombineServiceClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}TRY_FIND_AND_COMBINE_SERVICE_CLICKED` }
);

export const pensionValueSkipStepClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}PENSION_VALUE_SKIP_STEP_CLICKED` }
);

export const policyNumberSkipStepClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}POLICY_NUMBER_SKIP_STEP_CLICKED` }
);

export const introStepLandedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}INTRO_STEP_LANDED` }
);

export const introStepPensionClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}ADD_PENSION_CLICKED` }
);

export const cannotTransferPensionLandedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}CANNOT_TRANSFER_PENSION_STEP_LANDED` }
);

export const currentlyContributingStepLandedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}CURRENTLY_CONTRIBUTING_STEP_LANDED` }
);

export const hasBegunWithdrawingStepLandedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}HAS_BEGUN_WITHDRAWING_STEP_LANDED` }
);

export const alreadyWithdrawingYesSelectedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}ALREADY_WITHDRAWING_YES_SELECTED` }
);

export const alreadyWithdrawingNoSelectedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}ALREADY_WITHDRAWING_NO_SELECTED` }
);

export const currentlyContributingYesSelectedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}CURRENTLY_CONTRIBUTING_YES_SELECTED` }
);

export const currentlyContributingNoSelectedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}CURRENTLY_CONTRIBUTING_NO_SELECTED` }
);

export const currentlyContributingSubmitClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}CURRENTLY_CONTRIBUTING_SUBMIT_CLICKED` }
);

export const employerDatesStepLandedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_DATES_STEP_LANDED` }
);

export const employerDatesFromChangedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_DATES_FROM_CHANGED` }
);

export const employerDatesToChangedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_DATES_TO_CHANGED` }
);

export const employerDatesSubmitClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_DATES_SUBMIT_CLICKED` }
);

export const employerLocationLandedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_LOCATION_LANDED` }
);

export const employerLocationChangedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_LOCATION_CHANGED` }
);

export const employerLocationSubmitClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_LOCATION_SUBMIT_CLICKED` }
);

export const employerNameStepLandedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_NAME_STEP_LANDED` }
);

export const employerNameChangedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_NAME_CHANGED` }
);

export const employerNameSubmitClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_NAME_SUBMIT_CLICKED` }
);

export const employerPensionStepLandedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_PENSION_STEP_LANDED` }
);

export const employerPensionSubmitClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_PENSION_SUBMIT_CLICKED` }
);

export const employerPensionYesSelectedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_PENSION_YES_SELECTED` }
);

export const employerPensionNoSelectedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_PENSION_NO_SELECTED` }
);

export const employerSectorStepLandedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_SECTOR_STEP_LANDED` }
);

export const employerSectorChangedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_SECTOR_CHANGED` }
);

export const employerSectorSubmitClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}EMPLOYER_SECTOR_SUBMIT_CLICKED` }
);

export const otherProviderStepLandedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}OTHER_PROVIDER_STEP_LANDED` }
);

export const otherProviderInputChangedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}OTHER_PROVIDER_INPUT_CHANGED` }
);

export const otherProviderSubmitClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}OTHER_PROVIDER_SUBMIT_CLICKED` }
);

export const pensionProviderStepLandedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}PENSION_PROVIDER_STEP_LANDED` }
);
export const pensionTransferOptionStepLandedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}PENSION_TRANSFER_OPTION_STEP_LANDED` }
);

export const pensionTransferOptionSelectedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}PENSION_OPTION_SELECTED` }
);

export const pensionTransferOptionTabSelectedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}PENSION_OPTION_TAB_SELECTED` }
);

export const onGuaranteesAndExitPenaltiesModalShow = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}PENSION_GUARANTEES_AND_EXIT_PENALTIES_MODAL_SHOW` }
);

export const pensionProviderSubmitClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}PENSION_PROVIDER_SUBMIT_CLICKED` }
);

export const unknownProviderLinkClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}UNKNOWN_PROVIDER_LINK_CLICKED` }
);

export const unknownEmployerLinkClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}UNKNOWN_EMPLOYER_LINK_CLICKED` }
);

export const pensionValueSubmitClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}PENSION_VALUE_SUBMIT_CLICKED` }
);

export const pensionValueStepLandedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}PENSION_VALUE_STEP_LANDED` }
);

export const pensionValueChangedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}PENSION_VALUE_CHANGED` }
);
export const policyNumberStepLandedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}POLICY_NUMBER_STEP_LANDED` }
);
export const policyNumberChangedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}POLICY_NUMBER_CHANGED` }
);
export const policyNumberSubmitClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}POLICY_NUMBER_SUBMIT_CLICKED` }
);
export const submitStepLandedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}SUBMIT_STEP_LANDED` }
);
export const recommendationReportLinkClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}RECOMMENDATION_REPORT_LINK_CLICKED` }
);
export const keyDetailsClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}KEY_DETAILS_CLICKED` }
);
export const illustrationClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}ILLUSTRATION_CLICKED` }
);
export const termsOfBusinessClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}TERMS_OF_BUSINESS_CLICKED` }
);
export const authoriseImplementCheckboxClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}AUTHORISE_IMPLEMENT_CHECKBOX_CLICKED` }
);
export const authoriseTransferBasicCheckboxClickedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}AUTHORISE_TRANSFER_BASIC_CHECKBOX_CLICKED` }
);
export const inScopeProviderSelectedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}IN_SCOPE_PROVIDER_SELECTED` }
);
export const outOfScopeProviderSelectedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}OUT_OF_SCOPE_PROVIDER_SELECTED` }
);
export const otherProviderSelectedAction = (ongoing, transferOption) => (
  { type: `pp/pensions/${ongoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}OTHER_PROVIDER_SELECTED` }
);

export const ADD_PENSIONS_DISPATCHED = 'pp/pensions/ADD_PENSION_DISPATCHED';
export const ADD_PENSIONS_FULFILLED = 'pp/pensions/ADD_PENSION_FULFILLED';
export const ADD_PENSIONS_REJECTED = 'pp/pensions/ADD_PENSION_REJECTED';
export const ONGOING_ADD_PENSIONS_DISPATCHED = 'pp/pensions/ONGOING_ADD_PENSION_DISPATCHED';
export const ONGOING_ADD_PENSIONS_FULFILLED = 'pp/pensions/ONGOING_ADD_PENSION_FULFILLED';
export const ONGOING_ADD_PENSIONS_REJECTED = 'pp/pensions/ONGOING_ADD_PENSION_REJECTED';

export const UPDATE_PENSIONS_DISPATCHED = 'pp/pensions/UPDATE_PENSION_DISPATCHED';
export const UPDATE_PENSIONS_FULFILLED = 'pp/pensions/UPDATE_PENSION_FULFILLED';
export const UPDATE_PENSIONS_REJECTED = 'pp/pensions/UPDATE_PENSION_REJECTED';
export const ONGOING_UPDATE_PENSIONS_DISPATCHED = 'pp/pensions/ONGOING_UPDATE_PENSION_DISPATCHED';
export const ONGOING_UPDATE_PENSIONS_FULFILLED = 'pp/pensions/ONGOING_UPDATE_PENSION_FULFILLED';
export const ONGOING_UPDATE_PENSIONS_REJECTED = 'pp/pensions/ONGOING_UPDATE_PENSION_REJECTED';
export const SERPS_PENSION_AUTHORITY_PROVIDED = 'pp/dashboard/SERPS_PENSION_AUTHORITY_PROVIDED';

export const addPensionAction = ({
  authoriseImplementPensionPlan,
  pensions,
  screenshot,
  pageHtml,
  topup,
  isCustomerOngoing,
  transferOption,
}) => (async (dispatch) => {
  const addPensions = firebase.app().functions('europe-west1').httpsCallable('addPensions');
  const pensionsDispatched = topup ? ONGOING_ADD_PENSIONS_DISPATCHED : ADD_PENSIONS_DISPATCHED;
  const pensionsFulfilled = topup ? ONGOING_ADD_PENSIONS_FULFILLED : ADD_PENSIONS_FULFILLED;
  const pensionsRejected = topup ? ONGOING_ADD_PENSIONS_REJECTED : ADD_PENSIONS_REJECTED;

  const addPensionWithTransferOptionFulfilled = `pp/pensions/${isCustomerOngoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}ADD_PENSION_FULFILLED`;

  dispatch({ type: pensionsDispatched });

  try {
    await addPensions({
      authoriseImplementPensionPlan,
      pensions,
      screenshot,
      pageHtml,
      topup,
    });
    dispatch({ type: pensionsFulfilled });
    dispatch({ type: addPensionWithTransferOptionFulfilled });
  } catch (e) {
    dispatch({ type: pensionsRejected });
    sentryException(e, {
      section: 'add-pensions',
    });
    throw e;
  }
});

export const updatePensionsAction = ({
  authoriseImplementPensionPlan,
  pensions,
  screenshot,
  pageHtml,
  topup,
  transferOption,
  isCustomerOngoing,
}) => (async (dispatch) => {
  const updatePensions = firebase.app().functions('europe-west1').httpsCallable('updatePensions');
  const pensionsDispatched = topup ? ONGOING_UPDATE_PENSIONS_DISPATCHED : UPDATE_PENSIONS_DISPATCHED;
  const pensionsFulfilled = topup ? ONGOING_UPDATE_PENSIONS_FULFILLED : UPDATE_PENSIONS_FULFILLED;
  const pensionsRejected = topup ? ONGOING_UPDATE_PENSIONS_REJECTED : UPDATE_PENSIONS_REJECTED;

  const updatePensionWithTransferOptionFulfilled = `pp/pensions/${isCustomerOngoing ? 'ONGOING_' : ''}${transferOption ? `${transferOptionsList[transferOption]}_` : ''}UPDATE_PENSION_FULFILLED`;

  dispatch({ type: pensionsDispatched });

  try {
    await updatePensions({
      authoriseImplementPensionPlan,
      pensions,
      screenshot,
      pageHtml,
      topup,
    });
    dispatch({ type: pensionsFulfilled });
    dispatch({ type: updatePensionWithTransferOptionFulfilled });
    dispatch({ type: SERPS_PENSION_AUTHORITY_PROVIDED });
  } catch (e) {
    dispatch({ type: pensionsRejected });
    sentryException(e, {
      section: 'update-pensions',
    });
    throw e;
  }
});