import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import styles from './SetupWithdrawalPage.css';
import Bullet from '../../assets/images/icons/circles/primary.svg';
import ButtonLink from '../../components/ButtonLink/ButtonLink';

import {
  withdrawalImportantInformationVisitedAction,
  withdrawalOtherImportantInformationClickedAction,
} from '../../redux/modules/withdrawal';

const WithdrawalsImportantInformationStep = ({
  dispatchImportantInformationLinkClicked,
  dispatchWithdrawalImportantInformationVisited,
}) => {
  useEffect(() => {
    dispatchWithdrawalImportantInformationVisited();
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <h3 className={styles.heading}>{'Taking money from your pension plan is an important decision. Please read all the information here carefully.'}</h3>
      <div className={styles.importantInformationContainer}>

        <div className={styles.importantInformation}>
          <img className={styles.importantInformationBullet} src={Bullet} alt="Bullet" />
          <div className={styles.importantInformationText}>
            {'We give you advice on how to invest your pension. We do not give you advice on how much you should withdraw, however, your Pension Adviser can help you understand your options and we can arrange withdrawals for you.  There is also a free impartial pension guidance service provided by the '}
            <a href="https://www.pensionwise.gov.uk" rel="noopener noreferrer" target="_blank">{'government'}</a>
            {'.'}
          </div>
        </div>

        <div className={styles.importantInformation}>
          <img className={styles.importantInformationBullet} src={Bullet} alt="Bullet" />
          <div className={styles.importantInformationText}>{'Taking money now will reduce the value of your pension pot which means you will have less money to generate an income in the future which may have an impact on your standard of living.'}</div>
        </div>

        <div className={styles.importantInformation}>
          <img className={styles.importantInformationBullet} src={Bullet} alt="Bullet" />
          <div className={styles.importantInformationText}>{'You can withdraw up to 25% from your fund as Tax Free Cash. This can be as a one off lump sum, a series of withdrawals or as a regular amount. Any amount you take above this 25% will be taxed at your marginal rate.'}</div>
        </div>

        <div className={styles.importantInformation}>
          <img className={styles.importantInformationBullet} src={Bullet} alt="Bullet" />
          <div className={styles.importantInformationText}>{'There is no charge for withdrawing Tax Free Cash from your pension plan, however once your withdrawals exceed this amount, there is a charge of £75 per annum from your provider, Aegon.'}</div>
        </div>

        <div className={styles.otherImportantInformationText}>
          <ButtonLink
            link={'/aegon-withdrawals'}
            onClick={dispatchImportantInformationLinkClicked}
            label={'Other important information'}
            target="_blank"
            font="inherit"
            underline
          />
        </div>
      </div>

    </React.Fragment>

  );
};

const mapDispatchToProps = {
  dispatchImportantInformationLinkClicked: withdrawalOtherImportantInformationClickedAction,
  dispatchWithdrawalImportantInformationVisited: withdrawalImportantInformationVisitedAction,
};

export default connect(null, mapDispatchToProps)(WithdrawalsImportantInformationStep);
