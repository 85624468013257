import trim from 'lodash/trim';
import get from 'lodash/get';
import React, { useState } from 'react';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { Field, Formik } from 'formik';

import * as accountActions from '../../redux/modules/account';
import Button from '../Button/Button';
import FormError from '../FormError/FormError';
import ButtonLink from '../ButtonLink/ButtonLink';
import ServiceAlertCard from '../AlertCard/ServiceAlertCard';
import getRecaptchaToken from '../../util/getRecaptchaToken';
import { renderTextField } from '../../util/formik-custom-fields';
import { generateNewIdToken } from '../../util/auth';
import { getAuthToken, getFirebaseUid, getUserProfile } from '../../redux/selectors';

import styles from './ChangeableEmail.css';
import { emailRegex } from '../../util/validation';

const schema = yup.object().shape({
  newEmail: yup.string().required('Required').matches(emailRegex, 'Please provide a valid email address'),
});

const STEP_INITIAL = 'STEP_INITIAL';
const STEP_ENTER_EMAIL = 'STEP_ENTER_EMAIL';
const STEP_VALIDATE_OTP = 'STEP_VALIDATE_OTP';

const ChangeableEmail = (props) => {
  const isUserLoaded = get(props, 'user.isLoaded');
  const userEmail = get(props, 'user.contact.email');
  const {
    uid,
    idToken,
    dispatchSendChangeEmailOtp,
    dispatchVerifyChangeEmailOtp,
    dispatchInitialChangeEmailButtonClicked,
    dispatchSendChangeEmailOtpCancelled,
    dispatchVerifyChangeEmailOtpCancelled,
    dispatchVerifyChangeEmailOtpResendCode,
  } = props;

  const [currentStep, setCurrentStep] = useState(STEP_INITIAL);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [newEmailAddress, setNewEmailAddress] = useState(null);

  const sendChangeEmailOtpHandler = async (values, { setSubmitting, setStatus }) => {
    const { newEmail } = values;
    setNewEmailAddress(newEmail);

    setStatus({ formError: null });
    setSubmitting(true);

    try {
      const recaptchaToken = await getRecaptchaToken('sendChangeEmailOtpHandler');
      const changeEmailResponse = await dispatchSendChangeEmailOtp(uid, trim(newEmail), recaptchaToken);
      if (get(changeEmailResponse, 'emailInUse')) {
        setStatus({ formError: 'That email is taken. Try another' });
      } else {
        setCurrentStep(STEP_VALIDATE_OTP);
      }
    } catch (error) {
      setStatus({ formError: 'Failed to send verification email.' });
    }

    setSubmitting(false);
  };

  const verifyChangeEmailOtpHandler = async (values, { setSubmitting, setStatus }) => {
    const { otpCode } = values;

    setStatus({ formError: null });
    setSubmitting(true);

    try {
      const recaptchaToken = await getRecaptchaToken('verifyChangeEmailOtpToken');
      const verifyOtpResponse = await dispatchVerifyChangeEmailOtp(uid, otpCode, recaptchaToken);
      const recaptchaTokenNewId = await getRecaptchaToken('verifyChangeEmailNewIdToken');
      await generateNewIdToken(idToken, recaptchaTokenNewId);

      if (get(verifyOtpResponse, 'expired')) {
        setStatus({ formError: 'The code you have entered has expired, please press ‘Resend code’ and try again.' });
      } else if (!get(verifyOtpResponse, 'otpCorrect')) {
        setStatus({ formError: 'You’ve entered the code incorrectly, try again or press ‘Resend code’ to receive a new code.' });
      } else if (get(verifyOtpResponse, 'otpCorrect')) {
        setCurrentStep(STEP_INITIAL);
        setUpdateSuccess(true);
      } else {
        setStatus({ formError: 'Something went wrong' });
      }
    } catch (error) {
      setStatus({ formError: 'Could not verify the PIN code, try again or press ‘Resend code’ to receive a new code.' });
    }

    setSubmitting(false);
  };

  const startChangePwdFlow = () => {
    setCurrentStep(STEP_ENTER_EMAIL);
    setUpdateSuccess(false);
    dispatchInitialChangeEmailButtonClicked();
  };

  const renderStep = (newStep) => {
    const validStepKeys = [STEP_INITIAL, STEP_ENTER_EMAIL, STEP_VALIDATE_OTP];
    const validatedStep = validStepKeys.indexOf(newStep) > -1 ? newStep : STEP_INITIAL;

    const steps = {
      [STEP_INITIAL]: (
        <div className={styles.changeEmailButtons}>
          <Button
            label="Change email"
            onClick={startChangePwdFlow}
            disabled={!isUserLoaded}
            size="medium"
          />
        </div>
      ),
      [STEP_ENTER_EMAIL]: (
        <Formik
          onSubmit={sendChangeEmailOtpHandler}
          validationSchema={schema}
          render={({
            handleSubmit,
            values,
            isSubmitting,
            status,
            errors,
          }) => {
            const { newEmail: newEmailValue } = values;
            const { newEmail: newEmailErrors } = errors;

            return (
              <form onSubmit={handleSubmit}>
                <Field
                  name="newEmail"
                  type="email"
                  component={renderTextField}
                  label="New email"
                  defaultValue="Enter new email address"
                  required
                />
                <br />
                <FormError error={status && status.formError} />
                <div className={styles.updateEmailButtons}>
                  <Button
                    type="submit"
                    label="Send code"
                    loading={isSubmitting}
                    disabled={Boolean(!newEmailValue || newEmailErrors)}
                  />
                  <Button
                    label="Cancel"
                    wrapperStyles={{ display: 'block' }}
                    onClick={() => {
                      setCurrentStep(STEP_INITIAL);
                      dispatchSendChangeEmailOtpCancelled();
                    }}
                  />
                </div>
              </form>
            );
          }}
        />
      ),
      [STEP_VALIDATE_OTP]: (
        <Formik
          onSubmit={verifyChangeEmailOtpHandler}
          validationSchema={
            yup.object().shape({ otpCode: yup.string().required('Required') })
          }
          render={({ handleSubmit, isSubmitting: verifyOtpIsSubmitting, status }) => {
            return (
              <form onSubmit={handleSubmit}>
                <p className={styles.body}>
                  {'We’ve just sent you an email with a 4-digit PIN code to '}
                  <b>{newEmailAddress}</b>
                  {'. Please enter the code in the box below.'}
                </p>
                <p className={styles.body}>{'If you can’t find the email please check your spam folder to see if it’s there.'}</p>
                <Field
                  name="otpCode"
                  type="text"
                  component={renderTextField}
                  label={'Verification code'}
                  defaultValue="e.g. 1234"
                />
                <br />
                <FormError error={status && status.formError} />
                <ButtonLink
                  onClick={() => {
                    setCurrentStep(STEP_ENTER_EMAIL);
                    dispatchVerifyChangeEmailOtpResendCode();
                  }}
                  label="Resend code"
                  font="bodyTwo"
                />
                <br />
                <div className={styles.updateEmailButtons}>
                  <Button type="submit" label="Confirm" loading={verifyOtpIsSubmitting} marginRight />
                  <Button
                    label="Cancel"
                    wrapperStyles={{ display: 'block' }}
                    onClick={() => {
                      setCurrentStep(STEP_INITIAL);
                      dispatchVerifyChangeEmailOtpCancelled();
                    }}
                  />
                </div>
              </form>
            );
          }}
        />
      ),
    };

    return steps[validatedStep];
  };

  return (
    <div>
      <div className={styles.value}>{isUserLoaded ? userEmail : 'fetching'}</div>
      {updateSuccess && (
        <ServiceAlertCard
          alertDescription={'Your email has been changed successfully!'}
          alertSeverity="success"
          icon="success"
        />
      )}
      {renderStep(currentStep)}
    </div>
  );
};

const mapStateToProps = (state) => ({
  idToken: getAuthToken(state),
  uid: getFirebaseUid(state),
  user: getUserProfile(state),
});

export default connect(mapStateToProps, { ...accountActions })(ChangeableEmail);
