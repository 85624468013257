/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './SetupLumpSumPage.css';
import Button from '../../components/Button/Button';
import WithMentionMe from '../../components/WithMentionMe/WithMentionMe';

import {
  lumpSumCompleteStepVisitedAction,
  ongoingLumpSumCompleteStepVisitedAction,
  returnLinkClickedAction,
  ongoingReturnLinkClickedAction,
  referFriendClickedAction,
  ongoingReferFriendClickedAction,
} from '../../redux/modules/lumpSum';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import { setForceMentionMeModalAction } from '../../redux/modules/investmentAdvice';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import MentionMeTile from '../../components/MentionMeTile/MentionMeTile';

import TransferThankYouBg from '../../assets/images/transfer-thank-you-bg.svg';
import { SHOW_MENTION_ME } from '../../util/constants';

const LumpSumCompleteStep = ({
  dispatchLumpSumCompleteStepVisited,
  dispatchReturnLinkClicked,
  dispatchReferFriendClicked,
  dispatchSetForceMentionMeModal,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchLumpSumCompleteStepVisited();
  }, []);

  return (
    <BaseGrid pageSectionStyles={{ marginBottom: '200px' }} addPensionsTheme>
      <WithMentionMe>
        <div className={styles.contributionsContainer}>
          <div className={styles.imageContainer}>
            <img src={TransferThankYouBg} alt="Green tick" />
          </div>
          <h2 className={styles.contributionsHeading}>{'Thank you, your one-off contribution request has been received'}</h2>
          <h5 className={styles.contributionsSubHeading}>{'What happens next?'}</h5>
          <div className={styles.standardContainer}>
            <div className={styles.whatHappensNextRow}>
              <div className={styles.whatHappensNextNumber}>{'1'}</div>
              <p className={styles.whatHappensNextText}>{'Your payment will be set up with Aegon. We’ll send you the details by secure message to complete your bank transfer'}</p>
            </div>
            <div className={styles.whatHappensNextRow}>
              <div className={styles.whatHappensNextNumber}>{'2'}</div>
              <p className={styles.whatHappensNextText}>{'You complete the bank transfer using the reference and details provided using online banking or a branch'}</p>
            </div>
          </div>
          <Button
            size="large"
            onClick={() => {
              dispatchSetForceMentionMeModal();
              dispatchReturnLinkClicked();
            }}
            to="/"
            label="Return to dashboard"
          />
        </div>
        {SHOW_MENTION_ME && (
          <div className={styles.mentionMeContainer}>
            <div className={styles.mentionMeCard}>
              <MentionMeTile />
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div
                id="mmWrapper"
                className="setup-contribution-mm"
                onClick={() => dispatchReferFriendClicked()}
              />
            </div>
          </div>
        )}
      </WithMentionMe>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  _dispatchOnboardingLumpSumCompleteStepVisited: lumpSumCompleteStepVisitedAction,
  _dispatchOngoingLumpSumCompleteStepVisited: ongoingLumpSumCompleteStepVisitedAction,
  _dispatchOnboardingReturnLinkClicked: returnLinkClickedAction,
  _dispatchOngoingReturnLinkClicked: ongoingReturnLinkClickedAction,
  _dispatchOnboardingReferFriendClicked: referFriendClickedAction,
  _dispatchOngoingReferFriendClicked: ongoingReferFriendClickedAction,
  dispatchSetForceMentionMeModal: setForceMentionMeModalAction,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isCustomerOngoing } = stateProps;
  const {
    _dispatchOnboardingLumpSumCompleteStepVisited,
    _dispatchOngoingLumpSumCompleteStepVisited,
    _dispatchOnboardingReturnLinkClicked,
    _dispatchOngoingReturnLinkClicked,
    _dispatchOnboardingReferFriendClicked,
    _dispatchOngoingReferFriendClicked,
  } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    dispatchLumpSumCompleteStepVisited: isCustomerOngoing ? _dispatchOngoingLumpSumCompleteStepVisited : _dispatchOnboardingLumpSumCompleteStepVisited,
    dispatchReturnLinkClicked: isCustomerOngoing ? _dispatchOngoingReturnLinkClicked : _dispatchOnboardingReturnLinkClicked,
    dispatchReferFriendClicked: isCustomerOngoing ? _dispatchOngoingReferFriendClicked : _dispatchOnboardingReferFriendClicked,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LumpSumCompleteStep);
