import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

import OngoingBalance from '../OngoingBalance';
import CurrentPlanCard from '../../../components/PensionPlanCard/CurrentPlanCard';
import ContactUs from './ContactUs';

import {
  getUser,
  getFirstName,
  getEmail,
  getAuthoritativeRetirementPlan,
} from '../../../redux/selectors';

import { getPensionExpert } from '../../../redux/selectors/investmentAdvice';

import styles from '../DashboardPage.css';

const OptOutCustomer = (props) => {
  const {
    user,
    authoritativeRp,
    pensionExpert,
    firstName,
    email,
  } = props;

  return (
    <React.Fragment>
      <div className={styles.dashboardContentContainer}>
        <OngoingBalance user={user} rp={authoritativeRp} />
        <CurrentPlanCard />
      </div>
      <div className={styles.contactUsContainer}>
        <ContactUs
          pensionExpert={pensionExpert}
          firstName={firstName}
          email={email}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  pensionExpert: getPensionExpert(state),
  firstName: getFirstName(state),
  email: getEmail(state),
  authoritativeRp: getAuthoritativeRetirementPlan(state),
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect(),
)(withRouter(OptOutCustomer));
