import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';

import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { getHasInProgressPensions } from '../../redux/selectors/investmentAdvice';
import { MISSING_DETAILS_ALERT_LINK_CLICKED } from '../../redux/modules/dashboard';

const MissingDetailsNotification = ({
  hasInProgressPensions,
  timeMissingDetailsCompleted,
}) => {
  const [hideMissingDetails, setHideMissingDetails] = useState(false);

  const shouldHideBanner = () => {
    const timeBeforeShowingBanner = 15; // seconds

    if (!timeMissingDetailsCompleted) {
      return false;
    }

    const currentTime = moment.utc();
    return currentTime.diff(moment.utc(timeMissingDetailsCompleted), 'seconds') < timeBeforeShowingBanner;
  };

  useEffect(() => {
    let interval;
    let hideBanner;
    hideBanner = shouldHideBanner();
    setHideMissingDetails(hideBanner);

    if (hideBanner) {
      interval = setInterval(() => {
        hideBanner = shouldHideBanner();
        if (!hideBanner) {
          setHideMissingDetails(false);
          return clearInterval(interval);
        }
      }, 2000);
    }

    return () => { clearInterval(interval); };
  }, [timeMissingDetailsCompleted]);

  if (hideMissingDetails) {
    return null;
  }

  return (
    <NotificationBanner
      alertCategory={'Red'}
      alertTitle={''}
      expandableAlertDescription={hasInProgressPensions ? 'We need some details in order to transfer your pension' : 'We need some details in order to set up your contribution'}
      ctaButtonOptions={{
        size: 'small',
        label: 'Add missing details',
        link: '/missing-details',
        event: MISSING_DETAILS_ALERT_LINK_CLICKED,
        dataCy: 'missing-details-dashboard-link',
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  timeMissingDetailsCompleted: get(state, 'notificationTracking.timeMissingDetailsCompleted'),
  hasInProgressPensions: getHasInProgressPensions(state),
});

export default connect(mapStateToProps)(MissingDetailsNotification);
