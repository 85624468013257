import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import once from 'lodash/once';
import get from 'lodash/get';
import classnames from 'classnames';
import sentryException from '../../util/sentryException';

import ButtonLink from '../../components/ButtonLink/ButtonLink';
import styles from './ConversationSection.css';
import {
  getPendingMessagesOnConversation,
} from '../../redux/selectors/conversations';
import { getEmail, getFirebaseUid } from '../../redux/selectors';
import {
  addMessageAction,
  updateMessagesAction,
  clearPendingAction,
  replyMessageBodyTyped,
} from '../../redux/modules/conversations';
import TextField from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';
import FirstNameIcon from '../../components/InitialIcon/FirstNameIcon';
import InitialIcon from '../../components/InitialIcon/InitialIcon';
import { getPensionExpert } from '../../redux/selectors/investmentAdvice';
import formatDate from '../../util/formatDate';
import informationIcon from '../../assets/images/icons/information-grey.svg';
import isMessageFromAdviser from '../../util/isMessageFromAdviser';
import { removeWhitespace } from '../../util/common';
import HyperlinkSanitizedText from '../../components/HyperlinkSanitizedText/HyperlinkSanitizedText';
import AddMessageAttachments from '../../components/AddMessageAttachments/AddMessageAttachments';
import MessageAttachment from '../../components/MessageAttachment/MessageAttachment';

const ConversationSection = ({
  conversation,
  pendingMessages,
  dispatchAddMessage,
  dispatchUpdateMessages,
  pensionExpert,
  dispatchClearPending,
  dispatchReplyMessageBodyTyped: _dispatchReplyMessageBodyTyped,
  showDisclaimer,
  setShowDisclaimer,
  email,
}) => {
  const subject = get(conversation, 'subject');
  const messages = get(conversation, 'messages');
  const conversationId = get(conversation, 'conversationId');
  const pending = get(conversation, 'pending');
  const [newMessageBody, setNewMessageBody] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [submitError, setSubmitError] = useState(null);
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [loading, setLoading] = useState(false);

  const disableSendMessage = attachments.length > 0
    ? (!attachments.every((file) => file.accepted) || !removeWhitespace(newMessageBody))
    : !removeWhitespace(newMessageBody);

  const [dispatchReplyMessageBodyTyped] = useState(
    () => once(_dispatchReplyMessageBodyTyped),
  );
  const replyBoxRef = useRef(null);
  const latestMessageRef = useRef(null);

  const onSubmit = async () => {
    const messageData = { messageBody: newMessageBody.trim(), conversationId };

    if (attachments.length > 0) {
      messageData.attachmentUploads = attachments.map(({ filePath }) => filePath);
    }

    try {
      setLoading(true);
      setSubmitError(null);
      await dispatchAddMessage(messageData);
      setNewMessageBody('');
      setLoading(false);
      setShowDisclaimer(true);
      setShowReplyBox(false);
      window.scrollTo(0, 0);
    } catch (e) {
      dispatchClearPending();
      setLoading(false);
      setSubmitError(true);
      sentryException(e, {
        section: 'send-reply-error',
      });
    }
  };

  useEffect(() => {
    const unreadMessageIds = messages.filter((m) => get(m, 'fromUsername') !== 'account-hub' && !get(m, 'readDate')).map((m) => m._id).filter(Boolean);
    if (!unreadMessageIds.length) return;
    dispatchUpdateMessages({
      messageIds: unreadMessageIds,
      update: { readDate: new Date().toISOString() },
      conversationId,
    }).catch((e) => {
      sentryException(e, {
        section: 'update-message-error',
      });
    });
  }, [messages.length, conversationId]);

  useEffect(() => {
    return () => {
      if (!conversationId) return;
      if (showDisclaimer) {
        setShowDisclaimer(false);
      }
    };
  }, [conversationId, showDisclaimer]);

  useEffect(() => {
    const elem = get(latestMessageRef, 'current');
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [conversationId]);

  const getIcon = (fromUsername, isFromAdviser) => {
    if (fromUsername === 'account-hub' || pending) return <FirstNameIcon wrapperStyles={{ marginRight: '15px' }} />;
    const adviserImage = get(pensionExpert, 'imageUrl');
    if (isFromAdviser) {
      return (
        <img className={styles.adviserImage} src={adviserImage} alt="Pension adviser" />
      );
    }
    const fromOtherProfileUser = fromUsername !== 'account-hub' && !isFromAdviser;
    if (fromOtherProfileUser) return <InitialIcon initial="P" wrapperStyles={{ marginRight: '15px' }} />;
  };
  const getAuthor = (fromUsername, isFromAdviser) => {
    if (fromUsername === 'account-hub' || pending) return 'You';

    const adviserFirstName = get(pensionExpert, 'firstName');
    const adviserLastName = get(pensionExpert, 'lastName');

    const fromOtherProfileUser = fromUsername !== 'account-hub' && !isFromAdviser;

    if (isFromAdviser) return `${adviserFirstName} ${adviserLastName[0]}`;
    if (fromOtherProfileUser) return 'Profile Pensions';
    return '';
  };

  const messageList = messages.map(({
    messageBody,
    _id,
    createdDate,
    fromUsername,
    attachments: messageAttachments,
  },
  index) => {
    const hasAttachments = messageAttachments && messageAttachments.length > 0;
    const latestMessage = messages.length === (index + 1);
    const isFromAdviser = isMessageFromAdviser({ fromUsername }, get(pensionExpert, 'email'));
    return (
      <div
        className={styles.messageCard}
        key={_id}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(latestMessage && { ref: latestMessageRef })}
      >
        <div className={styles.metadataBar}>
          <div className={styles.authorContainer}>
            {getIcon(fromUsername, isFromAdviser)}
            {getAuthor(fromUsername, isFromAdviser)}
          </div>
          <div className={styles.date}>{formatDate(createdDate)}</div>
        </div>
        <HyperlinkSanitizedText
          className={styles.messageBody}
          value={messageBody}
        />
        {hasAttachments && (
        <div>
          {messageAttachments.map(({ attachmentUpload, document }) => {
            return (
              <MessageAttachment attachmentUpload={attachmentUpload} document={document} />
            );
          })}
        </div>
        )}
      </div>
    );
  });

  const replyBox = (
    <div
      ref={replyBoxRef}
      key="reply-box"
      id="reply-box"
    >
      {
        showReplyBox && (
          <div className={styles.replyBox}>
            <div className={styles.authorContainer}>
              <FirstNameIcon wrapperStyles={{ marginRight: '15px' }} />
              {'You'}
            </div>
            <TextField
              wrapperStyles={{ maxWidth: 'none', width: '100%' }}
              multiLine
              multiLineRows={4}
              value={newMessageBody}
              name="messageBody"
              defaultValue="Enter message here"
              onChange={(e) => {
                const messageBody = get(e, 'target.value');
                dispatchReplyMessageBodyTyped();
                setNewMessageBody(messageBody);
              }}
            />
            {(submitError) && (<div className={styles.error}>{'Something went wrong submitting this reply. Please try again.'}</div>)}
            <AddMessageAttachments
              onAttachmentsChange={(attachmentFiles) => {
                setAttachments(
                  attachmentFiles.map(({ accepted, filePath, createId }) => ({ accepted, filePath, createId })),
                );
              }}
            />
            <div className={styles.formButtons}>
              <div className={styles.desktopButtonContainer}>
                <Button
                  size="large"
                  disabled={disableSendMessage}
                  label="Send"
                  onClick={onSubmit}
                  loading={Boolean(loading || pendingMessages.length)}
                />
              </div>
              <ButtonLink
                label="Cancel"
                font="bodyTwo"
                onClick={() => setShowReplyBox(false)}
              />
            </div>
          </div>
        )
      }
      {
        (!showReplyBox) && (
          <React.Fragment>
            <div className={
              classnames(
                styles.desktopButtonCard,
                {
                  [styles.showDisclaimer]: showDisclaimer,
                },
              )
            }
            >
              {
                  (showDisclaimer) && (
                    <div className={styles.disclaimer}>
                      <img className={styles.informationIcon} src={informationIcon} alt="Information" />
                      <div className={styles.disclaimerMessage}>{'We aim to respond to all messages within 2 business days.'}</div>
                    </div>
                  )
                }
              <div className={styles.desktopButtonContainer}>
                <Button
                  disabled={pending}
                  label="Reply"
                  onClick={() => setShowReplyBox(true)}
                />
              </div>
            </div>
          </React.Fragment>
        )
      }
    </div>
  );

  return (
    <div className={styles.innerWrapper}>
      <div className={styles.inboxLinkWrapper}>
        <ButtonLink label="← View my inbox" to="/inbox" />
      </div>
      <h2 className={styles.subjectHeading}>{subject}</h2>
      <div className={styles.messagesList}>
        {messageList}
      </div>
      {replyBox}
    </div>
  );
};

ConversationSection.defaultProps = {
  showDisclaimer: false,
};

const mapStateToProps = (state, ownProps) => {
  const conversationId = get(ownProps, 'conversation.conversationId');
  return ({
    pendingMessages: getPendingMessagesOnConversation(state, conversationId),
    uid: getFirebaseUid(state),
    pensionExpert: getPensionExpert(state),
    email: getEmail(state),
  });
};

const mapDispatchToProps = ({
  dispatchAddMessage: addMessageAction,
  dispatchUpdateMessages: updateMessagesAction,
  dispatchClearPending: clearPendingAction,
  dispatchReplyMessageBodyTyped: replyMessageBodyTyped,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ConversationSection);
