/* eslint-disable import/no-cycle */
import get from 'lodash/get';

export const getTargetInvestment = (state) => {
  return get(state, 'firestore.ordered.investmentAdviceOnboarding[0].targetInvestment');
};

export const getTargetInvestmentPlatformName = (state) => {
  return get(state, 'firestore.ordered.investmentAdviceOnboarding[0].targetInvestment.platformName');
};
