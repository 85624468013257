import React from 'react';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ButtonLink from '../../components/ButtonLink/ButtonLink';

import styles from './AegonWithdrawals.css';

const AegonWithdrawals = () => {
  return (
    <BaseGrid tabletTwelveColumn messageWidget>
      <div className={styles.container}>
        <ButtonLink
          label="← Back to dashboard"
          to="/"
        />
        <h1 className={styles.pageHeading}>{'Important information'}</h1>
        <p className={styles.bodyCopy}>
          {'We give you advice on how to invest your pension. We do not give you advice on how much you should withdraw, however, your Pension Adviser can help you understand your options and we can arrange withdrawals for you.  There is also a free impartial pension guidance service provided by the government '}
          <a href="https://pensionwise.gov.uk/" target={'_blank'} rel="noopener noreferrer">{'pensionwise.gov.uk'}</a>
          {'.'}
          <br />
          <br />
          {'Taking money from your pension plan is an important decision and you should consider the merits of taking advice or guidance first.'}
          <br />
          <br />
          {'All our plans allow for Flexible Access Drawdown. This means that you can access the money in your pension flexibly once you are 55 (57 from 2028). You can withdraw money either as a lump sum or on a regular basis.'}
          <br />
          <br />
          {'You can withdraw up to 25% from your fund as Tax Free Cash. This can be as a one off lump sum, a series of withdrawals or as a regular amount. Any amount you take above this 25% will be taxed at your marginal rate.'}
          <br />
          <br />
          {'There is no charge for withdrawing Tax Free Cash from your pension plan, however once your withdrawals exceed this amount, there is a charge of £75 per annum from your provider, Aegon. This is deducted annually where there has been a withdrawal in any 12 month period.'}
          <br />
          <br />
          {'Once any income you take exceeds the Tax Free Cash element, the income automatically stops.  A new instruction would be required in order to continue income from the crystallised pension fund.'}
          <br />
          <br />
          {'You should consider  other options to raise the cash you need.'}
          <br />
          <br />
          {'Taking money now will reduce the value of your pension pot which means you will have less money to generate an income in the future which may have an impact on your standard of living.'}
          <br />
          <br />
          {'You should consider how you will continue to fund your retirement should the money in your pension run out.'}
          <br />
          <br />
          {'If the amount you withdraw exceeds 25%, whilst this excess is charged at your marginal rate, initially it may be subject to emergency tax and you may have to reclaim this from HMRC.'}
          <br />
          <br />
          {'Once you withdraw more than 25% from your plan, you may be subject to the Money Purchase Annual Allowance (MPAA) which means that future contributions into your pension will be restricted. This allowance is currently £4000.'}
          <br />
          <br />
          {'When you make this withdrawal, if the money is left in a savings or current account, inflation may erode its value.'}
          <br />
          <br />
          {'Taking money can affect any means tested benefits you receive and may affect how long term care costs are assessed'}
          <br />
          <br />
          {'If you owe money to creditors, they may be entitled to the money you withdraw from your pension.'}
          <br />
          <br />
          {'If the withdrawal is to invest this money elsewhere, you should consider the tax and charges on the new investment and how they compare to the existing plan. By withdrawing from a pension, you are potentially moving to a less tax efficient investment. You should also be aware of investment scams and take care with where you invest.'}
          <br />
          <br />
          {'If you are looking to purchase an annuity, you should be aware of the merits of shopping around to get the best rates. If you have a partner or dependants, you may wish to consider a joint life annuity?'}
        </p>
      </div>
    </BaseGrid>
  );
};

export default AegonWithdrawals;
