import React from 'react';
import { connect } from 'react-redux';
import { getHasOnlyInProgressContributionInstruction } from '../../redux/selectors/investmentAdvice';
import SetupContributionPage from '../SetupContributionPage/SetupContributionPage';
import { MissingDetailsController } from '../MissingDetailsControllers/MissingDetailsControllers';

const MissingDetailsRouter = ({
  hasOnlyInProgressContributionInstruction,
}) => {
  if (hasOnlyInProgressContributionInstruction) {
    return (
      <SetupContributionPage missingDetailsOnly flow={'missing-details-cta'} />
    );
  }
  return (
    <MissingDetailsController flow={'missing-details-cta'} />
  );
};

const mapStateToProps = (state) => ({
  hasOnlyInProgressContributionInstruction: getHasOnlyInProgressContributionInstruction(state),
});

export default connect(mapStateToProps)(MissingDetailsRouter);
