import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { getFirebaseUid } from '../../redux/selectors';
import LoaderPage from '../../pages/LoaderPage/LoaderPage';
import {
  getIsOnboardingLoaded,
  getIsProviderNamesLoaded,
  getIsRetirementPlansLoaded,
  getIsIvuLoaded,
  getisCdsLoaded,
  getIsSignupLoaded,
  getIsUserLoaded,
  getIsConversationsLoaded,
  getIsNotificationsLoaded,
  getIsAttachmentsLoaded,
  getIsProjectionsLoaded,
  getIsCachedRetirementIncomePlanValuesLoaded,
} from '../../redux/selectors/loaded';

export const COLLECTIONS = {
  USER: 'USER',
  ONBOARDING: 'ONBOARDING',
  CDS: 'CDS',
  RETIREMENTPLANS: 'RETIREMENTPLANS',
  PROVIDERS: 'PROVIDERS',
  IVU: 'IVU',
  SIGNUP: 'SIGNUP',
  CONVERSATIONS: 'CONVERSATIONS',
  NOTIFICATIONS: 'NOTIFICATIONS',
  ATTACHMENTS: 'ATTACHMENTS',
  PROJECTIONS: 'PROJECTIONS',
  CACHED_RETIREMENT_INCOME_PLAN_VALUES: 'CACHED_RETIREMENT_INCOME_PLAN_VALUES',
};

const WithCollections = ({
  children,
  dependencies,
  isUserLoaded,
  isConversationsLoaded,
  isOnboardingLoaded,
  isCdsLoaded,
  isRetirementPlansLoaded,
  isProviderNamesLoaded,
  isIvuLoaded,
  isSignupLoaded,
  isNotificationsLoaded,
  isAttachmentsLoaded,
  isProjectionsLoaded,
  isCachedRetirementIncomePlanValuesLoaded,
  context,
}) => {
  const loadingChecks = {
    ...(dependencies.includes(COLLECTIONS.USER) && { isUserLoaded }),
    ...(dependencies.includes(COLLECTIONS.ONBOARDING) && { isOnboardingLoaded }),
    ...(dependencies.includes(COLLECTIONS.CDS) && { isCdsLoaded }),
    ...(dependencies.includes(COLLECTIONS.RETIREMENTPLANS) && { isRetirementPlansLoaded }),
    ...(dependencies.includes(COLLECTIONS.PROVIDERS) && { isProviderNamesLoaded }),
    ...(dependencies.includes(COLLECTIONS.IVU) && { isIvuLoaded }),
    ...(dependencies.includes(COLLECTIONS.SIGNUP) && { isSignupLoaded }),
    ...(dependencies.includes(COLLECTIONS.CONVERSATIONS) && { isConversationsLoaded }),
    ...(dependencies.includes(COLLECTIONS.NOTIFICATIONS) && { isNotificationsLoaded }),
    ...(dependencies.includes(COLLECTIONS.ATTACHMENTS) && { isAttachmentsLoaded }),
    ...(dependencies.includes(COLLECTIONS.PROJECTIONS) && { isProjectionsLoaded }),
    ...(dependencies.includes(COLLECTIONS.CACHED_RETIREMENT_INCOME_PLAN_VALUES) && { isCachedRetirementIncomePlanValuesLoaded }),
  };

  const allCollectionsLoaded = Object.values(loadingChecks).every(Boolean);

  const metadata = {
    ...loadingChecks,
    context,
  };

  if (!allCollectionsLoaded) {
    return <LoaderPage metadata={metadata} />;
  }

  return (
    <React.Fragment>{children}</React.Fragment>
  );
};

WithCollections.defaultProps = {
  context: 'unknown-loader',
  dependencies: [],
};

const mapStateToProps = (state) => ({
  state,
  uid: getFirebaseUid(state),
  isConversationsLoaded: getIsConversationsLoaded(state),
  isOnboardingLoaded: getIsOnboardingLoaded(state),
  isProviderNamesLoaded: getIsProviderNamesLoaded(state),
  isRetirementPlansLoaded: getIsRetirementPlansLoaded(state),
  isIvuLoaded: getIsIvuLoaded(state),
  isCdsLoaded: getisCdsLoaded(state),
  isSignupLoaded: getIsSignupLoaded(state),
  isUserLoaded: getIsUserLoaded(state),
  isNotificationsLoaded: getIsNotificationsLoaded(state),
  isAttachmentsLoaded: getIsAttachmentsLoaded(state),
  isProjectionsLoaded: getIsProjectionsLoaded(state),
  isCachedRetirementIncomePlanValuesLoaded: getIsCachedRetirementIncomePlanValuesLoaded(state),
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    const queries = [];

    if (props.dependencies.includes(COLLECTIONS.USER)) {
      queries.push({
        collection: 'users',
        doc: props.uid,
        storeAs: 'users',
      });
    }
    if (props.dependencies.includes(COLLECTIONS.CONVERSATIONS)) {
      queries.push({
        collection: 'users',
        doc: props.uid,
        subcollections: [{ collection: 'conversations' }],
        storeAs: 'conversations',
      });
    }
    if (props.dependencies.includes(COLLECTIONS.ONBOARDING)) {
      queries.push({
        collection: 'users',
        doc: props.uid,
        subcollections: [{ collection: 'investmentAdviceOnboarding' }],
        storeAs: 'investmentAdviceOnboarding',
      });
    }
    if (props.dependencies.includes(COLLECTIONS.RETIREMENTPLANS)) {
      queries.push({
        collection: 'users',
        doc: props.uid,
        subcollections: [{ collection: 'retirementPlans' }],
        storeAs: 'retirementPlans',
      });
    }
    if (props.dependencies.includes(COLLECTIONS.NOTIFICATIONS)) {
      queries.push({
        collection: 'users',
        doc: props.uid,
        subcollections: [{ collection: 'notificationTracking' }],
        storeAs: 'notificationTracking',
      });
    }
    if (props.dependencies.includes(COLLECTIONS.CDS)) {
      queries.push({
        collection: 'users',
        doc: props.uid,
        subcollections: [{ collection: 'customerDataSet' }],
        storeAs: 'customerDataSets',
      });
    }
    if (props.dependencies.includes(COLLECTIONS.SIGNUP)) {
      queries.push({
        collection: 'users',
        doc: props.uid,
        subcollections: [{ collection: 'signup' }],
        storeAs: 'signups',
      });
    }
    if (props.dependencies.includes(COLLECTIONS.PROVIDERS)) {
      queries.push({
        collection: 'providers',
        doc: 'names',
        storeAs: 'providers',
      });
    }
    if (props.dependencies.includes(COLLECTIONS.IVU)) {
      queries.push({
        collection: 'ivuCollection',
        doc: 'ivuDocument',
        storeAs: 'ivuCollection',
      });
    }
    if (props.dependencies.includes(COLLECTIONS.ATTACHMENTS)) {
      queries.push({
        collection: 'users',
        doc: props.uid,
        subcollections: [{ collection: 'attachmentUploads' }],
        storeAs: 'attachmentUploads',
      });
    }
    if (props.dependencies.includes(COLLECTIONS.PROJECTIONS)) {
      queries.push({
        collection: 'users',
        doc: props.uid,
        subcollections: [{ collection: 'projections' }],
        storeAs: 'projections',
      });
    }
    if (props.dependencies.includes(COLLECTIONS.CACHED_RETIREMENT_INCOME_PLAN_VALUES)) {
      queries.push({
        collection: 'users',
        doc: props.uid,
        subcollections: [{ collection: 'cachedRetirementIncomePlanValues' }],
        storeAs: 'cachedRetirementIncomePlanValues',
      });
    }
    return queries;
  }),
)(WithCollections);
