import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import sentryException from '../../util/sentryException';

export const SERPS_MISSING_DETAILS_NINO_PROVIDED = 'pp/dashboard/SERPS_MISSING_DETAILS_NINO_PROVIDED';
export const SERPS_READ_YOUR_RECOMMENDED_PLAN_CLICKED = 'pp/dashboard/SERPS_READ_YOUR_RECOMMENDED_PLAN_CLICKED';
const SIGNATURE_CLEAR_BUTTON_CLICKED = 'pp/dashboard/SIGNATURE_CLEAR_BUTTON_CLICKED';
const SERPS_MISSING_DETAILS_SIGNATURE_PROVIDED = 'pp/dashboard/SERPS_MISSING_DETAILS_SIGNATURE_PROVIDED';
const SERPS_MISSING_DETAILS_AUTHORITY_PROVIDED = 'pp/dashboard/SERPS_MISSING_DETAILS_AUTHORITY_PROVIDED';
const SERPS_MISSING_DETAILS_GET_STARTED = 'pp/dashboard/SERPS_MISSING_DETAILS_GET_STARTED';
const SERPS_MISSING_DETAILS_SUCCESS_FIND_PENSIONS_CLICKED = 'pp/dashboard/SERPS_MISSING_DETAILS_SUCCESS_FIND_PENSIONS_CLICKED';
const SERPS_MISSING_DETAILS_SUCCESS_ADD_CONTRIBUTIONS_CLICKED = 'pp/dashboard/SERPS_MISSING_DETAILS_SUCCESS_ADD_CONTRIBUTIONS_CLICKED';
const SERPS_TILE_COMPLETE_REQUEST_CLICKED = 'pp/dashboard/SERPS_TILE_COMPLETE_REQUEST_CLICKED';
const SERPS_TILE_FIND_CHECK_AND_TRANSFER_CLICKED = 'pp/dashboard/SERPS_TILE_FIND_CHECK_AND_TRANSFER_CLICKED';
const SERPS_NEXT_STEPS_ADD_PENSIONS_CLICKED = 'pp/dashboard/SERPS_NEXT_STEPS_ADD_PENSIONS_CLICKED';
const SERPS_NEXT_STEPS_ADD_MONEY_CLICKED = 'pp/dashboard/SERPS_NEXT_STEPS_ADD_MONEY_CLICKED';
const SERPS_TRUST_PILOT_REVIEW_CAROUSEL_CLICKED = 'pp/dashboard/SERPS_TRUST_PILOT_REVIEW_CAROUSEL_CLICKED';
const SERPS_TRUST_PILOT_LOGO_CLICKED = 'pp/dashboard/SERPS_TRUST_PILOT_LOGO_CLICKED';
const SERPS_FIND_OTHER_PENSIONS_CLICKED = 'pp/dashboard/SERPS_FIND_OTHER_PENSIONS_CLICKED';
const SERPS_DASHBOARD_VIEWED = 'pp/dashboard/SERPS_DASHBOARD_VIEWED';
const SERPS_INTRO_MODAL_CLOSED = 'pp/dashboard/SERPS_INTRO_MODAL_CLOSED';

export const SERPS_MISSING_DETAILS_MODAL_CTA_CLICKED = 'pp/serps/SERPS_ADD_MISSING_DETAILS_CTA_CLICKED';
export const SERPS_MISSING_DETAILS_MODAL_CLOSED = 'pp/serps/SERPS_ADD_MISSING_DETAILS_MODAL_CLOSED';
export const SERPS_MISSING_DETAILS_NINO_PAGE_LOADED = 'pp/serps/SERPS_MISSING_DETAILS_NINO_PAGE_LOADED';
export const SERPS_MISSING_DETAILS_NINO_ADDED = 'pp/serps/SERPS_MISSING_DETAILS_NINO_ADDED';
export const SERPS_MISSING_DETAILS_SIGNATURE_PAGE_LOADED = 'pp/serps/SERPS_MISSING_DETAILS_SIGNATURE_PAGE_LOADED';
export const SERPS_MISSING_DETAILS_SIGNATURE_SIZE_ERROR = 'pp/dashboard/SERPS_MISSING_DETAILS_SIGNATURE_SIZE_ERROR';
export const SERPS_MISSING_DETAILS_SIGNATURE_ADDED = 'pp/serps/SERPS_MISSING_DETAILS_SIGNATURE_ADDED';
export const SERPS_MISSING_DETAILS_AUTHORIZE_PAGE_LOADED = 'pp/serps/SERPS_MISSING_DETAILS_AUTHORIZE_PAGE_LOADED';
export const SERPS_MISSING_DETAILS_AUTHORIZE_REQUEST_CLICKED = 'pp/serps/SERPS_AUTHORIZE_REQUEST_CLICKED';
export const SERPS_MISSING_DETAILS_MESSAGE_ME_CLICKED = 'pp/dashboard/SERPS_MISSING_DETAILS_SIGNATURE_MESSAGE_ME_CLICKED';
export const SERPS_MISSING_DETAILS_THANK_YOU_PAGE_LOADED = 'pp/serps/SERPS_MISSING_DETAILS_THANK_YOU_PAGE_LOADED';
export const SERPS_MISSING_DETAILS_THANK_YOU_PAGE_ADD_PENSION_CLICKED_ACTION = 'pp/serps/SERPS_MISSING_DETAILS_THANK_YOU_PAGE_ADD_PENSION_CLICKED_ACTION';
export const SERPS_MISSING_DETAILS_THANK_YOU_PAGE_ADD_CONTRIBUTION_CLICKED_ACTION = 'pp/serps/SERPS_MISSING_DETAILS_THANK_YOU_PAGE_ADD_PENSION_CLICKED_ACTION';
export const SERPS_MISSING_DETAILS_THANK_YOU_PAGE_VIEW_DASHBOARD_CLICKED_ACTION = 'pp/serps/SERPS_MISSING_DETAILS_THANK_YOU_PAGE_VIEW_DASHBOARD_CLICKED_ACTION';
export const SERPS_PENSION_AUTHORITY_SCREEN_LANDED = 'pp/dashboard/SERPS_PENSION_AUTHORITY_SCREEN_LANDED';
export const SERPS_PENSION_AUTHORITY_SUCCESS_ADD_PENSIONS_CLICKED = 'pp/dashboard/SERPS_PENSION_AUTHORITY_SUCCESS_ADD_PENSIONS_CLICKED';
export const SERPS_PENSION_AUTHORITY_SUCCESS_ADD_MONEY_CLICKED = 'pp/dashboard/SERPS_PENSION_AUTHORITY_SUCCESS_ADD_MONEY_CLICKED';
export const SERPS_PENSION_AUTHORITY_SUCCESS_LANDED = 'pp/dashboard/SERPS_PENSION_AUTHORITY_SUCCESS_LANDED';
export const SERPS_PENSION_AUTHORITY_SUCCESS_RETURN_TO_DASHBOARD_CLICKED = 'pp/dashboard/SERPS_PENSION_AUTHORITY_SUCCESS_RETURN_TO_DASHBOARD_CLICKED';
export const SERPS_RECOMMENDED_PENSION_PLAN_ADD_MONEY_CLICKED = 'pp/dashboard/SERPS_RECOMMENDED_PENSION_PLAN_ADD_MONEY_CLICKED';
export const SERPS_RECOMMENDED_PENSION_PLAN_ADD_PENSIONS_CLICKED = 'pp/dashboard/SERPS_RECOMMENDED_PENSION_PLAN_ADD_PENSIONS_CLICKED';

export const SERPS_MISSING_DETAILS_NEXT_STEPS_ADD_PENSIONS_CLICKED = 'pp/dashboard/SERPS_MISSING_DETAILS_NEXT_STEPS_ADD_PENSIONS_CLICKED';
export const SERPS_MISSING_DETAILS_NEXT_STEPS_ADD_MONEY_CLICKED = 'pp/dashboard/SERPS_MISSING_DETAILS_NEXT_STEPS_ADD_MONEY_CLICKED';
export const SERPS_TRACE_RECEIVED_NEXT_STEPS_ADD_PENSIONS_CLICKED = 'pp/dashboard/SERPS_TRACE_RECEiVED_NEXT_STEPS_ADD_PENSIONS_CLICKED';
export const SERPS_TRACE_RECEIVED_NEXT_STEPS_ADD_MONEY_CLICKED = 'pp/dashboard/SERPS_TRACE_RECEIVED_NEXT_STEPS_ADD_MONEY_CLICKED';
export const SERPS_HMRC_PENSIONS_FOUND_NEXT_STEPS_ADD_PENSIONS_CLICKED = 'pp/dashboard/SERPS_HMRC_PENSIONS_FOUND_NEXT_STEPS_ADD_PENSIONS_CLICKED';
export const SERPS_HMRC_PENSIONS_FOUND_NEXT_STEPS_ADD_MONEY_CLICKED = 'pp/dashboard/SERPS_HMRC_PENSIONS_FOUND_NEXT_STEPS_ADD_MONEY_CLICKED';
export const SERPS_HMRC_PENSIONS_NOT_FOUND_NEXT_STEPS_ADD_PENSIONS_CLICKED = 'pp/dashboard/SERPS_HMRC_PENSIONS_NOT_FOUND_NEXT_STEPS_ADD_PENSIONS_CLICKED';
export const SERPS_HMRC_PENSIONS_NOT_FOUND_NEXT_STEPS_ADD_MONEY_CLICKED = 'pp/dashboard/SERPS_HMRC_PENSIONS_NOT_FOUND_NEXT_STEPS_ADD_MONEY_CLICKED';
export const SERPS_HMRC_PENSIONS_AUTHORISED_NEXT_STEPS_ADD_PENSIONS_CLICKED = 'pp/dashboard/SERPS_HMRC_PENSIONS_AUTHORISED_NEXT_STEPS_ADD_PENSIONS_CLICKED';
export const SERPS_HMRC_PENSIONS_AUTHORISED_NEXT_STEPS_ADD_MONEY_CLICKED = 'pp/dashboard/SERPS_HMRC_PENSIONS_AUTHORISED_NEXT_STEPS_ADD_MONEY_CLICKED';
export const SERPS_DOB_NOT_ELIGIBLE_NEXT_STEPS_ADD_PENSIONS_CLICKED = 'pp/dashboard/SERPS_DOB_NOT_ELIGIBLE_NEXT_STEPS_ADD_PENSIONS_CLICKED';
export const SERPS_DOB_NOT_ELIGIBLE_AUTHORISED_NEXT_STEPS_ADD_MONEY_CLICKED = 'pp/dashboard/SERPS_DOB_NOT_ELIGIBLE_AUTHORISED_NEXT_STEPS_ADD_MONEY_CLICKED';

export const SET_SELECTED_SERPS_TO_AUTHORISE = 'pp/dashboard/SET_SELECTED_SERPS_TO_AUTHORISE';

export const serpsMissingDetailsModalCTAClickedAction = () => ({
  type: SERPS_MISSING_DETAILS_MODAL_CTA_CLICKED,
});

export const serpsMissingDetailsModalClosedAction = () => ({
  type: SERPS_MISSING_DETAILS_MODAL_CLOSED,
});

export const serpsMissingDetailsNinoProvided = () => ({
  type: SERPS_MISSING_DETAILS_NINO_PROVIDED,
});

export const signatureClearButtonClicked = () => ({
  type: SIGNATURE_CLEAR_BUTTON_CLICKED,
});

export const serpsMissingDetailsSignatureProvided = () => ({
  type: SERPS_MISSING_DETAILS_SIGNATURE_PROVIDED,
});

export const serpsMissingDetailsSignatureSizeErrorAction = () => ({
  type: SERPS_MISSING_DETAILS_SIGNATURE_SIZE_ERROR,
});
export const serpsMissingDetailsSignaturePageLoaded = () => ({
  type: SERPS_MISSING_DETAILS_SIGNATURE_PAGE_LOADED,
});

export const serpsMissingDetailsAuthorityProvided = () => ({
  type: SERPS_MISSING_DETAILS_AUTHORITY_PROVIDED,
});

export const serpsMissingDetailsAuthorizeRequestPageLoaded = () => ({
  type: SERPS_MISSING_DETAILS_AUTHORIZE_PAGE_LOADED,
});

export const serpsMissingDetailsMessageMeClickedAction = () => ({
  type: SERPS_MISSING_DETAILS_MESSAGE_ME_CLICKED,
});

export const serpsMissingDetailsNinoPageLoaded = () => ({
  type: SERPS_MISSING_DETAILS_NINO_PAGE_LOADED,
});

export const serpsMissingDetailsThankYouPageLoaded = () => ({
  type: SERPS_MISSING_DETAILS_THANK_YOU_PAGE_LOADED,
});

export const serpsMissingDetailsThankYouPageAddPensionClickedAction = () => ({
  type: SERPS_MISSING_DETAILS_THANK_YOU_PAGE_ADD_PENSION_CLICKED_ACTION,
});

export const serpsMissingDetailsThankYouPageAddContributionClickedAction = () => ({
  type: SERPS_MISSING_DETAILS_THANK_YOU_PAGE_ADD_CONTRIBUTION_CLICKED_ACTION,
});

export const serpsMissingDetailsThankYouPageViewDashboardClickedAction = () => ({
  type: SERPS_MISSING_DETAILS_THANK_YOU_PAGE_VIEW_DASHBOARD_CLICKED_ACTION,
});

export const serpsReadYourRecommendedPlanClicked = () => ({
  type: SERPS_READ_YOUR_RECOMMENDED_PLAN_CLICKED,
});

export const serpsMissingDetailsGetStarted = () => ({
  type: SERPS_MISSING_DETAILS_GET_STARTED,
});

export const serpsMissingDetailsSuccessFindPensionsClicked = () => ({
  type: SERPS_MISSING_DETAILS_SUCCESS_FIND_PENSIONS_CLICKED,
});

export const serpsMissingDetailsSuccessAddContributionsClicked = () => ({
  type: SERPS_MISSING_DETAILS_SUCCESS_ADD_CONTRIBUTIONS_CLICKED,
});

export const serpsTileCompleteRequestClicked = () => ({
  type: SERPS_TILE_COMPLETE_REQUEST_CLICKED,
});

export const serpsTileFindCheckAndTransferClicked = () => ({
  type: SERPS_TILE_FIND_CHECK_AND_TRANSFER_CLICKED,
});

export const serpsNextStepsAddMoneyClicked = () => ({
  type: SERPS_NEXT_STEPS_ADD_MONEY_CLICKED,
});

export const serpsNextStepsAddPensionsClicked = () => ({
  type: SERPS_NEXT_STEPS_ADD_PENSIONS_CLICKED,
});

export const serpsTrustPilotReviewCarouselClicked = () => ({
  type: SERPS_TRUST_PILOT_REVIEW_CAROUSEL_CLICKED,
});

export const serpsTrustPilotLogoClicked = () => ({
  type: SERPS_TRUST_PILOT_LOGO_CLICKED,
});

export const serpsFindOtherPensionsButtonClicked = () => ({
  type: SERPS_FIND_OTHER_PENSIONS_CLICKED,
});

export const serpsDashboardViewed = () => ({
  type: SERPS_DASHBOARD_VIEWED,
});

export const serpsIntroModalClosed = () => ({
  type: SERPS_INTRO_MODAL_CLOSED,
});

export const serpsAuthorityScreenLanded = () => ({
  type: SERPS_PENSION_AUTHORITY_SCREEN_LANDED,
});

export const serpsPensionAuthorityThankYouPageAddContributionClickedAction = () => ({
  type: SERPS_PENSION_AUTHORITY_SUCCESS_ADD_MONEY_CLICKED,
});
export const serpsPensionAuthorityThankYouPageAddPensionClickedAction = () => ({
  type: SERPS_PENSION_AUTHORITY_SUCCESS_ADD_PENSIONS_CLICKED,
});
export const serpsPensionAuthorityThankYouPageLoaded = () => ({
  type: SERPS_PENSION_AUTHORITY_SUCCESS_LANDED,
});
export const serpsPensionAuthorityThankYouPageViewDashboardClickedAction = () => ({
  type: SERPS_PENSION_AUTHORITY_SUCCESS_RETURN_TO_DASHBOARD_CLICKED,
});

export const serpsRecommendedAddPensionClicked = () => ({
  type: SERPS_RECOMMENDED_PENSION_PLAN_ADD_MONEY_CLICKED,
});
export const serpsRecommendedAddMoneyClicked = () => ({
  type: SERPS_RECOMMENDED_PENSION_PLAN_ADD_PENSIONS_CLICKED,
});

export const serpsNextStepsMissingDetailsAddPensionClicked = () => ({
  type: SERPS_MISSING_DETAILS_NEXT_STEPS_ADD_PENSIONS_CLICKED,
});
export const serpsNextStepsMissingDetailsAddMoneyClicked = () => ({
  type: SERPS_MISSING_DETAILS_NEXT_STEPS_ADD_MONEY_CLICKED,
});
export const serpsNextStepsTraceReceivedAddPensionClicked = () => ({
  type: SERPS_TRACE_RECEIVED_NEXT_STEPS_ADD_PENSIONS_CLICKED,
});
export const serpsNextStepsTraceReceivedAddMoneyClicked = () => ({
  type: SERPS_TRACE_RECEIVED_NEXT_STEPS_ADD_MONEY_CLICKED,
});
export const serpsNextStepsPensionsFoundAddPensionClicked = () => ({
  type: SERPS_HMRC_PENSIONS_FOUND_NEXT_STEPS_ADD_PENSIONS_CLICKED,
});
export const serpsNextStepsPensionsFoundAddMoneyClicked = () => ({
  type: SERPS_HMRC_PENSIONS_FOUND_NEXT_STEPS_ADD_MONEY_CLICKED,
});
export const serpsNextStepsPensionsNotFoundAddPensionClicked = () => ({
  type: SERPS_HMRC_PENSIONS_NOT_FOUND_NEXT_STEPS_ADD_PENSIONS_CLICKED,
});
export const serpsNextStepsPensionsNotFoundAddMoneyClicked = () => ({
  type: SERPS_HMRC_PENSIONS_NOT_FOUND_NEXT_STEPS_ADD_MONEY_CLICKED,
});
export const serpsNextStepsPensionsAuthorisedAddPensionClicked = () => ({
  type: SERPS_HMRC_PENSIONS_AUTHORISED_NEXT_STEPS_ADD_PENSIONS_CLICKED,
});
export const serpsNextStepsPensionsAuthorisedAddMoneyClicked = () => ({
  type: SERPS_HMRC_PENSIONS_AUTHORISED_NEXT_STEPS_ADD_MONEY_CLICKED,
});
export const serpsNextStepsDOBNotEligibleAddPensionClicked = () => ({
  type: SERPS_DOB_NOT_ELIGIBLE_NEXT_STEPS_ADD_PENSIONS_CLICKED,
});
export const serpsNextStepsDOBNotEligibleAddMoneyClicked = () => ({
  type: SERPS_DOB_NOT_ELIGIBLE_AUTHORISED_NEXT_STEPS_ADD_MONEY_CLICKED,
});
export const setSelectedSerpsToAuthoriseAction = ({ selectedPensions }) => {
  return {
    type: SET_SELECTED_SERPS_TO_AUTHORISE,
    payload: selectedPensions,
  };
};

export const SERPS_MISSING_DETAILS_ADD_SERPS_REQUEST_DISPATCHED = 'pp/serps/SERPS_MISSING_DETAILS_ADD_SERPS_REQUEST_DISPATCHED';
export const SERPS_MISSING_DETAILS_ADD_SERPS_REQUEST_FULFILLED = 'pp/serps/SERPS_MISSING_DETAILS_ADD_SERPS_REQUEST_FULFILLED';
export const SERPS_MISSING_DETAILS_ADD_SERPS_REQUEST_REJECTED = 'pp/serps/SERPS_MISSING_DETAILS_ADD_SERPS_REQUEST_REJECTED';

export const addSerpsRequestAction = ({
  nationalInsuranceNumber,
  signature,
  authoriseSerpsTrace,
  screenshot,
  pageHtml,
}) => (async (dispatch) => {
  const addSerpsRequest = firebase.app().functions('europe-west1').httpsCallable('addSerpsTraceRequest');
  const serpsRequestDispatched = SERPS_MISSING_DETAILS_ADD_SERPS_REQUEST_DISPATCHED;
  const serpsRequestFulfilled = SERPS_MISSING_DETAILS_ADD_SERPS_REQUEST_FULFILLED;
  const serpsRequestRejected = SERPS_MISSING_DETAILS_ADD_SERPS_REQUEST_REJECTED;

  dispatch({ type: serpsRequestDispatched });

  try {
    await addSerpsRequest({
      nationalInsuranceNumber,
      signature,
      authoriseSerpsTrace,
      screenshot,
      pageHtml,
    });
    dispatch({ type: serpsRequestFulfilled });
  } catch (e) {
    dispatch({ type: serpsRequestRejected });
    sentryException(e, {
      section: 'add-serps-request',
    });
    throw e;
  }
});

const initialState = {
  selectedSerpsToAuthorise: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_SERPS_TO_AUTHORISE:
      return {
        ...state,
        selectedSerpsToAuthorise: action.payload,
      };
    default:
      return state;
  }
}
