import React from 'react';
import styles from './FooterContact.css';

const locations = [
  { name: 'Preston', address: 'Norwest Court, Guildhall Street, Preston, PR1 3NU' },
];

const FooterContact = () => (
  <div className={styles.wrapper}>
    <h5 className={styles.heading}>{'CONTACT US'}</h5>
    {locations.map((location) => (
      <React.Fragment key={location.name}>
        <p className={styles.locationName}>{location.name}</p>
        <p className={styles.text}>{location.address}</p>
        <br />
      </React.Fragment>
    ))}
    <p className={styles.text}>
      <a className={styles.link} href="tel:01772 804 404">{'01772 804 404'}</a>
      <br />
      <a className={styles.link} href="mailto:contact@profilepensions.co.uk">{'contact@profilepensions.co.uk'}</a>
    </p>
  </div>
);

export default FooterContact;
