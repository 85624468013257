import React from 'react';
import PropTypes from 'prop-types';
import styles from './CalloutText.css';

const CalloutText = ({ text }) => (
  <p className={styles.wrapper}>{text}</p>
);

CalloutText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CalloutText;
