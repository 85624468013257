import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Loader.css';

export default class Loader extends React.PureComponent {
  render() {
    const {
      label,
      centred,
      page,
      basePage,
      wrapperStyles,
    } = this.props;
    const firstLoaderClasses = [styles.loader1, styles.loader].join(' ');
    const subsequentLoaderClasses = [styles.loader2, styles.loader].join(' ');

    return (
      <div
        className={
          classnames(
            styles.wrapper,
            {
              [styles.centred]: centred || basePage,
              [styles.page]: page,
              [styles.basePage]: basePage,
            },
          )
        }
        style={wrapperStyles}
      >
        <div className={styles.loaderWrapper}>
          <div className={firstLoaderClasses} />
          <div className={subsequentLoaderClasses} />
          <div className={subsequentLoaderClasses} />
        </div>
        {label && <div className={styles.label}>{label}</div>}
      </div>
    );
  }
}

Loader.propTypes = {
  label: PropTypes.string,
  basePage: PropTypes.bool,
  wrapperStyles: PropTypes.shape({}),
};

Loader.defaultProps = {
  label: null,
  basePage: false,
  wrapperStyles: {},
};
