import React from 'react';
import { connect } from 'react-redux';

import Button from '../Button/Button';
import GrayDash from '../../assets/images/icons/Icon_Circle_Gray_Dash.svg';

import { withdrawalLinkClicked } from '../../redux/modules/dashboard';
import {
  getInProgressWithdrawalInstructions,
  getInProgressWithdrawalLumpSumInstructions,
  getIsWithdrawalLumpSumWithinCoolOffPeriod,
} from '../../redux/selectors/investmentAdvice';

import styles from './AddPensions.css';
import pensionRowStyles from './PensionRows.css';
import withdrawalRowStyles from './WithdrawalRow.css';
import { WITHDRAWAL_AGE } from '../../util/constants';
import ButtonLink from '../ButtonLink/ButtonLink';

function WithdrawalRow({
  rpProvider,
  dispatchWithdrawalLinkClicked,
  inProgressWithdrawalInstructions,
  inProgressWithdrawalLumpSumInstructions,
  withdrawalLumpSumWithinCoolOffPeriod,
}) {
  const isAegonCustomer = rpProvider === 'aegon';

  let showWithdrawalButtonOrLink;

  if (withdrawalLumpSumWithinCoolOffPeriod) {
    showWithdrawalButtonOrLink = (
      <div className={withdrawalRowStyles.contactPensionAdviserText}>
        <h3 className={styles.body}>
          {'If you wish to discuss further withdrawals, please '}
          <ButtonLink
            variant="primary"
            link={'/inbox/new-message?subject=Withdrawal%20Request'}
            label={'contact your Pension Adviser'}
            font="inherit"
          />
          {'.'}
        </h3>
      </div>
    );
  } else if (inProgressWithdrawalLumpSumInstructions.length) {
    showWithdrawalButtonOrLink = (
      <div className={withdrawalRowStyles.contactPensionAdviserText}>
        <h3 className={styles.body}>
          {'We’re already processing a withdrawal for you. If you wish to make further requests, please '}
          <ButtonLink
            variant="primary"
            link={'/inbox/new-message?subject=Withdrawal%20Request'}
            label={'contact your Pension Adviser'}
            font="inherit"
          />
          {'.'}
        </h3>
      </div>
    );
  } else {
    showWithdrawalButtonOrLink = (
      <Button
        link="/withdraw-money-from-your-pension"
        onClick={dispatchWithdrawalLinkClicked}
        label="- Withdraw"
      />
    );
  }

  return (
    <div className={styles.addPensions}>
      <h3 className={styles.heading}>{'Withdraw money from your pension'}</h3>
      <p className={styles.body}>{`As you are over ${WITHDRAWAL_AGE}, you can access your pension flexibly including up to 25% tax free cash.`}</p>
      {Boolean(inProgressWithdrawalInstructions.length) && (
      <div className={pensionRowStyles.instructionStatusWrapper}>
        <img src={GrayDash} alt="Pending icon" />
        <p className={pensionRowStyles.subText}>{'We are currently processing your withdrawal'}</p>
      </div>
      )}
      {
        isAegonCustomer
          ? (
            showWithdrawalButtonOrLink
          )
          : (
            <div className={withdrawalRowStyles.contactPensionAdviserText}>
              <h3 className={styles.body}>
                {'If you want to withdraw money from your pension, please '}
                <ButtonLink
                  variant="primary"
                  link={'/inbox/new-message?subject=Withdrawal%20Request'}
                  label={'contact your Pension Adviser'}
                  font="inherit"
                />
                {'.'}
              </h3>
            </div>
          )
      }
    </div>
  );
}

const mapStateToProps = ((state) => ({
  inProgressWithdrawalInstructions: getInProgressWithdrawalInstructions(state),
  inProgressWithdrawalLumpSumInstructions: getInProgressWithdrawalLumpSumInstructions(state),
  withdrawalLumpSumWithinCoolOffPeriod: getIsWithdrawalLumpSumWithinCoolOffPeriod(state),
}));

const mapDispatchToProps = ({
  dispatchWithdrawalLinkClicked: withdrawalLinkClicked,
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalRow);
