import React from 'react';
import classnames from 'classnames';
import styles from './InitialIcon.css';

const InitialIcon = ({
  initial,
  colour,
  size,
  wrapperStyles,
}) => {
  return (
    <div
      className={
      classnames(
        styles.container,
        {
          [styles.interfaceFeedbackTipRegular]: colour === 'interfaceFeedbackTipRegular',
          [styles.lavender]: colour === 'lavender',
          [styles.satsuma]: colour === 'satsuma',
          [styles.cobalt]: colour === 'cobalt',
          [styles.lime]: colour === 'lime',
          [styles.small]: size === 's',
          [styles.medium]: size === 'm',
          [styles.large]: size === 'l',
        },
      )
    }
      style={wrapperStyles}
    >
      <div
        className={
          classnames(
            styles.initial,
            {
              [styles.small]: size === 's',
              [styles.medium]: size === 'm',
              [styles.large]: size === 'l',
            },
          )
        }
      >
        {initial}

      </div>
    </div>
  );
};

InitialIcon.defaultProps = {
  wrapperStyles: {},
};

export default InitialIcon;
