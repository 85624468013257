import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  TWO_FACTOR_AUTH_SMS_FULFILL,
  FBLoginReject,
  FBLogin,
} from '../modules/auth';

const loginEpic = (action$) => action$.ofType(
  TWO_FACTOR_AUTH_SMS_FULFILL,
).pipe(
  map((action) => FBLogin(action.authToken)),
  catchError((error) => of(FBLoginReject(error))),
);

export default combineEpics(
  loginEpic,
);
