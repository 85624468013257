import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';

import {
  nextClickedAction,
  backClickedAction,
  withdrawalOtherOptionsStepVisitedAction,
} from '../../redux/modules/withdrawal';

import WithdrawalOtherOptionsImage from '../../assets/images/withdrawals/three-book-stack.svg';

import styles from './WithdrawalPage.css';

const WithdrawalOtherOptions = ({
  step,
  totalSteps,
  setStep,
  dispatchWithdrawalOtherOptionsStepVisited,
  dispatchNextClicked,
  dispatchBackClicked,
}) => {
  useEffect(() => {
    dispatchWithdrawalOtherOptionsStepVisited();
  }, []);

  return (
    <BaseGrid addPensionsTheme>
      <div className={styles.withdrawalsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          onClick={(event) => {
            dispatchBackClicked();
            setStep((_step) => _step - 1);
            event.stopPropagation();
          }}
          label="← Back"
        />
        <div className={styles.withdrawalsImageContainer}>
          <img style={{ maxWidth: '225px' }} src={WithdrawalOtherOptionsImage} alt="books" />
        </div>
        <h2 className={styles.headingTwo}>{'Have you looked at other options to raise the cash you need or have you considered reducing the amount of your withdrawal?'}</h2>
        <p className={styles.body}>{'Taking money from your pension now will reduce the value of your pension pot, meaning you will have less money to generate a retirement income in the future.'}</p>
        <p className={styles.greyBox}>{'When taking money now you should carefully consider how you will fund your retirement should the funds in your pension run out.'}</p>
        <br />
        <div className={styles.pensionAdviserContainer}>
          <div className={styles.pensionAdviserCard}>
            <ContactUsContent
              source={'WithdrawalOtherOptionsStep'}
              noExpertContentWrapper={({ children }) => (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexShrink: 'initial',
                  padding: '10px',
                  flexWrap: 'wrap',
                }}
                >
                  {children}
                </div>
              )}
              showButton={false}
              customMessage={(
                <div className={styles.contactUsInnerContent}>
                  {'When markets fall, it may not be the best time to withdraw money from your pension pot, as you are crystallising any losses. If you have any questions, please feel free to '}
                  <span>
                    <ButtonLink
                      variant="primary"
                      displayAsHref
                      to="/inbox/new-message"
                      search={'?subject=Withdrawal%20Request'}
                      font="inherit"
                      label={'contact your Pension Adviser'}
                    />
                    {'.'}
                  </span>
                </div>
              )}
            />
          </div>
        </div>
        <Button
          size="large"
          label={'I understand'}
          onClick={() => {
            dispatchNextClicked();
            setStep((_step) => _step + 1);
          }}
        />
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  dispatchWithdrawalOtherOptionsStepVisited: withdrawalOtherOptionsStepVisitedAction,
  dispatchNextClicked: nextClickedAction,
  dispatchBackClicked: backClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalOtherOptions);
