/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
import get from 'lodash/get';
import {
  getAuthoritativeRetirementPlan,
  getIsNewOngoingCdsRequiredAndAllowed,
  getIsPersonalDetailsRequired,
  getRecentlyCompletedCds,
  getTwoFactorAuthenticationEnabled,
} from '.';
import {
  getIsCustomerOngoing,
  getIsCustomerOngoingMissingRp,
  getIsCustomerOptout,
} from './experience';
import {
  getCompleteAndUnseenContributionInstructions,
  getCompleteAndUnseenWithdrawalInstructions,
  getHasInProgressContributionInstruction,
  getHasInProgressPensions,
  getHasInProgressOneOffContributionInstructionId,
  getHasInProgressLumpSumInstructions,
} from './investmentAdvice';
import { getIsNotAUkTaxResident, getIsOngoingCdsAllowed, getIsAuthoriseChangeAllowed } from './journeys';
import {
  getShowMarketingPreferencesNotification,
  getAuthoritativePensionPlanNotifications,
  getHasIntroModalBeenDismissed,
  getHasMay2023DrawdownUpdateNotificationBeenDismissed,
  getHasLumpSumNotificationBeenDismissed,
  getHasCompletePensionRequested,
  getHasCompletePensionRequestedDismissed,
  getHasCompleteContributionRequested,
  getHasCompleteContributionRequestedDismissed,
  getHasSERPSPensionFoundUpdateBeenDismissed,
  getHasSerpsWaitingForHmrcBeenDismissed,
  getHasSERPSNoPensionsFoundUpdateBeenDismissed,
  getHasSERPSCompleteRequestBeenDismissed,
  getHasSERPSTraceIneligibleBeenDismissed
} from './notificationTracking';
import {
  getCurrentPensionPlanRecommendation,
  getHasRecommendationToAcceptAndNoReport,
  getOngoingServiceFromOnboarding,
  getHasRecommendationToAcceptAndReport,
} from './ongoingService';
import { getIsVulnerableCustomer } from './vulnerableCustomer';
import { getPensionsReadyToAuthorise } from './pensions';
import {
  PENSION_PLAN_RECOMMENDATION_CURRENT_STEPS as CURRENT_STEP,
  PENSION_PLAN_OVERALL_STATUSES as OVERALL_STATUS,
  PROVIDERS,
} from '../../util/constants';
import {
  getHmrcResponseReceivedAndPensionsAreEligible,
  getWaitingForHmrcResponse,
  getMissingDetailsCompleteAndNegativeHmrcResponseReceived,
  getCustomerEligibleForSerps,
  getSerpsMissingDetailsComplete,
  getCustomerAgeEligibleForSerps,
  getWasSerpsTraceRequested,
} from './serps';

/*
  Notifications should be shown in the following order: RED || BLUE > GREEN
  In the instance where a red notification needs to be displayed, no other
  notifications should be displayed. Blue and green notifications can be
  displayed at the same time.
*/

/**
 *  Category: Red - Immediate action,
 *  Purpose: Unable to show pension details & No authorative pension plan
 */
export const getShouldShowMissingRPOngoingNotification = (state) => {
  return getIsCustomerOngoingMissingRp(state);
};

/**
 *  Category: Red - Immediate action,
 *  Purpose: Vulnerable customer
 */
export const getShouldShowVCNotification = (state) => {
  const isOngoingCdsAllowed = getIsOngoingCdsAllowed(state);
  const isCustomerOptOut = getIsCustomerOptout(state);
  const isVulnerableCustomer = getIsVulnerableCustomer(state);
  return !isOngoingCdsAllowed && !isCustomerOptOut && isVulnerableCustomer;
};

/**
 *  Category: Red - Immediate action,
 *  Purpose: UK residency fail
 */
export const getShouldShowTaxResidencyNotification = (state) => {
  const isNotAUkTaxResident = getIsNotAUkTaxResident(state);
  const isCustomerOngoing = getIsCustomerOngoing(state);
  const isOngoingCdsAllowed = getIsOngoingCdsAllowed(state);
  return isNotAUkTaxResident && isCustomerOngoing && !isOngoingCdsAllowed;
};

/**
 *  Category: Red - Immediate action,
 *  Purpose: Missing details
 */
export const getShouldShowMissingDetailsNotification = (state) => {
  const hasInProgressPensions = getHasInProgressPensions(state);
  const hasInProgressContributionInstruction = getHasInProgressContributionInstruction(state);
  const hasInProgressLumpSumInstructions = getHasInProgressLumpSumInstructions(state);
  const isPersonalDetailsRequired = getIsPersonalDetailsRequired(state);
  return (hasInProgressPensions
    || hasInProgressContributionInstruction
    || hasInProgressLumpSumInstructions)
    && isPersonalDetailsRequired;
};

/**
 *  Category: Blue - Informative and CDS action,
 *  Purpose: Pensions Ready to authorise
 */
export const getShouldShowPensionsReadyToAuthoriseNotification = (state) => {
  const pensionsReadyToAuthorise = getPensionsReadyToAuthorise(state);
  const nonSerpsPensionsToAuthorise = pensionsReadyToAuthorise.filter((pension) => pension.source !== 'hmrc');
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state);
  return additionalRules && Boolean(nonSerpsPensionsToAuthorise.length);
};

/**
 *  Category: Blue - Informative and SERPS,
 *  Purpose: SERPS complete request
 */
 export const getShouldShowSERPSCompleteRequestNotification = (state) => {
  const hasBannerBeenDismissed = getHasSERPSCompleteRequestBeenDismissed(state);
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state);
  const showCompleteRequestBanner = getCustomerEligibleForSerps(state) && !getSerpsMissingDetailsComplete(state);
  return additionalRules
  && !hasBannerBeenDismissed
  && showCompleteRequestBanner;
};

/**
 *  Category: Blue - Informative and SERPS,
 *  Purpose: SERPS Trace ineligible
 */
 export const getShouldShowSERPSTraceIneligibleNotification = (state) => {
  const hasBannerBeenDismissed = getHasSERPSTraceIneligibleBeenDismissed(state);
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state);
  return additionalRules
  && !hasBannerBeenDismissed
  && !getCustomerAgeEligibleForSerps(state) && getWasSerpsTraceRequested(state);
};
/**
 *  Category: Blue - Informative and SERPS,
 *  Purpose: SERPS Waiting for HMRC response
 */
export const getShouldShowSERPSWaitingForHmrcResponseNotification = (state) => {
  const hasBannerBeenDismissed = getHasSerpsWaitingForHmrcBeenDismissed(state);
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state);
  const waitingForHmrcResponse = getWaitingForHmrcResponse(state);
  return additionalRules
  && !hasBannerBeenDismissed
  && waitingForHmrcResponse;
};

/**
 *  Category: Blue - Informative and SERPS,
 *  Purpose: SERPS No Pensions Found Update
 */
export const getShouldShowNoSERPSPensionsFoundNotification = (state) => {
  const hasBannerBeenDismissed = getHasSERPSNoPensionsFoundUpdateBeenDismissed(state);
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state);
  const missingDetailsCompleteAndNegativeHmrcResponseReceived = getMissingDetailsCompleteAndNegativeHmrcResponseReceived(state);
  return additionalRules && !hasBannerBeenDismissed && missingDetailsCompleteAndNegativeHmrcResponseReceived;
};

/**
 *  Category: Blue - Informative and SERPS,
 *  Purpose: SERPS Pension(s) Found Update
 */
export const getShouldShowSERPSPensionFoundNotification = (state) => {
  const hasBannerBeenDismissed = getHasSERPSPensionFoundUpdateBeenDismissed(state);
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state);
  const hmrcResponseReceivedAndPensionsAreEligible = getHmrcResponseReceivedAndPensionsAreEligible(state);
  return additionalRules
  && !hasBannerBeenDismissed
  && hmrcResponseReceivedAndPensionsAreEligible;
};

/**
 *  Category: Green - Status update,
 *  Purpose: Regular contributions update
 */
export const getShouldShowRegularContributionsUpdateNotification = (state) => {
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state);
  return additionalRules && Boolean(getCompleteAndUnseenContributionInstructions(state).length);
};

/**
 *  Category: Green - Status update,
 *  Purpose: Withdrawal update
 */
export const getShouldShowWithdrawalUpdateNotification = (state) => {
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state);
  return additionalRules && Boolean(getCompleteAndUnseenWithdrawalInstructions(state).length);
};

/**
 * Category: Green - Status update,
 * Purpose: One-off contribution update
 */
export const getShouldShowNewOneOffLumpSumNotification = (state) => {
  const instructionId = getHasInProgressOneOffContributionInstructionId(state);
  const hasBannerBeenDismissed = getHasLumpSumNotificationBeenDismissed(state, instructionId);
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state);
  const hasInstructionId = !hasBannerBeenDismissed ? instructionId : null;
  return additionalRules && hasInstructionId;
};

// --- OngoingStatusSection --- //

/**
 *  Category: Blue - Informative and CDS action,
 *  Purpose: MarketingPreferences
 */
export const getShouldShowMarketingPreferencesNotification = (state) => {
  const additionalRules = !(
    getShouldShowUpdateCDSNotification(state)
    || getShouldShowUpdatingPensionPlanNotification(state)
    || getShouldShowUpdatePensionPlanNotification(state)
    || getShouldShowMetlifeCustomerNotification(state)
    || getShouldShowPensionsReadyToAuthoriseNotification(state)
    || getIsAnyRedCategoryNotificationActive(state)
  );
  return additionalRules && getShowMarketingPreferencesNotification(state);
};

/**
 *  Category: Blue - Informative and CDS action,
 *  Purpose: Metlife customer
 */
export const getShouldShowMetlifeCustomerNotification = (state) => {
  const ongoingService = getOngoingServiceFromOnboarding(state);
  const authoritativeRp = getAuthoritativeRetirementPlan(state);
  const isCDSAllowed = get(ongoingService, 'isNewCdsAllowed');
  const provider = get(authoritativeRp, 'provider');
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state);
  return additionalRules && (!isCDSAllowed && provider === PROVIDERS.METLIFE);
};

/**
 *  Category: Blue - Informative and CDS action,
 *  Purpose: Update CDS
 */
export const getShouldShowUpdateCDSNotification = (state) => {
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state);
  return additionalRules && getIsOngoingCdsAllowed(state);
};

/**
 *  Category: Blue - Informative and CDS action,
 *  Purpose: 2023 portfolios changed info
 */
export const getShouldShowMay2023DrawdownUpdateNotification = (state) => {
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state)
    && !getHasMay2023DrawdownUpdateNotificationBeenDismissed(state)
    && getHasIntroModalBeenDismissed(state);

  return additionalRules;
};

/**
 *  Category: Blue - Informative and CDS action,
 *  Purpose: Update pension plan
 */
export const getShouldShowUpdatePensionPlanNotification = (state) => {
  const isAuthoriseChangeAllowed = getIsAuthoriseChangeAllowed(state);
  const hasRecommendationToAccept = getHasRecommendationToAcceptAndReport(state);
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state);
  return additionalRules && (isAuthoriseChangeAllowed && hasRecommendationToAccept);
};

/**
 *  Category: Blue - Informative and CDS action,
 *  Purpose: Updating pension plan
 */
export const getShouldShowUpdatingPensionPlanNotification = (state) => {
  const currentPensionPlanRecommendation = getCurrentPensionPlanRecommendation(state);
  const currentStep = get(currentPensionPlanRecommendation, 'currentStep');
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state);
  return additionalRules && (
    currentStep === CURRENT_STEP.CHANGE_SUBMITTED
    || currentStep === CURRENT_STEP.CHANGE_ACCEPTED
  );
};

/**
 *  Category: Blue - Informative and CDS action,
 *  Purpose: Enable 2FA
 */
export const getShouldShowTwoFactorAuthenticationNotification = (state) => {
  const ongoing = getIsCustomerOngoing(state);
  const hasTwoFactorAuthenticationEnabled = getTwoFactorAuthenticationEnabled(state);
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state);

  return additionalRules && ongoing && !hasTwoFactorAuthenticationEnabled;
};

/**
 *  Category: Blue - Informative and CDS action,
 *  Purpose: Complete Pension Request
 */
export const getShouldShowCompletePensionRequestNotification = (state) => {
  const ongoing = getIsCustomerOngoing(state);
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state)
  && getHasCompletePensionRequested(state) && !getHasCompletePensionRequestedDismissed(state);

  return additionalRules && ongoing;
};

/**
 *  Category: Blue - Informative and CDS action,
 *  Purpose: Complete Contribution Request
 */
export const getShouldShowCompleteContributionRequestNotification = (state) => {
  const ongoing = getIsCustomerOngoing(state);
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state)
  && getHasCompleteContributionRequested(state)
  && !getHasCompleteContributionRequestedDismissed(state);
  return additionalRules && ongoing;
};

/**
 *  Category: Green - Status update,
 *  Purpose: Recommended pension plan
 */
export const getShouldShowRecPensionPlanNotification = (state) => {
  const currentPensionPlanRecommendation = getCurrentPensionPlanRecommendation(state);
  const hasRecentlyCompletedCds = getRecentlyCompletedCds(state);
  const isNewOngoingCdsRequiredAndAllowed = getIsNewOngoingCdsRequiredAndAllowed(state);
  const hasRecToAcceptAndNoReport = getHasRecommendationToAcceptAndNoReport(state);
  const currentStep = get(currentPensionPlanRecommendation, 'currentStep');
  const overallStatus = get(currentPensionPlanRecommendation, 'overallStatus');
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state);
  return additionalRules && (
    currentStep === CURRENT_STEP.OPEN
    || (
      overallStatus === OVERALL_STATUS.BLOCKED
      && currentStep !== CURRENT_STEP.CHANGE_READY_TO_ACCEPT
      && currentStep !== CURRENT_STEP.CHANGE_ACCEPTED)
    || (hasRecentlyCompletedCds && isNewOngoingCdsRequiredAndAllowed)
    || hasRecToAcceptAndNoReport
  );
};

/**
 *  Category: Green - Status update,
 *  Purpose: No switch banner
 */
export const getShouldShowNoSwitchBannerNotification = (state) => {
  const currentPensionPlanRecommendation = getCurrentPensionPlanRecommendation(state);
  const authoritativePensionPlanNotifications = getAuthoritativePensionPlanNotifications(
    state,
    currentPensionPlanRecommendation,
  );
  const currentStep = get(currentPensionPlanRecommendation, 'currentStep');
  const additionalRules = !getIsAnyRedCategoryNotificationActive(state)
  && !getIsOngoingCdsAllowed(state);
  return additionalRules && (
    (currentStep === CURRENT_STEP.NO_CHANGE_COMPLETED)
    && get(authoritativePensionPlanNotifications, 'no-change-completed') !== 'closed');
};

// Red category notifications
const getIsAnyRedCategoryNotificationActive = (state) => {
  return getShouldShowMissingRPOngoingNotification(state)
    || getShouldShowVCNotification(state)
    || getShouldShowTaxResidencyNotification(state)
    || getShouldShowMissingDetailsNotification(state);
};

export const getCurrentlyActiveNotifications = (state) => {
  // getShouldShowNewOneOffLumpSumNotification returns an instruction ID if true

  const allNotifications = [
    { notification: 'missingRPOngoingNotificationIsActive', value: getShouldShowMissingRPOngoingNotification(state) },
    { notification: 'vcNotificationIsActive', value: getShouldShowVCNotification(state) },
    { notification: 'taxResidencyNotificationIsActive', value: getShouldShowTaxResidencyNotification(state) },
    { notification: 'missingDetilsNotificationIsActive', value: getShouldShowMissingDetailsNotification(state) },
    { notification: 'marketingPreferencesNotificationIsActive', value: getShouldShowMarketingPreferencesNotification(state) },
    { notification: 'pensionsReadyToAuthoriseNotificationIsActive', value: getShouldShowPensionsReadyToAuthoriseNotification(state) },
    { notification: 'regularContributionsUpdateNotificationIsActive', value: getShouldShowRegularContributionsUpdateNotification(state) },
    { notification: 'withdrawalUpdateNotificationIsActive', value: getShouldShowWithdrawalUpdateNotification(state) },
    { notification: 'metLifeCustomerNotificationIsActive', value: getShouldShowMetlifeCustomerNotification(state) },
    { notification: 'updateCDSNotificationIsActive', value: getShouldShowUpdateCDSNotification(state) },
    { notification: 'updatePensionPlanNotificationIsActive', value: getShouldShowUpdatePensionPlanNotification(state) },
    { notification: 'updatingPensionPlanNotificationIsActive', value: getShouldShowUpdatingPensionPlanNotification(state) },
    { notification: 'recPensionPlanNotificationIsActive', value: getShouldShowRecPensionPlanNotification(state) },
    { notification: 'noSwitchBannerNotificationIsActive', value: getShouldShowNoSwitchBannerNotification(state) },
    { notification: 'may2023DrawdownUpdateNotificationIsActive', value: getShouldShowMay2023DrawdownUpdateNotification(state) },
    { notification: 'twoFactorAuthenticationNotificationIsActive', value: getShouldShowTwoFactorAuthenticationNotification(state) },
    { notification: 'lumpSumNotificatonIsActive', value: getShouldShowNewOneOffLumpSumNotification(state) },
    { notification: 'completePensionRequestNotificationIsActive', value: getShouldShowCompletePensionRequestNotification(state) },
    { notification: 'completeContributionRequestNotificationIsActive', value: getShouldShowCompleteContributionRequestNotification(state) },
    { notification: 'serpsWaitingForHmrcResponseNotification', value: getShouldShowSERPSWaitingForHmrcResponseNotification(state) },
    { notification: 'serpsPensionFoundNotification', value: getShouldShowSERPSPensionFoundNotification(state) },
    { notification: 'serpsNoPensionsFoundNotification', value: getShouldShowNoSERPSPensionsFoundNotification(state) },
  ];

  const getAllActiveNotifications = [];
  allNotifications.forEach((notif) => {
    if (notif.value) {
      getAllActiveNotifications.push(notif.notification);
    }
  });

  return getAllActiveNotifications;
};

export const getIsAnyNotificationActive = (state) => {
  return getCurrentlyActiveNotifications(state).length > 0;
};
