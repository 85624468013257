/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../Button/Button';
import PensionRows from '../DashboardRow/PensionRows';
import ButtonLink from '../ButtonLink/ButtonLink';
import { getPensionExpert } from '../../redux/selectors/investmentAdvice';
import { getAllNonSerpsPensionsAndAuthorisedSerpsPensions } from '../../redux/selectors/pensions';
import {
  serpsNextStepsMissingDetailsAddPensionClicked,
  serpsNextStepsMissingDetailsAddMoneyClicked,
  serpsNextStepsTraceReceivedAddPensionClicked,
  serpsNextStepsTraceReceivedAddMoneyClicked,
  serpsNextStepsPensionsFoundAddPensionClicked,
  serpsNextStepsPensionsFoundAddMoneyClicked,
  serpsNextStepsPensionsNotFoundAddPensionClicked,
  serpsNextStepsPensionsNotFoundAddMoneyClicked,
  serpsNextStepsPensionsAuthorisedAddPensionClicked,
  serpsNextStepsPensionsAuthorisedAddMoneyClicked,
  serpsNextStepsDOBNotEligibleAddPensionClicked,
  serpsNextStepsDOBNotEligibleAddMoneyClicked,
  serpsNextStepsAddMoneyClicked,
  serpsNextStepsAddPensionsClicked,
} from '../../redux/modules/serps';

import ThreeCircles from '../../assets/images/rebranding/tri-tone-overlapping-circles.svg';
import ContributionIcon from '../../assets/images/rebranding/add-money.svg';
import EllipseCircleGreyTick from '../../assets/images/icons/EllipseCircleGreyTick.svg';
import Tick from '../../assets/images/icons/smallGreenTick.svg';
import styles from './SERPSNextSteps.css';

const PensionAdviserContent = ({ pensionExpert, advisorCopy }) => {
  return (
    <div className={styles.advisorContainer}>
      <div className={styles.advisorHeadingRow}>
        <img className={styles.advisorImage} src={get(pensionExpert, 'imageUrl')} alt="Pension adviser" />
        <h3 className={styles.advisorHeading}>{`Hello, I’m ${get(pensionExpert, 'firstName')}, your Adviser`}</h3>
      </div>
      <p className={styles.advisorText}>
        {advisorCopy}
        <ButtonLink
          variant="primary"
          label={'contact me.'}
          to="/inbox/new-message"
          font="inherit"
          displayAsHref
        />
      </p>
    </div>
  );
};

const HeadingOnlyStep = ({
  number, stepComplete, headingText, showLink = false,
}) => {
  const boxStyles = stepComplete ? styles.stepBoxComplete : styles.stepBoxIncomplete;
  return (
    <div className={styles.stepRow}>
      <div className={styles.stepNumber}>
        <span className={classNames({
          [styles.grassCircle]: stepComplete,
          [styles.greyCircle]: !stepComplete,
        })}
        >
          {`${number}`}
        </span>
      </div>
      <div className={boxStyles}>
        <div className={styles.boxHeadingContainer}>
          <h4 className={[styles.stepHeading, styles.thickFogText].join(' ')}>
            {headingText}
          </h4>
          { stepComplete && <img className={styles.greenTickIcon} src={Tick} alt="" />}
        </div>
        {showLink && (
        <div className={styles.pensionPlanLink}>
          <ButtonLink
            variant="primary"
            label={'Read your plan'}
            to="/plan-summary"
            font="inherit"
            displayAsHref
          />
        </div>
        )}
      </div>
    </div>
  );
};

const FindAndCombineOrContributionStep = ({
  number,
  stepComplete,
  hasAuthorisedSerpsPensionsAndNonSerpsPensions,
  dispatchSerpsAddPensionsClicked,
  dispatchSerpsAddMoneyClicked,
}) => {
  return (
    <React.Fragment>
      <div className={styles.stepRow}>
        <div className={styles.stepNumber}>
          <span className={styles.clearCircle} />
        </div>
        <div className={classNames({
          [styles.findAndCombineStepBoxComplete]: stepComplete,
          [styles.findAndCombineStepBoxIncomplete]: !stepComplete,
        })}
        >
          <div className={styles.stepHeadingContainer}>
            <img src={ThreeCircles} alt="Overlapping circles" />
            <h4 className={styles.stepHeading}>{'Find and combine old pensions'}</h4>
          </div>
          {!hasAuthorisedSerpsPensionsAndNonSerpsPensions && (
            <p className={styles.stepBodyText}>
              {'The average person has 11 workplace jobs in their lifetime. We can help you to find and combine any other old pensions and consolidate into your Profile Pension plan, making sure you benefit from ongoing investment advice, dedicated support and whole of market fund selection.'}
            </p>
          )}
          <div className={styles.buttonContainer}>
            <Button
              label="Add Pensions"
              size="large"
              to="/add-pensions"
              onClick={() => {
                dispatchSerpsAddPensionsClicked();
              }}
            />
          </div>
          {hasAuthorisedSerpsPensionsAndNonSerpsPensions && (
            <div className={styles.pensionWrapper}>
              <h4 className={styles.stepSubHeading}>{'Pensions'}</h4>
              <PensionRows hmrcResponseEligible />
            </div>
          )}
        </div>
      </div>
      <div className={styles.stepRow}>
        <div className={styles.stepNumber}>
          <span className={classNames(styles.greyCircle)}>
            {`${number}`}
          </span>
        </div>
        <h4 className={styles.nextStepsOrDivider}>
          {'OR'}
        </h4>
      </div>
      <div className={styles.stepRow}>
        <div className={styles.stepNumber}>
          <span className={styles.clearCircle} />
        </div>
        <div className={classNames({
          [styles.findAndCombineStepBoxComplete]: stepComplete,
          [styles.findAndCombineStepBoxIncomplete]: !stepComplete,
        })}
        >
          <div className={styles.stepHeadingContainer}>
            <img src={ContributionIcon} alt="Pound and plus sign" />
            <h4 className={styles.stepHeading}>{'Make contributions'}</h4>
          </div>
          <p className={styles.stepBodyText}>
            {'The more you add into your pension now, the more you’ll have for your retirement. If you can afford  it, you may want to consider setting up regular contributions. You could also benefit from tax relief (25% top-up for non or basic rate taxpayers).'}
          </p>
          <div className={styles.buttonContainer}>
            <Button
              label="Add money"
              size="large"
              to="/add-money-option"
              onClick={() => {
                dispatchSerpsAddMoneyClicked();
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const GrowYourPensionStep = () => (
  <div className={styles.stepRow}>
    <div className={styles.stepNumber}>
      <span className={classNames(styles.clearCircle)}>
        <div>
          <img className={styles.greyTickIcon} src={EllipseCircleGreyTick} alt="Grey Tick" />
        </div>
      </span>
    </div>
    <div className={styles.findAndCombineStepBoxIncomplete}>
      <h5 className={styles.stepBoldBodyText}>
        {'Grow your pension in the best plan for you from the whole of the market'}
      </h5>
    </div>
  </div>
);

const SERPSNextSteps = ({
  pensionExpert,
  showMissingDetailsCta,
  showWaitingForHmrcResponseCta,
  showHmrcResponseEligiblePensionsYesCta,
  showPostSerpsPensionsAuthorisationCta,
  showNegativeHmrcResponse,
  showNotEligibleForSerpsTraceCta,
  showPensionAdvisorContent,
  allNonSerpsPensionsAndAuthorisedSerpsPensions,
  showReadYourPlanLink,
  noHmrcPensionsFound,
  dispatchSerpsNextStepsMissingDetailsAddPensionClicked,
  dispatchSerpsNextStepsMissingDetailsAddMoneyClicked,
  dispatchSerpsNextStepsTraceReceivedAddPensionClicked,
  dispatchSerpsNextStepsTraceReceivedAddMoneyClicked,
  dispatchSerpsNextStepsPensionsFoundAddPensionClicked,
  dispatchSerpsNextStepsPensionsFoundAddMoneyClicked,
  dispatchSerpsNextStepsPensionsNotFoundAddPensionClicked,
  dispatchSerpsNextStepsPensionsNotFoundAddMoneyClicked,
  dispatchSerpsNextStepsPensionsAuthorisedAddPensionClicked,
  dispatchSerpsNextStepsPensionsAuthorisedAddMoneyClicked,
  dispatchSerpsNextStepsDOBNotEligibleAddPensionClicked,
  dispatchSerpsNextStepsDOBNotEligibleAddMoneyClicked,
  dispatchSerpsNextStepsAddMoneyClicked,
  dispatchSerpsNextStepsAddPensionsClicked,
}) => {
  const calculateSerpsStepsEvents = () => {
    if (showWaitingForHmrcResponseCta) {
      return {
        dispatchSerpsAddPensionsClicked: dispatchSerpsNextStepsTraceReceivedAddPensionClicked,
        dispatchSerpsAddMoneyClicked: dispatchSerpsNextStepsTraceReceivedAddMoneyClicked,
      };
    }
    if (showMissingDetailsCta) {
      return {
        dispatchSerpsAddPensionsClicked: dispatchSerpsNextStepsMissingDetailsAddPensionClicked,
        dispatchSerpsAddMoneyClicked: dispatchSerpsNextStepsMissingDetailsAddMoneyClicked,
      };
    }
    if (showHmrcResponseEligiblePensionsYesCta) {
      return {
        dispatchSerpsAddPensionsClicked: dispatchSerpsNextStepsPensionsFoundAddPensionClicked,
        dispatchSerpsAddMoneyClicked: dispatchSerpsNextStepsPensionsFoundAddMoneyClicked,
      };
    }
    if (noHmrcPensionsFound) {
      return {
        dispatchSerpsAddPensionsClicked: dispatchSerpsNextStepsPensionsNotFoundAddPensionClicked,
        dispatchSerpsAddMoneyClicked: dispatchSerpsNextStepsPensionsNotFoundAddMoneyClicked,
      };
    }
    if (showNotEligibleForSerpsTraceCta) {
      return {
        dispatchSerpsAddPensionsClicked: dispatchSerpsNextStepsDOBNotEligibleAddPensionClicked,
        dispatchSerpsAddMoneyClicked: dispatchSerpsNextStepsDOBNotEligibleAddMoneyClicked,
      };
    }
    if (showPostSerpsPensionsAuthorisationCta) {
      return {
        dispatchSerpsAddPensionsClicked: dispatchSerpsNextStepsPensionsAuthorisedAddPensionClicked,
        dispatchSerpsAddMoneyClicked: dispatchSerpsNextStepsPensionsAuthorisedAddMoneyClicked,
      };
    }
    return {
      dispatchSerpsAddPensionsClicked: dispatchSerpsNextStepsAddPensionsClicked,
      dispatchSerpsAddMoneyClicked: dispatchSerpsNextStepsAddMoneyClicked,
    };
  };

  const steps = [
    (number) => (
      <HeadingOnlyStep
        number={number}
        stepComplete
        headingText={'Sign Up'}
      />
    ),
    (number) => (
      <HeadingOnlyStep
        number={number}
        stepComplete={!showMissingDetailsCta}
        headingText={'Discover your recommended pension plan'}
        showLink={showReadYourPlanLink}
      />
    ),
    (number) => (
      <FindAndCombineOrContributionStep
        stepComplete={!showMissingDetailsCta}
        hasAuthorisedSerpsPensionsAndNonSerpsPensions={allNonSerpsPensionsAndAuthorisedSerpsPensions && allNonSerpsPensionsAndAuthorisedSerpsPensions.length > 0}
        number={number}
        dispatchSerpsAddPensionsClicked={calculateSerpsStepsEvents().dispatchSerpsAddPensionsClicked}
        dispatchSerpsAddMoneyClicked={calculateSerpsStepsEvents().dispatchSerpsAddMoneyClicked}
      />
    ),
    () => <GrowYourPensionStep />,
  ];

  if (!showNotEligibleForSerpsTraceCta) {
    steps.splice(1, 0, (number) => <HeadingOnlyStep number={number} stepComplete={!showMissingDetailsCta} headingText={'SERPS Trace'} />);
  }

  let advisorCopy = 'Let’s track down your SERPS pension and see if you can increase your income at retirement, just follow the steps below. Green ticks show the steps you’ve already completed. If you have questions about your pension, please feel free to ';
  if (showWaitingForHmrcResponseCta) {
    advisorCopy = 'If you have questions about your pension, please feel free to ';
  }
  if (showHmrcResponseEligiblePensionsYesCta || showPostSerpsPensionsAuthorisationCta) {
    advisorCopy = 'Let’s track your SERPS pension and increase your income at retirement, just follow the steps below. If you have any questions about your pension, please feel free to ';
  }
  if (showNegativeHmrcResponse || showNotEligibleForSerpsTraceCta) {
    advisorCopy = 'Let’s track your old workplace pensions or set up contributions to increase your income at retirement, just follow the steps below. If you have any questions about your pension, please feel free to ';
  }

  return (
    <React.Fragment>
      {showPensionAdvisorContent
      && (
      <PensionAdviserContent
        pensionExpert={pensionExpert}
        advisorCopy={advisorCopy}
      />
      )}
      <h3 className={styles.headingText}>{'Next Steps'}</h3>
      <div className={styles.nextStepsContainer}>
        {steps.map((step, index) => {
          return (
            <React.Fragment key={index}>
              {step(index + 1)}
            </React.Fragment>
          );
        })}
      </div>
    </React.Fragment>
  );
};

SERPSNextSteps.propTypes = {
  pensionExpert: PropTypes.shape({
    firstName: PropTypes.string,
    imageUrl: PropTypes.string,
  }).isRequired,
  allNonSerpsPensionsAndAuthorisedSerpsPensions: PropTypes.arrayOf(PropTypes.shape({
    policyName: PropTypes.string,
  })),
  showMissingDetailsCta: PropTypes.bool.isRequired,
  showWaitingForHmrcResponseCta: PropTypes.bool.isRequired,
  showHmrcResponseEligiblePensionsYesCta: PropTypes.bool.isRequired,
  showPostSerpsPensionsAuthorisationCta: PropTypes.bool.isRequired,
  showNegativeHmrcResponse: PropTypes.bool.isRequired,
  showNotEligibleForSerpsTraceCta: PropTypes.bool.isRequired,
  showReadYourPlanLink: PropTypes.bool,
};

SERPSNextSteps.defaultProps = {
  allNonSerpsPensionsAndAuthorisedSerpsPensions: [],
  authorisedPensions: [],
  showReadYourPlanLink: true,
};

const mapStateToProps = (state) => ({
  pensionExpert: getPensionExpert(state),
  allNonSerpsPensionsAndAuthorisedSerpsPensions: getAllNonSerpsPensionsAndAuthorisedSerpsPensions(state),
});

const mapDispatchToProps = ({
  dispatchSerpsNextStepsMissingDetailsAddPensionClicked: serpsNextStepsMissingDetailsAddPensionClicked,
  dispatchSerpsNextStepsMissingDetailsAddMoneyClicked: serpsNextStepsMissingDetailsAddMoneyClicked,
  dispatchSerpsNextStepsTraceReceivedAddPensionClicked: serpsNextStepsTraceReceivedAddPensionClicked,
  dispatchSerpsNextStepsTraceReceivedAddMoneyClicked: serpsNextStepsTraceReceivedAddMoneyClicked,
  dispatchSerpsNextStepsPensionsFoundAddPensionClicked: serpsNextStepsPensionsFoundAddPensionClicked,
  dispatchSerpsNextStepsPensionsFoundAddMoneyClicked: serpsNextStepsPensionsFoundAddMoneyClicked,
  dispatchSerpsNextStepsPensionsNotFoundAddPensionClicked: serpsNextStepsPensionsNotFoundAddPensionClicked,
  dispatchSerpsNextStepsPensionsNotFoundAddMoneyClicked: serpsNextStepsPensionsNotFoundAddMoneyClicked,
  dispatchSerpsNextStepsPensionsAuthorisedAddPensionClicked: serpsNextStepsPensionsAuthorisedAddPensionClicked,
  dispatchSerpsNextStepsPensionsAuthorisedAddMoneyClicked: serpsNextStepsPensionsAuthorisedAddMoneyClicked,
  dispatchSerpsNextStepsDOBNotEligibleAddPensionClicked: serpsNextStepsDOBNotEligibleAddPensionClicked,
  dispatchSerpsNextStepsDOBNotEligibleAddMoneyClicked: serpsNextStepsDOBNotEligibleAddMoneyClicked,
  dispatchSerpsNextStepsAddMoneyClicked: serpsNextStepsAddMoneyClicked,
  dispatchSerpsNextStepsAddPensionsClicked: serpsNextStepsAddPensionsClicked,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SERPSNextSteps));
