import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './AddPensionsPage.css';
import threeCircles from '../../assets/images/rebranding/tri-tone-overlapping-circles.svg';
import clock from '../../assets/images/clock.svg';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import Button from '../../components/Button/Button';
import {
  introStepLandedAction,
  introStepPensionClickedAction,
  returnLinkClickedAction,
} from '../../redux/modules/pensions';

import { getIsCustomerOngoing } from '../../redux/selectors/experience';

const IntroStep = ({
  setStep,
  dispatchIntroStepLanded,
  dispatchIntroStepPensionClicked,
  dispatchReturnLinkClicked,
  isCustomerOngoing,
  hideBackNavigation,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchIntroStepLanded(isCustomerOngoing);
  }, []);

  return (
    <BaseGrid tabletTwelveColumn messageWidget addPensionsTheme>
      <div className={styles.introStepContainer}>
        <div
          className={styles.intoBackContainer}
        >
          {!hideBackNavigation && (
            <ButtonLink
              onClick={() => {
                dispatchReturnLinkClicked(isCustomerOngoing);
              }}
              to="/"
              label="← Back"
            />
          )}
        </div>
        <div className={styles.introHeadingContainer}>
          <img src={threeCircles} alt="" className={styles.threeCircles} />
          <h2 className={styles.introHeading}>{'Find and combine old pensions'}</h2>
        </div>
        <p className={styles.introBody}>
          {'We can help you find and combine old pensions with just a few details. The more information you can give us the better, but we can help even if you only know your old employer or pension provider.'}
        </p>
        <p className={styles.introBody}>
          {'The best place to find your old pension information is on an old pension statement, if you have it.'}
        </p>
        <div className={styles.twoMinsContainer}>
          <img src={clock} alt="Clock" className={styles.clock} />
          <div className={styles.twoMinsText}>
            {'This form usually takes less than '}
            <b>{'2 mins'}</b>
          </div>
        </div>
      </div>
      <div
        className={styles.submitContainer}
      >
        <Button
          size="large"
          label={'+ Add Pension'}
          onClick={() => {
            dispatchIntroStepPensionClicked(isCustomerOngoing);
            setStep((_step) => _step + 1);
          }}
        />
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  dispatchIntroStepLanded: introStepLandedAction,
  dispatchIntroStepPensionClicked: introStepPensionClickedAction,
  dispatchReturnLinkClicked: returnLinkClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntroStep);
