exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-FscsWidget-fcscCard-3M0h- {\n  display: grid;\n  grid-template-columns: auto 1fr;\n  grid-template-areas:\n  'logo title'\n  'logo text';\n  background-color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["white"] + ";\n  border-radius: 10px;\n  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);\n          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.14);\n  padding: 15px;\n  height: 100%;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n}\n.-FscsWidget-logo-2J0I8 {\n  grid-area: logo;\n  align-self: flex-start;\n  width: 60px;\n  min-width: 60px;\n  margin-right: 15px;\n}\n.-FscsWidget-heading-1VttB {\n  grid-area: title;\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + ";\n  margin: 0;\n}\n.-FscsWidget-bodyText-18MWb {\n  grid-area: text;\n  line-height: 22px;\n  margin: 15px 0 0 0;\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n  .-FscsWidget-fcscCard-3M0h- {\n    grid-template-columns: 1fr;\n    grid-template-areas:\n    'title'\n    'logo'\n    'text';\n  }\n  \n  .-FscsWidget-logo-2J0I8 {\n    margin-right: 0;\n    margin-top: 15px;\n  }\n\n  .-FscsWidget-bodyText-18MWb {\n    margin-top: 15px;\n  }\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["typographyDesktop"] + ") {\n  .-FscsWidget-bodyText-18MWb {\n    line-height: 24px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"dimensions": "\"../../theme/dimensions.css\"",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"typographyDesktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["typographyDesktop"] + "",
	"typography": "\"../../theme/typography.css\"",
	"bodyOne": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"colors": "\"../../theme/colors.css\"",
	"textTitleRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + "",
	"white": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["white"] + "",
	"contentMargin": "15px",
	"fcscCard": "-FscsWidget-fcscCard-3M0h-",
	"logo": "-FscsWidget-logo-2J0I8",
	"heading": "-FscsWidget-heading-1VttB",
	"bodyText": "-FscsWidget-bodyText-18MWb " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + ""
};