exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);

// module
exports.push([module.id, ".-ToggleCard-card-3MzGS { \n  background: white;\n  border-radius: 10px;\n  padding: 15px;\n\n  margin: 16px 0;\n}\n.-ToggleCard-cardHeader-HE9Ak {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.-ToggleCard-cardHeaderWrapper-3QJY_ {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.-ToggleCard-cardTitle-R089- {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + ";\n  margin: 0 0 0 5px;\n}\n.-ToggleCard-cardContent-3iV6b {\n  margin-left: 42px;\n}\n@media screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n  .-ToggleCard-cardContent-3iV6b {\n    margin-right: 50px;\n  }\n}", ""]);

// exports
exports.locals = {
	"typography": "\"../../theme/typography.css\"",
	"h4": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h4"] + "",
	"colors": "\"../../theme/colors.css\"",
	"textTitleRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + "",
	"dimensions": "\"../../theme/dimensions.css\"",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"desktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["desktop"] + "",
	"card": "-ToggleCard-card-3MzGS",
	"cardHeader": "-ToggleCard-cardHeader-HE9Ak",
	"cardHeaderWrapper": "-ToggleCard-cardHeaderWrapper-3QJY_",
	"cardTitle": "-ToggleCard-cardTitle-R089- " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h4"] + "",
	"cardContent": "-ToggleCard-cardContent-3iV6b"
};