import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import classnames from 'classnames';
import get from 'lodash/get';

import fundMap from '../FundList/fundMap';

import aegonLogo from '../../assets/images/providers/aegon.svg';
import avivaLogo from '../../assets/images/providers/aviva.svg';
import lvLogo from '../../assets/images/providers/lv.png';
import metlifeLogo from '../../assets/images/providers/metlife.svg';
import prudentialLogo from '../../assets/images/providers/prudential.svg';
import scottishWidowsLogo from '../../assets/images/providers/scottish-widows.svg';

import '../../assets/images/rebranding/pensionPlanBanner/pension-plan-balanced.svg';
import '../../assets/images/rebranding/pensionPlanBanner/pension-plan-adventurous.svg';
import '../../assets/images/rebranding/pensionPlanBanner/pension-plan-cautious.svg';
import '../../assets/images/rebranding/pensionPlanBanner/pension-plan-conservative.svg';
import '../../assets/images/rebranding/pensionPlanBanner/pension-plan-growth.svg';
import '../../assets/images/rebranding/pensionPlanBanner/pension-plan-generic.svg';
import arrowDown from '../../assets/images/rebranding/triangle-downward.svg';

import styles from './PensionPlanCard.css';

import { getIVUCollection } from '../../redux/selectors';
import PlanNameBox from '../PlanNameBox/PlanNameBox';

const PROVIDER_MAP = {
  aegon: { label: 'Aegon', logo: aegonLogo },
  aviva: { label: 'Aviva', logo: avivaLogo },
  lv: { label: 'LV', logo: lvLogo },
  metlife: { label: 'MetLife', logo: metlifeLogo },
  prudential: { label: 'Prudential', logo: prudentialLogo },
  'scottish-widows': { label: 'Scottish Widows', logo: scottishWidowsLogo },
};

const PensionPlanCard = ({
  authorise,
  title,
  startOpen = false,
  platformName,
  portfolioReference,
  funds,
  ivuCollection,
}) => {
  if (!platformName) return null;

  const mappedFunds = funds.filter((fund) => fund.name !== 'Cash').map((fund) => {
    const fundData = fundMap[fund.isin];
    if (!fundData) return;
    return { name: fundData.name, fundSplit: get(fund, 'fundRoundedSplit'), isin: fund.isin };
  });

  if (mappedFunds.length === 1 && mappedFunds[0]) mappedFunds[0].fundSplit = 100;

  const canShowFundSection = mappedFunds
    && mappedFunds.length > 0
    && mappedFunds.every((fund) => get(fund, 'fundSplit') && get(fund, 'name'));

  const [showFunds, setShowFunds] = useState(false);

  const getProfilePortfolio = () => {
    const profilePortfolio = {
      planName: 'Mixed Plan',
      esg: undefined,
      styleName: 'Generic',
    };

    const recentPortfolio = ivuCollection.find((p) => portfolioReference && portfolioReference === get(p, 'reference'));
    if (recentPortfolio) {
      profilePortfolio.planName = recentPortfolio.displayName;
      profilePortfolio.esg = recentPortfolio.environmentalSocialGovernance;
      profilePortfolio.styleName = recentPortfolio.displayName;
      return profilePortfolio;
    }
    const legacyPortfolio = ivuCollection.find((p) => get(p.funds, '0.isin') === get(mappedFunds, '0.isin'));
    if (mappedFunds.length === 1 && legacyPortfolio) {
      profilePortfolio.planName = legacyPortfolio.displayName;
      profilePortfolio.styleName = legacyPortfolio.displayName;
    }

    return profilePortfolio;
  };
  const { planName, esg, styleName } = getProfilePortfolio();
  useEffect(() => {
    function doesPortfolioExist() {
      if (!portfolioReference || !planName || startOpen) {
        setShowFunds(true);
      } else {
        setShowFunds(false);
      }
    }
    return doesPortfolioExist();
  }, [portfolioReference, startOpen]);

  return (
    <div className={classnames(styles.container, {
      [styles.authorise]: authorise,
    })}
    >
      <h3 className={styles.updatedPensionPlanHeading}>{title}</h3>
      {planName && (
        <PlanNameBox planName={planName} esg={esg} styleName={styleName} />
      )}
      {showFunds ? (
        <React.Fragment>
          {planName && (
          <button className={styles.hideShowPlanDetails} onClick={() => setShowFunds(false)} type="button">
            <span>{'Hide plan details'}</span>
            <img src={arrowDown} style={{ transform: 'rotate(180deg)' }} alt="arrow" />
          </button>
          )}
          <React.Fragment>
            <h6 className={styles.planDetailsHeading}>{'PLATFORM'}</h6>
            <div
              className={
                classnames(
                  styles.planNameContainer,
                  { [styles.planNameContainerBorder]: canShowFundSection },
                )
              }
            >
              <img src={PROVIDER_MAP[platformName].logo} alt="provider logo" />
              <p className="body-one">{PROVIDER_MAP[platformName].label}</p>
            </div>
          </React.Fragment>
          {canShowFundSection && (
          <React.Fragment>
            <h6 className={styles.fundsHeading}>{'FUNDS'}</h6>
            {mappedFunds.map(({ name, fundSplit }) => (
              <div className={styles.planFundRow} key={name}>
                <p className="body-one">{name}</p>
                <p className="body-one">{`${fundSplit}%`}</p>
              </div>
            ))}
          </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <button className={styles.hideShowPlanDetails} onClick={() => setShowFunds(true)} type="button">
          <span>{'Show plan details'}</span>
          <img src={arrowDown} alt="arrow" />
        </button>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  ivuCollection: getIVUCollection(state),
});

export default connect(mapStateToProps)(PensionPlanCard);
