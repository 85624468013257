import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Header from '../Header/Header';
import NavigationPrimary from '../NavigationPrimary/NavigationPrimary';
import Footer from '../Footer/Footer';
import ProgressIndicator from '../ProgressIndicator/ProgressIndicator';
import MessageWidget from '../MessageWidget/MessageWidget';
import MsmPartnershipTile from '../MsmPartnershipTile/MsmPartnershipTile';
import styles from './BasePage.css';

const BasePage = ({
  children,
  footerContent,
  progressBar,
  progress,
  showFooter,
  showAlerts,
  showNavMenu,
  messageWidget,
  showMsmTile = false,
}) => {
  return (
    <Fragment>
      <Header showAlerts={showAlerts}>
        <NavigationPrimary showNavMenu={showNavMenu} />
      </Header>
      {showMsmTile && <MsmPartnershipTile />}
      { progressBar && <ProgressIndicator value={progress} /> }
      <div className={styles.basePageOuter}>
        <div className={styles.basePage}>
          {children}
        </div>
      </div>
      {footerContent}
      {showFooter && <Footer />}
      {messageWidget && <MessageWidget />}
    </Fragment>
  );
};

BasePage.propTypes = {
  footerContent: PropTypes.element,
  progressBar: PropTypes.bool,
  progress: PropTypes.number,
  showFooter: PropTypes.bool,
  showAlerts: PropTypes.bool,
  showNavMenu: PropTypes.bool,
};

BasePage.defaultProps = {
  footerContent: null,
  progressBar: false,
  progress: 0,
  showFooter: true,
  showAlerts: false,
  showNavMenu: true,
};

export default BasePage;
