import React from 'react';
import { Field, withFormik } from 'formik';
import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { withFirestore } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { renderRadioButtons } from '../../util/formik-custom-fields';
import Paper from '../../components/Paper/Paper';
import BasePage from '../../components/BasePage/BasePage';
import Button from '../../components/Button/Button';
import styles from './FindPensionsPage.css';
import {
  getSignup,
  getFirebaseUid,
} from '../../redux/selectors';
import { hmrcTraceRequestSubmittedAction, hmrcTraceRequestNoSelectedAction, hmrcTraceRequestYesSelectedAction } from '../../redux/modules/findPensions';

const renderField = (field) => (
  <Field
    type={field.type}
    name={field.name}
    label={field.label}
    heading={field.heading}
    value={field.value}
    options={field.options}
    onChange={field.onChange}
    component={field.component}
    largeHeadings
    helpText={field.helpText}
  />
);

function FindPensionsPage({
  values, setFieldValue, isSubmitting, handleSubmit, signupData, dispatchHmrcTraceRequestNoSelected, dispatchHmrcTraceRequestYesSelected,
}) {
  const prevHmrcRequestAnswer = get(signupData, 'hmrcRequest');

  const fieldConfig = {
    hmrcRequest: {
      name: 'hmrcRequest',
      label: 'Would you like us to request these details from HMRC?',
      value: values.hmrcRequest,
      options: [
        {
          label: 'Yes',
          checked: values.hmrcRequest === true,
          value: true,
        },
        {
          label: 'No',
          checked: values.hmrcRequest !== true,
          value: false,
        },
      ],
      onChange: (value) => {
        if (value) {
          dispatchHmrcTraceRequestYesSelected();
        } else {
          dispatchHmrcTraceRequestNoSelected();
        }
        setFieldValue('hmrcRequest', value);
      },
      helpText: 'We can write to HMRC on your behalf. If you wish to do this, we’ll post you a form to sign and it would then take around 45 days to get the results.',
      component: renderRadioButtons,
    },
  };

  const getButton = () => {
    const hasAnswerChanged = Boolean(prevHmrcRequestAnswer) !== Boolean(values.hmrcRequest);

    return (prevHmrcRequestAnswer || !hasAnswerChanged)
      ? <Button size="large" dataSentry={'find-pensions/return'} type="button" to="/" label="← Return to dashboard" loading={isSubmitting} />
      : <Button size="large" dataSentry={'find-pensions/save'} type="submit" label="Save & continue" loading={isSubmitting} />;
  };

  return (
    <BasePage messageWidget>
      <h2 className={styles.heading}>
        {'Check for missing SERPS contributions'}
      </h2>
      <p className={styles.content}>
        {`If you were employed in the private sector between 1987 and 2012
                we can contact HMRC on your behalf to track down any lost pension
                contributions you may have had by contracting out of `}
        <a rel="noopener noreferrer" target="_blank" href="https://www.profilepensions.co.uk/blogs/what-is-serps">{'SERPS'}</a>
        {'.'}
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Paper innerStyles={{ flexDirection: 'column', alignItems: 'stretch' }}>
          {prevHmrcRequestAnswer
            ? <p className={styles.content}>{'You have already requested that we track down lost SERPS pension contributions you may have.'}</p>
            : Object.keys(fieldConfig).map((field) => (
              <div className="field" key={field}>
                {renderField(fieldConfig[field])}
              </div>
            ))}
          {
        }
        </Paper>
        {getButton()}
      </form>
    </BasePage>
  );
}

const saveSerpsAnswerToSignup = async (props, hmrcRequest) => {
  const { signupData, firestore, uid } = props;

  if (signupData && signupData.id) {
    const updateSignup = firestore.collection('users').doc(uid).collection('signup').doc(signupData.id);
    await updateSignup.update({ hmrcRequest });
  } else {
    const newSignup = firestore.collection('users').doc(uid).collection('signup');
    await newSignup.add({ hmrcRequest });
  }
};

const FormikEnhancer = withFormik({
  mapPropsToValues: (props) => ({
    hmrcRequest: get(props, 'signupData.hmrcRequest', false),
  }),
  /* eslint-disable no-unused-vars */
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { dispatchHmrcTraceRequestSubmitted } = props;
    dispatchHmrcTraceRequestSubmitted();
    await saveSerpsAnswerToSignup(props, values.hmrcRequest);
    setSubmitting(false);
    props.history.push('/');
  },
  displayName: 'FindPensions',
})(FindPensionsPage);

const mapStateToProps = (state) => ({
  signupData: getSignup(state),
  uid: getFirebaseUid(state),
});

const mapDispatchToProps = {
  dispatchHmrcTraceRequestSubmitted: hmrcTraceRequestSubmittedAction,
  dispatchHmrcTraceRequestNoSelected: hmrcTraceRequestNoSelectedAction,
  dispatchHmrcTraceRequestYesSelected: hmrcTraceRequestYesSelectedAction,
};

export default compose(
  withFirestore,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(FormikEnhancer);
