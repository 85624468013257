/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';

import styles from './OngoingBalance.css';

import CloseIcon from '../../assets/images/icons/close-icon.svg';
import InformationIcon from '../../components/InformationIcon/InformationIcon';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import { getIsCustomerOngoing, getIsCustomerOptoutFullEncashment } from '../../redux/selectors/experience';
import { getLatestClosedEncashmentRetirementPlan } from '../../redux/selectors';
import { getIsOngoingCdsAllowed } from '../../redux/selectors/journeys';

const OngoingBalance = ({
  rp,
  isCustomerOngoing,
  isCustomerOptoutFullEncashment,
  isLatestClosedEncashmentRetirementPlan,
}) => {
  const pensionValue = get(rp, 'latestPensionValue');
  const valuationDate = get(rp, 'valuationDate');
  const balanceAtEncashment = get(isLatestClosedEncashmentRetirementPlan, 'latestPensionValue');

  const parsedValuationDate = valuationDate ? moment(valuationDate) : null;
  const showBalance = Boolean(pensionValue && parsedValuationDate && isCustomerOngoing);

  const getBalance = () => {
    if (isCustomerOptoutFullEncashment) {
      return balanceAtEncashment ? `£${Number(balanceAtEncashment.toFixed(0)).toLocaleString()}` : '£ -';
    }
    const balanceString = showBalance ? `£${Number(pensionValue.toFixed(0)).toLocaleString()}` : '£ -';
    return balanceString;
  };
  const closedRPEncashmentValuationDate = get(isLatestClosedEncashmentRetirementPlan, 'valuationDate');
  const parsedClosedRPEncashmentValuationDate = closedRPEncashmentValuationDate
    ? moment(closedRPEncashmentValuationDate) : null;

  const getValuationDate = () => {
    if (isCustomerOptoutFullEncashment) {
      return closedRPEncashmentValuationDate && <p className={styles.investmentReturnsDate} data-cy={'ongoing-valuation-date'}>{`Updated on ${parsedClosedRPEncashmentValuationDate.format('DD/MM/YYYY')}`}</p>;
    }
    return valuationDate && <p className={styles.investmentReturnsDate} data-cy={'ongoing-valuation-date'}>{`Updated on ${parsedValuationDate.format('DD/MM/YYYY')}`}</p>;
  };

  const retirementPlanOngoingServiceCancelledDate = get(isLatestClosedEncashmentRetirementPlan, 'onGoingServiceCancelledDate');
  const parsedRetirementPlanOngoingServiceCancelledDate = retirementPlanOngoingServiceCancelledDate
    ? moment(retirementPlanOngoingServiceCancelledDate) : null;

  const [showHelpText, setShowHelpText] = useState(false);
  const handleTooltipClick = () => {
    setShowHelpText(!showHelpText);
  };

  const getTooltip = () => {
    return (
      <React.Fragment>
        {
          !showHelpText && (
          <div className={styles.tooltipSpacing}>
            <InformationIcon
              active={showHelpText}
              onClick={handleTooltipClick}
            />
          </div>
          )
        }
        <div className={styles.helpTextContainer}>
          {showHelpText && (
            <div className={styles.helpTextModalContainer} onClick={() => handleTooltipClick()}>
              <div className={styles.helpTextModal} onClick={(event) => event.stopPropagation()}>
                <button className={styles.closeHelpTextButton} type="button" onClick={() => handleTooltipClick()}>
                  <img src={CloseIcon} alt="information" />
                  {'Close'}
                </button>
                <p className={styles.tooltipInfo}>
                  {'Potential reasons why we cannot show your latest pension balance'}
                  <ul className={styles.tooltipBullets}>
                    <li>{'You have set up a regular payment and we are waiting for your first payment to come through'}</li>
                    <li>{'We are waiting for your first pension transfer to complete'}</li>
                    <li>
                      {'If none of the reasons above applies and wish to discuss your balance, '}
                      <ButtonLink to="/inbox/new-message" label="contact us" font="inherit" underline />
                      {'.'}
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className={styles.ongoingBalanceContainer}>
        <div className={styles.ongoingBalance}>
          <div className={styles.alignBalanceAndTooltip}>
            <h3 className={styles.heading}>{'Balance'}</h3>
            {isCustomerOptoutFullEncashment ? !showBalance : !showBalance && getTooltip()}
          </div>
          <p className={isCustomerOptoutFullEncashment ? styles.pensionValueEncashment : styles.pensionValue} data-cy={'ongoing-pension-value'}>{getBalance()}</p>
          {getValuationDate()}
          { isCustomerOptoutFullEncashment && (
            <div>
              <h4 className={styles.encashmentInfoHeading}>{`You have requested full encashment of your pension on ${parsedRetirementPlanOngoingServiceCancelledDate.format('DD/MM/YYYY')}`}</h4>
              <p className={styles.encashmentInfoSubHeading}>
                {'It can take up to 6 weeks for your money to arrive. Your balance might not be updated whilst this transaction is being processed.'}
              </p>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
  isLatestClosedEncashmentRetirementPlan: getLatestClosedEncashmentRetirementPlan(state),
  isCustomerOptoutFullEncashment: getIsCustomerOptoutFullEncashment(state),
  isOngoingCdsRequired: getIsOngoingCdsAllowed(state),
});

export default connect(mapStateToProps)(OngoingBalance);
