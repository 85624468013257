import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import WithDocumentLink from './WithDocumentLink';
import {
  getReadyToAcceptSuitabilityReport,
} from '../../redux/selectors/ongoingService';

const WithOngoingSuitabilityLetter = ({
  reportReference,
  ...otherProps
}) => {
  return (
    <WithDocumentLink
      documentType="ongoing-suitability-letter"
      fileRef={reportReference}
      {...otherProps}
    />
  );
};

const mapStateToProps = (state) => ({
  reportReference: getReadyToAcceptSuitabilityReport(state),
});

export default compose(
  connect(mapStateToProps),
)(WithOngoingSuitabilityLetter);
