/* eslint-disable import/prefer-default-export */

export const sendGaPageEvent = (pageRoute) => {
  if (window.ga && typeof window.ga.getAll === 'function') {
    try {
      const tracker = window.ga.getAll()[0];
      tracker.set('page', pageRoute);
      tracker.send('pageview');
    } catch (error) {
      console.log('errorL', error);
    }
  }
};

export const sendGaCustomEvent = ({
  category,
  action,
  label,
}) => {
  if (window.ga && typeof window.ga === 'function') {
    try {
      window.ga('send', 'event', category, action, label);
    } catch (error) {
      console.log('errorL', error);
    }
  }
};

export const gaCategories = {
  acquisition: 'acquisition',
};

export const gaLabels = {
  signupClicks: 'signupClicks',
  generateAdvice: 'generateAdvice',
  clickAccept: 'clickAccept',
};

export const gaActions = {
  recordCount: 'recordCount',
  adviceGenerated: 'adviceGenerated',
  transferAuthorityGiven: 'transferAuthorityGiven',
};
