exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);

// module
exports.push([module.id, ".-FooterContact-wrapper-nN5gZ {\n  grid-row: 4;\n  grid-column: 1 / span 6;\n  margin-top: 48px;\n  width: 100%;\n  \n}\n@supports not (display: grid) {\n  .-FooterContact-wrapper-nN5gZ {\n    margin-top: 30px;\n  }\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n  .-FooterContact-wrapper-nN5gZ {\n    grid-row: 2;\n    grid-column: 4 / span 4;\n    margin-top: 65px;\n  }\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["largeDesktop"] + ") {\n  .-FooterContact-wrapper-nN5gZ {\n    grid-row: 1;\n    grid-column: 7 / span 3;\n    margin-top: 80px;\n  }\n}\n.-FooterContact-heading-R84Vq {\n  font-family: \"Poppins\";\n  font-weight: 500;\n  margin: 0;\n  font-size: 18px;\n  line-height: 28px;\n\n}\n.-FooterContact-locationName-3OwoK {\n  font-family: \"Lato\";\n  font-weight: 700;\n  font-size: 18px;\n  margin: 0;\n}\n.-FooterContact-text-3rf1_.-FooterContact-text-3rf1_ {\n  font-family: \"Lato\";\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 28px;\n  margin: 0;\n}\n.-FooterContact-link-V5LAO.-FooterContact-link-V5LAO {\n  font-family: \"Lato\";\n  font-weight: 400;\n  text-decoration: none;\n}", ""]);

// exports
exports.locals = {
	"dimensions": "\"../../theme/dimensions.css\"",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"largeDesktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["largeDesktop"] + "",
	"wrapper": "-FooterContact-wrapper-nN5gZ",
	"heading": "-FooterContact-heading-R84Vq",
	"locationName": "-FooterContact-locationName-3OwoK",
	"text": "-FooterContact-text-3rf1_",
	"link": "-FooterContact-link-V5LAO"
};