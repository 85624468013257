import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { getFirebaseUid } from '../../redux/selectors';
import {
  dispatchIntroModalViewAction,
  introModalClosed,
  may2023DrawdownUpdateNotificationClicked,
} from '../../redux/modules/notificationTracking';
import { getHasIntroModalBeenDismissed, getIsNewOngoingCustomerIntroModal } from '../../redux/selectors/notificationTracking';

import Modal from '../Modal/Modal';
import DotNavigation from '../DotNavigation/DotNavigation';
import Button from '../Button/Button';

import introModalContent from './introModalContent';

import styles from './IntroModel.css';

const IntroModal = ({
  uid,
  dispatchIntroModalView,
  dispatchIntroModalClosed,
  hasIntroModalBeenDismissed,
  isNewOngoingCustomer,
  dispatchMay2023DrawdownUpdateNotificationClicked,
}) => {
  const [showModal, setShowModal] = useState(!hasIntroModalBeenDismissed);
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (showModal) {
      dispatchIntroModalView();
    }

    window.forceIntroModal = () => {
      setShowModal(true);
      setStep(0);
    };
  }, []);

  const modalSteps = introModalContent(isNewOngoingCustomer);
  const {
    icon,
    heading,
    body,
    ctaLabel,
  } = modalSteps[step];

  const handleCtaButton = async () => {
    if (step < modalSteps.length - 1) {
      setStep(step + 1);
    } else {
      dispatchMay2023DrawdownUpdateNotificationClicked(uid);
      dispatchIntroModalClosed(uid);
      setShowModal(false);
    }
  };

  return (
    <Modal
      show={showModal}
      centerContent
      introModal
      modalFullMaxWidth
      foggyBackdrop
    >
      <div className={styles.introModalContainer}>
        <div className={styles.imageContainer}>
          <img className={styles.image} src={icon} alt="" />
        </div>
        <div className={styles.textContainer}>
          <h2 className={styles.heading}>{heading}</h2>
          <p className={styles.body}>{body}</p>
        </div>
        <div className={styles.navigationAndButtonWrapper}>
          <DotNavigation
            dotCount={modalSteps.length}
            dotStep={step}
            onClick={setStep}
            grassActiveDot
          />
          <div className={styles.ctaButtonContainer}>
            <Button size="large" onClick={handleCtaButton} label={ctaLabel} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
  hasIntroModalBeenDismissed: getHasIntroModalBeenDismissed(state),
  isNewOngoingCustomer: getIsNewOngoingCustomerIntroModal(state),
});

const mapDispatchToProps = ({
  dispatchIntroModalView: dispatchIntroModalViewAction,
  dispatchIntroModalClosed: introModalClosed,
  dispatchMay2023DrawdownUpdateNotificationClicked: may2023DrawdownUpdateNotificationClicked,
});

export default connect(mapStateToProps, mapDispatchToProps)(IntroModal);
