import React, { useEffect } from 'react';

const WithIntercom = ({ children }) => {
  useEffect(() => {
    const body = document.getElementById('body');
    if (body) {
      body.classList.add('with-intercom');
    }
    return () => {
      if (body) {
        body.classList.remove('with-intercom');
      }
    };
  }, []);

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

export default WithIntercom;
