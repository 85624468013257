import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import FieldLabel from '../FieldLabel/FieldLabel';
import FieldStatusBlock from '../FieldStatusBlock/FieldStatusBlock';

import styles from './SelectField.css';

class SelectField extends Component {
  focus() {
    if (this.select) {
      this.select.focus();
    }
  }

  render() {
    const {
      name,
      label,
      value,
      placeholder,
      onChange,
      options,
      wrapperStyles,
      errorText,
      touched,
      onBlur,
      required,
      disabled,
      largeHeadings,
      onMenuOpen,
      selectStyles,
      isSearchable,
    } = this.props;

    const currentValue = value ? { value, label: value } : null;

    return (
      <div className={styles.wrapper}>
        <FieldLabel label={label} required={required} disabled={disabled} largeHeadings={largeHeadings} />
        <FieldStatusBlock
          error={errorText}
          touched={touched}
          wrapperStyles={wrapperStyles}
        >
          <Select
            clearable={false}
            searchable={false}
            value={currentValue}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            options={options}
            onMenuOpen={onMenuOpen}
            styles={{
              ...selectStyles,
              menu: () => ({ zIndex: '1000 !important' }),
            }}
            isSearchable={isSearchable}
          />
          <input type="hidden" name={name} value={value} />
        </FieldStatusBlock>
      </div>
    );
  }
}

export default SelectField;

SelectField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

SelectField.defaultProps = {
  name: '',
  value: '',
  placeholder: '',
  options: [{ label: '', value: '' }],
};
