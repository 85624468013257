/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import get from 'lodash/get';
import moment from 'moment';
import Switch from 'react-switch';

import TextField from '../components/TextField/TextField';
import DateOfBirthField from '../components/DateOfBirthField/DateOfBirthField';
import MultiFieldDatePicker from '../components/MultiFieldDatePicker/MultiFieldDatePicker';
import SelectField from '../components/SelectField/SelectField';
import AddressPicker from '../components/AddressPicker/AddressPicker';
import { PersonalDetailsPostcodeAddressLookup, MissingDetailsPostcodeAddressLookup } from '../components/PostcodeAddressLookup/PostcodeAddressLookup';
import RadioButtons from '../components/RadioButtons/RadioButtons';
import CheckBox from '../components/CheckBox/CheckBox';
import TimeSpentAtAddressField from '../components/DatePickerField/TimeSpentAtAddressField';
import { MISSING_ADDRESS_ERROR } from './constants';

const CURRENT_YEAR = moment().year();
const OLDEST_YEAR = 1910;

export const renderTextField = ({
  field,
  form,
  label,
  largeHeadings,
  hasSubmitted,
  ...custom
}) => (
  <TextField
    name={field.name}
    label={label}
    value={field.value}
    touched={get(form.touched, field.name) || hasSubmitted}
    errorText={get(form.errors, field.name)}
    onChange={field.onChange}
    onBlur={field.onBlur}
    wrapperStyles={{ maxWidth: '100%' }}
    defaultValue={null}
    largeHeadings={largeHeadings}
    {...custom}
  />
);

export const renderDOBField = ({
  field,
  form,
  label,
  ...custom
}) => (
  <DateOfBirthField
    name={field.name}
    label={label}
    value={field.value}
    touched={get(form.touched, field.name)}
    errorText={get(form.errors, field.name)}
    onChange={field.onChange}
    onBlur={field.onBlur}
    {...custom}
  />
);

export const renderTimeSpentAtAddressField = ({
  field,
  form,
  label,
  max,
  min,
  type,
  name,
  value,
  ...custom
}) => (
  <div>
    <TimeSpentAtAddressField
      name={field.name}
      label={label}
      value={value}
      max={max}
      min={min}
      touched={get(form.touched, field.name)}
      errorText={get(form.errors, field.name)}
      onChange={field.onChange}
      onBlur={field.onBlur}
      {...custom}
    />
  </div>
);

export const renderSelectField = ({
  field,
  form,
  label,
  placeholder,
  options = [],
  onChange,
  largeHeadings,
  ...custom
}) => (
  <React.Fragment>
    <SelectField
      name={field.name}
      label={label}
      value={field.value}
      options={options}
      touched={get(form.touched, field.name)}
      errorText={get(form.errors, field.name)}
      onChange={(event) => {
        onChange({ [field.name]: event.value });
      }}
      onBlur={() => {
        form.setTouched({ ...form.touched, [field.name]: true });
      }}
      wrapperStyles={{ maxWidth: '100%' }}
      placeholder={placeholder || 'Select...'}
      largeHeadings={largeHeadings}
      {...custom}
    />
    <input type="hidden" name={field.name} value={field.value} />
  </React.Fragment>
);

export const renderMultiDOBFields = ({
  field,
  form,
  largeHeadings,
  ...custom
}) => {
  const error = get(form.errors, field.name);
  const errorText = typeof error === 'string' ? error : '';
  const errorTextPerField = typeof error === 'object' ? error : {};

  return (
    <MultiFieldDatePicker
      name={field.name}
      label={field.label}
      date={field.value}
      touched={get(form.touched, field.name)}
      errorText={errorText}
      errorTextPerField={errorTextPerField}
      range={{ max: CURRENT_YEAR, min: OLDEST_YEAR }}
      wrapperStyles={{ maxWidth: '100%' }}
      largeHeadings={largeHeadings}
      {...custom}
    />
  );
};

export const renderPersonalDetailsPostcodeAddressLookup = ({
  field,
  form,
  label,
  largeHeadings,
  getAddresses,
  selectAddress,
  hasSubmitted,
  ...custom
}) => {
  const hadAddressErrors = Boolean(Object.keys(get(form.errors, 'address', {})).length);
  const noAddressError = hasSubmitted && hadAddressErrors ? MISSING_ADDRESS_ERROR : '';
  return (
    <PersonalDetailsPostcodeAddressLookup
      form={form}
      name={field.name}
      label={label}
      value={field.value}
      touched={get(form.touched, field.name) || hasSubmitted}
      errorText={get(form.errors, field.name) || noAddressError}
      onBlur={field.onBlur}
      largeHeadings={largeHeadings}
      getAddresses={getAddresses}
      selectAddress={selectAddress}
      {...custom}
    />
  );
};

export const renderMissingDetailsPostcodeAddressLookup = ({
  field,
  form,
  label,
  largeHeadings,
  getAddresses,
  selectAddress,
  hasSubmitted,
  setStep,
  ...custom
}) => {
  const hadAddressErrors = Boolean(Object.keys(get(form.errors, 'address', {})).length);
  const noAddressError = hasSubmitted && hadAddressErrors ? MISSING_ADDRESS_ERROR : '';
  return (
    <MissingDetailsPostcodeAddressLookup
      form={form}
      setStep={setStep}
      name={field.name}
      label={label}
      value={field.value}
      touched={get(form.touched, field.name) || hasSubmitted}
      errorText={get(form.errors, field.name) || noAddressError}
      onBlur={field.onBlur}
      largeHeadings={largeHeadings}
      getAddresses={getAddresses}
      selectAddress={selectAddress}
      {...custom}
    />
  );
};

// eslint-disable-next-line arrow-body-style
export const renderAddressFields = ({
  field,
  form,
  ...custom
}) => {
  const error = get(form.errors, field.name);
  const errorText = typeof error === 'string' ? error : '';
  const errorTextPerField = typeof error === 'object' ? error : {};

  return (
    <AddressPicker
      name={field.name}
      label={field.label}
      address={field.value}
      touched={get(form.touched, field.name)}
      errorText={errorText}
      errorTextPerField={errorTextPerField}
      onBlur={field.onBlur}
      {...custom}
    />
  );
};

export const renderRadioButtons = ({
  field,
  form,
  options,
  ...custom
}) => (
  <RadioButtons
    name={field.name}
    label={field.label}
    buttons={options}
    onChange={field.onChange}
    {...custom}
  />
);

export const renderCheckBox = ({
  field,
  form,
  label,
  largeHeadings,
  ...custom
}) => (
  <CheckBox
    name={field.name}
    label={label}
    value={field.value}
    touched={get(form.touched, field.name)}
    errorText={get(form.errors, field.name)}
    onChange={field.onChange}
    onBlur={field.onBlur}
    defaultValue={false}
    largeHeadings={largeHeadings}
    {...custom}
  />
);

export const renderToggleSlideCheckBox = ({
  handleChange,
  isChecked,
  setFieldValue,
  field,
}) => (
  <Switch
    uncheckedIcon={false}
    offHandleColor={'#C3C3C3'}
    onHandleColor={'#169B62'}
    offColor={'#F0F0F0'}
    onColor={'#E5F7D8'}
    checkedIcon={false}
    className="switch-checkbox"
    onChange={(checked) => {
      setFieldValue(field.name, checked);
      handleChange(field.name, checked);
    }}
    checked={isChecked}
    height={20}
    width={42}
  />
);
