exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-FooterSecurity-wrapper-2xiF4 {\n  border-top: 0.5px solid " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["interfaceNeutralStrong"] + ";\n  padding: 48px 0 0;\n  grid-row: 5;\n  grid-column: 1 / span 6;\n  margin-top: 48px;\n  width: 100%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n  .-FooterSecurity-wrapper-2xiF4 {\n    grid-row: 3;\n    grid-column: 1 / span 10;\n    margin-top: 65px;\n  }\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["largeDesktop"] + ") {\n  .-FooterSecurity-wrapper-2xiF4 {\n    grid-row: 2;\n    grid-column: 4 / span 9;\n    margin-top: 50px;\n  }\n}\n.-FooterSecurity-wrapper-2xiF4 > div {\n  margin: 0 50px 0 0;\n}\n.-FooterSecurity-digicert-1dclB {\n  padding: 12px 0;\n}\n.-FooterSecurity-eliteicon-33KRy {\n  padding: 15px 5px;\n}", ""]);

// exports
exports.locals = {
	"dimensions": "\"../../theme/dimensions.css\"",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"largeDesktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["largeDesktop"] + "",
	"colors": "\"../../theme/colors.css\"",
	"interfaceNeutralStrong": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["interfaceNeutralStrong"] + "",
	"wrapper": "-FooterSecurity-wrapper-2xiF4",
	"digicert": "-FooterSecurity-digicert-1dclB",
	"eliteicon": "-FooterSecurity-eliteicon-33KRy"
};