import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import classNames from 'classnames';
import styles from './RetirementIncomePage.css';
import { currencyString } from '../../util/currency';
import ToggleCard from '../../components/ToggleCard/ToggleCard';
import addPoundIcon from '../../assets/images/rebranding/add-money.svg';
import transferPensionIcon from '../../assets/images/rebranding/tri-tone-overlapping-circles.svg';
import changeRetirementAgeIcon from '../../assets/images/retirementIncome/edit-retirement-age.svg';
import TextInput from '../../components/TextInput/TextInput';
import { getIntendedRetirementAge, getVerifiedNetPersonalContributions } from '../../redux/selectors';
import Button from '../../components/Button/Button';
import {
  MIN_MONETARY_VALUE,
  MAX_MONETARY_VALUE,
  MIN_RETIREMENT_AGE,
  MAX_RETIREMENT_AGE,
} from './state';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import { DisplayError } from './Utils';
import {
  createScenarioSetupContributionToggledAction,
  createScenarioTransferPensionToggledAction,
  createScenarioAddLumpSumToggledAction,
  createScenarioChangeRetirementAgeToggledAction,
  createScenarioSetupContributionInputAction,
  createScenarioTransferPensionInputAction,
  createScenarioAddLumpSumInputAction,
  createScenarioChangeRetirementAgeInputAction,
  createScenarioStepContinueButtonClickedAction,
  createScenarioStepViewPreviousResultClickedAction,
  createScenarioStepPageLanded,
} from '../../redux/modules/projection';

const handleCurrencyInputChange = (
  e, dispatchNewAmount, dispatchEvent, MIN_VALUE = MIN_MONETARY_VALUE,
) => {
  const strippedInput = get(e, 'target.value', '').replace(/\D/g, '');
  let error = null;
  if (strippedInput) {
    const amount = parseInt(strippedInput, 10);
    if (amount < MIN_VALUE || amount >= MAX_MONETARY_VALUE) {
      error = 'Please enter an amount or deselect this option to proceed';
    }
    const amountMask = currencyString(amount);
    dispatchNewAmount(amount, amountMask, error);
    dispatchEvent(amountMask);
  } else {
    dispatchNewAmount(0, '', 'Please enter an amount or deselect this option to proceed');
    dispatchEvent('');
  }
};

const handleAgeInputChange = (e, setValue, dispatchEvent) => {
  const strippedInput = get(e, 'target.value', '').replace(/\D/g, '');
  let error = null;
  let newAge = null;
  if (strippedInput) {
    newAge = parseInt(strippedInput, 10);
  }

  if (newAge < MIN_RETIREMENT_AGE || newAge > MAX_RETIREMENT_AGE) {
    error = 'Please enter a retirement age between 55 and 80 or deselect this option to proceed';
  }
  setValue(newAge, error);
  dispatchEvent(newAge);
};

function RetirementIncomeCreateScenarioStep({
  intendedRetirementAge,
  state,
  errorMessage,
  loading,
  currentVerifiedContributionsValue,
  isHighValueContributionCustomer,
  hasCompletedFlow,
  onResultsStep,
  onMonetaryValueChange,
  onChangeRetirementAge,
  toggleScenario,
  loadLastProjection,
  dispatchViewPreviousResultClicked,
  dispatchContinueButtonClicked,
  dispatchSetupContributionToggled,
  dispatchTransferPensionToggled,
  dispatchAddLumpSumToggled,
  dispatchChangeRetirementAgeToggled,
  dispatchSetupContributionInput,
  dispatchTransferPensionInput,
  dispatchAddLumpSumInput,
  dispatchChangeRetirementAgeInput,
  dispatchResultsStepPageLanded,
}) {
  const [nextJourneyStepDisabled, setNextJourneyStepDisabled] = useState(true);
  const [showToggleMessage, setShowToggleMessage] = useState(false);
  const [error, setError] = useState(null);

  const {
    scenarios: {
      monthlyContributions, transferPension, addLumpSum, changeRetirementAge,
    },
  } = state;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchResultsStepPageLanded();
  }, []);

  useEffect(() => {
    const scenariosKeys = Object.keys(state.scenarios);

    if (scenariosKeys.every((key) => {
      return !get(state, `scenarios[${key}].enabled`, false);
    })) {
      setShowToggleMessage(true);
      setNextJourneyStepDisabled(true);
      return;
    }
    setShowToggleMessage(false);

    const allScenariosValid = scenariosKeys
      .filter(
        (key) => get(state, `scenarios[${key}].enabled`, false),
      ).every((key) => {
        return !isNil(get(state, `scenarios[${key}].value`)) && get(state, `scenarios[${key}].value`) !== '' && !get(state, `scenarios[${key}].error`);
      });

    setNextJourneyStepDisabled(!allScenariosValid);
  }, [
    monthlyContributions, transferPension, addLumpSum, changeRetirementAge,
  ]);

  const onContinue = () => {
    setError(null);
    dispatchContinueButtonClicked();

    if (!nextJourneyStepDisabled) {
      onResultsStep();
      return;
    }

    const scenariosKeys = Object.keys(state.scenarios);

    scenariosKeys
      .filter((key) => get(state, `scenarios[${key}].enabled`, false))
      .filter((key) => isNil(get(state, `scenarios[${key}].value`)) || get(state, `scenarios[${key}].value`) === '')
      .forEach((key) => {
        handleCurrencyInputChange(null, onMonetaryValueChange(key), () => {});
      });

    if (
      scenariosKeys.every((key) => {
        return !get(state, `scenarios[${key}].enabled`, false);
      })
    ) {
      setError('Please select at least one scenario to continue!');
      const element = document.getElementById('select-option-message');
      if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <div>
      <h2 className={styles.createScenarioHeading}>{'Create your scenario'}</h2>
      <p className={styles.createScenarioText}>{'Adjust as many of the options as you’d like to see the impact on your estimated pension income'}</p>
      {isHighValueContributionCustomer && hasCompletedFlow && (
        <ButtonLink
          label="View your previous result"
          onClick={() => {
            dispatchViewPreviousResultClicked();
            loadLastProjection();
          }}
          bold
          underline
        />
      )}
      <ToggleCard
        icon={addPoundIcon}
        title={currentVerifiedContributionsValue > 0 ? 'Amending my contributions' : 'Set up regular contributions'}
        open={monthlyContributions.enabled}
        setOpen={() => {
          dispatchSetupContributionToggled(!monthlyContributions.enabled);
          toggleScenario('monthlyContributions');
        }}
      >
        {currentVerifiedContributionsValue > 0
          ? (
            <React.Fragment>
              {'I would like to amend my regular contributions from '}
              <b>
                {currencyString(currentVerifiedContributionsValue)}
              </b>
              {' to '}
            </React.Fragment>
          )
          : 'I would like to make regular contributions of '}
        <TextInput
          inline
          placeholder={'£ enter'}
          value={monthlyContributions.valueMask}
          error={monthlyContributions.error}
          onChange={(e) => handleCurrencyInputChange(e, onMonetaryValueChange('monthlyContributions'), dispatchSetupContributionInput, 0)}
        />
        {' a month'}
        {monthlyContributions.error
        && <small className={styles.createScenarioError}>{monthlyContributions.error}</small>}
      </ToggleCard>
      <ToggleCard
        icon={transferPensionIcon}
        title="Transfer a pension"
        open={transferPension.enabled}
        setOpen={() => {
          dispatchTransferPensionToggled(!transferPension.enabled);
          toggleScenario('transferPension');
        }}
      >
        {'I have one or more existing pensions worth '}
        <TextInput
          value={transferPension.valueMask}
          error={transferPension.error}
          placeholder={'£ enter'}
          inline
          onChange={(e) => handleCurrencyInputChange(e, onMonetaryValueChange('transferPension'), dispatchTransferPensionInput)}
        />
        {' that I would like to combine'}
        {transferPension.error
        && <small className={styles.createScenarioError}>{transferPension.error}</small>}
      </ToggleCard>
      <ToggleCard
        icon={addPoundIcon}
        title="Add a one-off contribution"
        open={addLumpSum.enabled}
        setOpen={() => {
          dispatchAddLumpSumToggled(!addLumpSum.enabled);
          toggleScenario('addLumpSum');
        }}
      >
        {'I would like to add a one-off contribution of '}
        <TextInput
          value={addLumpSum.valueMask}
          error={addLumpSum.error}
          placeholder={'£ enter'}
          inline
          onChange={(e) => handleCurrencyInputChange(e, onMonetaryValueChange('addLumpSum'), dispatchAddLumpSumInput)}
        />
        {addLumpSum.error
        && <small className={styles.createScenarioError}>{addLumpSum.error}</small>}
      </ToggleCard>
      <ToggleCard
        icon={changeRetirementAgeIcon}
        title="Change my retirement age"
        open={changeRetirementAge.enabled}
        setOpen={() => {
          dispatchChangeRetirementAgeToggled(!changeRetirementAge.enabled);
          toggleScenario('changeRetirementAge');
        }}
      >
        {'I would like to change my retirement age from '}
        <b>{intendedRetirementAge}</b>
        {' to '}
        <TextInput
          inline
          type="number"
          placeholder={'enter age'}
          error={changeRetirementAge.error}
          value={changeRetirementAge.value}
          onChange={
            (e) => handleAgeInputChange(e, onChangeRetirementAge, dispatchChangeRetirementAgeInput)
          }
        />
        {' years old'}
        {changeRetirementAge.error
        && <small className={styles.createScenarioError}>{changeRetirementAge.error}</small>}
        <hr className={styles.divider} />
        <p className={styles.createScenarioRetirementAgeExtraText}>
          {'You can message your dedicated Pension Adviser if you have any questions around changing your retirement age'}
        </p>
      </ToggleCard>

      <Button
        size="large"
        label="Continue"
        loading={loading}
        onClick={onContinue}
      />
      <div className={styles.createScenarioHelpText} id="select-option-message">
        {showToggleMessage && (
          <small className={classNames(styles.createScenarioError, {
            [styles.asMessage]: !error,
          })}
          >
            {'Please select an option to proceed'}
          </small>
        )}
      </div>
      <DisplayError error={errorMessage} />

    </div>
  );
}

RetirementIncomeCreateScenarioStep.propTypes = {};

const mapStateToProps = (state) => ({
  intendedRetirementAge: getIntendedRetirementAge(state),
  currentVerifiedContributionsValue: getVerifiedNetPersonalContributions(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetupContributionToggled:
    (enabled) => dispatch(createScenarioSetupContributionToggledAction(enabled)),
  dispatchTransferPensionToggled:
    (enabled) => dispatch(createScenarioTransferPensionToggledAction(enabled)),
  dispatchAddLumpSumToggled:
    (enabled) => dispatch(createScenarioAddLumpSumToggledAction(enabled)),
  dispatchChangeRetirementAgeToggled:
    (enabled) => dispatch(createScenarioChangeRetirementAgeToggledAction(enabled)),
  dispatchSetupContributionInput:
    (value) => dispatch(createScenarioSetupContributionInputAction(value)),
  dispatchTransferPensionInput:
    (value) => dispatch(createScenarioTransferPensionInputAction(value)),
  dispatchAddLumpSumInput:
    (value) => dispatch(createScenarioAddLumpSumInputAction(value)),
  dispatchChangeRetirementAgeInput:
    (value) => dispatch(createScenarioChangeRetirementAgeInputAction(value)),
  dispatchContinueButtonClicked:
    () => dispatch(createScenarioStepContinueButtonClickedAction()),
  dispatchViewPreviousResultClicked:
    () => dispatch(createScenarioStepViewPreviousResultClickedAction()),
  dispatchResultsStepPageLanded: () => dispatch(createScenarioStepPageLanded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RetirementIncomeCreateScenarioStep);
