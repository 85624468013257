import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import HtmlSelectField from '../../components/HtmlSelectField/HtmlSelectField';
import TextField from '../../components/TextField/TextField';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';

import {
  nextClickedAction,
  backClickedAction,
  withdrawalReasonsStepVisitedAction,
  withdrawalFundsUsedForChangedAction,
} from '../../redux/modules/withdrawal';

import WithdrawalReasonImage from '../../assets/images/withdrawals/mountain-sun.svg';

import styles from './WithdrawalPage.css';

const FUNDS_USED_FOR_OPTIONS = [
  { value: 'income', label: 'Income' },
  { value: 'pay-off-mortgage-dept', label: 'Pay off mortgage/debt' },
  { value: 'home-improvements', label: 'Home improvements' },
  { value: 'to-invest-elsewhere', label: 'To invest elsewhere' },
  { value: 'other', label: 'Other (please specify)' },
];

const WithdrawalAmount = ({
  step,
  totalSteps,
  setStep,
  formData,
  setFormData,
  dispatchWithdrawalReasonsStepVisited,
  dispatchFundsUsedForChanged,
  dispatchNextClicked,
  dispatchBackClicked,
}) => {
  const [otherTextValue, setOtherTextValue] = useState('');

  useEffect(() => {
    dispatchWithdrawalReasonsStepVisited();
  }, []);

  return (
    <BaseGrid addPensionsTheme>
      <div className={styles.withdrawalsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          onClick={(event) => {
            dispatchBackClicked();
            setStep((_step) => _step - 1);
            event.stopPropagation();
          }}
          label="← Back"
        />
        <div className={styles.withdrawalsImageContainer}>
          <img style={{ maxWidth: '225px' }} src={WithdrawalReasonImage} alt="mountain" />
        </div>
        <h2 className={styles.headingTwo}>{'What are you using the money for?'}</h2>
        <br />
        <HtmlSelectField
          fullWidth
          wrapperStyles={{ margin: '0 0 15px' }}
          value={formData.fundsUsedForSelectedOption}
          options={FUNDS_USED_FOR_OPTIONS}
          placeholder={'Select'}
          onChange={(event) => {
            dispatchFundsUsedForChanged();
            const selectedValue = event.target.value;
            if (selectedValue === 'other') {
              setFormData({
                ...formData,
                fundsUsedForSelectedOption: selectedValue,
                fundsUsedFor: otherTextValue,
              });
            } else {
              setFormData({
                ...formData,
                fundsUsedForSelectedOption: selectedValue,
                fundsUsedFor: event.target.options[event.target.selectedIndex].innerText,
              });
            }
          }}
        />
        {formData.fundsUsedForSelectedOption === 'other' && (
          <React.Fragment>
            <TextField
              tallInput
              wrapperStyles={{ margin: '-10px 0 15px 0' }}
              inputWrapperStyles={{ marginBottom: '20px', maxWidth: 'none' }}
              name="otherFundsUsedFor"
              value={formData.fundsUsedFor}
              onChange={(event) => {
                const selectedValue = event.target.value;
                setFormData({ ...formData, fundsUsedFor: selectedValue });
                setOtherTextValue(selectedValue);
              }}
              defaultValue={'Please state what you are using the money for'}
            />
          </React.Fragment>
        )}
        <div className={styles.pensionAdviserContainer}>
          <div className={styles.pensionAdviserCard}>
            <ContactUsContent
              source={'WithdrawalReasonStep'}
              noExpertContentWrapper={({ children }) => (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexShrink: 'initial',
                  padding: '10px',
                  flexWrap: 'wrap',
                }}
                >
                  {children}
                </div>
              )}
              showButton={false}
              customMessage={(
                <div className={styles.contactUsInnerContent}>
                  {'Taking money from your pension should be carefully considered and you should only take out what you really need. Anything you take out will effect your income in retirement. If you need help with this, please feel free to '}
                  <span>
                    <ButtonLink
                      variant="primary"
                      displayAsHref
                      to="/inbox/new-message"
                      search={'?subject=Withdrawal%20Request'}
                      font="inherit"
                      label={'contact your Pension Adviser'}
                    />
                    {'.'}
                  </span>
                </div>
              )}
            />
          </div>
        </div>
        <Button
          size="large"
          disabled={!formData.fundsUsedForSelectedOption || !formData.fundsUsedFor}
          label={'Next'}
          onClick={() => {
            dispatchNextClicked();
            setStep((_step) => _step + 1);
          }}
        />
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  dispatchWithdrawalReasonsStepVisited: withdrawalReasonsStepVisitedAction,
  dispatchFundsUsedForChanged: withdrawalFundsUsedForChangedAction,
  dispatchNextClicked: nextClickedAction,
  dispatchBackClicked: backClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalAmount);
