const getFundsWithRoundedSplits = (_funds) => {
  const funds = [..._funds];
  /*
    Rounds the fund splits so that the sum of all the fund splits is 100
  */
  const integerTotal = funds.reduce((currentReduced, currentValue) => {
    return currentReduced + Math.floor(currentValue.fundSplit);
  }, 0);
  const differenceToDistribute = 100 - integerTotal;
  const fundsSortedByDecimalSize = funds.sort((
    { fundSplit: fundSplitA },
    { fundSplit: fundSplitB },
  ) => {
    const decimalA = fundSplitA - Math.floor(fundSplitA);
    const decimalB = fundSplitB - Math.floor(fundSplitB);
    return (decimalA === decimalB) ? fundSplitA - fundSplitB : decimalB - decimalA;
  });
  const fundsWithRoundedSplits = fundsSortedByDecimalSize.map((fund, index) => {
    const shouldAddOne = index < differenceToDistribute;
    const fundRoundedSplit = shouldAddOne
      ? Math.floor(fund.fundSplit) + 1
      : Math.floor(fund.fundSplit);
    return { ...fund, fundRoundedSplit };
  });
  fundsWithRoundedSplits.sort((a, b) => {
    return b.fundRoundedSplit - a.fundRoundedSplit;
  });

  return fundsWithRoundedSplits;
};

export default getFundsWithRoundedSplits;
