import React from 'react';

import NotificationBanner from '../NotificationBanner/NotificationBanner';

const TaxResidencyNotification = () => {
  return (
    <NotificationBanner
      alertCategory={'Red'}
      alertTitle={'UK residency'}
      expandableAlertDescription={
        'As you have told us you are not a UK resident we need to speak to you urgently about your pension. Please contact your dedicated Pension Adviser.'
      }
      ctaButtonOptions={{
        label: 'Message my Pension Adviser',
        to: 'inbox/new-message',
      }}
    />
  );
};

export default TaxResidencyNotification;
