exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".-VideoPlayer-videoPlayerContainer-2JL0F {\n  width: 100%;\n  height: 100%;\n  position: relative;\n}\n\n.-VideoPlayer-pauseButton-1etPg {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 5;\n  background: transparent;\n  cursor: pointer;\n}\n\n.-VideoPlayer-playButton-1aJ2f {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  cursor: pointer;\n}\n\n.-VideoPlayer-muteUnmuteButton-1KEQz {\n  position: absolute;\n  right: 10px;\n  bottom: 10px;\n  cursor: pointer;\n  z-index: 10;\n  padding: 5px;\n}\n\n.-VideoPlayer-replayButton-1bLrD {\n  position: absolute;\n  bottom: 10px;\n  left: 10px;\n  cursor: pointer;\n  z-index: 10;\n  padding: 5px;\n}\n\n.-VideoPlayer-muteUnmuteButton-1KEQz > img,\n.-VideoPlayer-replayButton-1bLrD > img {\n  opacity: 85%;\n  vertical-align: middle;\n}", ""]);

// exports
exports.locals = {
	"videoPlayerContainer": "-VideoPlayer-videoPlayerContainer-2JL0F",
	"pauseButton": "-VideoPlayer-pauseButton-1etPg",
	"playButton": "-VideoPlayer-playButton-1aJ2f",
	"muteUnmuteButton": "-VideoPlayer-muteUnmuteButton-1KEQz",
	"replayButton": "-VideoPlayer-replayButton-1bLrD"
};