/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import get from 'lodash/get';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import BaseGrid from '../../components/BaseGrid/BaseGrid';
import { sendGaPageEvent } from '../../util/googleAnalyticHelpers';
import MessageCard from '../../components/MessageCard/MessageCard';
import PostSignUpAnimatedVideo from '../../components/PostSignUpAnimatedVideo/PostSignUpAnimatedVideo';
import GettingStarted from '../GettingStarted/GettingStarted';
import WithMentionMe from '../../components/WithMentionMe/WithMentionMe';
import VulnerableCustomerNotification from '../../components/Notifications/VulnerableCustomerNotification';
import OngoingDashboard from './OngoingDashboard/OngoingDashboard';
import OptOutCustomer from './sections/OptOutCustomer';
import OngoingCustomerMissingRP from './sections/OngoingCustomerMissingRP';
import OptOutCustomerFullEncashment from './sections/OptOutCustomerFullEncashment';
import OngoingCustomerMultipleRP from './sections/OngoingCustomerMultipleRP';
import Onboarding from './OnboardingDashboard/Onboarding';
import Notifications from './sections/Notifications';
import OnboardingSerps from './OnboardingDashboard/Serps/OnboardingSerps';

import { getShouldShowVCNotification, getIsAnyNotificationActive } from '../../redux/selectors/notifications';
import { getConversationsByRead, getLatestConversation } from '../../redux/selectors/conversations';
import { getPensions } from '../../redux/selectors/pensions';
import { getHasRequestedTrace, getWasSerpsTraceRequested } from '../../redux/selectors/serps';
import { getIsAddPensionsAllowed } from '../../redux/selectors/journeys';
import { newMessageDashboardCtaClickedAction } from '../../redux/modules/conversations';
import { getHasOnboardingSyncedBack, getForceMentionMeModal } from '../../redux/selectors/investmentAdvice';
import {
  getPasswordReset,
  getFirstName,
  getUserProfileCreatedBeforeMillis,
} from '../../redux/selectors';
import {
  getIsCustomerOngoing,
  getIsCustomerOptout,
  getIsCustomerOnboarding,
  getIsCustomerOngoingMissingRp,
  getIsCustomerOngoingMultipleRps,
  getIsCustomerOptoutFullEncashment,
  getShouldShowMSMBanner,
} from '../../redux/selectors/experience';
import {
  PAGE_VIEW,
  ONGOING_PAGE_VIEW,
  OPTOUT_PAGE_VIEW,
  ONGOING_MISSING_RP_PAGE_VIEW,
  mentionMeModalForceShownAction,
} from '../../redux/modules/dashboard';

import { twoHoursInMilliseconds } from '../../util/constants';
import styles from './DashboardPage.css';

export const UnreadMessageNotification = connect(
  null,
  {
    dispatchNewMessageDashboardCtaClicked: newMessageDashboardCtaClickedAction,
  },
)(({
  unreadConversations,
  context,
  dispatchNewMessageDashboardCtaClicked,
}) => {
  return (
    <div
      className={
        classNames(
          styles.unreadNotificationContainer,
          {
            [styles.dashboard]: context === 'dashboard',
          },
        )
      }
    >
      <div className={styles.unreadMessagesIcon} />
      <div
        className={
          classNames(
            styles.subheadingMessage,
            {
              [styles.forest]: context === 'initialSignup',
            },
          )
        }
      >
        <Link
          to="/inbox"
          onClick={dispatchNewMessageDashboardCtaClicked}
        >
          {`You have ${unreadConversations.length} unread message${unreadConversations.length > 1 ? 's' : ''} →`}
        </Link>
      </div>
    </div>
  );
});

class DashboardPage extends React.Component {
  componentDidMount() {
    const { pageLoaded } = this.props;

    window.scrollTo(0, 0);
    sendGaPageEvent('/dashboard');
    const pageViewEvent = this.getPageViewEvent();
    pageLoaded(pageViewEvent);

    this.injectMentionMe();
  }

  componentWillUnmount() {
    if (this.mentionMeTimeout) {
      clearTimeout(this.mentionMeTimeout);
    }
  }

  getPageViewEvent() {
    const {
      isCustomerOngoing,
      isCustomerOptout,
      isCustomerOngoingMissingRp,
      isCustomerOptoutFullEncashment,
    } = this.props;

    if (isCustomerOngoing) {
      return ONGOING_PAGE_VIEW;
    }
    if (isCustomerOptout) {
      return OPTOUT_PAGE_VIEW;
    }
    if (isCustomerOngoingMissingRp) {
      return ONGOING_MISSING_RP_PAGE_VIEW;
    }
    if (isCustomerOptoutFullEncashment) {
      return ONGOING_PAGE_VIEW;
    }
    return PAGE_VIEW;
  }

  setMentionMeTimeout = () => {
    this.mentionMeTimeout = setTimeout(() => {
      const mmLink = document.getElementById('mmLink');
      if (!mmLink) {
        this.setMentionMeTimeout();
        return;
      }
      mmLink.click();
      const { dispatchMentionMeModalForceShown, isCustomerOngoing } = this.props;
      dispatchMentionMeModalForceShown(isCustomerOngoing);
    }, 500);
  }

  injectMentionMe = () => {
    const { forceMentionMeModal } = this.props;
    if (forceMentionMeModal) {
      this.setMentionMeTimeout();
    }
  }

  showCorrectExperience = () => {
    const {
      isCustomerOngoingMissingRp,
      isCustomerOptout,
      isCustomerOptoutFullEncashment,
      isCustomerOngoingMultipleRps,
      wasSerpsTraceRequested,
    } = this.props;

    if (isCustomerOptout) return <OptOutCustomer />;
    if (wasSerpsTraceRequested) return <OnboardingSerps />;
    if (isCustomerOngoingMissingRp) return <OngoingCustomerMissingRP />;
    if (isCustomerOptoutFullEncashment) return <OptOutCustomerFullEncashment />;
    if (isCustomerOngoingMultipleRps) return <OngoingCustomerMultipleRP />;

    return <Onboarding />;
  }

  render() {
    const {
      passwordReset,
      firstName,
      pensions = [],
      hasRequestedTrace,
      isAddPensionsAllowed,
      isCustomerOngoing,
      isCustomerOnboarding,
      hasOnboardingSyncedBack,
      unreadConversations,
      isFirstSession,
      latestConversation,
      shouldShowVCNotification,
      isAnyNotificationActive,
      shouldShowMSMBanner,
      isCustomerOptoutFullEncashment,
      isCustomerOptout,
    } = this.props;

    const hasPensions = Boolean(pensions.length);
    const journeyComplete = (
      (hasPensions || hasRequestedTrace) && isAddPensionsAllowed
    ) || isCustomerOngoing;

    let headingMessage;
    if (journeyComplete) {
      headingMessage = firstName ? `Welcome, ${firstName}` : 'Welcome';
    } else {
      headingMessage = firstName ? `${firstName}, let’s continue` : 'Let’s continue';
    }

    const subheadingMessage = unreadConversations.length
      ? (
        <UnreadMessageNotification unreadConversations={unreadConversations} context="dashboard" />
      )
      : null;

    const heading = (
      <div className={styles.headingContainer}>
        <h2 className={styles.heading}>{headingMessage}</h2>
        {subheadingMessage}
      </div>
    );

    const signUpHeading = (
      <div className={styles.headingContainer}>
        <h2 className={styles.heading}>{`Welcome ${firstName}`}</h2>
        {subheadingMessage}
      </div>
    );

    const latestConversationId = get(latestConversation, 'conversationId', null);

    if (isCustomerOnboarding && (!hasOnboardingSyncedBack || !isAddPensionsAllowed)) {
      return (
        <BaseGrid
          tabletTwelveColumn
          heading={(!isFirstSession || !!unreadConversations.length) && signUpHeading}
          showMsmTile={shouldShowMSMBanner}
        >
          {(passwordReset || shouldShowVCNotification) && (
            <div className={styles.initialSignupNotificationAlertsWrapper}>
              {passwordReset && (
                <div className={styles.dashboardHeader}>
                  <MessageCard>{'Your password has been updated.'}</MessageCard>
                </div>
              )}
              {shouldShowVCNotification && (
                <div className={styles.notificationAlertContainer}>
                  <VulnerableCustomerNotification latestConversationId={latestConversationId} />
                </div>
              )}
            </div>
          )}
          {shouldShowMSMBanner ? (
            <PostSignUpAnimatedVideo
              showMsmTile={shouldShowMSMBanner}
            />
          ) : (
            <GettingStarted />
          )}
        </BaseGrid>
      );
    }

    if (isCustomerOngoing || isCustomerOptoutFullEncashment || isCustomerOptout) {
      return (
        <WithMentionMe>
          <OngoingDashboard heading={heading} />
        </WithMentionMe>
      );
    }

    return (
      <WithMentionMe>
        <BaseGrid
          heading={heading}
          tabletTwelveColumn
          messageWidget
          showMsmTile={shouldShowMSMBanner}
        >
          {isAnyNotificationActive && <Notifications />}
          {this.showCorrectExperience()}
        </BaseGrid>
      </WithMentionMe>
    );
  }
}

const mapStateToProps = (state) => ({
  passwordReset: getPasswordReset(state),
  pensions: getPensions(state),
  firstName: getFirstName(state),
  hasRequestedTrace: getHasRequestedTrace(state),
  isAddPensionsAllowed: getIsAddPensionsAllowed(state),
  isCustomerOngoing: getIsCustomerOngoing(state),
  isCustomerOnboarding: getIsCustomerOnboarding(state),
  isCustomerOptout: getIsCustomerOptout(state),
  isCustomerOngoingMissingRp: getIsCustomerOngoingMissingRp(state),
  isCustomerOptoutFullEncashment: getIsCustomerOptoutFullEncashment(state),
  hasOnboardingSyncedBack: getHasOnboardingSyncedBack(state),
  forceMentionMeModal: getForceMentionMeModal(state),
  unreadConversations: getConversationsByRead(state).unread,
  isCustomerOngoingMultipleRps: getIsCustomerOngoingMultipleRps(state),
  isFirstSession: getUserProfileCreatedBeforeMillis(
    state,
    twoHoursInMilliseconds,
  ),
  latestConversation: getLatestConversation(state),
  shouldShowVCNotification: getShouldShowVCNotification(state),
  isAnyNotificationActive: getIsAnyNotificationActive(state),
  shouldShowMSMBanner: getShouldShowMSMBanner(state),
  wasSerpsTraceRequested: getWasSerpsTraceRequested(state),
});

const mapDispatchToProps = {
  pageLoaded: (pageView) => ({ type: pageView }),
  dispatchMentionMeModalForceShown: mentionMeModalForceShownAction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect(),
)(withRouter(DashboardPage));
