import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import once from 'lodash/once';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import styles from './PersonalDetails.css';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import CheckBoxGreen from '../../components/CheckBoxGreen/CheckBoxGreen';

import {
  missingDetailsReturnLinkClickedAction,
  missingDetailsMessageMeClickedAction,
  missingDetailsPostcodeDeleted,
  missingDetailsPostcodeTyped,
  missingDetailsEditPreviousAddressClickedAction,
  missingDetailsPreviousAddressSaveClickedAction,
  missingDetailsSavePreviousAddressClickedAction,
} from '../../redux/modules/signupFlow';
import { getUser } from '../../redux/selectors';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import TimeSpentAtAddressField from '../../components/DatePickerField/TimeSpentAtAddressField';

class ConfirmAddressStep extends Component {
  constructor(props) {
    super(props);

    const {
      dispatchMissingDetailsPostcodeDeleted,
      dispatchMissingDetailsPostcodeTyped,
    } = this.props;

    this.dispatchMissingDetailsPostcodeDeleted = once(dispatchMissingDetailsPostcodeDeleted);
    this.dispatchMissingDetailsPostcodeTyped = once(dispatchMissingDetailsPostcodeTyped);
    this.missingDetailsEditPreviousAddressClickedAction = once(missingDetailsEditPreviousAddressClickedAction);
    this.missingDetailsPreviousAddressSaveClickedAction = once(missingDetailsPreviousAddressSaveClickedAction);
    this.dispatchMissingDetailsSavePreviousAddressClickedAction = once(missingDetailsSavePreviousAddressClickedAction);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      step,
      totalSteps,
      amendMode,
      setStep,
      dispatchMissingDetailsReturnLinkClickedAction,
      dispatchMissingDetailsMessageMeClickedAction,
      dispatchMissingDetailsEditPreviousAddressClickedAction,
      dispatchMissingDetailsPreviousAddressSaveClickedAction,
      dispatchMissingDetailsSavePreviousAddressClickedAction,
      formData,
      setFormData,
      isSubmitting,
      isSubmitLoading,
      setIsSubmitLoading,
      editPreviousAddress,
      setEditPreviousAddress,
      componentIsEditing,
      setComponentIsEditing,
      datesCheckBoxClicked,
      setDatesCheckBoxClicked,
      setShowFields,
      timeFrom,
      setTimeFrom,
      timeTo,
      setTimeTo,
      errorMessage,
      setErrorMessage,
      errorText,
      setErrorText,
    } = this.props;

    const { updatedAddress } = formData;

    const showEdit = !editPreviousAddress && !componentIsEditing;
    const showSave = editPreviousAddress && componentIsEditing;
    const disableConfirmAddressButton = updatedAddress.line1 === '' || updatedAddress.city === '';

    const noPreviousAddressDates = (!timeFrom || !timeTo) && !datesCheckBoxClicked;

    const disableSaveButton = noPreviousAddressDates || isSubmitting;

    const joinAddress = (values) => {
      return values.filter((s) => s).join(', ');
    };

    const formattedStreet = joinAddress([formData.updatedAddress.line1,
      formData.updatedAddress.line2,
      formData.updatedAddress.line3,
      formData.updatedAddress.line4]);

    return (
      <BaseGrid tabletTwelveColumn addPensionsTheme>
        <div className={styles.missingDetailsWrapper}>
          <div className={styles.progressContainer}>
            <ProgressStepper maxStep={totalSteps} step={step + 1} />
          </div>
          {!amendMode && (
          <ButtonLink
            onClick={() => {
              dispatchMissingDetailsReturnLinkClickedAction();
              setShowFields(false);
              setTimeFrom(null);
              setTimeTo(null);
              setStep(step - 1);
            }}
            label="← Back"
          />
          )}
          <h1 className={styles.ninoHeading}>
            {'Please confirm the address'}
          </h1>
          { showEdit && (
            <div className={styles.addressContainer}>
              <div className={styles.addressContent}>
                <div>{formData.updatedAddress.line1}</div>
                <div>{formData.updatedAddress.line2}</div>
                <div>{formData.updatedAddress.line3}</div>
                <div>{formData.updatedAddress.line4}</div>
                <div>{formData.updatedAddress.city}</div>
                <div>{formData.updatedAddress.county}</div>
                <div>{formData.updatedAddress.postCode}</div>
              </div>
            </div>
          )}
          {editPreviousAddress && componentIsEditing && (
            <div>
              <TextField
                value={updatedAddress.line1}
                defaultValue={'Line 1'}
                touched
                onChange={(event) => {
                  const value = get(event, 'target.value');
                  if (!value) {
                    setErrorText(true);
                  } else {
                    setErrorText(false);
                  }
                  setFormData((_formData) => ({
                    ..._formData,
                    updatedAddress: { ...updatedAddress, line1: value },
                  }));
                }}
              />
                {errorText && (
                  <div className={styles.errorMessage}>
                    {'Please enter the first line of your address'}
                  </div>
                )}
              <TextField
                value={updatedAddress.line2}
                defaultValue={'Line 2'}
                onChange={(event) => {
                  const value = get(event, 'target.value');
                  setFormData((_formData) => ({
                    ..._formData,
                    updatedAddress: { ...updatedAddress, line2: value },
                  }));
                }}
              />
              <TextField
                value={updatedAddress.line3}
                defaultValue={'Line 3'}
                onChange={(event) => {
                  const value = get(event, 'target.value');
                  setFormData((_formData) => ({
                    ..._formData,
                    updatedAddress: { ...updatedAddress, line3: value },
                  }));
                }}
              />
              <TextField
                value={updatedAddress.line4}
                defaultValue={'Line 4'}
                onChange={(event) => {
                  const value = get(event, 'target.value');
                  setFormData((_formData) => ({
                    ..._formData,
                    updatedAddress: { ...updatedAddress, line4: value },
                  }));
                }}
              />
              <TextField
                value={updatedAddress.city}
                defaultValue={'City'}
                touched
                onChange={(event) => {
                  const value = get(event, 'target.value');
                  if (!value) {
                    setErrorMessage(true);
                  } else {
                    setErrorMessage(false);
                  }
                  setFormData((_formData) => ({
                    ..._formData,
                    updatedAddress: { ...updatedAddress, city: value },
                  }));
                }}
              />
              {errorMessage && (
              <div className={styles.errorMessage}>
                {'Please enter your city'}
              </div>
              )}
              <TextField
                value={updatedAddress.county}
                defaultValue={'County'}
                onChange={(event) => {
                  const value = get(event, 'target.value');
                  setFormData((_formData) => ({
                    ..._formData,
                    updatedAddress: { ...updatedAddress, county: value },
                  }));
                }}
              />
            </div>
          )}
          <div>
            {<div>&nbsp;</div>}
            {showEdit && (
            <Button
              size="large"
              type="button"
              label={'Edit'}
              loading={isSubmitLoading}
              onClick={() => {
                setIsSubmitLoading(true);
                dispatchMissingDetailsEditPreviousAddressClickedAction();
                setShowFields(true);
                setComponentIsEditing(true);
                setEditPreviousAddress(true);
                setIsSubmitLoading(false);
              }}
              wrapperId="missing-details-submit"
            />
            )}
            {showSave && (
            <Button
              size="large"
              type="button"
              label={'Save'}
              disabled={disableConfirmAddressButton}
              loading={isSubmitLoading}
              onClick={() => {
                setIsSubmitLoading(true);
                dispatchMissingDetailsSavePreviousAddressClickedAction();
                setComponentIsEditing(false);
                setEditPreviousAddress(false);
                setIsSubmitLoading(false);
              }}
              wrapperId="missing-details-submit"
            />
            )}
          </div>
          {<div>&nbsp;</div>}
          <div className={styles.confirmAddressContent} font="bold">
            {'When did you live here?'}
          </div>
          <div className={styles.previousAddressDatesContainer}>
            <div>
              {'From'}
              <TimeSpentAtAddressField
                value={timeFrom}
                disabled={datesCheckBoxClicked}
                min={new Date('1900-01-01')}
                max={timeTo || new Date()}
                onChange={(event) => {
                  const value = new Date(event);
                  setTimeFrom(value);
                }}
              />
            </div>
            <div className={styles.timeSpentTo}>
              {'Until'}
              <TimeSpentAtAddressField
                value={timeTo}
                disabled={datesCheckBoxClicked}
                min={timeFrom || new Date('1900-01-01')}
                max={new Date()}
                onChange={(event) => {
                  const value = new Date(event);
                  setTimeTo(value);
                }}
              />
            </div>
          </div>
          {<div>&nbsp;</div>}
          <CheckBoxGreen
            type="checkbox"
            name="datesCheckBox"
            defaultValue={!datesCheckBoxClicked}
            value={datesCheckBoxClicked}
            label={(
              <span>
                {'I can’t remember' }
              </span>
              )}
            onChange={() => {
              setDatesCheckBoxClicked(!datesCheckBoxClicked);
              setTimeFrom(null);
              setTimeTo(null);
            }}
            wrapperStyles={{ marginBottom: '13px' }}
          />
          <div>
            <Button
              size="large"
              type="button"
              label={'Save & continue'}
              disabled={disableSaveButton}
              loading={isSubmitLoading}
              onClick={() => {
                dispatchMissingDetailsPreviousAddressSaveClickedAction();
                setFormData((_formData) => ({
                  ..._formData,
                  updatedAddress: undefined,
                  previousAddresses: [..._formData.previousAddresses,
                    {
                      ...updatedAddress,
                      street: formattedStreet,
                      livedAtAddressDateFrom: timeFrom,
                      livedAtAddressDateTo: timeTo,
                      addedDate: new Date(),
                    }],
                }));
                setIsSubmitLoading(true);
                setDatesCheckBoxClicked(false);
                setTimeFrom(null);
                setTimeTo(null);
                setIsSubmitLoading(false);
                setStep(step - 2);
              }}
              wrapperId="missing-details-submit"
            />
          </div>
          {<div>&nbsp;</div>}
          <div className={styles.pensionAdviserCard}>
            <ContactUsContent
              source={'ConfirmAddressStep'}
              noExpertContentWrapper={({ children }) => (
                <div
                  style={{
                    display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                  }}
                >
                  {children}
                </div>
              )}
              showButton={false}
              customMessage={(
                <div
                  className={styles.contactUsInnerContent}
                >
                  {'If you have any questions, please feel free to '}
                  <ButtonLink
                    label="message me"
                    variant="primary"
                    link="/inbox/new-message"
                    font="inherit"
                    target="_blank"
                    onClick={() => dispatchMissingDetailsMessageMeClickedAction()}
                  />
                  {'.'}
                </div>
              )}
            />
          </div>
        </div>
      </BaseGrid>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  dispatchMissingDetailsPostcodeDeleted: () => dispatch(missingDetailsPostcodeDeleted()),
  dispatchMissingDetailsPostcodeTyped: () => dispatch(missingDetailsPostcodeTyped()),

  dispatchMissingDetailsReturnLinkClickedAction:
  () => dispatch(missingDetailsReturnLinkClickedAction()),
  dispatchMissingDetailsMessageMeClickedAction:
  () => dispatch(missingDetailsMessageMeClickedAction()),
  dispatchMissingDetailsSavePreviousAddressClickedAction:
  () => dispatch(missingDetailsSavePreviousAddressClickedAction()),
  dispatchMissingDetailsEditPreviousAddressClickedAction:
  () => dispatch(missingDetailsEditPreviousAddressClickedAction()),
  dispatchMissingDetailsPreviousAddressSaveClickedAction:
  () => dispatch(missingDetailsPreviousAddressSaveClickedAction()),
});

const mapStateToProps = (state) => ({
  user: getUser(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmAddressStep);
