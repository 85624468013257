exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".-Modal-backdrop-3pyz2 {\n  position: fixed;\n  top: 0px;\n  bottom: 0px;\n  left: 0px;\n  right: 0px;\n  background-color: rgba(0,0,0,0.5);\n  padding: 16px;\n  z-index: 250;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n\n.-Modal-backdrop-3pyz2.-Modal-foggyBackdrop-1GTwO {\n  background-color: rgba(61,61,61,0.65098);\n}\n\n.-Modal-backdrop-3pyz2.-Modal-introModal-1YUHm {\n  padding: 0;\n  width: 100%;\n}\n\n.-Modal-modal-Fr9Ly {\n  width: 100%;\n  max-width: 650px;\n  max-height: 100%;\n  height: 100vh;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  overflow-y: auto;\n}\n\n.-Modal-modal-Fr9Ly.-Modal-modalFullMaxWidth-EIpAJ {\n  max-width: 100%;\n}\n\n.-Modal-modal-Fr9Ly.-Modal-centerContent-2GvBN {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n\n.-Modal-modal-Fr9Ly::-webkit-scrollbar {\n  display: none;\n}\n\n.-Modal-modal-Fr9Ly {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n", ""]);

// exports
exports.locals = {
	"backdrop": "-Modal-backdrop-3pyz2",
	"foggyBackdrop": "-Modal-foggyBackdrop-1GTwO",
	"introModal": "-Modal-introModal-1YUHm",
	"modal": "-Modal-modal-Fr9Ly",
	"modalFullMaxWidth": "-Modal-modalFullMaxWidth-EIpAJ",
	"centerContent": "-Modal-centerContent-2GvBN"
};