exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".-RenderHyperlink-preWrapLink-pHQXu {\n    word-wrap: break-word;\n    word-wrap: break-word;\n    \n    /* Multi browser support for word-break */\n    -ms-word-break: break-all;\n    word-break: break-all;\n    word-break: break-word;\n  \n    /* Add hyphen if it is supported by the browser */\n    -ms-hyphens: auto;\n    hyphens: auto;\n}", ""]);

// exports
exports.locals = {
	"preWrapLink": "-RenderHyperlink-preWrapLink-pHQXu"
};