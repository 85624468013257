import React from 'react';
import get from 'lodash/get';

import ContactUsContent from '../../../components/ContactUsContent/ContactUsContent';

const ContactUs = (props) => {
  const {
    pensionExpert,
    firstName,
    email,
  } = props;

  const contactUsHeading = `Hello, I’m ${get(pensionExpert, 'firstName')}, your Adviser`;
  const contactUsMessage = 'If you have any questions about your pension, please feel free to contact me.';

  return (
    <ContactUsContent
      heading={contactUsHeading}
      message={contactUsMessage}
      customerFirstName={firstName}
      customerEmail={email}
      source={'Dashboard'}
    />
  );
};

export default ContactUs;
