import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import get from 'lodash/get';
import sentryException from '../../util/sentryException';

export const VERIFY_LINK_CLICKED = 'pp/account/VERIFY_CTA_CLICKED';

const SEND_EMAIL_OTP_DISPATCHED = 'pp/account/SEND_EMAIL_OTP_DISPATCHED';
const SEND_EMAIL_OTP_FULFILLED = 'pp/account/SEND_EMAIL_OTP_FULFILLED';
const SEND_EMAIL_OTP_REJECTED = 'pp/account/SEND_EMAIL_OTP_REJECTED';

export const dispatchSendEmailOtp = (uid, recaptchaToken) => (
  async (dispatch) => {
    dispatch({ type: SEND_EMAIL_OTP_DISPATCHED });
    try {
      const sendEmailOtpFunction = firebase.app().functions('europe-west1').httpsCallable('emailVerification-sendEmailOtp');
      const response = await sendEmailOtpFunction({ recaptchaToken });
      dispatch({ type: SEND_EMAIL_OTP_FULFILLED });
      return response;
    } catch (error) {
      dispatch({ type: SEND_EMAIL_OTP_REJECTED });
      sentryException(error, {
        section: 'failed-to-send-email-otp',
      });
      return null;
    }
  }
);

const VERIFY_OTP_DISPATCHED = 'pp/account/VERIFY_OTP_DISPATCHED';
const VERIFY_OTP_FULFILLED = 'pp/account/VERIFY_OTP_FULFILLED';
const VERIFY_OTP_INCORRECT = 'pp/account/VERIFY_OTP_INCORRECT';
const VERIFY_OTP_EXPIRED = 'pp/account/VERIFY_OTP_EXPIRED';
const VERIFY_OTP_REJECTED = 'pp/account/VERIFY_OTP_REJECTED';

export const dispatchVerifyOtp = (uid, otpCode, recaptchaToken) => (
  async (dispatch) => {
    dispatch({ type: VERIFY_OTP_DISPATCHED });
    try {
      const verifyOtpFunction = firebase.app().functions('europe-west1').httpsCallable('emailVerification-verifyOtp');
      const { data: { otpCorrect, expired } } = await verifyOtpFunction({
        otpCode,
        recaptchaToken,
      });

      dispatch({ type: VERIFY_OTP_FULFILLED });

      if (expired) {
        dispatch({ type: VERIFY_OTP_EXPIRED });
      } else if (!otpCorrect) {
        dispatch({ type: VERIFY_OTP_INCORRECT });
      }

      return { otpCorrect, expired };
    } catch (error) {
      dispatch({ type: VERIFY_OTP_REJECTED });
      sentryException(error, {
        section: 'failed-to-verify-otp',
      });
      return null;
    }
  }
);

const SEND_CHANGE_EMAIL_OTP_DISPATCHED = 'pp/account/SEND_CHANGE_EMAIL_OTP_DISPATCHED';
const SEND_CHANGE_EMAIL_OTP_FULFILLED = 'pp/account/SEND_CHANGE_EMAIL_OTP_FULFILLED';
const SEND_CHANGE_EMAIL_OTP_EMAIL_IN_USE = 'pp/account/SEND_CHANGE_EMAIL_OTP_EMAIL_IN_USE';
const SEND_CHANGE_EMAIL_OTP_REJECTED = 'pp/account/SEND_CHANGE_EMAIL_OTP_REJECTED';
const SEND_CHANGE_EMAIL_OTP_CANCELLED = 'pp/account/SEND_CHANGE_EMAIL_OTP_CANCELLED';

export const dispatchSendChangeEmailOtp = (uid, newEmail, recaptchaToken) => (
  async (dispatch) => {
    dispatch({ type: SEND_CHANGE_EMAIL_OTP_DISPATCHED });
    try {
      const sendChangeEmailOtpFunction = firebase.app().functions('europe-west1').httpsCallable('emailVerification-sendChangeEmailOtp');
      const response = await sendChangeEmailOtpFunction({ recaptchaToken, newEmail });
      const emailInUse = get(response, 'data.emailInUse');

      dispatch({ type: SEND_CHANGE_EMAIL_OTP_FULFILLED });

      if (emailInUse) {
        dispatch({ type: SEND_CHANGE_EMAIL_OTP_EMAIL_IN_USE });
      }

      return { emailInUse };
    } catch (error) {
      dispatch({ type: SEND_CHANGE_EMAIL_OTP_REJECTED });
      sentryException(error, {
        section: 'failed-to-send-change-email-otp',
      });
      throw error;
    }
  }
);

export const dispatchSendChangeEmailOtpCancelled = () => (dispatch) => dispatch({ type: SEND_CHANGE_EMAIL_OTP_CANCELLED });

const VERIFY_CHANGE_EMAIL_OTP_DISPATCHED = 'pp/account/VERIFY_CHANGE_EMAIL_OTP_DISPATCHED';
const VERIFY_CHANGE_EMAIL_OTP_FULFILLED = 'pp/account/VERIFY_CHANGE_EMAIL_OTP_FULFILLED';
const VERIFY_CHANGE_EMAIL_OTP_INCORRECT = 'pp/account/VERIFY_CHANGE_EMAIL_OTP_INCORRECT';
const VERIFY_CHANGE_EMAIL_OTP_EXPIRED = 'pp/account/VERIFY_CHANGE_EMAIL_OTP_EXPIRED';
const VERIFY_CHANGE_EMAIL_OTP_REJECTED = 'pp/account/VERIFY_CHANGE_EMAIL_OTP_REJECTED';
const VERIFY_CHANGE_EMAIL_OTP_CANCELLED = 'pp/account/VERIFY_CHANGE_EMAIL_OTP_CANCELLED';
const VERIFY_CHANGE_EMAIL_OTP_RESEND_CODE = 'pp/account/VERIFY_CHANGE_EMAIL_OTP_RESEND_CODE';

export const dispatchVerifyChangeEmailOtp = (uid, otpCode, recaptchaToken) => (
  async (dispatch) => {
    dispatch({ type: VERIFY_CHANGE_EMAIL_OTP_DISPATCHED });
    try {
      const verifyChangeEmailOtpFunction = firebase.app().functions('europe-west1').httpsCallable('emailVerification-verifyChangeEmailOtp');
      const { data: { otpCorrect, expired } } = await verifyChangeEmailOtpFunction({
        otpCode,
        recaptchaToken,
      });

      dispatch({ type: VERIFY_CHANGE_EMAIL_OTP_FULFILLED });

      if (expired) {
        dispatch({ type: VERIFY_CHANGE_EMAIL_OTP_EXPIRED });
      } else if (!otpCorrect) {
        dispatch({ type: VERIFY_CHANGE_EMAIL_OTP_INCORRECT });
      }

      return { otpCorrect, expired };
    } catch (error) {
      dispatch({ type: VERIFY_CHANGE_EMAIL_OTP_REJECTED });
      sentryException(error, {
        section: 'failed-to-verify-change-email-otp',
      });
      throw error;
    }
  }
);

export const dispatchVerifyChangeEmailOtpCancelled = () => (dispatch) => dispatch({ type: VERIFY_CHANGE_EMAIL_OTP_CANCELLED });
export const dispatchVerifyChangeEmailOtpResendCode = () => (dispatch) => dispatch({ type: VERIFY_CHANGE_EMAIL_OTP_RESEND_CODE });

const INITIAL_CHANGE_EMAIL_BUTTON_CLICKED = 'pp/account/INITIAL_CHANGE_EMAIL_BUTTON_CLICKED';

export const dispatchInitialChangeEmailButtonClicked = () => (dispatch) => dispatch({ type: INITIAL_CHANGE_EMAIL_BUTTON_CLICKED });

/**
 * TWO_FACTOR_AUTHENTICATION: events and functions
 */
const ENABLE_TWO_FACTOR_AUTHENTICATION_BUTTON_CLICKED = 'pp/account/ENABLE_TWO_FACTOR_AUTHENTICATION_BUTTON_CLICKED';
export const dispatchEnableTwoFactorAuthenticationButtonClicked = () => (dispatch) => dispatch({ type: ENABLE_TWO_FACTOR_AUTHENTICATION_BUTTON_CLICKED });

const DISABLE_TWO_FACTOR_AUTHENTICATION_BUTTON_CLICKED = 'pp/account/DISABLE_TWO_FACTOR_AUTHENTICATION_BUTTON_CLICKED';
export const dispatchDisableTwoFactorAuthenticationButtonClicked = () => (dispatch) => dispatch({ type: DISABLE_TWO_FACTOR_AUTHENTICATION_BUTTON_CLICKED });

const SEND_TWO_FACTOR_AUTHENTICATION_FLAG = 'pp/account/SEND_TWO_FACTOR_AUTHENTICATION_FLAG';
const SEND_TWO_FACTOR_AUTHENTICATION_FLAG_FULFILLED = 'pp/account/SEND_TWO_FACTOR_AUTHENTICATION_FLAG_FULFILLED';
const SEND_TWO_FACTOR_AUTHENTICATION_FLAG_REJECTED = 'pp/account/SEND_TWO_FACTOR_AUTHENTICATION_FLAG_REJECTED';
const SEND_TWO_FACTOR_AUTHENTICATION_NEW_MOBILE_ENTERED = 'pp/account/SEND_TWO_FACTOR_AUTHENTICATION_NEW_MOBILE_ENTERED';
const SEND_TWO_FACTOR_AUTHENTICATION_OTP_CANCELLED = 'pp/account/SEND_TWO_FACTOR_AUTHENTICATION_OTP_CANCELLED';

export const dispatchSendTwoFactorAuthenticationNewMobileEntered = () => (dispatch) => dispatch({ type: SEND_TWO_FACTOR_AUTHENTICATION_NEW_MOBILE_ENTERED });
export const dispatchSendTwoFactorAuthenticationOtpCancelled = () => (dispatch) => dispatch({ type: SEND_TWO_FACTOR_AUTHENTICATION_OTP_CANCELLED });

const VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG = 'pp/account/VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG';
const VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG_FULFILLED = 'pp/account/VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG_FULFILLED';
const VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG_REJECTED = 'pp/account/VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG_REJECTED';
const VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG_INCORRECT = 'pp/account/VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG_INCORRECT';
const VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG_EXPIRED = 'pp/account/VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG_EXPIRED';
const VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG_MAX_TRIES_REACHED = 'pp/account/VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG_MAX_TRIES_REACHED';
const VERIFY_TWO_FACTOR_AUTHENTICATION_OTP_RESEND_CODE = 'pp/account/VERIFY_TWO_FACTOR_AUTHENTICATION_OTP_RESEND_CODE';
const VERIFY_TWO_FACTOR_AUTHENTICATION_OTP_CANCELLED = 'pp/account/VERIFY_TWO_FACTOR_AUTHENTICATION_OTP_CANCELLED';

export const dispatchVerifyTwoFactorAuthenticationOtpResendCode = () => (dispatch) => dispatch({ type: VERIFY_TWO_FACTOR_AUTHENTICATION_OTP_RESEND_CODE });
export const dispatchVerifyTwoFactorAuthenticationOtpCancelled = () => (dispatch) => dispatch({ type: VERIFY_TWO_FACTOR_AUTHENTICATION_OTP_CANCELLED });

export const sendSMSOtp = (twoFactorAuthenticationEnabled, mobileNumber, recaptchaToken) => (
  async (dispatch) => {
    const sendSMSOtpFunction = firebase.app().functions('europe-west1').httpsCallable('smsVerification-sendSMSOtp');
    dispatch({ type: SEND_TWO_FACTOR_AUTHENTICATION_FLAG });

    try {
      await sendSMSOtpFunction({ twoFactorAuthenticationEnabled, mobileNumber, recaptchaToken });
      dispatch({ type: SEND_TWO_FACTOR_AUTHENTICATION_FLAG_FULFILLED });
    } catch (e) {
      dispatch({ type: SEND_TWO_FACTOR_AUTHENTICATION_FLAG_REJECTED });
      sentryException(e, {
        section: 'two-factor-authentication-send-sms-otp',
      });
      throw e;
    }
  });

export const updateTwoFactorAuthenticationFlag = (code, recaptchaToken) => (
  async (dispatch) => {
    const updateTwoFactorAuthentication = firebase.app().functions('europe-west1').httpsCallable('updateTwoFactorAuthenticationFlag');
    dispatch({ type: VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG });

    try {
      const {
        data: {
          success,
          max_tries_reached: maxTriesReached,
          incorrect_code: incorrectCode,
          retriesLeft,
          expired,
        },
      } = await updateTwoFactorAuthentication({
        code,
        recaptchaToken,
      });

      dispatch({ type: VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG_FULFILLED });

      if (incorrectCode) {
        dispatch({ type: VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG_INCORRECT });
      } else if (expired) {
        dispatch({ type: VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG_EXPIRED });
      } else if (maxTriesReached) {
        dispatch({ type: VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG_MAX_TRIES_REACHED });
      }

      return {
        success, incorrectCode, expired, maxTriesReached, retriesLeft,
      };
    } catch (e) {
      dispatch({ type: VERIFY_TWO_FACTOR_AUTHENTICATION_FLAG_REJECTED });
      sentryException(e, {
        section: 'two-factor-authentication-verify-sms-otp',
      });
      throw e;
    }
  });
