import React from 'react';

import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { AUTHORISE_PAGE_LINK_CLICKED } from '../../redux/modules/ongoingAdvice';

const UpdatePensionPlanNotification = () => {
  return (
    <NotificationBanner
      alertCategory={'Blue'}
      alertTitle={'We recommend updating your pension plan'}
      expandableAlertDescription={''}
      ctaButtonOptions={{
        label: 'View',
        link: '/authorise-change',
        event: AUTHORISE_PAGE_LINK_CLICKED,
      }}
    />
  );
};

export default UpdatePensionPlanNotification;
