/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { isFilenameValid } from '../../util/attachments';
import { FILE_NAME_INVALID } from '../../util/constants';

import styles from './FileUploadWrapper.css';

const FileUploadWrapper = ({
  onFilesAccepted,
  onFilesRejected,
  acceptedFilesFormats,
  maxSizeInMb,
  maxFiles,
  children,
  setOnDrag,
}) => {
  const handleDrop = useCallback((acceptedFiles) => {
    onFilesAccepted(acceptedFiles);
    setOnDrag(false);
  });

  const handleRejectedFiles = useCallback((rejectedFiles) => {
    onFilesRejected(rejectedFiles);
  });

  const handleDragEnter = () => {
    setOnDrag(true);
  };

  const handleDragLeave = () => {
    setOnDrag(false);
  };

  const validateFile = (file) => {
    if (!isFilenameValid(file.name)) {
      return { code: FILE_NAME_INVALID };
    }

    return null;
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFilesFormats,
    maxFiles,
    maxSize: maxSizeInMb * 1000000,
    onDrop: handleDrop,
    onDropRejected: handleRejectedFiles,
    validator: validateFile,
    multiple: maxFiles > 1,
    onDragEnter: handleDragEnter,
    onDragLeave: handleDragLeave,
  });

  return (
    <div
      {...getRootProps()}
      className={styles.draggableArea}
    >
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

FileUploadWrapper.propTypes = {
  onFilesAccepted: PropTypes.func.isRequired,
  onFilesRejected: PropTypes.func.isRequired,
  acceptedFilesFormats: PropTypes.string.isRequired,
  maxSizeInMb: PropTypes.number,
  maxFiles: PropTypes.number,
  children: PropTypes.element,
  setOnDrag: PropTypes.func.isRequired,
};

FileUploadWrapper.defaultProps = {
  children: <React.Fragment>{'Upload'}</React.Fragment>,
  maxSizeInMb: 100,
  maxFiles: 0,
};

export default FileUploadWrapper;
/* eslint-enable react/jsx-props-no-spreading */
