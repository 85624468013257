exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-PrivateRoute-sessionExpiredContainer-2GzdX {\n  text-align: center;\n}\n.-PrivateRoute-sessionExpiredTitle-3g9Ma {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + ";\n}\n.-PrivateRoute-sessionExpiredMessage-1DbOg {\n  text-align: center;\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textParagraphRegular"] + ";\n}\n.-PrivateRoute-sessionExpiredButtonsContainer-2lj0z {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-pack: space-evenly;\n      -ms-flex-pack: space-evenly;\n          justify-content: space-evenly;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}", ""]);

// exports
exports.locals = {
	"colours": "\"../../theme/colors.css\"",
	"textTitleRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + "",
	"textParagraphRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textParagraphRegular"] + "",
	"sessionExpiredContainer": "-PrivateRoute-sessionExpiredContainer-2GzdX",
	"sessionExpiredTitle": "-PrivateRoute-sessionExpiredTitle-3g9Ma",
	"sessionExpiredMessage": "-PrivateRoute-sessionExpiredMessage-1DbOg",
	"sessionExpiredButtonsContainer": "-PrivateRoute-sessionExpiredButtonsContainer-2lj0z"
};