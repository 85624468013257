import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FieldLabel from '../FieldLabel/FieldLabel';
import FieldStatusBlock from '../FieldStatusBlock/FieldStatusBlock';
import styles from './CheckBox.css';

export default class CheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { onChange } = this.props;

    onChange(event);
  }

  render() {
    const {
      value,
      name,
      required,
      disabled,
      errorText,
      onBlur,
      onFocus,
      label,
      touched,
      wrapperStyles,
      largeHeadings,
      defaultValue,
    } = this.props;

    const { focused } = this.state;

    const handleFocus = (e) => {
      this.setState({ focused: true });

      if (onFocus) {
        onFocus(e);
      }
    };

    const handleBlur = (e) => {
      if (onBlur) {
        onBlur(e);
      }
      this.setState({ focused: false });
    };

    const inputClass = classnames(styles.textInput, {
      [styles.disabled]: disabled,
    });

    return (
      <div className={styles.wrapper} style={wrapperStyles} ref={(el) => { this.wrapper = el; }}>
        <div className={styles.fieldLabelContainer}>
          <FieldLabel
            label={label}
            required={required}
            disabled={disabled}
            largeHeadings={largeHeadings}
          />
        </div>
        <FieldStatusBlock
          error={errorText}
          focused={focused}
          touched={touched}
          wrapperStyles={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 'fit-content',
            margin: '2px 0 0 0',
          }}
          blockWrapper={{
            display: 'block',
            height: '16px',
            width: '16px',
            margin: 0,
          }}
          statusBlockWrapper={{
            display: 'flex',
          }}
        >
          <input
            type="checkbox"
            checked={(value === undefined || value === null) ? defaultValue : value}
            name={name}
            onChange={(event) => this.handleChange(event)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={inputClass}
            disabled={disabled}
          />
        </FieldStatusBlock>
      </div>
    );
  }
}

CheckBox.propTypes = {
  value: PropTypes.bool,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.bool,
  label: PropTypes.string,
  errorText: PropTypes.node,
  touched: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  wrapperStyles: PropTypes.object,
  largeHeadings: PropTypes.bool,
};

CheckBox.defaultProps = {
  value: false,
  required: false,
  disabled: false,
  label: null,
  errorText: null,
  defaultValue: false,
  touched: false,
  wrapperStyles: {},
  largeHeadings: false,
};
