import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withFirestore } from 'react-redux-firebase';
import { compose } from 'redux';
import { getAttachmentFiles } from '../../redux/selectors';
import { ATTACHMENT_ATTACHED, ATTACHMENT_LOADING } from '../../util/constants';
import sentryException from '../../util/sentryException';
import AttachmentFileShell from '../AttachmentFile/AttachmentFileShell';

const ATTACHMENT_SYNC_TIMEOUT_SEC = 60;

const MessageAttachment = ({
  attachmentUpload, document, files, firebase,
}) => {
  const [downloadUrl, setDownloadUrl] = useState('');
  const [error, setError] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  const attachmentName = attachmentUpload.split('/').pop();
  const fileReference = `/message-attachments/${document}/${attachmentName}`;
  const firebaseAttachmentFile = files && files.find(({ name }) => name.endsWith(fileReference));

  const hasFileSyncedBack = Boolean(firebaseAttachmentFile);
  const status = hasFileSyncedBack ? ATTACHMENT_ATTACHED : ATTACHMENT_LOADING;

  const getDownloadURL = (name) => {
    return new Promise((resolve, reject) => {
      if (!name) {
        reject();
      }
      try {
        const userDocRef = firebase.storage().ref().child(name);
        clearTimeout(timeoutId);
        return resolve(userDocRef.getDownloadURL());
      } catch (e) {
        reject(e);
        sentryException(e, {
          section: 'conversation-add-attachment-getDownloadURL',
        });
      }
    });
  };

  useEffect(() => {
    if (hasFileSyncedBack) {
      getDownloadURL(firebaseAttachmentFile.name)
        .then(setDownloadUrl)
        .catch(() => setError('Something went wrong with attachment'));
    }
  }, [get(firebaseAttachmentFile, 'name'), timeoutId]);

  useEffect(() => {
    if (!hasFileSyncedBack) {
      const timeoutRef = setTimeout(() => {
        sentryException(
          new Error(`Attachment link failed to generate after ${ATTACHMENT_SYNC_TIMEOUT_SEC} seconds`),
          { section: 'conversation-add-attachment-hasFileSyncedBack' },
        );
        setTimeoutId(timeoutRef);
      }, ATTACHMENT_SYNC_TIMEOUT_SEC * 1000);
    }
  }, [firebaseAttachmentFile]);

  return (
    <AttachmentFileShell
      name={attachmentName}
      status={status}
      errorMessage={error}
      isLoading={!hasFileSyncedBack}
      downloadUrl={downloadUrl}
    />
  );
};

const mapStateToProps = (state) => ({
  files: getAttachmentFiles(state),
});

export default compose(withFirestore, connect(mapStateToProps))(MessageAttachment);
