import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ButtonLink from '../ButtonLink/ButtonLink';
import TextField from '../TextField/TextField';
import {
  personalDetailsEnterAddressManuallyClicked,
} from '../../redux/modules/signupFlow';

class AddressPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFields: false,
    };
  }

  textField = (label, key, required) => {
    const {
      name,
      address,
      onChange,
      touched,
      errorText,
      errorTextPerField,
      largeHeadings,
      onBlur,
    } = this.props;

    const errorMessage = errorTextPerField[key] ? errorTextPerField[key] : errorText;
    return (
      <TextField
        name={`${name}-${key}`}
        label={label}
        value={address[key]}
        touched={!!(errorMessage && touched)}
        errorText={errorMessage}
        onChange={(event) => {
          onChange({
            ...address,
            [key]: event.target.value,
          });
        }}
        onBlur={onBlur}
        wrapperStyles={{ maxWidth: '100%' }}
        defaultValue={null}
        largeHeadings={largeHeadings}
        required={required}
      />
    );
  };

  hiddenTextField = (label, key) => {
    const { name, address } = this.props;

    return (
      <input
        name={`${name}-${key}`}
        value={address[key]}
        type="hidden"
      />
    );
  };

  render() {
    const {
      touched,
      errorText,
      dispatchPersonalDetailsEnterAddressManuallyClicked,
      wrapperStyles,
    } = this.props;

    const { showFields } = this.state;

    return (
      <div style={wrapperStyles}>
        <ButtonLink
          onClick={() => {
            this.setState({ showFields: true });
            dispatchPersonalDetailsEnterAddressManuallyClicked();
          }}
          label="Enter address manually"
        />
        {(touched || showFields) && (
          <React.Fragment>
            <div>
              {this.textField('Line 1', 'line1', true)}
              {this.textField('Line 2', 'line2')}
              {this.textField('Line 3', 'line3')}
              {this.textField('Line 4', 'line4')}
              {this.hiddenTextField('locality', 'locality')}
              {this.textField('City', 'city', true)}
              {this.textField('County', 'county')}
            </div>
            {(touched && errorText) && <div>{errorText}</div>}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  // eslint-disable-next-line max-len
  dispatchPersonalDetailsEnterAddressManuallyClicked: () => dispatch(personalDetailsEnterAddressManuallyClicked()),
});

export default connect(null, mapDispatchToProps)(AddressPicker);

AddressPicker.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  address: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    line3: PropTypes.string,
    line4: PropTypes.string,
    locality: PropTypes.string,
    city: PropTypes.string,
    county: PropTypes.string,
  }),
  errorText: PropTypes.string,
  errorTextPerField: PropTypes.objectOf(PropTypes.string),
  wrapperStyles: PropTypes.objectOf(PropTypes.string),
};

AddressPicker.defaultProps = {
  address: {
    line1: '',
    line2: '',
    line3: '',
    line4: '',
    locality: '',
    city: '',
    county: '',
  },
  errorText: '',
  errorTextPerField: {},
  wrapperStyles: {},
};
