exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".-SignaturePad-wrapper-3VNoh {\n  width: 100%;\n}\n\n.-SignaturePad-signatureLine-3lJjC {\n  position: absolute;\n  width: 80%;\n  height: 30px;\n  border-bottom: solid rgba(171, 171, 171, 0.7) 1px;\n  bottom: 60px;\n  margin: 0 10%;\n  z-index: 1;\n}\n\n.-SignaturePad-crossIcon-Tk5Oo {\n  height: 20px;\n  fill: solid rgba(171, 171, 171, 0.7);\n}\n\n.-SignaturePad-buttonContainer-1waXi {\n  width: 100%;\n  position: relative; \n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "-SignaturePad-wrapper-3VNoh",
	"signatureLine": "-SignaturePad-signatureLine-3lJjC",
	"crossIcon": "-SignaturePad-crossIcon-Tk5Oo",
	"buttonContainer": "-SignaturePad-buttonContainer-1waXi"
};