export const ONE_OFF_WITHDRAWAL_FLOW = 'ONE_OFF_WITHDRAWAL_FLOW';
export const ONE_OFF_WITHDRAWAL_FLOW_NO_WHAT_IF = 'ONE_OFF_WITHDRAWAL_FLOW_NO_WHAT_IF';
export const REGULAR_WITHDRAWAL_FLOW = 'REGULAR_WITHDRAWAL_FLOW';
export const WITHDRAWAL_OPTIONS = 'WITHDRAWAL_OPTIONS';
export const WITHDRAWAL_AMOUNT = 'WITHDRAWAL_AMOUNT';
export const WITHDRAWAL_REASON = 'WITHDRAWAL_REASON';
export const WITHDRAWAL_RESULTS_STEP = 'WITHDRAWAL_RESULTS_STEP';
export const WITHDRAWAL_OTHER_OPTIONS = 'WITHDRAWAL_OTHER_OPTIONS';
export const WITHDRAWAL_INFLUENCED = 'WITHDRAWAL_INFLUENCED';
export const WITHDRAWAL_ACCOUNT_DETAILS = 'WITHDRAWAL_ACCOUNT_DETAILS';
export const WITHDRAWAL_REVIEW_DETAILS = 'WITHDRAWAL_REVIEW_DETAILS';
export const WITHDRAWAL_REQUEST_COMPLETE = 'WITHDRAWAL_REQUEST_COMPLETE';

export const JOURNEYS = {
  [ONE_OFF_WITHDRAWAL_FLOW]: [
    WITHDRAWAL_OPTIONS,
    WITHDRAWAL_AMOUNT,
    WITHDRAWAL_REASON,
    WITHDRAWAL_RESULTS_STEP,
    WITHDRAWAL_OTHER_OPTIONS,
    WITHDRAWAL_INFLUENCED,
    WITHDRAWAL_ACCOUNT_DETAILS,
    WITHDRAWAL_REVIEW_DETAILS,
    WITHDRAWAL_REQUEST_COMPLETE,
  ],
  [ONE_OFF_WITHDRAWAL_FLOW_NO_WHAT_IF]: [
    WITHDRAWAL_OPTIONS,
    WITHDRAWAL_AMOUNT,
    WITHDRAWAL_REASON,
    WITHDRAWAL_OTHER_OPTIONS,
    WITHDRAWAL_INFLUENCED,
    WITHDRAWAL_ACCOUNT_DETAILS,
    WITHDRAWAL_REVIEW_DETAILS,
    WITHDRAWAL_REQUEST_COMPLETE,
  ],
  [REGULAR_WITHDRAWAL_FLOW]: [
    WITHDRAWAL_OPTIONS,
  ],
};
