/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import LumpSumIntro from './LumpSumIntro';
import LumpSumAmountStep from './LumpSumAmountStep';
import LumpSumSourceOfFundsStep from './LumpSumSourceOfFundsStep';
import LumpSumSubmitStep from './LumpSumSubmitStep';
import LumpSumCompleteStep from './LumpSumCompleteStep';
import MissingDetailsPage from '../PersonalDetails/MissingDetails';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import { getMissingDetailsRequiredFields, getStepWithRequiredFields } from '../../redux/selectors';
import WithInvestmentAdviceLetter from '../../components/WithDocumentLink/WithInvestmentAdviceLetter';

const LUMP_SUM_INTRO = 'LUMP_SUM_INTRO';
const LUMP_SUM_AMOUNT_STEP = 'LUMP_SUM_AMOUNT_STEP';
const LUMP_SUM_SOURCE_OF_FUNDS_STEP = 'LUMP_SUM_SOURCE_OF_FUNDS_STEP';
const SUBMIT_STEP = 'SUBMIT_STEP';
const MISSING_DETAILS_STEP = 'MISSING_DETAILS_STEP';
const THANK_YOU_STEP = 'THANK_YOU_STEP';

const SetupLumpSumPage = ({
  isCustomerOngoing,
  stepWithRequiredFields,
  missingDetailsOnly,
  investmentAdviceLetterUrl,
  flow,
}) => {
  const instructionType = () => {
    if (isCustomerOngoing) return 'contribution-lump-sum';
    return 'new-customer-contribution-lump-sum';
  };

  const [formData, setFormData] = useState({
    netAmount: 0,
    salaryMask: '',
    type: instructionType(),
    ongoing: isCustomerOngoing,
  });

  const [totalSteps, setTotalSteps] = useState(4);
  const [step, setStep] = useState(0);

  const stepsMap = {
    [LUMP_SUM_INTRO]: (
      <LumpSumIntro
        step={step}
        setStep={setStep}
      />
    ),
    [LUMP_SUM_AMOUNT_STEP]: (
      <LumpSumAmountStep
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
      />
    ),
    [LUMP_SUM_SOURCE_OF_FUNDS_STEP]: (
      <LumpSumSourceOfFundsStep
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
      />
    ),
    [SUBMIT_STEP]: (
      <LumpSumSubmitStep
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
        investmentAdviceLetterUrl={investmentAdviceLetterUrl}
      />
    ),
    [MISSING_DETAILS_STEP]: (
      <MissingDetailsPage
        stepWithRequiredFields={stepWithRequiredFields}
        journey={stepWithRequiredFields}
        step={step}
        setStep={setStep}
        flow={flow}
      />
    ),
    [THANK_YOU_STEP]: (
      <LumpSumCompleteStep
        step={step}
        totalSteps={totalSteps}
      />
    ),
  };

  const getJourney = () => {
    const setupSteps = [
      LUMP_SUM_INTRO,
      LUMP_SUM_AMOUNT_STEP,
      LUMP_SUM_SOURCE_OF_FUNDS_STEP,
      SUBMIT_STEP,
    ];

    if (stepWithRequiredFields.length) {
      return [
        ...setupSteps,
        MISSING_DETAILS_STEP,
        THANK_YOU_STEP,
      ];
    }

    return [
      ...setupSteps,
      THANK_YOU_STEP,
    ];
  };

  const [journey] = useState(getJourney());
  if (journey.length !== totalSteps) {
    setTotalSteps(journey.length);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    stepsMap[journey[step] || LUMP_SUM_AMOUNT_STEP]
  );
};

const mapStateToProps = (state, ownProps) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
  missingDetailsRequiredFields: getMissingDetailsRequiredFields(state, ownProps.flow),
  stepWithRequiredFields: getStepWithRequiredFields(state, ownProps.flow),
});

const SetupLumpSumPageWithLetter = (props) => (
  <WithInvestmentAdviceLetter>
    {(investmentAdviceLetterUrl) => (
      <SetupLumpSumPage {...{ ...props, investmentAdviceLetterUrl }} />
    )}
  </WithInvestmentAdviceLetter>
);

const ConnectedSetUpLumpSumPageLetter = connect(mapStateToProps)(SetupLumpSumPageWithLetter);

ConnectedSetUpLumpSumPageLetter.defaultProps = {
  flow: 'setup-lump-sum',
};

export default ConnectedSetUpLumpSumPageLetter;
