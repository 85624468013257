import React, { useState } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';

import BaseGrid from '../../components/BaseGrid/BaseGrid';
import PlanSummary from './PlanSummary/PlanSummary';
import PlanSummaryMSM from './PlanSummaryMSM/PlanSummaryMSM';

import { getUserReferredFromMoneySupermarket } from '../../redux/selectors/experience';
import { getTargetInvestment } from '../../redux/selectors/targetInvestment';
import { getIVUCollection } from '../../redux/selectors';
import { getPensionExpert, getHasInProgressContributionInstruction } from '../../redux/selectors/investmentAdvice';
import { getPensions } from '../../redux/selectors/pensions';

import {
  recommendationDetailPageLoadedAction,
  reportFromRecommendationDetailClickedAction,
  addPensionFromRecommendationDetailClickedAction,
  addMoneyFromRecommendationDetailClickedAction,
  messageAdviserFromRecommendationDetailClickedAction,
  openYourRecommendedPensionPlanClickedAction,
  comparisonGridScrollAction,
} from '../../redux/modules/investmentAdvice';
import {
  planSummaryPageVisitedStatus,
} from '../../redux/modules/customerDataSet';
import PlanSummaryPage from './PlanSummaryOld/PlanSummaryPage';

const PlanSummaryPageWrapper = ({
  isMoneySupermarketCustomer,
  targetInvestment,
  ivuCollection,
  pensionExpert,
  pensions,
  inProgressInstructions,
  dispatchRecommendationDetailPageLoaded,
  dispatchReportFromRecommendationDetailClicked,
  dispatchAddPensionFromRecommendationDetailClicked,
  dispatchAddMoneyFromRecommendationDetailClicked,
  dispatchPlanSummaryPageVisitedAction,
  dispatchMessageAdviserFromRecommendationDetailClicked,
  dispatchOpenYourRecommendedPensionPlanClicked,
  dispatchComparisonGridScroll: _dispatchComparisonGridScroll,
}) => {
  const [dispatchComparisonGridScroll] = useState(
    () => once(_dispatchComparisonGridScroll),
  );

  const hasPensions = Boolean(pensions && pensions.length);
  const hasInProgressInstructions = Boolean(inProgressInstructions.length);
  const hasPensionOrInstruction = hasPensions || hasInProgressInstructions;

  const eventProps = {
    recommendationDetailPageLoaded:
      () => dispatchRecommendationDetailPageLoaded(isMoneySupermarketCustomer),
    reportFromRecommendationDetailClicked:
      () => dispatchReportFromRecommendationDetailClicked(isMoneySupermarketCustomer),
    addPensionFromRecommendationDetailClicked:
      () => dispatchAddPensionFromRecommendationDetailClicked(isMoneySupermarketCustomer),
    addMoneyFromRecommendationDetailClicked:
      () => dispatchAddMoneyFromRecommendationDetailClicked(isMoneySupermarketCustomer),
    planSummaryPageVisitedAction:
      () => dispatchPlanSummaryPageVisitedAction(isMoneySupermarketCustomer),
    messageAdviserFromRecommendationDetailClicked:
      () => dispatchMessageAdviserFromRecommendationDetailClicked(isMoneySupermarketCustomer),
    openYourRecommendedPensionPlanClicked:
      () => dispatchOpenYourRecommendedPensionPlanClicked(isMoneySupermarketCustomer),
    comparisonGridScroll:
      () => dispatchComparisonGridScroll(isMoneySupermarketCustomer),
  };

  function showCorrectExperience() {
    return (
      <PlanSummaryPage
        targetInvestment={targetInvestment}
        ivuCollection={ivuCollection}
        pensionExpert={pensionExpert}
        {...eventProps}
      />
    );
  }

  return (
    <BaseGrid
      noPaddedWrapper={isMoneySupermarketCustomer}
    >
      {showCorrectExperience()}
    </BaseGrid>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchRecommendationDetailPageLoaded: (isMSMCustomer) => dispatch(
      recommendationDetailPageLoadedAction(isMSMCustomer),
    ),
    dispatchReportFromRecommendationDetailClicked: (isMSMCustomer) => dispatch(
      reportFromRecommendationDetailClickedAction(isMSMCustomer),
    ),
    dispatchAddPensionFromRecommendationDetailClicked: (isMSMCustomer) => dispatch(
      addPensionFromRecommendationDetailClickedAction(isMSMCustomer),
    ),
    dispatchAddMoneyFromRecommendationDetailClicked: (isMSMCustomer) => dispatch(
      addMoneyFromRecommendationDetailClickedAction(isMSMCustomer),
    ),
    dispatchPlanSummaryPageVisitedAction: (isMSMCustomer) => dispatch(
      planSummaryPageVisitedStatus(isMSMCustomer),
    ),
    dispatchMessageAdviserFromRecommendationDetailClicked: (isMSMCustomer) => dispatch(
      messageAdviserFromRecommendationDetailClickedAction(isMSMCustomer),
    ),
    dispatchOpenYourRecommendedPensionPlanClicked: (isMSMCustomer) => dispatch(
      openYourRecommendedPensionPlanClickedAction(isMSMCustomer),
    ),
    dispatchComparisonGridScroll: (isMSMCustomer) => dispatch(
      comparisonGridScrollAction(isMSMCustomer),
    ),
  };
};

const mapStateToProps = (state) => ({
  isMoneySupermarketCustomer: getUserReferredFromMoneySupermarket(state),
  targetInvestment: getTargetInvestment(state),
  ivuCollection: getIVUCollection(state),
  pensionExpert: getPensionExpert(state),
  pensions: getPensions(state),
  inProgressInstructions: getHasInProgressContributionInstruction(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanSummaryPageWrapper);
