/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import get from 'lodash/get';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import NationalInsuranceNumber from './NationalInsuranceNumberStep';
import '../../util/signupValidators';
import MissingDetailsSignature from './SignatureStep';
import ThankYou from './ThankYouStep';
import {
  NATIONAL_INSURANCE_NUMBER_STEP,
  SIGNATURE_STEP,
  THANK_YOU_STEP,
  AUTHORISATION_STEP,
} from './SerpsMissingDetailsFlow';
import { getHasNationalInsuranceNumber, getHasDigitalSignature } from '../../redux/selectors/index';
import { getHasSerpsAuthority } from '../../redux/selectors/serps';

import AuthorisationStep from './AuthorisationStep';

const SerpsMissingDetailsPage = ({
  flow,
  hasDigitalSignature,
  hasNationalInsuranceNumber,
  hasSerpsAuthority,
}) => {
  const initialState = {
    nationalInsuranceNumber: '',
    signature: undefined,
  };

  const serpsJourney = [];

  if (!hasNationalInsuranceNumber) {
    serpsJourney.push(NATIONAL_INSURANCE_NUMBER_STEP);
  }
  if (!hasDigitalSignature) {
    serpsJourney.push(SIGNATURE_STEP);
  }

  const [journey, setJourney] = useState([...serpsJourney, AUTHORISATION_STEP, THANK_YOU_STEP]);
  const [formData, setFormData] = useState(initialState);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [step, setStepValue] = useState(0);
  const [amendMode, setAmendMode] = useState(false);

  const totalSteps = journey.length;
  const setStep = (newStepArg) => {
    const newStepValue = typeof newStepArg === 'function' ? newStepArg(step) : newStepArg;
    setStepValue(newStepValue);
  };

  const stepsMap = {
    [NATIONAL_INSURANCE_NUMBER_STEP]: (
      <NationalInsuranceNumber
        setStep={setStep}
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        amendMode={amendMode}
        setAmendMode={setAmendMode}
        isSubmitLoading={isSubmitLoading}
        setIsSubmitLoading={setIsSubmitLoading}
        flow={flow}
      />
    ),
    [SIGNATURE_STEP]: (
      <MissingDetailsSignature
        step={step}
        totalSteps={totalSteps}
        setStep={setStep}
        formData={formData}
        setFormData={setFormData}
        amendMode={amendMode}
        setAmendMode={setAmendMode}
        setIsSubmitLoading={setIsSubmitLoading}
      />
    ),
    [AUTHORISATION_STEP]: (
      <AuthorisationStep
        step={step}
        totalSteps={totalSteps}
        setStep={setStep}
        formData={formData}
        setFormData={setFormData}
        amendMode={amendMode}
        setAmendMode={setAmendMode}
        setIsSubmitLoading={setIsSubmitLoading}
      />
    ),
    [THANK_YOU_STEP]: (
      <ThankYou
        onBack={() => {
          setJourney(journey);
          setStep(0);
        }}
        flow={flow}
        isSubmitLoading={isSubmitLoading}
      />
    ),
  };

  const stepComponent = get(stepsMap, journey[step]);

  if (journey[step] === THANK_YOU_STEP) {
    return stepsMap[journey[step]];
  }

  if (!stepComponent || hasSerpsAuthority) {
    return <Redirect to="/" />;
  }

  return (
    stepsMap[journey[step]]
  );
};

const mapStateToProps = (state) => ({
  hasDigitalSignature: getHasDigitalSignature(state),
  hasNationalInsuranceNumber: getHasNationalInsuranceNumber(state),
  hasSerpsAuthority: getHasSerpsAuthority(state),
});

export default connect(mapStateToProps)(withRouter(SerpsMissingDetailsPage));
