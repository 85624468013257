import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';
import get from 'lodash/get';
import styles from './AddPensionsPage.css';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import UnknownEmployerContent from './UnknownEmployerContent';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import {
  returnLinkClickedAction,
  messageMeClickedAction,
  employerNameStepLandedAction,
  employerNameChangedAction,
  employerNameSubmitClickedAction,
  unknownEmployerLinkClickedAction,
} from '../../redux/modules/pensions';

const EmployerNameStep = ({
  setFormData,
  setStep,
  amendMode,
  step,
  totalSteps,
  dispatchReturnLinkClicked,
  dispatchMessageMeClicked,
  dispatchEmployerNameStepLanded,
  isCustomerOngoing,
  dispatchEmployerNameChanged: _dispatchEmployerNameChanged,
  dispatchEmployerNameSubmitClicked,
  dispatchUnknownEmployerLinkClicked,
  formData,
  backFromEmployerName,
}) => {
  const [unknownEmployerMode, setUnknownEmployerMode] = useState(false);
  const [dispatchEmployerNameChanged] = useState(() => once(_dispatchEmployerNameChanged));
  const [employerName, setEmployerName] = useState(formData.employerName || undefined);
  const [error, setError] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchEmployerNameStepLanded(isCustomerOngoing, formData.transferOption);
  }, []);

  const employerNameContent = (
    <React.Fragment>
      <div className={styles.employerNameWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
          <ButtonLink
            onClick={(event) => {
              if (unknownEmployerMode) {
                setUnknownEmployerMode(false);
                event.stopPropagation();
                return;
              }
              backFromEmployerName();
              event.stopPropagation();
              dispatchReturnLinkClicked(isCustomerOngoing, formData.transferOption);
            }}
            label="← Back"
          />
        )}
        <h3 className={styles.employerNameHeading}>
          {'Who was your employer?*'}
        </h3>
        <div
          className={styles.employerNameCallout}
        >
          <p
            className={styles.employerNameCalloutText}
          >
            {'We '}
            <b>{'need'}</b>
            {' your employer name to proceed'}
          </p>
        </div>
        <input
          className={styles.employerNameInput}
          placeholder={'Enter employer name'}
          value={employerName}
          onChange={(event) => {
            const value = get(event, 'target.value');
            dispatchEmployerNameChanged(isCustomerOngoing, formData.transferOption);
            setEmployerName(value);
          }}
        />
        { error && <p className={styles.error}>{error}</p>}
        <div
          className={styles.submitContainer}
        >
          <Button
            size="large"
            disabled={!employerName || error}
            label={(amendMode) ? 'Confirm' : 'Submit'}
            onClick={() => {
              dispatchEmployerNameSubmitClicked(isCustomerOngoing, formData.transferOption);

              const anyWordCharacters = (/\w+/).test(employerName);

              if (!anyWordCharacters) {
                setError('Please enter a valid employer name');
                return;
              }
              setFormData((_formData) => ({
                ..._formData,
                employerName,
              }));
              setStep(step + 1);
            }}
          />
          <ButtonLink
            variant="primary"
            label={<span className={styles.employerLinkText}>{'I don’t know my employer'}</span>}
            onClick={() => {
              dispatchUnknownEmployerLinkClicked(isCustomerOngoing, formData.transferOption);
              setUnknownEmployerMode(true);
            }}
          />
        </div>
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'EmployerNameStep'}
            noExpertContentWrapper={({ children }) => (
              <div
                style={{
                  display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                }}
              >
                {children}
              </div>
            )}
            showButton={false}
            customMessage={(
              <div
                className={styles.contactUsInnerContent}
              >
                {'If you have any questions, please feel free to '}
                <ButtonLink
                  label="message me"
                  variant="primary"
                  to="/inbox/new-message"
                  font="inherit"
                  onClick={() => dispatchMessageMeClicked(
                    isCustomerOngoing,
                    formData.transferOption,
                  )}
                />
                {'.'}
              </div>
            )}
          />
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      {
        unknownEmployerMode ? (
          <UnknownEmployerContent
            setUnknownEmployerMode={setUnknownEmployerMode}
          />
        ) : (
          employerNameContent
        )
      }
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  dispatchReturnLinkClicked: returnLinkClickedAction,
  dispatchMessageMeClicked: messageMeClickedAction,
  dispatchEmployerNameStepLanded: employerNameStepLandedAction,
  dispatchEmployerNameChanged: employerNameChangedAction,
  dispatchEmployerNameSubmitClicked: employerNameSubmitClickedAction,
  dispatchUnknownEmployerLinkClicked: unknownEmployerLinkClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployerNameStep);
