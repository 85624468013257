import React from 'react';
import classnames from 'classnames';
import styles from './RadioOptions.css';

const RadioOptions = ({
  optionOneClickHandler,
  isOptionOneSelected,
  optionTwoClickHandler,
  isOptionTwoSelected,
}) => (
  <React.Fragment>
    <button
      className={styles.radioButton}
      type="button"
      onClick={optionOneClickHandler}
    >
      <div
        className={classnames(
          styles.optionLetter,
          { [styles.selectedOption]: isOptionOneSelected },
        )}
      >
        {'A'}
      </div>
      <div className={styles.optionLabel}>{'Yes'}</div>
    </button>
    <button
      className={styles.bottomRadioButton}
      type="button"
      onClick={optionTwoClickHandler}
    >
      <div
        className={classnames(
          styles.optionLetter,
          { [styles.selectedOption]: isOptionTwoSelected },
        )}
      >
        {'B'}
      </div>
      <div className={styles.optionLabel}>{'No'}</div>
    </button>
  </React.Fragment>
);

export default RadioOptions;
