import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import once from 'lodash/once';
import moment from 'moment';

import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import {
  serpsMissingDetailsAuthorizeRequestPageLoaded,
  serpsMissingDetailsMessageMeClickedAction,
  addSerpsRequestAction,
  serpsMissingDetailsAuthorityProvided,
} from '../../redux/modules/serps';
import CheckBoxGreen from '../../components/CheckBoxGreen/CheckBoxGreen';
import Button from '../../components/Button/Button';
import styles from './SerpMissingDetails.css';
import { serpsRequestAuthorized } from '../../redux/modules/notificationTracking';
import {
  getHasDigitalSignature,
  getFirebaseUid,
  getNationalInsuranceNumber,
  getDigitalSignature,
} from '../../redux/selectors';

import sentryException from '../../util/sentryException';
import captureScreen from '../../util/captureScreenshot';
import captureHtml from '../../util/captureHtml';

const SerpsAuthorisationStep = ({
  step,
  totalSteps,
  amendMode,
  dispatchSerpsMissingDetailsAuthorizeRequestPageLoaded:
  _dispatchSerpsMissingDetailsAuthorizeRequestPageLoaded,
  dispatchSerpsMissingDetailsMessageMeClicked,
  dispatchAddSerpsRequest,
  setStep,
  heading,
  isSubmitting,
  formData,
  setFormData,
  uid,
  nino,
  signature,
  dispatchSerpsMissingDetailsAuthorityProvided,
}) => {
  const [dispatchSerpsMissingDetailsAuthorizeRequestPageLoaded] = useState(() => once(_dispatchSerpsMissingDetailsAuthorizeRequestPageLoaded || ''));
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);

  const today = moment().format('DD/MM/YY');

  const disabled = isSubmitting
    || !formData.confirmRequestForInfoDetail
    || !formData.authorizeForInfoRelease;

  const onSubmit = async () => {
    try {
      setShowSubmitError(false);
      setIsSubmitLoading(true);
      let screenshot = null;
      try {
        screenshot = await captureScreen();
      } catch (error) {
        sentryException(error, {
          section: 'add-serps-request-capture-screen',
        });
        throw error;
      }
      const pageHtml = captureHtml();
      const serpsRequestData = {
        nationalInsuranceNumber: formData.nationalInsuranceNumber || nino,
        signature: formData.signature || signature,
        authoriseSerpsTrace: !disabled,
        screenshot,
        pageHtml,
      };
      await dispatchAddSerpsRequest(serpsRequestData);
      serpsRequestAuthorized(uid);
      setIsSubmitLoading(false);
      setStep((_step) => _step + 1);
    } catch (error) {
      setShowSubmitError(true);
      setIsSubmitLoading(false);
    }
  };
  const debouncedOnSubmit = debounce(onSubmit, 3000, { leading: true, trailing: false });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchSerpsMissingDetailsAuthorizeRequestPageLoaded();
  }, []);

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.missingDetailsWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
          <ButtonLink
            secondary
            mid
            onClick={() => {
              setStep(step - 1);
            }}
            label="← Back"
            wrapperStyles={{
              margin: '0 0 19px',
              fontFamily: 'Poppins',
              fontWeight: 500,
              fontSize: '16px',
            }}
          />
        )}
        <h1 className={styles.ninoHeading}>
          {heading}
          {'Authorisation'}
        </h1>
        <div className={styles.content}>
          {'By authorising us to contact HMRC on your behalf we can help you find lost SERPS pensions, if you have them. We will never share your data with anyone other than HMRC. We need this authorisation to start your SERPS trace.'}
        </div>
        <br />
        <div className={styles.authoriseNextCard}>
          <h3 className={styles.headingThree}>{'Please confirm you have read the following:'}</h3>
          <CheckBoxGreen
            name="confirmRequestForInfoDetail"
            label={'I request a Data Protection Print, under the terms of the Data Protection Act 2018, to include my contracted out pension history/status for personal, occupational, salary rated and money purchase agreements. Please can you provide Profile Pensions with any relevant information you hold including any pension provider(s) information and policy number(s).'}
            value={formData.confirmRequestForInfoDetail}
            defaultValue={false}
            onChange={() => {
              setFormData({
                ...formData,
                confirmRequestForInfoDetail: !formData.confirmRequestForInfoDetail,
              });
            }}
            wrapperStyles={{ marginBottom: '13px' }}
          />
          <div className={styles.divider} />
          <CheckBoxGreen
            name="authorizeForInfoRelease"
            label={'I authorise HM Revenue & Customs to release this directly to Profile Pensions.'}
            value={formData.authorizeForInfoRelease}
            defaultValue={false}
            onChange={() => {
              dispatchSerpsMissingDetailsAuthorityProvided();
              setFormData({
                ...formData,
                authorizeForInfoRelease: !formData.authorizeForInfoRelease,
              });
            }}
            wrapperStyles={{ marginBottom: '13px' }}
          />
          <div className={styles.divider} />
          <div className={styles.dateDislay}>
            {`Date ${today}`}
          </div>
        </div>

        <div
          className={styles.submitContainer}
        >
          {showSubmitError && (
            <div
              className={styles.error}
            >
              {'Something went wrong - please try again'}
            </div>
          )}
          <Button
            size="large"
            label="Save & continue"
            disabled={disabled}
            loading={isSubmitLoading}
            onClick={debouncedOnSubmit}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapDispatchToProps = {
  dispatchSerpsMissingDetailsAuthorizeRequestPageLoaded:
    serpsMissingDetailsAuthorizeRequestPageLoaded,
  dispatchSerpsMissingDetailsMessageMeClicked: serpsMissingDetailsMessageMeClickedAction,
  dispatchAddSerpsRequest: addSerpsRequestAction,
  dispatchSerpsMissingDetailsAuthorityProvided: serpsMissingDetailsAuthorityProvided,
};

const mapStateToProps = (state) => ({
  digitalSignature: getHasDigitalSignature(state),
  uid: getFirebaseUid(state),
  nino: getNationalInsuranceNumber(state),
  signature: getDigitalSignature(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SerpsAuthorisationStep);
