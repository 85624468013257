import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import GreenTick from '../../assets/images/icons/Icon_Alert_Success.svg';
import { withdrawalCompleteVisitedAction } from '../../redux/modules/withdrawal';
import styles from './SetupWithdrawalPage.css';

const WithdrawalCompleteStep = ({
  dispatchWithdrawalCompleteVisited,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchWithdrawalCompleteVisited();
  }, []);

  return (
    <div>
      <div className={styles.textAlignCenter}>
        <img src={GreenTick} className={styles.greenTick} alt="Green tick" />
      </div>
      <h2 className={styles.accountDetailsHeading}>{'Your withdrawal is being processed'}</h2>
      <p className={styles.accountDetailsBody}>{'This can take up to 4-6 weeks to complete. Aegon will directly credit your bank account with the funds due and confirm the amount in writing.'}</p>
    </div>

  );
};

const mapDispatchToProps = {
  dispatchWithdrawalCompleteVisited: withdrawalCompleteVisitedAction,
};

export default connect(null, mapDispatchToProps)(WithdrawalCompleteStep);
