import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Header from '../Header/Header';
import NavigationPrimary from '../NavigationPrimary/NavigationPrimary';
import MsmPartnershipTile from '../MsmPartnershipTile/MsmPartnershipTile';
import Footer from '../Footer/Footer';
import MessageWidget from '../MessageWidget/MessageWidget';

import styles from './BaseLayout.css';

const BaseGrid = ({
  children,
  footerContent,
  showFooter,
  showAlerts,
  heading,
  initialSignupTheme,
  inboxTheme,
  messageWidget,
  addPensionsTheme,
  showHeader,
  showMsmTile = false,
  dashboardTheme,
  showNavMenu = true,
}) => {
  return (
    <Fragment>
      {showHeader && (
        <Header
          showAlerts={showAlerts}
          initialSignupTheme={initialSignupTheme}
          inboxTheme={inboxTheme}
          addPensionsTheme={addPensionsTheme}
          dashboardTheme={dashboardTheme}
        >
          <NavigationPrimary
            themeDark={initialSignupTheme}
            inboxTheme={inboxTheme}
            dashboardTheme={dashboardTheme}
            showNavMenu={showNavMenu}
          />
        </Header>
      )}
      {(heading) && (
        <div className={styles.dashboardHeaderSection}>
          <div className={classNames(
            styles.dashboardHeaderSectionWrapper,
            { [styles.dashboardTheme]: dashboardTheme },
          )}
          >
            {heading}
          </div>
        </div>
      )}
      {showMsmTile && <MsmPartnershipTile />}
      {children}
      {footerContent}
      {showFooter && <Footer />}
      {messageWidget && <MessageWidget />}
    </Fragment>
  );
};

BaseGrid.propTypes = {
  footerContent: PropTypes.element,
  showFooter: PropTypes.bool,
  showAlerts: PropTypes.bool,
  initialSignupTheme: PropTypes.bool,
  addPensionsTheme: PropTypes.bool,
  showHeader: PropTypes.bool,
};

BaseGrid.defaultProps = {
  footerContent: null,
  showFooter: true,
  showAlerts: false,
  initialSignupTheme: false,
  addPensionsTheme: false,
  showHeader: true,
};

export default BaseGrid;
