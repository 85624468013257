export const DOCUMENTS_PAGE_ILLUSTRATION_CLICKED = 'pp/documentsPage/ILLUSTRATION_CLICKED';
export const DOCUMENTS_PAGE_KEY_FEATURES_CLICKED = 'pp/documentsPage/KEY_FEATURES_CLICKED';
export const DOCUMENTS_PAGE_TERMS_OF_BUSINESS_CLICKED = 'pp/documentsPage/TERMS_OF_BUSINESS_CLICKED';

export const documentsPageIllustrationClicked = () => ({
  type: DOCUMENTS_PAGE_ILLUSTRATION_CLICKED,
});

export const documentsPageKeyFeaturesClicked = () => ({
  type: DOCUMENTS_PAGE_KEY_FEATURES_CLICKED,
});

export const documentsPageTermsOfBusinessClicked = () => ({
  type: DOCUMENTS_PAGE_TERMS_OF_BUSINESS_CLICKED,
});
