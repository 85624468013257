import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { default as DotLoader } from '../Loader/Loader';
import styles from './Loader.css';
import BestPensionStep from '../../assets/images/cds/best-pension-in-the-market.svg';
import { ReactComponent as InvestInPensionStepImage } from '../../assets/images/cds/invest-in-your-pension.svg';
import DedicatedAdviserStepImage from '../DedicatedAdviserImageSvgComponent/DedicatedAdviserImageSvgComponent';
import { getPensionExpert } from '../../redux/selectors/investmentAdvice';

class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStepIndex: 1,
      intervalId: null,
    };
  }

  componentDidMount() {
    const { timeout } = this.props;

    const intervalId = setInterval(() => {
      this.setContent();
    }, timeout);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    clearInterval(intervalId);
  }

  setContent() {
    const { steps } = this.props;
    const { currentStepIndex } = this.state;
    if ((currentStepIndex < steps.length)) {
      return this.setState({ currentStepIndex: currentStepIndex + 1 });
    }
    this.completeAnimation();
  }

  completeAnimation() {
    const { intervalId } = this.state;
    const { callback } = this.props;
    clearInterval(intervalId);
    if (callback) callback();
  }

  render() {
    const { steps, pensionExpert } = this.props;
    const { currentStepIndex } = this.state;
    const { imageUrl, firstName } = pensionExpert || {};

    const currentStep = steps[currentStepIndex - 1];
    if (!currentStep) return null;

    const { getText } = currentStep;

    // I'm conditionally applying display: none instead of conditionally rendering the images
    // to allow each image to preload before it's displayed to reduce latency.

    return (
      <div key={currentStepIndex} className={styles.loaderWrapper}>
        <img
          src={BestPensionStep}
          className={styles.image}
          style={{ ...(currentStepIndex !== 1 && { display: 'none' }) }}
          alt=""
        />
        <img
          src={'https://res.cloudinary.com/profile-pensions/image/upload/v1697206514/Account%20Hub/find-and-transfer-old-pensions.svg'}
          className={styles.image}
          style={{ ...(currentStepIndex !== 2 && { display: 'none' }) }}
          alt=""
        />
        <div
          style={{ ...(currentStepIndex !== 3 && { display: 'none' }) }}
        >
          <DedicatedAdviserStepImage image={imageUrl} className={styles.image} />
        </div>
        <div
          style={{ ...(currentStepIndex !== 4 && { display: 'none' }) }}
        >
          <InvestInPensionStepImage className={styles.image} />
        </div>
        <h3 className={styles.text}>
          {getText(firstName)}
        </h3>
        <DotLoader />
      </div>
    );
  }
}

Loader.propTypes = {
  timeout: PropTypes.number,
  steps: PropTypes.arrayOf({
    getText: PropTypes.func,
    getImage: PropTypes.func,
  }),
  callback: PropTypes.func,
};

Loader.defaultProps = {
  timeout: 3500,
  steps: [
    {
      getText: () => 'We tell you where to invest your pension, choosing funds from the whole of the market',
    },
    {
      getText: () => 'We can help you find and combine your old pensions with just a few details',
    },
    {
      getText: (firstName) => {
        return firstName
          ? `Your dedicated Pension Adviser, ${firstName}, is there to help you whenever you need then`
          : 'Your dedicated Pension Adviser is there to help you whenever you need them.';
      },
    },
    {
      getText: () => 'We continue to monitor your pension, for a brighter financial future',
    },
  ],
  callback: () => {},
};

const mapStateToProps = (state) => ({
  pensionExpert: getPensionExpert(state),
});

export default connect(mapStateToProps)(Loader);
