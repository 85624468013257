exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-VulnerableCustomerPage-container-3Is0d {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  text-align: center;\n}\n.-VulnerableCustomerPage-heading-XBMRT {\n}\n.-VulnerableCustomerPage-body-2Ku-T {\n}\n.-VulnerableCustomerPage-expertImage-20UvC {\n  height: 60px;\n  width: 60px;\n  margin-right: 15px;\n  margin: auto;\n}\n.-VulnerableCustomerPage-contactRow-1Dxxm {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin: 0 auto 20px;\n}\n.-VulnerableCustomerPage-contactIconContainer-1jCMQ {\n  width: 30px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n  margin: auto;\n}\n.-VulnerableCustomerPage-buttonContainer-1qUGS {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}", ""]);

// exports
exports.locals = {
	"typography": "\"../../theme/typography.css\"",
	"h2": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h2"] + "",
	"bodyOne": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"colors": "\"../../theme/colors.css\"",
	"danger": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["danger"] + "",
	"container": "-VulnerableCustomerPage-container-3Is0d",
	"heading": "-VulnerableCustomerPage-heading-XBMRT " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h2"] + "",
	"body": "-VulnerableCustomerPage-body-2Ku-T " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"expertImage": "-VulnerableCustomerPage-expertImage-20UvC",
	"contactRow": "-VulnerableCustomerPage-contactRow-1Dxxm",
	"contactIconContainer": "-VulnerableCustomerPage-contactIconContainer-1jCMQ",
	"buttonContainer": "-VulnerableCustomerPage-buttonContainer-1qUGS"
};