/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import ReactDOM from 'react-dom';
import { TransitionMotion, spring } from 'react-motion';
import classnames from 'classnames';
import styles from './Modal.css';

export default class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.modalRoot = document.getElementById('modal');
  }

  render() {
    const {
      show,
      children,
      backDropClick,
      centerContent,
      introModal,
      modalFullMaxWidth,
      foggyBackdrop,
    } = this.props;
    const willLeave = () => ({ opacity: spring(0), y: spring(-200) });
    const willEnter = () => ({ opacity: 0, y: -400 });

    if (show) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }

    return ReactDOM.createPortal((
      <TransitionMotion
        willLeave={willLeave}
        willEnter={willEnter}
        styles={
          show ? [{
            key: 'a',
            style: {
              opacity: spring(1),
              y: spring(0),
            },
          }] : []
        }
      >
        {(items) => {
          if (items.length) {
            const { key, style } = items[0];
            return (
              <div
                className={classnames(styles.backdrop, {
                  [styles.introModal]: introModal,
                  [styles.foggyBackdrop]: foggyBackdrop,
                })}
                key={key}
                style={{ opacity: style.opacity }}
                onClick={backDropClick}
              >
                <div
                  className={classnames(styles.modal, {
                    [styles.centerContent]: centerContent,
                    [styles.modalFullMaxWidth]: modalFullMaxWidth,
                  })}
                  style={{ position: 'relative', bottom: style.y, opacity: style.opacity }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {children}
                </div>
              </div>
            );
          }
          return null;
        }}
      </TransitionMotion>
    ), this.modalRoot);
  }
}
