exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".-PageGrid-pageContainer-vEnXC {\n  width: 100%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n\n.-PageGrid-pageGrid-3J3GC {\n  max-width: 1440px;\n  margin: 0 auto;\n  padding: 25px 10px;\n  display: grid;\n  grid-auto-flow: dense;\n  grid-column-gap: 22px;\n  -webkit-column-gap: 22px;\n     -moz-column-gap: 22px;\n          column-gap: 22px;\n  grid-auto-rows: minmax(0px, auto);\n}", ""]);

// exports
exports.locals = {
	"pageContainer": "-PageGrid-pageContainer-vEnXC",
	"pageGrid": "-PageGrid-pageGrid-3J3GC"
};