import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './FieldStatusBlock.css';

const FieldStatusBlock = (props) => {
  const {
    displayBlock,
    error,
    touched,
    focused,
    children,
    wrapperStyles,
    blockWrapper,
    statusBlockWrapper,
    hideMessage,
    fullWidth,
  } = props;

  const statusBlockClasses = [
    displayBlock && styles.statusBlock,
    (focused) && styles.focused,
    (!focused && touched && error) && styles.error,
  ].join(' ');

  return (
    <div
      className={classnames(styles.wrapper, { [styles.fullWidth]: fullWidth })}
      style={wrapperStyles}
    >
      <div className={styles.blockWrapper} style={blockWrapper}>
        <div className={statusBlockClasses} style={statusBlockWrapper}>{children}</div>
      </div>
      {(touched && error && !hideMessage) && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
};

FieldStatusBlock.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.bool,
  focused: PropTypes.bool,
  displayBlock: PropTypes.bool,
  wrapperStyles: PropTypes.object,
  blockWrapper: PropTypes.object,
  hideMessage: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

FieldStatusBlock.defaultProps = {
  error: '',
  touched: false,
  focused: false,
  displayBlock: true,
  wrapperStyles: {},
  blockWrapper: {},
  hideMessage: false,
  fullWidth: false,
};

export default FieldStatusBlock;
