import React from 'react';
import { connect } from 'react-redux';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { SERPS_NO_PENSIONS_FOUND_UPDATE_DISMISSED, serpsNoPensionsFoundNotificationDismissed } from '../../redux/modules/notificationTracking';
import { getFirebaseUid } from '../../redux/selectors';

const SerpsNoPensionsFoundNotification = ({
  uid,
  dispatchSerpsNoPensionsFoundNotificationDismissed,
}) => {
  return (
    <NotificationBanner
      alertCategory={'Blue'}
      alertTitle={'SERPS Update'}
      alertDescription={'HMRC have responded and there is no record of eligible SERPS contributions for us to transfer in the name and National Insurance number that you have provided.'}
      closeButtonOptions={{
        event: SERPS_NO_PENSIONS_FOUND_UPDATE_DISMISSED,
        handleClick: () => dispatchSerpsNoPensionsFoundNotificationDismissed(uid),
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
});

const mapDispatchToProps = ({
  dispatchSerpsNoPensionsFoundNotificationDismissed: serpsNoPensionsFoundNotificationDismissed,
});

export default connect(mapStateToProps, mapDispatchToProps)(SerpsNoPensionsFoundNotification);
