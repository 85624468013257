/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FieldLabel from '../FieldLabel/FieldLabel';
import styles from './CheckBoxGreen.css';

import tick from '../../assets/images/icons/tick_white_medium.svg';

export default class CheckBoxGreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.handleChange = this.handleChange.bind(this);

    this.checkboxRef = React.createRef();
  }

  handleChange(event) {
    const { onChange, disabled } = this.props;

    if (!disabled) {
      onChange(event);
    }
  }

  render() {
    const {
      value,
      name,
      required,
      disabled,
      label,
      wrapperStyles,
      largeHeadings,
      defaultValue,
    } = this.props;

    const checkBoxStyles = classnames(styles.checkBoxStyled, {
      [styles.checkBoxDisabled]: disabled,
    });

    if (value && this.checkboxRef.current) {
      this.checkboxRef.current.setAttribute('checked', true);
    }

    if (!value && this.checkboxRef.current) {
      this.checkboxRef.current.removeAttribute('checked');
    }

    return (
      <div className={styles.wrapper} style={wrapperStyles} ref={(el) => { this.wrapper = el; }}>
        <div className={styles.checkBoxContainer}>
          <input
            ref={this.checkboxRef}
            id={`checkbox-${name}`}
            type="checkbox"
            checked={(value === undefined || value === null) ? defaultValue : value}
            name={name}
            onChange={() => {}}
          />
          <div className={checkBoxStyles} onClick={this.handleChange}>
            {(value || defaultValue) && (
              <img src={tick} alt="tick" />
            )}
          </div>
          <div className={styles.checkBoxContainerLabel}>
            <FieldLabel
              label={label}
              required={required}
              disabled={disabled}
              largeHeadings={largeHeadings}
            />
          </div>
        </div>
      </div>
    );
  }
}

CheckBoxGreen.propTypes = {
  value: PropTypes.bool,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  wrapperStyles: PropTypes.object,
  largeHeadings: PropTypes.bool,
};

CheckBoxGreen.defaultProps = {
  value: false,
  required: false,
  disabled: false,
  label: null,
  defaultValue: false,
  wrapperStyles: {},
  largeHeadings: false,
};
