import get from 'lodash/get';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import firebase from 'firebase/compat/app';
import { withFirestore } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import CustomerDataSetComponentLibrary from '@profilepensions/customer-data-set-component-library';
import NavigationPrimary from '../../components/NavigationPrimary/NavigationPrimary';
import * as cdsActions from '../../redux/modules/customerDataSet';
import * as investmentAdviceActions from '../../redux/modules/investmentAdvice';
import CustomerDataSetError from './CustomerDataSetError';
import Loader from '../../components/Loader/Loader';
import DynamicLoader from '../../components/DynamicLoader/Loader';
import DynamicLoaderMSM from '../../components/DynamicLoaderMSM/DynamicLoaderMSM';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import logoUrlWithMfGroup from '../../assets/images/rebranding/pp-logo-mf-group.svg';

import sentryException from '../../util/sentryException';

import {
  getPreviousAnswersForOnboarding,
  getQuestionsToSkipForOnboarding,
  getLatestIncompleteInvestmentAdviceCds,
  getPreviousAnswersForOngoing,
  getQuestionsToSkipForOngoing,
} from '../../redux/selectors/cds';

import {
  getFirebaseUid,
  getUserProfile,
  getCdsLoadingError,
  getPostCdsAnimationLoading,
  getPippaId,
} from '../../redux/selectors';

import styles from './CustomerDataSetPage.css';
import { getIsTradeDoublerConversionTagAllowed } from '../../redux/selectors/tradeDoubler';
import { getUserReferredFromMoneySupermarket, getShouldShowMSMBanner } from '../../redux/selectors/experience';
import MsmPartnershipTile from '../../components/MsmPartnershipTile/MsmPartnershipTile';

const MEDIUM = 'digital';
const ENABLE_MSM_LOADER = false;

class CustomerDataSetPage extends React.Component {
  constructor(props) {
    super(props);
    this.actionPrefix = props.ongoingCds ? 'ongoing-' : '';
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.state = {
      showOnboardingAdviceLoader: false,
      showErrorModal: false,
      isLoading: false,
      previousOngoingAnswers: {},
    };
  }

  componentDidMount() {
    const { publishCdsLoaded, customerDataSet, ongoingCds } = this.props;
    window.scrollTo(0, 0);
    publishCdsLoaded(this.actionPrefix);
    if (!ongoingCds && !customerDataSet) {
      this.setCDSAbandonmentStatus({ isMostRecentCdsDigital: true, hasCompletedLatestCds: false });
    }

    if (ongoingCds) {
      this.setState({ isLoading: true });
      this.getPreviousAnswersForOngoingCds()
        .then((prevAnswers) => {
          this.setState({ previousOngoingAnswers: prevAnswers, isLoading: false });
        });
    }
  }

  setCDSAbandonmentStatus = async ({ isMostRecentCdsDigital, hasCompletedLatestCds }) => {
    const {
      uid,
      pippaId,
      firestore,
    } = this.props;
    await firestore.collection('users').doc(uid).collection('customerDataSetAbandonment').doc('status')
      .set({
        isMostRecentCdsDigital,
        hasCompletedLatestCds,
        pippaId,
      }, { merge: true });
  }

  getAddresses = async (postcode) => {
    let addresses = [];
    const getAddresses = firebase.app().functions('europe-west1').httpsCallable('addressLookup');

    try {
      ({ data: addresses } = await getAddresses({ postcode }));
    } catch (error) {
      sentryException(error, {
        section: 'customer-data-set-page-getAddresses',
      });
    }

    return addresses;
  };

  getPreviousAnswersForOngoingCds = async () => {
    const { getPreviousOngoingAnswers } = this.props;
    const getLatestCompletedCds = firebase.app().functions('europe-west1').httpsCallable('getLatestCompleteCds');
    let latestCds = {};
    try {
      const { data } = await getLatestCompletedCds();
      latestCds = data;
    } catch (error) {
      if (!error.code.includes('not-found')) {
        sentryException(error, {
          section: 'customer-data-set-page-getLatestCompleteCds',
        });
      }
    }
    return getPreviousOngoingAnswers(latestCds);
  };

  getContent() {
    const {
      cdsLoadingError,
      history,
      isUserReferredFromMoneySupermarket,
      previousAnswers,
      publishNextPageAction,
      ongoingCds,
      postCdsAnimationLoading,
      shouldShowMSMBanner,
      skipSteps,
    } = this.props;

    if (cdsLoadingError) {
      return (
        <CustomerDataSetError />
      );
    }

    const { previousOngoingAnswers } = this.state;

    return (
      <CustomerDataSetComponentLibrary
        previousAnswers={ongoingCds ? previousOngoingAnswers : previousAnswers}
        onNext={async (cdsValues, flatCdsValues, questionName) => {
          publishNextPageAction(this.actionPrefix, questionName, flatCdsValues, skipSteps);
          if (!ongoingCds) await this.storeQuestions(cdsValues);
          const askAdditionalQuestions = await this.calculateAskAdditionalQuestions(flatCdsValues);
          const isInexperienced = get(askAdditionalQuestions, 'isInexperienced', false);
          const shouldAskAtrTerm = get(askAdditionalQuestions, 'shouldAskAtrTerm', false);
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          return { inexperienced: isInexperienced, shouldAskAtrTerm };
        }}
        onSubmit={(cdsValues, _, earlyExit) => this.handleOnSubmit(cdsValues, earlyExit)}
        exitForm={() => {
          if (!postCdsAnimationLoading) {
            if (!ongoingCds && isUserReferredFromMoneySupermarket) {
              history.push('/plan-summary');
            } else {
              history.push('/');
            }
          }
        }}
        skipSteps={skipSteps}
        getAddresses={this.getAddresses}
        hideBackToDashboardButton={shouldShowMSMBanner}
      />
    );
  }

  calculateAskAdditionalQuestions = (cds) => {
    const { dispatchAskAdditionalQuestions } = this.props;
    const lumpSum = get(cds, 'answers.lumpSum');
    const requiredFields = ['gender', 'dateOfBirth', 'intendedRetirementAge', 'atrObjective', 'lumpSum',
      'drawdownIntention', 'atrRisk', 'atrAmount', 'capacityForLoss', 'investedFrequency'];
    if (lumpSum === 'yes') {
      requiredFields.push('lumpSumAge', 'lumpSumPercent');
    }
    const missingRequiredFields = requiredFields.some((field) => get(cds, `answers.${field}`) === null);
    if (!missingRequiredFields) {
      return dispatchAskAdditionalQuestions(cds.answers);
    }
  }

  addPartnerTag = () => {
    const {
      uid,
      isTradeDoublerConversionTagAllowed,
      publishCdsCompletedTradedoublerTagFired,
    } = this.props;

    const { DEPLOYED_ENVIRONMENT } = process.env;

    if (window && window.tdconv && isTradeDoublerConversionTagAllowed && uid && DEPLOYED_ENVIRONMENT === 'production') {
      window.tdconv('init', '2297265', { element: 'iframe' });
      window.tdconv('track', 'lead', { transactionId: `${uid}-signup`, event: '415197' });

      publishCdsCompletedTradedoublerTagFired();

      const tradeDoublerLandingPageScript = document.createElement('script');
      tradeDoublerLandingPageScript.type = 'text/javascript';
      tradeDoublerLandingPageScript.src = 'https://swrap.tradedoubler.com/wrap?id=25664';
    }
  }

  handleOnSubmit = async (cds, earlyExit) => {
    const {
      customerDataSet,
      investmentAdviceCds,
      isUserReferredFromMoneySupermarket,
      planSummaryPageVisitedStatus,
      postCdsAnimationLoadingStatus,
      publishCdsCompleted,
      ongoingCds,
    } = this.props;
    try {
      if ((!customerDataSet || !customerDataSet.id) && !ongoingCds) {
        return false;
      }
      if (earlyExit) {
        await this.storeQuestions(cds, true, earlyExit);
        return false;
      }
      if (investmentAdviceCds) {
        if (isUserReferredFromMoneySupermarket) {
          planSummaryPageVisitedStatus(false);
        }
        postCdsAnimationLoadingStatus(true);
        this.setState({ showOnboardingAdviceLoader: true });
      }

      this.addPartnerTag();
      await this.storeQuestions(cds, true);
      publishCdsCompleted(this.actionPrefix);
      return true;
    } catch (error) {
      sentryException(error, {
        section: 'customer-data-set-page-handleOnSubmit',
      });
      this.setState({ showOnboardingAdviceLoader: false, showErrorModal: true });
    }
  }

  storeQuestions = async (cds, complete = false, earlyExit = false) => {

    const {
      uid,
      firestore,
      customerDataSet,
      ongoingCds,
    } = this.props;

    const { skipSteps } = this.props;

    const structuredCds = {
      ...cds,
      complete,
      customer: uid,
      medium: MEDIUM,
      ongoing: ongoingCds,
      earlyExit,
      skipSteps,
    };

    if (structuredCds.complete) {
      structuredCds.completedDate = (new Date()).toISOString();
    }

    const existingCdsId = get(customerDataSet, 'id');
    const isExistingCdsComplete = get(customerDataSet, 'complete');
    if (existingCdsId && !isExistingCdsComplete) {
      const updateCds = firestore.collection('users').doc(uid).collection('customerDataSet').doc(existingCdsId);
      await updateCds.update(structuredCds);
    } else {
      const newCds = firestore.collection('users').doc(uid).collection('customerDataSet');
      await newCds.add(structuredCds);
    }
    return structuredCds;
  }

  render() {
    const { showErrorModal, showOnboardingAdviceLoader, isLoading } = this.state;
    const { postCdsAnimationLoadingStatus, shouldShowMSMBanner } = this.props;
    return (
      <Fragment>
        <div className={styles.header}>
          <div className={styles.headerContent}>
            {showErrorModal
              ? <NavigationPrimary />
              : (
                <div className={styles.headerLogo}>
                  <a href="/"><img src={logoUrlWithMfGroup} alt="Profile Pensions Logo" /></a>
                </div>
              )}
          </div>
        </div>
        <div className={styles.section}>
          {shouldShowMSMBanner && <MsmPartnershipTile />}
          {showOnboardingAdviceLoader && (
            <div className={classnames(styles.postCdsLoaderWrapper, {
              [styles.withMsmTile]: shouldShowMSMBanner,
            })}
            >
              {(shouldShowMSMBanner && ENABLE_MSM_LOADER)
                ? (
                  <DynamicLoaderMSM
                    callback={() => {
                      postCdsAnimationLoadingStatus(false);
                    }}
                  />
                ) : (
                  <DynamicLoader
                    callback={() => {
                      postCdsAnimationLoadingStatus(false);
                    }}
                  />
                )}
            </div>
          )}
          {isLoading && (
          <div className={classnames(styles.postCdsLoaderWrapper)}>
            <Loader />
          </div>
          )}
          {((!showOnboardingAdviceLoader || showErrorModal) && !isLoading) && (
            <div className={styles.sectionWrapper}>
              <div className={styles.customerDataSet}>
                {showErrorModal && <ErrorModal />}
                {this.getContent()}
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

CustomerDataSetPage.defaultProps = {
  investmentAdviceCds: false,
  ongoingCds: false,
};

const commonMapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
  user: getUserProfile(state),
  cdsLoadingError: getCdsLoadingError(state),
  pippaId: getPippaId(state),
  shouldShowMSMBanner: getShouldShowMSMBanner(state),
});

const investmentAdviceMapStateToProps = (state) => {
  const customerDataSet = getLatestIncompleteInvestmentAdviceCds(state);
  const ongoingCds = false;
  const previousAnswers = getPreviousAnswersForOnboarding(state, customerDataSet);
  return {
    ...commonMapStateToProps(state),
    ongoingCds,
    customerDataSet,
    previousAnswers,
    investmentAdviceCds: true,
    isTradeDoublerConversionTagAllowed: getIsTradeDoublerConversionTagAllowed(state),
    uid: getFirebaseUid(state),
    pippaId: getPippaId(state),
    postCdsAnimationLoading: getPostCdsAnimationLoading(state),
    isUserReferredFromMoneySupermarket: getUserReferredFromMoneySupermarket(state),
    skipSteps: getQuestionsToSkipForOnboarding(state, customerDataSet),
  };
};

const ongoingMapStateToProps = (state) => {
  const ongoingCds = true;
  return {
    ...commonMapStateToProps(state),
    ongoingCds,
    skipSteps: getQuestionsToSkipForOngoing(state),
    getPreviousOngoingAnswers: getPreviousAnswersForOngoing(state),
  };
};

export const InvestmentRecommendationCustomerDataSetPage = compose(
  withFirestore,
  connect(investmentAdviceMapStateToProps, { ...cdsActions, ...investmentAdviceActions }),
)(withRouter(CustomerDataSetPage));

export const OngoingCustomerDataSetPage = compose(
  withFirestore, connect(ongoingMapStateToProps, cdsActions),
)(withRouter(CustomerDataSetPage));
