import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import features from '../../util/features';
import logoUrlWithMfGroup from '../../assets/images/rebranding/pp-logo-mf-group.svg';
import UserIcon from '../../assets/images/icons/Icon_User.svg';
import NotificationIcon from '../../assets/images/icons/Icon_Alerts_Bell.svg';
import NavigationSubMenu from '../NavigationSubMenu/NavigationSubMenu';
import NavigationMenuIcon from '../NavigationMenuIcon/NavigationMenuIcon';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import { getName, getIsAuthenticated } from '../../redux/selectors';
import { getConversationsByRead } from '../../redux/selectors/conversations';
import { newMessageMenuItemClickedAction } from '../../redux/modules/conversations';
import styles from './NavigationPrimary.css';

class NavigationPrimary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  render() {
    const {
      name,
      isAuthenticated,
      themeDark,
      themeWhite,
      inboxTheme,
      greyTheme,
      showNavMenu = true,
      unreadConversations,
      dispatchNewMessageMenuItemClicked,
      isCustomerOngoing,
      dashboardTheme,
    } = this.props;
    const { open } = this.state;
    const showNotifications = features.notifications;
    const toggleMenu = () => {
      this.setState((state) => ({
        open: !state.open,
      }));
    };

    const closeMenu = () => {
      this.setState({
        open: false,
      });
    };

    const logoClasses = [
      styles.headerLogo,
      open && styles.open,
    ].join(' ');

    const navClasses = [
      styles.navigation,
      open && styles.open,
    ].join(' ');

    if (open) {
      const html = document.getElementsByTagName('HTML')[0];
      const body = document.getElementsByTagName('BODY')[0];
      html.style.maxHeight = '100vh';
      html.style.overflow = 'hidden';
      body.style.maxHeight = '100vh';
      body.style.overflow = 'hidden';
    } else {
      const html = document.getElementsByTagName('HTML')[0];
      const body = document.getElementsByTagName('BODY')[0];
      html.removeAttribute('style');
      body.removeAttribute('style');
    }

    const userOptions = {
      services: [{
        label: unreadConversations.length ? `Inbox (${unreadConversations.length})` : 'Inbox',
        to: '/inbox',
        onClick: dispatchNewMessageMenuItemClicked,
      },
      {
        label: 'Dashboard',
        to: '/',
      },

      ],
      settings: [
        {
          label: 'Documents',
          to: '/documents',
        },
        {
          label: 'Personal Details',
          to: '/personal-details',
        },
        {
          label: 'Account Settings',
          to: '/account-settings',
        }],
      actions: [{
        label: 'Sign Out',
        to: '/logout',
      }],
    };

    if (isCustomerOngoing) {
      userOptions.services.push({
        label: 'Plan Activity',
        to: '/plan-activity',
      });
    }

    const notificationOptions = [
      {
        label: 'Tasks Outstanding',
        to: '/',
        options: [
          {
            label: 'Task 1',
            to: '/',
          },
          {
            label: 'Task 2',
            to: '/',
          },
          {
            label: 'Task 3',
            to: '/',
          },
        ],
      },
      {
        label: 'Notifications',
        to: '/',
        options: [
          {
            label: 'Notification 1',
            to: '/',
          },
          {
            label: 'Notification 2',
            to: '/',
          },
          {
            label: 'Notification 3',
            to: '/',
          },
        ],
      },
    ];

    const userAvatar = (
      <div>
        <img src={UserIcon} alt="Account Icon" />
      </div>
    );

    const notificationIcon = (
      <div>
        <img src={NotificationIcon} alt="Alert Icon" />
      </div>
    );

    return (
      <div className={styles.header}>
        <div className={classnames(logoClasses, { [styles.dashboardTheme]: dashboardTheme })}>
          <a href="https://www.profilepensions.co.uk/">
            <img
              className={
                classnames(
                  styles.forestLogo,
                  {
                    [styles.themeDark]: themeDark,
                    [styles.themeWhite]: themeWhite,
                    [styles.inboxTheme]: inboxTheme,
                    [styles.greyTheme]: greyTheme,
                  },
                )
              }
              src={logoUrlWithMfGroup}
              alt="Profile Pensions Logo"
            />
            <img
              className={
                classnames(
                  styles.whiteLogo,
                  {
                    [styles.themeDark]: themeDark,
                    [styles.themeWhite]: themeWhite,
                    [styles.inboxTheme]: inboxTheme,
                    [styles.greyTheme]: greyTheme,
                  },
                )
              }
              src={logoUrlWithMfGroup}
              alt="Profile Pensions Logo"
            />
          </a>
        </div>
        {showNavMenu && (
          <div className={styles.navWrapper}>
            {isAuthenticated && (
              <NavigationMenuIcon
                themeDark={themeDark || themeWhite}
                inboxTheme={inboxTheme || greyTheme}
                open={open}
                onClick={toggleMenu}
              />
            )}
            <nav className={navClasses}>
              <ul className={styles.navItems}>
                {
                  isAuthenticated && (
                    <NavigationSubMenu
                      icon={userAvatar}
                      label={name || 'My account'}
                      onClick={closeMenu}
                      options={userOptions}
                      themeDark={themeDark || themeWhite}
                      inboxTheme={inboxTheme}
                      greyTheme={greyTheme}
                    />
                  )
                }
                {
                  showNotifications && (
                    <NavigationSubMenu
                      iconOnly
                      icon={notificationIcon}
                      label="Alerts"
                      onClick={closeMenu}
                      options={notificationOptions}
                    />
                  )
                }
              </ul>
            </nav>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  name: getName(state),
  isAuthenticated: getIsAuthenticated(state),
  unreadConversations: getConversationsByRead(state).unread,
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  dispatchNewMessageMenuItemClicked: newMessageMenuItemClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationPrimary);
