exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);

// module
exports.push([module.id, ".-AddMessageAttachments-attachmentsWrapper-1tven {\n  margin: 0 0 20px 0;\n}\n.-AddMessageAttachments-addAttachmentWrapper-3ghme {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  cursor: pointer;\n}\n.-AddMessageAttachments-addAttachmentCopy-15ft3 {\n  margin: 0 0 0 11px;\n}\n/* Draggable styles */\n.-AddMessageAttachments-attachmentUploadArrow-2jHN8 {\n  -webkit-transform: rotate(180deg);\n          transform: rotate(180deg);\n  width: 43px;\n}\n.-AddMessageAttachments-attachmentDesktopView-3UJm6 {\n  display: none;\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["desktop"] + ") {\n  .-AddMessageAttachments-attachmentMobileView-2nQGq {\n    display: none;\n  }\n\n  .-AddMessageAttachments-attachmentDesktopView-3UJm6 {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"typography": "\"../../theme/typography.css\"",
	"bodyOne": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"dimensions": "\"../../theme/dimensions.css\"",
	"desktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["desktop"] + "",
	"attachmentsWrapper": "-AddMessageAttachments-attachmentsWrapper-1tven",
	"addAttachmentWrapper": "-AddMessageAttachments-addAttachmentWrapper-3ghme",
	"addAttachmentCopy": "-AddMessageAttachments-addAttachmentCopy-15ft3 " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"attachmentUploadArrow": "-AddMessageAttachments-attachmentUploadArrow-2jHN8",
	"attachmentDesktopView": "-AddMessageAttachments-attachmentDesktopView-3UJm6",
	"attachmentMobileView": "-AddMessageAttachments-attachmentMobileView-2nQGq"
};