import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import once from 'lodash/once';
import get from 'lodash/get';

import { currencyString } from '../../util/currency';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import TextInput from '../../components/TextInput/TextInput';

import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import {
  getAuthoritativeRetirementPlan,
} from '../../redux/selectors';
import {
  nextClickedAction,
  backClickedAction,
  withdrawalAmountChangedAction,
  withdrawalAmountStepVisitedAction,
} from '../../redux/modules/withdrawal';

import LumpSumsAmountImage from '../../assets/images/withdrawals/calc-book.svg';

import styles from './WithdrawalPage.css';

const WithdrawalAmount = ({
  step,
  totalSteps,
  setStep,
  formData,
  setFormData,
  dispatchWithdrawalAmountChanged,
  dispatchWithdrawalAmountStepVisited,
  dispatchNextClicked,
  dispatchBackClicked,
  authoritativeRp,
}) => {
  const [_dispatchWithdrawalAmountChanged] = useState(() => once(dispatchWithdrawalAmountChanged));
  const pensionValue = get(authoritativeRp, 'latestPensionValue');
  const errorMessage = 'The withdrawal amount you\'ve requested exceeds your current pension value. Please enter a valid withdrawal amount.';

  useEffect(() => {
    dispatchWithdrawalAmountStepVisited();
  }, []);

  const amountInputChanged = (amount) => {
    const withdrawalAmount = parseInt(amount, 10);
    const withdrawalAmountMask = currencyString(withdrawalAmount);
    setFormData({
      ...formData,
      withdrawalAmount,
      withdrawalAmountMask,
    });
  };

  return (
    <BaseGrid addPensionsTheme>
      <div className={styles.withdrawalsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          onClick={(event) => {
            dispatchBackClicked();
            setStep((_step) => _step - 1);
            event.stopPropagation();
          }}
          label="← Back"
        />
        <div className={styles.withdrawalsImageContainer}>
          <img style={{ maxWidth: '105px' }} src={LumpSumsAmountImage} alt="books" />
        </div>
        <h2 className={styles.headingTwo}>{'How much money are you looking to take from your pension?'}</h2>
        <div className={styles.withdrawalsAmountWrapper}>
          <div className={styles.withdrawalsAmountRowContainer}>
            <TextInput
              value={formData.withdrawalAmountMask}
              placeholder={'£ Enter amount'}
              onChange={(event) => {
                _dispatchWithdrawalAmountChanged();
                const strippedInput = event.target.value.replace(/\D/g, '');
                if (strippedInput) {
                  amountInputChanged(strippedInput);
                } else {
                  setFormData({ ...formData, withdrawalAmountMask: '', withdrawalAmount: 0 });
                }
              }}
            />
          </div>
        </div>
        <div className={styles.summaryContainer}>
          <div className={styles.summaryDetailsLine}>
            <div className={styles.body}>{'Your withdrawal'}</div>
            <div className={styles.largeBody}>{currencyString(formData.withdrawalAmount)}</div>
          </div>
          <div className={styles.lineDivider} />
          <div className={styles.summaryDetailsLine}>
            <div className={styles.body}>{'Withdrawal type'}</div>
            <div className={classNames(styles.bodyBold, styles.largeBody)}>{'One off lump sum'}</div>
          </div>
          <div className={styles.lineDivider} />
        </div>
        <p className={styles.greyBox}>{'If this is your first withdrawal or if you have already started drawing from your pension and some of your tax free cash allowance is still available, we will use this element first.'}</p>
        <div className={styles.pensionAdviserContainer}>
          <div className={styles.pensionAdviserCard}>
            <ContactUsContent
              source={'WithdrawalAmountStep'}
              noExpertContentWrapper={({ children }) => (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexShrink: 'initial',
                  padding: '10px',
                  flexWrap: 'wrap',
                }}
                >
                  {children}
                </div>
              )}
              showButton={false}
              customMessage={(
                <div className={styles.contactUsInnerContent}>
                  <span>
                    {'If you want to access your lump sum any differently, please '}
                    <ButtonLink
                      variant="primary"
                      displayAsHref
                      to="/inbox/new-message"
                      search={'?subject=Withdrawal%20Request'}
                      font="inherit"
                      label={'message your Pension Adviser'}
                    />
                    {'.'}
                  </span>
                </div>
              )}
            />
          </div>
        </div>
        {formData.withdrawalAmount > pensionValue && (
          <div className={styles.standardContainer}>
            <div className={styles.withdrawalAmountErrorMessage}>
              {errorMessage}
            </div>
          </div>
        )}
        <Button
          size="large"
          disabled={(!formData.withdrawalAmount || formData.withdrawalAmount > pensionValue)}
          label={'Next'}
          onClick={() => {
            dispatchNextClicked();
            setStep((_step) => _step + 1);
          }}
        />
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  authoritativeRp: getAuthoritativeRetirementPlan(state),
});

const mapDispatchToProps = {
  dispatchWithdrawalAmountChanged: withdrawalAmountChangedAction,
  dispatchWithdrawalAmountStepVisited: withdrawalAmountStepVisitedAction,
  dispatchNextClicked: nextClickedAction,
  dispatchBackClicked: backClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalAmount);
