import React from 'react';
import PropTypes from 'prop-types';
import styles from './TabFilter.css';


const TabFilter = ({ onChange, selected, items, multiple }) => {
  // TODO add multi select cabability (temporarily descoped for document filter)
  const handleSelect = value => onChange([value]);
  const tabClasses = value => [
    styles.tab,
    selected.includes(value) && styles.active,
  ].join(' ');
  return (
    <div className={styles.wrapper} data-cy="filter-container">
      {items.map(({ value, label }) => <button className={tabClasses(value)} key={value} onClick={() => handleSelect(value)}>{label}</button>)}
    </div>
  );
};

export default TabFilter;

TabFilter.propTypes = {
  onChange: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  multiple: PropTypes.bool,
};

TabFilter.defaultProps = {
  onChange: null,
  selected: [],
  items: [],
  multiple: false,
};
