import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';

import splitSentence from '../../util/splitSentence';
import styles from './NotificationBanner.css';

const ExpandableText = ({
  text,
  maxCharacters = 130,
  mobileOnly,
  notificationBannerText,
}) => {
  const classNames = classnames(
    {
      [styles.bodyText]: notificationBannerText,
    },
  );

  const [isExpanded, setIsExpanded] = useState(false);

  const isMobile = mobileOnly
    && !useMediaQuery({
      query: '(min-width: 768px)',
    });

  const toggleExpandableText = () => {
    setIsExpanded(!isExpanded);
  };

  if (!text) {
    return null;
  }

  if (text.length > maxCharacters && isMobile) {
    const { truncatedText, remainingText } = splitSentence(text, maxCharacters);
    const displayText = isExpanded
      ? `${truncatedText} ${remainingText}`
      : truncatedText;

    return (
      <p className={classNames}>
        {displayText}
        {' '}
        <button
          type="button"
          className={classNames}
          style={{
            textDecoration: 'underline',
            fontWeight: 700,
            cursor: 'pointer',
            border: 'none',
            background: 'none',
            padding: 0,
          }}
          onClick={toggleExpandableText}
        >
          {isExpanded ? 'Read less' : 'Read more'}
        </button>
      </p>
    );
  }

  return <p className={classNames}>{text}</p>;
};

export default ExpandableText;
