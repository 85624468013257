import React from 'react';
import classnames from 'classnames';

import logoMSM from '../../assets/images/MSM.png';

import styles from './MsmPartnershipTile.css';

const MsmPartnershipTile = ({ msmStyles }) => {
  return (
    <div className={styles.HeaderSection}>
      <div className={classnames(styles.HeaderSectionWrapper, {
        [styles.msmHeaderSectionWrapper]: msmStyles,
      })}
      >
        <p>
          {'In partnership with'}
        </p>
        <a target="_blank" href="https://www.moneysupermarket.com/" rel="noreferrer">
          <img
            src={logoMSM}
            alt="Money Super Market Logo"
          />
        </a>
      </div>
    </div>
  );
};

MsmPartnershipTile.propTypes = {
};

MsmPartnershipTile.defaultProps = {
};

export default MsmPartnershipTile;
