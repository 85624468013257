import firebase from 'firebase/compat/app';
import sentryException from '../../util/sentryException';
import { formatLumpSumEvent, formatOngoingLumpSumEvent } from '../util/eventKeyFormats';

const SETUP_LUMP_SUM_DISPATCHED = formatLumpSumEvent('SETUP_LUMP_SUM_DISPATCHED');
const ONGOING_SETUP_LUMP_SUM_DISPATCHED = formatOngoingLumpSumEvent('SETUP_LUMP_SUM_DISPATCHED');
const SETUP_LUMP_SUM_FULFILLED = formatLumpSumEvent('SETUP_LUMP_SUM_FULFILLED');
const ONGOING_SETUP_LUMP_SUM_FULFILLED = formatOngoingLumpSumEvent('SETUP_LUMP_SUM_FULFILLED');
const SETUP_LUMP_SUM_REJECTED = formatLumpSumEvent('SETUP_LUMP_SUM_REJECTED');
const ONGOING_SETUP_LUMP_SUM_REJECTED = formatOngoingLumpSumEvent('SETUP_LUMP_SUM_REJECTED');

export const submitErrorAction = () => ({ type: formatLumpSumEvent('SUBMIT_ERROR') });
export const ongoingSubmitErrorAction = () => ({ type: formatOngoingLumpSumEvent('SUBMIT_ERROR') });

export const lumpSumAndReliefTermsLinkClickedAction = () => (
  { type: formatLumpSumEvent('LUMP_SUM_AND_RELIEF_TERMS_LINK_CLICKED') }
);

export const ongoingLumpSumAndReliefTermsLinkClickedAction = () => (
  { type: formatOngoingLumpSumEvent('LUMP_SUM_AND_RELIEF_TERMS_LINK_CLICKED') }
);

export const termsOfBusinessLinkClickedAction = () => (
  { type: formatLumpSumEvent('TERMS_OF_BUSINESS_LINK_CLICKED') }
);

export const ongoingTermsOfBusinessLinkClickedAction = () => (
  { type: formatOngoingLumpSumEvent('TERMS_OF_BUSINESS_LINK_CLICKED') }
);

export const authoriseLumpSumUnselectedAction = () => (
  { type: formatLumpSumEvent('AUTHORISE_LUMP_SUM_UNSELECTED') }
);

export const ongoingAuthoriseLumpSumUnselectedAction = () => (
  { type: formatOngoingLumpSumEvent('AUTHORISE_LUMP_SUM_UNSELECTED') }
);

export const authoriseLumpSumSelectedAction = () => (
  { type: formatLumpSumEvent('AUTHORISE_LUMP_SUM_SELECTED') }
);

export const ongoingAuthoriseLumpSumSelectedAction = () => (
  { type: formatOngoingLumpSumEvent('AUTHORISE_LUMP_SUM_SELECTED') }
);

export const taxReliefEligibleSelectedAction = () => (
  { type: formatLumpSumEvent('TAX_RELIEF_SELECTED') }
);

export const ongoingTaxReliefEligibleSelectedAction = () => (
  { type: formatOngoingLumpSumEvent('TAX_RELIEF_SELECTED') }
);

export const taxReliefEligibleUnselectedAction = () => (
  { type: formatLumpSumEvent('TAX_RELIEF_UNSELECTED') }
);

export const ongoingTaxReliefEligibleUnselectedAction = () => (
  { type: formatOngoingLumpSumEvent('TAX_RELIEF_UNSELECTED') }
);

export const changeLumpSumClickedAction = () => (
  { type: formatLumpSumEvent('CHANGE_LUMP_SUM_CLICKED') }
);

export const ongoingChangeLumpSumClickedAction = () => (
  { type: formatOngoingLumpSumEvent('CHANGE_LUMP_SUM_CLICKED') }
);

export const submitClickedAction = () => (
  { type: formatLumpSumEvent('SUBMIT_CLICKED') }
);

export const ongoingSubmitClickedAction = () => (
  { type: formatOngoingLumpSumEvent('SUBMIT_CLICKED') }
);

export const lumpSumSubmitStepVisitedAction = () => (
  { type: formatLumpSumEvent('LUMP_SUM_SUBMIT_STEP_VISITED') }
);

export const ongoingLumpSumSubmitStepVisitedAction = () => (
  { type: formatOngoingLumpSumEvent('LUMP_SUM_SUBMIT_STEP_VISITED') }
);

export const salaryChangedAction = () => (
  { type: formatLumpSumEvent('SALARY_CHANGED') }
);

export const ongoingSalaryChangedAction = () => (
  { type: formatOngoingLumpSumEvent('SALARY_CHANGED') }
);

export const occupationChangedAction = () => (
  { type: formatLumpSumEvent('OCCUPATION_CHANGED') }
);

export const ongoingOccupationChangedAction = () => (
  { type: formatOngoingLumpSumEvent('OCCUPATION_CHANGED') }
);

export const otherSourceOfFundsChangedAction = () => (
  { type: formatLumpSumEvent('OTHER_SOURCE_OF_FUNDS_CHANGED') }
);

export const ongoingOtherSourceOfFundsChangedAction = () => (
  { type: formatOngoingLumpSumEvent('OTHER_SOURCE_OF_FUNDS_CHANGED') }
);

export const sourceOfFundsChangedAction = () => (
  { type: formatLumpSumEvent('SOURCE_OF_FUNDS_CHANGED') }
);

export const ongoingSourceOfFundsChangedAction = () => (
  { type: formatOngoingLumpSumEvent('SOURCE_OF_FUNDS_CHANGED') }
);

export const lumpSumSourceOfFundsStepVisitedAction = () => (
  { type: formatLumpSumEvent('LUMP_SUM_SOURCE_OF_FUNDS_STEP_VISITED') }
);

export const ongoingLumpSumSourceOfFundsStepVisitedAction = () => (
  { type: formatOngoingLumpSumEvent('LUMP_SUM_SOURCE_OF_FUNDS_STEP_VISITED') }
);

export const referFriendClickedAction = () => (
  { type: formatLumpSumEvent('REFER_FRIEND_CLICKED') }
);

export const ongoingReferFriendClickedAction = () => (
  { type: formatOngoingLumpSumEvent('REFER_FRIEND_CLICKED') }
);

export const lumpSumCompleteStepVisitedAction = () => (
  { type: formatLumpSumEvent('LUMP_SUM_COMPLETE_STEP_VISITED') }
);

export const ongoingLumpSumCompleteStepVisitedAction = () => (
  { type: formatOngoingLumpSumEvent('LUMP_SUM_COMPLETE_STEP_VISITED') }
);

export const confirmClickedAction = () => (
  { type: formatLumpSumEvent('CONFIRM_CLICKED') }
);

export const ongoingConfirmClickedAction = () => (
  { type: formatOngoingLumpSumEvent('CONFIRM_CLICKED') }
);

export const nextClickedAction = () => (
  { type: formatLumpSumEvent('NEXT_CLICKED') }
);

export const ongoingNextClickedAction = () => (
  { type: formatOngoingLumpSumEvent('NEXT_CLICKED') }
);

export const amountManuallyChangedAction = () => (
  { type: formatLumpSumEvent('AMOUNT_MANUALLY_CHANGED') }
);

export const ongoingAmountManuallyChangedAction = () => (
  { type: formatOngoingLumpSumEvent('AMOUNT_MANUALLY_CHANGED') }
);

export const taxReliefLinkClickedAction = () => (
  { type: formatLumpSumEvent('TAX_RELIEF_LINK_CLICKED') }
);

export const ongoingTaxReliefLinkClickedAction = () => (
  { type: formatOngoingLumpSumEvent('TAX_RELIEF_LINK_CLICKED') }
);

export const returnLinkClickedAction = () => (
  { type: formatLumpSumEvent('RETURN_LINK_CLICKED') }
);

export const ongoingReturnLinkClickedAction = () => (
  { type: formatOngoingLumpSumEvent('RETURN_LINK_CLICKED') }
);

export const lumpSumAmountStepVisitedAction = () => (
  { type: formatLumpSumEvent('LUMP_SUM_AMOUNT_STEP_VISITED') }
);

export const ongoingLumpSumAmountStepVisitedAction = () => (
  { type: formatOngoingLumpSumEvent('LUMP_SUM_AMOUNT_STEP_VISITED') }
);

export const lumpSumAmountStepMessageMeClickedAction = () => (
  { type: formatLumpSumEvent('LUMP_SUM_AMOUNT_STEP_MESSAGE_ME_CLICKED') }
);

export const ongoingLumpSumAmountStepMessageMeClickedAction = () => (
  { type: formatOngoingLumpSumEvent('LUMP_SUM_AMOUNT_STEP_MESSAGE_ME_CLICKED') }
);

export const authoriseImplementPensionPlanUnselectedAction = () => (
  { type: formatLumpSumEvent('AUTHORISE_IMPLEMENT_PENSION_PLAN_UNSELECTED') }
);

export const ongoingAuthoriseImplementPensionPlanUnselectedAction = () => (
  { type: formatOngoingLumpSumEvent('ONGOING_AUTHORISE_IMPLEMENT_PENSION_PLAN_UNSELECTED') }
);

export const authoriseImplementPensionPlanSelectedAction = () => (
  { type: formatLumpSumEvent('AUTHORISE_IMPLEMENT_PENSION_PLAN_SELECTED') }
);

export const ongoingAuthoriseImplementPensionPlanSelectedAction = () => (
  { type: formatOngoingLumpSumEvent('AUTHORISE_IMPLEMENT_PENSION_PLAN_SELECTED') }
);

export const illustrationLinkClickedAction = () => (
  { type: formatLumpSumEvent('ILLUSTRATION_LINK_CLICKED') }
);

export const ongoingIllustrationLinkClickedAction = () => (
  { type: formatOngoingLumpSumEvent('ILLUSTRATION_LINK_CLICKED') }
);

export const keyDetailsLinkClickedAction = () => (
  { type: formatLumpSumEvent('KEY_DETAILS_LINK_CLICKED') }
);

export const ongoingKeyDetailsLinkClickedAction = () => (
  { type: formatOngoingLumpSumEvent('KEY_DETAILS_LINK_CLICKED') }
);

export const recommendationReportLinkClickedAction = () => (
  { type: formatLumpSumEvent('RECOMMENDATION_REPORT_LINK_CLICKED') }
);

export const ongoingRecommendationReportLinkClickedAction = () => (
  { type: formatOngoingLumpSumEvent('RECOMMENDATION_REPORT_LINK_CLICKED') }
);

export const setupLumpSumAction = (
  lumpSumData,
  isCustomerOngoing,
) => async (dispatch) => {
  const addContritbutionInstruction = firebase.app().functions('europe-west1').httpsCallable('addInstruction');
  const dispatchedType = isCustomerOngoing
    ? ONGOING_SETUP_LUMP_SUM_DISPATCHED
    : SETUP_LUMP_SUM_DISPATCHED;
  const fulfilledType = isCustomerOngoing
    ? ONGOING_SETUP_LUMP_SUM_FULFILLED
    : SETUP_LUMP_SUM_FULFILLED;
  const rejectedType = isCustomerOngoing
    ? ONGOING_SETUP_LUMP_SUM_REJECTED
    : SETUP_LUMP_SUM_REJECTED;
  dispatch({ type: dispatchedType });
  try {
    await addContritbutionInstruction(lumpSumData);
    dispatch({ type: fulfilledType });
  } catch (e) {
    dispatch({ type: rejectedType });
    sentryException(e, {
      section: 'setup-lumpSum',
    });
    throw e;
  }
};
