import Tick from '../../../assets/images/icons/smallGreenTick.svg';
import Cross from '../../../assets/images/icons/thick_fog_cross.svg';

const featureItemsText = {
  featureOne: 'Find pension and detailed policy information',
  featureTwo: 'Check for guarantees and exit penalties',
  featureThree: 'Arrange the transfer of your pension',
};

const pensionTransferOptionContent = [
  {
    summary: 'We will track down your old pensions, check for exit penalties and guarantees and arrange the transfer for you.',
    featureItems: [
      {
        icon: Tick,
        text: featureItemsText.featureOne,
      },
      {
        icon: Tick,
        text: featureItemsText.featureTwo,
      },
      {
        icon: Tick,
        text: featureItemsText.featureThree,
      },
    ],
    suitableIfBody: 'You would like to transfer your old pension but do not know where your old policies are or you are not confident about the details of your existing pension policies and would like expert guidance.',
    costBody: '1% arrangement fee for the find and check service, taken from the pension at point of transfer.',
  },
  {
    summary: 'You give us the provider name, policy number and estimated value and we arrange the transfer for you.',
    featureItems: [
      {
        icon: Cross,
        text: featureItemsText.featureOne,
      },
      {
        icon: Cross,
        text: featureItemsText.featureTwo,
      },
      {
        icon: Tick,
        text: featureItemsText.featureThree,
      },
    ],
    suitableIfBody: 'You know where your old pensions are and have all the details to transfer them. You understand the details of your policy and you do not require expert guidance on any possible guarantees or exit penalties.',
    costBody: 'No charge to transfer your pension',
  },
];

export default pensionTransferOptionContent;
