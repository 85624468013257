import moment from 'moment';
import { getFirestore } from 'redux-firestore';
import { setDoc, doc } from 'firebase/firestore';

import firebase from '../../util/firebaseInstance';

/**
 * Creates a new event from the action type and
 * store the event in the events sub collection
 * on the users collection.
 */
const recordEvents = () => (next) => (action) => {
  if (firebase.auth().currentUser && action.type) {
    const firestore = getFirestore();
    const { uid } = firebase.auth().currentUser;

    let event = {
      type: 'account-hub-action',
      key: action.type,
      date: moment.utc().format(),
      createdBy: 'account-hub',
    };

    if (action.metadata) {
      event = { ...event, metadata: action.metadata };
    }

    const newEvent = firestore.collection('users').doc(uid).collection('events');
    setDoc(doc(newEvent), event);
  }

  return next(action);
};

export default recordEvents;
