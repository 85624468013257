import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import ContributionAmountStep from './ContributionAmountStep';
import ContributionInflationStep from './ContributionInflationStep';
import ContributionSourceOfFundsStep from './ContributionSourceOfFundsStep';
import ContributionBankDetailsStep from './ContributionBankDetailsStep';
import ContributionSubmitStep from './ContributionSubmitStep';
import ContributionCompleteStep from './ContributionCompleteStep';
import MissingDetailsPage from '../PersonalDetails/MissingDetails';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import {
  getMissingDetailsRequiredFields,
  getStepWithRequiredFields,
  getVerifiedNetPersonalContributions,
  getOverallContributions,
} from '../../redux/selectors';
import {
  getLatestCompleteContributionInstruction,
} from '../../redux/selectors/investmentAdvice';
import WithInvestmentAdviceLetter from '../../components/WithDocumentLink/WithInvestmentAdviceLetter';

const CONTRIBUTION_AMOUNT_STEP = 'CONTRIBUTION_AMOUNT_STEP';
const CONTRIBUTION_INFLATION_STEP = 'CONTRIBUTION_INFLATION_STEP';
const CONTRIBUTION_SOURCE_OF_FUNDS_STEP = 'CONTRIBUTION_SOURCE_OF_FUNDS_STEP';
const BANK_DETAILS_STEP = 'BANK_DETAILS_STEP';
const SUBMIT_STEP = 'SUBMIT_STEP';
const MISSING_DETAILS_STEP = 'MISSING_DETAILS_STEP';
const THANK_YOU_STEP = 'THANK_YOU_STEP';

const SetupContributionPage = ({
  isCustomerOngoing,
  stepWithRequiredFields,
  missingDetailsOnly,
  investmentAdviceLetterUrl,
  verifiedNetPersonalContributions,
  flow,
  overallContributions,
  latestCompleteContributionInstruction,
}) => {
  const instructionType = (() => {
    const isActive = get(overallContributions, 'status') === 'active';
    if (verifiedNetPersonalContributions || isActive) return 'contribution-amend-regular';
    if (isCustomerOngoing) return 'contribution-regular';
    return 'new-customer-contribution-regular';
  })();

  const previousContributionAmount = (() => {
    if (instructionType !== 'contribution-amend-regular') return null;
    if (verifiedNetPersonalContributions) return verifiedNetPersonalContributions;
    const completeContributionAmount = get(latestCompleteContributionInstruction, 'regularContributionFields.amountPerMonth');
    if (completeContributionAmount) return completeContributionAmount;
    return null;
  })();
  const [formData, setFormData] = useState({
    amountPerMonth: verifiedNetPersonalContributions ? 0 : 100,
    amountPerMonthMask: '',
    salaryMask: '',
    type: instructionType,
    ongoing: isCustomerOngoing,
  });
  const [totalSteps, setTotalSteps] = useState(6);

  const [step, setStep] = useState(0);
  const [amendMode, setAmendMode] = useState(false);

  const stepsMap = {
    [CONTRIBUTION_AMOUNT_STEP]: (
      <ContributionAmountStep
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
        amendMode={amendMode}
        setAmendMode={setAmendMode}
        previousContributionAmount={previousContributionAmount}
      />
    ),
    [CONTRIBUTION_INFLATION_STEP]: (
      <ContributionInflationStep
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
        step={step}
        amendMode={amendMode}
      />
    ),
    [CONTRIBUTION_SOURCE_OF_FUNDS_STEP]: (
      <ContributionSourceOfFundsStep
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
        step={step}
        amendMode={amendMode}
      />
    ),
    [BANK_DETAILS_STEP]: (
      <ContributionBankDetailsStep
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
        step={step}
        amendMode={amendMode}
        setAmendMode={setAmendMode}
      />
    ),
    [SUBMIT_STEP]: (
      <ContributionSubmitStep
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
        step={step}
        amendMode={amendMode}
        setAmendMode={setAmendMode}
        investmentAdviceLetterUrl={investmentAdviceLetterUrl}
      />
    ),
    [MISSING_DETAILS_STEP]: (
      <MissingDetailsPage
        stepWithRequiredFields={stepWithRequiredFields}
        journey={stepWithRequiredFields}
        step={step}
        setStep={setStep}
        flow={flow}
      />
    ),
    [THANK_YOU_STEP]: (
      <ContributionCompleteStep
        step={step}
        totalSteps={totalSteps}
      />
    ),
  };

  const getJourney = () => {
    const setupSteps = [
      CONTRIBUTION_AMOUNT_STEP,
      CONTRIBUTION_INFLATION_STEP,
      CONTRIBUTION_SOURCE_OF_FUNDS_STEP,
      BANK_DETAILS_STEP,
      SUBMIT_STEP,
    ];

    if (missingDetailsOnly) {
      return [
        MISSING_DETAILS_STEP,
      ];
    }

    if (stepWithRequiredFields.length) {
      return [
        ...setupSteps,
        MISSING_DETAILS_STEP,
      ];
    }

    return [
      ...setupSteps,
      THANK_YOU_STEP,
    ];
  };

  const [journey] = useState(getJourney());
  if (journey.length !== totalSteps) {
    setTotalSteps(journey.length);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    stepsMap[journey[step] || CONTRIBUTION_AMOUNT_STEP]
  );
};

const mapStateToProps = (state, ownProps) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
  missingDetailsRequiredFields: getMissingDetailsRequiredFields(state, ownProps.flow),
  stepWithRequiredFields: getStepWithRequiredFields(state, ownProps.flow),
  verifiedNetPersonalContributions: getVerifiedNetPersonalContributions(state),
  overallContributions: getOverallContributions(state),
  latestCompleteContributionInstruction: getLatestCompleteContributionInstruction(state),
});

const SetupContributionPageWithLetter = (props) => (
  <WithInvestmentAdviceLetter>
    {(investmentAdviceLetterUrl) => <SetupContributionPage {...{ ...props, investmentAdviceLetterUrl }} />}
  </WithInvestmentAdviceLetter>
);

const ConnectedSetUpContributionPageLetter = connect(mapStateToProps)(SetupContributionPageWithLetter);

ConnectedSetUpContributionPageLetter.defaultProps = {
  flow: 'add-contribution',
};
export default ConnectedSetUpContributionPageLetter;
