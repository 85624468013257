/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import once from 'lodash/once';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import get from 'lodash/get';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { withFirestore } from 'react-redux-firebase';
import { validate } from 'validate.js';
import { withFormik } from 'formik';
import firebase from 'firebase/compat/app';
import { GENERIC_ERROR } from '../../forms/copyTexts';
import { addressConstraints, convertFlattenedObjectToObject } from '../../util/completePersonalDetailsValidation';
import {
  getFirebaseUid, getUser, getIsVerified,
} from '../../redux/selectors';
import {
  renderAddressFields, renderCheckBox, renderTimeSpentAtAddressField,
  renderPersonalDetailsPostcodeAddressLookup,
} from '../../util/formik-custom-fields';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import {
  updatePreviousAddresses,
  updateAddressDetails,
  updateAddressAddButtonClicked,
  updateAddressCancelButtonClicked,
  previousAddressesAddButtonClicked,
  previousAddressesCancelButtonClicked,
} from '../../redux/modules/investmentAdvice';
import '../../util/signupValidators';
import styles from './PersonalDetails.css';
import { CURRENT_ADDRESS, PREVIOUS_ADDRESSES } from '../../util/constants';
import ServiceAlertCard from '../../components/AlertCard/ServiceAlertCard';

const ADDRESS_FIELDS = ['line1', 'line2', 'line3', 'line4', 'locality', 'city', 'county', 'postcode'];

const removeWhitespace = (string) => {
  return string ? string.replace(/\s/g, '') : '';
};
const uppercase = (string) => {
  return string ? string.toUpperCase() : '';
};
const formatPostcode = (postcode) => {
  return postcode ? `${postcode.slice(0, -3)} ${postcode.slice(-3)}` : '';
};
const joinAddress = (values) => {
  return values.filter((s) => s).join(', ');
};

class AddressContainer extends Component {
  constructor(props) {
    super(props);
    const {
      dispatchUpdateAddressAddButtonClicked,
      dispatchUpdateAddressCancelButtonClicked,
      dispatchPreviousAddressesAddButtonClicked,
      dispatchPreviousAddressesCancelButtonClicked,
    } = this.props;
    this.state = {
      inEditMode: false,
    };
    this.setLocalInEditMode = this.setLocalInEditMode.bind(this);
    this.dispatchUpdateAddressAddButtonClicked = once(dispatchUpdateAddressAddButtonClicked);
    this.dispatchUpdateAddressCancelButtonClicked = once(dispatchUpdateAddressCancelButtonClicked);
    this.dispatchPreviousAddressesAddButtonClicked = once(dispatchPreviousAddressesAddButtonClicked);
    this.dispatchPreviousAddressesCancelButtonClicked = once(dispatchPreviousAddressesCancelButtonClicked);
  }

  componentDidUpdate(prevProps) {
    const {
      isSubmitting,
      isValid,
      errors,
    } = this.props;
    if (!isValid && prevProps.isSubmitting && !isSubmitting) {
      const [firstError] = Object.keys(errors);
      let errorElement = document.querySelector(`input[name="${firstError}"]`);
      if (firstError === 'address') {
        const addressKeys = Object.keys(errors[firstError]);
        errorElement = document.querySelector(`input[name="${firstError}-${addressKeys[0]}"]`);
      }
      if (errorElement) {
        errorElement.parentNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    if (isValid && !prevProps.isSubmitting && isSubmitting) {
      document
        .querySelector('button[type=submit]')
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  setLocalInEditMode = (inEditMode) => {
    this.setState({
      inEditMode,
    });
  }

  constructEmailUsElement = (showOnlyLink) => {
    return (
      <span>
        {(!showOnlyLink) ? 'Please ' : ''}
        <ButtonLink
          variant="primary"
          font="inherit"
          underline
          label="message us"
          to="/inbox/new-message"
        >
          {'message us'}
        </ButtonLink>
        {'.'}
      </span>
    );
  }

  defaultHandleChange = (event) => {
    const { handleChange } = this.props;
    handleChange(event);
  };

  addressHandleChange = (fieldValue) => {
    const {
      setFieldValue,
      setTouched,
      touched,
    } = this.props;

    setFieldValue('address', fieldValue);

    if (fieldValue.line1 && fieldValue.city) {
      setTouched({ ...touched, address: true });
    }
  };

  dateFromHandleChange = (event) => {
    const {
      setFieldValue,
    } = this.props;
    setFieldValue('livedAtAddressDateFrom', new Date(event));
  }

  dateToHandleChange = (event) => {
    const {
      setFieldValue,
    } = this.props;
    setFieldValue('livedAtAddressDateTo', new Date(event));
  }

  noPreviousAddressDatesHandleChange = (event) => {
    const { handleChange, setFieldValue } = this.props;
    handleChange(event);

    if (event.target.checked) {
      setFieldValue('livedAtAddressDateFrom', null);
      setFieldValue('livedAtAddressDateTo', null);
    }
  };

  getAddresses = async (postcode) => {
    let addresses = [];
    const getAddresses = firebase.app().functions('europe-west1').httpsCallable('addressLookup');

    try {
      ({ data: addresses } = await getAddresses({ postcode }));
    } catch (error) {
      console.log(error);
    }

    return addresses;
  };

  selectAddress = (address = '') => {
    const {
      setFieldValue,
      setTouched,
      touched,
    } = this.props;

    const updatedAddress = {
      line1: '',
      line2: '',
      line3: '',
      line4: '',
      locality: '',
      city: '',
      county: '',
      postcode: '',
    };
    address.split(',').forEach((field, index) => {
      if (ADDRESS_FIELDS && ADDRESS_FIELDS[index]) {
        updatedAddress[ADDRESS_FIELDS[index]] = field.trim();
      }
    });
    setFieldValue('address', updatedAddress);
    if (updatedAddress.line1 && updatedAddress.city) {
      setTouched({ ...touched, address: false });
    }
  };

  fieldsConfig = () => {
    const { values, title } = this.props;

    if (title === 'Address') {
      return {
        postcode: {
          type: 'text',
          name: 'postcode',
          label: 'Postcode',
          value: values.postcode,
          onChange: (event) => this.defaultHandleChange(event, 'postcode'),
          component: renderPersonalDetailsPostcodeAddressLookup,
          getAddresses: this.getAddresses,
          selectAddress: this.selectAddress,
        },
        address: {
          type: null,
          name: 'address',
          label: 'Address',
          value: values.address,
          onChange: this.addressHandleChange,
          component: renderAddressFields,
        },
      };
    }

    if (title === 'Previous Addresses') {
      return {
        postcode: {
          type: 'text',
          name: 'postcode',
          label: 'Postcode',
          value: values.postcode,
          onChange: (event) => this.defaultHandleChange(event, 'postcode'),
          component: renderPersonalDetailsPostcodeAddressLookup,
          getAddresses: this.getAddresses,
          selectAddress: this.selectAddress,
        },
        address: {
          type: null,
          name: 'address',
          label: 'Address',
          value: values.address,
          onChange: this.addressHandleChange,
          component: renderAddressFields,
        },
        livedAtAddressDateFrom: {
          type: 'month',
          name: 'livedAtAddressDateFrom',
          label: 'From:',
          min: new Date('1900-01-01'),
          max: values.livedAtAddressDateTo || new Date(),
          value: values.livedAtAddressDateFrom,
          onChange: this.dateFromHandleChange,
          component: renderTimeSpentAtAddressField,
          disabled: values.noPreviousAddressDates,
        },
        livedAtAddressDateTo: {
          type: 'month',
          name: 'livedAtAddressDateTo',
          label: 'Until:',
          min: values.livedAtAddressDateFrom || new Date('1900-01-01'),
          max: new Date(),
          value: values.livedAtAddressDateTo,
          onChange: this.dateToHandleChange,
          component: renderTimeSpentAtAddressField,
          disabled: values.noPreviousAddressDates,
        },
        noPreviousAddressDates: {
          type: 'checkbox',
          name: 'noPreviousAddressDates',
          label: "I can't remember",
          value: values.noPreviousAddressDates,
          onChange: this.noPreviousAddressDatesHandleChange,
          component: renderCheckBox,
          wrapperStyles: { float: 'left' },
        },
      };
    }
  }

  /* eslint consistent-return: 0 */
  constructErrorMessage = (data) => {
    if (!data) {
      return;
    }

    return (
      <div>
        {GENERIC_ERROR}
        {this.constructEmailUsElement()}
      </div>
    );
  }

  render() {
    const {
      handleSubmit,
      isSubmitting,
      errors,
      values,
      isValid,
      initialValues,
      renderField,
      status,
      handleReset,
      setComponentIsEditing,
      componentIsEditing,
      setStatus,
      dispatchUpdateAddressAddButtonClicked,
      dispatchUpdateAddressCancelButtonClicked,
      dispatchPreviousAddressesAddButtonClicked,
      dispatchPreviousAddressesCancelButtonClicked,
      title,
      type,
      user,
    } = this.props;

    const previousAddresses = get(user, 'personal.previousAddresses', []);
    const addressEmpty = Object.values(values.address).every((x) => x === null || x === '');
    const {
      inEditMode,
    } = this.state;
    const fieldsConfig = this.fieldsConfig();
    const formValuesHaveChanged = !isEqual(initialValues, values);
    const noPreviousAddressDatesAdded = (!values.livedAtAddressDateFrom || !values.livedAtAddressDateTo) && !values.noPreviousAddressDates;

    const submitSuccessful = get(status, 'submitSuccessful');
    const showUpdate = !inEditMode && !componentIsEditing;
    const showSave = inEditMode && formValuesHaveChanged && componentIsEditing;
    const showCancel = inEditMode && componentIsEditing;

    const disableSaveButton = () => {
      if (title === 'Address') {
        return !formValuesHaveChanged || isSubmitting || !isValid;
      }
      return !formValuesHaveChanged || isSubmitting || !isValid || noPreviousAddressDatesAdded;
    };

    const formatAddressAndPreviousAddressDates = (value) => {
      if (value.livedAtAddressDateFrom && value.livedAtAddressDateTo) {
        return `${value.street}
              ${value.city}, ${value.county || ''} ${value.postCode}
              Time spent at this address:
              ${moment(value.livedAtAddressDateFrom).format('MM/YYYY')} - ${moment(value.livedAtAddressDateTo).format('MM/YYYY')} `;
      }
      return `${value.street}
              ${value.city}, ${value.county || ''} ${value.postCode}`;
    };

    if (submitSuccessful) {
      this.state.inEditMode = false;
      setStatus({ submitSuccessful: false });
    }

    const addressContent = () => {
      if (type === PREVIOUS_ADDRESSES) {
        const filteredPreviousAddresses = previousAddresses.filter((pv) => pv.street && pv.city && pv.postCode);
        if (filteredPreviousAddresses.length === 0) {
          return <p className={styles.content}>{'No Previous Address Found.'}</p>;
        }
        return (
          <div>
            <p className={styles.content}>
              {filteredPreviousAddresses.map((pv, divider) => {
                const isLast = filteredPreviousAddresses.length - 1 === divider;
                return (
                  <p key={pv._id} className={styles.multipleInput}>
                    {`${formatAddressAndPreviousAddressDates(pv)}
                      `}
                    {!isLast && <hr className={styles.hr} />}
                  </p>
                );
              })}
              <br />
              <ServiceAlertCard
                alertDescription={(
                  <span>
                    {'If you want to update or remove a previous address, please '}
                    <ButtonLink
                      variant="primary"
                      font="inherit"
                      underline
                      label="message us"
                      link="/inbox/new-message"
                      target="_blank"
                    />
                    {'.'}
                  </span>
                )}
                alertSeverity="warning"
                icon="warningCircle"
                detached
              />
            </p>
          </div>
        );
      }
      if (type === CURRENT_ADDRESS && addressEmpty) {
        return <p className={styles.content}>{'No Address Found.'}</p>;
      }

      const currentAddress = () => {
        return `${values.address.street || (joinAddress([values.address.line1, values.address.line2, values.address.line3, values.address.line4]))}
            ${joinAddress([values.address.locality, values.address.city, values.address.county, formatPostcode(uppercase(removeWhitespace(values.postcode)))])}`;
      };

      return (
        <p className={styles.linebreaks}>
          {currentAddress()}
        </p>
      );
    };

    const dispatchAddButton = () => {
      if (type === PREVIOUS_ADDRESSES) {
        return dispatchPreviousAddressesAddButtonClicked();
      }
      return dispatchUpdateAddressAddButtonClicked();
    };

    const dispatchCancelButton = () => {
      if (type === PREVIOUS_ADDRESSES) {
        return dispatchPreviousAddressesCancelButtonClicked();
      }
      return dispatchUpdateAddressCancelButtonClicked();
    };

    const addBtnLabel = () => {
      if (type === PREVIOUS_ADDRESSES) {
        return 'Add';
      }
      return addressEmpty ? 'Add' : 'Update';
    };
    return (
      <div className={styles.container}>
        <form
          className={styles.form}
          onSubmit={handleSubmit}
        >
          <h1 className={styles.heading}>
            {title}
          </h1>
          {addressContent()}
          {inEditMode && componentIsEditing && (
            Object.keys(fieldsConfig).map((field) => {
              return !fieldsConfig[field].hidden
                && (
                  <div className="field" key={fieldsConfig[field].name}>
                    {renderField(fieldsConfig[field])}
                  </div>
                );
            })
          )}
          <div className={styles.errorMessage}>{errors && this.constructErrorMessage(errors.submitError)}</div>
          <div className={styles.actionRow}>
            {showUpdate && (
              <Button
                size="small"
                label={addBtnLabel()}
                onClick={() => {
                  this.setLocalInEditMode(true);
                  setComponentIsEditing(true);
                  dispatchAddButton();
                }}
              />
            )}
            {showSave && (
              <Button type="submit" label="Save" mediumFixedWidth disabled={disableSaveButton()} loading={isSubmitting} />
            )}
            {showCancel && (
              <Button
                label="Cancel"
                size="small"
                disabled={isSubmitting}
                onClick={() => {
                  this.setLocalInEditMode(false);
                  setComponentIsEditing(false);
                  handleReset();
                  dispatchCancelButton();
                }}
              />
            )}
          </div>
        </form>
      </div>
    );
  }
}

/**
 * withFormik is being used to map props to
 * form values which will auto populate the fields.
 */
const FormikEnhancer = withFormik({
  mapPropsToValues: (props) => {
    const { user, type } = props;
    let address = get(
      user, 'contact.address',
      {
        street: '',
        locality: '',
        city: '',
        county: '',
        postcode: '',
      },
    );
    address = { ...address, line1: address.street };
    return {
      postcode: type === CURRENT_ADDRESS ? formatPostcode(removeWhitespace(
        get(user, 'contact.address.postalCode'),
      )) : '',
      address: type === CURRENT_ADDRESS ? address : '',
      previousAddresses: get(user, 'personal.previousAddresses', []),
    };
  },
  validate: (formValues) => {
    const valuesToValidate = {};

    // Create a new object which matches the forms object but strings are set to null.
    Object.keys(formValues).forEach((value) => {
      valuesToValidate[value] = formValues[value] || null;
    });

    // Validate the current values from the form object against the constraints
    const validationResult = validate(
      pick(valuesToValidate, Object.keys(addressConstraints)),
      addressConstraints,
    );

    if (!validationResult) {
      return {};
    }

    return convertFlattenedObjectToObject(validationResult);
  },
  handleSubmit: async (values, {
    setSubmitting, props, setStatus, resetForm, setFieldValue,
  }) => {
    const {
      dispatchUpdatePreviousAddresses,
      dispatchUpdateDetails,
      setComponentIsEditing,
      type,
      user,
    } = props;
    setSubmitting(true);
    const {
      address, postcode,
    } = values;
    const previousAddresses = get(user, 'personal.previousAddresses', []).map(
      ({
        _id, street, city, county, postCode, livedAtAddressDateFrom, livedAtAddressDateTo, addedDate,
      }) => ({
        _id,
        street,
        city,
        county: county || '',
        postCode,
        livedAtAddressDateFrom,
        livedAtAddressDateTo,
        addedDate,
      }),
    );
    const currentAddress = get(user, 'contact.address', null);
    const formattedPostCode = formatPostcode(uppercase(removeWhitespace(postcode)));

    const formattedStreet = joinAddress([address.line1, address.line2, address.line3, address.line4]);
    const data = {
      street: formattedStreet,
      county: address.county || '',
      postCode: formattedPostCode,
      city: address.city,
      locality: address.locality || '',
    };
    let newValues = values;

    if (type === CURRENT_ADDRESS) {
      let newData = data;
      if (currentAddress && !isEmpty(currentAddress)) {
        const { postalCode, locality, ...newCurrentAddress } = currentAddress;
        newCurrentAddress.postCode = postalCode;
        if (!isEqual(newCurrentAddress, {
          street: formattedStreet,
          county: address.county || '',
          postCode: formattedPostCode,
          city: address.city,
        }) && newCurrentAddress.street && newCurrentAddress.city && newCurrentAddress.postCode) {
          previousAddresses.push({
            ...newCurrentAddress,
            livedAtAddressDateFrom: null,
            livedAtAddressDateTo: null,
            addedDate: new Date(),
          });
          newData = { ...data, previousAddresses };
        }
      }
      await dispatchUpdateDetails(newData);
    }
    if (type === PREVIOUS_ADDRESSES) {
      previousAddresses.push({
        ...data,
        livedAtAddressDateFrom: values.livedAtAddressDateFrom || null,
        livedAtAddressDateTo: values.livedAtAddressDateTo || null,
        addedDate: new Date(),
      });

      newValues = {
        ...values,
        previousAddresses,
      };
      await dispatchUpdatePreviousAddresses({ previousAddresses });
    }

    setSubmitting(false);
    setComponentIsEditing(false);
    resetForm(newValues);
    setFieldValue('noPreviousAddressDates', false);
    setFieldValue('livedAtAddressDateFrom', '');
    setFieldValue('livedAtAddressDateTo', '');
    return setStatus({ submitSuccessful: true });
  },
})(AddressContainer);

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
  user: getUser(state),
  isVerified: getIsVerified(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdatePreviousAddresses: (data) => dispatch(updatePreviousAddresses(data)),
  dispatchUpdateDetails: (data) => dispatch(updateAddressDetails(data)),
  dispatchUpdateAddressAddButtonClicked: () => dispatch(updateAddressAddButtonClicked()),
  dispatchUpdateAddressCancelButtonClicked: () => dispatch(updateAddressCancelButtonClicked()),
  dispatchPreviousAddressesAddButtonClicked: () => dispatch(previousAddressesAddButtonClicked()),
  dispatchPreviousAddressesCancelButtonClicked: () => dispatch(previousAddressesCancelButtonClicked()),
});

export default compose(
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps),
)(FormikEnhancer);
