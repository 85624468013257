/* eslint-disable import/no-cycle */

import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import moment from 'moment';
import {
  getFiles,
  getUser,
} from './index';

import {
  getTargetInvestmentPlatformName,
} from './targetInvestment';

import {
  getHasInstructionBeenSeenNotifications,
  getHasInstructionNotificationTimedOut,
} from './notificationTracking';

import { getPensions } from './pensions';

import { withdrawalCalendlyUrl } from '../../config';

import { WITHDRAWAL_LUMP_SUM_COOL_OFF_PERIOD } from '../../util/constants';

export const getInvestmentAdviceOnboarding = (state) => {
  return get(state, 'firestore.ordered.investmentAdviceOnboarding[0]');
};

export const getHasOnboardingSyncedBack = (state) => {
  return Boolean(getInvestmentAdviceOnboarding(state).reference);
};

export const getSerpsTrace = (state) => {
  return get(getInvestmentAdviceOnboarding(state), 'serpsTrace') || false;
};

export const getEligibleForSerpsTrace = (state) => {
  return get(getInvestmentAdviceOnboarding(state), 'eligibleForSerpsTrace') || false;
};

export const isAdviceGenerated = (state) => {
  return Boolean(getTargetInvestmentPlatformName(state));
};

export const getPensionExpert = (state) => {
  return get(getInvestmentAdviceOnboarding(state), 'onboardingPensionExpert');
};

export const getCalendlyUrl = (state) => {
  const pensionExpert = getPensionExpert(state);
  return withdrawalCalendlyUrl(pensionExpert);
};

export const getAdviceGeneratedDate = (state) => {
  return get(getInvestmentAdviceOnboarding(state), 'adviceGeneratedDate');
};

export const getContributionInstructions = (state) => {
  const onboarding = getInvestmentAdviceOnboarding(state);
  const instructions = get(onboarding, 'ongoingService.instructions') || [];
  return instructions.filter((i) => (
    ['new-customer-contribution-regular', 'contribution-regular'].includes(i.type)
  ));
};

export const getLatestCompleteContributionInstruction = (state) => {
  const contributionInstructions = getContributionInstructions(state) || [];
  const completeInstructions = contributionInstructions.filter((i) => i.status === 'completed');
  const sortedList = sortBy(completeInstructions, ({ completedDate }) => completedDate);
  return sortedList[sortedList.length - 1];
};

export const getLumpSumInstructions = (state) => {
  const onboarding = getInvestmentAdviceOnboarding(state);
  const instructions = get(onboarding, 'ongoingService.instructions') || [];
  return instructions.filter((i) => (
    ['new-customer-contribution-lump-sum', 'contribution-lump-sum'].includes(i.type)
  ));
};

export const getInProgressContributionInstructions = (state) => {
  const contributionInstructions = getContributionInstructions(state);
  return contributionInstructions.filter((i) => (
    ['new', 'in-progress'].includes(i.status)
  ));
};

export const getInProgressLumpSumInstructions = (state) => {
  const contributionInstructions = getLumpSumInstructions(state);
  return contributionInstructions.filter((i) => (
    ['new', 'in-progress'].includes(i.status)
  ));
};

export const getHasInProgressLumpSumInstructions = (state) => {
  const contributions = getInProgressLumpSumInstructions(state);
  const hasInProgressInstructions = Boolean(contributions.length);
  return hasInProgressInstructions;
};

export const getHasInProgressContributionInstructions = (state) => {
  const contributions = getInProgressContributionInstructions(state);
  const hasInProgressInstructions = Boolean(contributions.length);
  return hasInProgressInstructions;
};

export const getCompleteAndUnseenContributionInstructions = (state) => {
  const contributionInstructions = getContributionInstructions(state);
  return contributionInstructions.filter((i) => (
    i.status === 'completed'
    && !getHasInstructionBeenSeenNotifications(state, i._id)
    && !getHasInstructionNotificationTimedOut(state, i._id)
  ));
};

export const getWithdrawalInstructions = (state) => {
  const onboarding = getInvestmentAdviceOnboarding(state);
  const instructions = get(onboarding, 'ongoingService.instructions') || [];
  return instructions.filter((i) => (
    ['customer-withdrawal-lump-sum', 'customer-withdrawal-monthly'].includes(i.type)
  ));
};

export const getCompleteAndUnseenWithdrawalInstructions = (state) => {
  const withdrawalInstructions = getWithdrawalInstructions(state);
  return withdrawalInstructions.filter((i) => (
    i.status === 'completed'
    && !getHasInstructionBeenSeenNotifications(state, i._id)
    && !getHasInstructionNotificationTimedOut(state, i._id)
  ));
};

export const getInProgressWithdrawalInstructions = (state) => {
  const withdrawalInstructions = getWithdrawalInstructions(state);
  return withdrawalInstructions.filter((i) => (
    ['new', 'in-progress'].includes(i.status)
  ));
};

export const getWithdrawalLumpSumInstructions = (state) => {
  const onboarding = getInvestmentAdviceOnboarding(state);
  const instructions = get(onboarding, 'ongoingService.instructions') || [];
  const lumpSumWithdrawalTypes = [
    'withdrawal-tfc',
    'withdrawal-full-encashment',
    'customer-withdrawal-lump-sum',
    'withdrawal-other',
  ];
  return instructions.filter((i) => (
    lumpSumWithdrawalTypes.includes(i.type)
  ));
};

export const getInProgressWithdrawalLumpSumInstructions = (state) => {
  const withdrawalLumpSumInstructions = getWithdrawalLumpSumInstructions(state);
  return withdrawalLumpSumInstructions.filter((i) => (
    ['new', 'in-progress'].includes(i.status)
  ));
};

export const getIsWithdrawalLumpSumWithinCoolOffPeriod = (state) => {
  const withdrawalLumpSumInstructions = getWithdrawalLumpSumInstructions(state);
  return withdrawalLumpSumInstructions.some((i) => (
    i.status === 'completed'
    && moment().utc().startOf('day') - moment(i.completedDate).utc().startOf('day') < WITHDRAWAL_LUMP_SUM_COOL_OFF_PERIOD * 86400000
  ));
};

export const getIsWithdrawalRequestInProgress = (state) => get(state, 'withdrawal.withdrawalRequestInProgress', false);

export const getIsAppointmentPending = (state) => get(state, 'withdrawal.appointmentPending', false);

export const getForceMentionMeModal = (state) => get(state, 'investmentAdvice.forceMentionMeModal');

export const getAuthoriseImplementPensionPlan = (state) => get(getInvestmentAdviceOnboarding(state), 'authoriseImplementPensionPlan');

export const getInvestmentAdviceLetter = (state) => {
  const files = getFiles(state);
  const user = getUser(state);
  const investmentAdviceOnboarding = getInvestmentAdviceOnboarding(state);
  const userId = get(user, 'id');
  const investmentAdviceOnboardingId = get(investmentAdviceOnboarding, 'id');
  const matchedFileDate = [];
  let newestFile;

  if (!userId || !investmentAdviceOnboardingId || files.length < 1) {
    return null;
  }

  const regex = /suitability-letter-([0-9-]+).pdf/g;
  const adviceGeneratedDate = moment(investmentAdviceOnboarding.adviceGeneratedDate).utc();
  const matchesAdviceGeneratedDate = (dateFromFilename) => {
    if (dateFromFilename.length <= 10) {
      // looks like a day which was the original implemenation
      // check day is same day as adviceGeneratedDate
      return dateFromFilename.includes(adviceGeneratedDate.format('YYYY-MM-DD'));
    }
    // looks like a datetime which is the newer more precise implementation
    // check datetime is the same as advice generated date
    return dateFromFilename.includes(adviceGeneratedDate.format('YYYY-MM-DD-hh-mm-ss'));
  };
  files.forEach((file, index) => {
    const found = file.name.split(regex);
    if (found[1]) {
      if (matchesAdviceGeneratedDate(found[1])) {
        matchedFileDate.push({ date: found[1], index });
      }
    }
  });

  matchedFileDate.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  if (matchedFileDate[0]) {
    newestFile = files[matchedFileDate[0].index];
  }

  return newestFile;
};

export const getValidInvestmentAdviceLetter = (state) => {
  const file = getInvestmentAdviceLetter(state);
  const fileName = get(file, 'name', '');
  if (!fileName) return undefined;
  return file;
};

export const getHasInProgressOneOffContributionInstructionId = (state) => {
  const onboarding = getInvestmentAdviceOnboarding(state);
  const instructions = get(onboarding, 'ongoingService.instructions') || [];

  const foundInstructions = instructions.find((i) => {
    const isContribution = ['new-customer-contribution-lump-sum',
      'contribution-lump-sum'].includes(i.type);

    const isInProgress = [
      'new', 'in-progress',
    ].includes(i.status);

    return isContribution && isInProgress;
  });

  if (foundInstructions) {
    return foundInstructions._id;
  }

  return null;
};

export const getHasInProgressContributionInstruction = (state) => {
  const onboarding = getInvestmentAdviceOnboarding(state);
  const instructions = get(onboarding, 'ongoingService.instructions') || [];

  return instructions.some((i) => {
    const isContribution = ['new-customer-contribution-regular',
      'contribution-regular'].includes(i.type);

    const isInProgress = [
      'new', 'in-progress',
    ].includes(i.status);

    return isContribution && isInProgress;
  });
};

export const getHasInProgressPensions = (state) => {
  const onboarding = getInvestmentAdviceOnboarding(state);
  return onboarding.hasInProgressPensions;
};

export const getHasOnlyInProgressContributionInstruction = (state) => (
  !getHasInProgressPensions(state) && getHasInProgressContributionInstruction(state)
);

export const getActivityPlanInstructions = (state) => {
  const onboarding = getInvestmentAdviceOnboarding(state);
  const instructions = get(onboarding, 'ongoingService.instructions') || [];

  return instructions.filter((i) => {
    const types = [
      'new-customer-contribution-regular',
      'contribution-regular',
      'contribution-amend-regular',
      'contribution-lump-sum',
      'new-customer-contribution-lump-sum',
    ];
    const isContribution = types.includes(i.type);
    return isContribution && i.status !== 'closed';
  });
};

export const getHasPensionsOrInProgressInstructions = (state) => {
  const pensions = getPensions(state);
  const hasPensions = Boolean(pensions && pensions.length);
  const hasInProgressInstructions = getHasInProgressContributionInstruction(state);
  const hasInProgressLumpSumInstructions = getHasInProgressLumpSumInstructions(state);
  return hasPensions || hasInProgressInstructions || hasInProgressLumpSumInstructions;
};

export const getCanRequestOnlineWithdrawal = (state) => {
  const onboarding = getInvestmentAdviceOnboarding(state);
  return get(onboarding, 'ongoingService.canRequestOnlineWithdrawal', false);
};
