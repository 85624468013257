import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import sentryException from '../../util/sentryException';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import { NO_PENSION_EXPERT_MESSAGE, NO_PENSION_EXPERT_TIMEOUT } from '../../util/constants';
import { messageMeClickedAction } from '../../redux/modules/conversations';
import styles from './ContactUsContent.css';
import { getPensionExpert } from '../../redux/selectors/investmentAdvice';

function ContactUsContent({
  pensionAdviser,
  heading,
  message,
  customMessage,
  customerFirstName,
  dispatchMessageMeClicked,
  prefillSubject,
  showButton,
  removeHeadingRow,
  headingRowStyles,
  noExpertContentWrapper,
  onClick,
}) {
  const adviser = {
    firstName: get(pensionAdviser, 'firstName'),
    imageUrl: get(pensionAdviser, 'imageUrl'),
    email: get(pensionAdviser, 'email'),
  };

  const allExpertFieldsPresent = Object.values(adviser).every(Boolean);

  const [isLoading, setIsLoading] = useState(true);
  const [hasAdvisor, setHasAdvisor] = useState(false);

  const handleOnClick = () => {
    if (onClick && typeof onClick === 'function') {
      onClick();
      return;
    }
    dispatchMessageMeClicked();
  };

  useEffect(() => {
    if (allExpertFieldsPresent) {
      setIsLoading(false);
      setHasAdvisor(true);
    } else {
      const timeout = setTimeout(() => {
        if (allExpertFieldsPresent) {
          setHasAdvisor(true);
        } else {
          sentryException(new Error('pension-expert-fields-missing'), {
            section: 'onboarding-dashboard',
          });
        }
        setIsLoading(false);
      }, NO_PENSION_EXPERT_TIMEOUT);
      return () => clearTimeout(timeout);
    }
  }, [allExpertFieldsPresent]);

  if (isLoading) {
    return (
      <Loader />
    );
  }

  if (hasAdvisor) {
    return (
      <React.Fragment>
        {!removeHeadingRow && (
        <div style={headingRowStyles} className={styles.headingRow}>
          <img className={styles.expertImage} src={adviser.imageUrl} alt="Pension adviser" />
          <h3 className={styles.headingSmall}>{heading}</h3>
        </div>
        )}
        { message && <p className={styles.expertMessage}>{message}</p>}
        {customMessage && (customMessage)}
        {showButton && (
          <div className={styles.contactRow}>
            <Button
              size="large"
              label={'Message me'}
              to="/inbox/new-message"
              search={prefillSubject ? `?subject=${prefillSubject}` : ''}
              wrapperStyles={{ marginBottom: 0 }}
              onClick={handleOnClick}
            />
          </div>
        )}
      </React.Fragment>
    );
  }

  const noExpertContent = (
    <React.Fragment>
      <div className={styles.headingRow}>
        <Loader
          spinnerStyles={{ height: '40px', width: '40px' }}
          wrapperStyles={{ height: '100%', width: 'auto', marginRight: '15px' }}
        />
        <h3 className={styles.headingSmall}>{customerFirstName ? `Hi ${customerFirstName}!` : 'Hi there!'}</h3>
      </div>
      <p className={styles.noExpertMessage}>{NO_PENSION_EXPERT_MESSAGE}</p>
    </React.Fragment>
  );

  const NoExpertContentWrapper = noExpertContentWrapper;

  return (
    <NoExpertContentWrapper>
      {noExpertContent}
    </NoExpertContentWrapper>
  );
}

ContactUsContent.defaultProps = {
  showButton: true,
  customMessage: null,
  noExpertContentWrapper: ({ children }) => <React.Fragment>{children}</React.Fragment>,
  onClick: null,
};

const mapStateToProps = (state) => ({
  pensionAdviser: getPensionExpert(state),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatchMessageMeClicked: () => dispatch(messageMeClickedAction(ownProps.source)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsContent);
