exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #FFFFFF */ /* #FAFAFA */ /* #2E2D2C */ /* #C3C3C3 */ /* #E5E5E5 */ /* #E9E9E9 */ /* #848484 */ /* #169B62 */ /* #169B62 */ /* #92DE63, lime */ /* #92DE63, lime */ /* #125A42 */ /* #21233C */ /* #EEFFEE */ /* #c0eec0 */ /* #B191D2 */ /* #FFAD55 */ /* #2E5896; */ /* #FEF2EC; */ /* #128754 */ /* #ED1639 */ /* New Token colours */ /* #F8F8F8 */ /* #5B3552 */ /* #320327 */ /* LINKS #5B3552 */ /* #CECCCE */ /* #FF2E5B */ /* #D93657 */ /* #5273A6 */ /* #320327 */ /* #AD9AA9 */ /* #F8F8F8 */ /* USED FOR PAGE HEADING OR PARAGRAPH TEXT #262325 */ /* USED FOR HEADINGS #262325 */ /* #FFFFFF */ /* #747272 */", ""]);

// exports
exports.locals = {
	"white": "rgba(255, 255, 255, 1)",
	"offWhite": "rgba(250, 250, 250, 1)",
	"lightestGrey": "rgba(239, 239, 239, 1)",
	"lightGrey": "rgb(211, 211, 211)",
	"grey": "rgba(151, 151, 151, 1)",
	"darkGrey": "rgba(74, 74, 74, 1)",
	"tarmac": "rgb(46, 45, 44)",
	"fog": "#C3C3C3",
	"thickFog": "#848484",
	"inactiveGrey": "rgba(195, 195, 195, 1)",
	"borderGrey": "rgba(229, 229, 229, 1)",
	"backgroundGrey": "rgba(244, 243, 243, 1)",
	"chalk": "rgba(233, 233, 233, 1)",
	"subtleGrey": "rgba(132, 132, 132)",
	"black": "rgba(0, 0, 0, 1)",
	"primaryGreen": "rgba(22, 158, 98, 1)",
	"grass": "rgba(22, 158, 98, 1)",
	"secondaryGreen": "rgba(146, 222, 99, 1)",
	"lime": "rgba(146, 222, 99, 1)",
	"darkGreen": "rgba(10, 93, 57, 1)",
	"forest": "rgba(18, 90, 66, 1)",
	"blue": "rgba(73, 130, 207, 1)",
	"midnight": "rgba(33, 35, 60, 1)",
	"turquoise": "rgba(93, 219, 211, 1)",
	"orange": "rgba(255, 193, 101, 1)",
	"yellow": "rgba(255, 225, 94, 1)",
	"red": "rgba(241, 50, 30, 1)",
	"cream": "rgba(249, 245, 225, 1)",
	"mint": "rgb(238, 255, 238)",
	"lightMint": "rgb(192,238,192)",
	"lavender": "rgb(177, 145, 210)",
	"satsuma": "rgba(255, 173, 85, 1)",
	"cobalt": "rgba(46, 88, 150, 1)",
	"palePiggy": "rgb(254, 242, 236)",
	"accessiblePrimaryGreen": "rgba(18, 135, 84, 1)",
	"accessibleRed": "rgba(237, 22, 57, 1)",
	"warning": "rgba(255, 193, 101, 1)",
	"danger": "rgba(241, 50, 30, 1)",
	"accentBasePrimaryLight": "rgba(248, 248, 248, 1)",
	"accentBasePrimaryRegular": "rgba(91, 53, 82, 1)",
	"accentBasePrimaryStrong": "rgba(50, 3, 39, 1)",
	"accentBaseSecondaryRegular": "rgba(91, 53, 82, 1)",
	"accentTokenPrimaryLight": "rgba(206, 204, 206, 1)",
	"accentTokenPrimaryRegular": "rgba(255, 46, 91, 1)",
	"accentTokenPrimaryStrong": "rgba(217, 54, 87, 1)",
	"accentTokenSecondaryRegular": "rgba(82, 115, 166, 1)",
	"accentTokenSecondaryStrong": "rgba(50, 3, 39, 1)",
	"interfaceFeedbackTipRegular": "rgba(173, 154, 169, 1.0)",
	"lightInterfaceNeutralLight": "rgba(231, 231, 231, 1)",
	"lightSpaceSecondaryRegular": "#FFFFFF",
	"lightSpaceTertiaryRegular": "#EFEBEE",
	"paleSlate": "#E3DDE2",
	"spacePrimaryRegular": "rgba(248, 248, 248, 1)",
	"textParagraphRegular": "rgba(38, 35, 37, 1)",
	"textTitleRegular": "rgba(38, 35, 37, 1)",
	"textTitleReversed": "rgba(255, 255, 255, 1)",
	"interfaceNeutralStrong": "rgba(116, 114, 114, 1)"
};