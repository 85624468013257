import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';
import get from 'lodash/get';
import classnames from 'classnames';
import styles from './AddPensionsPage.css';
import {
  getProviderNames,
  getProviderStatuses,
} from '../../redux/selectors';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import { transferOptions } from '../../util/constants';
import {
  returnLinkClickedAction,
  messageMeClickedAction,
  unknownProviderLinkClickedAction,
  otherProviderStepLandedAction,
  otherProviderInputChangedAction,
  otherProviderSubmitClickedAction,
  tryFindAndCombineServiceClickedAction,
} from '../../redux/modules/pensions';

const OtherPensionProviderStep = ({
  setFormData,
  setStep,
  amendMode,
  step,
  totalSteps,
  setEmployerFlow,
  providers,
  providerStatuses,
  setUnmatchedFlow,
  dispatchReturnLinkClicked,
  dispatchMessageMeClicked,
  isCustomerOngoing,
  dispatchOtherProviderStepLanded,
  dispatchOtherProviderInputChanged: _dispatchOtherProviderInputChanged,
  dispatchOtherProviderSubmitClicked,
  dispatchUnknownProviderLinkClicked,
  dispatchTryFindAndCombineServiceClicked,
  formData,
  resetFormData,
}) => {
  const [otherProvider, setOtherProvider] = useState(formData.commonName || '');
  const [dispatchOtherProviderInputChanged] = useState(
    () => once(_dispatchOtherProviderInputChanged),
  );
  const [error, setError] = useState(undefined);
  const isTransferBasic = formData.transferOption === transferOptions.TRANSFER_BASIC;
  const isTransferWithCheck = formData.transferOption === transferOptions.TRANSFER_WITH_CHECK;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchOtherProviderStepLanded(isCustomerOngoing, formData.transferOption);
  }, []);

  const PROVIDER_MAX_LEN = 60;

  const isProviderOutOfScope = (provider) => providerStatuses[provider.replace(/\s+/g, '')] === 2;

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.otherProviderWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
          <ButtonLink
            onClick={(event) => {
              dispatchReturnLinkClicked(isCustomerOngoing, formData.transferOption);
              setStep((_step) => _step - 1);
              event.stopPropagation();
            }}
            label="← Back"
          />
        )}
        <h3 className={styles.otherProviderHeading}>
          {'Other pension provider*'}
        </h3>
        <input
          className={styles.otherProviderInput}
          onChange={(e) => {
            dispatchOtherProviderInputChanged(isCustomerOngoing, formData.transferOption);
            setError(undefined);
            const otherProviderInput = get(e, 'target.value') || '';
            const trimmedInput = otherProviderInput.slice(0, PROVIDER_MAX_LEN);
            setOtherProvider(trimmedInput);
          }}
          value={otherProvider}
          placeholder="Enter provider name"
        />
        { error && <p className={styles.error}>{error}</p>}
        <div
          className={styles.submitContainer}
        >
          <div
            className={styles.providerSubmitButtonWrapper}
          >
            <Button
              size="large"
              disabled={!otherProvider}
              label={amendMode ? 'Confirm' : 'Submit'}
              onClick={() => {
                dispatchOtherProviderSubmitClicked(isCustomerOngoing, formData.transferOption);
                const matchedProvider = providers.find(
                  (provider) => provider.toLowerCase() === otherProvider.toLowerCase(),
                );

                const outOfScope = isProviderOutOfScope(matchedProvider || '');

                if (matchedProvider && outOfScope) {
                  setError('Sorry, we are unable to transfer pensions from the selected provider. This may be because they offer defined or safeguarded benefits.');
                  return;
                }

                if (matchedProvider) {
                  setFormData((_formData) => ({
                    ..._formData,
                    commonName: matchedProvider,
                    freeTextProvider: false,
                    isOldEmployerPension: undefined,
                    employerLocation: undefined,
                    employerIndustry: undefined,
                    employmentStartDate: '',
                    employmentEndDate: '',
                  }));
                  setStep(step + 1);
                  return;
                }

                setFormData((_formData) => ({
                  ..._formData,
                  commonName: otherProvider,
                  freeTextProvider: true,
                }));
                if (isTransferWithCheck) {
                  setUnmatchedFlow();
                }
                setStep(step + 1);
              }}
            />
          </div>
          { isTransferBasic && (
          <ButtonLink
            label={(
              <div>
                <span className={classnames(styles.employerLinkText, styles.inlineBlock)}>{'Don’t have this information?'}</span>
                <span className={classnames(styles.employerLinkText, styles.inlineBlock)}>{'Try our “find, check and transfer” service'}</span>
              </div>
            )}
            onClick={() => {
              dispatchTryFindAndCombineServiceClicked(isCustomerOngoing, formData.transferOption);
              resetFormData();
              setStep(0);
            }}
          />
          )}
          { isTransferWithCheck && (
          <ButtonLink
            variant="primary"
            label={<span className={styles.employerLinkText}>{'I don’t know my pension provider'}</span>}
            onClick={() => {
              dispatchUnknownProviderLinkClicked(isCustomerOngoing, formData.transferOption);
              setEmployerFlow();
            }}
          />
          )}
        </div>
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'OtherPensionProviderStep'}
            noExpertContentWrapper={({ children }) => (
              <div
                style={{
                  display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                }}
              >
                {children}
              </div>
            )}
            customMessage={(
              <div
                className={styles.contactUsInnerContent}
              >
                {'This information can be found on your previous statement. If you have any questions, please feel free to '}
                <ButtonLink
                  label="message me"
                  variant="primary"
                  to="/inbox/new-message"
                  font="inherit"
                  onClick={() => dispatchMessageMeClicked(
                    isCustomerOngoing,
                    formData.transferOption,
                  )}
                />
                {'.'}
              </div>
                )}
            showButton={false}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  providers: getProviderNames(state),
  providerStatuses: getProviderStatuses(state),
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  dispatchReturnLinkClicked: returnLinkClickedAction,
  dispatchMessageMeClicked: messageMeClickedAction,
  dispatchUnknownProviderLinkClicked: unknownProviderLinkClickedAction,
  dispatchOtherProviderStepLanded: otherProviderStepLandedAction,
  dispatchOtherProviderInputChanged: otherProviderInputChangedAction,
  dispatchOtherProviderSubmitClicked: otherProviderSubmitClickedAction,
  dispatchTryFindAndCombineServiceClicked: tryFindAndCombineServiceClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherPensionProviderStep);
