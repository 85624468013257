import isNil from 'lodash/isNil';
import get from 'lodash/get';

export const INITIAL_STEP = 'INITIAL_STEP';
export const SUGGESTION_STEP = 'SUGGESTION_STEP';
export const CREATE_SCENARIO_STEP = 'CREATE_SCENARIO_STEP';
export const RESULTS_STEP = 'RESULTS_STEP';
export const CONTRIBUTION_SUGGESTION_THRESHOLD = 200;

export const FIRST_TIME_USER_FLOW = [
  INITIAL_STEP,
  SUGGESTION_STEP,
  CREATE_SCENARIO_STEP,
  RESULTS_STEP,
];

export const RETURNING_USER_FLOW = [
  SUGGESTION_STEP,
  CREATE_SCENARIO_STEP,
  RESULTS_STEP,
];

export const FIRST_TIME_HIGH_VALUE_CONTRIBUTION_FLOW = [
  INITIAL_STEP,
  CREATE_SCENARIO_STEP,
  RESULTS_STEP,
];

export const RETURNING_HIGH_VALUE_CONTRIBUTION_FLOW = [
  CREATE_SCENARIO_STEP,
  RESULTS_STEP,
];

export const getIsHighValueContributionCustomer = (currentContribution) => {
  return currentContribution > CONTRIBUTION_SUGGESTION_THRESHOLD;
};

export const getHasCompletedFlow = (latestProjection) => {
  return !isNil(get(latestProjection, 'scenarios'));
};

export const determineFlow = ({ currentContribution, latestProjection }) => {
  const hasNeverCompletedFlow = !getHasCompletedFlow(latestProjection);

  if (hasNeverCompletedFlow) {
    if (getIsHighValueContributionCustomer(currentContribution)) {
      return FIRST_TIME_HIGH_VALUE_CONTRIBUTION_FLOW;
    }
    return FIRST_TIME_USER_FLOW;
  }

  if (getIsHighValueContributionCustomer(currentContribution)) {
    return RETURNING_HIGH_VALUE_CONTRIBUTION_FLOW;
  }
  return RETURNING_USER_FLOW;
};
