/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import get from 'lodash/get';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import SerpsAuthorisePensionsWithLetterPage from './SerpsAuthorisePensions';
import ThankYou from './ThankYou';
import { getIsSerpsAuthAllowed } from '../../redux/selectors/journeys';
import { AUTHORISATION_PENSIONS_STEP, THANK_YOU_STEP } from './SerpsAuthorisePensionsFlow';

const SerpsAuthorisePensionsSteps = ({
  isSerpsAuthAllowed,
}) => {
  const journey = [AUTHORISATION_PENSIONS_STEP, THANK_YOU_STEP];

  const [step, setStepValue] = useState(0);

  const setStep = (newStepArg) => {
    const newStepValue = typeof newStepArg === 'function' ? newStepArg(step) : newStepArg;
    setStepValue(newStepValue);
  };

  const stepsMap = {
    [AUTHORISATION_PENSIONS_STEP]: (
      <SerpsAuthorisePensionsWithLetterPage
        setStep={setStep}
        step={step}
      />
    ),
    [THANK_YOU_STEP]: (
      <ThankYou
        onBack={() => {
          setStep(0);
        }}
      />
    ),
  };

  const stepComponent = get(stepsMap, journey[step]);

  if (journey[step] === THANK_YOU_STEP) {
    return stepsMap[journey[step]];
  }

  if (!stepComponent || !isSerpsAuthAllowed) {
    return <Redirect to="/" />;
  }

  return (
    stepsMap[journey[step]]
  );
};

const mapStateToProps = (state) => ({
  isSerpsAuthAllowed: getIsSerpsAuthAllowed(state),
});
export default connect(mapStateToProps)(withRouter(SerpsAuthorisePensionsSteps));
