/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import pick from 'lodash/pick';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import sentryException from '../../util/sentryException';
import styles from './SetupContributionPage.css';
import Button from '../../components/Button/Button';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import { getAuthoriseImplementPensionPlan } from '../../redux/selectors/investmentAdvice';
import CheckBoxGreen from '../../components/CheckBoxGreen/CheckBoxGreen';

import ButtonLink from '../../components/ButtonLink/ButtonLink';
import { currencyString } from '../../util/currency';
import { PENSION_TRANSFER_KEY_FILES } from '../../util/constants';
import DirectDebit from '../../assets/images/direct-debit.svg';
import ContributionsDirectDebitImage from '../../assets/images/contributions/contributions-direct-debit.svg';
import ArrowDown from '../../assets/images/rebranding/triangle-downward.svg';
import {
  setupContributionAction,
  contributionSubmitStepVisitedAction,
  ongoingContributionSubmitStepVisitedAction,
  submitClickedAction,
  ongoingSubmitClickedAction,
  returnLinkClickedAction,
  ongoingReturnLinkClickedAction,
  changeBankDetailsClickedAction,
  ongoingChangeBankDetailsClickedAction,
  changeContributionClickedAction,
  ongoingChangeContributionClickedAction,
  recommendationReportLinkClickedAction,
  ongoingRecommendationReportLinkClickedAction,
  keyDetailsLinkClickedAction,
  ongoingKeyDetailsLinkClickedAction,
  illustrationLinkClickedAction,
  ongoingIllustrationLinkClickedAction,
  authoriseImplementPensionPlanUnselectedAction,
  ongoingAuthoriseImplementPensionPlanUnselectedAction,
  authoriseImplementPensionPlanSelectedAction,
  termsOfBusinessLinkClickedAction,
  ongoingTermsOfBusinessLinkClickedAction,
  contributionAndReliefTermsLinkClickedAction,
  ongoingContributionAndReliefTermsLinkClickedAction,
  authoriseDirectDebitUnselectedAction,
  ongoingAuthoriseDirectDebitUnselectedAction,
  authoriseDirectDebitSelectedAction,
  ongoingAuthoriseDirectDebitSelectedAction,
  directDebitGuaranteeExpandedAction,
  ongoingDirectDebitGuaranteeExpandedAction,
  directDebitGuaranteeCollapsedAction,
  ongoingDirectDebitGuaranteeCollapsedAction,
  submitErrorAction,
  ongoingSubmitErrorAction,
  ongoingAuthoriseImplementPensionPlanSelectedAction,
} from '../../redux/modules/contributions';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import captureScreen from '../../util/captureScreenshot';
import captureHtml from '../../util/captureHtml';
import BaseGrid from '../../components/BaseGrid/BaseGrid';

import { TAX_RELIEF_URL } from '../../config';

const formFieldsToSubmit = [
  'amountPerMonth',
  'indexedWithInflation',
  'sourceOfFunds',
  'occupation',
  'salary',
  'accountNumber',
  'sortCode',
  'name',
  'authoriseImplementPensionPlan',
  'confirmTaxReliefEligibility',
  'authoriseArrangeDirectDebit',
  'ongoing',
  'type',
  'previousContributionAmount',
];

const ContributionSubmitStep = ({
  setFormData,
  formData,
  setStep,
  step,
  totalSteps,
  isCustomerOngoing,
  authoriseImplementPensionPlan,
  dispatchSetupContribution,
  setAmendMode,
  dispatchContributionSubmitStepVisited,
  dispatchSubmitClicked,
  dispatchReturnLinkClicked,
  dispatchChangeBankDetailsClicked,
  dispatchChangeContributionClicked,
  dispatchRecommendationReportLinkClicked,
  dispatchKeyDetailsLinkClicked,
  dispatchIllustrationLinkClicked,
  dispatchAuthoriseImplementPensionPlanSelected,
  dispatchAuthoriseImplementPensionPlanUnselected,
  dispatchAuthoriseDirectDebitSelected,
  dispatchAuthoriseDirectDebitUnselected,
  dispatchDirectDebitGuaranteeCollapsed,
  dispatchDirectDebitGuaranteeOpened,
  dispatchSubmitError,
  dispatchContributionAndReliefTermsLinkClicked,
  dispatchTermsOfBusinessLinkClicked,
  investmentAdviceLetterUrl,
}) => {
  const disabled = !(
    authoriseImplementPensionPlan
    || isCustomerOngoing
    || formData.authoriseImplementPensionPlan
  )
    || !formData.confirmTaxReliefEligibility
    || !formData.authoriseArrangeDirectDebit;

  const [submitError, setSubmitError] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [directDebitGuaranteeExpanded, setDirectDebitGuaranteeExpanded] = useState(false);

  useEffect(() => {
    dispatchContributionSubmitStepVisited();
    window.scrollTo(0, 0);
    setAmendMode(false);
  }, []);

  const onSubmit = debounce(async (e) => {
    try {
      e.preventDefault();
      dispatchSubmitClicked();
      setSubmitError(false);

      let screenshot;
      try {
        screenshot = await captureScreen();
      } catch (error) {
        sentryException(error, {
          section: 'setup-contributions-capture-screen',
        });
        throw error;
      }

      const pageHtml = captureHtml();
      const request = {
        ...pick(formData, formFieldsToSubmit),
        screenshot,
        pageHtml,
      };
      setSubmitInProgress(true);
      await dispatchSetupContribution(request, isCustomerOngoing);
      setSubmitInProgress(false);
      setStep(step + 1);
    } catch (error) {
      setSubmitError(true);
      setSubmitInProgress(false);
      dispatchSubmitError();
      sentryException(error, {
        section: 'setup-contributions-submit',
      });
    }
  }, 3000, { leading: true, trailing: false });
  return (
    <BaseGrid pageSectionStyles={{ marginBottom: '200px' }} addPensionsTheme>
      <div className={styles.contributionsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          onClick={() => {
            dispatchReturnLinkClicked();
            setStep(step - 1);
          }}
          label="← Back"
        />
        <div className={styles.imageContainer}>
          <img src={ContributionsDirectDebitImage} alt="watering plants" />
        </div>
        <h2 className={styles.contributionsHeading}>{'Direct Debit confirmation'}</h2>
        <p className={styles.accountDetailsBody}>
          {'Your Direct Debit will be taken from your account on the 2nd working day of the month.'}
          <br />
          <br />
          {'Please check the following details are correct before you continue.'}
        </p>
        <div className={styles.confirmation}>
          <div className={styles.confirmationHeading}>{'Name'}</div>
          <div className={styles.confirmationBody}>{formData.name}</div>
          <div className={styles.confirmationDivider} />
          <div className={styles.confirmationHeading}>{'Sort code'}</div>
          <div className={styles.confirmationBody}>{formData.sortCode}</div>
          <div className={styles.confirmationDivider} />
          <div className={styles.confirmationHeading}>{'Account number'}</div>
          <div className={styles.confirmationBody}>{formData.accountNumber}</div>
          <Button
            variant="secondary"
            size="large"
            type="button"
            onClick={() => {
              dispatchChangeBankDetailsClicked();
              setStep(3);
              setAmendMode(true);
            }}
            label="Change bank details"
          />
          <div className={styles.confirmationDivider} />
          <div className={styles.confirmationHeading}>{'Monthly contribution'}</div>
          <div
            className={styles.contribution}
          >
            {currencyString(formData.amountPerMonth, true)}
          </div>
          <Button
            variant="secondary"
            size="large"
            onClick={() => {
              dispatchChangeContributionClicked();
              setStep(0);
              setAmendMode(true);
            }}
            label="Amend contribution"
          />
        </div>
        <div className={styles.directDebitContainer}>
          <img src={DirectDebit} alt="Direct Debit logo" className={styles.directDebitLogo} />
          <h3 className={styles.directDebitHeading}>{'The Direct Debit Guarantee'}</h3>
          <button
            type="button"
            onClick={() => {
              if (directDebitGuaranteeExpanded) {
                dispatchDirectDebitGuaranteeCollapsed();
              } else {
                dispatchDirectDebitGuaranteeOpened();
              }
              setDirectDebitGuaranteeExpanded(!directDebitGuaranteeExpanded);
            }}
            className={styles.guaranteeToggleButton}
          >
            <span className={styles.guaranteeToggleText}>{directDebitGuaranteeExpanded ? 'Hide guarantee' : 'Show guarantee'}</span>
            <img src={ArrowDown} alt="Down arrow" style={{ ...(directDebitGuaranteeExpanded && { transform: 'rotate(180deg)' }) }} />
          </button>
          {directDebitGuaranteeExpanded && (
          <ul className={styles.directDebitGuarantee}>
            <li className={styles.directDebitGuaranteeItem}>{'The Guarantee is offered by all banks and building societies that accept instructions to pay Direct Debits'}</li>
            <li className={styles.directDebitGuaranteeItem}>{'If there are any changes to the amount, date or frequency of your Direct Debit the organisation will notify you (normally 10 working days) in advance of your account being debited or as otherwise agreed. If you request the organisation to collect a payment, confirmation of the amount and date will be given to you at the time of the request'}</li>
            <li className={styles.directDebitGuaranteeItem}>{'If an error is made in the payment of your Direct Debit, by the organisation or your bank or building society, you are entitled to a full and immediate refund of the amount paid from your bank or building society'}</li>
            <li className={styles.directDebitGuaranteeItemIndent}>{'If you receive a refund you are not entitled to, you must pay it back when the organisation asks you to'}</li>
            <li className={styles.directDebitGuaranteeItem}>{'You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation may be required. Please also notify the organisation'}</li>
          </ul>
          )}
        </div>
        <h3 className={styles.tickboxHeading}>{'Please read and confirm the following:'}</h3>
        {(investmentAdviceLetterUrl || isCustomerOngoing) ? (
          <div className={styles.standardContainer}>
            {!authoriseImplementPensionPlan && !isCustomerOngoing && (
            <React.Fragment>
              <CheckBoxGreen
                name="authoriseImplementPensionPlan"
                disabled={!investmentAdviceLetterUrl}
                label={(
                  <span>
                    {'I authorise Profile Pensions to implement my Pension Plan. I have read and understand the '}
                    <a
                      className={styles.documentsDownloadLink}
                      href={investmentAdviceLetterUrl}
                      onClick={dispatchRecommendationReportLinkClicked}
                      download
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {'Recommendation'}
                    </a>
                    {', '}
                    <a
                      href={PENSION_TRANSFER_KEY_FILES.keyFeatures}
                      onClick={dispatchKeyDetailsLinkClicked}
                      download
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {'Key Features'}
                    </a>
                    {' and '}
                    <a
                      href={PENSION_TRANSFER_KEY_FILES.illustration}
                      onClick={dispatchIllustrationLinkClicked}
                      download
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {'Illustration'}
                    </a>
                    {'.'}
                  </span>
                    )}
                value={formData.authoriseImplementPensionPlan}
                defaultValue={false}
                onChange={() => {
                  if (formData.authoriseImplementPensionPlan) {
                    dispatchAuthoriseImplementPensionPlanUnselected();
                  } else {
                    dispatchAuthoriseImplementPensionPlanSelected();
                  }
                  setFormData({
                    ...formData,
                    authoriseImplementPensionPlan: !formData.authoriseImplementPensionPlan,
                  });
                }}
                wrapperStyles={{ marginBottom: '13px' }}
              />
              <div className={styles.divider} />
            </React.Fragment>
            )}
            <CheckBoxGreen
              name="confirmTaxReliefEligibility"
              label={(
                <span>
                  {'I confirm that I am eligible for tax relief on my pension contributions and I have read and understand the '}
                  <a
                    href={TAX_RELIEF_URL}
                    onClick={dispatchContributionAndReliefTermsLinkClicked}
                    download
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {'Pension Contribution and Tax Relief'}
                  </a>
                  {' terms.'}
                </span>
                  )}
              value={formData.confirmTaxReliefEligibility}
              defaultValue={false}
              onChange={() => {
                setFormData({
                  ...formData,
                  confirmTaxReliefEligibility: !formData.confirmTaxReliefEligibility,
                });
              }}
              wrapperStyles={{ marginBottom: '13px' }}
            />
            <div className={styles.divider} />
            <CheckBoxGreen
              name="authoriseArrangeDirectDebit"
              label={(
                <span>
                  {'I authorise Profile Pensions to arrange for my pension platform to debit my bank account on a regular basis, in line with the instruction above. I understand that payments will continue until further notice and the decision to contribute to a pension is my own. I have received, understood and agree to the '}
                  <a
                    href={PENSION_TRANSFER_KEY_FILES.termsOfBusiness}
                    onClick={dispatchTermsOfBusinessLinkClicked}
                    download
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {'Terms of Business'}
                  </a>
                  {'.'}
                </span>
                )}
              value={formData.authoriseArrangeDirectDebit}
              defaultValue={false}
              onChange={() => {
                if (formData.authoriseArrangeDirectDebit) {
                  dispatchAuthoriseDirectDebitUnselected();
                } else {
                  dispatchAuthoriseDirectDebitSelected();
                }

                setFormData({
                  ...formData,
                  authoriseArrangeDirectDebit: !formData.authoriseArrangeDirectDebit,
                });
              }}
            />
          </div>
        ) : (
          <div className={styles.disclaimerTextContainer}>
            <p className={styles.disclaimerText}>{'Loading recommendation documents, please wait...'}</p>
          </div>
        )}
        {submitError && <p className={styles.submitError}>{'Something went wrong - please try again'}</p>}
        <div className={styles.standardCOntainer}>
          <Button
            size="large"
            disabled={disabled}
            type="button"
            onClick={onSubmit}
            label="Complete setup"
            loading={submitInProgress}
          />
        </div>
      </div>
    </BaseGrid>

  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
  authoriseImplementPensionPlan: getAuthoriseImplementPensionPlan(state),
});

const mapDispatchToProps = {
  dispatchSetupContribution: setupContributionAction,
  _dispatchOnboardingContributionSubmitStepVisited: contributionSubmitStepVisitedAction,
  _dispatchOngoingContributionSubmitStepVisited: ongoingContributionSubmitStepVisitedAction,
  _dispatchOnboardingSubmitClicked: submitClickedAction,
  _dispatchOngoingSubmitClicked: ongoingSubmitClickedAction,
  _dispatchOnboardingReturnLinkClicked: returnLinkClickedAction,
  _dispatchOngoingReturnLinkClicked: ongoingReturnLinkClickedAction,
  _dispatchOnboardingChangeBankDetailsClicked: changeBankDetailsClickedAction,
  _dispatchOngoingChangeBankDetailsClicked: ongoingChangeBankDetailsClickedAction,
  _dispatchOnboardingChangeContributionClicked: changeContributionClickedAction,
  _dispatchOngoingChangeContributionClicked: ongoingChangeContributionClickedAction,
  _dispatchOnboardingRecommendationReportLinkClicked: recommendationReportLinkClickedAction,
  _dispatchOngoingRecommendationReportLinkClicked: ongoingRecommendationReportLinkClickedAction,
  _dispatchOnboardingKeyDetailsLinkClicked: keyDetailsLinkClickedAction,
  _dispatchOngoingKeyDetailsLinkClicked: ongoingKeyDetailsLinkClickedAction,
  _dispatchOnboardingIllustrationLinkClicked: illustrationLinkClickedAction,
  _dispatchOngoingIllustrationLinkClicked: ongoingIllustrationLinkClickedAction,
  _dispatchOnboardingAuthoriseImplementPensionPlanUnselected: authoriseImplementPensionPlanUnselectedAction,
  _dispatchOngoingAuthoriseImplementPensionPlanUnselected: ongoingAuthoriseImplementPensionPlanUnselectedAction,
  _dispatchOnboardingAuthoriseImplementPensionPlanSelected: authoriseImplementPensionPlanSelectedAction,
  _dispatchOngoingAuthoriseImplementPensionPlanSelected: ongoingAuthoriseImplementPensionPlanSelectedAction,
  _dispatchOnboardingAuthoriseDirectDebitUnselected: authoriseDirectDebitUnselectedAction,
  _dispatchOngoingAuthoriseDirectDebitUnselected: ongoingAuthoriseDirectDebitUnselectedAction,
  _dispatchOnboardingAuthoriseDirectDebitSelected: authoriseDirectDebitSelectedAction,
  _dispatchOngoingAuthoriseDirectDebitSelected: ongoingAuthoriseDirectDebitSelectedAction,
  _dispatchOnboardingDirectDebitGuaranteeOpened: directDebitGuaranteeExpandedAction,
  _dispatchOngoingDirectDebitGuaranteeOpened: ongoingDirectDebitGuaranteeExpandedAction,
  _dispatchOnboardingDirectDebitGuaranteeCollapsed: directDebitGuaranteeCollapsedAction,
  _dispatchOngoingDirectDebitGuaranteeCollapsed: ongoingDirectDebitGuaranteeCollapsedAction,
  _dispatchOnboardingSubmitError: submitErrorAction,
  _dispatchOngoingSubmitError: ongoingSubmitErrorAction,
  _dispatchOnboardingContributionAndReliefTermsLinkClicked: contributionAndReliefTermsLinkClickedAction,
  _dispatchOngoingContributionAndReliefTermsLinkClicked: ongoingContributionAndReliefTermsLinkClickedAction,
  _dispatchOnboardingTermsOfBusinessLinkClicked: termsOfBusinessLinkClickedAction,
  _dispatchOngoingTermsOfBusinessLinkClicked: ongoingTermsOfBusinessLinkClickedAction,
};

// Merge it all (create final props to be passed)
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isCustomerOngoing } = stateProps;
  const {
    _dispatchOnboardingContributionSubmitStepVisited,
    _dispatchOngoingContributionSubmitStepVisited,
    _dispatchOnboardingSubmitClicked,
    _dispatchOngoingSubmitClicked,
    _dispatchOnboardingReturnLinkClicked,
    _dispatchOngoingReturnLinkClicked,
    _dispatchOnboardingChangeBankDetailsClicked,
    _dispatchOngoingChangeBankDetailsClicked,
    _dispatchOnboardingChangeContributionClicked,
    _dispatchOngoingChangeContributionClicked,
    _dispatchOnboardingRecommendationReportLinkClicked,
    _dispatchOngoingRecommendationReportLinkClicked,
    _dispatchOnboardingKeyDetailsLinkClicked,
    _dispatchOngoingKeyDetailsLinkClicked,
    _dispatchOnboardingIllustrationLinkClicked,
    _dispatchOngoingIllustrationLinkClicked,
    _dispatchOnboardingAuthoriseImplementPensionPlanUnselected,
    _dispatchOngoingAuthoriseImplementPensionPlanUnselected,
    _dispatchOnboardingAuthoriseImplementPensionPlanSelected,
    _dispatchOngoingAuthoriseImplementPensionPlanSelected,
    _dispatchOnboardingAuthoriseDirectDebitUnselected,
    _dispatchOngoingAuthoriseDirectDebitUnselected,
    _dispatchOnboardingAuthoriseDirectDebitSelected,
    _dispatchOngoingAuthoriseDirectDebitSelected,
    _dispatchOnboardingDirectDebitGuaranteeOpened,
    _dispatchOngoingDirectDebitGuaranteeOpened,
    _dispatchOnboardingDirectDebitGuaranteeCollapsed,
    _dispatchOngoingDirectDebitGuaranteeCollapsed,
    _dispatchOnboardingSubmitError,
    _dispatchOngoingSubmitError,
    _dispatchOnboardingContributionAndReliefTermsLinkClicked,
    _dispatchOngoingContributionAndReliefTermsLinkClicked,
    _dispatchOnboardingTermsOfBusinessLinkClicked,
    _dispatchOngoingTermsOfBusinessLinkClicked,
  } = dispatchProps;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    dispatchContributionSubmitStepVisited: isCustomerOngoing ? _dispatchOngoingContributionSubmitStepVisited : _dispatchOnboardingContributionSubmitStepVisited,
    dispatchSubmitClicked: isCustomerOngoing ? _dispatchOngoingSubmitClicked : _dispatchOnboardingSubmitClicked,
    dispatchReturnLinkClicked: isCustomerOngoing ? _dispatchOngoingReturnLinkClicked : _dispatchOnboardingReturnLinkClicked,
    dispatchChangeBankDetailsClicked: isCustomerOngoing ? _dispatchOngoingChangeBankDetailsClicked : _dispatchOnboardingChangeBankDetailsClicked,
    dispatchChangeContributionClicked: isCustomerOngoing ? _dispatchOngoingChangeContributionClicked : _dispatchOnboardingChangeContributionClicked,
    dispatchRecommendationReportLinkClicked: isCustomerOngoing ? _dispatchOngoingRecommendationReportLinkClicked : _dispatchOnboardingRecommendationReportLinkClicked,
    dispatchKeyDetailsLinkClicked: isCustomerOngoing ? _dispatchOngoingKeyDetailsLinkClicked : _dispatchOnboardingKeyDetailsLinkClicked,
    dispatchIllustrationLinkClicked: isCustomerOngoing ? _dispatchOngoingIllustrationLinkClicked : _dispatchOnboardingIllustrationLinkClicked,
    dispatchAuthoriseImplementPensionPlanUnselected: isCustomerOngoing ? _dispatchOngoingAuthoriseImplementPensionPlanUnselected : _dispatchOnboardingAuthoriseImplementPensionPlanUnselected,
    dispatchAuthoriseImplementPensionPlanSelected: isCustomerOngoing ? _dispatchOngoingAuthoriseImplementPensionPlanSelected : _dispatchOnboardingAuthoriseImplementPensionPlanSelected,
    dispatchAuthoriseDirectDebitUnselected: isCustomerOngoing ? _dispatchOngoingAuthoriseDirectDebitUnselected : _dispatchOnboardingAuthoriseDirectDebitUnselected,
    dispatchAuthoriseDirectDebitSelected: isCustomerOngoing ? _dispatchOngoingAuthoriseDirectDebitSelected : _dispatchOnboardingAuthoriseDirectDebitSelected,
    dispatchDirectDebitGuaranteeOpened: isCustomerOngoing ? _dispatchOngoingDirectDebitGuaranteeOpened : _dispatchOnboardingDirectDebitGuaranteeOpened,
    dispatchDirectDebitGuaranteeCollapsed: isCustomerOngoing ? _dispatchOngoingDirectDebitGuaranteeCollapsed : _dispatchOnboardingDirectDebitGuaranteeCollapsed,
    dispatchSubmitError: isCustomerOngoing ? _dispatchOngoingSubmitError : _dispatchOnboardingSubmitError,
    dispatchContributionAndReliefTermsLinkClicked: isCustomerOngoing ? _dispatchOngoingContributionAndReliefTermsLinkClicked : _dispatchOnboardingContributionAndReliefTermsLinkClicked,
    dispatchTermsOfBusinessLinkClicked: isCustomerOngoing ? _dispatchOngoingTermsOfBusinessLinkClicked : _dispatchOnboardingTermsOfBusinessLinkClicked,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ContributionSubmitStep);
