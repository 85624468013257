import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import sentryException from '../../util/sentryException';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import EmailIcon from '../../assets/images/rebranding/email.svg';
import BasePage from '../../components/BasePage/BasePage';
import { sendGaPageEvent } from '../../util/googleAnalyticHelpers';
import { vcCustomerPageView } from '../../redux/modules/investmentAdvice';
import { getLatestConversation } from '../../redux/selectors/conversations';

import { getPensionExpert } from '../../redux/selectors/investmentAdvice';
import { getEmail } from '../../redux/selectors';

import { NO_PENSION_EXPERT_TIMEOUT } from '../../util/constants';

import styles from './VulnerableCustomerPage.css';

function VulnerableCustomerPage({
  pensionExpert,
  dispatchVcCustomerPageView,
  latestConversation,
}) {
  useEffect(() => {
    sendGaPageEvent('/we-need-to-speak-to-you');
    dispatchVcCustomerPageView();
  }, []);

  const adviser = {
    firstName: get(pensionExpert, 'firstName'),
    imageUrl: get(pensionExpert, 'imageUrl'),
    email: get(pensionExpert, 'email'),
  };

  const allExpertFieldsPresent = Object.values(adviser).every(Boolean);

  const [hasAdvisor, setHasAdvisor] = useState(false);
  const latestConversationId = get(latestConversation, 'conversationId');

  useEffect(() => {
    if (allExpertFieldsPresent) {
      setHasAdvisor(true);
    } else {
      const timeout = setTimeout(() => {
        if (allExpertFieldsPresent) {
          setHasAdvisor(true);
        } else {
          sentryException(new Error('pension-expert-fields-missing'), {
            section: 'vulnerable-customer-page',
          });
        }
      }, NO_PENSION_EXPERT_TIMEOUT);
      return () => clearTimeout(timeout);
    }
  }, [allExpertFieldsPresent]);

  if (hasAdvisor) {
    return (
      <BasePage messageWidget>
        <div className={styles.container}>
          <h2 className={styles.heading}>{'Your Pension Adviser needs to speak to you.'}</h2>
          <img className={styles.expertImage} src={adviser.imageUrl} alt="Pension adviser" />
          <p className={styles.body}>
            {' '}
            {'The answers to the questions we have asked you about your health and ability to make financial decisions mean that we need to speak to you before we confirm your recommended pension plan. Please contact your Pension Adviser to discuss this.'}
            {' '}
          </p>
          <div className={styles.contactRow}>
            <div className={styles.contactIconContainer}><img className={styles.mailIcon} src={EmailIcon} alt="Email" /></div>
            <ButtonLink
              label={'Message me'}
              variant="primary"
              font="bodyTwo"
              to={latestConversationId ? `/inbox/${latestConversationId}` : 'inbox/new-message'}
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button to="/" label="Back to dashboard" />
          </div>
        </div>
      </BasePage>
    );
  }

  return (
    <BasePage>
      <div className={styles.container}>
        <h2 className={styles.heading}>{'Your Pension Adviser needs to speak to you.'}</h2>
        <p className={styles.body}>
          {' '}
          {'The answers to the questions we have asked you about your health and ability to make financial decisions mean that we need to speak to you before we confirm your recommended pension plan.'}
          {' '}
        </p>
        <p className={styles.body}>
          {'We will contact you to discuss this.'}
        </p>
        <Button to="/" label="Back to dashboard" />
      </div>
    </BasePage>
  );
}

const mapStateToProps = (state) => ({
  pensionExpert: getPensionExpert(state),
  customerEmail: getEmail(state),
  latestConversation: getLatestConversation(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchVcCustomerPageView: () => dispatch(vcCustomerPageView()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VulnerableCustomerPage);
