import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/get';

import styles from './RecommendedPensionPlanCard.css';

import PlanNameBox from '../PlanNameBox/PlanNameBox';
import Button from '../Button/Button';

import { getIVUCollection } from '../../redux/selectors';
import { getTargetInvestment } from '../../redux/selectors/targetInvestment';
import { planSummaryCtaClickedAction } from '../../redux/modules/investmentAdvice';
import { serpsReadYourRecommendedPlanClicked } from '../../redux/modules/serps';

import smallGreenTick from '../../assets/images/icons/smallGreenTick.svg';
import aegonLogo from '../../assets/images/providers/aegon.svg';
import avivaLogo from '../../assets/images/providers/aviva.svg';
import lvLogo from '../../assets/images/providers/lv.png';
import metlifeLogo from '../../assets/images/providers/metlife.svg';
import prudentialLogo from '../../assets/images/providers/prudential.svg';
import scottishWidowsLogo from '../../assets/images/providers/scottish-widows.svg';

import { ADVISER_FEE } from '../../util/constants';

const getProfilePortfolio = (ivuCollection, portfolioReference) => {
  const profilePortfolio = {
    planName: 'Mixed Plan',
    esg: undefined,
    styleName: 'Generic',
  };

  const recentPortfolio = ivuCollection.find((p) => portfolioReference && portfolioReference === get(p, 'reference'));
  profilePortfolio.planName = recentPortfolio.displayName;
  profilePortfolio.esg = recentPortfolio.environmentalSocialGovernance;
  profilePortfolio.styleName = recentPortfolio.displayName;
  return profilePortfolio;
};

const PROVIDER_MAP = {
  aegon: { label: 'Aegon', logo: aegonLogo },
  aviva: { label: 'Aviva', logo: avivaLogo },
  lv: { label: 'LV', logo: lvLogo },
  metlife: { label: 'MetLife', logo: metlifeLogo },
  prudential: { label: 'Prudential', logo: prudentialLogo },
  'scottish-widows': { label: 'Scottish Widows', logo: scottishWidowsLogo },
};

const RecommendedPensionPlanCard = ({
  ivuCollection,
  targetInvestment,
  dispatchPlanSummaryCtaClickedAction,
  dispatchSerpsReadYourRecommendedPlanClicked,
}) => {
  const portfolioReference = get(targetInvestment, 'reference');
  const platformName = get(targetInvestment, 'platformName');

  const { label: providerLabel, logo: providerLogo } = PROVIDER_MAP[platformName.toLowerCase()];

  const { planName, esg, styleName } = getProfilePortfolio(ivuCollection, portfolioReference);

  const fmc = get(targetInvestment, 'fmc');
  const platformFee = get(targetInvestment, 'platformFee');
  const annualCharge = (fmc + platformFee + ADVISER_FEE).toFixed(2);

  return (
    <React.Fragment>
      <h4 className={styles.pensionPlanHeading}>{'Your recommended pension plan'}</h4>
      {planName && (
        <PlanNameBox planName={planName} esg={esg} styleName={styleName} context="post-cds" />
      )}
      <div className={styles.tickTextContainer}>
        <img src={smallGreenTick} alt="Green tick" className={styles.tick} />
        <div className={styles.tickText}>{'Personalised plan updated reguarly'}</div>
      </div>
      <div className={classnames(styles.tickTextContainer, styles.last)}>
        <img src={smallGreenTick} alt="Green tick" className={styles.tick} />
        <div className={styles.tickText}>{'Selected from the whole of the market'}</div>
      </div>
      <div className={styles.divider} />
      <div className={styles.planInfoContainer}>
        <div className={styles.platformHeader}>{'Platform'}</div>
        <div className={styles.feeHeader}>{'All-inclusive annual fee with ongoing advice'}</div>
        <div className={styles.vertDivider} />
        <div className={styles.providerContainer}>
          <img className={styles.providerLogo} src={providerLogo} alt={`${providerLabel} logo`} />
          <div className={styles.providerLabel}>{providerLabel}</div>
        </div>
        <div className={styles.annualCharge}>{`${annualCharge}%`}</div>
      </div>
      <div className={styles.bottomDivider} />
      <Button
        size="large"
        label="Read your recommended plan"
        to="/plan-summary"
        onClick={() => {
          dispatchSerpsReadYourRecommendedPlanClicked();
          return dispatchPlanSummaryCtaClickedAction;
        }}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  dispatchPlanSummaryCtaClickedAction: planSummaryCtaClickedAction,
  dispatchSerpsReadYourRecommendedPlanClicked: serpsReadYourRecommendedPlanClicked,
};

const mapStateToProps = (state) => ({
  ivuCollection: getIVUCollection(state),
  targetInvestment: getTargetInvestment(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedPensionPlanCard);
