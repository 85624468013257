/* eslint-disable max-len */
import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button/Button';
import ButtonLink from '../../../../components/ButtonLink/ButtonLink';

import { getPensionsWithHmrcResponseEligibleYes } from '../../../../redux/selectors/serps';
import {
  serpsTileCompleteRequestClicked,
  serpsTileFindCheckAndTransferClicked,
  serpsFindOtherPensionsButtonClicked,
  setSelectedSerpsToAuthoriseAction,
} from '../../../../redux/modules/serps';

import BlueTick from '../../../../assets/images/icons/Icon_Circle_Blue_Tick_Rounded.svg';
import TickPensionUnSelected from '../../../../assets/images/icons/Tick_Pension_Unselected.svg';
import smallGreenTick from '../../../../assets/images/icons/smallGreenTick.svg';

import styles from './OnboardingSerps.css';

const SerpsCtaTile = ({
  pensionsWithHmrcResponseEligibleYes,
  showMissingDetailsCta,
  showWaitingForHmrcResponseCta,
  showHmrcResponseEligiblePensionsYesCta,
  showPostSerpsPensionsAuthorisationCta,
  showHmrcResponsePendingPensionsVettingCta,
  showNegativeHmrcResponse,
  showNotEligibleForSerpsTraceCta,
  dispatchSerpsFindOtherPensionsButtonClicked,
  dispatchSerpsTileFindCheckAndTransferClicked,
  dispatchSerpsTileCompleteRequestClicked,
  dispatchSetSelectedSerpsToAuthoriseAction,
}) => {
  const [selectedPensions, setSelectedPensions] = useState(pensionsWithHmrcResponseEligibleYes.map((p) => p._id) || []);
  const getAllSelectedPensions = (pension) => {
    if ((selectedPensions.indexOf(pension._id) < 0)) {
      setSelectedPensions([...selectedPensions, pension._id]);
    } else {
      setSelectedPensions(selectedPensions.filter((data) => data !== pension._id));
    }
  };
  return (
    <React.Fragment>
      {showMissingDetailsCta && (
        <div className={styles.serpsCtaWrapper}>
          <div className={styles.serpsContentWrapper}>
            <h3 className={styles.serpsCtaHeading}>{'SERPS trace'}</h3>
            <p className={styles.serpsCtaBody}>{'Great news! You are eligible for a SERPS pension trace. In order for us to contact HMRC on your behalf, we require some personal details to complete your request.'}</p>
          </div>
          <Button
            label="Complete request"
            size="large"
            to={'/serps-request'}
            onClick={() => {
              dispatchSerpsTileCompleteRequestClicked();
            }}
          />
        </div>
      )}
      {showWaitingForHmrcResponseCta && (
        <div className={styles.serpsCtaWrapper}>
          <h3 className={styles.serpsCtaHeading}>{'SERPS trace'}</h3>
          <p className={styles.serpsCtaBody}>{'Your SERPS trace request has been received. We usually expect to hear back from HMRC within 30 days, but this can take longer.'}</p>
          <br />
          <p className={styles.serpsCtaBody}>{'Once we hear back from HMRC, we will give you your results in your secure online account.'}</p>
        </div>
      )}
      {showHmrcResponseEligiblePensionsYesCta && (
        <div className={styles.serpsCtaWrapper}>
          <div className={styles.serpsContentWrapper}>
            <h3 className={styles.serpsCtaHeading}>{'SERPS trace'}</h3>
            <p className={styles.serpsCtaBody}>{'Good news! HMRC have told us you may have the following pensions.'}</p>
            <br />
            <p className={styles.serpsCtaBody}>{'At this point, we only know the provider. The next step is our Find, Check & Transfer service to discover the value of your pension(s), check for any guarantees or penalties, and then complete the transfer. (One-off 1% arrangement fee applies).'}</p>
            <br />
            <p className={styles.serpsCtaBody}>{'Please select the pensions you want to find and combine into your new personalised pension plan.'}</p>
            <br />
            <ButtonLink
              to="/documents"
              font="bodyTwo"
              label="View HMRC response letter"
              onClick={() => {}}
            />
            <div className={styles.serpsCtaPensionsWrapper}>
              {pensionsWithHmrcResponseEligibleYes.map((pension) => {
                return (
                  <div key={pension._id} className={styles.serpsCtaPensionsRow}>
                    <button
                      type="button"
                      className={styles.closeButton}
                      onClick={() => {
                        getAllSelectedPensions(pension);
                      }}
                    >
                      <img
                        src={(selectedPensions.indexOf(pension._id) > -1) ? BlueTick : TickPensionUnSelected}
                        alt="Blue tick"
                      />
                    </button>
                    <p className={styles.serpsCtaPensionRowName}>{pension.policyName}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <Button
            size="large"
            label="→ Find, Check & Transfer"
            to={'/serps-auth'}
            disabled={selectedPensions.length === 0}
            onClick={async () => {
              dispatchSetSelectedSerpsToAuthoriseAction({ selectedPensions });
              dispatchSerpsTileFindCheckAndTransferClicked();
            }}
          />
        </div>
      )}
      {showHmrcResponsePendingPensionsVettingCta && (
      <div className={styles.serpsCtaWrapper}>
        <div className={styles.serpsContentWrapper}>
          <h3 className={styles.serpsCtaHeading}>{'SERPS trace'}</h3>
          <p className={styles.serpsCtaBody}>{'We’re processing your SERPS request which normally takes around 30 days. We\'ll update the pension status in your account and we\'ll send you a message when the transfer can be processed.'}</p>
          <br />
          <p className={styles.serpsCtaBody}>{'While you wait, we can help you to find and combine other old workplace pensions and consolidate them into your new personalised pension plan, making sure you benefit from ongoing investment advice, a dedicated Pension Adviser and impartial, whole of market fund selection.'}</p>
        </div>
        <Button
          size="large"
          label="Find other pensions "
          to={'/add-pensions'}
          onClick={() => {
            dispatchSerpsFindOtherPensionsButtonClicked();
          }}
        />
      </div>
      )}
      {showPostSerpsPensionsAuthorisationCta && (
        <div className={styles.serpsCtaWrapper}>
          <div className={styles.serpsContentWrapper}>
            <h3 className={styles.serpsCtaHeading}>{'SERPS trace'}</h3>
            <p className={styles.serpsCtaBody}>{'We’re processing your Find, Check & Transfer request which normally takes around 30 days. We’ll update the pension status in your account and we’ll send you a message when the transfer can be processed.'}</p>
            <br />
            <p className={styles.serpsCtaBody}>{'While you wait, we can help you to find and combine other old workplace pensions and consolidate them into your new personalised pension plan, making sure you benefit from ongoing investment advice, a dedicated Pension Adviser and impartial, whole of market fund selection.'}</p>
          </div>
          <Button
            size="large"
            label="Find other pensions "
            to={'/add-pensions'}
            onClick={() => {
              dispatchSerpsFindOtherPensionsButtonClicked();
            }}
          />
        </div>
      )}
      {showNegativeHmrcResponse && (
        <div className={styles.serpsCtaWrapper}>
          <div className={styles.serpsContentWrapper}>
            <h3 className={styles.serpsCtaHeading}>{'SERPS trace'}</h3>
            <p className={styles.serpsCtaBody}>{'HMRC have told us they don’t hold any contracted out information for you, which suggests you have never contracted out of SERPS.'}</p>
            <br />
            <p className={styles.serpsCtaBody}>{'However, we can help you to find and combine any other old workplace pensions and consolidate them into your new personalised pension plan, making sure you benefit from ongoing investment advice, a dedicated Pension Adviser and impartial, whole of market fund selection.'}</p>
          </div>
          <Button
            size="large"
            label="Find other pensions "
            to={'/add-pensions'}
            onClick={() => {
              dispatchSerpsFindOtherPensionsButtonClicked();
            }}
          />
        </div>
      )}
      {showNotEligibleForSerpsTraceCta && (
        <div className={styles.serpsCtaWrapper}>
          <div className={styles.serpsContentWrapper}>
            <h3 className={styles.serpsCtaHeading}>{'SERPS trace'}</h3>
            <p className={styles.serpsCtaBody}>{'Based on your age, we don’t think it’s likely you would have a SERPS pension. However, we’ve made it easy to find your old workplace pensions and consolidate them into your new personalised pension plan, making sure you benefit from:'}</p>
            <br />
            <div className={styles.tickTextContainer}>
              <img src={smallGreenTick} alt="Green tick" className={styles.tick} />
              <div className={styles.tickText}>{'A modern, flexible pension plan tailored for you'}</div>
            </div>
            <div className={styles.tickTextContainer}>
              <img src={smallGreenTick} alt="Green tick" className={styles.tick} />
              <div className={styles.tickText}>{'Support from a dedicated Pension Adviser'}</div>
            </div>
            <div className={styles.tickTextContainer}>
              <img src={smallGreenTick} alt="Green tick" className={styles.tick} />
              <div className={styles.tickText}>{'Impartial, whole of market fund selection'}</div>
            </div>
          </div>
          <Button
            size="large"
            label="Find other pensions "
            to={'/add-pensions'}
            onClick={() => {
              dispatchSerpsFindOtherPensionsButtonClicked();
            }}
          />
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  pensionsWithHmrcResponseEligibleYes: getPensionsWithHmrcResponseEligibleYes(state),
});

const mapDispatchToProps = ({
  dispatchSerpsFindOtherPensionsButtonClicked: serpsFindOtherPensionsButtonClicked,
  dispatchSerpsTileFindCheckAndTransferClicked: serpsTileFindCheckAndTransferClicked,
  dispatchSerpsTileCompleteRequestClicked: serpsTileCompleteRequestClicked,
  dispatchSetSelectedSerpsToAuthoriseAction: setSelectedSerpsToAuthoriseAction,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(SerpsCtaTile);

SerpsCtaTile.propTypes = {
  pensionsWithHmrcResponseEligibleYes: PropTypes.arrayOf(PropTypes.shape({
    policyName: PropTypes.string,
  })),
  showMissingDetailsCta: PropTypes.bool.isRequired,
  showWaitingForHmrcResponseCta: PropTypes.bool.isRequired,
  showHmrcResponseEligiblePensionsYesCta: PropTypes.bool.isRequired,
  showPostSerpsPensionsAuthorisationCta: PropTypes.bool.isRequired,
  showHmrcResponsePendingPensionsVettingCta: PropTypes.bool.isRequired,
  showNegativeHmrcResponse: PropTypes.bool.isRequired,
  showNotEligibleForSerpsTraceCta: PropTypes.bool.isRequired,
};

SerpsCtaTile.defaultProps = {
  pensionsWithHmrcResponseEligibleYes: [],
};
