import React from 'react';

import NotificationBanner from '../NotificationBanner/NotificationBanner';

const MetlifeCustomerNotification = () => {
  return (
    <NotificationBanner
      alertCategory={'Blue'}
      alertTitle={''}
      expandableAlertDescription={'Please contact your Pension Adviser to discuss this pension.'}
    />
  );
};

export default MetlifeCustomerNotification;
