import React from 'react';

import NotificationBanner from '../NotificationBanner/NotificationBanner';

const MissingRpOngoingNotification = () => {
  return (
    <NotificationBanner
      alertCategory={'Red'}
      alertTitle={'Please contact your dedicated Pension Adviser'}
      expandableAlertDescription={'We are currently not able to show your pension details.'}
      ctaButtonOptions={{
        size: 'medium',
        label: 'Message my Pension Adviser',
        to: 'inbox/new-message',
      }}
    />
  );
};

export default MissingRpOngoingNotification;
