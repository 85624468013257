import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';
import { getIsAuthenticated } from '../../redux/selectors';

const PreAuthRoute = ({
  component, authenticated, location, exact,
}) => {
  const { from } = (location && location.state) || { from: { pathname: '/' } };

  return (
    <Route
      exact={exact}
      render={(props) => (
        !authenticated
          ? React.createElement(component, props)
          : (
            <Redirect
              to={{
                pathname: from.pathname,
              }}
            />
          )
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  authenticated: getIsAuthenticated(state),
});

PreAuthRoute.propTypes = {
  exact: PropTypes.bool,
  component: PropTypes.elementType.isRequired,
  authenticated: PropTypes.bool.isRequired,
};

PreAuthRoute.defaultProps = {
  exact: false,
};

export default connect(mapStateToProps)(PreAuthRoute);
