exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);

// module
exports.push([module.id, ".-InvestorProfile-headingContainer-3xXit {\n  margin-top: 30px;\n}\n.-InvestorProfile-atrImage-iRwlh {\n  height: 58px;\n  max-width: 160px;\n}\n.-InvestorProfile-atrHeading-DmANU {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + ";\n  font-size: 32px;\n  margin: 0;\n}\n.-InvestorProfile-contentContainer-2_EMD{\n  margin-top: 15px;\n}\n.-InvestorProfile-atrDescription-1FTYH, \n.-InvestorProfile-adviserMessageMeContainer-2ePmq {\n  line-height: 22px;\n}\n.-InvestorProfile-atrDescription-1FTYH {\n  margin: 0;\n}\n.-InvestorProfile-adviserMessageMeContainer-2ePmq {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin-top: 25px;\n}\n.-InvestorProfile-adviserImage-1flw3 {\n  width: 55px;\n  height: 55px;\n  margin-right: 15px;\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["typographyDesktop"] + ") {\n  .-InvestorProfile-atrImage-iRwlh {\n    height: 66px;\n    max-width: 184px;\n  }\n\n  .-InvestorProfile-atrHeading-DmANU {\n    font-size: 40px;\n  }\n\n  .-InvestorProfile-atrDescription-1FTYH, \n  .-InvestorProfile-adviserMessageMeContainer-2ePmq {\n    line-height: 24px;\n  }\n\n  .-InvestorProfile-adviserImage-1flw3 {\n    width: 63px;\n    height: 63px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"colors": "\"../../theme/colors.css\"",
	"textTitleRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + "",
	"typography": "\"../../theme/typography.css\"",
	"bodyOne": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"dimensions": "\"../../theme/dimensions.css\"",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"typographyDesktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["typographyDesktop"] + "",
	"headingContainer": "-InvestorProfile-headingContainer-3xXit",
	"atrImage": "-InvestorProfile-atrImage-iRwlh",
	"atrHeading": "-InvestorProfile-atrHeading-DmANU",
	"contentContainer": "-InvestorProfile-contentContainer-2_EMD",
	"atrDescription": "-InvestorProfile-atrDescription-1FTYH " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"adviserMessageMeContainer": "-InvestorProfile-adviserMessageMeContainer-2ePmq " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"adviserImage": "-InvestorProfile-adviserImage-1flw3"
};