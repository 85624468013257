import React, { Component } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import missingDetailsSubmitted from '../../assets/images/missing-details-submitted.svg';
import { missingDetailsThankYouPageLanded } from '../../redux/modules/signupFlow';
import styles from '../PersonalDetails/PersonalDetails.css';
import { getHasOnlyInProgressContributionInstruction, getHasInProgressLumpSumInstructions } from '../../redux/selectors/investmentAdvice';

import { addPensionThankYouPageView, setForceMentionMeModalAction } from '../../redux/modules/investmentAdvice';

class ThankYou extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onBack = props.onBack.bind(this);
    const {
      dispatchMissingDetailsThankYouPageLanded,
    } = this.props;
    this.dispatchMissingDetailsThankYouPageLanded = once(dispatchMissingDetailsThankYouPageLanded);
  }

  componentDidMount() {
    const {
      pageLoaded,
      flow,
      parentSetStep,
      parentStep,
    } = this.props;
    pageLoaded();
    if (flow === 'setup-lump-sum') {
      parentSetStep(parentStep + 1);
    }

    window.scrollTo(0, 0);
    this.dispatchMissingDetailsThankYouPageLanded();
  }

  render() {
    const {
      dispatchSetForceMentionMeModal,
      isSubmitting,
      flow,
      hasOnlyInProgressContributionInstructions,
      hasInProgressLumpSumInstructions,
    } = this.props;

    const showContributionOnlyCopy = (flow === 'missing-details-cta' && (hasOnlyInProgressContributionInstructions || hasInProgressLumpSumInstructions)) || (flow === 'add-contribution' || flow === 'setup-lump-sum');

    return (
      <BaseGrid tabletTwelveColumn addPensionsTheme>
        <div className={styles.missingDetailsWrapper}>
          <div className={styles.submitted}>
            <img src={missingDetailsSubmitted} alt="Missing Details Submitted" />
          </div>
          {showContributionOnlyCopy ? (
            <div>
              <h1 className={styles.ninoHeading}>
                {'Thank you for providing these details'}
              </h1>
              <div className={styles.content}>
                {'Providing these will help us to authorise and set up your contributions.'}
                {' '}
              </div>
            </div>
          ) : (
            <div>
              <h1 className={styles.ninoHeading}>
                {'Thank you for providing these details'}
              </h1>
              <div className={styles.content}>
                {'Providing these will help us to locate and access your pension(s) faster and significantly speed up the transfer process for you.'}
                {' '}
              </div>
            </div>
          )}
          {showContributionOnlyCopy ? (
            <div>
              <Button
                size="large"
                type="button"
                label={'+ Transfer a pension'}
                loading={isSubmitting}
                to="/add-pensions"
                wrapperId="missing-details-submit"
              />
            </div>
          ) : (
            <div>
              <Button
                size="large"
                label={'+ Transfer another pension'}
                loading={isSubmitting}
                link="/add-pensions"
                wrapperId="missing-details-submit"
              />
            </div>
          )}
          {<div>&nbsp;</div>}
          <ButtonLink
            label={<span className={styles.employerLinkText}>{'Return to dashboard'}</span>}
            to="/"
            secondary
            mid
            onClick={dispatchSetForceMentionMeModal}
            wrapperStyles={{
              fontFamily: 'Poppins',
              fontWeight: 500,
              fontSize: '16px',
              display: 'block',
              margin: '0 auto',
            }}
          />
        </div>
      </BaseGrid>
    );
  }
}

const mapStateToProps = (state) => ({
  hasOnlyInProgressContributionInstructions: getHasOnlyInProgressContributionInstruction(state),
  hasInProgressLumpSumInstructions: getHasInProgressLumpSumInstructions(state),
});

const mapDispatchToProps = {
  pageLoaded: addPensionThankYouPageView,
  dispatchSetForceMentionMeModal: setForceMentionMeModalAction,
  dispatchMissingDetailsThankYouPageLanded:
    missingDetailsThankYouPageLanded,
};

ThankYou.defaultProps = {
  onBack: () => { },
};

export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);
