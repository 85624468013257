import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FieldLabel from '../FieldLabel/FieldLabel';
import FieldStatusBlock from '../FieldStatusBlock/FieldStatusBlock';

import styles from './HtmlSelectField.css';

class HtmlSelectField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionRefs: [],
    };
  }

  componentDidMount() {
    this.setOptionRefs();
    this.setSelectedAttribute();
  }

  componentDidUpdate(prevProps) {
    const { options, value } = this.props;
    if (prevProps.options.length !== options.length) {
      this.setOptionRefs();
    }
    if (prevProps.value !== value) {
      this.setSelectedAttribute();
    }
  }

  setOptionRefs() {
    const { options } = this.props;
    this.setState({
      optionRefs: options.map((option) => ({ option: option.value, ref: React.createRef() })),
    });
  }

  setSelectedAttribute() {
    const { value } = this.props;
    const { optionRefs } = this.state;
    optionRefs.forEach(({ option, ref }) => {
      if (value === option && ref.current) {
        ref.current.setAttribute('selected', true);
      } else if (ref.current) {
        ref.current.removeAttribute('selected');
      }
    });
  }

  focus() {
    if (this.select) {
      this.select.focus();
    }
  }

  render() {
    const {
      name,
      label,
      placeholder,
      onChange,
      options,
      wrapperStyles,
      errorText,
      touched,
      required,
      disabled,
      largeHeadings,
      value,
      onMouseDown,
      fullWidth,
      showLabel,
      blockWrapper,
      fieldStatusWrapper,
    } = this.props;
    const {
      optionRefs,
    } = this.state;

    return (
      <div className={styles.wrapper} style={wrapperStyles}>
        {showLabel && (
          <FieldLabel
            label={label}
            required={required}
            disabled={disabled}
            largeHeadings={largeHeadings}
          />
        )}
        <FieldStatusBlock
          error={errorText}
          touched={touched}
          fullWidth={fullWidth}
          blockWrapper={blockWrapper}
          wrapperStyles={fieldStatusWrapper}
        >
          <select
            name={name}
            required
            onChange={onChange}
            value={value || ''}
            className={styles.selectOptions}
            onMouseDown={onMouseDown}
          >
            <option value="" disabled>{placeholder}</option>
            {options.map(
              (option) => {
                const optionRef = optionRefs
                  .find((_optionRef) => _optionRef.option === option.value);
                return (
                  <option
                    ref={optionRef ? optionRef.ref : undefined}
                    value={option.value}
                    key={option.value}
                  >
                    {option.label}
                  </option>
                );
              },
            )}
          </select>
        </FieldStatusBlock>
      </div>
    );
  }
}

export default HtmlSelectField;

HtmlSelectField.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  wrapperStyles: PropTypes.shape({}),
  showLabel: PropTypes.bool,
  showStatusBlock: PropTypes.bool,
};

HtmlSelectField.defaultProps = {
  placeholder: 'Select...',
  options: [{ label: '', value: '' }],
  wrapperStyles: {},
  showLabel: true,
  showStatusBlock: true,
  blockWrapper: {},
  fieldStatusWrapper: {},
};
