import React from 'react';
import { Formik, Field } from 'formik';
import { renderTextField } from '../util/formik-custom-fields';
import FormError from '../components/FormError/FormError';
import Button from '../components/Button/Button';
import ButtonLink from '../components/ButtonLink/ButtonLink';
import styles from './ForgotPasswordReset.css';

const ForgotPasswordResetEmail = (props) => {
  return (
    <Formik
      onSubmit={props.onSubmit}
      render={({
        handleSubmit,
        isSubmitting,
        status,
      }) => (
        <form onSubmit={handleSubmit}>
          <h1 className="h2">{'Forgot password?'}</h1>
          <p>{'Don’t worry, just enter the email address you registered with. If there is an online account with that email we’ll send you a link to reset your password.'}</p>
          <br />
          <Field
            name="email"
            type="email"
            component={renderTextField}
            label="Email"
            defaultValue="Enter email address"
            required
          />
          <br />
          {status && <FormError error={status.formError} additionalInfo={status.additionalInfo} />}
          <Button type="submit" label="Send link" cta loading={isSubmitting} />
          <br />
          <p className={styles.recaptchaTxt}>
            {'This site is protected by reCAPTCHA and the Google '}
            <a href="https://policies.google.com/privacy">{'Privacy Policy'}</a>
            {' and '}
            <a href="https://policies.google.com/terms">{'Terms of Service'}</a>
            {' apply.'}
          </p>
          <br />
          <ButtonLink label="Back to login page" to="/" />
        </form>
      )}
    />
  );
};

export default ForgotPasswordResetEmail;
