import React from 'react';
import get from 'lodash/get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

import MessageCard from '../../../components/MessageCard/MessageCard';
import OngoingStatusSection from '../OngoingStatusSection';
import TaxResidencyNotification from '../../../components/Notifications/TaxResidencyNotification';
import MissingDetailsNotification from '../../../components/Notifications/MissingDetailsNotification';
import RegularContributionsUpdateNotification from '../../../components/Notifications/RegularContributionsUpdateNotification';
import WithdrawalUpdateNotification from '../../../components/Notifications/WithdrawalUpdateNotification';
import PensionsReadyToAuthoriseNotification from '../../../components/Notifications/PensionsReadyToAuthoriseNotification';
import VulnerableCustomerNotification from '../../../components/Notifications/VulnerableCustomerNotification';
import NewOneOffLumpSumNotification from '../../../components/Notifications/NewOneOffLumpSumNotification';

import { getPasswordReset } from '../../../redux/selectors';
import { getIsCustomerOngoing } from '../../../redux/selectors/experience';
import { getLatestConversation } from '../../../redux/selectors/conversations';

import {
  getShouldShowVCNotification,
  getShouldShowWithdrawalUpdateNotification,
  getShouldShowTaxResidencyNotification,
  getShouldShowMissingDetailsNotification,
  getShouldShowPensionsReadyToAuthoriseNotification,
  getShouldShowRegularContributionsUpdateNotification,
  getShouldShowNewOneOffLumpSumNotification,
} from '../../../redux/selectors/notifications';

import styles from '../DashboardPage.css';

const Notifications = (props) => {
  const {
    passwordReset,
    isCustomerOngoing,
    latestConversation,
    shouldShowVCNotification,
    shouldShowRegularContributionsUpdateNotification,
    shouldShowWithdrawalUpdateNotification,
    shouldShowTaxResidencyNotification,
    shouldShowMissingDetailsNotification,
    shouldShowPensionsReadyToAuthoriseNotification,
    shouldShowInProgressLumpSumInstruction,
  } = props;

  const latestConversationId = get(latestConversation, 'conversationId', null);

  return (
    <div className={styles.notificationAlertsWrapper}>
      {passwordReset && (
        <div className={styles.dashboardHeader}>
          <MessageCard>{'Your password has been updated.'}</MessageCard>
        </div>
      )}
      {/* RED */}
      {shouldShowVCNotification && (
        <div className={styles.notificationAlertContainer}>
          <VulnerableCustomerNotification latestConversationId={latestConversationId} />
        </div>
      )}
      {/* RED */}
      {shouldShowTaxResidencyNotification && (
        <div className={styles.notificationAlertContainer}>
          <TaxResidencyNotification />
        </div>
      )}
      {/* RED */}
      {shouldShowMissingDetailsNotification && (
        <div className={styles.notificationAlertContainer}>
          <MissingDetailsNotification />
        </div>
      )}
      {/* BLUE - Priority 2 */}
      {shouldShowPensionsReadyToAuthoriseNotification
      && !isCustomerOngoing && (
        <div className={styles.notificationAlertContainer}>
          <PensionsReadyToAuthoriseNotification />
        </div>
      )}
      {isCustomerOngoing && (
        <OngoingStatusSection />
      )}
      {/* GREEN */}
      {shouldShowRegularContributionsUpdateNotification && (
        <div className={styles.notificationAlertContainer}>
          <RegularContributionsUpdateNotification />
        </div>
      )}
      {/* GREEN */}
      {shouldShowWithdrawalUpdateNotification && (
        <div className={styles.notificationAlertContainer}>
          <WithdrawalUpdateNotification />
        </div>
      )}
      {/* GREEN */}
      {shouldShowInProgressLumpSumInstruction && !isCustomerOngoing && (
        <div className={styles.notificationAlertContainer}>
          <NewOneOffLumpSumNotification instructionId={shouldShowInProgressLumpSumInstruction} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  passwordReset: getPasswordReset(state),
  isCustomerOngoing: getIsCustomerOngoing(state),
  latestConversation: getLatestConversation(state),
  shouldShowVCNotification: getShouldShowVCNotification(state),
  shouldShowRegularContributionsUpdateNotification:
    getShouldShowRegularContributionsUpdateNotification(state),
  shouldShowWithdrawalUpdateNotification: getShouldShowWithdrawalUpdateNotification(state),
  shouldShowTaxResidencyNotification: getShouldShowTaxResidencyNotification(state),
  shouldShowMissingDetailsNotification: getShouldShowMissingDetailsNotification(state),
  shouldShowPensionsReadyToAuthoriseNotification:
    getShouldShowPensionsReadyToAuthoriseNotification(state),
  shouldShowInProgressLumpSumInstruction: getShouldShowNewOneOffLumpSumNotification(state),
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect(),
)(withRouter(Notifications));
