/* eslint-disable max-len */
import { getFirestore } from 'redux-firestore';

export const COMPLETED_NO_CHANGE = 'pp/ongoingStatusNotification/COMPLETED_NO_CHANGE_CLOSED';
export const ENABLE_TWO_FACTOR_AUTHENTICATION_CTA_CLICKED = 'pp/ongoing-dashboard/ENABLE_TWO_FACTOR_AUTHENTICATION_CTA_CLICKED';

export const statusNotificationCompletedNoChangeClosed = (uid, pensionPlanRecommendationId) => {
  const firestore = getFirestore();

  return async (dispatch) => {
    if (!uid || !pensionPlanRecommendationId) {
      return;
    }

    dispatch({ type: COMPLETED_NO_CHANGE });
    const updateSeenNotification = firestore.collection('users').doc(uid).collection('notificationTracking').doc('pensionPlanNotifications');
    await updateSeenNotification.set({
      [pensionPlanRecommendationId]: {
        'no-change-completed': 'closed',
      },
    }, { merge: true });
  };
};

export const INSTRUCTION_COMPLETED_CLOSED = 'pp/contributions/INSTRUCTION_COMPLETED_NOTIFICATION_CLOSED';
export const ONGOING_INSTRUCTION_COMPLETED_CLOSED = 'pp/contributions/ONGOING_INSTRUCTION_COMPLETED_NOTIFICATION_CLOSED';
export const MARKETING_PREFERENCES_NOTIFICATION_CLOSED = 'pp/contributions/MARKETING_PREFERENCES_NOTIFICATION_CLOSED';

export const statusNotificationInstructionCompletedClosed = (
  uid,
  instructionId,
  isCustomerOngoing,
) => {
  const firestore = getFirestore();

  return async (dispatch) => {
    if (!uid || !instructionId) {
      return;
    }
    const type = isCustomerOngoing ? INSTRUCTION_COMPLETED_CLOSED : ONGOING_INSTRUCTION_COMPLETED_CLOSED;
    dispatch({ type });

    const updateSeenNotification = firestore.collection('users').doc(uid).collection('notificationTracking').doc('instructionNotifications');
    await updateSeenNotification.set({
      [instructionId]: {
        'intruction-completed': 'closed',
      },
    }, { merge: true });
  };
};

export const COMPLETE_ADD_CONTRIBUTION_REQUEST_DISMISSED = 'pp/ongoing-dashboard/COMPLETE_ADD_CONTRIBUTION_REQUEST_DISMISSED';
export const COMPLETE_ADD_PENSION_REQUEST_DISMISSED = 'pp/ongoing-dashboard/COMPLETE_ADD_PENSION_REQUEST_DISMISSED';
export const COMPLETE_ADD_CONTRIBUTION_REQUEST_CLICKED = 'pp/ongoing-dashboard/COMPLETE_ADD_CONTRIBUTION_REQUEST_CLICKED';
export const COMPLETE_ADD_PENSION_REQUEST_CLICKED = 'pp/ongoing-dashboard/COMPLETE_ADD_PENSION_REQUEST_CLICKED';
export const SERPS_PENSION_FOUND_UPDATE_DISMISSED = 'pp/ongoing-dashboard/SERPS_PENSION_FOUND_UPDATE_DISMISSED';
export const SERPS_COMPLETE_PENSION_DISMISSED = 'pp/ongoing-dashboard/SERPS_COMPLETE_PENSION_DISMISSED';
export const SERPS_PENSION_TRACE_DISMISSED = 'pp/ongoing-dashboard/SERPS_PENSION_TRACE_DISMISSED';
export const SERPS_INELIGIBLE_DISMISSED = 'pp/ongoing-dashboard/SERPS_INELIGIBLE_DISMISSED';
export const SERPS_NO_PENSIONS_FOUND_UPDATE_DISMISSED = 'pp/ongoing-dashboard/SERPS_NO_PENSIONS_FOUND_UPDATE_DISMISSED';

export const setNotificationCompletePensionOrContributionRequested = (
  uid,
  requestType,
) => {
  const firestore = getFirestore();

  return async () => {
    if (!uid || !requestType) {
      return;
    }

    const addCompleteRequestNotification = firestore.collection('users').doc(uid).collection('notificationTracking').doc('completeRequestNotification');
    await addCompleteRequestNotification.set({
      [requestType]: {
        requested: true,
        dismissed: false,
        timestamp: new Date().toISOString(),
      },
    }, { merge: true });
  };
};

export const setNotificationCompletePensionOrContributionRequestDismissed = (
  uid,
  requestType,
) => {
  const firestore = getFirestore();

  return async () => {
    if (!uid || !requestType) {
      return;
    }

    const updateSeenNotification = firestore.collection('users').doc(uid).collection('notificationTracking').doc('completeRequestNotification');
    await updateSeenNotification.set({
      [requestType]: {
        dismissed: true,
        timestamp: new Date().toISOString(),
      },
    }, { merge: true });
  };
};

export const setWithdrawalMiniJourneyVisited = (
  uid,
) => {
  const firestore = getFirestore();

  return async () => {
    if (!uid) {
      return;
    }

    const addWithdrawalMiniJourneyViewHistory = firestore.collection('users').doc(uid).collection('notificationTracking').doc('withdrawalMiniJourney');
    await addWithdrawalMiniJourneyViewHistory.set({
      viewed: true,
      viewedDate: new Date().toISOString(),
    }, { merge: true });
  };
};

export const marketingPreferenceNotificationClicked = (
  uid,
) => {
  const firestore = getFirestore();

  return async () => {
    const marketingPreferenceNotification = firestore.collection('users').doc(uid).collection('notificationTracking').doc('marketingPreferenceNotification');
    const snoozeUntilDate = new Date();
    snoozeUntilDate.setMonth(snoozeUntilDate.getMonth() + 6);
    await marketingPreferenceNotification.set({
      snoozeUntil: snoozeUntilDate.toISOString(),
    }, { merge: true });
  };
};

export const PORTFOLIO_2023_UPDATE_NOTIFICATION_CLOSED = 'pp/ongoing-dashboard/PORTFOLIO_2023_UPDATE_NOTIFICATION_CLOSED';

export const may2023DrawdownUpdateNotificationClicked = (
  uid,
) => {
  const firestore = getFirestore();

  return async () => {
    const may2023DrawdownUpdateNotification = firestore.collection('users').doc(uid).collection('notificationTracking').doc('portfolioUpdateNotifications');
    await may2023DrawdownUpdateNotification.set({
      may2023DrawdownUpdate: new Date().toISOString(),
    }, { merge: true });
  };
};

export const MISSING_DETAILS_COMPLETED_NOTIFICATION = 'pp/missingDetails/MISSING_DETAILS_COMPLETED_NOTIFICATION';

export const statusNotificationMissingDetailsCompleted = () => {
  return (dispatch) => dispatch({
    type: MISSING_DETAILS_COMPLETED_NOTIFICATION,
    payload: new Date().toISOString(),
  });
};

const INTRO_MODAL_CLOSED = 'pp/ongoing-dashboard/INTRO_MODAL_CLOSED';
const INTRO_MODAL_VIEW = 'pp/ongoing-dashboard/INTRO_MODAL_VIEW';

export const introModalClosed = (
  uid,
) => {
  const firestore = getFirestore();

  return async (dispatch) => {
    const notificationTrackingNotification = firestore.collection('users').doc(uid).collection('notificationTracking').doc('introModal');
    await notificationTrackingNotification.set({
      timestamp: new Date().toISOString(),
      dismissed: true,
    }, { merge: true });

    dispatch({ type: INTRO_MODAL_CLOSED });
  };
};

export const dispatchIntroModalViewAction = () => ({ type: INTRO_MODAL_VIEW });

const MFM_ANNOUNCEMENT_MODAL_CLOSED = 'pp/ongoing-dashboard/MFM_ANNOUNCEMENT_MODAL_CLOSED';
const MFM_ANNOUNCEMENT_MODAL_VIEW = 'pp/ongoing-dashboard/MFM_ANNOUNCEMENT_MODAL_VIEW';

export const mfmAnnouncementModalClosed = (
  uid,
) => {
  const firestore = getFirestore();

  return async (dispatch) => {
    const notificationTrackingNotification = firestore.collection('users').doc(uid).collection('notificationTracking').doc('mfmAnnouncement');
    await notificationTrackingNotification.set({
      timestamp: new Date().toISOString(),
      dismissed: true,
    }, { merge: true });

    dispatch({ type: MFM_ANNOUNCEMENT_MODAL_CLOSED });
  };
};

export const mfmAnnouncementModalViewAction = () => ({ type: MFM_ANNOUNCEMENT_MODAL_VIEW });

const initialState = {
  missingDetailsCompleted: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case MISSING_DETAILS_COMPLETED_NOTIFICATION:
      return {
        ...state,
        timeMissingDetailsCompleted: action.payload,
      };
    default:
      return state;
  }
}

export const planActivityViewedTracking = async (
  uid,
) => {
  const firestore = getFirestore();
  if (!uid) {
    return;
  }
  const updateSeenPlanActivityFirstTime = firestore.collection('users').doc(uid).collection('notificationTracking').doc('planActivityViewedTracking');
  await updateSeenPlanActivityFirstTime.set({
    viewed: true,
    viewedDate: new Date().toISOString(),
  }, { merge: true });
};

export const NEW_ONE_OFF_LUMP_SUM_NOTIFICATION_CLOSED = 'pp/ongoing-dashboard/NEW_ONE_OFF_LUMP_SUM_NOTIFICATION_CLOSED';

export const oneOffLumpSumNotificationClicked = async (
  uid,
  instructionId,
) => {
  const firestore = getFirestore();
  if (!uid || !instructionId) {
    return;
  }

  const seenLumpSumNotification = firestore.collection('users').doc(uid).collection('notificationTracking').doc('lumpSumNotificationUpdate');
  await seenLumpSumNotification.set({
    [instructionId]: {
      dateClosed: new Date().toISOString(),
    },
  }, { merge: true });
};

export const SERPS_INTRO_MODAL_CLOSED = 'pp/serps/SERPS_INTRO_MODAL_CLOSED';

export const customerVisitedSerpsMissingDetails = (
  uid,
) => {
  const firestore = getFirestore();
  if (!uid) {
    return;
  }

  return async (dispatch) => {
    const serpsNotification = firestore.collection('users').doc(uid).collection('notificationTracking').doc('serpsFirstSession');
    await serpsNotification.set({
      visited: true,
      visitedDate: new Date().toISOString(),
    }, { merge: true });

    dispatch({ type: SERPS_INTRO_MODAL_CLOSED });
  };
};

export const serpsRequestAuthorized = async (
  uid,
) => {
  const firestore = getFirestore();
  if (!uid) {
    return;
  }

  const serpsNotification = firestore.collection('users').doc(uid).collection('notificationTracking').doc('serpsRequest');
  await serpsNotification.set({
    authorized: true,
    authorizedDate: new Date().toISOString(),
  }, { merge: true });
};


export const serpsCompleteRequestNotificationDismissed = (
  uid,
) => {
  const firestore = getFirestore();
  return async () => {
    const updateSeenNotification = firestore.collection('users').doc(uid).collection('notificationTracking').doc('serpsCompleteRequestNotification');
    await updateSeenNotification.set({
      viewed: true,
      viewedDate: new Date().toISOString(),
    }, { merge: true });
  };
};

export const dispatchSerpsTraceIneligibleNotificationDismissed = (
  uid,
) => {
  const firestore = getFirestore();
  return async () => {
    const updateSeenNotification = firestore.collection('users').doc(uid).collection('notificationTracking').doc('serpsTraceIneligibleNotification');
    await updateSeenNotification.set({
      viewed: true,
      viewedDate: new Date().toISOString(),
    }, { merge: true });
  };
};

export const dispatchSerpsWaitingForHmrcNotificationDismissed = (
  uid,
) => {
  const firestore = getFirestore();
  return async () => {
    const updateSeenNotification = firestore.collection('users').doc(uid).collection('notificationTracking').doc('serpsWaitingForHmrcNotification');
    await updateSeenNotification.set({
      viewed: true,
      viewedDate: new Date().toISOString(),
    }, { merge: true });
  };
};

export const serpsPensionFoundNotificationDismissed = (
  uid,
) => {
  const firestore = getFirestore();
  return async () => {
    const updateSeenNotification = firestore.collection('users').doc(uid).collection('notificationTracking').doc('serpsPensionFoundNotification');
    await updateSeenNotification.set({
      viewed: true,
      viewedDate: new Date().toISOString(),
    }, { merge: true });
  };
};

export const serpsNoPensionsFoundNotificationDismissed = (
  uid,
) => {
  const firestore = getFirestore();
  return async () => {
    const updateSeenNotification = firestore.collection('users').doc(uid).collection('notificationTracking').doc('noSerpsPensionsFoundNotification');
    await updateSeenNotification.set({
      viewed: true,
      viewedDate: new Date().toISOString(),
    }, { merge: true });
  };
};

export const SERPS_PENSIONS_SELECTED_TO_AUTHORISE = 'pp/serps/SERPS_PENSIONS_SELECTED_TO_AUTHORISE';
