import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import classNames from 'classnames';

import ContributionsWidget from './DashboardWidgets/ContributionsWidget';
import AddPensionsWidget from './DashboardWidgets/AddPensionsWidget';
import {
  contributionWidgetClicked,
  addPensionsWidgetClicked,
  messageMeWidgetClicked,
  planActivityWidgetClicked,
} from '../../../redux/modules/dashboard';
import { getAuthoritativeRetirementPlan } from '../../../redux/selectors';
import { getIsCustomerOngoingMultipleRps, getIsCustomerOptoutFullEncashment } from '../../../redux/selectors/experience';
import { getIsWithdrawalAllowed, getIsAddPensionsAllowed } from '../../../redux/selectors/journeys';
import {
  getPensionExpert,
  getInProgressContributionInstructions,
  getInProgressLumpSumInstructions,
} from '../../../redux/selectors/investmentAdvice';
import {
  getCustomerAgeEligibleForSerps,
  getSerpsMissingDetailsComplete,
} from '../../../redux/selectors/serps';

import PlanActivity from '../../../assets/images/rebranding/horizontal-bars-icon.svg';
import SERPSIcon from '../../../assets/images/rebranding/SERPS_icon.svg';
import NoAdvisorAssigned from '../../../assets/images/cds/no-advisor-assigned.svg';

import styles from './OngoingDashboard.css';

const OngoingBalance = (props) => {
  const {
    pensionAdviser,
    history,
    dispatchContributionWidgetClicked,
    dispatchAddPensionsWidgetClicked,
    dispatchMessageMeWidgetClicked,
    dispatchPlanActivityWidgetClicked,
    isWithdrawalAllowed,
    authoritativeRp,
    inProgressContributionInstructions,
    inProgressLumpSumInstructions,
    isAddPensionsAllowed,
    isCustomerOngoingMultipleRps,
    isCustomerOptoutFullEncashment,
    isCustomerAgeEligibleForSerps,
    isSerpsMissingDetailsComplete,
  } = props;

  const adviser = {
    imageUrl: get(pensionAdviser, 'imageUrl'),
  };

  const addMoneyLink = (contributionAmount = null) => {
    dispatchContributionWidgetClicked();
    const url = contributionAmount ? `/setup-contribution?amount=${contributionAmount}` : '/setup-contribution';
    history.push(url);
  };

  const addMoneyOptionsLink = () => {
    dispatchContributionWidgetClicked();
    history.push('/add-money-option');
  };

  const addLumpSumLink = () => {
    dispatchContributionWidgetClicked();
    history.push('/setup-lump-sum');
  };

  const addPensionsLink = () => {
    dispatchAddPensionsWidgetClicked();
    history.push('/add-pensions');
  };

  const messagePensionAdvisorLink = () => {
    dispatchMessageMeWidgetClicked();
    history.push('/inbox/new-message');
  };

  const viewPlanActivityLink = () => {
    dispatchPlanActivityWidgetClicked();
    history.push('/plan-activity');
  };

  const serpsLink = () => {
    dispatchPlanActivityWidgetClicked();
    history.push('/serps-request');
  };

  const verifiedNetPersonalContribution = get(authoritativeRp, 'personalContributions.status') === 'verified' ? get(authoritativeRp, 'personalContributions.netValue') : 0;

  return (
    <div
      className={classNames(
        styles.ongoingWidgetsBox,
        {
          [styles.fullRowWidgets]: !isWithdrawalAllowed,
        },
      )}
    >
      {!isCustomerOptoutFullEncashment && (
        <React.Fragment>
          <ContributionsWidget
            addMoneyOnClick={addMoneyLink}
            addMoneyOptionsOnClick={addMoneyOptionsLink}
            addLumpSumOnClick={addLumpSumLink}
            inProgressContributionInstructions={inProgressContributionInstructions}
            inProgressLumpSumInstructions={inProgressLumpSumInstructions}
            isAddPensionsAllowed={isAddPensionsAllowed}
            isCustomerOngoingMultipleRps={isCustomerOngoingMultipleRps}
            authoritativeRp={authoritativeRp}
          />
          <AddPensionsWidget
            onClick={addPensionsLink}
            isCustomerOngoingMultipleRps={isCustomerOngoingMultipleRps}
            isCustomerOptoutFullEncashment={isCustomerOptoutFullEncashment}
            isAddPensionsAllowed={isAddPensionsAllowed}
          />
        </React.Fragment>
      )}
      <button
        type="button"
        className={classNames(styles.widget, styles.messagePensionAdvisorWidget, {
          [styles.fullWidthWidget]: isCustomerOptoutFullEncashment,
        })}
        onClick={messagePensionAdvisorLink}
      >
        <img className={styles.widgetImg} alt="Pension adviser" src={adviser.imageUrl || NoAdvisorAssigned} width="36" height="36" />
        <p className={styles.widgetText}>{'Message my Pension Adviser'}</p>
      </button>
      {!isCustomerOptoutFullEncashment && (
        <button
          type="button"
          className={classNames(
            styles.widget,
            {
              [styles.fullRowWidgets]: !verifiedNetPersonalContribution,
            },
          )}
          onClick={viewPlanActivityLink}
        >
          <img className={styles.widgetImg} alt="plan activity" src={PlanActivity} />
          <p className={styles.widgetText}>{'View plan activity'}</p>
        </button>
      )}
      {isCustomerAgeEligibleForSerps && !isSerpsMissingDetailsComplete && (
        <button
          type="button"
          className={classNames(
            styles.widget,
            styles.fullRowWidgets
          )}
          onClick={serpsLink}
        >
          <img className={styles.widgetImg} alt="plan activity" src={SERPSIcon} />
          <p className={styles.widgetText}>{'Start SERPS Trace'}</p>
        </button>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  pensionAdviser: getPensionExpert(state),
  isWithdrawalAllowed: getIsWithdrawalAllowed(state),
  authoritativeRp: getAuthoritativeRetirementPlan(state),
  inProgressContributionInstructions: getInProgressContributionInstructions(state),
  inProgressLumpSumInstructions: getInProgressLumpSumInstructions(state),
  isAddPensionsAllowed: getIsAddPensionsAllowed(state),
  isCustomerOngoingMultipleRps: getIsCustomerOngoingMultipleRps(state),
  isCustomerOptoutFullEncashment: getIsCustomerOptoutFullEncashment(state),
  isCustomerAgeEligibleForSerps: getCustomerAgeEligibleForSerps(state),
  isSerpsMissingDetailsComplete: getSerpsMissingDetailsComplete(state)
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchContributionWidgetClicked: () => dispatch(contributionWidgetClicked()),
    dispatchAddPensionsWidgetClicked: () => dispatch(addPensionsWidgetClicked()),
    dispatchMessageMeWidgetClicked: () => dispatch(messageMeWidgetClicked()),
    dispatchPlanActivityWidgetClicked: () => dispatch(planActivityWidgetClicked()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OngoingBalance));
