import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import merge from 'lodash/merge';
import get from 'lodash/get';

import { withRouter, Redirect } from 'react-router-dom';
import PensionProviderStep from './PensionProviderStep';
import PensionTransferOptionStep from './PensionTransferOptionStep';
import PensionValueStep from './PensionValueStep';
import CurrentlyContributingStep from './CurrentlyContributingStep';
import HasBegunWithdrawing from './HasBegunWithdrawing';
import SubmitStep from './SubmitStep';
import OtherPensionProviderStep from './OtherPensionProviderStep';
import EmployerSectorStep from './EmployerSectorStep';
import EmployerDatesStep from './EmployerDatesStep';
import EmployerLocationStep from './EmployerLocationStep';
import EmployerNameStep from './EmployerNameStep';
import IntroStep from './IntroStep';
import EmployerPensionStep from './EmployerPensionStep';
import WithCollections, { COLLECTIONS } from '../../components/WithCollections/WithCollections';
import WithInvestmentAdviceLetter from '../../components/WithDocumentLink/WithInvestmentAdviceLetter';
import { AddPensionsController } from '../MissingDetailsControllers/MissingDetailsControllers';

import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import PolicyNumberStep from './PolicyNumberStep';
import { getAge } from '../../redux/selectors';
import { getPensions } from '../../redux/selectors/pensions';
import { currencyString } from '../../util/currency';
import {
  employerFlow,
  otherProviderUnmatchedFlowEmployerPension,
  otherProviderUnmatchedFlow,
  otherProviderFlow,
  authorityOnlyFlow,
  initialFlow,
  employerContributingFlow,
  INTRO_STEP,
  OTHER_PENSION_PROVIDER_STEP,
  PENSION_PROVIDER_STEP,
  PENSION_TRANSFER_OPTION_STEP,
  CURRENTLY_CONTRIBUTING_STEP,
  EMPLOYER_PENSION_STEP,
  SUBMIT_STEP,
  THANKYOU_STEP,
  POLICY_NUMBER_STEP,
  EMPLOYER_NAME_STEP,
  EMPLOYER_LOCATION_STEP,
  EMPLOYMENT_SECTOR_STEP,
  EMPLOYMENT_DATES_STEP,
  PENSION_VALUE_STEP,
  HAS_BEGUN_WITHDRAWING,
  CANNOT_TRANSFER_PENSION,
} from './flows';
import CannotTransferPension from './CannotTransferPension';
import { transferOptions } from '../../util/constants';

const AddPensionsPage = ({
  isCustomerOngoing,
  location,
  pensions,
  investmentAdviceLetterUrl,
  flow,
  history,
  currentAge,
  stepWithRequiredFields,
}) => {
  const initialState = {
    authoriseImplementPensionPlan: undefined,
    authoriseTransferBalanceOfPension: undefined,
    authoriseTransferOptionTransferBasic: undefined,
    authoriseTransferOptionTransferWithCheck: undefined,
    pageHtml: '',
    screenshot: '',
    topup: isCustomerOngoing,
    commonName: '',
    freeTextProvider: undefined,
    employerName: undefined,
    isOldEmployerPension: undefined,
    employmentStartDate: '',
    employmentEndDate: '',
    employerLocation: undefined,
    employerIndustry: undefined,
    employerAdditionalInformation: undefined,
    currentWorkplacePension: undefined,
    policyNumber: '',
    estimatedValue: undefined,
    _id: undefined,
    transferOption: undefined,
    customerAnsweredAlreadyDrawingDown: undefined,
  };

  const hideBackNavigation = get(history, 'location.state.hideBackNavigation');
  const urlParams = new URLSearchParams(location.search);
  const pensionId = urlParams.get('pension');
  const selectedPension = pensions.find((pension) => pension._id === pensionId);

  if (selectedPension) {
    const commonName = get(selectedPension, 'policyName', '');
    const policyNumber = get(selectedPension, 'policyNumber', '');
    const transferOption = get(selectedPension, 'transferOption', '');
    const customerAnsweredAlreadyDrawingDown = get(selectedPension, 'customerAnsweredAlreadyDrawingDown', '');
    const _id = get(selectedPension, '_id', '');

    const getCurrentWorkplacePension = () => {
      const currentWorkplacePension = get(selectedPension, 'employerContributions');

      if (!currentWorkplacePension || ['dont-know', 'not-answered'].includes(currentWorkplacePension)) return undefined;
      if (currentWorkplacePension === 'yes') return true;
      if (currentWorkplacePension === 'no') return false;
    };

    const currentWorkplacePension = getCurrentWorkplacePension();

    const estimatedValue = get(selectedPension, 'estimatedValue');
    const estimatedValueMask = estimatedValue ? currencyString(estimatedValue) : '';

    merge(initialState, {
      commonName,
      policyNumber,
      currentWorkplacePension,
      estimatedValue,
      estimatedValueMask,
      transferOption,
      customerAnsweredAlreadyDrawingDown,
      _id,
    });
  }

  const [formData, setFormData] = useState(initialState);
  const [totalSteps, setTotalSteps] = useState(initialFlow.length);
  const [step, setStepValue] = useState(0);
  const [amendMode, setAmendMode] = useState(false);

  const getUpdatedJourney = (_flow) => {
    if (currentAge >= 55 && formData.transferOption === transferOptions.TRANSFER_BASIC) {
      const newJourney = [..._flow];
      const submitStepIndex = newJourney.indexOf(SUBMIT_STEP);
      newJourney.splice(submitStepIndex, 0, HAS_BEGUN_WITHDRAWING);
      return newJourney;
    }
    return _flow;
  };

  const [journey, setJourney] = useState(selectedPension ? authorityOnlyFlow : initialFlow);

  const backFromEmployerName = () => {
    setJourney(getUpdatedJourney(selectedPension ? authorityOnlyFlow : initialFlow));
    setStepValue(1);
  };

  if (journey.length !== totalSteps) {
    setTotalSteps(journey.length);
  }

  const setStep = (newStepArg) => {
    const newStepValue = typeof newStepArg === 'function' ? newStepArg(step) : newStepArg;

    if (journey[newStepValue] === INTRO_STEP && journey !== initialFlow) {
      setJourney(initialFlow);
    }

    setStepValue(newStepValue);
  };

  const otherProviderStepIndex = otherProviderFlow.findIndex(
    (stepName) => stepName === OTHER_PENSION_PROVIDER_STEP,
  );

  const setProviderOtherFlow = () => {
    setJourney(getUpdatedJourney(otherProviderFlow));
    setStep(otherProviderStepIndex);
  };

  const setEmployerFlow = () => {
    const startIndex = employerFlow.findIndex((stage) => stage === EMPLOYER_NAME_STEP);
    setJourney(getUpdatedJourney(employerFlow));
    setStep(startIndex);
  };

  const setUnmatchedFlow = () => {
    setJourney(getUpdatedJourney(otherProviderUnmatchedFlow));
  };

  const setUnmatchedEmployerPensionFlow = () => {
    setJourney(getUpdatedJourney(otherProviderUnmatchedFlowEmployerPension));
  };

  const resetInitialFlow = () => {
    setJourney(getUpdatedJourney(initialFlow));
    const startIndex = initialFlow.findIndex((stage) => stage === CURRENTLY_CONTRIBUTING_STEP);
    setStepValue(startIndex);
  };

  const setEmployerContributionFlow = () => {
    let newJourney = [...journey];
    const index = newJourney.findIndex((stage) => stage === CURRENTLY_CONTRIBUTING_STEP);
    if (!journey.includes(CANNOT_TRANSFER_PENSION)) {
      newJourney = newJourney.slice(0, index + 1);
      newJourney.push(CANNOT_TRANSFER_PENSION);
      setJourney(newJourney);
    }
    setStep((_step) => _step + 1);
  };

  const resetFormData = () => {
    setFormData(initialState);
    setJourney(getUpdatedJourney(journey));
  };

  useEffect(() => {
    if (formData.transferOption === transferOptions.TRANSFER_BASIC) {
      setJourney(getUpdatedJourney(journey));
    } else {
      setJourney(selectedPension ? authorityOnlyFlow : initialFlow);
    }
  }, [formData.transferOption]);

  useEffect(() => {
    if (journey[step - 1] === PENSION_PROVIDER_STEP
      && formData.commonName !== ''
      && journey.includes(OTHER_PENSION_PROVIDER_STEP)) {
      setJourney(getUpdatedJourney(initialFlow));
    }
    if (journey[step - 1] === OTHER_PENSION_PROVIDER_STEP
      && !formData.freeTextProvider
      && journey.includes(EMPLOYER_PENSION_STEP)) {
      setJourney(getUpdatedJourney(otherProviderFlow));
    }
  }, [formData.commonName]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const stepsMap = {
    [INTRO_STEP]: (
      <IntroStep
        setStep={setStep}
        journey={journey}
        hideBackNavigation={hideBackNavigation}
      />
    ),
    [PENSION_TRANSFER_OPTION_STEP]: (
      <PensionTransferOptionStep
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
        hideBackNavigation={hideBackNavigation}
        resetFormData={resetFormData}
      />
    ),
    [PENSION_PROVIDER_STEP]: (
      <PensionProviderStep
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
        setEmployerFlow={setEmployerFlow}
        setProviderOtherFlow={setProviderOtherFlow}
        amendMode={amendMode}
        setAmendMode={setAmendMode}
        hideBackNavigation={hideBackNavigation}
        resetFormData={resetFormData}
      />
    ),
    [PENSION_VALUE_STEP]: (
      <PensionValueStep
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
        resetFormData={resetFormData}
      />
    ),
    [OTHER_PENSION_PROVIDER_STEP]: (
      <OtherPensionProviderStep
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
        setUnmatchedFlow={setUnmatchedFlow}
        setEmployerFlow={setEmployerFlow}
        resetFormData={resetFormData}
      />
    ),
    [CURRENTLY_CONTRIBUTING_STEP]: (
      <CurrentlyContributingStep
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        currentAge={currentAge}
        setStep={setStep}
        setEmployerContributionFlow={setEmployerContributionFlow}
      />
    ),
    [CANNOT_TRANSFER_PENSION]: (
      <CannotTransferPension
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
        currentAge={currentAge}
        journey={selectedPension ? authorityOnlyFlow : initialFlow}
        setJourney={setJourney}
        resetFormData={resetFormData}
        resetInitialFlow={resetInitialFlow}
      />
    ),
    [HAS_BEGUN_WITHDRAWING]: (
      <HasBegunWithdrawing
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
      />
    ),
    [EMPLOYER_PENSION_STEP]: (
      <EmployerPensionStep
        setUnmatchedFlow={setUnmatchedFlow}
        setUnmatchedEmployerPensionFlow={setUnmatchedEmployerPensionFlow}
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
      />
    ),
    [SUBMIT_STEP]: (
      <SubmitStep
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
        resetFormData={resetFormData}
        investmentAdviceLetterUrl={investmentAdviceLetterUrl}
      />
    ),
    [THANKYOU_STEP]: (
      <AddPensionsController
        onBack={() => {
          setJourney(initialFlow);
          setStep(0);
        }}
        flow={flow}
        stepWithRequiredFields={stepWithRequiredFields}
      />
    ),
    [POLICY_NUMBER_STEP]: (
      <PolicyNumberStep
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
        resetFormData={resetFormData}
      />
    ),
    [EMPLOYER_NAME_STEP]: (
      <EmployerNameStep
        journey={journey}
        backFromEmployerName={backFromEmployerName}
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
        resetFormData={resetFormData}
      />
    ),
    [EMPLOYER_LOCATION_STEP]: (
      <EmployerLocationStep
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
      />
    ),
    [EMPLOYMENT_SECTOR_STEP]: (
      <EmployerSectorStep
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
        resetFormData={resetFormData}
      />
    ),
    [EMPLOYMENT_DATES_STEP]: (
      <EmployerDatesStep
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
      />
    ),
  };

  const stepComponent = get(stepsMap, journey[step]);

  if (!stepComponent) {
    return <Redirect to="/" />;
  }

  return (
    stepsMap[journey[step]]
  );
};

const AddPensionsPageWithCollections = (props) => (
  <WithCollections
    context="add-pensions"
    dependencies={[COLLECTIONS.PROVIDERS]}
  >
    <WithInvestmentAdviceLetter
      section="add-pensions"
    >
      {
        (investmentAdviceLetterUrl) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <AddPensionsPage flow={'add-pension'} {...{ ...props, investmentAdviceLetterUrl }} />
        )
      }
    </WithInvestmentAdviceLetter>
  </WithCollections>
);

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
  pensions: getPensions(state),
  currentAge: getAge(state),
});

export default connect(mapStateToProps)(withRouter(AddPensionsPageWithCollections));
