import React, { useState } from 'react';
import { connect } from 'react-redux';
import AuthoriseChangePage from '../AuthoriseChangePage/AuthoriseChangePage';
import OngoingThankyouPage from '../OngoingThankYouPage/OngoingThankYouPage';
import { getHasRecommendationToAcceptAndReport } from '../../redux/selectors/ongoingService';

const AcceptChangeController = ({
  hasRecommendationToAccept,
}) => {
  const [acceptChangeSubmitted, setAcceptChangeSubmitted] = useState(false);

  if (acceptChangeSubmitted || !hasRecommendationToAccept) {
    return <OngoingThankyouPage />;
  }

  return (
    <AuthoriseChangePage onSubmit={() => setAcceptChangeSubmitted(true)} />
  );
};

const mapStateToProps = (state) => ({
  hasRecommendationToAccept: getHasRecommendationToAcceptAndReport(state),
});

export default connect(mapStateToProps)(AcceptChangeController);
