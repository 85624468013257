import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import sentryException from '../../util/sentryException';

import Loader from '../../components/Loader/Loader';
import {
  getFirestoreErrors,
} from '../../redux/selectors';

const fireAfterMs = 12000;

const LoaderPage = ({
  metadata, firestoreErrors,
}) => {
  const context = get(metadata, 'context', 'unknown');

  useEffect(() => {
    const timeout = setTimeout(() => {
      const error = new Error(`${context}-loader-stuck`);
      error.name = 'Stuck Loader';
      try {
        console.log(firestoreErrors);
      } catch (e) {
        console.log('Cannot log firestore errors', e);
      }
      sentryException(error, {
        ...metadata,
        fireAfterMs,
      });
    }, fireAfterMs);
    return () => {
      clearTimeout(timeout);
    };
  }, [...Object.values(metadata)]);

  return (
    <Loader wrapperStyles={{
      left: '50%',
      right: '50%',
      top: '50%',
      bottom: '50%',
      position: 'fixed',
    }}
    />
  );
};

const mapStateToProps = (state) => ({
  firestoreErrors: getFirestoreErrors(state),
});

export default connect(mapStateToProps)(LoaderPage);
