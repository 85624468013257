import get from 'lodash/get';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';

import { withRouter } from 'react-router';
import WithdrawalImportantInformationStep from './WithdrawalImportantInformationStep';
import WithdrawalDetailsFormStep from './WithdrawalDetailsFormStep';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import WithdrawalReviewDetailsStep from './WithdrawalReviewDetailsStep';
import WithdrawalCompleteStep from './WithdrawalCompleteStep';

import Button from '../../components/Button/Button';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import AccountDetails from '../../components/AccountDetails/AccountDetails';

import styles from './SetupWithdrawalPage.css';

import {
  nextClickedAction,
  backClickedAction,
  backToDashboardAction,
  referFriendClickedAction,
  withdrawalRequestInProgress,
} from '../../redux/modules/withdrawal';
import {
  getProjectedIncome,
  WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_DISPATCHED,
  WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_FULFILLED,
  WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_REJECTED,
} from '../../redux/modules/projection';

import {
  getAge,
  getFirebaseUid,
  getGender,
  getIntendedRetirementAge,
  getLatestPensionValue,
  getModelDescriptor,
  getVerifiedNetPersonalContributions,
  getVerifiedGrossEmployerContributions,
} from '../../redux/selectors';

import { getPensionExpert } from '../../redux/selectors/investmentAdvice';

import WithMentionMe from '../../components/WithMentionMe/WithMentionMe';
import MentionMeTile from '../../components/MentionMeTile/MentionMeTile';

import { SHOW_MENTION_ME, WITHDRAWAL_LUMP_SUM_PERCENTAGE_LIMIT } from '../../util/constants';
import WithdrawalResultsStep from './WithdrawalResultsStep';
import { percentage, percentSubtraction } from './Utils';

const WITHDRAWAL_IMPORTANT_INFORMATION_STEP = 'WITHDRAWAL_IMPORTANT_INFORMATION_STEP';
const WITHDRAWAL_DETAILS_FORM_STEP = 'WITHDRAWAL_DETAILS_FORM_STEP';
const WITHDRAWAL_ACCOUNT_DETAILS_FORM_STEP = 'WITHDRAWAL_ACCOUNT_DETAILS_FORM_STEP';
const WITHDRAWAL_REVIEW_DETAILS_STEP = 'WITHDRAWAL_REVIEW_DETAILS_STEP';
const WITHDRAWAL_RESULTS_STEP = 'WITHDRAWAL_RESULTS_STEP';
const WITHDRAWAL_COMPLETE_STEP = 'WITHDRAWAL_COMPLETE_STEP';

const SetupWithdrawalPage = ({
  isCustomerOngoing,
  pensionAdviser,
  dispatchNextClicked,
  dispatchBackClicked,
  dispatchBackToDashboardClicked,
  dispatchMentionMeLinkClicked,
  withdrawalType,
  latestPensionValue,
  dispatchWithdrawalRequestInProgress,
  verifiedNetPersonalContributions,
  verifiedGrossEmployerContributions,
  getProjection,
  gender,
  currentAge,
  retirementAge,
  estimatedPensionSavings,
  portfolio,
}) => {
  const [formData, setFormData] = useState({
    ongoing: isCustomerOngoing,
    withdrawalAmount: 0,
    withdrawalAmountMask: '£0',
    withdrawalType,
    balanceAtRequestTime: latestPensionValue,
  });

  const [currentEstimate, setcurrentEstimate] = useState(
    { currentAnnualIncome: 0, currentEstimatedPensionValueAtRetirement: 0 },
  );
  const [newEstimate, setNewEstimate] = useState(
    { newAnnualIncome: 0, newEstimatedPensionValueAtRetirement: 0 },
  );
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const onResultsStep = async () => {
    setLoading(true);
    setErrorMessage(null);

    const currentCustomerData = {
      gender,
      currentAge,
      retirementAge,
      estimatedPensionSavings,
      portfolio,
      statePension: false,
      estimatedMonthlyContributions: verifiedNetPersonalContributions || 0,
      grossMonthlyContributions: verifiedGrossEmployerContributions || 0,
    };
    try {
      if (formData.withdrawalAmount > 0) {
        const currentProjection = await getProjection(currentCustomerData);
        const {
          estimatedPreTaxIncomePerYear: currentAnnualIncome,
          estimatedPensionValueAtRetirement: currentEstimatedPensionValueAtRetirement,
        } = currentProjection;
        setcurrentEstimate({ currentAnnualIncome, currentEstimatedPensionValueAtRetirement });

        const percentWithdrawal = percentage(formData.withdrawalAmount,
          currentEstimatedPensionValueAtRetirement);

        setNewEstimate({
          newAnnualIncome: percentSubtraction(currentAnnualIncome, percentWithdrawal),
          newEstimatedPensionValueAtRetirement:
            percentSubtraction(currentEstimatedPensionValueAtRetirement, percentWithdrawal),
        });
      }
    } catch (e) {
      setErrorMessage('Something went wrong! Please try again.');
    }
    setLoading(false);
  };

  const [totalSteps, setTotalSteps] = useState(5);

  const [step, setStep] = useState(0);
  const [amendMode, setAmendMode] = useState(false);
  const stepsMap = {

    [WITHDRAWAL_IMPORTANT_INFORMATION_STEP]: (
      <WithdrawalImportantInformationStep
        formData={formData}
        setFormData={setFormData}
      />
    ),
    [WITHDRAWAL_DETAILS_FORM_STEP]: (
      <WithdrawalDetailsFormStep
        formData={formData}
        setFormData={setFormData}
      />
    ),
    [WITHDRAWAL_RESULTS_STEP]: (
      <WithdrawalResultsStep
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        loading={loading}
        errorMessage={errorMessage}
        setFormData={setFormData}
        setStep={setStep}
        currentEstimate={currentEstimate}
        newEstimate={newEstimate}
        amendMode={amendMode}
        setAmendMode={setAmendMode}
      />
    ),
    [WITHDRAWAL_ACCOUNT_DETAILS_FORM_STEP]: (
      <AccountDetails
        formData={formData}
        setFormData={setFormData}
        instructionType={'withdrawal'}
      />
    ),
    [WITHDRAWAL_REVIEW_DETAILS_STEP]: (
      <WithdrawalReviewDetailsStep
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        setStep={setStep}
        amendMode={amendMode}
        setAmendMode={setAmendMode}
      />
    ),
    [WITHDRAWAL_COMPLETE_STEP]: (
      <WithdrawalCompleteStep />
    ),
  };

  const setupSteps = [
    WITHDRAWAL_IMPORTANT_INFORMATION_STEP,
    WITHDRAWAL_DETAILS_FORM_STEP,
    WITHDRAWAL_RESULTS_STEP,
    WITHDRAWAL_ACCOUNT_DETAILS_FORM_STEP,
    WITHDRAWAL_REVIEW_DETAILS_STEP,
    WITHDRAWAL_COMPLETE_STEP,
  ];

  const [journey] = useState(setupSteps);
  if (journey.length !== totalSteps) {
    setTotalSteps(journey.length);
  }

  const validate = () => {
    switch (journey[step]) {
      case WITHDRAWAL_ACCOUNT_DETAILS_FORM_STEP:
        return (!formData.name
          || !/\S/g.test(formData.name)
          || !formData.sortCode
          || formData.sortCode.length !== 8
          || !formData.accountNumber
          || formData.accountNumber.length !== 8
          || !formData.bankName
          || !/\S/g.test(formData.bankName))
          || (formData.rollNo && !/^[A-Z0-9*/-]{1,18}$/.test(formData.rollNo));

      case WITHDRAWAL_DETAILS_FORM_STEP:
        return typeof formData.healthIssues !== 'boolean'
          || !['lump-sum', 'monthly'].includes(formData.withdrawalType)
          || (!formData.fundsUsedForSelectedOption
            || (formData.fundsUsedForSelectedOption && !formData.fundsUsedFor))
          || formData.withdrawalAmount <= 0;

      default:
        return false;
    }
  };

  const setNextButtonLabel = () => {
    if (step === 2) {
      return 'Proceed to withdrawal';
    }
    if (step === 4) {
      return 'Thanks, I’m done';
    }
    if (amendMode) {
      return 'Confirm';
    }
    return 'Next';
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const stepFromParam = urlParams.get('step');
  const parsedStepFromParam = parseInt(stepFromParam, 10);
  const validStepFromParam = setupSteps[parsedStepFromParam];

  /**
   * Advance to step 1 using url param if redirected
   * from /withdrawals-we-need-to-speak-to-you.
   */
  useEffect(() => {
    if (validStepFromParam && parsedStepFromParam === 1) setStep(parsedStepFromParam);
  }, []);

  /**
   * Populate formData if redirected from /withdrawals-we-need-to-speak-to-you.
   */
  useEffect(() => {
    const withdrawalFormData = JSON.parse(sessionStorage.getItem('withdrawal-lump-sum'));
    if (withdrawalFormData && validStepFromParam) {
      setFormData(withdrawalFormData);
    }
  }, []);

  /**
   * Store formData in sessionStorage when formData changes.
   */
  useEffect(() => {
    if (step === 1) {
      sessionStorage.setItem('withdrawal-lump-sum', JSON.stringify(formData));
    }
  }, [formData]);

  /**
   * Remove formData from sessionStorage when step = WITHDRAWAL_COMPLETE_STEP.
   */
  useEffect(() => {
    if (step === 4) {
      sessionStorage.removeItem('withdrawal-lump-sum');
    }
  }, [step]);

  useEffect(() => {
    return () => {
      dispatchWithdrawalRequestInProgress(false);
    };
  }, []);

  const showDefaultNextButton = step !== 4;
  const showDefaultBackButton = step !== 5;

  return (
    <BaseGrid initialSignupTheme tabletTwelveColumn messageWidget>
      <div className={styles.formFieldsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {showDefaultBackButton && !amendMode && (
          <ButtonLink
            secondary
            mid
            onClick={() => {
              dispatchBackClicked();
              setStep(step > 0 ? step - 1 : 0);
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(step === 0 && { to: '/withdraw-money-from-your-pension' })}
            label="← Back"
            wrapperStyles={{ margin: '0 0 30px' }}
          />
        )}
        {stepsMap[journey[step] || WITHDRAWAL_IMPORTANT_INFORMATION_STEP]}
        {showDefaultNextButton && (
          <Button
            disabled={validate()}
            wrapperStyles={{ margin: '0' }}
            label={setNextButtonLabel()}
            onClick={() => {
              if (step === 5) {
                dispatchBackToDashboardClicked();
              } else {
                dispatchNextClicked();
              }
              if (step === 2) {
                dispatchWithdrawalRequestInProgress(true);
              }
              if (journey[step + 1] === WITHDRAWAL_RESULTS_STEP) {
                onResultsStep();
              }
              setStep(amendMode ? 5 : Math.min(step + 1, setupSteps.length - 1));
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(step === 5 && { to: '/dashboard' })}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(step === 1
              && formData.withdrawalAmount > latestPensionValue * WITHDRAWAL_LUMP_SUM_PERCENTAGE_LIMIT
              && { to: '/withdrawals-we-need-to-speak-to-you' }
            )}
            forestSecondary={step === 2}
            center
            fullWidth
            greyDisabled
          />
        )}
      </div>
      {step === 4 && SHOW_MENTION_ME && (
        <WithMentionMe>
          <div className={styles.mentionMeContainer}>
            <div className={styles.mentionMeCard}>
              <MentionMeTile />
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div
                id="mmWrapper"
                className="setup-contribution-mm"
                onClick={() => dispatchMentionMeLinkClicked()}
              />
            </div>
          </div>
        </WithMentionMe>
      )}
      {step !== 4 && (
        <div className={styles.pensionAdviserContainer}>
          <div className={styles.pensionAdviserCard}>
            <ContactUsContent
              source={'SetupWithdrawalPage'}
              heading={`Hello, I’m ${get(pensionAdviser, 'firstName')}, your Adviser`}
              message="If you need help with this, please contact me."
              prefillSubject={'Withdrawal%20Request'}
            />
          </div>
        </div>
      )}
    </BaseGrid>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatchNextClicked: nextClickedAction,
  dispatchBackClicked: backClickedAction,
  dispatchBackToDashboardClicked: backToDashboardAction,
  dispatchMentionMeLinkClicked: referFriendClickedAction,
  dispatchWithdrawalRequestInProgress: withdrawalRequestInProgress,
  getProjection: (data) => dispatch(getProjectedIncome(data, {
    DISPATCHED: WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_DISPATCHED,
    FULFILLED: WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_FULFILLED,
    REJECTED: WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_REJECTED,
  })),
});
const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
  pensionAdviser: getPensionExpert(state),
  uid: getFirebaseUid(state),
  gender: getGender(state),
  currentAge: getAge(state),
  retirementAge: getIntendedRetirementAge(state),
  estimatedPensionSavings: getLatestPensionValue(state),
  portfolio: getModelDescriptor(state),
  verifiedNetPersonalContributions: getVerifiedNetPersonalContributions(state),
  verifiedGrossEmployerContributions: getVerifiedGrossEmployerContributions(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SetupWithdrawalPage));
