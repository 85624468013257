const demoTrustpilotData = {
  tpReviews: [
    {
      consumer: {
        displayName: 'Noah',
      },
      createdAt: '2023-11-16T13:29:44Z',
      stars: 5,
      text: 'The interface is easy to use and all communications are very clear. Transferring other pensions into the account was also straightforward.',
      title: 'The interface is easy to use and all…',
    },
    {
      consumer: {
        displayName: 'Evelyn Bates',
      },
      createdAt: '2023-11-15T00:52:25Z',
      stars: 5,
      text: 'When I transferred my old pension to\nMy new one they did all the work for me very good service',
      title: 'When I transferred my old pension to',
    },
    {
      consumer: {
        displayName: 'Karen Hunter',
      },
      createdAt: '2023-11-14T17:46:12Z',
      stars: 5,
      text: 'Excellent service ,everything explained efficiently, prompt replies to any questions asked,would definitely recommend',
      title: 'Excellent all round service',
    },
    {
      consumer: {
        displayName: 'Stephen Harkins',
      },
      createdAt: '2023-11-10T11:17:25Z',
      stars: 5,
      text: 'My experience with Profile Pensions thus far has been simple and straightforward. Communication and feedback works extremely well and l have been kept fully informed of all activities. I have already recommended the company to many others.',
      title: 'My experience with Profile Pensions…',
    },
    {
      consumer: {
        displayName: 'Jane Wallwork',
      },
      createdAt: '2023-11-05T13:26:22Z',
      stars: 5,
      text: 'Excellent advice and communication',
      title: 'Excellent advice and communication',
    },
    {
      consumer: {
        displayName: 'Andrew George',
      },
      createdAt: '2023-11-04T11:27:19Z',
      stars: 5,
      text: 'Just good communication',
      title: 'Just good communication',
    },
  ],
  tpBusinessUnits: {
    displayName: 'Profile Pensions',
    numberOfReviews: {
      fiveStars: 1993,
      fourStars: 306,
      oneStar: 54,
      threeStars: 36,
      total: 2404,
      twoStars: 15,
      usedForTrustScoreCalculation: 2336,
    },
    score: {
      stars: 4.5,
      trustScore: 4.6,
    },
    status: 'active',
  },
};

export default demoTrustpilotData;
