exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-Divider-divider-2BEyM {\n  border-top: 0.5px solid " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["borderGrey"] + ";\n  height: 0px;\n}", ""]);

// exports
exports.locals = {
	"colours": "'../../theme/colors.css'",
	"borderGrey": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["borderGrey"] + "",
	"divider": "-Divider-divider-2BEyM"
};