import WelcomeIcon from '../../assets/images/rebranding/introModal/intro-modal-welcome.svg';
import ImproveIncomeIcon from '../../assets/images/rebranding/introModal/intro-modal-improve-income.svg';
import PlanActivityIcon from '../../assets/images/rebranding/introModal/intro-modal-plan-activity.svg';
import PensionAdviserIcon from '../../assets/images/rebranding/introModal/intro-modal-pension-adviser.svg';

const introModalContent = (isNewCustomer) => [
  {
    heading: isNewCustomer ? 'Welcome to your dashboard!' : 'Welcome to your new dashboard!',
    body: 'Quickly see your balance and make sure your pension is on track for a brighter financial future',
    ctaLabel: 'Next',
    icon: WelcomeIcon,
  },
  {
    heading: 'Could you improve your retirement income?',
    body: 'Discover what your estimated pension income could look like and ways you may improve it using our new tool',
    ctaLabel: 'Next',
    icon: ImproveIncomeIcon,
  },
  {
    heading: 'Keep track of your plan activity',
    body: 'Easily check the status and history of your plan activity, including any old pensions you have transferred and your pension performance',
    ctaLabel: 'Next',
    icon: PlanActivityIcon,
  },
  {
    heading: 'Your dedicated Pension Adviser',
    body: 'Always on hand to help answer your questions, send a message directly to them via your dashboard or the main menu',
    ctaLabel: 'View my dashboard',
    icon: PensionAdviserIcon,
  },
];

export default introModalContent;
