import React from 'react';
import { DEFAULT_CONTACT_EMAIL } from '../../config';
import JourneyLayoutSidebar from './JourneyLayoutSidebar';
import JourneyLayoutSidebarItem from './JourneyLayoutSidebarItem';
import IconMail from '../../assets/images/rebranding/envelope.svg';
import LogoTitle from './LogoTitle';

const DefaultJourneyLayoutSidebar = () => (
  <JourneyLayoutSidebar>
    <LogoTitle title="Have a question?" />
    <JourneyLayoutSidebarItem icon={<img src={IconMail} alt="list icon" />} light>
      <p><a href={`mailto:${DEFAULT_CONTACT_EMAIL}`}>{'Email us'}</a></p>
    </JourneyLayoutSidebarItem>
  </JourneyLayoutSidebar>
);

export default DefaultJourneyLayoutSidebar;
