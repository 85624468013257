/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { Field } from 'formik';
import BasePage from '../../components/BasePage/BasePage';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Button from '../../components/Button/Button';
import '../../util/signupValidators';
import ContactNumberInformation from './ContactNumberInformation';
import AccountOwnerInformation from './AccountOwnerInformation';
import styles from './PersonalDetails.css';
import { sendGaPageEvent } from '../../util/googleAnalyticHelpers';
import UpdateAuthoritySignature from './UpdateAuthoritySignature';
import AddressContainer from './AddressContainer';
import { CURRENT_ADDRESS, PREVIOUS_ADDRESSES } from '../../util/constants';

const renderField = ({
  type,
  name,
  label,
  value,
  options,
  onChange,
  component,
  ...custom
}) => (
  <Field
    type={type}
    name={name}
    label={label}
    value={value}
    options={options}
    onChange={onChange}
    component={component}
    largeHeadings
    {...custom}
  />
);
class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { componentIsEditing: false };
    this.setComponentIsEditing = this.setComponentIsEditing.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    sendGaPageEvent('/personal-details');
  }

  setComponentIsEditing = async (componentIsEditing) => {
    await this.setState({
      componentIsEditing,
    });
  }

  render() {
    const { componentIsEditing } = this.state;
    return (
      <BasePage messageWidget>
        <div className={styles.returnButton}>
          <ButtonLink label="← Return to dashboard" to="/" />
        </div>
        <AccountOwnerInformation renderField={renderField} />
        <UpdateAuthoritySignature setComponentIsEditing={this.setComponentIsEditing} componentIsEditing={componentIsEditing} />
        <ContactNumberInformation renderField={renderField} setComponentIsEditing={this.setComponentIsEditing} componentIsEditing={componentIsEditing} />
        <AddressContainer type={CURRENT_ADDRESS} title={'Address'} renderField={renderField} setComponentIsEditing={this.setComponentIsEditing} componentIsEditing={componentIsEditing} />
        <AddressContainer type={PREVIOUS_ADDRESSES} title={'Previous Addresses'} renderField={renderField} setComponentIsEditing={this.setComponentIsEditing} componentIsEditing={componentIsEditing} />
        <div className={styles.container}>
          <h1 className={styles.heading}>
            {'Marketing Preferences'}
          </h1>
          <div className={styles.actionRow}>
            <Button
              label={'Update'}
              to={'/marketing-preferences'}
            />
          </div>
        </div>
      </BasePage>
    );
  }
}

export default PersonalDetails;
