export default {
  GB00BN08YZ75: {
    name: 'BlackRock iShares ESG Sterling Corporate Bond Index Fund',
    provider: 'Blackrock',
    displayName: 'BlackRock',
  },
  GB00BN08ZV03: {
    name: 'BlackRock iShares UK Equity ESG Index Fund',
    provider: 'Blackrock',
    displayName: 'BlackRock',
  },
  GB00BN090307: {
    name: 'BlackRock iShares Emerging Markets Equity ESG Index Fund',
    provider: 'Blackrock',
    displayName: 'BlackRock',
  },
  GB00BFK3MT61: {
    name: 'BlackRock Developed World Fossil Fuel Screened Fund (Excludes Fossil Fuels)',
    provider: 'Blackrock',
    displayName: 'BlackRock',
  },
  GB00BG0QNY41: {
    name: 'L&G All Stocks Index Linked Gilt Index Fund',
    provider: 'LegalGeneral',
    displayName: 'Legal & General',
  },
  GB00BG0QP489: {
    name: 'L&G Global Emerging Markets Index Fund',
    provider: 'LegalGeneral',
    displayName: 'Legal & General',
  },
  GB00BMJJJF91: {
    name: 'HSBC All World Index',
    provider: 'HSBC',
    displayName: 'HSBC',
  },
  GB00B83RVT96: {
    name: 'BlackRock iShares Index Linked Gilt Index (UK)',
    provider: 'Blackrock',
    displayName: 'BlackRock',
  },
  IE00B1S74Q32: {
    name: 'Vanguard UK Investment Grade Bond Index',
    provider: 'Vanguard',
    displayName: 'Vanguard',
  },
  GB00B7C44X99: {
    name: 'BlackRock iShares UK Equity Index Fund',
    provider: 'Blackrock',
    displayName: 'BlackRock',
  },
  GB00BJS8SJ34: {
    name: 'Fidelity Index World Fund',
    provider: 'Fidelity',
    displayName: 'Fidelity',
  },
  GB00B84DY642: {
    name: 'BlackRock iShares Emerging Markets Index Fund',
    provider: 'Blackrock',
    displayName: 'BlackRock',
  },
  GB00B41XG308: {
    name: 'Vanguard LifeStrategy 100%',
    provider: 'Vanguard',
    displayName: 'Vanguard',
  },
  GB00B4PQW151: {
    name: 'Vanguard LifeStrategy 80%',
    provider: 'Vanguard',
    displayName: 'Vanguard',
  },
  GB00B3TYHH97: {
    name: 'Vanguard LifeStrategy 60%',
    provider: 'Vanguard',
    displayName: 'Vanguard',
  },
  GB00B3ZHN960: {
    name: 'Vanguard LifeStrategy 40%',
    provider: 'Vanguard',
    displayName: 'Vanguard',
  },
  GB00B4NXY349: {
    name: 'Vanguard LifeStrategy 20%',
    provider: 'Vanguard',
    displayName: 'Vanguard',
  },
  IE00B76VTN11: {
    name: 'Vanguard ESG Developed World All Cap Equity Index Fund',
    provider: 'Vanguard',
    displayName: 'Vanguard',
  },
  IE00BKV0VZ05: {
    name: 'Vanguard ESG Emerging Markets All Cap Equity Index Fund',
    provider: 'Vanguard',
    displayName: 'Vanguard',
  },
  GB00BMFXWV25: {
    name: 'Legal & General Future World ESG UK Index Fund',
    provider: 'LegalGeneral',
    displayName: 'Legal & General',
  },
  GB00BS7K6H77: {
    name: 'Aegon Blackrock Aquila 30/70',
  },
  GB00B67BP573: {
    name: 'Aegon BlackRock 40/60 Global Equity Tracker',
  },
  GB00B3NGC102: {
    name: 'Aegon BlackRock 50/50 Equity and Bond Tracker',
  },
  GB00B65MNB44: {
    name: 'Aegon BlackRock 75/25 Equity and Bond Tracker',
  },
  GB00B83ZTD31: {
    name: 'Aviva Blackrock 50:50 Global Equity',
  },
  GB00B4ZF9G62: {
    name: 'Aviva Blackrock Consensus S14',
  },
  GB00B8HLW721: {
    name: 'Aviva Blackrock Corporate Bond Index Tracker',
  },
  GB00B8H3TX32: {
    name: 'Aviva Blackrock Pacific Rim Equity',
  },
  GB00B8BKB126: {
    name: 'Aviva Blackrock UK Equity Tracker',
  },
  GB00B8J54Q55: {
    name: 'Aviva Blackrock US Equity Tracker',
  },
  GB00B7FW3M29: {
    name: 'Aviva Deposit S14',
  },
  GB00B87WFZ48: {
    name: 'Aviva Gilt',
  },
  GB00B8825531: {
    name: 'Aviva Mixed Investment 0-35% Shares S14',
  },
  GB00B83DKX40: {
    name: 'Aviva Mixed Investment 20-60% Shares S14',
  },
  GB00B8BL4618: {
    name: 'Aviva Mixed Investment 40-85% Shares',
  },
  GB00B88YKM19: {
    name: '7IM AAP Balanced',
  },
  GB00B3STZ717: {
    name: 'Balanced Index',
  },
  GB00BQT3TG00: {
    name: 'Fidelity Index Europe Ex UK',
  },
  GB00BQT3TH17: {
    name: 'Fidelity Index Japans',
  },
  GB00BQT3TJ31: {
    name: 'Fidelity Index Pacific Ex Japan',
  },
  GB00BQT3TK46: {
    name: 'Fidelity Index UK',
  },
  GB00BQT3TL52: {
    name: 'Fidelity Index US',
  },
  GB00BN79MP91: {
    name: 'LV Flexible Guarantee Balanced S2',
  },
  GB00BN79MN77: {
    name: 'LV Flexible Guarantee Cautious S2',
  },
  GB00B7Y6JH20: {
    name: 'Vanguard Sterling Short-Term Money Market',
  },
  GB00B4NQVT82: {
    name: 'Threadneedle High Yield Bond',
  },
  GB00B79LS647: {
    name: 'Vanguard LifeStrategy 20%',
  },
  GB00B7M7M782: {
    name: 'Vanguard LifeStrategy 40%',
  },
  GB00B70DDK80: {
    name: 'Vanguard LifeStrategy 60%',
  },
  GB00B3TNSL75: {
    name: 'Vanguard LifeStrategy 80%',
  },
  GB00B58HY274: {
    name: 'Vanguard UK Govt Bond Index S2',
  },
  GB00B634MF92: {
    name: 'Vanguard UK Investment Grade Bond Index S2',
  },
  GB00B0GT4K86: {
    name: 'SW Adventurous Solution',
  },
  GB0031278327: {
    name: 'SW Balanced Portfolio',
  },
  GB0009393751: {
    name: 'SW Mixed',
  },
  GB0009370015: {
    name: 'SW North American',
  },
  GB00B51H5P45: {
    name: 'SW Pension Portfolio 1',
  },
  GB00B55VKH91: {
    name: 'SW Pension Portfolio 2',
  },
  GB00B58KT872: {
    name: 'SW Pension Portfolio 3',
  },
  GB00B597YZ99: {
    name: 'SW Pension Portfolio 4',
  },
  GB00BQJZTB16: {
    name: 'SW Pension Portfolio 5',
  },
  GB00B17FSL11: {
    name: 'SW Cash',
  },
  GB00B17K9681: {
    name: 'SW Pension Protector',
  },
  GB00B64XV036: {
    name: 'Aegon/Scottish Equitable plc Aegon AM Ethical Corporate Bond (ARC) Pn GTR in GB',
  },
  GB00BN79MQ09: {
    name: 'LV= Flexible Guarantee Managed Growth Pn S2 GTR in GB',
  },
  GB00B7GTPR10: {
    name: 'LV= Vanguard LifeStrategy 100% Equity Pn S2 GTR in GB',
  },
  GB00B3B5NB69: {
    name: 'MetLife Fidelity Cash Pn RP GTR in GB',
  },
  GB00B815RN15: {
    name: 'MetLife Managed Wealth Portfolio Foundation Pn GTR in GB',
  },
  GB00B17M8P68: {
    name: 'Scottish Widows Balanced Portfolio Pension Series 4 GTR in GB',
  },
  GB00B17M8Q75: {
    name: 'Scottish Widows Cautious Portfolio Pension Series 4 GTR in GB',
  },
  GB0009387035: {
    name: 'Scottish Widows Fixed Interest Pension Series 2 GTR in GB',
  },
  GB00B09CCX34: {
    name: 'Scottish Widows Pension Portfolio Four Pension Series 2 GTR in GB',
  },
  GB00B09CD306: {
    name: 'Scottish Widows Pension Portfolio Three Pension Series 2 GTR in GB',
  },
  GB00B09CD413: {
    name: 'Scottish Widows Pension Portfolio Three Pension Series 3 GTR in GB',
  },
  GB00BW4NYP11: {
    name: 'Scottish Widows Premier Pension Portfolio 1 Series 4 GTR in GB',
  },
  GB00BW4NYV70: {
    name: 'Scottish Widows Premier Pension Portfolio 2 Series 4 GTR in GB',
  },
  GB00B1KCLS79: {
    name: 'SW Fidelity Special Situations 2006 Pension Series 4 GTR in GB',
  },
  GB00B41F6L43: {
    name: 'Vanguard LifeStrategy 40% Equity A Inc TR in GB',
  },
  GB00BTFRCV38: {
    name: 'MetLife Blackrock Global Growth Fund',
  },
  GB00BTFRD102: {
    name: 'MetLife Fidelity Corporate Bond Fund',
  },
  GB00B6RR9K89: {
    name: 'MetLife Managed Wealth Portfolio - Fdn Secure Income Option',
  },
  GB00B8QXTB23: {
    name: 'MetLife Managed Wealth Portfolio - Fdn Secure Capital Option ',
  },
};
