import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { connect } from 'react-redux';
import PaperclipIcon from '../../assets/images/rebranding/paperclip.svg';
import ArrowIcon from '../../assets/images/icons/Icon_Documents_Download.svg';
import FileUploadWrapper from '../FileUploadWrapper/FileUploadWrapper';
import AttachmentFile from '../AttachmentFile/AttachmentFile';

import styles from './AddMessageAttachments.css';
import {
  ATTACHMENT_ACCEPTED_FILE_FORMATS, ATTACHMENT_PENDING, ATTACHMENT_REJECTED, FILE_INVALID_TYPE,
} from '../../util/constants';
import { convertExtensionToLowerCase } from '../../util/attachments';
import { attachmentAddButtonClickedAction, attachmentStatusPendingAction, attachmentStatusRejectedAction } from '../../redux/modules/conversations';
import sentryException from '../../util/sentryException';

const AddMessageAttachments = ({
  onAttachmentsChange,
  dispatchAttachmentAddButtonClickedAction,
  dispatchAttachmentStatusPendingAction,
  dispatchAttachmentStatusRejectedAction,
}) => {
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [onDrag, setOnDrag] = useState(false);

  const removeAcceptedFile = (index) => {
    const newAcceptedFileArray = [...attachedFiles];

    newAcceptedFileArray.splice(index, 1);
    setAttachedFiles(newAcceptedFileArray);
  };

  const appendFiles = (files) => {
    setAttachedFiles((existingFiles) => ([
      ...existingFiles,
      ...files.map((node) => {
        dispatchAttachmentStatusPendingAction();

        const {
          errors,
          name,
          file,
          type,
        } = node;
        const hasErrors = errors && errors.length > 0;
        const status = hasErrors ? ATTACHMENT_REJECTED : ATTACHMENT_PENDING;
        const filename = hasErrors ? file.name : name;
        const formattedFilename = convertExtensionToLowerCase(filename);

        if (hasErrors) {
          dispatchAttachmentStatusRejectedAction();
          const hasRejectedFile = errors.some(({ code }) => code === FILE_INVALID_TYPE);

          if (hasRejectedFile) {
            sentryException(new Error(`Unsupported file type on file ${file.name}`), {
              section: 'conversation-add-attachment-appendFiles',
              rejectedFileType: file.name.split('.').pop(),
            });
          }
        }

        return {
          file: new File([node], formattedFilename, { type }),
          createId: uniqid('account-hub-'),
          status,
          filename: formattedFilename,
          errors,
        };
      }),
    ]));
  };

  useEffect(() => {
    onAttachmentsChange(attachedFiles);
  }, [attachedFiles]);

  const setAcceptedAt = (accepted, filePath, index) => {
    const updatedAttachmentsList = [...attachedFiles];
    const attachment = { ...updatedAttachmentsList[index] };
    attachment.accepted = accepted;
    attachment.filePath = filePath;
    updatedAttachmentsList[index] = attachment;

    setAttachedFiles(updatedAttachmentsList);
  };

  const renderAttachments = () => {
    return attachedFiles.map(({
      file,
      createId,
      status,
      filename,
      errors,
    }, index) => {
      return (
        <AttachmentFile
          key={createId}
          createId={createId}
          name={filename}
          status={status}
          errors={errors}
          onAccepted={(accepted, filePath) => setAcceptedAt(accepted, filePath, index)}
          onRemoveHandler={() => removeAcceptedFile(index)}
          file={file}
        />
      );
    });
  };

  return (
    <div className={styles.attachmentsWrapper}>
      <FileUploadWrapper
        onFilesAccepted={appendFiles}
        onFilesRejected={appendFiles}
        acceptedFilesFormats={ATTACHMENT_ACCEPTED_FILE_FORMATS}
        maxFiles={1}
        maxSizeInMb={10}
        setOnDrag={setOnDrag}
      >

        <div
          onClick={dispatchAttachmentAddButtonClickedAction}
          role="button"
          tabIndex="0"
          onKeyUp={() => {}}
        >
          <div className={[styles.attachmentMobileView, styles.addAttachmentWrapper].join(' ')}>
            <img src={PaperclipIcon} alt="Attachment paperclip" />
            <p className={styles.addAttachmentCopy}>{'Add attachments'}</p>
          </div>
          <div className={[styles.attachmentDesktopView, styles.addAttachmentWrapper].join(' ')}>
            {onDrag ? (
              <img src={ArrowIcon} alt="Attachment arrow" className={styles.attachmentUploadArrow} />
            ) : (
              <img src={PaperclipIcon} alt="Attachment paperclip" />
            )}
            <p className={styles.addAttachmentCopy}>{onDrag ? 'Drop your files here' : 'Click here or drag files to add attachments'}</p>
          </div>
        </div>

      </FileUploadWrapper>

      {renderAttachments()}
    </div>
  );
};

AddMessageAttachments.propTypes = {
  onAttachmentsChange: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchAttachmentAddButtonClickedAction: attachmentAddButtonClickedAction,
  dispatchAttachmentStatusPendingAction: attachmentStatusPendingAction,
  dispatchAttachmentStatusRejectedAction: attachmentStatusRejectedAction,
};

export default connect(null, mapDispatchToProps)(AddMessageAttachments);
