import React from 'react';
import PropTypes from 'prop-types';

import styles from './RenderHyperlink.css';

function isLinkAnEmail(link) {
  return String(link)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
}

const RenderHyperlink = ({ link }) => {
  let href = '';

  if (link) {
    href = isLinkAnEmail(link)
      ? `mailto:${link}`
      : `//${link.replace(/(^\w+:|^)\/\//, '')}`;
  }

  return (
    <React.Fragment>
      {link && (
      <a
        href={href}
        onClick={(e) => e.stopPropagation()}
        target="_blank"
        className={styles.preWrapLink}
        rel="noreferrer"
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {link}
      </a>
      )}
    </React.Fragment>
  );
};

RenderHyperlink.propTypes = {
  link: PropTypes.string.isRequired,
};

export default RenderHyperlink;
