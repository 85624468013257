
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import JourneyLayout from '../../components/JourneyLayout/JourneyLayout';
import DefaultJourneyLayoutSidebar from '../../components/JourneyLayout/DefaultJourneyLayoutSidebar';
import {
  requestVerifyEmail,
} from '../../util/auth';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Button/Button';
import styles from './VerificationPage.css';
import * as actions from '../../redux/modules/eventDispatch';
import WithIntercom from '../../components/WithIntercom/WithIntercom';

const composePage = (children) => (
  <WithIntercom>
    <JourneyLayout sidebar={<DefaultJourneyLayoutSidebar />}>
      {children}
    </JourneyLayout>
  </WithIntercom>
);

class VerificationPage extends React.Component {
  async componentDidMount() {
    const { location, history, dispatchEventsWithPromise } = this.props;

    if (!location.search) {
      history.push('/');
    }

    const queryStringObject = queryString.parse(location.search);

    if (queryStringObject.ref) {
      await dispatchEventsWithPromise('VERIFY_EMAIL', async () => requestVerifyEmail(queryStringObject.ref));
    }
  }

  render() {
    const { isFetching, isEmailVerified, verifiedEmail } = this.props;

    if (isFetching) {
      return composePage(
        <div>
          <h3 className={styles.heading}>{'Verifying your email'}</h3>
          <Loader wrapperStyles={{ margin: '5em 0' }} />
        </div>,
      );
    }

    if (isEmailVerified) {
      return composePage(
        <div>
          <h3 className={styles.heading}>{'Thank you. Email verified.'}</h3>
          <div className={styles.text}>{`Your email address ${verifiedEmail ? `(${verifiedEmail})` : ''} has been verified.`}</div>
          <div className={styles.text}>{'Please use this email address when logging into your account.'}</div>
          <Link to="/">
            <Button
              wrapperStyles={{ margin: '2em auto' }}
              fullWidth
              label="Next"
              center
            />
          </Link>
        </div>,
      );
    }

    return (
      composePage(
        <div>
          <h3 className={styles.heading}>
            {'Something went wrong'}
          </h3>
          <p className={styles.text}>
            {'Your verification link may have expired.'}
          </p>
          <p className={styles.text}>
            <Link className={styles.link} to="/login">{'Click here'}</Link>
            {' to log in and request a new verification link by email.'}
          </p>
        </div>,
      )
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.auth.isFetching,
  isEmailVerified: state.auth.isEmailVerified,
  verifiedEmail: state.auth.verifiedEmail,
  auth: state.auth,
});

VerificationPage.propsTypes = {
  isFetching: PropTypes.bool,
  isEmailVerified: PropTypes.bool,
};

export default connect(mapStateToProps, actions)(VerificationPage);
