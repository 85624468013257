import get from 'lodash/get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Loader from '../../components/Loader/Loader';

import ConversationSection from '../ConversationSection/ConversationSection';
import SendMessageSection from '../SendMessageSection/SendMessageSection';
import WithCollections, { COLLECTIONS } from '../../components/WithCollections/WithCollections';
import FirstNameIcon from '../../components/InitialIcon/FirstNameIcon';
import InitialIcon from '../../components/InitialIcon/InitialIcon';
import styles from './InboxPage.css';
import { getConversationById, getConversationsByRead, getPendingConversations } from '../../redux/selectors/conversations';
import { getPensionExpert } from '../../redux/selectors/investmentAdvice';
import { conversationClicked, inboxPageLanded } from '../../redux/modules/conversations';
import formatDate from '../../util/formatDate';
import isMessageFromAdviser from '../../util/isMessageFromAdviser';
import informationIcon from '../../assets/images/icons/information-grey.svg';

const getParticipants = (messages, adviser, pending) => {
  if (pending) return ['You'];
  const participants = [];
  const adviserEmail = get(adviser, 'email');
  const adviserFirstName = get(adviser, 'firstName');
  const adviserLastName = get(adviser, 'lastName');
  const includesAdviser = messages.some((m) => isMessageFromAdviser(m, adviserEmail));
  const includesYou = messages.some((m) => m.fromUsername === 'account-hub');
  const includesProfile = messages.some((m) => !isMessageFromAdviser(m, adviserEmail) && m.fromUsername !== 'account-hub');

  if (includesAdviser) participants.push(`${adviserFirstName} ${adviserLastName[0]}`);
  if (includesYou) participants.push('You');
  if (includesProfile) participants.push('Profile Pensions');

  return participants;
};

const ConversationCard = ({
  conversation,
  pensionExpert,
  dispatchConversationClicked,
}) => {
  const {
    subject,
    pending,
    conversationId,
    messages,
  } = conversation;

  const participants = getParticipants(messages, pensionExpert, pending);
  const containsUnreadMessage = messages.some((m) => !pending && m.fromUsername !== 'account-hub' && !m.readDate);
  const participantsList = participants.join(', ');

  const getAdviserImageStyle = (size) => {
    if (size === 'l') return styles.adviserImageLarge;
    if (size === 'm') return styles.adviserImageMedium;
    return styles.adviserImageSmall;
  };

  const getIcon = (participant, size, wrapperStyles = {}) => {
    if (participant === 'You') return <FirstNameIcon size={size} wrapperStyles={wrapperStyles} />;
    if (participant === 'Profile Pensions') return <InitialIcon initial="P" size={size} wrapperStyles={wrapperStyles} />;
    return (
      <div style={wrapperStyles}>
        <img className={getAdviserImageStyle(size)} src={get(pensionExpert, 'imageUrl')} alt="Pension adviser" />
      </div>
    );
  };

  const getIconGroup = () => {
    if (pending) {
      return (
        <Loader
          wrapperStyles={{ margin: '70% auto' }}
        />
      );
    }
    if (participants.length === 1) {
      return (
        getIcon(participants[0], 'l')
      );
    }
    if (participants.length === 2) {
      return (
        <React.Fragment>
          {getIcon(participants[0], 's', { position: 'absolute', left: 0 })}
          {getIcon(participants[1], 's', { position: 'absolute', right: 0 })}
        </React.Fragment>
      );
    }
    if (participants.length === 3) {
      return (
        <React.Fragment>
          {getIcon(participants[0], 's', { position: 'absolute', left: 0 })}
          {getIcon(participants[1], 's', { position: 'absolute', left: '15px' })}
          {getIcon(participants[2], 's', { position: 'absolute', left: '25px' })}
        </React.Fragment>
      );
    }
    return (
      <div style={{
        height: '30px',
        width: '30px',
      }}
      />
    );
  };
  const latestMessage = get(messages, `${messages.length - 1}`);
  const latestMessageBody = get(latestMessage, 'messageBody');
  const latestMessageDate = get(latestMessage, 'createdDate');
  const cardContent = (
    <React.Fragment>
      <div className={styles.authorIconContainer}>
        {getIconGroup()}
      </div>
      <div className={styles.conversationCardInner}>
        <div className={styles.metadataBar}>
          <div className={styles.participantsContainer}>
            {participantsList && <div className={styles.participantsList}>{participantsList}</div>}
            {(containsUnreadMessage) && <div className={styles.unreadIcon} />}
          </div>
          {latestMessageDate && (
            <div className={styles.date}>
              {formatDate(latestMessageDate)}
            </div>
          )}
        </div>
        <h4 className={styles.subject}>{subject}</h4>
        <pre className={styles.latestMessageBody}>{latestMessageBody}</pre>
      </div>
    </React.Fragment>

  );
  if (pending) {
    return (
      <div className={styles.conversationCard}>
        {cardContent}
      </div>
    );
  }
  return (
    <Link className={styles.conversationCard} to={`/inbox/${conversationId}`} onClick={() => dispatchConversationClicked()}>
      {cardContent}
    </Link>
  );
};

const InboxPage = ({
  readConversations,
  unreadConversations,
  pendingConversations,
  pensionExpert,
  match,
  location,
  conversationFromParam,
  history,
  dispatchInboxPageLanded,
  dispatchConversationClicked,
}) => {
  useEffect(() => {
    dispatchInboxPageLanded();
  }, []);
  const [showMessageDisclaimer, setShowMessageDisclaimer] = useState(false);
  const [showConversationDisclaimer, setShowConversationDisclaimer] = useState(false);
  const activePanelParam = get(match, 'params.activePanel');

  const subjectFromParams = new URLSearchParams(location.search).get('subject');

  useEffect(() => {
    if (showConversationDisclaimer && activePanelParam) {
      setShowConversationDisclaimer(false);
    }
  }, [activePanelParam]);

  const conversationsList = [
    ...[...unreadConversations].reverse(),
    ...[...pendingConversations].reverse(),
    ...[...readConversations].reverse(),
  ];
  const newMessageView = activePanelParam === 'new-message';
  const conversationIdFromParam = newMessageView ? null : activePanelParam;

  if (conversationIdFromParam && !conversationFromParam) {
    history.push({ pathname: '/inbox' });
  }

  const selectedConversationView = Boolean(conversationIdFromParam && conversationFromParam);
  const selectedConversation = conversationFromParam;

  const renderConversationsList = conversationsList.map((c) => (
    <ConversationCard
      key={c.conversationId || c.subject}
      conversation={c}
      pensionExpert={pensionExpert}
      dispatchConversationClicked={dispatchConversationClicked}
    />
  ));

  const disclaimerRow = (
    <div key="disclaimer-row" className={styles.disclaimerRow}>
      <img className={styles.informationIcon} src={informationIcon} alt="Information" />
      <div className={styles.disclaimerMessage}>{'We aim to respond to all messages within 2 business days.'}</div>
    </div>
  );

  if (renderConversationsList.length && showConversationDisclaimer) {
    renderConversationsList.splice(1, 0, disclaimerRow);
  }

  return (
    <BaseGrid
      inboxTheme
      pageSectionStyles={{ paddingBottom: 0, overflow: 'visible' }}
    >
      <div
        className={
          classNames(styles.inboxWrapper, {
            [styles.selectedConversationView]: selectedConversationView || newMessageView,
          })
        }
      >
        <div
          className={
            classNames(
              styles.innerInboxWrapper,
              {
                [styles.twoPanelView]: selectedConversationView || newMessageView,
                [styles.onePanelView]: !(selectedConversationView || newMessageView),
              },
            )
          }
        >
          <div
            className={
              classNames(styles.dashboardLinkWrapper, {
                [styles.selectedConversationView]: selectedConversationView || newMessageView,
              })
            }
          >
            <ButtonLink variant="primary" label="← View my dashboard" to="/" />
          </div>
          <div className={activePanelParam ? styles.newMessageButtonContainer.selectedConversationView : styles.newMessageButtonContainer}>
            <Button
              size="medium"
              label="New Message"
              to="/inbox/new-message"
              disabled={newMessageView}
            />
          </div>
          <h1 className={styles.inboxHeading}>{unreadConversations.length ? `Inbox (${(unreadConversations.length)})` : 'Inbox'}</h1>
          <div className={styles.conversationsContainer}>
            {renderConversationsList.length ? (
              renderConversationsList
            ) : <div>{'You have no messages.'}</div>}
          </div>
        </div>
      </div>
      <div
        className={
          classNames(styles.secondPanelWrapper, {
            [styles.twoPanelView]: selectedConversationView || newMessageView,
          })
        }
      >
        {newMessageView && (
          <SendMessageSection
            subjectFromParams={subjectFromParams}
            setShowDisclaimer={setShowConversationDisclaimer}
          />
        )}
        {selectedConversationView && (
          <ConversationSection
            conversation={selectedConversation}
            selectedConversationView={selectedConversationView}
            showDisclaimer={showMessageDisclaimer}
            setShowDisclaimer={setShowMessageDisclaimer}
          />
        )}
      </div>
    </BaseGrid>
  );
};

const InboxPageWithCollections = (props) => (
  <WithCollections
    context="inbox-page"
    dependencies={[
      COLLECTIONS.USER,
      COLLECTIONS.CONVERSATIONS,
      COLLECTIONS.ONBOARDING,
      COLLECTIONS.ATTACHMENTS,
    ]}
  >
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <InboxPage {...props} />
  </WithCollections>
);

const mapStateToProps = (state, { match }) => {
  const { read, unread } = getConversationsByRead(state);
  const conversationIdFromParam = get(match, 'params.activePanel');
  return {
    readConversations: read,
    unreadConversations: unread,
    pendingConversations: getPendingConversations(state),
    pensionExpert: getPensionExpert(state),
    conversationFromParam: getConversationById(state, conversationIdFromParam),
  };
};

const mapDispatchToProps = ({
  dispatchInboxPageLanded: inboxPageLanded,
  dispatchConversationClicked: conversationClicked,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(InboxPageWithCollections);
