import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import moment from 'moment';
import { getPippaId } from './index';

export const getConversations = (state) => {
  const pippaId = getPippaId(state);
  const conversationsForUser = Object.values(get(state, 'firestore.data.conversations') || {})
    .filter((c) => get(c, 'customerId') === pippaId);
  return sortBy(
    conversationsForUser,
    (c) => get(c, `messages.${c.messages.length - 1}.createdDate`),
  );
};

export const getConversationsByRead = (state) => {
  const res = {
    unread: [],
    read: [],
  };
  const allConversations = getConversations(state);
  allConversations.forEach((c) => {
    const containsUnreadMessage = c.messages.some((m) => m.fromUsername !== 'account-hub' && !m.readDate);
    if (containsUnreadMessage) {
      res.unread.push(c);
    } else {
      res.read.push(c);
    }
  });
  return res;
};

export const getConversationById = (state, conversationId) => {
  if (!conversationId) return;
  const conversations = getConversations(state);
  const matchingConversation = conversations.find((conv) => (
    conv.conversationId === conversationId
  ));
  return matchingConversation;
};

const _hasPendingConversationBeenFulfilled = (fulfilledConversations, pendingConv) => {
  const isFulfilled = fulfilledConversations.find((fullfilledConv) => {
    const sameSubject = get(fullfilledConv, 'subject') === get(pendingConv, 'subject');
    const sameFirstMessageBody = get(fullfilledConv, 'messages.0.messageBody') === get(pendingConv, 'messages.0.messageBody');
    return sameSubject && sameFirstMessageBody;
  });
  return isFulfilled;
};

export const getPendingConversations = (state) => {
  const recentlySubmittedConversations = get(state, 'conversations.pendingConversations', []);
  const pendingConversations = recentlySubmittedConversations.filter((pendingConv) => {
    const fulfilledConversations = getConversations(state);
    return !_hasPendingConversationBeenFulfilled(fulfilledConversations, pendingConv);
  });
  return pendingConversations;
};

export const getOpenConversation = (state) => {
  const openConversationId = get(state, 'conversations.openConversationId');
  if (!openConversationId) return null;
  const conversations = getConversations(state);
  const openConversation = conversations.filter((c) => (c.conversationId === openConversationId));
  return openConversation;
};

export const getPendingMessagesOnConversation = (state, conversationId) => {
  const conversation = getConversationById(state, conversationId);
  if (!conversation) return [];
  const recentlySubmittedMessages = get(state, 'conversations.pendingMessages', []).filter((message) => message.conversationId === conversationId);
  const fulfilledMessages = get(conversation, 'messages') || [];
  if (!recentlySubmittedMessages.length) return [];
  const pendingMessages = recentlySubmittedMessages.filter((message) => {
    return fulfilledMessages.every(({ messageBody }) => messageBody !== message.messageBody);
  });
  return pendingMessages;
};

export const getAttachmentByCreatedId = (state, createId) => {
  const attachmentsForUser = Object.values(get(state, 'firestore.data.attachmentUploads') || {});
  const attachment = attachmentsForUser.find(
    (document) => document && document.createId === createId,
  );

  if (!attachment || attachment.passed === undefined) {
    return [];
  }

  const { passed, filePath } = attachment;

  return [passed, filePath];
};

export const getLatestConversation = (state) => {
  const allConversations = getConversations(state);
  if (allConversations && allConversations.length > 0) {
    return allConversations
      .sort((a, b) => moment(b.createdDate).diff(a.createdDate))[0];
  }
  return undefined;
};
