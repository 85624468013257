/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import pick from 'lodash/pick';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import sentryException from '../../util/sentryException';
import Button from '../../components/Button/Button';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import CheckBoxGreen from '../../components/CheckBoxGreen/CheckBoxGreen';

import ButtonLink from '../../components/ButtonLink/ButtonLink';
import { currencyString } from '../../util/currency';
import { PENSION_TRANSFER_KEY_FILES } from '../../util/constants';
import { getAuthoriseImplementPensionPlan } from '../../redux/selectors/investmentAdvice';
import LumpSumLumpSumImage from '../../assets/images/contributions/contributions-direct-debit.svg';
import {
  setupLumpSumAction,
  lumpSumSubmitStepVisitedAction,
  ongoingLumpSumSubmitStepVisitedAction,
  submitClickedAction,
  ongoingSubmitClickedAction,
  returnLinkClickedAction,
  ongoingReturnLinkClickedAction,
  changeLumpSumClickedAction,
  ongoingChangeLumpSumClickedAction,
  termsOfBusinessLinkClickedAction,
  ongoingTermsOfBusinessLinkClickedAction,
  lumpSumAndReliefTermsLinkClickedAction,
  ongoingLumpSumAndReliefTermsLinkClickedAction,
  authoriseLumpSumUnselectedAction,
  ongoingAuthoriseLumpSumUnselectedAction,
  authoriseLumpSumSelectedAction,
  ongoingAuthoriseLumpSumSelectedAction,
  submitErrorAction,
  ongoingSubmitErrorAction,
  taxReliefEligibleSelectedAction,
  ongoingTaxReliefEligibleSelectedAction,
  taxReliefEligibleUnselectedAction,
  ongoingTaxReliefEligibleUnselectedAction,
  authoriseImplementPensionPlanUnselectedAction,
  ongoingAuthoriseImplementPensionPlanUnselectedAction,
  authoriseImplementPensionPlanSelectedAction,
  ongoingAuthoriseImplementPensionPlanSelectedAction,
  recommendationReportLinkClickedAction,
  ongoingRecommendationReportLinkClickedAction,
  keyDetailsLinkClickedAction,
  ongoingKeyDetailsLinkClickedAction,
  illustrationLinkClickedAction,
  ongoingIllustrationLinkClickedAction,
} from '../../redux/modules/lumpSum';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import captureScreen from '../../util/captureScreenshot';
import captureHtml from '../../util/captureHtml';
import BaseGrid from '../../components/BaseGrid/BaseGrid';

import { TAX_RELIEF_URL } from '../../config';

import styles from './SetupLumpSumPage.css';

const formFieldsToSubmit = [
  'netAmount',
  'sourceOfFunds',
  'occupation',
  'salary',
  'authoriseArrangeLumpSum',
  'confirmTaxReliefEligibility',
  'authoriseImplementPensionPlan',
  'ongoing',
  'type',
];

const LumpSumSubmitStep = ({
  setFormData,
  formData,
  setStep,
  step,
  totalSteps,
  isCustomerOngoing,
  dispatchSetupLumpSum,
  dispatchLumpSumSubmitStepVisited,
  dispatchSubmitClicked,
  dispatchReturnLinkClicked,
  dispatchChangeLumpSumClicked,
  dispatchAuthoriseLumpSumSelected,
  dispatchAuthoriseLumpSumUnselected,
  dispatchSubmitError,
  dispatchLumpSumAndReliefTermsLinkClicked,
  dispatchTermsOfBusinessLinkClicked,
  investmentAdviceLetterUrl,
  dispatchTaxReliefEligibleSelectedAction,
  dispatchTaxReliefEligibleUnselectedAction,
  authoriseImplementPensionPlan,
  dispatchAuthoriseImplementPensionPlanSelected,
  dispatchAuthoriseImplementPensionPlanUnselected,
  dispatchRecommendationReportLinkClicked,
  dispatchKeyDetailsLinkClicked,
  dispatchIllustrationLinkClicked,
}) => {
  const disabled = !(
    authoriseImplementPensionPlan
    || isCustomerOngoing
    || formData.authoriseImplementPensionPlan
  )
    || !formData.confirmTaxReliefEligibility
    || !formData.authoriseArrangeLumpSum;

  const [submitError, setSubmitError] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  useEffect(() => {
    dispatchLumpSumSubmitStepVisited();
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = debounce(async (e) => {
    try {
      e.preventDefault();
      dispatchSubmitClicked();
      setSubmitError(false);

      let screenshot;
      try {
        screenshot = await captureScreen();
      } catch (error) {
        sentryException(error, {
          section: 'setup-contributions-capture-screen',
        });
        throw error;
      }

      const pageHtml = captureHtml();
      const request = {
        ...pick(formData, formFieldsToSubmit),
        screenshot,
        pageHtml,
      };
      setSubmitInProgress(true);
      await dispatchSetupLumpSum(request, isCustomerOngoing);
      setSubmitInProgress(false);
      setStep(step + 1);
    } catch (error) {
      setSubmitError(true);
      setSubmitInProgress(false);
      dispatchSubmitError();
      sentryException(error, {
        section: 'setup-contributions-submit',
      });
    }
  }, 3000, { leading: true, trailing: false });
  return (
    <BaseGrid pageSectionStyles={{ marginBottom: '200px' }} addPensionsTheme>
      <div className={styles.contributionsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          secondary
          mid
          onClick={() => {
            dispatchReturnLinkClicked();
            setStep(step - 1);
          }}
          label="← Back"
        />
        <div className={styles.imageContainer}>
          <img src={LumpSumLumpSumImage} alt="watering plants" />
        </div>
        <h2 className={styles.contributionsHeading}>{'One-off contribution confirmation'}</h2>
        <p className={styles.accountDetailsBody}>
          {'You will receive transfer details by secure message to complete your bank transfer.'}
          <br />
          <br />
          {'Please check the following details are correct before you continue.'}
        </p>
        <div className={styles.confirmation}>
          <div className={styles.confirmationHeading}>{'ONE-OFF PAYMENT AMOUNT'}</div>
          <div
            className={styles.contribution}
          >
            {currencyString(formData.netAmount, true)}
          </div>
          <br />
          <Button
            variant="secondary"
            size="large"
            onClick={() => {
              dispatchChangeLumpSumClicked();
              setStep(1);
            }}
            label="Amend contribution"
          />
        </div>
        <h3 className={styles.tickboxHeading}>{'Please read and confirm the following:'}</h3>
        <div className={styles.standardContainer}>
          {!authoriseImplementPensionPlan && !isCustomerOngoing && (
            <React.Fragment>
              <CheckBoxGreen
                name="authoriseImplementPensionPlan"
                disabled={!investmentAdviceLetterUrl}
                label={(
                  <span>
                    {'I authorise Profile Pensions to implement my Pension Plan. I have read and understand the '}
                    <a
                      className={styles.documentsDownloadLink}
                      href={investmentAdviceLetterUrl}
                      onClick={dispatchRecommendationReportLinkClicked}
                      download
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {'Recommendation'}
                    </a>
                    {', '}
                    <a
                      href={PENSION_TRANSFER_KEY_FILES.keyFeatures}
                      onClick={dispatchKeyDetailsLinkClicked}
                      download
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {'Key Features'}
                    </a>
                    {' and '}
                    <a
                      href={PENSION_TRANSFER_KEY_FILES.illustration}
                      onClick={dispatchIllustrationLinkClicked}
                      download
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {'Illustration'}
                    </a>
                    {'.'}
                  </span>
                    )}
                value={formData.authoriseImplementPensionPlan}
                defaultValue={false}
                onChange={() => {
                  if (formData.authoriseImplementPensionPlan) {
                    dispatchAuthoriseImplementPensionPlanUnselected();
                  } else {
                    dispatchAuthoriseImplementPensionPlanSelected();
                  }
                  setFormData({
                    ...formData,
                    authoriseImplementPensionPlan: !formData.authoriseImplementPensionPlan,
                  });
                }}
                wrapperStyles={{ marginBottom: '13px' }}
              />
              <div className={styles.divider} />
            </React.Fragment>
          )}
        </div>
        {(investmentAdviceLetterUrl || isCustomerOngoing) ? (
          <div className={styles.standardContainer}>
            <CheckBoxGreen
              name="confirmTaxReliefEligibility"
              label={(
                <span>
                  {'I confirm that I am eligible for tax relief on my pension contributions and I have read and understand the '}
                  <a
                    href={TAX_RELIEF_URL}
                    onClick={dispatchLumpSumAndReliefTermsLinkClicked}
                    download
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {'Pension Contribution and Tax Relief'}
                  </a>
                  {' terms.'}
                </span>
                  )}
              value={formData.confirmTaxReliefEligibility}
              defaultValue={false}
              onChange={() => {
                if (formData.confirmTaxReliefEligibility) {
                  dispatchTaxReliefEligibleUnselectedAction();
                } else {
                  dispatchTaxReliefEligibleSelectedAction();
                }

                setFormData({
                  ...formData,
                  confirmTaxReliefEligibility: !formData.confirmTaxReliefEligibility,
                });
              }}
              wrapperStyles={{ marginBottom: '13px' }}
            />
            <div className={styles.divider} />
            <CheckBoxGreen
              name="authoriseArrangeLumpSum"
              label={(
                <span>
                  {'I have received, understood and agree to the '}
                  <a
                    href={PENSION_TRANSFER_KEY_FILES.termsOfBusiness}
                    onClick={dispatchTermsOfBusinessLinkClicked}
                    download
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {'Terms of Business'}
                  </a>
                  {'.'}
                </span>
                )}
              value={formData.authoriseArrangeLumpSum}
              defaultValue={false}
              onChange={() => {
                if (formData.authoriseArrangeLumpSum) {
                  dispatchAuthoriseLumpSumUnselected();
                } else {
                  dispatchAuthoriseLumpSumSelected();
                }

                setFormData({
                  ...formData,
                  authoriseArrangeLumpSum: !formData.authoriseArrangeLumpSum,
                });
              }}
            />
          </div>
        ) : (
          <div className={styles.disclaimerTextContainer}>
            <p className={styles.disclaimerText}>{'Loading recommendation documents, please wait...'}</p>
          </div>
        )}
        {submitError && <p className={styles.submitError}>{'Something went wrong - please try again'}</p>}
        <div className={styles.standardCOntainer}>
          <Button
            size="large"
            disabled={disabled}
            onClick={onSubmit}
            label="Complete setup"
            loading={submitInProgress}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
  authoriseImplementPensionPlan: getAuthoriseImplementPensionPlan(state),
});

const mapDispatchToProps = {
  dispatchSetupLumpSum: setupLumpSumAction,
  _dispatchOnboardingLumpSumSubmitStepVisited: lumpSumSubmitStepVisitedAction,
  _dispatchOngoingLumpSumSubmitStepVisited: ongoingLumpSumSubmitStepVisitedAction,
  _dispatchOnboardingSubmitClicked: submitClickedAction,
  _dispatchOngoingSubmitClicked: ongoingSubmitClickedAction,
  _dispatchOnboardingReturnLinkClicked: returnLinkClickedAction,
  _dispatchOngoingReturnLinkClicked: ongoingReturnLinkClickedAction,
  _dispatchOnboardingChangeLumpSumClicked: changeLumpSumClickedAction,
  _dispatchOngoingChangeLumpSumClicked: ongoingChangeLumpSumClickedAction,
  _dispatchOnboardingAuthoriseLumpSumUnselected: authoriseLumpSumUnselectedAction,
  _dispatchOngoingAuthoriseLumpSumUnselected: ongoingAuthoriseLumpSumUnselectedAction,
  _dispatchOnboardingAuthoriseLumpSumSelected: authoriseLumpSumSelectedAction,
  _dispatchOngoingAuthoriseLumpSumSelected: ongoingAuthoriseLumpSumSelectedAction,
  _dispatchOnboardingSubmitError: submitErrorAction,
  _dispatchOngoingSubmitError: ongoingSubmitErrorAction,
  _dispatchOnboardingLumpSumAndReliefTermsLinkClicked: lumpSumAndReliefTermsLinkClickedAction,
  _dispatchOngoingLumpSumAndReliefTermsLinkClicked: ongoingLumpSumAndReliefTermsLinkClickedAction,
  _dispatchOnboardingTermsOfBusinessLinkClicked: termsOfBusinessLinkClickedAction,
  _dispatchOngoingTermsOfBusinessLinkClicked: ongoingTermsOfBusinessLinkClickedAction,
  _dispatchOngoingTaxReliefEligibleSelectedAction: ongoingTaxReliefEligibleSelectedAction,
  _dispatchOnboardingTaxReliefEligibleSelectedAction: taxReliefEligibleSelectedAction,
  _dispatchOngoingTaxReliefEligibleUnselectedAction: ongoingTaxReliefEligibleUnselectedAction,
  _dispatchOnboardingTaxReliefEligibleUnselectedAction: taxReliefEligibleUnselectedAction,
  _dispatchOnboardingAuthoriseImplementPensionPlanUnselected: authoriseImplementPensionPlanUnselectedAction,
  _dispatchOngoingAuthoriseImplementPensionPlanUnselected: ongoingAuthoriseImplementPensionPlanUnselectedAction,
  _dispatchOnboardingAuthoriseImplementPensionPlanSelected: authoriseImplementPensionPlanSelectedAction,
  _dispatchOngoingAuthoriseImplementPensionPlanSelected: ongoingAuthoriseImplementPensionPlanSelectedAction,
  _dispatchOnboardingIllustrationLinkClicked: illustrationLinkClickedAction,
  _dispatchOngoingIllustrationLinkClicked: ongoingIllustrationLinkClickedAction,
  _dispatchOnboardingRecommendationReportLinkClicked: recommendationReportLinkClickedAction,
  _dispatchOngoingRecommendationReportLinkClicked: ongoingRecommendationReportLinkClickedAction,
  _dispatchOnboardingKeyDetailsLinkClicked: keyDetailsLinkClickedAction,
  _dispatchOngoingKeyDetailsLinkClicked: ongoingKeyDetailsLinkClickedAction,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isCustomerOngoing } = stateProps;
  const {
    _dispatchOnboardingLumpSumSubmitStepVisited,
    _dispatchOngoingLumpSumSubmitStepVisited,
    _dispatchOnboardingSubmitClicked,
    _dispatchOngoingSubmitClicked,
    _dispatchOnboardingReturnLinkClicked,
    _dispatchOngoingReturnLinkClicked,
    _dispatchOnboardingChangeLumpSumClicked,
    _dispatchOngoingChangeLumpSumClicked,
    _dispatchOnboardingAuthoriseLumpSumSelected,
    _dispatchOngoingAuthoriseLumpSumUnselected,
    _dispatchOnboardingAuthoriseLumpSumUnselected,
    _dispatchOngoingAuthoriseLumpSumSelected,
    _dispatchOnboardingSubmitError,
    _dispatchOngoingSubmitError,
    _dispatchOnboardingLumpSumAndReliefTermsLinkClicked,
    _dispatchOngoingLumpSumAndReliefTermsLinkClicked,
    _dispatchOnboardingTermsOfBusinessLinkClicked,
    _dispatchOngoingTermsOfBusinessLinkClicked,
    _dispatchOngoingTaxReliefEligibleSelectedAction,
    _dispatchOnboardingTaxReliefEligibleSelectedAction,
    _dispatchOngoingTaxReliefEligibleUnselectedAction,
    _dispatchOnboardingTaxReliefEligibleUnselectedAction,
    _dispatchOnboardingAuthoriseImplementPensionPlanUnselected,
    _dispatchOngoingAuthoriseImplementPensionPlanUnselected,
    _dispatchOnboardingAuthoriseImplementPensionPlanSelected,
    _dispatchOngoingAuthoriseImplementPensionPlanSelected,
    _dispatchOngoingRecommendationReportLinkClicked,
    _dispatchOnboardingRecommendationReportLinkClicked,
    _dispatchOngoingKeyDetailsLinkClicked,
    _dispatchOnboardingKeyDetailsLinkClicked,
    _dispatchOngoingIllustrationLinkClicked,
    _dispatchOnboardingIllustrationLinkClicked,
  } = dispatchProps;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    dispatchLumpSumSubmitStepVisited: isCustomerOngoing ? _dispatchOngoingLumpSumSubmitStepVisited : _dispatchOnboardingLumpSumSubmitStepVisited,
    dispatchSubmitClicked: isCustomerOngoing ? _dispatchOngoingSubmitClicked : _dispatchOnboardingSubmitClicked,
    dispatchReturnLinkClicked: isCustomerOngoing ? _dispatchOngoingReturnLinkClicked : _dispatchOnboardingReturnLinkClicked,
    dispatchChangeLumpSumClicked: isCustomerOngoing ? _dispatchOngoingChangeLumpSumClicked : _dispatchOnboardingChangeLumpSumClicked,
    dispatchAuthoriseLumpSumUnselected: isCustomerOngoing ? _dispatchOngoingAuthoriseLumpSumUnselected : _dispatchOnboardingAuthoriseLumpSumUnselected,
    dispatchAuthoriseLumpSumSelected: isCustomerOngoing ? _dispatchOngoingAuthoriseLumpSumSelected : _dispatchOnboardingAuthoriseLumpSumSelected,
    dispatchTaxReliefEligibleSelectedAction: isCustomerOngoing ? _dispatchOngoingTaxReliefEligibleSelectedAction : _dispatchOnboardingTaxReliefEligibleSelectedAction,
    dispatchTaxReliefEligibleUnselectedAction: isCustomerOngoing ? _dispatchOngoingTaxReliefEligibleUnselectedAction : _dispatchOnboardingTaxReliefEligibleUnselectedAction,
    dispatchSubmitError: isCustomerOngoing ? _dispatchOngoingSubmitError : _dispatchOnboardingSubmitError,
    dispatchLumpSumAndReliefTermsLinkClicked: isCustomerOngoing ? _dispatchOngoingLumpSumAndReliefTermsLinkClicked : _dispatchOnboardingLumpSumAndReliefTermsLinkClicked,
    dispatchTermsOfBusinessLinkClicked: isCustomerOngoing ? _dispatchOngoingTermsOfBusinessLinkClicked : _dispatchOnboardingTermsOfBusinessLinkClicked,
    dispatchAuthoriseImplementPensionPlanUnselected: isCustomerOngoing ? _dispatchOngoingAuthoriseImplementPensionPlanUnselected : _dispatchOnboardingAuthoriseImplementPensionPlanUnselected,
    dispatchAuthoriseImplementPensionPlanSelected: isCustomerOngoing ? _dispatchOngoingAuthoriseImplementPensionPlanSelected : _dispatchOnboardingAuthoriseImplementPensionPlanSelected,
    dispatchRecommendationReportLinkClicked: isCustomerOngoing ? _dispatchOngoingRecommendationReportLinkClicked : _dispatchOnboardingRecommendationReportLinkClicked,
    dispatchKeyDetailsLinkClicked: isCustomerOngoing ? _dispatchOngoingKeyDetailsLinkClicked : _dispatchOnboardingKeyDetailsLinkClicked,
    dispatchIllustrationLinkClicked: isCustomerOngoing ? _dispatchOngoingIllustrationLinkClicked : _dispatchOnboardingIllustrationLinkClicked,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LumpSumSubmitStep);
