import React from 'react';
import PropTypes from 'prop-types';
import NoAdviserAssigned from '../../assets/images/cds/no-advisor-assigned.svg';

const DedicatedAdviserImageSvgComponent = ({ image, className }) => {
  return (
    <React.Fragment>
      <svg className={className} viewBox="0 0 169 169" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <rect x="0.916016" y="0.867188" width="168" height="168" rx="84" fill="#FEF2EC" />
        <mask id="mask0_27363_36519" style={{ 'mask-type': 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="169" height="169">
          <rect x="0.916016" y="0.867188" width="168" height="168" rx="84" fill="#FEF2EC" />
        </mask>
        <g mask="url(#mask0_27363_36519)">
          <rect x="52.0039" y="31.4609" width="73.2326" height="109.849" transform="rotate(-2.74533 52.0039 31.4609)" fill="#125A42" />
          <rect x="58.7012" y="37.543" width="60.7902" height="83.1867" transform="rotate(-2.74533 58.7012 37.543)" fill="#EEFFEE" />
          <path d="M38.5083 148.322C35.8566 127.885 36.7861 112.672 50.456 92.4703C57.9361 96.7999 55.3953 104.265 53.6469 109.402L53.6094 109.512C51.8605 114.651 52.5826 122.707 56.447 126.905C60.3113 131.104 62.9422 138.565 63.1558 140.69L86.4256 139.583C79.3709 164.87 67.6917 151.38 57.5407 171.072L34.0082 159.165L38.5083 148.322Z" fill="#FFBB9E" />
          <circle cx="107.599" cy="73.7997" r="32.3173" fill="white" />
          <circle cx="107.408" cy="73.6106" r="31.1399" fill="#FAFAFA" />
          <ellipse cx="107.155" cy="65.0591" rx="9.74561" ry="9.74561" transform="rotate(180 107.155 65.0591)" fill="#92DE63" />
          <path d="M124.208 91.8613C124.208 82.4422 116.572 74.8065 107.153 74.8065C97.7341 74.8065 90.0984 82.4422 90.0984 91.8613L124.208 91.8613Z" fill="#169B62" />
          <mask id="mask1_27363_36519" style={{ 'mask-type': 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="169" height="169">
            <rect x="149.248" y="-15" width="168.048" height="168.048" rx="84.0239" transform="rotate(77.7921 149.248 -15)" fill="#FEF2EC" />
          </mask>
          <g mask="url(#mask1_27363_36519)">
            <circle cx="106.691" cy="-1.15943" r="24.5287" transform="rotate(77.7921 106.691 -1.15943)" fill="#FFE1D6" />
            <circle cx="1.51552" cy="108.563" r="24.5287" transform="rotate(77.7921 1.51552 108.563)" fill="#FFE1D6" />
            <circle cx="168.813" cy="120.456" r="24.5287" transform="rotate(77.7921 168.813 120.456)" fill="#FFE1D6" />
          </g>
          <circle cx="107.717" cy="73.717" r="32.717" fill="white" />
          <mask id="mask2_27363_36519" style={{ 'mask-type': 'alpha' }} maskUnits="userSpaceOnUse" x="76" y="42" width="64" height="64">
            <circle cx="107.713" cy="73.7128" r="31.5273" fill="#C4C4C4" />
          </mask>
          <g mask="url(#mask2_27363_36519)">
            <rect x="44.6602" y="41.5547" width="109.715" height="73.1433" fill="url(#pattern0)" />
          </g>
          <path d="M81.7014 57.1171L82.3555 70.0527L68.7657 57.7711L81.7014 57.1171Z" fill="#92DE63" />
          <rect x="38.7305" y="28.166" width="16.4061" height="16.4061" transform="rotate(-2.89459 38.7305 28.166)" fill="#169B62" />
          <rect x="55.1182" y="27.3359" width="16.4061" height="16.4061" transform="rotate(-2.89459 55.1182 27.3359)" fill="#169B62" />
          <rect x="71.5049" y="26.5078" width="16.4061" height="16.4061" transform="rotate(-2.89459 71.5049 26.5078)" fill="#169B62" />
          <rect x="39.5605" y="44.5488" width="16.4061" height="16.4061" transform="rotate(-2.89459 39.5605 44.5488)" fill="#169B62" />
          <rect x="55.9453" y="43.7227" width="16.4061" height="16.4061" transform="rotate(-2.89459 55.9453 43.7227)" fill="#169B62" />
          <rect x="72.333" y="42.8926" width="16.4061" height="16.4061" transform="rotate(-2.89459 72.333 42.8926)" fill="#169B62" />
          <circle r="3.88566" transform="matrix(-0.998724 0.0504987 0.0504987 0.998724 74.5108 43.2156)" fill="#EEFFEE" />
          <circle r="3.88566" transform="matrix(-0.998724 0.0504987 0.0504987 0.998724 64.1593 43.741)" fill="#EEFFEE" />
          <circle r="3.88566" transform="matrix(-0.998724 0.0504987 0.0504987 0.998724 53.8155 44.2644)" fill="#EEFFEE" />
        </g>
        <defs>
          <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image0_27363_36519" transform="scale(0.000666667 0.001)" />
          </pattern>
          <image id="image0_27363_36519" width="1800" height="900" xlinkHref={image} />
        </defs>
      </svg>

    </React.Fragment>
  );
};

DedicatedAdviserImageSvgComponent.propTypes = {
  image: PropTypes.string,
  className: PropTypes.string,
};

DedicatedAdviserImageSvgComponent.defaultProps = {
  image: NoAdviserAssigned,
  className: '',
};

export default DedicatedAdviserImageSvgComponent;
