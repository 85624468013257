/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import get from 'lodash/get';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GENERIC_ERROR } from '../../forms/copyTexts';
import { getUser } from '../../redux/selectors';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ServiceAlertCard from '../../components/AlertCard/ServiceAlertCard';
import UpdateNationalInsuranceNumber from './UpdateNationalInsuranceNumber';

import { setFirstLetterUppercase } from '../../util/textTransform';
import '../../util/signupValidators';
import styles from './PersonalDetails.css';

class AccountOwnerInformation extends Component {
    /* eslint consistent-return: 0 */
    constructErrorMessage = (data) => {
      if (!data) {
        return;
      }
      return (
        <div>
          {GENERIC_ERROR}
          {this.constructEmailUsElement()}
        </div>
      );
    }

    render() {
      const {
        user,
        renderField,
      } = this.props;

      const values = {
        email: get(user, 'contact.email'),
        salutation: get(user, 'personal.name.title'),
        firstName: get(user, 'personal.name.first'),
        lastName: get(user, 'personal.name.last'),
        nationalInsuranceNumber: get(user, 'personal.nationalInsuranceNumber', ''),
      };

      return (
        <div>
          <div className={styles.container}>
            <h1 className={styles.heading}>
              {'Account Owner'}
            </h1>
            <h1 className={styles.content}>
              {[setFirstLetterUppercase(values.salutation), values.firstName, values.lastName].filter((s) => s).join(' ')}
            </h1>
            <UpdateNationalInsuranceNumber renderField={renderField} nationalInsuranceNumber={values.nationalInsuranceNumber} />
            <ServiceAlertCard
              alertDescription={(
                <span>
                  {'If you want to change account owner details, please '}
                  <ButtonLink
                    variant="primary"
                    font="inherit"
                    label="message us"
                    to="/inbox/new-message"
                  />
                  {'.'}
                </span>
            )}
              alertSeverity="warning"
              icon="warningCircle"
              detached
            />
          </div>
        </div>
      );
    }
}

const mapStateToProps = (state) => ({
  user: getUser(state),
});

export default connect(
  mapStateToProps,
)(AccountOwnerInformation);
