/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import get from 'lodash/get';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import NationalInsuranceNumber from './NationalInsuranceNumberStep';
import '../../util/signupValidators';
import {
  getHasOnlyInProgressContributionInstruction, getHasInProgressLumpSumInstructions,
} from '../../redux/selectors/investmentAdvice';
import {
  missingDetailsPageLoaded,
} from '../../redux/modules/signupFlow';
import MissingDetailsSignature from './SignatureStep';
import {
  CURRENT_ADDRESS_STEP,
  ADDRESS_HISTORY_STEP,
  NATIONAL_INSURANCE_NUMBER_STEP,
  SIGNATURE_STEP, MOBILE_NUMBER_STEP,
  THANK_YOU_STEP,
  ADD_A_PREVIOUS_ADDRESS_STEP,
  CONFIRM_ADDRESS_STEP,
} from './missingDetailsFlow';
import MobilePhoneNumberStep from './MobilePhoneNumberStep';
import AddressHistoryStep from './AddressHistoryStep';
import AddAPreviousAddressStep from './AddAPreviousAddressStep';
import ConfirmAddressStep from './ConfirmAddressStep';
import ThankYou from '../ThankYouPage/ThankYouPage';
import CurrentAddressStep from './CurrentAddressStep';

const MissingDetailsPage = ({
  flow,
  stepWithRequiredFields,
  setStep: parentSetStep,
  step: parentStep,
  hasInProgressLumpSumInstructions,
  hasOnlyInProgressContributionInstructions,
}) => {
  const initialState = {
    nationalInsuranceNumber: '',
    signature: undefined,
    mobileNumber: '',
    address: '',
    updatedAddress: undefined,
    currentAddress: undefined,
    previousAddresses: [],
  };

  const [journey, setJourney] = useState(stepWithRequiredFields);
  const [formData, setFormData] = useState(initialState);
  const [timeFrom, setTimeFrom] = useState(null);
  const [timeTo, setTimeTo] = useState(null);
  const [componentIsEditing, setComponentIsEditing] = useState(false);
  const [editPreviousAddress, setEditPreviousAddress] = useState(false);
  const [datesCheckBoxClicked, setDatesCheckBoxClicked] = useState(false);
  const [inEditMode, setInEditMode] = useState(false);
  const [editingComponent, setEditingComponent] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [fieldValue, setFieldValue] = useState('');
  const [step, setStepValue] = useState(0);
  const [amendMode, setAmendMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorText, setErrorText] = useState(false);

  const totalSteps = journey.length;
  const setStep = (newStepArg) => {
    const newStepValue = typeof newStepArg === 'function' ? newStepArg(step) : newStepArg;
    setStepValue(newStepValue);
  };

  const resetFormData = () => setFormData(initialState);

  const stepsMap = {
    [NATIONAL_INSURANCE_NUMBER_STEP]: (
      <NationalInsuranceNumber
        setStep={setStep}
        step={step}
        totalSteps={totalSteps}
        formData={formData}
        setFormData={setFormData}
        amendMode={amendMode}
        setAmendMode={setAmendMode}
        isSubmitLoading={isSubmitLoading}
        setIsSubmitLoading={setIsSubmitLoading}
        flow={flow}
        hasOnlyInProgressContributionInstructions={hasOnlyInProgressContributionInstructions}
        hasInProgressLumpSumInstructions={hasInProgressLumpSumInstructions}
      />
    ),
    [SIGNATURE_STEP]: (
      <MissingDetailsSignature
        step={step}
        totalSteps={totalSteps}
        setStep={setStep}
        formData={formData}
        setFormData={setFormData}
        amendMode={amendMode}
        setAmendMode={setAmendMode}
        setIsSubmitLoading={setIsSubmitLoading}

      />
    ),
    [MOBILE_NUMBER_STEP]: (
      <MobilePhoneNumberStep
        step={step}
        totalSteps={totalSteps}
        setStep={setStep}
        formData={formData}
        setFormData={setFormData}
        amendMode={amendMode}
        setAmendMode={setAmendMode}
        isSubmitLoading={isSubmitLoading}
        setIsSubmitLoading={setIsSubmitLoading}
        hasOnlyInProgressContributionInstructions={hasOnlyInProgressContributionInstructions}
        hasInProgressLumpSumInstructions={hasInProgressLumpSumInstructions}
        flow={flow}
      />
    ),
    [CURRENT_ADDRESS_STEP]: (
      <CurrentAddressStep
        step={step}
        totalSteps={totalSteps}
        setStep={setStep}
        formData={formData}
        setFormData={setFormData}
        fieldValue={fieldValue}
        setFieldValue={setFieldValue}
        amendMode={amendMode}
        setAmendMode={setAmendMode}
        resetFormData={resetFormData}
        isSubmitLoading={isSubmitLoading}
        setIsSubmitLoading={setIsSubmitLoading}
        inEditMode={inEditMode}
        setInEditMode={setInEditMode}
        editingComponent={editingComponent}
        setEditingComponent={setEditingComponent}
        editPreviousAddress={editPreviousAddress}
        setEditPreviousAddress={setEditPreviousAddress}
        hasOnlyInProgressContributionInstructions={hasOnlyInProgressContributionInstructions}
        hasInProgressLumpSumInstructions={hasInProgressLumpSumInstructions}
        flow={flow}
      />
    ),
    [ADDRESS_HISTORY_STEP]: (
      <AddressHistoryStep
        step={step}
        totalSteps={totalSteps}
        setStep={setStep}
        formData={formData}
        setFormData={setFormData}
        amendMode={amendMode}
        setAmendMode={setAmendMode}
        resetFormData={resetFormData}
        datesCheckBoxClicked={datesCheckBoxClicked}
        setDatesCheckBoxClicked={setDatesCheckBoxClicked}
        isSubmitLoading={isSubmitLoading}
        setIsSubmitLoading={setIsSubmitLoading}
        hasOnlyInProgressContributionInstructions={hasOnlyInProgressContributionInstructions}
        hasInProgressLumpSumInstructions={hasInProgressLumpSumInstructions}
        flow={flow}
      />
    ),
    [ADD_A_PREVIOUS_ADDRESS_STEP]: (
      <AddAPreviousAddressStep
        step={step}
        totalSteps={totalSteps}
        setStep={setStep}
        formData={formData}
        setFormData={setFormData}
        fieldValue={fieldValue}
        setFieldValue={setFieldValue}
        amendMode={amendMode}
        setAmendMode={setAmendMode}
        resetFormData={resetFormData}
        inEditMode={inEditMode}
        setInEditMode={setInEditMode}
        editingComponent={editingComponent}
        setEditingComponent={setEditingComponent}
        editPreviousAddress={editPreviousAddress}
        setEditPreviousAddress={setEditPreviousAddress}
      />
    ),
    [CONFIRM_ADDRESS_STEP]: (
      <ConfirmAddressStep
        step={step}
        totalSteps={totalSteps}
        setStep={setStep}
        formData={formData}
        setFormData={setFormData}
        setFieldValue={setFieldValue}
        amendMode={amendMode}
        setAmendMode={setAmendMode}
        resetFormData={resetFormData}
        componentIsEditing={componentIsEditing}
        setComponentIsEditing={setComponentIsEditing}
        editPreviousAddress={editPreviousAddress}
        setEditPreviousAddress={setEditPreviousAddress}
        datesCheckBoxClicked={datesCheckBoxClicked}
        setDatesCheckBoxClicked={setDatesCheckBoxClicked}
        showFields={showFields}
        setShowFields={setShowFields}
        timeFrom={timeFrom}
        setTimeFrom={setTimeFrom}
        timeTo={timeTo}
        setTimeTo={setTimeTo}
        errorText={errorText}
        setErrorText={setErrorText}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        isSubmitLoading={isSubmitLoading}
        setIsSubmitLoading={setIsSubmitLoading}
      />
    ),
    [THANK_YOU_STEP]: (
      <ThankYou
        onBack={() => {
          setJourney(journey);
          setStep(0);
        }}
        flow={flow}
        parentSetStep={parentSetStep}
        parentStep={parentStep}
        isSubmitLoading={isSubmitLoading}
        hasOnlyInProgressContributionInstructions={hasOnlyInProgressContributionInstructions}
        hasInProgressLumpSumInstructions={hasInProgressLumpSumInstructions}
      />
    ),
  };

  const stepComponent = get(stepsMap, journey[step]);

  if (!stepComponent) {
    return <Redirect to="/" />;
  }

  return (
    stepsMap[journey[step]]
  );
};

const mapStateToProps = (state) => ({
  hasOnlyInProgressContributionInstructions: getHasOnlyInProgressContributionInstruction(state),
  hasInProgressLumpSumInstructions: getHasInProgressLumpSumInstructions(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchMissingDetailsPageLoaded: () => dispatch(missingDetailsPageLoaded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MissingDetailsPage));
