exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-PasswordStrength-wrapper-1T-En {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 100%;\n}\n.-PasswordStrength-tipsWrapper-2UsW4 {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["thickFog"] + ";\n  height: 100%;\n  line-height: 110%;\n  font-family: 'Lato';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 18px;\n  text-transform: uppercase;\n  margin: 10px 0 0 0;\n}\n.-PasswordStrength-tipsList-2nlKF {\n  padding: 0 0 20px 25px;\n  margin: 10px 0 0 0px;\n}\n.-PasswordStrength-tips-1n9lW {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textParagraphRegular"] + ";\n  text-transform: none;\n  font-family: 'Lato';\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n}\n@media only screen and (max-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n  .-PasswordStrength-tipsWrapper-2UsW4 {\n    font-size: 16px;\n  }\n\n  .-PasswordStrength-tips-1n9lW {\n    font-size: 14px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"dimensions": "\"../../theme/dimensions.css\"",
	"gutter": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["gutter"] + "",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"desktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["desktop"] + "",
	"colors": "\"../../theme/colors.css\"",
	"lightestGrey": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["lightestGrey"] + "",
	"grey": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["grey"] + "",
	"danger": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["danger"] + "",
	"warning": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["warning"] + "",
	"thickFog": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["thickFog"] + "",
	"textParagraphRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textParagraphRegular"] + "",
	"primary": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["primary"] + "",
	"secondary": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["secondary"] + "",
	"wrapper": "-PasswordStrength-wrapper-1T-En",
	"tipsWrapper": "-PasswordStrength-tipsWrapper-2UsW4",
	"tipsList": "-PasswordStrength-tipsList-2nlKF",
	"tips": "-PasswordStrength-tips-1n9lW"
};