export const ButtonVariants = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  notificationRed: 'notificationRed',
  notificationBlue: 'notificationBlue',
  notificationGreen: 'notificationGreen',
};

export const ButtonSize = {
  xsmall: 'xsmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
};
