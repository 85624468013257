import React from 'react';

import AddPensions from '../../../components/DashboardRow/AddPensions';
import ListPensions from '../../../components/DashboardRow/ListPensions';

import styles from '../DashboardPage.css';

const AddAndListPensions = (props) => {
  const {
    isCustomerOngoingMultipleRps,
    isAddPensionsAllowed,
    isCustomerOngoing,
    isCustomerOptoutFullEncashment,
    hasPensions,
  } = props;

  const addPensionsMessage = () => {
    const isAddPensionsNotAllowedMessage = 'You need to update your pension plan before you can transfer another policy.';
    const onboardingMessage = 'Tell us the name of your old pension provider or employer. We’ll then take care of transferring this pension for you.';
    const ongoingMessage = 'Give us a few details so we can combine all your pensions.';
    const multipleRpsMessage = 'We are currently setting up your new pension plan. You will be able to add or transfer more pensions when this is complete.';
    if (isCustomerOngoingMultipleRps) return multipleRpsMessage;
    if (!isAddPensionsAllowed) return isAddPensionsNotAllowedMessage;
    if (isCustomerOngoing) return ongoingMessage;
    return onboardingMessage;
  };

  return (
    <div className={styles.addPensionsContainer}>
      <AddPensions
        disabled={
          isCustomerOngoingMultipleRps
          || isCustomerOptoutFullEncashment
          || !isAddPensionsAllowed
        }
        message={addPensionsMessage()}
      />
      {hasPensions && (
        <div className={styles.listPensionsContainer}>
          <ListPensions />
        </div>
      )}
    </div>
  );
};

export default AddAndListPensions;
