import React from 'react';
import { connect } from 'react-redux';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { NEW_ONE_OFF_LUMP_SUM_NOTIFICATION_CLOSED, oneOffLumpSumNotificationClicked } from '../../redux/modules/notificationTracking';
import { getFirebaseUid } from '../../redux/selectors';

const NewOneOffLumpSumNotification = ({
  uid,
  instructionId,
}) => {
  return (
    <NotificationBanner
      alertCategory={'Green'}
      alertTitle={'We’re processing your one-off payment reference'}
      expandableAlertDescription={'We will send you a payment reference via secure message to use when you complete your bank transfer'}
      closeButtonOptions={{
        event: NEW_ONE_OFF_LUMP_SUM_NOTIFICATION_CLOSED,
        handleClick: () => {
          oneOffLumpSumNotificationClicked(uid, instructionId);
        },
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
});

export default connect(mapStateToProps)(NewOneOffLumpSumNotification);
