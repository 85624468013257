exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-FormError-wrapper-oVrtq p{\n  font-size: 17px;\n  line-height: 1.5rem;\n}\n.-FormError-errorText-1AGSk {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["danger"] + "\n}\n", ""]);

// exports
exports.locals = {
	"colors": "\"../../theme/colors.css\"",
	"danger": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["danger"] + "",
	"wrapper": "-FormError-wrapper-oVrtq",
	"errorText": "-FormError-errorText-1AGSk"
};