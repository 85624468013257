import React from 'react';
import PropTypes from 'prop-types';
import styles from './FormError.css';


const FormError = ({ error, additionalInfo }) => (
  error && (
    <div className={styles.wrapper} data-cy="error-message">
      <p className={styles.errorText}>{error}</p>
      {additionalInfo}
    </div>
  )
);

FormError.propTypes = {
  error: PropTypes.node,
};

FormError.defaultProps = {
  error: null,
};

export default FormError;
