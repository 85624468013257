import React, { useState } from 'react';
import classnames from 'classnames';
import styles from './TextInput.css';

const TextInput = ({
  onChange,
  value,
  placeholder,
  error,
  inline,
  ...props
}) => {
  const [isInputFocussed, setIsInputFocussed] = useState(false);
  const toggleInputFocus = () => setIsInputFocussed((isFocussed) => !isFocussed);

  return (
    <input
      className={classnames(styles.textInput, { [styles.inline]: inline, [styles.error]: error })}
      placeholder={placeholder}
      value={(isInputFocussed || value) ? value : ''}
      onFocus={() => toggleInputFocus()}
      onBlur={toggleInputFocus}
      onChange={(event) => onChange(event)}
      // eslint-disable-next-line
      {...props}
    />
  );
};

export default TextInput;
