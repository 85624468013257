/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import BaseLayout from '../../../components/BaseLayout/BaseLayout';
import smallGreenTick from '../../../assets/images/icons/roundedGreenTick.svg';
import Notifications from '../sections/Notifications';
import OngoingBalanceCircle from './OngoingBalanceCircle';
import IntroModal from '../../../components/IntroModal/IntroModal';
import MfmAnnouncementModal from '../../../components/MfmAnnouncementModal/MfmAnnouncementModal';
import Cds15MonthHardBlock from '../../../components/Cds15MonthHardBlock/Cds15MonthHardBlock';
import PageGrid from '../../../components/PageGrid/PageGrid';

import { getIsAnyNotificationActive } from '../../../redux/selectors/notifications';
import { getHasMfmAnnouncementModalBeenDismissed, getHasIntroModalBeenDismissed } from '../../../redux/selectors/notificationTracking';

import {
  getIVUCollection,
  getAuthoritativeRetirementPlan,
  getLatestClosedEncashmentRetirementPlan,
} from '../../../redux/selectors';
import getProfilePortfolio from '../../../util/getProfilePortfolio';
import getFundsWithRoundedSplits from '../../../util/getFundsWithRoundedSplits';
import fundMap from '../../../components/FundList/fundMap';
import OngoingWidgets from './OngoingWidgets';
import { getIsCustomerOptout, getIsCustomerOptoutFullEncashment } from '../../../redux/selectors/experience';
import { getIsOngoingCdsAllowed, getIsOngoingCdsMandatory } from '../../../redux/selectors/journeys';

import styles from './OngoingDashboard.css';
import { getForceMentionMeModal } from '../../../redux/selectors/investmentAdvice';
import { unsetForceMentionMeModalAction } from '../../../redux/modules/investmentAdvice';

const OngoingDashboard = ({
  heading,
  ivuCollection,
  authoritativeRp,
  isAnyNotificationActive,
  isCustomerOptoutFullEncashment,
  latestClosedEncashmentRetirementPlan,
  isCustomerOptOut,
  dispatchUnsetForceMentionMeModal,
  isOngoingCdsRequired,
  isOngoingCdsMandatory,
  hasMfmAnnouncementModalBeenDismissed,
  hasIntroModalBeenDismissed,
}) => {
  const rp = authoritativeRp || latestClosedEncashmentRetirementPlan;
  const retirementPlanFunds = get(rp, 'funds', []);
  const funds = getFundsWithRoundedSplits(retirementPlanFunds);
  const mappedFunds = funds.filter((fund) => fund.name !== 'Cash').map((fund) => {
    const fundData = fundMap[fund.isin];
    if (!fundData) return;
    return { name: fundData.name, fundSplit: get(fund, 'fundRoundedSplit'), isin: fund.isin };
  });
  const portfolioReference = get(rp, 'investmentVehicleReference');
  const { planName, esg } = getProfilePortfolio(
    ivuCollection,
    portfolioReference,
    mappedFunds,
  );

  return (
    <BaseLayout
      heading={heading}
      dashboardTheme
    >
      {isOngoingCdsMandatory ? (
        <PageGrid>
          <Cds15MonthHardBlock />
        </PageGrid>
      ) : (
        <React.Fragment>
          <div className={styles.ongoingBalanceSection}>
            <div className={styles.ongoingBalanceWrapper}>
              <div className={styles.ongoingBalanceContainer}>
                <div>
                  {planName && !isCustomerOptOut && (
                    <p className={styles.statusText}>
                      {'Your pension plan '}
                      <span>
                        {planName}
                        {esg && ' ESG'}
                      </span>
                    </p>
                  )}
                  {
                    !isAnyNotificationActive
                    && !isOngoingCdsRequired
                    && !isCustomerOptoutFullEncashment
                    && !isCustomerOptOut && (
                      <p className={styles.statusText}>
                        <img src={smallGreenTick} alt="Green tick" className={styles.tick} />
                        {'You’re invested in the most up to date plan for you'}
                      </p>
                    )
                  }
                  {isCustomerOptOut && (
                    <p className={styles.statusText}>
                      {'You’re opted out of our service, message us to reactivate your account'}
                    </p>
                  )}
                </div>
                {isAnyNotificationActive && <Notifications />}
                <div className={styles.ongoingBalance}>
                  <OngoingBalanceCircle />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.ongoingWidgetsSection}>
            <div className={styles.ongoingWidgetsWrapper}>
              <div className={styles.ongoingWidgetsContainer}>
                <div className={styles.ongoingWidgets}>
                  <OngoingWidgets />
                </div>
              </div>
            </div>
          </div>
          {
            hasMfmAnnouncementModalBeenDismissed && !hasIntroModalBeenDismissed && (
              <IntroModal />
            )
          }
          {
            !hasMfmAnnouncementModalBeenDismissed && (
              <MfmAnnouncementModal />
            )
          }
          <div style={{ display: 'none' }} id="mmWrapper" onClick={dispatchUnsetForceMentionMeModal} />
        </React.Fragment>
      )}
    </BaseLayout>
  );
};

const mapStateToProps = (state) => ({
  authoritativeRp: getAuthoritativeRetirementPlan(state),
  ivuCollection: getIVUCollection(state),
  isAnyNotificationActive: getIsAnyNotificationActive(state),
  isCustomerOptoutFullEncashment: getIsCustomerOptoutFullEncashment(state),
  isCustomerOptOut: getIsCustomerOptout(state),
  latestClosedEncashmentRetirementPlan: getLatestClosedEncashmentRetirementPlan(state),
  forceMentionMeModal: getForceMentionMeModal(state),
  isOngoingCdsRequired: getIsOngoingCdsAllowed(state),
  isOngoingCdsMandatory: getIsOngoingCdsMandatory(state),
  hasMfmAnnouncementModalBeenDismissed: getHasMfmAnnouncementModalBeenDismissed(state),
  hasIntroModalBeenDismissed: getHasIntroModalBeenDismissed(state),
});

const mapDispatchToProps = ({
  dispatchUnsetForceMentionMeModal: unsetForceMentionMeModalAction,
});

export default connect(mapStateToProps, mapDispatchToProps)(OngoingDashboard);
