import React from 'react';
import { DEFAULT_CONTACT_EMAIL } from '../../config';
import styles from './JourneyLayout.css';
import JourneyLayoutSidebar from './JourneyLayoutSidebar';
import KeyInfoItem from '../KeyInfoItem/KeyInfoItem';
import IconLock from '../../assets/images/rebranding/padlock.svg';
import IconPhone from '../../assets/images/rebranding/envelope.svg';
import LogoTitle from './LogoTitle';

const PasswordJourneyLayoutSidebar = () => (
  <JourneyLayoutSidebar>
    <LogoTitle title="Creating a password" />
    <KeyInfoItem icon={<img src={IconLock} alt="lock icon" />}>
      <p className="body-two">{'Your password must be 8 characters or more. It can be any combination of letters, numbers and special characters.'}</p>
    </KeyInfoItem>
    <h4 className={styles.sidebarTitle}>
      {'Any questions?'}
    </h4>
    <KeyInfoItem icon={<img src={IconPhone} alt="email icon" />} light>
      <a href={`mailto:${DEFAULT_CONTACT_EMAIL}`}>
        {'Email us'}
      </a>
    </KeyInfoItem>
  </JourneyLayoutSidebar>
);

export default PasswordJourneyLayoutSidebar;
