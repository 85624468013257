/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import once from 'lodash/once';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import get from 'lodash/get';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirestore } from 'react-redux-firebase';
import { validate } from 'validate.js';
import { withFormik } from 'formik';
import { GENERIC_ERROR } from '../../forms/copyTexts';
import { contactNumberConstraints, convertFlattenedObjectToObject } from '../../util/completePersonalDetailsValidation';
import {
  getFirebaseUid, getUser, getIsVerified, getTwoFactorAuthenticationEnabled,
} from '../../redux/selectors';
import ServiceAlertCard from '../../components/AlertCard/ServiceAlertCard';
import {
  renderTextField,
} from '../../util/formik-custom-fields';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import {
  personalDetailsMobileNumberTyped,
  personalDetailsHomeNumberTyped,
} from '../../redux/modules/signupFlow';
import { updateContactNumberDetails } from '../../redux/modules/investmentAdvice';
import '../../util/signupValidators';
import styles from './PersonalDetails.css';
import {
  addCountryCodeToNumber,
} from '../../util/common';

const removeCountryCodeFromNumber = (phoneNumber) => {
  return phoneNumber ? phoneNumber.replace(/^\+44/, '0') : '';
};
const removeWhitespace = (string) => {
  return string ? string.replace(/\s/g, '') : '';
};

class ContactNumberInformation extends Component {
  constructor(props) {
    super(props);
    const {
      dispatchPersonalDetailsMobileNumberTyped,
      dispatchPersonalDetailsHomeNumberTyped,
    } = this.props;
    this.state = { inEditMode: false };
    this.setLocalInEditMode = this.setLocalInEditMode.bind(this);
    this.dispatchPersonalDetailsMobileNumberTyped = once(dispatchPersonalDetailsMobileNumberTyped);
    this.dispatchPersonalDetailsHomeNumberTyped = once(dispatchPersonalDetailsHomeNumberTyped);
  }

  componentDidUpdate(prevProps) {
    const {
      isSubmitting,
      isValid,
      errors,
    } = this.props;
    if (!isValid && prevProps.isSubmitting && !isSubmitting) {
      const [firstError] = Object.keys(errors);
      const errorElement = document.querySelector(`input[name="${firstError}"]`);
      if (errorElement) {
        errorElement.parentNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    if (isValid && !prevProps.isSubmitting && isSubmitting) {
      document
        .querySelector('button[type=submit]')
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  setLocalInEditMode = (inEditMode) => {
    this.setState({
      inEditMode,
    });
  }

  constructEmailUsElement = (showOnlyLink) => {
    return (
      <span>
        {(!showOnlyLink) ? 'Please ' : ''}
        <ButtonLink
          variant="primary"
          font="inherit"
          label="message us"
          to="/inbox/new-message"
        >
          {'message us'}
        </ButtonLink>
        {'.'}
      </span>
    );
  }

  defaultHandleChange = (event, fieldName) => {
    const { handleChange } = this.props;

    handleChange(event);
    if (fieldName === 'mobileNumber') {
      this.dispatchPersonalDetailsMobileNumberTyped();
    }
    if (fieldName === 'homeNumber') {
      this.dispatchPersonalDetailsHomeNumberTyped();
    }
  }

  fieldsConfig = () => {
    const {
      values,
      twoFactorAuthenticationEnabled,
    } = this.props;
    const { inEditMode } = this.state;
    return {
      mobileNumber: {
        type: 'tel',
        name: 'mobileNumber',
        label: 'Mobile Number',
        value: values.mobileNumber,
        onChange: (event) => this.defaultHandleChange(event, 'mobileNumber'),
        component: renderTextField,
        disabled: twoFactorAuthenticationEnabled || !inEditMode,
      },
      homeNumber: {
        type: 'tel',
        name: 'homeNumber',
        label: 'Home Number',
        value: values.homeNumber,
        onChange: (event) => this.defaultHandleChange(event, 'homeNumber'),
        component: renderTextField,
        disabled: !inEditMode,
      },
    };
  };

  /* eslint consistent-return: 0 */
  constructErrorMessage = (data) => {
    if (!data) {
      return;
    }
    return (
      <div>
        {GENERIC_ERROR}
        {this.constructEmailUsElement()}
      </div>
    );
  }

  render() {
    const {
      handleSubmit,
      isSubmitting,
      errors,
      isValid,
      values,
      initialValues,
      renderField,
      handleReset,
      setComponentIsEditing,
      componentIsEditing,
      status,
      setStatus,
      twoFactorAuthenticationEnabled,
    } = this.props;
    const { inEditMode } = this.state;
    const fieldsConfig = this.fieldsConfig();
    const removedBothNumbersError = values.homeNumber === '' && values.mobileNumber === '';
    const submitSuccessful = get(status, 'submitSuccessful');
    const formValuesHaveChanged = !isEqual(initialValues, values);
    if (submitSuccessful) {
      this.state.inEditMode = false;
      setStatus({ submitSuccessful: false });
    }
    const showUpdate = !inEditMode && !componentIsEditing;
    const showSave = inEditMode && formValuesHaveChanged && componentIsEditing && !removedBothNumbersError;
    const showCancel = inEditMode && componentIsEditing;
    return (
      <div>
        <div className={styles.container}>
          <form
            className={styles.form}
            onSubmit={handleSubmit}
          >
            <h1 className={styles.heading}>
              {'Contact Details'}
            </h1>
            <div className={styles.inlineSelectFieldsWrapper}>
              {
                Object.keys(fieldsConfig).map((field) => {
                  return !fieldsConfig[field].hidden
                    && (
                      <div className={styles.inlineSelectField} key={fieldsConfig[field].name}>
                        {renderField(fieldsConfig[field])}
                      </div>

                    );
                })
              }
            </div>
            <div className={styles.errorMessage}>{(errors && this.constructErrorMessage(errors.submitError)) || (removedBothNumbersError && ('Please enter at least one contact number'))}</div>
            <div className={styles.actionRow}>
              {showUpdate && (
                <Button
                  size="small"
                  label="Update"
                  onClick={() => {
                    this.setLocalInEditMode(true);
                    setComponentIsEditing(true);
                  }}
                />
              )}
              {showSave && (
                <Button type="submit" label="Save" mediumFixedWidth disabled={!formValuesHaveChanged || isSubmitting || !isValid} loading={isSubmitting} />
              )}
              {showCancel && (
                <Button
                  size="small"
                  label="Cancel"
                  disabled={isSubmitting}
                  onClick={() => {
                    this.setLocalInEditMode(false);
                    setComponentIsEditing(false);
                    handleReset();
                  }}
                />
              )}
            </div>
          </form>
          {twoFactorAuthenticationEnabled && (
            <ServiceAlertCard
              alertDescription={(
                <span>
                  {'If you want to change your mobile number, please turn off 2-step verification in '}
                  <ButtonLink
                    font="inherit"
                    underline
                    label="account settings"
                    to="/account-settings"
                  />
                  {'.'}
                </span>
              )}
              alertSeverity="warning"
              icon="warningCircle"
              detached
            />
          )}
        </div>
      </div>
    );
  }
}

/**
 * withFormik is being used to map props to
 * form values which will auto populate the fields.
 */
const FormikEnhancer = withFormik({
  mapPropsToValues: (props) => {
    const { user } = props;
    const mobileFromUser = get(user, 'contact.mobile');
    const landlinefromUser = get(user, 'contact.landline');
    return {
      mobileNumber: removeWhitespace(removeCountryCodeFromNumber(
        mobileFromUser,
      )),
      homeNumber: removeWhitespace(removeCountryCodeFromNumber(
        landlinefromUser,
      )),
    };
  },
  validate: (formValues) => {
    const valuesToValidate = {};
    // Create a new object which matches the forms object but strings are set to null.
    Object.keys(formValues).forEach((value) => {
      valuesToValidate[value] = formValues[value] || null;
    });

    // Validate the current values from the form object against the constraints
    const validationResult = validate(
      pick(valuesToValidate, Object.keys(contactNumberConstraints)),
      contactNumberConstraints,
    );

    if (!validationResult) {
      return {};
    }
    return convertFlattenedObjectToObject(validationResult);
  },
  handleSubmit: async (values, {
    setSubmitting, props, setStatus, resetForm,
  }) => {
    const { dispatchUpdateDetails, setComponentIsEditing } = props;

    setSubmitting(true);
    const data = {
      mobileNumber: removeWhitespace(addCountryCodeToNumber(values.mobileNumber)),
      homeNumber: removeWhitespace(
        addCountryCodeToNumber(values.homeNumber),
      ),
    };
    await dispatchUpdateDetails(data);
    setSubmitting(false);
    setComponentIsEditing(false);
    resetForm(values);
    return setStatus({ submitSuccessful: true });
  },
  displayName: 'ContactNumberInformation',
})(ContactNumberInformation);

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
  user: getUser(state),
  isVerified: getIsVerified(state),
  twoFactorAuthenticationEnabled: getTwoFactorAuthenticationEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPersonalDetailsMobileNumberTyped: () => dispatch(personalDetailsMobileNumberTyped()),
  dispatchPersonalDetailsHomeNumberTyped: () => dispatch(personalDetailsHomeNumberTyped()),
  dispatchUpdateDetails: (data) => dispatch(updateContactNumberDetails(data)),
});

export default compose(
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps),
)(FormikEnhancer);
