import { combineEpics } from 'redux-observable';
import initialDataLoad from './initialDataLoad';
import authEpic from './auth';

const rootEpic = combineEpics(
  initialDataLoad,
  authEpic,
);

export default rootEpic;
