import firebase from 'firebase/compat/app';
import sentryException from '../../util/sentryException';
import { formatContributionEvent, formatOngoingContributionEvent } from '../util/eventKeyFormats';

const SETUP_CONTRIBUTION_DISPATCHED = formatContributionEvent('SETUP_CONTRIBUTIONS_DISPATCHED');
const ONGOING_SETUP_CONTRIBUTION_DISPATCHED = formatOngoingContributionEvent('SETUP_CONTRIBUTIONS_DISPATCHED');
const SETUP_CONTRIBUTION_FULFILLED = formatContributionEvent('SETUP_CONTRIBUTIONS_FULFILLED');
const ONGOING_SETUP_CONTRIBUTION_FULFILLED = formatOngoingContributionEvent('SETUP_CONTRIBUTIONS_FULFILLED');
const SETUP_CONTRIBUTIONS_REJECTED = formatContributionEvent('SETUP_CONTRIBUTIONS_REJECTED');
const ONGOING_SETUP_CONTRIBUTIONS_REJECTED = formatOngoingContributionEvent('SETUP_CONTRIBUTIONS_REJECTED');

export const submitErrorAction = () => ({ type: formatContributionEvent('SUBMIT_ERROR') });

export const ongoingSubmitErrorAction = () => ({ type: formatOngoingContributionEvent('SUBMIT_ERROR') });

export const contributionAndReliefTermsLinkClickedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_AND_RELIEF_TERMS_LINK_CLICKED') }
);

export const ongoingContributionAndReliefTermsLinkClickedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_AND_RELIEF_TERMS_LINK_CLICKED') }
);

export const termsOfBusinessLinkClickedAction = () => (
  { type: formatContributionEvent('TERMS_OF_BUSINESS_LINK_CLICKED') }
);

export const ongoingTermsOfBusinessLinkClickedAction = () => (
  { type: formatOngoingContributionEvent('TERMS_OF_BUSINESS_LINK_CLICKED') }
);

export const directDebitGuaranteeExpandedAction = () => (
  { type: formatContributionEvent('DIRECT_DEBIT_GUARANTEE_EXPANDED') }
);

export const ongoingDirectDebitGuaranteeExpandedAction = () => (
  { type: formatOngoingContributionEvent('DIRECT_DEBIT_GUARANTEE_EXPANDED') }
);

export const directDebitGuaranteeCollapsedAction = () => (
  { type: formatContributionEvent('DIRECT_DEBIT_GUARANTEE_COLLAPSED') }
);

export const ongoingDirectDebitGuaranteeCollapsedAction = () => (
  { type: formatOngoingContributionEvent('DIRECT_DEBIT_GUARANTEE_COLLAPSED') }
);

export const authoriseDirectDebitUnselectedAction = () => (
  { type: formatContributionEvent('AUTHORISE_DIRECT_DEBIT_UNSELECTED') }
);

export const ongoingAuthoriseDirectDebitUnselectedAction = () => (
  { type: formatOngoingContributionEvent('AUTHORISE_DIRECT_DEBIT_UNSELECTED') }
);

export const authoriseDirectDebitSelectedAction = () => (
  { type: formatContributionEvent('AUTHORISE_DIRECT_DEBIT_SELECTED') }
);

export const ongoingAuthoriseDirectDebitSelectedAction = () => (
  { type: formatOngoingContributionEvent('AUTHORISE_DIRECT_DEBIT_SELECTED') }
);

export const authoriseImplementPensionPlanUnselectedAction = () => (
  { type: formatContributionEvent('AUTHORISE_IMPLEMENT_PENSION_PLAN_UNSELECTED') }
);

export const ongoingAuthoriseImplementPensionPlanUnselectedAction = () => (
  { type: formatOngoingContributionEvent('ONGOING_AUTHORISE_IMPLEMENT_PENSION_PLAN_UNSELECTED') }
);

export const authoriseImplementPensionPlanSelectedAction = () => (
  { type: formatContributionEvent('AUTHORISE_IMPLEMENT_PENSION_PLAN_SELECTED') }
);

export const ongoingAuthoriseImplementPensionPlanSelectedAction = () => (
  { type: formatOngoingContributionEvent('AUTHORISE_IMPLEMENT_PENSION_PLAN_SELECTED') }
);

export const illustrationLinkClickedAction = () => (
  { type: formatContributionEvent('ILLUSTRATION_LINK_CLICKED') }
);

export const ongoingIllustrationLinkClickedAction = () => (
  { type: formatOngoingContributionEvent('ILLUSTRATION_LINK_CLICKED') }
);

export const keyDetailsLinkClickedAction = () => (
  { type: formatContributionEvent('KEY_DETAILS_LINK_CLICKED') }
);

export const ongoingKeyDetailsLinkClickedAction = () => (
  { type: formatOngoingContributionEvent('KEY_DETAILS_LINK_CLICKED') }
);

export const recommendationReportLinkClickedAction = () => (
  { type: formatContributionEvent('RECOMMENDATION_REPORT_LINK_CLICKED') }
);

export const ongoingRecommendationReportLinkClickedAction = () => (
  { type: formatOngoingContributionEvent('RECOMMENDATION_REPORT_LINK_CLICKED') }
);

export const changeContributionClickedAction = () => (
  { type: formatContributionEvent('CHANGE_CONTRIBUTION_CLICKED') }
);

export const ongoingChangeContributionClickedAction = () => (
  { type: formatOngoingContributionEvent('CHANGE_CONTRIBUTION_CLICKED') }
);

export const changeBankDetailsClickedAction = () => (
  { type: formatContributionEvent('CHANGE_BANK_DETAILS_CLICKED') }
);

export const ongoingChangeBankDetailsClickedAction = () => (
  { type: formatOngoingContributionEvent('CHANGE_BANK_DETAILS_CLICKED') }
);

export const submitClickedAction = () => (
  { type: formatContributionEvent('SUBMIT_CLICKED') }
);

export const ongoingSubmitClickedAction = () => (
  { type: formatOngoingContributionEvent('SUBMIT_CLICKED') }
);

export const contributionSubmitStepVisitedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_SUBMIT_STEP_VISITED') }
);

export const ongoingContributionSubmitStepVisitedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_SUBMIT_STEP_VISITED') }
);

export const salaryChangedAction = () => (
  { type: formatContributionEvent('SALARY_CHANGED') }
);

export const ongoingSalaryChangedAction = () => (
  { type: formatOngoingContributionEvent('SALARY_CHANGED') }
);

export const occupationChangedAction = () => (
  { type: formatContributionEvent('OCCUPATION_CHANGED') }
);

export const ongoingOccupationChangedAction = () => (
  { type: formatOngoingContributionEvent('OCCUPATION_CHANGED') }
);

export const otherSourceOfFundsChangedAction = () => (
  { type: formatContributionEvent('OTHER_SOURCE_OF_FUNDS_CHANGED') }
);

export const ongoingOtherSourceOfFundsChangedAction = () => (
  { type: formatOngoingContributionEvent('OTHER_SOURCE_OF_FUNDS_CHANGED') }
);

export const sourceOfFundsChangedAction = () => (
  { type: formatContributionEvent('SOURCE_OF_FUNDS_CHANGED') }
);

export const ongoingSourceOfFundsChangedAction = () => (
  { type: formatOngoingContributionEvent('SOURCE_OF_FUNDS_CHANGED') }
);

export const indexedWithInflationChangedAction = () => (
  { type: formatContributionEvent('INDEXED_WITH_INFLATION_CHANGED') }
);

export const ongoingIndexedWithInflationChangedAction = () => (
  { type: formatOngoingContributionEvent('INDEXED_WITH_INFLATION_CHANGED') }
);

export const contributionInflationStepVisitedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_INFLATION_STEP_VISITED') }
);

export const ongoingContributionInflationStepVisitedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_INFLATION_STEP_VISITED') }
);

export const contributionSourceOfFundsStepVisitedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_SOURCE_OF_FUNDS_STEP_VISITED') }
);

export const ongoingContributionSourceOfFundsStepVisitedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_SOURCE_OF_FUNDS_STEP_VISITED') }
);

export const referFriendClickedAction = () => (
  { type: formatContributionEvent('REFER_FRIEND_CLICKED') }
);

export const ongoingReferFriendClickedAction = () => (
  { type: formatOngoingContributionEvent('REFER_FRIEND_CLICKED') }
);

export const contributionCompleteStepVisitedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_COMPLETE_STEP_VISITED') }
);

export const ongoingContributionCompleteStepVisitedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_COMPLETE_STEP_VISITED') }
);

export const accountNumberChangedAction = () => (
  { type: formatContributionEvent('ACCOUNT_NUMBER_CHANGED') }
);

export const ongoingAccountNumberChangedAction = () => (
  { type: formatOngoingContributionEvent('ACCOUNT_NUMBER_CHANGED') }
);

export const sortcodeChangedAction = () => (
  { type: formatContributionEvent('SORTCODE_CHANGED') }
);
export const ongoingSortcodeChangedAction = () => (
  { type: formatOngoingContributionEvent('SORTCODE_CHANGED') }
);

export const nameChangedAction = () => (
  { type: formatContributionEvent('NAME_CHANGED') }
);

export const ongoingNameChangedAction = () => (
  { type: formatOngoingContributionEvent('NAME_CHANGED') }
);

export const contributionBankDetailsStepVisitedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_BANK_DETAILS_STEP_VISITED') }
);

export const ongoingContributionBankDetailsStepVisitedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_BANK_DETAILS_STEP_VISITED') }
);

export const confirmClickedAction = () => (
  { type: formatContributionEvent('CONFIRM_CLICKED') }
);

export const ongoingConfirmClickedAction = () => (
  { type: formatOngoingContributionEvent('CONFIRM_CLICKED') }
);

export const nextClickedAction = () => (
  { type: formatContributionEvent('NEXT_CLICKED') }
);

export const ongoingNextClickedAction = () => (
  { type: formatOngoingContributionEvent('NEXT_CLICKED') }
);

export const amountManuallyChangedAction = () => (
  { type: formatContributionEvent('AMOUNT_MANUALLY_CHANGED') }
);

export const ongoingAmountManuallyChangedAction = () => (
  { type: formatOngoingContributionEvent('AMOUNT_MANUALLY_CHANGED') }
);

export const taxReliefLinkClickedAction = () => (
  { type: formatContributionEvent('TAX_RELIEF_LINK_CLICKED') }
);

export const ongoingTaxReliefLinkClickedAction = () => (
  { type: formatOngoingContributionEvent('TAX_RELIEF_LINK_CLICKED') }
);

export const returnLinkClickedAction = () => (
  { type: formatContributionEvent('RETURN_LINK_CLICKED') }
);

export const ongoingReturnLinkClickedAction = () => (
  { type: formatOngoingContributionEvent('RETURN_LINK_CLICKED') }
);

export const setupContributionLinkClicked = () => (
  { type: formatContributionEvent('SETUP_CONTRIBUTIONS_LINK_CLICKED') }
);

export const ongoingSetupContributionLinkClicked = () => (
  { type: formatOngoingContributionEvent('SETUP_CONTRIBUTIONS_LINK_CLICKED') }
);

export const contributionAmountStepVisitedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_AMOUNT_STEP_VISITED') }
);

export const ongoingContributionAmountStepVisitedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_AMOUNT_STEP_VISITED') }
);

export const contributionAmountStepMessageMeClickedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_AMOUNT_STEP_MESSAGE_ME_CLICKED') }
);

export const ongoingContributionAmountStepMessageMeClickedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_AMOUNT_STEP_MESSAGE_ME_CLICKED') }
);

export const contributionFixedAmountFiftyClickedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_FIXED_AMOUNT_FIFTY_CLICKED') }
);

export const ongoingContributionFixedAmountFiftyClickedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_FIXED_AMOUNT_FIFTY_CLICKED') }
);

export const contributionFixedAmountOneHundredClickedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_FIXED_AMOUNT_ONE_HUNDRED_CLICKED') }
);

export const ongoingContributionFixedAmountOneHundredClickedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_FIXED_AMOUNT_ONE_HUNDRED_CLICKED') }
);

export const contributionFixedAmountOneHundredFiftyClickedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_FIXED_AMOUNT_ONE_HUNDRED_FIFTY_CLICKED') }
);

export const ongoingContributionFixedAmountOneHundredFiftyClickedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_FIXED_AMOUNT_ONE_HUNDRED_FIFTY_CLICKED') }
);

export const contributionFaqTaxReliefViewedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_FAQ_TAX_RELIEF_VIEWED') }
);

export const ongoingContributionFaqTaxReliefViewedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_FAQ_TAX_RELIEF_VIEWED') }
);

export const contributionFaqPensionContributionAmountViewedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_FAQ_PENSION_CONTRIBUTION_AMOUNT_VIEWED') }
);

export const ongoingContributionFaqPensionContributionAmountViewedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_FAQ_PENSION_CONTRIBUTION_AMOUNT_VIEWED') }
);

export const contributionFaqHowMuchNeededToRetireViewedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_FAQ_HOW_MUCH_NEEDED_TO_RETIRE_VIEWED') }
);

export const ongoingContributionFaqHowMuchNeededToRetireViewedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_FAQ_HOW_MUCH_NEEDED_TO_RETIRE_VIEWED') }
);

export const contributionFaqAmendDirectDebitViewedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_FAQ_AMEND_DIRECT_DEBIT_VIEWED') }
);

export const ongoingContributionFaqAmendDirectDebitViewedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_FAQ_AMEND_DIRECT_DEBIT_VIEWED') }
);

export const contributionFaqRegularContributionVsSinglePaymentViewedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_FAQ_CONTRIBUTION_VS_SINGLE_PAYMENT_VIEWED') }
);

export const ongoingContributionFaqRegularContributionVsSinglePaymentViewedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_FAQ_CONTRIBUTION_VS_SINGLE_PAYMENT_VIEWED') }
);

export const contributionFaqWhatIsInflationViewedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_FAQ_WHAT_IS_INFLATION_VIEWED') }
);

export const ongoingContributionFaqWhatIsInflationViewedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_FAQ_WHAT_IS_INFLATION_VIEWED') }
);

export const contributionFaqWhenWouldMyPensionIncreaseIfISelectYesViewedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_FAQ_WHEN_WOULD_MY_PENSION_INCREASE_IF_I_SELECT_YES_VIEWED') }
);

export const ongoingContributionFaqWhenWouldMyPensionIncreaseIfISelectYesViewedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_FAQ_WHEN_WOULD_MY_PENSION_INCREASE_IF_I_SELECT_YES_VIEWED') }
);

export const contributionFaqCanIAmendMyDecisionViewedAction = () => (
  { type: formatContributionEvent('CONTRIBUTION_FAQ_CAN_I_AMEND_MY_DECISION_VIEWED') }
);

export const ongoingContributionFaqCanIAmendMyDecisionViewedAction = () => (
  { type: formatOngoingContributionEvent('CONTRIBUTION_FAQ_CAN_I_AMEND_MY_DECISION_VIEWED') }
);

export const setupContributionAction = (
  contributionData,
  isCustomerOngoing,
) => async (dispatch) => {
  const addContritbutionInstruction = firebase.app().functions('europe-west1').httpsCallable('addInstruction');
  const dispatchedType = isCustomerOngoing
    ? ONGOING_SETUP_CONTRIBUTION_DISPATCHED
    : SETUP_CONTRIBUTION_DISPATCHED;
  const fulfilledType = isCustomerOngoing
    ? ONGOING_SETUP_CONTRIBUTION_FULFILLED
    : SETUP_CONTRIBUTION_FULFILLED;
  const rejectedType = isCustomerOngoing
    ? ONGOING_SETUP_CONTRIBUTIONS_REJECTED
    : SETUP_CONTRIBUTIONS_REJECTED;
  dispatch({ type: dispatchedType });
  try {
    await addContritbutionInstruction(contributionData);
    dispatch({ type: fulfilledType });
  } catch (e) {
    dispatch({ type: rejectedType });
    sentryException(e, {
      section: 'setup-contribution',
    });
    throw e;
  }
};
