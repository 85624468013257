import React from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import { renderTextField } from '../util/formik-custom-fields';
import PasswordStrength from '../components/PasswordStrength/PasswordStrength';
import FormError from '../components/FormError/FormError';
import Button from '../components/Button/Button';
import styles from './ForgotPasswordReset.css';
import { MINIMUM_PASSWORD_STRENGTH } from '../util/constants';
import ShowablePasswordField from '../components/ShowablePasswordField/ShowablePasswordField';

export default class ForgotPasswordReset extends React.Component {
  render() {
    const {
      onSubmit, initialValues, refError, resetFlowState,
    } = this.props;
    /* eslint-disable no-template-curly-in-string, func-names */
    const schema = yup.object().shape({
      pwd: yup.string()
        .required('Required')
        .min(8, 'Minimum of ${min} characters required')
        .test('password-score', 'Please provide a stronger password', (value) => {
          if (!value) return false;
          return zxcvbn(value).score >= MINIMUM_PASSWORD_STRENGTH;
        }),
      pwdConfirm: yup.string()
        .required('Required')
        .test('matchPassword', 'Passwords do not match', function (value) { return this.parent.pwd === value; }),
    });
    /* eslint-enable no-template-curly-in-string, func-names */

    if (refError) {
      return (
        <div>
          <h3 className={styles.heading}>
            {'Something went wrong'}
          </h3>
          <p className={styles.text}>
            {'Your password reset link may have expired.'}
          </p>
          <p className={styles.text}>
            <Link
              onClick={() => resetFlowState()}
              to="/forgot-password"
            >
              {'Click here'}
            </Link>
            {' to request a new password reset link.'}
          </p>
        </div>
      );
    }

    return (
      <Formik
        onSubmit={onSubmit}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        render={({
          handleSubmit,
          isSubmitting,
          values,
          status,
          errors,
          setFieldTouched,
          handleChange,
        }) => (
          <form onSubmit={handleSubmit}>
            <h1 className="h2">{'Reset your password'}</h1>
            <br />
            <p>
              {'If you didn’t mean to do this, you can access the login page '}
              <a className={styles.aTagStyle} href="/">{'here'}</a>
              {'.'}
            </p>
            <Field
              name="email"
              type="email"
              component={renderTextField}
              label="Email"
              required
              disabled
            />
            <br />
            <ShowablePasswordField
              name="pwd"
              label="Password"
              required
              defaultValue="Enter new password"
              onChange={(e) => {
                setFieldTouched('pwd');
                handleChange(e);
              }}
            />
            {values && values.pwd && <PasswordStrength pwd={values.pwd} />}
            <br />
            <ShowablePasswordField
              name="pwdConfirm"
              label="Confirm password"
              required
              defaultValue="Re-enter password"
              onChange={(e) => {
                setFieldTouched('pwdConfirm');
                handleChange(e);
              }}
            />
            <br />
            <FormError error={status && status.formError} />
            <Button
              type="submit"
              label="Submit"
              loading={isSubmitting}
              disabled={!!Object.keys(errors).length}
            />
          </form>
        )}
      />
    );
  }
}
