/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isLoaded, withFirestore } from 'react-redux-firebase';

import UpdateCDSNotification from '../../components/Notifications/UpdateCDSNotification';
import RecommendedPensionPlanNotification from '../../components/Notifications/RecommendedPensionPlanNotification';
import NoSwitchBannerNotification from '../../components/Notifications/NoSwitchBannerNotification';
import UpdatingPensionPlanNotification from '../../components/Notifications/UpdatingPensionPlanNotification';
import UpdatePensionPlanNotification from '../../components/Notifications/UpdatePensionPlanNotification';
import MetlifeCustomerNotification from '../../components/Notifications/MetlifeCustomerNotification';
import MarketingPreferencesAlert from '../../components/Notifications/MarketingPreferencesAlert';
import PensionsReadyToAuthoriseNotification from '../../components/Notifications/PensionsReadyToAuthoriseNotification';
import May2023DrawdownUpdateNotification from '../../components/Notifications/May2023DrawdownUpdateNotification';
import AddContributionRequestNotification from '../../components/Notifications/AddContributionRequestNotification';
import AddPensionRequestNotification from '../../components/Notifications/AddPensionRequestNotification';
import NewLumpSumNotification from '../../components/Notifications/NewOneOffLumpSumNotification';
import EnableTwoFactorAuthenticationNotification from '../../components/Notifications/EnableTwoFactorAuthenticationNotification';
import SerpsWaitingForHmrcNotification from '../../components/Notifications/SerpsWaitingForHmrcNotification';
import SerpsCompleteRequestNotification from '../../components/Notifications/SerpsCompleteRequestNotification';
import SerpsPensionFoundNotification from '../../components/Notifications/SerpsPensionFoundNotification';
import SerpsNoPensionsFoundNotification from '../../components/Notifications/SerpsNoPensionsFoundNotification';
import SerpsTraceIneligibleNotification from '../../components/Notifications/SerpsTraceIneligibleNotification';

import {
  getCurrentPensionPlanRecommendation,
  getCurrentRecommendationSuitabilityReport,
  getIsVulnerableCustomerFromOngoing,
} from '../../redux/selectors/ongoingService';
import { getNotificationTracking } from '../../redux/selectors/notificationTracking';
import {
  getShouldShowMetlifeCustomerNotification,
  getShouldShowUpdateCDSNotification,
  getShouldShowMay2023DrawdownUpdateNotification,
  getShouldShowRecPensionPlanNotification,
  getShouldShowUpdatePensionPlanNotification,
  getShouldShowUpdatingPensionPlanNotification,
  getShouldShowNoSwitchBannerNotification,
  getShouldShowMarketingPreferencesNotification,
  getShouldShowPensionsReadyToAuthoriseNotification,
  getShouldShowNewOneOffLumpSumNotification,
  getShouldShowTwoFactorAuthenticationNotification,
  getCurrentlyActiveNotifications,
  getShouldShowCompletePensionRequestNotification,
  getShouldShowCompleteContributionRequestNotification,
  getShouldShowSERPSPensionFoundNotification,
  getShouldShowSERPSWaitingForHmrcResponseNotification,
  getShouldShowSERPSCompleteRequestNotification,
  getShouldShowNoSERPSPensionsFoundNotification,
  getShouldShowSERPSTraceIneligibleNotification,
} from '../../redux/selectors/notifications';

import styles from './DashboardPage.css';

const OngoingStatusSection = ({
  notificationTracking,
  currentRecSuitabilityReport,
  firebase,
  vulnerableCustomerFromOngoing,
  shouldShowMetlifeCustomerNotification,
  shouldShowUpdateCDSNotification,
  shouldShowMay2023DrawdownUpdateNotification,
  shouldShowRecPensionPlanNotification,
  shouldShowUpdatePensionPlanNotification,
  shouldShowUpdatingPensionPlanNotification,
  shouldShowNoSwitchBannerNotification,
  shouldShowMarketingPreferencesNotification,
  shouldShowPensionsReadyToAuthoriseNotification,
  inProgressLumpSumInstruction,
  shouldShowTwoFactorAuthenticationNotification,
  currentlyActiveNotifications,
  shouldShowCompletePensionRequestNotification,
  shouldShowCompleteContributionRequestNotification,
  shouldShowSERPSPensionFoundNotification,
  shouldShowSERPSWaitingForHmrcResponseNotification,
  shouldShowSERPSCompleteRequestNotification,
  shouldShowNoSERPSPensionsFoundNotification,
  shouldShowSERPSTraceIneligibleNotification
}) => {
  const [investmentAdviceReportDownloadUrl, setInvestmentAdviceReportDownloadUrl] = useState(null);

  const getInvestmentAdviceLetterDownloadUrl = async (referenceName) => {
    if (referenceName && referenceName.name) {
      const userDocRef = firebase.storage().ref().child(referenceName.name);
      const downloadUrl = await userDocRef.getDownloadURL();
      setInvestmentAdviceReportDownloadUrl(downloadUrl);
    }
  };

  useEffect(() => {
    async function getInvestmentAdviceLetter() {
      await getInvestmentAdviceLetterDownloadUrl(currentRecSuitabilityReport);
    }

    getInvestmentAdviceLetter();
  }, [currentRecSuitabilityReport]);

  if (!isLoaded(notificationTracking)) {
    return null;
  }

  if (vulnerableCustomerFromOngoing) {
    return null;
  }

  const isTFATheOnlyActiveNotification = currentlyActiveNotifications.length === 1 && currentlyActiveNotifications.includes('twoFactorAuthenticationNotificationIsActive');

  // BLUE - Priority - N/A
  if (shouldShowMetlifeCustomerNotification) {
    return (
      <div className={styles.notificationAlertContainer}>
        <MetlifeCustomerNotification />
      </div>
    );
  }

  return (
    <React.Fragment>
      {/* BLUE - Priority 1 */}
      {shouldShowUpdateCDSNotification && (
        <div className={styles.notificationAlertContainer}>
          <UpdateCDSNotification />
        </div>
      )}
      {/* BLUE - Priority 1 */}
      {shouldShowMay2023DrawdownUpdateNotification && (
        <div className={styles.notificationAlertContainer}>
          <May2023DrawdownUpdateNotification />
        </div>
      )}
      {/* BLUE - Priority 2 */}
      {shouldShowPensionsReadyToAuthoriseNotification && (
        <div className={styles.notificationAlertContainer}>
          <PensionsReadyToAuthoriseNotification />
        </div>
      )}
      {/* BLUE - Priority 3 */}
      {shouldShowUpdatePensionPlanNotification && (
        <div className={styles.notificationAlertContainer}>
          <UpdatePensionPlanNotification />
        </div>
      )}
      {/* BLUE - Priority 4 */}
      {shouldShowUpdatingPensionPlanNotification && (
        <div className={styles.notificationAlertContainer}>
          <UpdatingPensionPlanNotification
            investmentAdviceReportDownloadUrl={investmentAdviceReportDownloadUrl}
          />
        </div>
      )}
      {/* BLUE - Priority 5 */}
      {shouldShowMarketingPreferencesNotification && (
        <div className={styles.notificationAlertContainer}>
          <MarketingPreferencesAlert />
        </div>
      )}
      {/* BLUE - Priority 6 */}
      {shouldShowTwoFactorAuthenticationNotification && isTFATheOnlyActiveNotification && (
        <div className={styles.notificationAlertContainer}>
          <EnableTwoFactorAuthenticationNotification />
        </div>
      )}
      {/* BLUE - Priority 7 */}
      {shouldShowCompletePensionRequestNotification && (
        <div className={styles.notificationAlertContainer}>
          <AddPensionRequestNotification />
        </div>
      )}
      {/* BLUE - Priority 8 */}
      {shouldShowCompleteContributionRequestNotification && (
        <div className={styles.notificationAlertContainer}>
          <AddContributionRequestNotification />
        </div>
      )}
      {/* BLUE - Priority 9 */}
      {shouldShowSERPSTraceIneligibleNotification && (
        <div className={styles.notificationAlertContainer}>
          <SerpsTraceIneligibleNotification />
        </div>
      )}
      {/* BLUE - Priority 10 */}
      {shouldShowSERPSCompleteRequestNotification && (
        <div className={styles.notificationAlertContainer}>
          <SerpsCompleteRequestNotification />
        </div>
      )}
      {/* BLUE - Priority 11 */}
      {shouldShowSERPSWaitingForHmrcResponseNotification && (
        <div className={styles.notificationAlertContainer}>
          <SerpsWaitingForHmrcNotification />
        </div>
      )}
      {/* BLUE - Priority  12*/}
      {shouldShowSERPSPensionFoundNotification && (
        <div className={styles.notificationAlertContainer}>
          <SerpsPensionFoundNotification />
        </div>
      )}
      {/* BLUE - Priority 13 */}
      {shouldShowNoSERPSPensionsFoundNotification && (
        <div className={styles.notificationAlertContainer}>
          <SerpsNoPensionsFoundNotification />
        </div>
      )}
      {/* GREEN */}
      {shouldShowRecPensionPlanNotification && (
        <div className={styles.notificationAlertContainer}>
          <RecommendedPensionPlanNotification />
        </div>
      )}
      {/* GREEN */}
      {shouldShowNoSwitchBannerNotification && (
        <div className={styles.notificationAlertContainer}>
          <NoSwitchBannerNotification
            investmentAdviceReportDownloadUrl={investmentAdviceReportDownloadUrl}
          />
        </div>
      )}
      {/* GREEN */}
      {inProgressLumpSumInstruction && (
        <div className={styles.notificationAlertContainer}>
          <NewLumpSumNotification
            instructionId={inProgressLumpSumInstruction}
          />
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const currentPensionPlanRecommendation = getCurrentPensionPlanRecommendation(state);
  return {
    currentPensionPlanRecommendation,
    currentRecSuitabilityReport: getCurrentRecommendationSuitabilityReport(state),
    notificationTracking: getNotificationTracking(state),
    vulnerableCustomerFromOngoing: getIsVulnerableCustomerFromOngoing(state),
    shouldShowMetlifeCustomerNotification: getShouldShowMetlifeCustomerNotification(state),
    shouldShowUpdateCDSNotification: getShouldShowUpdateCDSNotification(state),
    shouldShowMay2023DrawdownUpdateNotification: getShouldShowMay2023DrawdownUpdateNotification(state),
    shouldShowRecPensionPlanNotification: getShouldShowRecPensionPlanNotification(state),
    shouldShowUpdatePensionPlanNotification: getShouldShowUpdatePensionPlanNotification(state),
    shouldShowUpdatingPensionPlanNotification: getShouldShowUpdatingPensionPlanNotification(state),
    shouldShowNoSwitchBannerNotification: getShouldShowNoSwitchBannerNotification(state),
    shouldShowMarketingPreferencesNotification:
      getShouldShowMarketingPreferencesNotification(state),
    shouldShowPensionsReadyToAuthoriseNotification:
      getShouldShowPensionsReadyToAuthoriseNotification(state),
    inProgressLumpSumInstruction: getShouldShowNewOneOffLumpSumNotification(state),
    shouldShowTwoFactorAuthenticationNotification: getShouldShowTwoFactorAuthenticationNotification(state),
    currentlyActiveNotifications: getCurrentlyActiveNotifications(state),
    shouldShowCompletePensionRequestNotification: getShouldShowCompletePensionRequestNotification(state),
    shouldShowCompleteContributionRequestNotification: getShouldShowCompleteContributionRequestNotification(state),
    shouldShowSERPSWaitingForHmrcResponseNotification: getShouldShowSERPSWaitingForHmrcResponseNotification(state),
    shouldShowSERPSCompleteRequestNotification: getShouldShowSERPSCompleteRequestNotification(state),
    shouldShowSERPSTraceIneligibleNotification: getShouldShowSERPSTraceIneligibleNotification(state),
    shouldShowSERPSPensionFoundNotification: getShouldShowSERPSPensionFoundNotification(state),
    shouldShowNoSERPSPensionsFoundNotification: getShouldShowNoSERPSPensionsFoundNotification(state),
  };
};

export default compose(withFirestore, connect(mapStateToProps))(OngoingStatusSection);
