import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';

import { getInProgressContributionInstructions, getInProgressLumpSumInstructions } from '../../redux/selectors/investmentAdvice';
import {
  addMoneyOptionPageLanded,
  setUpOrAmendContributionOptionClicked,
  lumpSumOptionClicked,
} from '../../redux/modules/addMoneyOption';

import chevronRight from '../../assets/images/rebranding/chevron-right.svg';

import styles from './AddMoneyOption.css';

const AddMoneyOption = ({
  prefillAmount,
  history,
  inProgressContributionInstructions,
  inProgressLumpSumInstructions,
  onClick,
  dispatchAddMoneyOptionPageLanded,
  dispatchSetUpOrAmendContributionOptionClicked,
  dispatchLumpSumOptionClicked,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchAddMoneyOptionPageLanded();
  }, []);

  const hasInProgressContributionInstructions = Boolean(inProgressContributionInstructions.length);
  const hasInProgressLumpSumInstructions = Boolean(inProgressLumpSumInstructions.length);

  const setUpOrAmendContributionOnClick = () => {
    if (!hasInProgressContributionInstructions) {
      if (typeof onClick === 'function') {
        onClick();
      }

      let setupContributionUrl = '/setup-contribution';
      if (prefillAmount) {
        setupContributionUrl += `?amount=${prefillAmount}`;
      }

      history.push(setupContributionUrl);
    }
  };

  const setupLumpSumOnClick = () => {
    if (!hasInProgressLumpSumInstructions) {
      if (typeof onClick === 'function') {
        onClick();
      }

      history.push('/setup-lump-sum');
    }
  };

  return (
    <BaseGrid pageSectionStyles={{ marginBottom: '200px' }} addPensionsTheme>
      <div className={styles.contributionsContainer}>
        <ButtonLink
          to="/"
          label="← Back"
        />
        <h2 className={styles.infoboxHeading}>{'Add money to your pension'}</h2>
        {!hasInProgressContributionInstructions ? (
          <button
            className={classNames(styles.setUpOrAmendCard)}
            type="button"
            onClick={() => {
              dispatchSetUpOrAmendContributionOptionClicked();
              setUpOrAmendContributionOnClick();
            }}
          >
            <div>
              <p font="bold" className={styles.infoTextHeading}>{'Set up a regular personal contribution'}</p>
              <p className={styles.infoText}>{'Choose how much you would like to contribute each month by Direct Debit'}</p>
            </div>
            <div className={styles.vector}>
              <img src={chevronRight} alt="Right Chevron" />
            </div>
          </button>
        ) : (
          <div className={classNames(styles.setUpOrAmendCard)}>
            <div>
              <p font="bold" className={styles.infoTextHeading}>{'Set up a regular personal contribution'}</p>
              <p className={styles.infoText}>
                {'We are currently setting up your regular contribution. '}
                {'If you wish to make further requests, '}
                <ButtonLink variant="primary" font="inherit" target="_blank" rel="noreferrer" label="please contact your adviser." link="/inbox/new-message" />
              </p>
            </div>
          </div>
        )}
        <br />
        {!hasInProgressLumpSumInstructions ? (
          <button
            className={classNames(styles.setUpOrAmendCard)}
            type="button"
            onClick={() => {
              dispatchLumpSumOptionClicked();
              setupLumpSumOnClick();
            }}
          >
            <div>
              <p className={styles.infoTextHeading}>{'Make a one-off personal contribution'}</p>
              <p className={styles.infoText}>{'Choose how much you want to pay as a one-off payment (Bank transfer)'}</p>
            </div>
            <div className={styles.vector}>
              <img src={chevronRight} alt="Right Chevron" />
            </div>
          </button>
        ) : (
          <div className={classNames(styles.setUpOrAmendCard)}>
            <div>
              <p className={styles.infoTextHeading}>{'Make a one-off personal contribution'}</p>
              <p
                className={styles.infoText}
              >
                {'We are currently setting up your one-off contribution. If you wish to make another contribution, '}
                <ButtonLink variant="primary" font="inherit" target="_blank" rel="noreferrer" label="please contact your adviser." link="/inbox/new-message" />
              </p>
            </div>
          </div>
        )}
        <br />
        <br />
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'AddMoneyOption'}
            noExpertContentWrapper={({ children }) => (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexShrink: 'initial',
                padding: '10px',
                flexWrap: 'wrap',
              }}
              >
                {children}
              </div>
            )}
            showButton={false}
            customMessage={(
              <div className={styles.contactUsInnerContent}>
                {'If you would like to make a contribution from your business or if you have any questions, please feel free to '}
                <ButtonLink
                  variant="primary"
                  label="message me"
                  to="/inbox/new-message"
                  font="inherit"
                />
                {'.'}
              </div>
              )}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  inProgressContributionInstructions: getInProgressContributionInstructions(state),
  inProgressLumpSumInstructions: getInProgressLumpSumInstructions(state),
});

const mapDispatchToProps = ({
  dispatchAddMoneyOptionPageLanded: addMoneyOptionPageLanded,
  dispatchSetUpOrAmendContributionOptionClicked: setUpOrAmendContributionOptionClicked,
  dispatchLumpSumOptionClicked: lumpSumOptionClicked,
});

AddMoneyOption.propTypes = {
  prefillAmount: PropTypes.number,
};

AddMoneyOption.defaultProps = {
  prefillAmount: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddMoneyOption));
