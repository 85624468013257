exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);

// module
exports.push([module.id, ".-KeyInfoItem-wrapper-1-cNQ {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-align: start;\n      -ms-flex-align: start;\n          align-items: flex-start;\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["primary"] + ";\n  margin-bottom: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["gutter"] + ";\n}\n.-KeyInfoItem-content-ehpoy {\n  line-height: 1.67;\n}\n.-KeyInfoItem-content-ehpoy p {\n  margin: 0;\n}\n.-KeyInfoItem-light-10FO_ {\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["white"] + ";\n  font-family: 'Poppins';\n  font-weight: 300;\n}\n.-KeyInfoItem-icon-34RHN {\n  min-width: 32px;\n  padding: 7px 15px 0 0;\n}\n@media only screen and (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n  .-KeyInfoItem-light-10FO_ {\n    font-size: 1.3125rem;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"colors": "\"../../theme/colors.css\"",
	"primary": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["primary"] + "",
	"white": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["white"] + "",
	"dimensions": "\"../../theme/dimensions.css\"",
	"gutter": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["gutter"] + "",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"wrapper": "-KeyInfoItem-wrapper-1-cNQ",
	"content": "-KeyInfoItem-content-ehpoy",
	"light": "-KeyInfoItem-light-10FO_",
	"icon": "-KeyInfoItem-icon-34RHN"
};