import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';
import get from 'lodash/get';
import classnames from 'classnames';
import styles from './AddPensionsPage.css';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import HtmlSelectField from '../../components/HtmlSelectField/HtmlSelectField';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import { INDUSTRIES_LIST, transferOptions } from '../../util/constants';
import {
  returnLinkClickedAction,
  messageMeClickedAction,
  employerSectorStepLandedAction,
  employerSectorChangedAction,
  employerSectorSubmitClickedAction,
  employerSectorSkipStepClickedAction,
  tryFindAndCombineServiceClickedAction,
} from '../../redux/modules/pensions';

const EmploymentSectorStep = ({
  setFormData,
  setStep,
  formData,
  amendMode,
  step,
  totalSteps,
  dispatchReturnLinkClicked,
  dispatchMessageMeClicked,
  isCustomerOngoing,
  dispatchEmployerSectorStepLanded,
  dispatchTryFindAndCombineServiceClicked,
  dispatchEmployerSectorChanged: _dispatchEmployerSectorChanged,
  dispatchEmployerSectorSubmitClicked,
  dispatchSkipStepClicked,
  resetFormData,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchEmployerSectorStepLanded(isCustomerOngoing, formData.transferOption);
  }, []);

  const [dispatchEmployerSectorChanged] = useState(() => once(_dispatchEmployerSectorChanged));
  const [employerIndustry, setEmployerIndustry] = useState(formData.employerIndustry || '');
  const isTransferBasic = formData.transferOption === transferOptions.TRANSFER_BASIC;
  const isTransferWithCheck = formData.transferOption === transferOptions.TRANSFER_WITH_CHECK;

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.employerSectorWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
          <ButtonLink
            onClick={(event) => {
              dispatchReturnLinkClicked(isCustomerOngoing, formData.transferOption);
              setStep((_step) => _step - 1);
              event.stopPropagation();
            }}
            label="← Back"
          />
        )}
        <h2 className={styles.employerSectorHeading}>
          {'Which employment sector did you work in?'}
          {isTransferBasic && ('*')}
        </h2>
        <HtmlSelectField
          fullWidth
          wrapperStyles={{
            width: '100%',
            margin: '0 0 22px',
          }}
          placeholder="Select industry"
          options={INDUSTRIES_LIST.map((industry) => ({
            value: industry, label: industry,
          }))}
          onChange={(e) => {
            const _employerIndustry = get(e, 'target.value');
            dispatchEmployerSectorChanged(isCustomerOngoing, formData.transferOption);
            setEmployerIndustry(_employerIndustry);
          }}
          value={employerIndustry}
        />
        <div
          className={styles.submitContainer}
        >
          <div
            className={styles.employerSectorSubmitWrapper}
          >
            <Button
              size="large"
              disabled={!employerIndustry}
              label={(amendMode) ? 'Confirm' : 'Submit'}
              onClick={() => {
                dispatchEmployerSectorSubmitClicked(isCustomerOngoing, formData.transferOption);
                setFormData((_formData) => ({
                  ..._formData,
                  employerIndustry,
                }));
                setStep(step + 1);
              }}
            />
          </div>
          {isTransferWithCheck && (
            <ButtonLink
              variant="primary"
              label={<span className={styles.employerLinkText}>{'Skip this step'}</span>}
              onClick={() => {
                dispatchSkipStepClicked(isCustomerOngoing, formData.transferOption);
                setFormData((_formData) => ({
                  ..._formData,
                  employerIndustry: undefined,
                }));
                setStep((_step) => _step + 1);
              }}
            />
          )}
          {isTransferBasic && (
            <ButtonLink
              label={(
                <div>
                  <span className={classnames(styles.employerLinkText, styles.inlineBlock)}>{'Don’t have this information?'}</span>
                  <span className={classnames(styles.employerLinkText, styles.inlineBlock)}>{'Try our “find, check and transfer” service'}</span>
                </div>
              )}
              onClick={() => {
                dispatchTryFindAndCombineServiceClicked(isCustomerOngoing, formData.transferOption);
                resetFormData();
                setStep(0);
              }}
            />
          )}
        </div>
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'EmploymentSectorStep'}
            noExpertContentWrapper={({ children }) => (
              <div
                style={{
                  display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                }}
              >
                {children}
              </div>
            )}
            customMessage={(
              <div
                className={styles.contactUsInnerContent}
              >
                {'We use this information to help locate your pension. If you have any questions, please feel free to '}
                <ButtonLink
                  label="message me"
                  variant="primary"
                  to="/inbox/new-message"
                  font="inherit"
                  onClick={() => dispatchMessageMeClicked(
                    isCustomerOngoing,
                    formData.transferOption,
                  )}
                />
                {'.'}
              </div>
            )}
            showButton={false}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  dispatchReturnLinkClicked: returnLinkClickedAction,
  dispatchMessageMeClicked: messageMeClickedAction,
  dispatchEmployerSectorStepLanded: employerSectorStepLandedAction,
  dispatchEmployerSectorChanged: employerSectorChangedAction,
  dispatchEmployerSectorSubmitClicked: employerSectorSubmitClickedAction,
  dispatchSkipStepClicked: employerSectorSkipStepClickedAction,
  dispatchTryFindAndCombineServiceClicked: tryFindAndCombineServiceClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmploymentSectorStep);
