import React from 'react';
import { connect } from 'react-redux';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';

import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';

import {
  nextClickedAction,
  backClickedAction,
} from '../../redux/modules/withdrawal';

import CalenderImage from '../../assets/images/withdrawals/calender.svg';

import styles from './WithdrawalMiniJourney.css';
import { IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY } from './flows';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';

const WithdrawalCharges = ({
  flow,
  step,
  totalSteps,
  setStep,
  dispatchNextClicked,
  dispatchBackClicked,
}) => {
  return (
    <BaseGrid addPensionsTheme>
      <div className={styles.withdrawalsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          onClick={(event) => {
            dispatchBackClicked();
            setStep((_step) => _step - 1);
            event.stopPropagation();
          }}
          label="← Back"
        />
        <div className={styles.withdrawalsImageContainer}>
          <img src={CalenderImage} alt="calender" />
        </div>
        <h2 className={styles.headingTwo}>{'Pension withdrawal charges'}</h2>
        <div className={styles.withdrawalText}>{'There is no charge for withdrawing Tax Free Cash from your pension plan, however once your withdrawals exceed this amount, there is a single charge of £75 from your provider, Aegon. This is deducted annually where there has been a withdrawal in any 12 month period.'}</div>
        <div className={styles.greenBox}>{'Once any income you take exceeds the Tax Free Cash element, the income automatically stops. A new instruction would be required in order to continue income from the crystallised pension fund.'}</div>
        {flow === IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY && (
          <div className={styles.pensionAdviserContainer}>
            <div className={styles.pensionAdviserCard}>
              <ContactUsContent
                source={'CombinePensions'}
                noExpertContentWrapper={({ children }) => (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 'initial',
                    padding: '10px',
                    flexWrap: 'wrap',
                  }}
                  >
                    {children}
                  </div>
                )}
                showButton={false}
                customMessage={(
                  <div className={styles.contactUsInnerContent}>
                    {'Your Pension Adviser can help you to understand whether there are better options for your money. If you have any questions or want to discuss your withdrawal, '}
                    <span>
                      <ButtonLink
                        variant="primary"
                        to="/inbox/new-message"
                        search={'?subject=Withdrawal%20Request'}
                        displayAsHref
                        font="inherit"
                        label={'message them here'}
                      />
                      {'.'}
                    </span>
                  </div>
                )}
              />
            </div>
          </div>
        )}
        <Button
          size="large"
          label={flow === IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY ? 'I understand' : 'Continue'}
          onClick={() => {
            dispatchNextClicked();
            setStep((_step) => _step + 1);
          }}
        />
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  dispatchNextClicked: nextClickedAction,
  dispatchBackClicked: backClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalCharges);
