/**
 * Truncates a given text to a maximum number of characters, but only breaks full words.
 * Returns an object containing the truncated text and the remaining text.
 *
 * @param {string} text - The text to be truncated.
 * @param {number} maxChars - The maximum number of characters for the truncated text.
 * @returns {Object} An object containing the truncated text and the remaining text.
 */
export default function splitSentence(text, maxChars) {
  if (!text || !maxChars) {
    return { truncatedText: null, remainingText: null };
  }

  if (text.length < maxChars) {
    return { truncatedText: text, remainingText: null };
  }

  const wordArray = text.split(' ');
  const truncatedWords = [];
  let charCount = 0;
  let i = 0;

  while (
    i < wordArray.length
    && charCount + wordArray[i].length <= maxChars
  ) {
    truncatedWords.push(wordArray[i]);
    charCount += wordArray[i].length + (i === wordArray.length - 1 ? 0 : 1);
    i++;
  }

  const truncatedText = truncatedWords.join(' ');
  const remainingText = text.substring(truncatedText.length).trim();

  return { truncatedText, remainingText };
}
