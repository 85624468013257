exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-BaseLayout-dashboardHeaderSection-eeyvc {\n  width: 100%;\n  background-color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["lightSpaceSecondaryRegular"] + ";\n  padding: 10px 0 0 0;\n}\n.-BaseLayout-dashboardHeaderSectionWrapper-3IgiI {\n  max-width: 1440px;\n  margin: 0 auto;\n  padding: 0 20px;\n  display: grid;\n  grid-template-columns: repeat(6, 1fr);\n  grid-gap: 0;\n  grid-auto-rows: minmax(0px, auto);\n}\n@media (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + ") {\n  .-BaseLayout-dashboardHeaderSectionWrapper-3IgiI {\n    grid-template-columns: repeat(10, 1fr);\n    padding: 0 20px;\n  }\n\n  .-BaseLayout-dashboardHeaderSectionWrapper-3IgiI.-BaseLayout-tabletTwelveColumn-37EMO {\n    grid-template-columns: repeat(12, 1fr);\n  }\n}\n@media (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["typographyDesktop"] + ") {\n  .-BaseLayout-dashboardHeaderSectionWrapper-3IgiI.-BaseLayout-dashboardTheme-3Za94 {\n    padding: 0 80px;\n  }\n}\n@media (min-width: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["largeDesktop"] + ") {\n  .-BaseLayout-dashboardHeaderSectionWrapper-3IgiI {\n    grid-template-columns: repeat(12, 1fr);\n    padding: 10px 80px;\n  }\n\n  .-BaseLayout-dashboardHeaderSectionWrapper-3IgiI.-BaseLayout-dashboardTheme-3Za94 {\n    padding: 0 80px;\n  }\n}\n\n", ""]);

// exports
exports.locals = {
	"dimensions": "\"../../theme/dimensions.css\"",
	"largeDesktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["largeDesktop"] + "",
	"typographyDesktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["typographyDesktop"] + "",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"colors": "\"../../theme/colors.css\"",
	"lightSpaceSecondaryRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["lightSpaceSecondaryRegular"] + "",
	"dashboardHeaderSection": "-BaseLayout-dashboardHeaderSection-eeyvc",
	"dashboardHeaderSectionWrapper": "-BaseLayout-dashboardHeaderSectionWrapper-3IgiI",
	"tabletTwelveColumn": "-BaseLayout-tabletTwelveColumn-37EMO",
	"dashboardTheme": "-BaseLayout-dashboardTheme-3Za94"
};