import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import TextField from '../../components/TextField/TextField';

import styles from './SetupContributionPage.css';

import {
  accountDetailsVisitedAction,
  accountHolderNameChangedAction,
  accountNumberChangedAction,
  bankNameChangedAction,
  rollNumberChangedAction,
  sortCodeChangedAction,
} from '../../redux/modules/accountDetails';
import { getPensionExpert } from '../../redux/selectors/investmentAdvice';

const AccountDetails = ({
  formData,
  setFormData,
  instructionType,
  dispatchAccountDetailsVisited,
  isCustomerOngoing,
  dispatchSortCodeChanged,
  dispatchAccountHolderNameChanged,
  dispatchAccountNumberChanged,
  dispatchBankNameChanged,
  dispatchRollNumberChanged,
}) => {
  useEffect(() => {
    dispatchAccountDetailsVisited(instructionType, isCustomerOngoing);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.accountDetailsContainer}>
      <div className={styles.standardContainer}>
        <p className={styles.body}>{`Please provide the bank details you would like to ${instructionType === 'withdrawal' ? 'withdraw to' : 'make payments from'}.`}</p>
      </div>
      <TextField
        tallInput
        wrapperStyles={{ margin: 0 }}
        inputWrapperStyles={{ marginBottom: '20px', maxWidth: 'none' }}
        name="name"
        label={'Name'}
        defaultValue={'As seen on your account'}
        value={formData.name}
        onChange={(event) => {
          dispatchAccountHolderNameChanged(instructionType, isCustomerOngoing);
          setFormData({ ...formData, name: event.target.value });
        }}
      />
      <TextField
        tallInput
        wrapperStyles={{ margin: 0 }}
        inputWrapperStyles={{ marginBottom: '20px', maxWidth: 'none' }}
        name="sortCode"
        label={'Sort code'}
        defaultValue={'Eg. 13-03-55'}
        value={formData.sortCode}
        onChange={(event) => {
          dispatchSortCodeChanged(instructionType, isCustomerOngoing);
          const input = event.target.value;
          const digitsOnly = input.replace(/\D/g, '');
          const digitsOnlyLength = digitsOnly.length;
          if (digitsOnlyLength > 6) return;
          const sortCode = digitsOnly.split('').map((digit, index) => {
            const shouldAppendHyphen = (index === 1 && digitsOnly.length > 2)
              || (index === 3 && digitsOnly.length > 4);
            return shouldAppendHyphen ? `${digit}-` : digit;
          }).join('');
          setFormData({ ...formData, sortCode });
        }}
      />
      <TextField
        tallInput
        wrapperStyles={{ margin: 0 }}
        inputWrapperStyles={{ marginBottom: '20px', maxWidth: 'none' }}
        name="accountNumber"
        label={'Account number'}
        helpText="Most account numbers are 8 digits long. If your account number is 7 digits long, add a ‘0’ to the beginning."
        defaultValue={'Eg. 12345678'}
        value={formData.accountNumber}
        onChange={(event) => {
          dispatchAccountNumberChanged(instructionType, isCustomerOngoing);
          const input = event.target.value;
          const accountNumber = input.replace(/\D/g, '');
          const accountNumberLength = accountNumber.length;
          if (accountNumberLength > 8) return;
          setFormData({ ...formData, accountNumber });
        }}
      />
      {instructionType === 'withdrawal'
      && (
      <React.Fragment>
        <TextField
          tallInput
          wrapperStyles={{ margin: 0 }}
          inputWrapperStyles={{ marginBottom: '20px', maxWidth: 'none' }}
          name="bankName"
          label={'Bank name'}
          defaultValue={'Eg. Bank of Scotland'}
          value={formData.bankName}
          onChange={(event) => {
            dispatchBankNameChanged(instructionType, isCustomerOngoing);
            setFormData({ ...formData, bankName: event.target.value });
          }}
        />
        <TextField
          tallInput
          wrapperStyles={{ margin: 0 }}
          inputWrapperStyles={{ marginBottom: '20px', maxWidth: 'none' }}
          name="rollNo"
          label={'Building Society Roll Number (If you have one)'}
          helpText="Only applies to Building Society accounts. You can find it on your card, statement or passbook"
          defaultValue={'Roll Number'}
          value={formData.rollNo}
          onChange={(event) => {
            dispatchRollNumberChanged(instructionType, isCustomerOngoing);
            setFormData({ ...formData, rollNo: event.target.value });
          }}
        />
      </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  pensionAdviser: getPensionExpert(state),
});

const mapDispatchToProps = {
  dispatchAccountDetailsVisited: accountDetailsVisitedAction,
  dispatchSortCodeChanged: sortCodeChangedAction,
  dispatchAccountHolderNameChanged: accountHolderNameChangedAction,
  dispatchAccountNumberChanged: accountNumberChangedAction,
  dispatchBankNameChanged: bankNameChangedAction,
  dispatchRollNumberChanged: rollNumberChangedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
