import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextField from '../TextField/TextField';

export default class DateOfBirthField extends React.Component {
  constructor(props) {
    super(props);
    this.setAgeLimit = this.setAgeLimit.bind(this);
    this.hasDateSupport = this.hasDateSupport.bind(this);
  }

  setAgeLimit = (limit, toString = false) => {
    if (!toString) {
      return moment().subtract(limit, 'years').toDate();
    }
    return moment().subtract(limit, 'years').toISOString().substring(0, 10);
  }

  hasDateSupport = () => {
    const testInput = document.createElement('input');
    testInput.setAttribute('type', 'date'); // or any other type
    return testInput.type === 'date';
  }

  render() {
    const {
      minAge, maxAge, value, onChange, ...rest
    } = this.props;

    return (
      <TextField
        type="date"
        value={value}
        onChange={onChange}
        max={this.setAgeLimit(minAge, true)}
        min={this.setAgeLimit(maxAge, true)}
        {...rest}
      />
    );
  }
}

DateOfBirthField.propTypes = {
  minAge: PropTypes.number,
  maxAge: PropTypes.number,
};

DateOfBirthField.defaultProps = {
  minAge: 18,
  maxAge: 150,
};
