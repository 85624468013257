import React, { useState } from 'react';
import { withFormik } from 'formik';
import { validate } from 'validate.js';
import { compose } from 'redux';
import { connect } from 'react-redux';
import once from 'lodash/once';
import get from 'lodash/get';
import { withFirestore } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { renderTextField } from '../../util/formik-custom-fields';
import { updateNationalInsuranceNumber } from '../../redux/modules/investmentAdvice';
import {
  personalDetailsNationalInsuranceNumberTyped,
  personalDetailsNationalInsuranceNumberDeleted,
} from '../../redux/modules/signupFlow';
import { personalDetailsConstraints, convertFlattenedObjectToObject } from '../../util/completePersonalDetailsValidation';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Button/Button';
import styles from './PersonalDetails.css';

export const removeWhiteSpace = (string) => string.replace(/\s/g, '');

const UpdateNationalInsuranceNumber = ({
  values,
  isSubmitting,
  handleSubmit,
  handleChange,
  renderField,
  dispatchPersonalDetailsNationalInsuranceNumberTyped: _dispatchPersonalDetailsNationalInsuranceNumberTyped,
  dispatchPersonalDetailsNationalInsuranceNumberDeleted: _dispatchPersonalDetailsNationalInsuranceNumberDeleted,
  nationalInsuranceNumber,
  status = false,
}) => {
  const [dispatchPersonalDetailsNationalInsuranceNumberTyped] = useState(() => once(_dispatchPersonalDetailsNationalInsuranceNumberTyped || ''));
  const [dispatchPersonalDetailsNationalInsuranceNumberDeleted] = useState(() => once(_dispatchPersonalDetailsNationalInsuranceNumberDeleted || ''));
  const obscure = (string) => {
    return removeWhiteSpace(string).replace(/(?!^)[\s\S](?!$)/g, 'X');
  };

  const fieldConfig = () => {
    const ninoFieldConfig = {
      nationalInsuranceNumber: {
        type: 'nino',
        name: 'nationalInsuranceNumber',
        label: 'No National Insurance number has been added yet. Please provide this information to help us process your requests.',
        value: values.nationalInsuranceNumber,
        onChange: (event) => {
          handleChange(event);
          const value = get(event, 'target.value');
          if (!value) {
            dispatchPersonalDetailsNationalInsuranceNumberDeleted();
          } else {
            dispatchPersonalDetailsNationalInsuranceNumberTyped();
          }
        },
        component: renderTextField,
        defaultValue: 'Enter your National Insurance number',
        largeHeadings: false,
        inputWrapperStyles: { marginTop: '15px' },
        fieldStyles: { textOverflow: 'ellipsis' },
      },
    };
    return !status ? Object.keys(ninoFieldConfig).map((field) => (
      <div className={styles.inlineSelectField} key={ninoFieldConfig[field].name}>
        {renderField(ninoFieldConfig[field])}
        <div className={styles.actionRow}>
          <Button
            type="submit"
            label="Save"
            loading={isSubmitting}
            disabled={!values.nationalInsuranceNumber}
          />
        </div>
      </div>
    )) : <Loader />;
  };

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit}
    >
      <div>
        <h2 className={styles.heading}>
          {'National Insurance number'}
        </h2>
        {nationalInsuranceNumber
          ? <p className={styles.content}>{obscure(nationalInsuranceNumber)}</p>
          : fieldConfig()}
      </div>
    </form>
  );
};

const FormikEnhancer = withFormik({
  mapPropsToValues: (props) => {
    const { nationalInsuranceNumber } = props;
    return {
      nationalInsuranceNumber,
    };
  },
  validate: (formValues) => {
    const valuesToValidate = {};
    // Create a new object which matches the forms object but strings are set to empty string.
    Object.keys(formValues).forEach((value) => {
      valuesToValidate[value] = formValues[value] || '';
    });

    // Validate the current values from the form object against the constraints
    const validationResult = validate(
      pick(valuesToValidate, Object.keys(personalDetailsConstraints)),
      personalDetailsConstraints,
    );

    if (!validationResult) {
      return {};
    }
    return convertFlattenedObjectToObject(validationResult);
  },
  handleSubmit: async (values, { setSubmitting, props, setStatus }) => {
    const { dispatchUpdateDetails } = props;
    const data = {
      nationalInsuranceNumber: removeWhiteSpace(values.nationalInsuranceNumber),
    };
    await dispatchUpdateDetails(data);
    setSubmitting(false);
    setStatus(true);
  },
  displayName: 'UpdateNationalInsuranceNumber',
})(UpdateNationalInsuranceNumber);

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateDetails: (data) => dispatch(updateNationalInsuranceNumber(data)),
  dispatchPersonalDetailsNationalInsuranceNumberTyped:
    () => dispatch(personalDetailsNationalInsuranceNumberTyped()),
  dispatchPersonalDetailsNationalInsuranceNumberDeleted:
    () => dispatch(personalDetailsNationalInsuranceNumberDeleted()),
});

export default compose(
  withFirestore,
  withRouter,
  connect(null, mapDispatchToProps),
)(FormikEnhancer);
