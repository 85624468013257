import React from 'react';
import classnames from 'classnames';
import styles from './PlanNameBox.css';
import esgTag from '../../assets/images/esg-tag-white.svg';

const PlanNameBox = ({
  styleName,
  esg,
  planName,
  context,
}) => {
  return (
    <div className={classnames(
      styles.planNameBox,
      styles[`planNameBox-${styleName}`],
      {
        [styles.postCds]: context === 'post-cds',
      },
    )}
    >
      <h1 className={styles.planNameBoxHeading}>{planName}</h1>
      {esg && <img src={esgTag} alt="esg" />}
    </div>
  );
};

export default PlanNameBox;
