import firebase from 'firebase/compat/app';
import sentryException from '../../util/sentryException';

export const AUTHORISE_PAGE_LINK_CLICKED = 'pp/ongoingAdvice/AUTHORISE_PAGE_LINK_CLICKED';
export const AUTHORISE_PAGE_VISITED = 'pp/ongoingAdvice/AUTHORISE_PAGE_VISITED';

export const ACCEPT_ONGOING_DISPATCHED = 'pp/ongoingAdvice/ACCEPT_ONGOING_DISPATCHED';
export const ACCEPT_ONGOING_FULFILLED = 'pp/ongoingAdvice/ACCEPT_ONGOING_FULFILLED';
export const ACCEPT_ONGOING_REJECTED = 'pp/ongoingAdvice/ACCEPT_ONGOING_REJECTED';
export const ACCEPT_ONGOING_SUBMIT_CLICKED = 'pp/ongoingAdvice/ACCEPT_ONGOING_SUBMIT_CLICKED';
export const ACCEPT_ONGOING_SUBMIT_REJECTED = 'pp/ongoingAdvice/ACCEPT_ONGOING_SUBMIT_REJECTED';

export const SUITABLITY_REPORT_CLICKED = 'pp/ongoingAdvice/SUITABLITY_REPORT_CLICKED';
export const KEY_FEATURES_CLICKED = 'pp/ongoingAdvice/KEY_FEATURES_CLICKED';
export const ILLUSTRATION_CLICKED = 'pp/ongoingAdvice/ILLUSTRATION_CLICKED';
export const TERMS_OF_BUSINESS_CLICKED = 'pp/ongoingAdvice/TERMS_OF_BUSINESS_CLICKED';

export const AUTHORISE_IMPLEMENT_PLAN_UNSELECTED = 'pp/ongoingAdvice/AUTHORISE_IMPLEMENT_PLAN_UNSELECTED';
export const AUTHORISE_IMPLEMENT_PLAN_SELECTED = 'pp/ongoingAdvice/AUTHORISE_IMPLEMENT_PLAN_SELECTED';
export const AUTHORISE_TRANSFER_BALANCE_UNSELECTED = 'pp/ongoingAdvice/AUTHORISE_TRANSFER_BALANCE_UNSELECTED';
export const AUTHORISE_TRANSFER_BALANCE_SELECTED = 'pp/ongoingAdvice/AUTHORISE_TRANSFER_BALANCE_SELECTED';

export const suitabilityReportClickedAction = () => ({ type: SUITABLITY_REPORT_CLICKED });
export const keyFeaturesClickedAction = () => ({ type: KEY_FEATURES_CLICKED });
export const illustrationClickedAction = () => ({ type: ILLUSTRATION_CLICKED });
export const termsOfBusinessClickedAction = () => ({ type: TERMS_OF_BUSINESS_CLICKED });

export const authoriseImplementPlanUnselectedAction = () => (
  { type: AUTHORISE_IMPLEMENT_PLAN_UNSELECTED }
);
export const authoriseImplementPlanSelectedAction = () => (
  { type: AUTHORISE_IMPLEMENT_PLAN_SELECTED }
);
export const authoriseTransferBalanceUnselectedAction = () => (
  { type: AUTHORISE_TRANSFER_BALANCE_UNSELECTED }
);
export const authoriseTransferBalanceSelectedAction = () => (
  { type: AUTHORISE_TRANSFER_BALANCE_SELECTED }
);

export const authorisePageLinkClickedAction = () => (
  { type: AUTHORISE_PAGE_LINK_CLICKED }
);

export const authorisePageVisitedAction = () => (
  { type: AUTHORISE_PAGE_VISITED }
);

export const acceptOngoingSubmitClickedAction = () => (
  { type: ACCEPT_ONGOING_SUBMIT_CLICKED }
);

export const acceptOngoingSubmitRejectedAction = () => (
  { type: ACCEPT_ONGOING_SUBMIT_REJECTED }
);

export const acceptOngoingRecommendationAction = (request) => async (dispatch) => {
  const acceptOngoingRec = firebase.app().functions('europe-west1').httpsCallable('acceptOngoingRecommendation');
  dispatch({ type: ACCEPT_ONGOING_DISPATCHED });
  try {
    await acceptOngoingRec(request);
    dispatch({ type: ACCEPT_ONGOING_FULFILLED });
  } catch (e) {
    dispatch({ type: ACCEPT_ONGOING_REJECTED });
    sentryException(e, {
      section: 'authorise-change',
    });
    throw e;
  }
};
