import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import features from '../../util/features';
import BasePage from '../../components/BasePage/BasePage';
import { DocumentContainer, AttachmentContainer } from '../../components/DocumentContainer/DocumentContainer';
import TabFilter from '../../components/TabFilter/TabFilter';
import WithCollections, { COLLECTIONS } from '../../components/WithCollections/WithCollections';
import { getFirebaseUid, getFiles, getAttachmentFiles } from '../../redux/selectors';
import { getTargetInvestmentPlatformName } from '../../redux/selectors/targetInvestment';
import {
  documentsPageIllustrationClicked,
  documentsPageKeyFeaturesClicked,
  documentsPageTermsOfBusinessClicked,
} from '../../redux/modules/documentsPage';
import ArrowIcon from '../../assets/images/rebranding/right-arrow-inside-circle.svg';
import { PENSION_TRANSFER_KEY_FILES } from '../../util/constants';

import styles from './DocumentsPage.css';

class DocumentsPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelection = this.handleSelection.bind(this);
    this.state = { selected: ['all'] };
  }

  handleSelection(selected) {
    this.setState({ selected });
  }

  render() {
    const { documentFilter } = features;
    const { selected } = this.state;
    const {
      targetInvestmentPlatformName,
      dispatchDocumentsPageIllustrationClicked,
      dispatchDocumentsPageKeyFeaturesClicked,
      dispatchDocumentsPageTermsOfBusinessClicked,
      attachmentFiles,
    } = this.props;

    const hasAttachmentFiles = Boolean(attachmentFiles.length);

    return (
      <BasePage showAlerts messageWidget>
        <section className={styles.lightSection}>
          <div className={styles.container}>
            <h3 className={styles.pageHeading}>{'Documents'}</h3>
            {documentFilter && (
              <TabFilter
                onChange={this.handleSelection}
                selected={selected}
                items={[
                  { label: 'All', value: 'all' },
                  { label: 'Suitability Reports', value: 'suitability' },
                ]}
              />
            )}
            <DocumentContainer />
            {targetInvestmentPlatformName && targetInvestmentPlatformName.toLowerCase() === 'aegon' && (
              <React.Fragment>
                <h3 className={styles.staticDocumentsPageHeading}>{'Service Documents'}</h3>
                <div className={styles.staticDocumentContainer}>
                  <React.Fragment>
                    <div className={styles.staticDocumentRow}>
                      <p className={styles.staticDocumentTitle}>{'Illustration - Transfer Only'}</p>
                      <a
                        className={styles.staticDocumentLink}
                        href={PENSION_TRANSFER_KEY_FILES.illustrationNoArrangementFee}
                        onClick={dispatchDocumentsPageIllustrationClicked}
                        download
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img src={ArrowIcon} alt="download icon" />
                      </a>
                    </div>
                    <div className={styles.staticDocumentRow}>
                      <p className={styles.staticDocumentTitle}>{'Illustration - Find, Check and Transfer'}</p>
                      <a
                        className={styles.staticDocumentLink}
                        href={PENSION_TRANSFER_KEY_FILES.illustrationOnePercentArrangementFee}
                        onClick={dispatchDocumentsPageIllustrationClicked}
                        download
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img src={ArrowIcon} alt="download icon" />
                      </a>
                    </div>
                  </React.Fragment>
                  <div className={styles.staticDocumentRow}>
                    <p className={styles.staticDocumentTitle}>{'Key Features'}</p>
                    <a
                      className={styles.staticDocumentLink}
                      href={PENSION_TRANSFER_KEY_FILES.keyFeatures}
                      onClick={dispatchDocumentsPageKeyFeaturesClicked}
                      download
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img src={ArrowIcon} alt="download icon" />
                    </a>
                  </div>
                  <div className={styles.staticDocumentRow}>
                    <p className={styles.staticDocumentTitle}>{'Terms of Business'}</p>
                    <a
                      className={styles.staticDocumentLink}
                      href={PENSION_TRANSFER_KEY_FILES.termsOfBusiness}
                      onClick={dispatchDocumentsPageTermsOfBusinessClicked}
                      download
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img src={ArrowIcon} alt="download icon" />
                    </a>
                  </div>
                </div>
              </React.Fragment>
            )}
            {hasAttachmentFiles && (
              <AttachmentContainer />
            )}
          </div>
        </section>
      </BasePage>
    );
  }
}

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
  targetInvestmentPlatformName: getTargetInvestmentPlatformName(state),
  files: getFiles(state),
  attachmentFiles: getAttachmentFiles(state),
});

const mapDispatchToProps = ({
  dispatchDocumentsPageIllustrationClicked: documentsPageIllustrationClicked,
  dispatchDocumentsPageKeyFeaturesClicked: documentsPageKeyFeaturesClicked,
  dispatchDocumentsPageTermsOfBusinessClicked: documentsPageTermsOfBusinessClicked,
});

const DocumentsPageWithCollections = (props) => (
  <WithCollections
    context="documents-page"
    dependencies={[
      COLLECTIONS.USER,
      COLLECTIONS.ONBOARDING,
      COLLECTIONS.CONVERSATIONS,
    ]}
  >
    <DocumentsPage {...props} />
  </WithCollections>
);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(DocumentsPageWithCollections);
