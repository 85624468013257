import store from '../redux/store';
import PPAuth from './pp-auth';
import {
  authEmailPwdDispatch,
  twoFactorAuthEmailPwdFulfill,
  authEmailPwdReject,
  twoFactorAuthSMSDispatch,
  twoFactorAuthSMSFulfill,
  twoFactorAuthSMSReject,
  setPasswordDispatch,
  setPasswordFulfill,
  setPasswordReject,
  resetPasswordFulfill,
  singleFactorAuthLoginFulfill,
  FBLogin,
  refreshToken,
  FBRegenerateIdTokenFulfill,
  FBRegenerateIdTokenReject,
  FBRegenerateLogin,
  setPreAuthEmail,
} from '../redux/modules/auth';
// promises for auth fromPromise

const { dispatch } = store;

export const requestVerifyEmail = (ref) => new Promise((resolve, reject) => {
  PPAuth.verifyEmail(ref)
    .then(resolve)
    .catch((err) => {
      reject(err);
    });
});

export const signupResendEmail = (uid) => new Promise((resolve, reject) => {
  PPAuth.resendEmail(uid)
    .then(resolve)
    .catch((err) => {
      reject(err);
    });
});

export const signupRequestSMSCode = (uid) => new Promise((resolve, reject) => {
  PPAuth.verificationRequestSMS(uid)
    .then(resolve)
    .catch((err) => {
      reject(err);
    });
});

export const signupVerifySMSCode = (uid, code) => new Promise((resolve, reject) => {
  PPAuth.verifySMS(uid, code)
    .then(() => {
      dispatch(refreshToken());
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
});

export const forgotPasswordVerifySMSCode = (uid, code) => new Promise((resolve, reject) => {
  PPAuth.verifySMS(uid, code)
    .then(resolve)
    .catch((err) => {
      reject(err);
    });
});

export const setPassword = (password) => new Promise((resolve, reject) => {
  dispatch(setPasswordDispatch(password));
  PPAuth.setUserPassword(password)
    .then(() => { dispatch(setPasswordFulfill()); })
    .then(resolve)
    .catch((err) => {
      dispatch(setPasswordReject(err));
      reject(err);
    });
});

export const login = (email, pwd, recaptchaToken) => new Promise((resolve, reject) => {
  dispatch(authEmailPwdDispatch(email, pwd));
  dispatch(setPreAuthEmail(email));
  PPAuth.login(email, pwd, recaptchaToken)
    .then(({ authToken, uid }) => {
      if (authToken) {
        dispatch(singleFactorAuthLoginFulfill(authToken, uid));
        return dispatch(FBLogin(authToken));
      }
      return dispatch(twoFactorAuthEmailPwdFulfill(uid));
    })
    .then(resolve)
    .catch((err) => {
      dispatch(authEmailPwdReject(err));
      reject(err);
    });
});

export const generateNewIdToken = (
  currentAuthToken,
  recaptchaToken,
) => {
  return new Promise((resolve, reject) => {
    PPAuth.generateNewIdToken(currentAuthToken, recaptchaToken)
      .then(({ data = {} }) => {
        const { authToken } = data;
        if (authToken) {
          dispatch(FBRegenerateLogin(authToken));
          dispatch(FBRegenerateIdTokenFulfill());
          return;
        }

        throw new Error('No auth token');
      })
      .then(resolve)
      .catch((err) => {
        dispatch(FBRegenerateIdTokenReject());
        reject(err);
      });
  });
};

export const loginUnverifiedUser = (authToken) => dispatch(FBLogin(authToken));

export const loginVerifySMS = (uid, code, recaptchaToken) => new Promise((resolve, reject) => {
  dispatch(twoFactorAuthSMSDispatch(uid, code));
  PPAuth.loginVerifySMS(uid, code, recaptchaToken)
    .then(({ authToken }) => {
      dispatch(twoFactorAuthSMSFulfill(authToken));
    })
    .then(resolve)
    .catch((err) => {
      dispatch(twoFactorAuthSMSReject(err));
      reject(err);
    });
});

export const passwordResetVerifySms = (uid, code, pwd, recaptchaToken) => new Promise((resolve, reject) => {
  dispatch(twoFactorAuthSMSDispatch(uid, code));
  return PPAuth.passwordResetVerifySMS(uid, code, pwd, recaptchaToken)
    .then(({ authToken }) => {
      dispatch(twoFactorAuthSMSFulfill(authToken));
      return dispatch(resetPasswordFulfill(authToken));
    })
    .then(() => resolve())
    .catch((err) => {
      dispatch(twoFactorAuthSMSReject(err));
      reject(err);
    });
});

export const resendSMS = (uid, recaptchaToken) => new Promise((resolve, reject) => {
  PPAuth.resendSMS(uid, recaptchaToken)
    .then(resolve)
    .catch(reject);
});

export const passwordUpdate = (authToken, pwdCurrent, pwd) => new Promise((resolve, reject) => {
  PPAuth.passwordUpdate(authToken, pwdCurrent, pwd)
    .then(resolve)
    .catch(reject);
});

export const passwordResetLink = (email, recaptchaToken) => new Promise((resolve, reject) => {
  PPAuth.passwordResetLink(email, recaptchaToken)
    .then(resolve)
    .catch(reject);
});

export const queryPasswordResetRef = (passwordResetRef) => new Promise((resolve, reject) => {
  PPAuth.queryPasswordResetRef(passwordResetRef)
    .then(resolve)
    .catch(reject);
});

export const passwordReset = (pwd, id) => new Promise((resolve, reject) => {
  return PPAuth.passwordReset(pwd, id)
    .then(async ({ authToken, twoFactorRequired, uid }) => {
      if (!twoFactorRequired) {
        await dispatch(resetPasswordFulfill(authToken));
      }
      return { twoFactorRequired, uid, authToken };
    })
    .then((userData) => resolve(userData))
    .catch(reject);
});
