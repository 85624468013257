import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classnames from 'classnames';

import trustpilotLogo from '../../assets/images/trustpilot-logo.png';
import TrustpilotStars from '../TrustpilotStars/TrustpilotStars';
import fcss from '../../assets/images/fcss.svg';

import styles from './TrustpilotAndFSCSWidget.css';

const TrustpilotAndFSCSWidget = ({
  trustpilot,
  columnMobileRowTabletAndDesktopLayout,
}) => {
  const totalReviews = get(trustpilot, 'tpBusinessUnits.numberOfReviews.total');
  const stars = get(trustpilot, 'tpBusinessUnits.score.stars');
  const trustScore = get(trustpilot, 'tpBusinessUnits.score.trustScore');

  return (
    <div className={classnames(styles.trustpilotContainer, {
      [styles.columnMobileRowTabletAndDesktopLayout]: columnMobileRowTabletAndDesktopLayout,
    })}
    >
      <a
        className={classnames(styles.trustpilotCard, {
          [styles.columnMobileRowTabletAndDesktopLayout]: columnMobileRowTabletAndDesktopLayout,
        })}
        href="https://uk.trustpilot.com/review/www.profilepensions.co.uk"
        target="_blank"
        rel="nofollow noreferrer noopener"
      >
        <div className={styles.trustpilotCardInner}>
          <div className={styles.trustpilotLine}>
            <div className={styles.totalReviews}>
              {'Check out our '}
              {totalReviews && <b>{`${totalReviews.toLocaleString()} `}</b>}
              {'reviews'}
            </div>
            <div className={styles.trustpilotStars}>
              <TrustpilotStars stars={stars} trustScore={trustScore} />
            </div>
          </div>
          <div className={styles.trustpilotLogoContainer}>
            <img src={trustpilotLogo} className={styles.trustpilotLogo} alt="Trustpilot logo" />
          </div>
        </div>
      </a>
      <div className={classnames(styles.fcssCard, {
        [styles.columnMobileRowTabletAndDesktopLayout]: columnMobileRowTabletAndDesktopLayout,
      })}
      >
        <img src={fcss} alt="FCSS logo" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  trustpilot: state.trustpilot,
});

export default connect(mapStateToProps)(TrustpilotAndFSCSWidget);
