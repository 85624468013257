import React from 'react';

import StepOne from '../../assets/images/mfmAnnouncementModal/step-one.png';
import StepTwo from '../../assets/images/mfmAnnouncementModal/step-two.png';
import StepThree from '../../assets/images/mfmAnnouncementModal/step-three.png';
import StepFour from '../../assets/images/mfmAnnouncementModal/step-four.png';

const introModalContent = () => [
  {
    heading: 'Profile Pensions has become part of Moneyfarm',
    body: 'As far as your pension goes, not much will change - it will be carefully looked after, just as it always has been.',
    ctaLabel: 'Next',
    icon: StepOne,
    iconWidth: '225px',
  },
  {
    heading: 'Who are Moneyfarm?',
    body: (
      <span>
        <a href="https://www.moneyfarm.com/uk/" target="_blank" rel="noreferrer">{'Moneyfarm'}</a>
        {' is a leading European digital wealth manager whose aim is to make finance simple and accessible.'}
      </span>
    ),
    ctaLabel: 'Next',
    icon: StepTwo,
    iconWidth: '225px',
  },
  {
    heading: 'What does this mean for you?',
    body: 'In the near future, you’ll be able to access your pension and open new savings and investment accounts online and in-app.',
    ctaLabel: 'Next',
    icon: StepThree,
    iconWidth: '225px',
  },
  {
    heading: 'Your dedicated Pension Adviser',
    body: 'If you have any questions, feel free to contact your Pension Adviser in your online account, and they’ll be happy to help.',
    ctaLabel: 'View my dashboard',
    icon: StepFour,
    iconWidth: '225px',
  },
];

export default introModalContent;
