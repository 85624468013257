import React from 'react';
import { connect } from 'react-redux';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { SERPS_INELIGIBLE_DISMISSED, dispatchSerpsTraceIneligibleNotificationDismissed } from '../../redux/modules/notificationTracking';
import { getFirebaseUid } from '../../redux/selectors';

const SerpsTraceIneligibleNotification = ({
  uid,
  dispatchSerpsTraceNotificationDismissed,
}) => {
  return (
    <NotificationBanner
      alertCategory={'Blue'}
      alertTitle={'SERPS Update'}
      alertDescription={'Based on your age, we don’t think it’s likely you would have a SERPS pension.'}
      closeButtonOptions={{
        event: SERPS_INELIGIBLE_DISMISSED,
        handleClick: () => dispatchSerpsTraceNotificationDismissed(uid),
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
});

const mapDispatchToProps = ({
  dispatchSerpsTraceNotificationDismissed: dispatchSerpsTraceIneligibleNotificationDismissed,
});

export default connect(mapStateToProps, mapDispatchToProps)(SerpsTraceIneligibleNotification);
