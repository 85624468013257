import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './AddPensionsPage.css';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import {
  employerPensionStepLandedAction,
  returnLinkClickedAction,
  messageMeClickedAction,
  employerPensionSubmitClickedAction,
  employerPensionYesSelectedAction,
  employerPensionNoSelectedAction,
} from '../../redux/modules/pensions';
import RadioOptions from '../../components/RadioOptions/RadioOptions';

const EmployerPensionStep = ({
  setFormData,
  setStep,
  amendMode,
  step,
  totalSteps,
  setUnmatchedEmployerPensionFlow,
  dispatchEmployerPensionStepLanded,
  isCustomerOngoing,
  dispatchReturnLinkClicked,
  dispatchMessageMeClicked,
  dispatchEmployerPensionSubmitClicked,
  dispatchEmployerPensionYesSelected,
  dispatchEmployerPensionNoSelected,
  formData,
  setUnmatchedFlow,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchEmployerPensionStepLanded(isCustomerOngoing, formData.transferOption);
  }, []);
  const defaultValue = typeof formData.isOldEmployerPension === 'boolean' ? formData.isOldEmployerPension : undefined;
  const [isOldEmployerPension, setIsOldEmployerPension] = useState(defaultValue);

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.employerPensionWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
          <ButtonLink
            onClick={(event) => {
              dispatchReturnLinkClicked(isCustomerOngoing, formData.transferOption);
              setStep((_step) => _step - 1);
              event.stopPropagation();
            }}
            label="← Back"
          />
        )}
        <h3 className={styles.employerPensionHeading}>
          {'Is this an old employer pension?*'}
        </h3>
        <RadioOptions
          optionOneClickHandler={() => {
            dispatchEmployerPensionYesSelected(isCustomerOngoing, formData.transferOption);
            setIsOldEmployerPension(true);
          }}
          isOptionOneSelected={isOldEmployerPension === true}
          optionTwoClickHandler={() => {
            dispatchEmployerPensionNoSelected(isCustomerOngoing, formData.transferOption);
            setIsOldEmployerPension(false);
          }}
          isOptionTwoSelected={isOldEmployerPension === false}
        />
        <div
          className={styles.submitContainer}
        >
          <Button
            size="large"
            disabled={typeof isOldEmployerPension !== 'boolean'}
            label={(amendMode) ? 'Confirm' : 'Submit'}
            onClick={() => {
              dispatchEmployerPensionSubmitClicked(isCustomerOngoing, formData.transferOption);
              setFormData((_formData) => ({
                ..._formData,
                isOldEmployerPension,
                ...(!isOldEmployerPension && {
                  employerLocation: undefined,
                  employerIndustry: undefined,
                  employmentStartDate: '',
                  employmentEndDate: '',
                }),
              }));
              if (isOldEmployerPension) {
                setUnmatchedEmployerPensionFlow();
              } else {
                setUnmatchedFlow();
              }
              setStep((_step) => _step + 1);
            }}
          />
        </div>
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'EmployerPensionStep'}
            noExpertContentWrapper={({ children }) => (
              <div
                style={{
                  display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                }}
              >
                {children}
              </div>
            )}
            customMessage={(
              <div
                className={styles.contactUsInnerContent}
              >
                {'If you have any questions, please feel free to '}
                <ButtonLink
                  label="message me"
                  variant="primary"
                  to="/inbox/new-message"
                  font="inherit"
                  onClick={() => dispatchMessageMeClicked(
                    isCustomerOngoing,
                    formData.transferOption,
                  )}
                />
                {'.'}
              </div>
                )}
            showButton={false}
          />
        </div>
      </div>

    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  dispatchEmployerPensionStepLanded: employerPensionStepLandedAction,
  dispatchReturnLinkClicked: returnLinkClickedAction,
  dispatchMessageMeClicked: messageMeClickedAction,
  dispatchEmployerPensionSubmitClicked: employerPensionSubmitClickedAction,
  dispatchEmployerPensionYesSelected: employerPensionYesSelectedAction,
  dispatchEmployerPensionNoSelected: employerPensionNoSelectedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployerPensionStep);
