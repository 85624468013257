import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';
import get from 'lodash/get';
import styles from './AddPensionsPage.css';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import {
  returnLinkClickedAction,
  employerLocationLandedAction,
  employerLocationChangedAction,
  messageMeClickedAction,
  employerLocationSubmitClickedAction,
} from '../../redux/modules/pensions';

const EmployerLocationStep = ({
  setFormData,
  setStep,
  amendMode,
  step,
  totalSteps,
  dispatchReturnLinkClicked,
  isCustomerOngoing,
  dispatchEmployerLocationLanded,
  dispatchEmployerLocationChanged: _dispatchEmployerLocationChanged,
  dispatchMessageMeClicked,
  dispatchEmployerLocationSubmitClicked,
  formData,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchEmployerLocationLanded(isCustomerOngoing, formData.transferOption);
  }, []);

  const [dispatchEmployerLocationChanged] = useState(() => once(_dispatchEmployerLocationChanged));
  const [employerLocation, setEmployerLocation] = useState(formData.employerLocation || undefined);
  const [error, setError] = useState('');

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.employerLocationWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
          <ButtonLink
            onClick={(event) => {
              dispatchReturnLinkClicked(isCustomerOngoing, formData.transferOption);
              setStep((_step) => _step - 1);
              event.stopPropagation();
            }}
            label="← Back"
          />
        )}
        <h2 className={styles.employerLocationHeading}>
          {'Where was your employer located?* (Head office or your office location)'}
        </h2>
        <input
          className={styles.employerLocationInput}
          placeholder={'Enter employer location'}
          value={employerLocation}
          onChange={(event) => {
            const value = get(event, 'target.value');
            if (error) setError('');
            dispatchEmployerLocationChanged(isCustomerOngoing, formData.transferOption);
            setEmployerLocation(value);
          }}
        />
        { error && <p className={styles.error}>{error}</p>}
        <div
          className={styles.submitContainer}
        >
          <Button
            size="large"
            disabled={!employerLocation || error}
            label={(amendMode) ? 'Confirm' : 'Submit'}
            onClick={() => {
              dispatchEmployerLocationSubmitClicked(isCustomerOngoing, formData.transferOption);

              const anyLetters = (/[a-z]|[A-Z]+/).test(employerLocation);

              if (!anyLetters) {
                setError('Please enter a valid location');
                return;
              }

              setFormData((_formData) => ({
                ..._formData,
                employerLocation,
              }));
              setStep(step + 1);
            }}
          />
        </div>
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'EmployerLocationStep'}
            noExpertContentWrapper={({ children }) => (
              <div
                style={{
                  display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                }}
              >
                {children}
              </div>
            )}
            customMessage={(
              <div
                className={styles.contactUsInnerContent}
              >
                {'We use this information to help locate your pension. If you have any questions, please feel free to '}
                <ButtonLink
                  label="message me"
                  variant="primary"
                  to="/inbox/new-message"
                  font="inherit"
                  onClick={() => dispatchMessageMeClicked(
                    isCustomerOngoing,
                    formData.transferOption,
                  )}
                />
                {'.'}
              </div>
                )}
            showButton={false}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  dispatchReturnLinkClicked: returnLinkClickedAction,
  dispatchEmployerLocationLanded: employerLocationLandedAction,
  dispatchEmployerLocationChanged: employerLocationChangedAction,
  dispatchMessageMeClicked: messageMeClickedAction,
  dispatchEmployerLocationSubmitClicked: employerLocationSubmitClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployerLocationStep);
