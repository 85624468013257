import React from 'react';
import get from 'lodash/get';
import classnames from 'classnames';

import { ADVISER_FEE } from '../../util/constants';

import pplogo from '../../assets/images/rebranding/pp-logo.svg';
import limeTick from '../../assets/images/lime-tick.svg';
import greyCross from '../../assets/images/grey-cross.svg';

import styles from './HowWeCompare.css';

const HowWeCompare = ({ targetInvestment, msmStyles, comparisonGridScroll }) => {
  const fmc = get(targetInvestment, 'fmc');
  const platformFee = get(targetInvestment, 'platformFee');
  const annualCharge = (fmc + platformFee + ADVISER_FEE).toFixed(2);

  return (
    <React.Fragment>
      <h3 className={classnames({
        [styles.compareSubheading]: !msmStyles,
        [styles.msmCompareSubheading]: msmStyles,
      })}
      >
        {'How we compare'}
      </h3>
      <div
        className={classnames(styles.comparisonGridContainer, {
          [styles.msmComparisonGridContainer]: msmStyles,
        })}
        onScroll={comparisonGridScroll}
      >
        <div className={styles.comparisonGrid}>
          <div className={styles.gridSpacer} />
          <div className={styles.greenTopGridSpacer} />
          <div className={styles.gridSpacer} />
          <div className={styles.gridSpacer} />
          <div className={styles.headerRowHeader} />
          <div className={styles.profilePensionsHeader}>
            <img className={styles.ppLogo} src={pplogo} alt="Profile Pensions logo" />
          </div>
          <div className={styles.otherCompaniesHeader}>
            <div className={styles.headerBody}>{'Other pension companies'}</div>
          </div>
          <div className={styles.independentHeader}>
            <div className={styles.headerBody}>{'Independent Financial Adviser (IFA)'}</div>
          </div>
          <div className={styles.subheaderRowHeader} />
          <div className={styles.profilePensionsSubheader}>
            <div className={styles.ppHeaderText}>{'Your recommended pension plan'}</div>
          </div>
          <div className={styles.otherCompaniesSubheader}>
            <div className={styles.otherHeaderText}>{'eg. Standard Life, Scottish Widows, Pension Bee'}</div>
          </div>
          <div className={styles.independentSubheader}>
            <div className={styles.otherHeaderText}>{'eg. St. James’s Place, Quilter'}</div>
          </div>
          <div className={styles.rowHeader}>
            {'Personalised plan'}
          </div>
          <div className={styles.profileColumnCell}>
            <img className={styles.greenTick} src={limeTick} alt="Green tick" />
          </div>
          <div className={styles.otherColumnCell}>
            <img className={styles.greyCross} src={greyCross} alt="Grey cross" />
          </div>
          <div className={styles.indepColumnCell}>
            <img className={styles.greenTick} src={limeTick} alt="Green tick" />
          </div>
          <div className={styles.rowHeader}>
            {'Ongoing advice'}
          </div>
          <div className={styles.profileColumnCell}>
            <img className={styles.greenTick} src={limeTick} alt="Green tick" />
          </div>
          <div className={styles.otherColumnCell}>
            <img className={styles.greyCross} src={greyCross} alt="Grey cross" />
          </div>
          <div className={styles.indepColumnCell}>
            <img className={styles.greenTick} src={limeTick} alt="Green tick" />
          </div>
          <div className={styles.rowHeader}>
            {'Whole-of-market fund coverage'}
          </div>
          <div className={styles.profileColumnCell}>
            <img className={styles.greenTick} src={limeTick} alt="Green tick" />
          </div>
          <div className={styles.otherColumnCell}>
            <img className={styles.greyCross} src={greyCross} alt="Grey cross" />
          </div>
          <div className={styles.indepColumnCell}>
            {'Some'}
          </div>

          <div className={styles.rowHeader}>
            {'Find and consolidate old pensions'}
          </div>
          <div className={styles.profileColumnCell}>
            <img className={styles.greenTick} src={limeTick} alt="Green tick" />
          </div>
          <div className={styles.otherColumnCell}>
            {'Some'}
          </div>
          <div className={styles.indepColumnCell}>
            <img className={styles.greyCross} src={greyCross} alt="Grey cross" />
          </div>

          <div className={styles.rowHeader}>
            {'No minimum investment'}
          </div>
          <div className={styles.profileColumnCell}>
            <img className={styles.greenTick} src={limeTick} alt="Green tick" />
          </div>
          <div className={styles.otherColumnCell}>
            <img className={styles.greenTick} src={limeTick} alt="Green tick" />
          </div>
          <div className={styles.indepColumnCell}>
            <img className={styles.greyCross} src={greyCross} alt="Grey cross" />
          </div>

          <div className={styles.rowHeaderBottom}>
            {'Typical total annual cost'}
          </div>
          <div className={styles.profileColumnCellBottom}>
            {`${annualCharge}%`}
          </div>
          <div className={styles.otherColumnCellBottom}>
            {'0.50% - 1.10%'}
          </div>
          <div className={styles.indepColumnCellBottom}>
            {'1.25% - 2.00%'}
          </div>
          <div className={styles.gridSpacer} />
          <div className={styles.greenBottomGridSpacer} />
          <div className={styles.gridSpacer} />
          <div className={styles.gridSpacer} />
        </div>
      </div>
    </React.Fragment>
  );
};

HowWeCompare.defaultProps = {
  comparisonGridScroll: () => {},
};

export default HowWeCompare;
