/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

import MentionMeTile from '../../../components/MentionMeTile/MentionMeTile';
import RecommendedPensionPlanCard from '../../../components/RecommendedPensionPlanCard/RecommendedPensionPlanCard';
import AddMoneyCard from '../../../components/AddMoneyCard/AddMoneyCard';
import AddPensionCard from '../../../components/AddPensionCard/AddPensionCard';
import ContactUs from '../sections/ContactUs';

import { getPensions } from '../../../redux/selectors/pensions';
import { getFirstName, getEmail } from '../../../redux/selectors';

import {
  getPensionExpert,
  getForceMentionMeModal,
  getHasInProgressContributionInstruction,
  getHasInProgressLumpSumInstructions,
} from '../../../redux/selectors/investmentAdvice';

import {
  unsetForceMentionMeModalAction,
  addMoneyFromDashboardClickedAction,
  addPensionFromDashboardClickedAction,
} from '../../../redux/modules/investmentAdvice';

import { MENTION_ME_LINK_CLICKED } from '../../../redux/modules/dashboard';

import styles from '../DashboardPage.css';
import { SHOW_MENTION_ME } from '../../../util/constants';

const Onboarding = (props) => {
  const {
    pensions,
    pensionExpert,
    firstName,
    email,
    hasInProgressContributionInstruction,
    hasInProgressLumpSumInstructions,
    forceMentionMeModal,
    dispatchAddPensionFromDashboardClicked,
    dispatchAddMoneyFromDashboardClicked,
    dispatchUnsetForceMentionMeModal,
    mentionMeLinkClicked,
  } = props;

  const hasPensions = Boolean(pensions.length);
  const hasPensionOrInstruction = hasPensions
    || hasInProgressContributionInstruction
    || hasInProgressLumpSumInstructions;

  return (
    <React.Fragment>
      <div className={styles.pensionPlanWrapper}>
        <RecommendedPensionPlanCard />
      </div>
      <div
        className={
          classNames(
            styles.selectOptionWrapper,
            {
              [styles.singleColumn]: hasPensionOrInstruction,
            },
          )
        }
      >
        <div className={styles.selectOptionTextContainer}>
          <h3 className={styles.selectOptionText}>
            {'Select an option to open your recommended pension plan'}
          </h3>
        </div>
        <AddPensionCard
          onClick={dispatchAddPensionFromDashboardClicked}
        />
        <AddMoneyCard
          onClick={dispatchAddMoneyFromDashboardClicked}
        />
      </div>
      <div className={styles.contactUsWrapper}>
        <ContactUs
          pensionExpert={pensionExpert}
          firstName={firstName}
          email={email}
        />
      </div>
      {hasPensionOrInstruction && SHOW_MENTION_ME && (
        <div className={styles.mmContainer}>
          <MentionMeTile />
          <div id="mmWrapper" onClick={forceMentionMeModal ? dispatchUnsetForceMentionMeModal : mentionMeLinkClicked} />
          {' '}
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  pensions: getPensions(state),
  pensionExpert: getPensionExpert(state),
  firstName: getFirstName(state),
  email: getEmail(state),
  hasInProgressContributionInstruction: getHasInProgressContributionInstruction(state),
  hasInProgressLumpSumInstructions: getHasInProgressLumpSumInstructions(state),
  forceMentionMeModal: getForceMentionMeModal(state),
});

const mapDispatchToProps = {
  dispatchAddPensionFromDashboardClicked: addPensionFromDashboardClickedAction,
  dispatchAddMoneyFromDashboardClicked: addMoneyFromDashboardClickedAction,
  dispatchUnsetForceMentionMeModal: unsetForceMentionMeModalAction,
  mentionMeLinkClicked: () => ({ type: MENTION_ME_LINK_CLICKED }),
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect(),
)(withRouter(Onboarding));
