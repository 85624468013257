import get from 'lodash/get';
import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import WithCollections, { COLLECTIONS } from '../../components/WithCollections/WithCollections';
import {
  backButtonClickedAction,
  getProjectedIncome,
  getProjectedIncomeWithCache,
  saveProjectionUserScenarios,
  RETIREMENT_INCOME_GET_CURRENT_PROJECTION_DISPATCHED,
  RETIREMENT_INCOME_GET_CURRENT_PROJECTION_FULFILLED,
  RETIREMENT_INCOME_GET_CURRENT_PROJECTION_REJECTED,
  RETIREMENT_INCOME_GET_NEW_PROJECTION_DISPATCHED,
  RETIREMENT_INCOME_GET_NEW_PROJECTION_FULFILLED,
  RETIREMENT_INCOME_GET_NEW_PROJECTION_REJECTED,
} from '../../redux/modules/projection';
import {
  getAge,
  getFirebaseUid,
  getGender,
  getIntendedRetirementAge,
  getLatestPensionValue,
  getModelDescriptor,
  getVerifiedPersonalContributionsValue,
  getVerifiedNetPersonalContributions,
  getVerifiedGrossEmployerContributions,
} from '../../redux/selectors';
import { getLastProjection, getCachedRetirementIncomePlanValues } from '../../redux/selectors/projections';
import {
  CREATE_SCENARIO_STEP,
  FIRST_TIME_USER_FLOW,
  INITIAL_STEP,
  RESULTS_STEP,
  SUGGESTION_STEP,
  determineFlow,
  getIsHighValueContributionCustomer,
  getHasCompletedFlow,
} from './flows';
import RetirementIncomeCreateScenarioStep from './RetirementIncomeCreateScenarioStep';
import RetirementIncomeInitialStep from './RetirementIncomeInitialStep';

import styles from './RetirementIncomePage.css';
import RetirementIncomeResultsStep from './RetirementIncomeResultsStep';
import RetirementIncomeSuggestionStep from './RetirementIncomeSuggestionStep';
import {
  initialState,
  reducer,
  LOAD_PROJECTION,
  POPULATE_ANNUAL_INCOME,
  RESET_FLOW,
  TOGGLE_SCENARIO,
  VALUE_CHANGE,
} from './state';
import { getEstimatedPensionSavings } from './Utils';

const RetirementIncomePage = ({
  uid,
  saveResults,
  latestProjection,
  cachedRetirementIncomePlanValues,
  currentContribution,
  getProjection,
  gender,
  currentAge,
  retirementAge,
  estimatedPensionSavings,
  portfolio,
  dispatchBackButtonClicked,
  verifiedNetPersonalContributions,
  verifiedGrossEmployerContributions,
  getProjectionWithCache,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const isHighValueContributionCustomer = getIsHighValueContributionCustomer(currentContribution);
  const hasCompletedFlow = getHasCompletedFlow(latestProjection);

  const [journey, setJourney] = useState(FIRST_TIME_USER_FLOW);
  const [step, setStep] = useState(0);

  const {
    scenarios, currentProjectedAnnualIncome, newProjectedAnnualIncome,
  } = state;

  useEffect(() => {
    if (window && window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  useEffect(() => {
    setJourney(determineFlow({ currentContribution, latestProjection }));
  }, [currentContribution, latestProjection]);

  useEffect(() => {
    if (step === journey.length) {
      setStep(step - 1);
    }
  }, [journey, step]);

  const onNextStep = () => {
    if (step === journey.length - 2) {
      return;
    }
    setStep((s) => s + 1);
  };

  const stepsMap = {
    [INITIAL_STEP]: RetirementIncomeInitialStep,
    [SUGGESTION_STEP]: RetirementIncomeSuggestionStep,
    [CREATE_SCENARIO_STEP]: RetirementIncomeCreateScenarioStep,
    [RESULTS_STEP]: RetirementIncomeResultsStep,
  };

  const onMonetaryValueChange = (scenario) => (value, valueMask, error) => {
    return dispatch({
      type: VALUE_CHANGE, scenario, value, valueMask, error,
    });
  };

  const onChangeRetirementAge = (value, error) => {
    return dispatch({
      type: VALUE_CHANGE, scenario: 'changeRetirementAge', value, error,
    });
  };

  const loadPreviousProjection = (s, currentAnnualIncome, newAnnualIncome) => {
    return dispatch({
      type: LOAD_PROJECTION,
      scenarios: s,
      currentProjectedAnnualIncome: currentAnnualIncome,
      newProjectedAnnualIncome: newAnnualIncome,
    });
  };

  const toggleScenario = (scenario) => {
    return dispatch({
      type: TOGGLE_SCENARIO, scenario,
    });
  };

  const resetFlow = () => dispatch({ type: RESET_FLOW });

  const navigateToCreateScenario = () => {
    resetFlow();
    setStep(journey.length - 2);
  };

  const populateAnnualIncome = (currentAnnualIncome, newAnnualIncome) => {
    return dispatch({
      type: POPULATE_ANNUAL_INCOME,
      currentProjectedAnnualIncome: currentAnnualIncome,
      newProjectedAnnualIncome: newAnnualIncome,
    });
  };

  const navigateToResultsPage = () => setStep(journey.length - 1);

  const onResultsStep = async () => {
    setLoading(true);
    setErrorMessage(null);

    const currentCustomerData = {
      gender,
      currentAge,
      retirementAge,
      estimatedPensionSavings,
      portfolio,
      statePension: false,
      estimatedMonthlyContributions: verifiedNetPersonalContributions || 0,
      grossMonthlyContributions: verifiedGrossEmployerContributions || 0,
    };

    const newCustomerData = {
      ...currentCustomerData,
      ...((scenarios.changeRetirementAge.enabled
        && { retirementAge: scenarios.changeRetirementAge.value }) || {}),
      ...((scenarios.monthlyContributions.enabled
          && { estimatedMonthlyContributions: scenarios.monthlyContributions.value || 0 }) || {}),
      estimatedPensionSavings: getEstimatedPensionSavings(estimatedPensionSavings, scenarios),
    };

    try {
      const cachedValues = cachedRetirementIncomePlanValues;

      const currentProjection = getProjectionWithCache(uid, currentCustomerData, cachedValues);
      const newProjection = getProjection(newCustomerData);

      const [currentProjectionResult, newProjectionResult] = await Promise.all([
        currentProjection,
        newProjection,
      ]);

      const {
        estimatedPreTaxIncomePerYear: currentAnnualIncome,
      } = currentProjectionResult;

      const {
        estimatedPreTaxIncomePerYear: newAnnualIncome,
      } = newProjectionResult;

      populateAnnualIncome(currentAnnualIncome, newAnnualIncome);
      saveResults(uid, scenarios, currentAnnualIncome, newAnnualIncome);

      navigateToResultsPage();
    } catch (e) {
      setErrorMessage('Something went wrong! Please try again.');
    }
    setLoading(false);
  };

  const loadLastProjection = () => {
    const lastScenarios = get(latestProjection, 'scenarios');
    const annualIncome = get(latestProjection, 'currentProjectedAnnualIncome');
    const newAnnualIncome = get(latestProjection, 'newProjectedAnnualIncome');

    if (!lastScenarios) {
      return;
    }

    loadPreviousProjection(lastScenarios, annualIncome, newAnnualIncome);
    navigateToResultsPage();
  };

  const handleBackLinkClick = () => {
    dispatchBackButtonClicked();
    setStep((currentStep) => (currentStep === 0 ? 0 : currentStep - 1));
  };

  const totalSteps = journey.length - 1;
  const FailSafe = () => null;

  const Component = stepsMap[journey[step]] || FailSafe;

  return (
    <BaseGrid addPensionsTheme greyTheme>
      <div className={styles.container}>
        {journey[step] !== INITIAL_STEP && (
          <div className={styles.progressContainer}>
            <ProgressStepper maxStep={totalSteps + 1} step={step + 1} />
          </div>
        )}

        <ButtonLink
          secondary
          mid
          font="bodyOne"
          fontSize={16}
          onClick={handleBackLinkClick}
          // eslint-disable-next-line
          {...(step === 0 && { to: '/' })}
          label="← Back"
        />

        <div>
          <Component
            state={state}
            loading={loading}
            isHighValueContributionCustomer={isHighValueContributionCustomer}
            hasCompletedFlow={hasCompletedFlow}
            currentProjectedAnnualIncome={currentProjectedAnnualIncome}
            newProjectedAnnualIncome={newProjectedAnnualIncome}
            errorMessage={errorMessage}
            onNextStep={onNextStep}
            dispatchAction={dispatch}
            onMonetaryValueChange={onMonetaryValueChange}
            onChangeRetirementAge={onChangeRetirementAge}
            toggleScenario={toggleScenario}
            onResultsStep={onResultsStep}
            loadLastProjection={loadLastProjection}
            navigateToCreateScenario={navigateToCreateScenario}
          />
        </div>
      </div>

    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
  latestProjection: getLastProjection(state),
  cachedRetirementIncomePlanValues: getCachedRetirementIncomePlanValues(state),
  currentContribution: getVerifiedPersonalContributionsValue(state),
  gender: getGender(state),
  currentAge: getAge(state),
  retirementAge: getIntendedRetirementAge(state),
  estimatedPensionSavings: getLatestPensionValue(state),
  portfolio: getModelDescriptor(state),
  estimatedMonthlyContributions: getVerifiedPersonalContributionsValue(state),
  verifiedNetPersonalContributions: getVerifiedNetPersonalContributions(state),
  verifiedGrossEmployerContributions: getVerifiedGrossEmployerContributions(state),
});

const mapDispatchToProps = (dispatch) => ({
  saveResults: (uid, scenarios, currentProjectedAnnualIncome, newProjectedAnnualIncome) => dispatch(
    saveProjectionUserScenarios(
      uid, scenarios, currentProjectedAnnualIncome, newProjectedAnnualIncome,
    ),
  ),
  getProjection: (data) => dispatch(getProjectedIncome(data, {
    DISPATCHED: RETIREMENT_INCOME_GET_NEW_PROJECTION_DISPATCHED,
    FULFILLED: RETIREMENT_INCOME_GET_NEW_PROJECTION_FULFILLED,
    REJECTED: RETIREMENT_INCOME_GET_NEW_PROJECTION_REJECTED,
  })),
  getProjectionWithCache: (uid, currentCustomerData, cachedValues) => dispatch(
    getProjectedIncomeWithCache(uid, currentCustomerData, cachedValues, {
      DISPATCHED: RETIREMENT_INCOME_GET_CURRENT_PROJECTION_DISPATCHED,
      FULFILLED: RETIREMENT_INCOME_GET_CURRENT_PROJECTION_FULFILLED,
      REJECTED: RETIREMENT_INCOME_GET_CURRENT_PROJECTION_REJECTED,
    }),
  ),
  dispatchBackButtonClicked: () => dispatch(backButtonClickedAction()),
});

const RetirementIncomePageWithCollections = (props) => (
  <WithCollections
    context="estimated-income-calculator"
    dependencies={[
      COLLECTIONS.PROJECTIONS,
      COLLECTIONS.RETIREMENTPLANS,
      COLLECTIONS.ONBOARDING,
    ]}
  >
    {/* eslint-disable-next-line */}
    <RetirementIncomePage {...props} />
  </WithCollections>
);

export default connect(mapStateToProps, mapDispatchToProps)(RetirementIncomePageWithCollections);
