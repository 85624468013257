import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import { pensionTransferNavigationButtonClicked } from '../../redux/modules/investmentAdvice';
import { getPensions } from '../../redux/selectors/pensions';
import PensionsList from '../PensionsList/PensionsList';

import GreenTick from '../../assets/images/icons/Icon_Circle_Green_Tick.svg';
import GrayDash from '../../assets/images/icons/Icon_Circle_Gray_Dash.svg';
import RedCross from '../../assets/images/icons/Icon_Circle_Red_Cross.svg';
import TransferPension from '../../assets/images/icons/Icon_Transfer_Arrow.svg';

import styles from './PensionRows.css';

const instructionIconMap = [
  {
    key: 'complete', icon: GreenTick, altText: 'Green tick - Pension transfer complete',
  },
  {
    key: 'inProgress', icon: GrayDash, altText: 'Gray dash - Pension transfer in progress',
  },
  {
    key: 'closed', icon: RedCross, altText: 'Red cross - Pension transfer closed',
  },
];

const getInstructionIcon = (instructionIcon) => {
  return instructionIconMap.find(
    (icon) => icon.key === instructionIcon,
  );
};

const PensionRows = ({
  onboardingPensions = [],
  pensionTransferClicked,
  history,
}) => {
  const openAddOrTransferPensionPage = ({ _id: pensionId }) => {
    history.push(`/add-pensions?pension=${pensionId}`);
  };

  if (!onboardingPensions.length) {
    return (<Loader centred />);
  }

  return (
    <div className={styles.pensionRowsContainer}>
      <PensionsList>
        {({
          _id,
          displayName,
          instructionIcon,
          instructionSubText,
          hideTransferButton,
          pension,
          enabledTransferButton,
          transferOptionMapped,
          serpsText,
          hmrcResponseEligible,
        }) => (
          <div className={styles.pensionRow} data-cy="pension-dashboard-row" key={_id}>
            <div className={styles.pensionRowTextContainer}>
              <p className={styles.pensionName}>
                {displayName}
                {transferOptionMapped && (
                  <span className={styles.pensionOption}>
                    <p className={styles.text}>{transferOptionMapped}</p>
                    {hmrcResponseEligible && (
                      <p className={styles.subText}>{serpsText}</p>
                    )}
                  </span>
                )}
              </p>
              <div className={styles.pensionStatusWrapper}>
                <img
                  src={getInstructionIcon(instructionIcon).icon}
                  alt={getInstructionIcon(instructionIcon).altText}
                />
                <p className={styles.subText}>{instructionSubText}</p>
              </div>
            </div>
            {!hideTransferButton && (
              <div className={styles.buttonContainer}>
                <Button
                  size="large"
                  label={(
                    <div className={styles.transferButtonContents}>
                      <img src={TransferPension} alt="transfer pension" />
                      <div>{'Transfer pension'}</div>
                    </div>
                  )}
                  onClick={(e) => {
                    pensionTransferClicked();
                    openAddOrTransferPensionPage(pension);
                    e.stopPropagation();
                  }}
                  dataCy="pension-dashboard-link"
                  disabled={!enabledTransferButton}
                />
              </div>
            )}
          </div>
        )}
      </PensionsList>
    </div>
  );
};

const mapStateToProps = (state) => ({
  onboardingPensions: getPensions(state),
});

const mapDispatchToProps = (dispatch) => ({
  pensionTransferClicked: () => dispatch(pensionTransferNavigationButtonClicked()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PensionRows));
