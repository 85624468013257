/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';

import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Loader from '../../components/Loader/Loader';
import AssumptionsModal from '../../components/AssumptionsModal/AssumptionsModal';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import CheckBoxGreen from '../../components/CheckBoxGreen/CheckBoxGreen';
import { currencyString } from '../../util/currency';

import {
  getAge,
  getGender,
  getLatestPensionValue,
  getModelDescriptor,
  getIntendedRetirementAge,
  getVerifiedGrossEmployerContributions,
  getVerifiedNetPersonalContributions,
  getFirebaseUid,
} from '../../redux/selectors';
import {
  getProjectedIncome,
  WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_DISPATCHED,
  WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_FULFILLED,
  WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_REJECTED,
} from '../../redux/modules/projection';
import {
  nextClickedAction, backClickedAction,
  completeAddContributionRequestSelectedAction,
  completeAddContributionRequestUnselectedAction,
  completeAddPensionRequestSelectedAction,
  completeAddPensionRequestUnselectedAction,
} from '../../redux/modules/withdrawal';
import { getHasInProgressContributionInstructions } from '../../redux/selectors/investmentAdvice';

import minusPound from '../../assets/images/rebranding/minus-money.svg';
import addPound from '../../assets/images/rebranding/add-money.svg';
import transferPensionIcon from '../../assets/images/rebranding/tri-tone-overlapping-circles.svg';
import healthyPlantImg from '../../assets/images/retirementIncome/healthy-plant.svg';
import dyingPlantImg from '../../assets/images/retirementIncome/dying-plant.svg';

import styles from './WithdrawalPage.css';

function WithdrawalResultsStep({
  step,
  setStep,
  totalSteps,
  formData,
  setFormData,
  dispatchNextClicked,
  dispatchBackClicked,
  dispatchResultsStepMessageAdvisorButtonClicked,
  gender,
  currentAge,
  retirementAge,
  estimatedPensionSavings,
  portfolio,
  verifiedNetPersonalContributions,
  verifiedGrossEmployerContributions,
  dispatchGetProjectedIncome,
  dispatchCompleteAddContributionRequestSelected,
  dispatchCompleteAddContributionRequestUnselected,
  dispatchCompleteAddPensionRequestSelected,
  dispatchCompleteAddPensionRequestUnselected,
}) {
  const [showModal, setShowModal] = useState(false);
  const [currentEstimate, setCurrentEstimate] = useState({ currentAnnualIncome: 0, currentEstimatedPensionValueAtRetirement: 0 });
  const [newEstimate, setNewEstimate] = useState({ newAnnualIncome: 0, newEstimatedPensionValueAtRetirement: 0 });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const loadResults = async () => {
      setLoading(true);
      setErrorMessage(null);

      const currentCustomerData = {
        gender,
        currentAge,
        retirementAge,
        estimatedPensionSavings,
        portfolio,
        statePension: false,
        estimatedMonthlyContributions: verifiedNetPersonalContributions || 0,
        grossMonthlyContributions: verifiedGrossEmployerContributions || 0,
      };

      try {
        if (formData.withdrawalAmount > 0) {
          const currentProjection = await dispatchGetProjectedIncome(currentCustomerData, {
            DISPATCHED: WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_DISPATCHED,
            FULFILLED: WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_FULFILLED,
            REJECTED: WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_REJECTED,
          });

          const currentAnnualIncome = get(currentProjection, 'estimatedPreTaxIncomePerYear');
          const currentEstimatedPensionValueAtRetirement = get(currentProjection, 'estimatedPensionValueAtRetirement');

          setCurrentEstimate({ currentAnnualIncome, currentEstimatedPensionValueAtRetirement });

          const pensionValueAfterWithdrawal = currentCustomerData.estimatedPensionSavings - formData.withdrawalAmount;

          const newCustomerData = {
            ...currentCustomerData,
            estimatedPensionSavings: pensionValueAfterWithdrawal,
          };

          const newProjection = await dispatchGetProjectedIncome(newCustomerData, {
            DISPATCHED: WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_DISPATCHED,
            FULFILLED: WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_FULFILLED,
            REJECTED: WITHDRAWAL_JOURNEY_GET_NEW_PROJECTION_REJECTED,
          });

          const newAnnualIncome = get(newProjection, 'estimatedPreTaxIncomePerYear');
          const newEstimatedPensionValueAtRetirement = get(newProjection, 'estimatedPensionValueAtRetirement');

          setNewEstimate({
            newAnnualIncome,
            newEstimatedPensionValueAtRetirement,
          });
        }
      } catch (e) {
        setErrorMessage('Something went wrong! Please try again.');
      }
      setLoading(false);
    };

    loadResults();
  }, []);

  const { currentAnnualIncome, currentEstimatedPensionValueAtRetirement } = currentEstimate;
  const { newAnnualIncome, newEstimatedPensionValueAtRetirement } = newEstimate;

  const annualIncomeDifference = newAnnualIncome - currentAnnualIncome;
  const absoluteAnnualIncomeDifference = Math.abs(annualIncomeDifference);
  const incomeIncrease = annualIncomeDifference >= -1;
  const overallFundDifference = newEstimatedPensionValueAtRetirement - currentEstimatedPensionValueAtRetirement;
  const absoluteOverAllFundDifference = Math.abs(overallFundDifference);
  const assumptionsSubheadings = [];

  const loadingOrError = (
    <div style={{ margin: '40px 0 60px 0' }}>
      {loading ? <Loader /> : <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );

  return (
    <BaseGrid addPensionsTheme>
      <div className={styles.withdrawalsContainer}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        <ButtonLink
          onClick={(event) => {
            dispatchBackClicked();
            setStep((_step) => _step - 1);
            event.stopPropagation();
          }}
          label="← Back"
        />
        {loading || errorMessage ? (loadingOrError) : (
          <div className={styles.withdrawalResultsContainer}>
            <div className={styles.withdrawalResultsHeaderSection}>
              <h1 className={styles.headingTwoNoMargin}>{'Your results'}</h1>
              <p className={styles.withdrawalResultsHeadingBody}>{'Your estimated income'}</p>
              <p className={styles.withdrawalResultsHeadingBody}>
                {'would '}
                <span
                  className={classnames({
                    [styles.increaseText]: incomeIncrease,
                    [styles.decreaseText]: !incomeIncrease,
                  })}
                >
                  {incomeIncrease ? 'increase by ' : 'decrease by '}
                  <span className={styles.monetaryValue}>{`${currencyString(absoluteAnnualIncomeDifference)}`}</span>
                  {' / year'}
                </span>
              </p>
              <br />
              <img src={incomeIncrease ? healthyPlantImg : dyingPlantImg} alt={incomeIncrease ? 'Healthy plant' : 'Dying plant'} />
            </div>
            <div className={classnames(styles.incomeTable, {
              [styles.increaseBorder]: incomeIncrease,
              [styles.decreaseBorder]: !incomeIncrease,
            })}
            >
              <div className={styles.incomeTableRow}>
                <div className={styles.incomeTableCell} />
                <div className={classnames(styles.incomeTableCell, styles.incomeTableHeader)}>
                  <span>{'Before'}</span>
                </div>
                <div className={classnames(styles.incomeTableCell, styles.incomeTableHeader)}>
                  <span>
                    {'After'}
                  </span>
                </div>
              </div>
              <div className={styles.incomeTableRow}>
                <div className={classnames(styles.incomeTableCell, styles.incomeTableCellLeftAlign)}>
                  <span>{'Estimated income / year'}</span>
                </div>
                <div className={classnames(styles.incomeTableCell, styles.incomeTableMonetaryValues)}>
                  <span>{`${currencyString(currentAnnualIncome)}*`}</span>
                </div>
                <div className={classnames(styles.incomeTableCell, styles.incomeTableMonetaryValues)}>
                  <span>
                    {`${currencyString(newAnnualIncome)}*`}
                  </span>
                </div>
              </div>
              <div className={styles.incomeTableRow}>
                <div className={classnames(styles.incomeTableCell, styles.incomeTableCellLeftAlign)}>
                  <span>{'Estimated overall fund value'}</span>
                </div>
                <div className={classnames(styles.incomeTableCell, styles.incomeTableMonetaryValues)}>
                  <span>{`${currencyString(currentEstimatedPensionValueAtRetirement)}*`}</span>
                </div>
                <div className={classnames(styles.incomeTableCell, styles.incomeTableMonetaryValues)}>
                  <span>
                    {`${currencyString(newEstimatedPensionValueAtRetirement)}*`}
                  </span>
                </div>
              </div>
              <div className={styles.incomeTableRow}>
                <div className={classnames(styles.incomeTableCell, styles.incomeTableCellLeftAlign)}><span>{'Difference in overall fund value'}</span></div>
                <div className={classnames(styles.incomeTableCell, styles.incomeTableMonetaryValues)} />
                <div className={classnames(
                  styles.incomeTableCell,
                  styles.incomeTableMonetaryValues,
                  styles.incomeTableMonetaryValuesBottomCell,
                  {
                    [styles.increaseText]: incomeIncrease,
                    [styles.decreaseText]: !incomeIncrease,
                  },
                )}
                >
                  <span>
                    {`${incomeIncrease ? '+' : '-'}${currencyString(absoluteOverAllFundDifference)}*`}
                  </span>
                </div>
              </div>
            </div>
            <ButtonLink
              label={'View assumptions'}
              variant="primary"
              fontSize={14}
              font="bodyOne"
              onClick={() => {
                setShowModal(true);
              }}
            />
            <p className={styles.resultsPageTextForest}>{'Based on your selections'}</p>
            <div className={styles.resultsPageChangedScenarioWrapper}>
              <img src={minusPound} alt={'Making a withdrawal'} />
              <div className={styles.resultsPageChangedScenarioTextSection}>
                <h4 className={styles.resultsPageSelectionTitle}>{'Making a withdrawal'}</h4>
                <p className={styles.resultsPageSelectionText}>
                  {'I would like to make a lump sum withdrawal of '}
                  <b>{currencyString(formData.withdrawalAmount)}</b>
                </p>
              </div>
            </div>
            <div className={styles.pensionAdviserContainer}>
              <div className={styles.resultPageContactUsWrapper}>
                <ContactUsContent
                  source={'WithdrawalResultStep'}
                  noExpertContentWrapper={({ children }) => (
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexShrink: 'initial',
                      padding: '10px',
                      flexWrap: 'wrap',
                    }}
                    >
                      {children}
                    </div>
                  )}
                  showButton={false}
                  customMessage={(
                    <div className={styles.contactUsInnerContent}>
                      <span className={styles.bodyBold}>
                        {'Making a withdrawal will negatively impact your estimated income in retirement and may affect your standard of living. '}
                      </span>
                      {'If you wish to discuss this before making this important decision, please feel free to '}
                      <span>
                        <ButtonLink
                          variant="primary"
                          displayAsHref
                          to="/inbox/new-message"
                          search={'?subject=Withdrawal%20Request'}
                          font="inherit"
                          onClick={dispatchResultsStepMessageAdvisorButtonClicked}
                          label={'contact your Pension Adviser'}
                        />
                        {'.'}
                      </span>
                    </div>
                  )}
                />
              </div>
            </div>
            <AssumptionsModal
              show={showModal}
              onClose={() => setShowModal(false)}
              subHeadings={assumptionsSubheadings}
            />
            <div className={styles.suggestionCardBadge}>
              <span>
                {'To balance your pension you may want to...'}
              </span>
            </div>
            <div className={styles.balanceYourPensionOption}>
              <div className={styles.balanceYourPensionOptionHeadingWrapper}>
                <img src={transferPensionIcon} alt={'Find & combine old pensions'} />
                <h4 className={styles.balanceYourPensionOptionHeading}>{'Find & combine old pensions'}</h4>
              </div>
              <div className={styles.balanceYourPensionOptionContentWrapper}>
                <p className={styles.resultsPageSelectionText}>
                  {'The average person has 11 workplace jobs in their lifetime. We can help you to find and combine any other old pensions and consolidate into your Profile Pension plan, making sure you benefit from ongoing investment advice, dedicated support and whole of market fund selection.'}
                </p>
                <br />
                <CheckBoxGreen
                  name="confirmAddPension"
                  label={(
                    <span>
                      {'I would like to find and combine old pensions after submitting my withdrawal request.'}
                    </span>
                  )}
                  value={formData.completePensionRequestSelected}
                  defaultValue={false}
                  onChange={() => {
                    setFormData({
                      ...formData,
                      completePensionRequestSelected: !formData.completePensionRequestSelected,
                    });
                    if (!formData.completePensionRequestSelected) dispatchCompleteAddPensionRequestSelected();
                    else dispatchCompleteAddPensionRequestUnselected();
                  }}
                  wrapperStyles={{ marginBottom: '13px' }}
                />
              </div>
            </div>
            <hr className={styles.withdrawalResultsDivider} />
            <div className={styles.balanceYourPensionOption}>
              <div className={styles.balanceYourPensionOptionHeadingWrapper}>
                <img src={addPound} alt={'Set up pension contributions'} />
                <h4 className={styles.balanceYourPensionOptionHeading}>{'Set up pension contributions'}</h4>
              </div>
              <div className={styles.balanceYourPensionOptionContentWrapper}>
                <p className={styles.resultsPageSelectionText}>
                  {'The more you take from your pension pot now, the less you’ll have for your future. If you can afford it, you may want to consider setting up regular contributions or reviewing existing contribution amounts to counteract the effect of making this withdrawal.'}
                </p>
                <br />
                <CheckBoxGreen
                  name="confirmAddContribution"
                  label={(
                    <span>
                      {'I would like to set up pension contributions after submitting my withdrawal request.'}
                    </span>
                  )}
                  value={formData.completeContributionRequestSelected}
                  defaultValue={false}
                  onChange={() => {
                    setFormData({
                      ...formData,
                      completeContributionRequestSelected: !formData.completeContributionRequestSelected,
                    });
                    if (!formData.completeContributionRequestSelected) dispatchCompleteAddContributionRequestSelected();
                    else dispatchCompleteAddContributionRequestUnselected();
                  }}
                  wrapperStyles={{ marginBottom: '13px' }}
                />
              </div>
            </div>
          </div>
        )}
        <br />
        <Button
          size="large"
          disabled={!formData.fundsUsedForSelectedOption || !formData.fundsUsedFor}
          label={'Proceed to withdrawal'}
          onClick={() => {
            dispatchNextClicked();
            setStep((_step) => _step + 1);
          }}
        />
      </div>
    </BaseGrid>
  );
}

const mapDispatchToProps = {
  dispatchGetProjectedIncome: getProjectedIncome,
  dispatchNextClicked: nextClickedAction,
  dispatchBackClicked: backClickedAction,
  dispatchCompleteAddContributionRequestSelected: completeAddContributionRequestSelectedAction,
  dispatchCompleteAddContributionRequestUnselected: completeAddContributionRequestUnselectedAction,
  dispatchCompleteAddPensionRequestSelected: completeAddPensionRequestSelectedAction,
  dispatchCompleteAddPensionRequestUnselected: completeAddPensionRequestUnselectedAction,
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
  currentVerifiedContributionsValue: getVerifiedNetPersonalContributions(state),
  intendedRetirementAge: getIntendedRetirementAge(state),
  hasInProgressContributions: getHasInProgressContributionInstructions(state),
  gender: getGender(state),
  currentAge: getAge(state),
  retirementAge: getIntendedRetirementAge(state),
  estimatedPensionSavings: getLatestPensionValue(state),
  portfolio: getModelDescriptor(state),
  verifiedNetPersonalContributions: getVerifiedNetPersonalContributions(state),
  verifiedGrossEmployerContributions: getVerifiedGrossEmployerContributions(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalResultsStep);
