import React from 'react';
import styles from './Footer.css';
import FooterLogo from './FooterLogo';
import FooterQuicklinks from './FooterQuicklinks';
import FooterContact from './FooterContact';
import FooterSocialLinks from './FooterSocialLinks';
import FooterSecurity from './FooterSecurity';
import FooterDisclaimer from './FooterDisclaimer';
import FooterSmallprint from './FooterSmallprint';

const Footer = () => (
  <footer>
    <div className={styles.footer}>
      <div className={styles.container}>
        <FooterLogo />
        <FooterQuicklinks />
        <FooterContact />
        <FooterSocialLinks />
        <FooterSecurity />
        <FooterDisclaimer />
        <FooterSmallprint />
      </div>
    </div>
  </footer>
);

export default Footer;
