import get from 'lodash/get';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import Loader from '../Loader/Loader';

import { getIsCustomerOngoing, getIsCustomerOnboarding } from '../../redux/selectors/experience';
import { getPensions, getAllNonSerpsPensionsAndAuthorisedSerpsPensions } from '../../redux/selectors/pensions';
import { getInvestmentAdviceLetter, getInvestmentAdviceOnboarding } from '../../redux/selectors/investmentAdvice';
import { transferOptionMapper } from '../../util/constants';
import styles from './PensionsList.css';

const CLOSED = 'closed';

const pensionClosureReasonsMap = {
  cannotLocatePolicy: 'The pension provider is unable to locate a policy for you.',
  notAPension: 'The pension provider tells us that this is not a pension policy.',
  duplicate: 'This policy is a duplicate. It matches another that we are looking into already.',
  notEligibleOther: 'The features of this pension are such that we are unable to transfer it for you.',
  guaranteedMinimumPension: 'Your policy has a special type of benefit included called a Guaranteed Minimum Pension. There is specific legal protection on this benefit, which would be lost if your pension was transferred.',
  pensionProtectionFund: 'The Pension Protection Fund (PPF) provides compensation to members of failed pension schemes. This plan is now part of the PPF and benefits from statutory protection.',
  pensionNotEligible: 'The features of this pension are such that we are unable to transfer it for you.',
  incomeBeingTaken: 'You are taking a regular income from this policy. Therefore, we cannot transfer it at present.',
  workplacePension: 'This is your current workplace pension and your employer is contributing to it.',
  guaranteedAnnuity: 'Your policy offers a guaranteed minimum level of income at retirement. This is a safeguarded benefit and means that we are unable to transfer this plan for you.',
  guaranteedAnnuityRate: 'Your policy offers a guaranteed annuity rate (GAR) at retirement. You do not wish to give this up.',
  guaranteedCashSum: 'Your policy benefits from a guaranteed minimum future value at the original pension date. You do not wish to give this up.',
  guaranteedGrowthRate: 'The underlying value of your policy will increase each year by a minimum set rate. You do not wish to give this up.',
  protectedTaxFreeCash: 'Your policy has more than the standard 25% of its value available as a tax-free lump sum.',
  marketValueReductionOrPenalty: 'If transferred, the full value of your policy would not be realised. This may be due to a Market Value Reduction (MVR) or a transfer penalty being applied.',
  customerDoesNotWishToTransfer: 'You have chosen to leave this pension unchanged at the present time.',
  transactionRejected: 'We are unable to complete the transfer of this pension.',
  providerNotRecognised: 'We have not been able to identify the provider or administrator of your pension.',
  unresolvedCustomerIssueChaseComplete: 'You need to take some action before we can proceed with the transfer of this pension and we have contacted you about this.',
  contributionsEquivalentPremium: 'You left this scheme with less than 2 years\' pensionable service and your employer reinstated you back into the secondary state pension.',
};

const pensionBlockingReasonsMap = {
  providerNotRecognised: 'We have not been able to identify the provider or administrator of your pension.',
  suspendedFunds: 'Some or all of your pension is held in funds that are currently suspended. Your plan cannot be transferred until this suspension is lifted.',
};

const customerClosureReasonsMap = {
  customerOptedOut: 'You have opted-out of our service.',
  duplicate: 'This account is a duplicate.',
  deceased: '',
  amlKycFail: 'We are unable to offer you our service at this time.',
  vulnerableCustomer: 'Following due consideration of your personal circumstances, we regret that Profile Pensions is not able to facilitate a pension transfer for you.',
};

/**
 * Gets one of the closure reason messages that will be displayed
 * @param {Array<object>} closureReasons List of closure reasons
 * @return {string} A single closure message
 */
const getClosureMessage = (closureReasonsMap, closureReasons) => {
  let closureMessage;

  Object.keys(closureReasons).forEach((closureReason) => {
    if (closureMessage) return;

    if (closureReasons[closureReason].value) {
      closureMessage = closureReasonsMap[closureReason];
    }
  });

  return closureMessage;
};

const capitalise = (string) => {
  return string ? string.charAt(0).toUpperCase().concat(string.slice(1)) : '';
};

const PensionsList = ({
  onboardingPensions = [],
  allNonSerpsPensionsAndAuthorisedSerpsPensions = [],
  investmentAdviceReport,
  onboarding: { customerDueDiligence, customerClosureReasons, overallStatus },
  customerIsOngoing,
  customerIsOnboarding,
  children,
  fromPlanActivity,
}) => {
  if (!onboardingPensions.length) {
    return (<Loader centred />);
  }

  const appendProviderNameAndPolicyNumber = (policyName, mappedProviderContact, policyNumber) => {
    if (policyName) {
      if (mappedProviderContact
      && mappedProviderContact.toLowerCase() !== policyName.toLowerCase()) {
        return `${capitalise(policyName)} (${capitalise(mappedProviderContact)}${policyNumber ? `: ${policyNumber}` : ''})`;
      }
      if (policyNumber) {
        return `${capitalise(policyName)} (${policyNumber})`;
      }
      return capitalise(policyName);
    }
    if (mappedProviderContact) {
      return `${capitalise(mappedProviderContact)}${policyNumber ? ` (${policyNumber})` : ''}`;
    }
  };
  const getPensionAuthority = (p) => get(p, 'dueDiligence.authority.overallProceed') || get(p, 'authorityToTransfer');
  const ftcPensions = onboardingPensions.filter((p) => (
    (p.transferOption === 'transfer-with-check' && p.hmrcResponseEligible !== 'check')
    || !p.transferOption
    || p.transferOption === 'legacy'
    || (p.hmrcResponseEligible === 'yes' && getPensionAuthority(p))));
  const transferOnlyPensions = onboardingPensions.filter((p) => p.transferOption === 'transfer-basic');

  const readyToTransferSerps = ftcPensions.filter((p) => {
    const authority = getPensionAuthority(p);
    const canGiveTransferAuthority = get(p, 'canGiveTransferAuthority');
    const hideTransferButton = overallStatus === CLOSED
    || p.overallStatus === CLOSED
    || get(p, 'blockingReasons.suspendedFunds.value')
    || get(p, 'blockingReasons.providerNotRecognised.value')
    || authority
    || get(p, 'transferCompleted.value');
    return p.hmrcResponseEligible === 'yes' && !hideTransferButton && canGiveTransferAuthority;
  });

  const [readyToTransferSerpsLength] = useState(readyToTransferSerps.length || 0);

  const processPensions = (filterPensions) => {
    const sortSerpPensions = [...filterPensions].sort((a, b) => {
      if (a.hmrcResponseEligible === 'yes' && !getPensionAuthority(a)) {
        return -1;
      }
      if (b.hmrcResponseEligible === 'yes' && !getPensionAuthority(b)) {
        return 1;
      }
      return a - b;
    });

    return sortSerpPensions.map((pension, index) => {
      const {
        _id,
        policyName,
        mappedProviderContact,
        policyNumber,
        transferOption,
        hmrcResponseEligible,
        createdDate,
      } = pension;
      const vettingProceed = get(pension, 'dueDiligence.vetting.overallProceed');
      const attitudeToRiskProceed = get(customerDueDiligence, 'attitudeToRisk.proceed');
      const vulnerableCustomerProceed = get(customerDueDiligence, 'vulnerableCustomer.proceed');
      const providerNotRecognised = get(pension, 'blockingReasons.providerNotRecognised.value');
      const suspendedFunds = get(pension, 'blockingReasons.suspendedFunds.value');
      const signedTransferDocsRequired = get(pension, 'blockingReasons.signedTransferDocsRequired.value');
      const authority = getPensionAuthority(pension);
      const transferCompleted = get(pension, 'transferCompleted.value');

      const canGiveTransferAuthority = get(pension, 'canGiveTransferAuthority');
      const enabledTransferButton = canGiveTransferAuthority
      && (
        (investmentAdviceReport && customerIsOnboarding) || customerIsOngoing
      );

      let hideTransferButton = overallStatus === CLOSED
        || pension.overallStatus === CLOSED
        || suspendedFunds
        || providerNotRecognised
        || authority
        || transferCompleted;

      let instructionSubText;
      let instructionIcon;
      let serpsText;
      let enableTransferPensionsButton = false;
      const transferOptionMapped = transferOptionMapper[transferOption];

      if (overallStatus === CLOSED) {
        instructionSubText = getClosureMessage(
          customerClosureReasonsMap,
          customerClosureReasons,
        );
        instructionIcon = 'closed';
      } else if (pension.overallStatus === CLOSED) {
        instructionSubText = getClosureMessage(
          pensionClosureReasonsMap,
          pension.closureReasons,
        );
        hideTransferButton = true;
        instructionIcon = 'closed';
      } else if (providerNotRecognised || suspendedFunds) {
        instructionSubText = getClosureMessage(
          pensionBlockingReasonsMap,
          {
            providerNotRecognised: { value: providerNotRecognised },
            suspendedFunds: { value: suspendedFunds },
          },
        );
        instructionIcon = 'inProgress';
      } else if (transferCompleted && hmrcResponseEligible === 'yes') {
        instructionSubText = `Completed on ${moment.utc(transferCompleted).format('DD/MM/YY')}`;
        instructionIcon = 'complete';
        serpsText = 'HMRC SERPS trace';
      } else if (transferCompleted) {
        instructionSubText = `Completed on ${moment.utc(transferCompleted).format('DD/MM/YY')}`;
        instructionIcon = 'complete';
      } else if (!attitudeToRiskProceed
      || !vulnerableCustomerProceed
      || (!investmentAdviceReport && !customerIsOngoing)) {
        instructionSubText = 'We need more information before we can transfer this pension';
        hideTransferButton = true;
        instructionIcon = 'inProgress';
      } else if (enabledTransferButton && !authority && hmrcResponseEligible === 'yes' && index < readyToTransferSerpsLength - 1) {
        instructionSubText = `Ready to transfer since ${moment.utc(createdDate).format('DD/MM/YY')}`;
        instructionIcon = 'inProgress';
        serpsText = 'HMRC SERPS trace';
        hideTransferButton = true;
      } else if (enabledTransferButton && !authority && hmrcResponseEligible === 'yes' && index === readyToTransferSerpsLength - 1) {
        instructionSubText = `Ready to transfer since ${moment.utc(createdDate).format('DD/MM/YY')}`;
        instructionIcon = 'inProgress';
        serpsText = 'HMRC SERPS trace';
        enableTransferPensionsButton = true;
        hideTransferButton = true;
      } else if (enabledTransferButton && !hideTransferButton && !authority) {
        instructionSubText = 'Ready to transfer';
        instructionIcon = 'inProgress';
      } else if (!vettingProceed) {
        instructionSubText = 'We are checking the details of this pension for you';
        instructionIcon = 'inProgress';
      } else if (signedTransferDocsRequired) {
        instructionSubText = `The pension provider requires signed transfer request
      forms before we can transfer this pension. These have been sent
      to you for signing.`;
        instructionIcon = 'inProgress';
      } else if (authority && hmrcResponseEligible === 'yes') {
        instructionSubText = 'The transfer of this pension is in progress';
        instructionIcon = 'inProgress';
        serpsText = 'HMRC SERPS trace';
      } else if (authority) {
        instructionSubText = 'The transfer of this pension is in progress';
        instructionIcon = 'inProgress';
      } else {
        instructionSubText = 'Pending';
        hideTransferButton = true;
        instructionIcon = 'inProgress';
      }

      const displayName = appendProviderNameAndPolicyNumber(
        policyName,
        mappedProviderContact,
        policyNumber,
      );
      return children({
        _id,
        displayName,
        instructionIcon,
        instructionSubText,
        serpsText,
        hideTransferButton,
        pension,
        enabledTransferButton,
        pensionListIndex: index,
        policyName,
        mappedProviderContact,
        transferOptionMapped,
        hmrcResponseEligible,
        enableTransferPensionsButton,
        authority,
      });
    });
  };

  return (
    <div className={styles.pensionListContainer}>
      {fromPlanActivity ? (
        <div>
          {ftcPensions.length > 0 && (
          <p className={styles.pensionOption}>
            {'Find, Check and Transfer'}
          </p>
          )}
          {processPensions(ftcPensions)}
          {ftcPensions.length > 0 && transferOnlyPensions.length > 0 && (<br />)}
          {transferOnlyPensions.length > 0 && (
          <p className={styles.pensionOption}>
            {'Transfer Only'}
          </p>
          )}
          {processPensions(transferOnlyPensions)}
        </div>
      ) : processPensions(allNonSerpsPensionsAndAuthorisedSerpsPensions)}
    </div>
  );
};

const mapStateToProps = (state) => ({
  customerIsOngoing: getIsCustomerOngoing(state),
  customerIsOnboarding: getIsCustomerOnboarding(state),
  onboardingPensions: getPensions(state),
  onboarding: getInvestmentAdviceOnboarding(state),
  investmentAdviceReport: getInvestmentAdviceLetter(state),
  allNonSerpsPensionsAndAuthorisedSerpsPensions:
  getAllNonSerpsPensionsAndAuthorisedSerpsPensions(state),
});

export default connect(mapStateToProps, null)(withRouter(PensionsList));
