import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import BaseGrid from '../../components/BaseGrid/BaseGrid';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';

import ThankYouBg from '../../assets/images/transfer-thank-you-bg.svg';

import {
  withdrawalCompleteVisitedAction,
  backToDashboardAction,
  withdrawalCompleteAddPensionsButtonClicked,
  withdrawalCompleteAddContributionsButtonClicked,
} from '../../redux/modules/withdrawal';

import AddMoney from '../../assets/images/rebranding/add-money.svg';
import AddPensions from '../../assets/images/rebranding/tri-tone-overlapping-circles.svg';

import styles from './WithdrawalPage.css';

const WithdrawalRequestComplete = ({
  dispatchWithdrawalCompleteVisited,
  dispatchBackToDashboard,
  dispatchWithdrawalCompleteAddPensionsButtonClicked,
  dispatchWithdrawalCompleteAddContributionsButtonClicked,
}) => {
  useEffect(() => {
    dispatchWithdrawalCompleteVisited();
  }, []);

  return (
    <BaseGrid addPensionsTheme>
      <div className={styles.withdrawalsContainer}>
        <div className={styles.withdrawalsImageContainer} style={{ marginTop: 0 }}>
          <img src={ThankYouBg} alt="thank you" />
        </div>
        <h2 className={styles.headingTwo}>{'Your withdrawal request has been received'}</h2>
        <h3 className={styles.summaryHeadingForest}>{'What happens next?'}</h3>
        <div className={styles.whatHappensNextRow}>
          <div className={styles.whatHappensNextNumberContainer}>
            <p className={styles.whatHappensNextNumber}>{'1'}</p>
          </div>
          <p className={styles.whatHappensNextBody}>{'We’ll instruct Aegon to complete your withdrawal request'}</p>
        </div>
        <div className={styles.whatHappensNextRow}>
          <div className={styles.whatHappensNextNumberContainer}>
            <p className={styles.whatHappensNextNumber}>{'2'}</p>
          </div>
          <p className={styles.whatHappensNextBody}>{'Your withdrawal may need to be paid out in 2 instalments, depending on how much tax free entitlement remains on your pension. Please allow up to 21 days for the first, or only, instalment to be paid into your account. If a second instalment is required, this should follow within 12 working days.'}</p>
        </div>
        <div className={styles.whatHappensNextCard}>
          <div className={styles.subHeadingBanner}>
            <span>
              {'To balance your pension you may want to...'}
            </span>
          </div>
          <div className={styles.whatHappensNextBlock}>
            <div className={styles.whatHappensNextRow}>
              <img className={styles.whatHappensNextWidgetImg} alt="add pensions" src={AddPensions} />
              <p className={styles.sectionSummaryHeading}>{'Find & combine old pensions'}</p>
            </div>
            <p className={styles.body}>{'The average person has 11 workplace jobs in their lifetime. We can help you to find and combine any other old pensions and consolidate into your Profile Pension plan, making sure you benefit from ongoing investment advice, dedicated support and whole of market fund selection.'}</p>
            <Button
              size="large"
              label={'Add pensions'}
              onClick={dispatchWithdrawalCompleteAddPensionsButtonClicked}
              to={'/add-pensions'}
            />
          </div>
          <div className={styles.divider} />
          <div className={styles.whatHappensNextBlock}>
            <div className={styles.whatHappensNextRow}>
              <img className={styles.whatHappensNextWidgetImg} alt="set up contributions" src={AddMoney} />
              <p className={styles.sectionSummaryHeading}>{'Set up pension contributions'}</p>
            </div>
            <p className={styles.body}>{'The more you take from your pension pot now, the less you’ll have for your future. If you can afford it, you may want to consider setting up regular contributions or reviewing existing contribution amounts to counteract the effect of making this withdrawal.'}</p>
            <Button
              size="large"
              label={'Add money'}
              onClick={dispatchWithdrawalCompleteAddContributionsButtonClicked}
              to={'/setup-contribution'}
            />
          </div>
        </div>
        <div className={styles.returnToDashboardLinkContainer}>
          <ButtonLink
            variant="primary"
            label="View my dashboard"
            onClick={dispatchBackToDashboard}
            to="/"
            font="inherit"
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  dispatchWithdrawalCompleteVisited: withdrawalCompleteVisitedAction,
  dispatchBackToDashboard: backToDashboardAction,
  dispatchWithdrawalCompleteAddPensionsButtonClicked:
    withdrawalCompleteAddPensionsButtonClicked,
  dispatchWithdrawalCompleteAddContributionsButtonClicked:
    withdrawalCompleteAddContributionsButtonClicked,
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalRequestComplete);
