import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import {
  serpsMissingDetailsSignatureSizeErrorAction,
  serpsMissingDetailsSignaturePageLoaded,
  serpsMissingDetailsMessageMeClickedAction,
  serpsMissingDetailsSignatureProvided,
} from '../../redux/modules/serps';
import AuthoritySignature from './AuthoritySignature';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import Button from '../../components/Button/Button';
import styles from './SerpMissingDetails.css';
import { getHasDigitalSignature } from '../../redux/selectors';

const MissingDetailsSignature = ({
  step,
  totalSteps,
  amendMode,
  dispatchSerpsMissingDetailsSignatureProvided,
  dispatchSerpsMissingDetailsSignaturePageLoaded: _dispatchSerpsMissingDetailsSignaturePageLoaded,
  dispatchSerpsMissingDetailsMessageMeClicked,
  setStep,
  heading,
  dispatchSignatureSizeError,
  isSubmitting,
  formData,
  setFormData,
  digitalSignature,
}) => {
  const [dispatchSerpsMissingDetailsSignaturePageLoaded] = useState(() => once(_dispatchSerpsMissingDetailsSignaturePageLoaded || ''));

  const MINIMUM_SIGNATURE_HEIGHT_PX = 30;
  const MINIMUM_SIGNATURE_WIDTH_PX = 70;

  const [signatureTooSmall, setSignatureTooSmall] = useState(false);

  const [signatureDrawn, setSignatureDrawn] = useState(false);

  const signatureHandleChange = (signature) => {
    if (!signature) {
      setSignatureTooSmall(false);
      setSignatureDrawn(false);
      setFormData((_formData) => ({
        ..._formData,
        signature: null,
      }));
      return;
    }
    const signatureBase64 = signature.toDataURL('image/svg+xml');
    const { height, width } = signature;
    const signatureIsTooSmall = height < MINIMUM_SIGNATURE_HEIGHT_PX
      || width < MINIMUM_SIGNATURE_WIDTH_PX;

    if (signatureIsTooSmall) {
      setSignatureTooSmall(true);
      setSignatureDrawn(true);
      dispatchSignatureSizeError();
    } else {
      setSignatureDrawn(true);
      setSignatureTooSmall(false);
    }
    setFormData((_formData) => ({
      ..._formData,
      signature: signatureBase64,
    }));
  };

  const disableNextButton = isSubmitting
    || !formData.signature
    || signatureTooSmall
    || signatureDrawn === false;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchSerpsMissingDetailsSignaturePageLoaded();
  }, []);

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.missingDetailsWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
          <ButtonLink
            secondary
            mid
            onClick={() => {
              setStep(step - 1);
            }}
            label="← Back"
            wrapperStyles={{
              margin: '0 0 19px',
              fontFamily: 'Poppins',
              fontWeight: 500,
              fontSize: '16px',
            }}
          />
        )}
        <h1 className={styles.ninoHeading}>
          {heading}
          {'Signature'}
        </h1>
        <div className={styles.content}>
          {'HMRC and pension providers require an original signature to release pension information. By signing the box below, you are authorising Profile Pensions to obtain pension information and securely process your personal data in line with our '}
          <a className={styles.privacyPolicy} href="https://www.profilepensions.co.uk/privacy-policy" target="_blank" rel="noreferrer">{'Privacy Policy.'}</a>
        </div>
        <AuthoritySignature
          onChange={signatureHandleChange}
          signatureTooSmall={signatureTooSmall}
          showHeading
          formData={formData.signature}
          dataSentry="/dashboard/missing-details/signature-pad"
        />
        <Button
          type="button"
          size="large"
          label={'Save & continue'}
          disabled={disableNextButton}
          loading={isSubmitting}
          onClick={() => {
            dispatchSerpsMissingDetailsSignatureProvided();
            setFormData((_formData) => ({
              ..._formData,
              signature: digitalSignature || formData.signature,
            }));
            setStep((_step) => _step + 1);
          }}
          wrapperId="missing-details-submit"
        />
        {<div>&nbsp;</div>}
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'SignatureStep'}
            noExpertContentWrapper={({ children }) => (
              <div
                style={{
                  display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                }}
              >
                {children}
              </div>
            )}
            showButton={false}
            customMessage={(
              <div
                className={styles.contactUsInnerContent}
              >
                {'If you have any questions, please feel free to '}
                <ButtonLink
                  variant="primary"
                  label="message your Pension Adviser."
                  link="/inbox/new-message"
                  font="inherit"
                  target="_blank"
                  onClick={() => dispatchSerpsMissingDetailsMessageMeClicked()}
                />
              </div>
            )}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapDispatchToProps = {
  dispatchSignatureSizeError: serpsMissingDetailsSignatureSizeErrorAction,
  dispatchSerpsMissingDetailsSignatureProvided: serpsMissingDetailsSignatureProvided,
  dispatchSerpsMissingDetailsSignaturePageLoaded: serpsMissingDetailsSignaturePageLoaded,
  dispatchSerpsMissingDetailsMessageMeClicked: serpsMissingDetailsMessageMeClickedAction,
};

const mapStateToProps = (state) => ({
  digitalSignature: getHasDigitalSignature(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MissingDetailsSignature);
