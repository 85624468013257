import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import styles from './PastRetirementAgeModal.css';
import Modal from '../Modal/Modal';
import GlanceCard from '../GlanceCard/GlanceCard';
import GlanceCardContent from '../GlanceCard/GlanceCardContent';

import closeIcon from '../../assets/images/rebranding/cross-icon-in-circle.svg';

function PastRetirementAgeModal({
  show, onClose, retirementAge,
}) {
  return (
    <Modal show={show} backDropClick={onClose} centerContent>
      <GlanceCard>
        <GlanceCardContent>
          <div className={styles.modalHeaderSection}>
            <h3 className={styles.modalHeading}>{'Retirement age reached'}</h3>
            <div className={styles.closeWrapper} onClick={() => onClose()} onKeyUp={() => {}} role="button" tabIndex="0">
              <img src={closeIcon} alt="Close icon" />
              {' '}
              <span className={styles.modalCloseWrapperText}>{'Close'}</span>
            </div>
          </div>
          <hr />
          <p className={styles.body}>
            {'We cannot show you an estimated retirement income because you are older than your stated retirement age, '}
            {retirementAge}
            {'. If you think this is wrong, please '}
            <Link to="/inbox/new-message">{'message your adviser'}</Link>
            {'.'}
          </p>
        </GlanceCardContent>
      </GlanceCard>
    </Modal>
  );
}

PastRetirementAgeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PastRetirementAgeModal;
