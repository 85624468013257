exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css"), undefined);

// module
exports.push([module.id, ".-AddPensions-heading-2x-OK {\n  margin: 1px 0 12px;\n}\n.-AddPensions-body-1onpN {\n  margin: 0;\n}\n.-AddPensions-addPensions-QApNI button {\n  margin: 15px 0 0 0;\n}\n.-AddPensions-errorMessage-2PJ1K {\n  margin: 15px 0 0 0;\n}", ""]);

// exports
exports.locals = {
	"typography": "\"../../theme/typography.css\"",
	"h3": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h3"] + "",
	"bodyOne": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"dimensions": "\"../../theme/dimensions.css\"",
	"gutterSmall": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["gutterSmall"] + "",
	"gutter": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["gutter"] + "",
	"tablet": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["tablet"] + "",
	"typographyDesktop": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/dimensions.css").locals["typographyDesktop"] + "",
	"heading": "-AddPensions-heading-2x-OK " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h3"] + "",
	"body": "-AddPensions-body-1onpN " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"addPensions": "-AddPensions-addPensions-QApNI",
	"errorMessage": "-AddPensions-errorMessage-2PJ1K " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + ""
};