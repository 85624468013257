import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { Redirect, withRouter } from 'react-router-dom';

import { signupResendEmail } from '../../util/auth';
import LoaderPage from '../LoaderPage/LoaderPage';
import BasePage from '../../components/BasePage/BasePage';
import WithIntercom from '../../components/WithIntercom/WithIntercom';
import {
  getFirebaseUid,
  getSignup,
  getUser,
  getIsVerified,
  getRequestedVerification,
} from '../../redux/selectors';
import { getShouldShowMSMBanner } from '../../redux/selectors/experience';
import VerifyEmail from './VerifyEmail';

import * as actions from '../../redux/modules/eventDispatch';

class VerifyPage extends Component {
  setUserDetails = async (data = {}) => {
    const { firestore, uid } = this.props;

    await firestore
      .collection('users')
      .doc(uid)
      .collection('userDetails')
      .doc('customer')
      .set({
        ...data,
        lastModifiedDate: new Date().toISOString(),
        lastModifiedByUsername: 'account-hub',
      }, { merge: true });
  };

  showRoute = () => {
    const {
      uid, user, history, emailVerified, emailVerificationRequested,
    } = this.props;

    if (!emailVerified) {
      return {
        progress: 67,
        page: (
          <VerifyEmail uid={uid} user={user} verificationRequested={emailVerificationRequested} />
        ),
      };
    }

    const prevPage = get(history, 'location.state.from');
    if (prevPage) {
      return { page: <Redirect to={prevPage} /> };
    }

    return { page: <Redirect to="/" /> };
  }

  resendEmailHandler = async () => {
    const { uid, dispatchEventsWithPromise } = this.props;

    await dispatchEventsWithPromise(
      'VERIFY_EMAIL_REQUEST',
      async () => signupResendEmail(uid),
    );
  };

  render() {
    const { user, signupData, shouldShowMSMBanner } = this.props;
    const currentStep = this.showRoute();
    const allDataLoadedAndPresent = isLoaded(user) && user && user.contact && user.contact.email;

    if (!allDataLoadedAndPresent) {
      const metadata = {
        context: 'verify-page',
        isUserLoaded: isLoaded(user),
        user: JSON.stringify(user),
      };
      return <LoaderPage metadata={metadata} />;
    }

    return (
      <WithIntercom>
        <BasePage showNavMenu={false} messageWidget showMsmTile={shouldShowMSMBanner}>
          {
            (user.accountHubSetup || isLoaded(signupData))
            && allDataLoadedAndPresent
            && currentStep.page
          }
        </BasePage>
      </WithIntercom>
    );
  }
}

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
  user: getUser(state),
  signupData: getSignup(state),
  emailVerified: getIsVerified(state),
  emailVerificationRequested: getRequestedVerification(state),
  shouldShowMSMBanner: getShouldShowMSMBanner(state),
});

export default compose(
  connect(mapStateToProps, actions),
  firestoreConnect((props) => ([
    {
      collection: 'users',
      doc: props.uid,
      subcollections: [{ collection: 'signup' }],
      storeAs: 'signups',
    },
  ])),
)(withRouter(VerifyPage));

VerifyPage.propsTypes = {
  isFetching: PropTypes.bool,
  isEmailVerified: PropTypes.bool,
  signupData: PropTypes.objectOf(PropTypes.string),
};

VerifyPage.defaultProps = {
  signupData: {},
};
