import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import {
  backClickedAction,
} from '../../redux/modules/withdrawal';
import {
  getImportantConsiderationsRouting,
} from '../../redux/selectors';

import AssessingPension from './AssessingPension';

import {
  JOURNEYS,
  IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY,
  IMPORTANT_CONSIDERATIONS_INFO_JOURNEY,
  IMPORTANT_CONSIDERATIONS,
  ASSESSING_PENSION,
  WITHDRAWAL_ADVICE,
  TAX_IMPLICATIONS,
  WITHDRAWAL_CHARGES,
  WITHDRAWAL_INVESTMENT,
  BENEFITS,
  HEALTH,
  DEBT,
  ALTERNATIVES,
  COMBINE_PENSIONS,
  IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY_PATH,
} from './flows';
import ImportantConsiderations from './ImportantConsiderations';
import TaxImplications from './TaxImplications';
import WithdrawalAdvice from './WithdrawalAdvice';
import WithdrawalCharges from './WithdrawalCharges';
import WithdrawalInvestment from './WithdrawalInvestment';
import Benefits from './Benefits';
import Health from './Health';
import Debt from './Debt';
import Alternatives from './Alternatives';
import CombinePensions from './CombinePensions';

const WithdrawalMiniJourney = ({
  history,
  dispatchBackClicked,
  importantConsiderationsRouting,
}) => {
  const pathname = get(history, 'location.pathname');
  const flow = pathname.includes(
    IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY_PATH,
  ) ? IMPORTANT_CONSIDERATIONS_PRE_WITHDRAWAL_JOURNEY : IMPORTANT_CONSIDERATIONS_INFO_JOURNEY;
  const [currentJourney, setCurrentJourney] = useState(flow);
  const [totalSteps, setTotalSteps] = useState(JOURNEYS[currentJourney].length);
  const [step, setStep] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    setTotalSteps(JOURNEYS[currentJourney].length);
    if (get(importantConsiderationsRouting, 'from', '') === 'withdrawal-options'
    || get(importantConsiderationsRouting, 'from', '') === 'pension-options') {
      setStep(JOURNEYS[currentJourney].length - 1);
    }
  }, [currentJourney]);

  const setStepWrapper = (newStepArg) => {
    const newStepValue = typeof newStepArg === 'function' ? newStepArg(step) : newStepArg;
    setStep(newStepValue);
  };

  const hideBackNavigation = get(history, 'location.state.hideBackNavigation');
  const onBack = (event) => {
    dispatchBackClicked();
    if (step > 0) {
      setStep((_step) => _step - 1);
    } else {
      history.push(get(importantConsiderationsRouting, 'back', '/'));
    }
    event.stopPropagation();
  };
  const stepsMap = {
    [ASSESSING_PENSION]: (
      <AssessingPension
        flow={flow}
        step={step}
        setStep={setStepWrapper}
        totalSteps={totalSteps}
        setCurrentJourney={setCurrentJourney}
        hideBackNavigation={hideBackNavigation}
        onBack={onBack}
      />
    ),
    [IMPORTANT_CONSIDERATIONS]: (
      <ImportantConsiderations
        flow={flow}
        step={step}
        setStep={setStepWrapper}
        totalSteps={totalSteps}
        setCurrentJourney={setCurrentJourney}
        hideBackNavigation={hideBackNavigation}
        onBack={onBack}
      />
    ),
    [WITHDRAWAL_ADVICE]: (
      <WithdrawalAdvice
        flow={flow}
        step={step}
        setStep={setStepWrapper}
        totalSteps={totalSteps}
        setCurrentJourney={setCurrentJourney}
        hideBackNavigation={hideBackNavigation}
      />
    ),
    [TAX_IMPLICATIONS]: (
      <TaxImplications
        flow={flow}
        step={step}
        setStep={setStepWrapper}
        totalSteps={totalSteps}
        setCurrentJourney={setCurrentJourney}
        hideBackNavigation={hideBackNavigation}
      />
    ),
    [WITHDRAWAL_CHARGES]: (
      <WithdrawalCharges
        flow={flow}
        step={step}
        setStep={setStepWrapper}
        totalSteps={totalSteps}
        setCurrentJourney={setCurrentJourney}
        hideBackNavigation={hideBackNavigation}
      />
    ),
    [WITHDRAWAL_INVESTMENT]: (
      <WithdrawalInvestment
        flow={flow}
        step={step}
        setStep={setStepWrapper}
        totalSteps={totalSteps}
        setCurrentJourney={setCurrentJourney}
        hideBackNavigation={hideBackNavigation}
      />
    ),
    [BENEFITS]: (
      <Benefits
        flow={flow}
        step={step}
        setStep={setStepWrapper}
        totalSteps={totalSteps}
        setCurrentJourney={setCurrentJourney}
        hideBackNavigation={hideBackNavigation}
      />
    ),
    [HEALTH]: (
      <Health
        flow={flow}
        step={step}
        setStep={setStepWrapper}
        totalSteps={totalSteps}
        setCurrentJourney={setCurrentJourney}
        hideBackNavigation={hideBackNavigation}
      />
    ),
    [DEBT]: (
      <Debt
        flow={flow}
        step={step}
        setStep={setStepWrapper}
        totalSteps={totalSteps}
        setCurrentJourney={setCurrentJourney}
        hideBackNavigation={hideBackNavigation}
      />
    ),
    [ALTERNATIVES]: (
      <Alternatives
        step={step}
        setStep={setStepWrapper}
        totalSteps={totalSteps}
        setCurrentJourney={setCurrentJourney}
        hideBackNavigation={hideBackNavigation}
      />
    ),
    [COMBINE_PENSIONS]: (
      <CombinePensions
        step={step}
        setStep={setStepWrapper}
        totalSteps={totalSteps}
        setCurrentJourney={setCurrentJourney}
        hideBackNavigation={hideBackNavigation}
        forward={get(importantConsiderationsRouting, 'forward', '/')}
      />
    ),
  };

  return (
    stepsMap[JOURNEYS[currentJourney][step]]
  );
};

const mapStateToProps = (state) => ({
  importantConsiderationsRouting: getImportantConsiderationsRouting(state),
});

const mapDispatchToProps = {
  dispatchBackClicked: backClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WithdrawalMiniJourney));
