import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { CDS_LOADING_ERROR } from '../../redux/modules/customerDataSet';

class CustomerDataSetError extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({ type: CDS_LOADING_ERROR });
  }

  render() {
    return (
      <Fragment>
        <div>
          <h1 className="h2">{'Sorry something went wrong'}</h1>
        </div>
        <p>{'We are unable to load the form at the moment, please try again later.'}</p>
      </Fragment>
    );
  }
}

export default connect(() => ({}))(CustomerDataSetError);
