import React from 'react';
import styles from './FooterDisclaimer.css';

const FooterDisclaimer = () => (
  <div className={styles.wrapper}>
    <p className={styles.disclaimer}>
      <b className={styles.emphasis}>
        {'Capital at risk. '}
      </b>
      {'This website does not constitute personal advice. If you are in doubt as to the suitability of an investment please contact one of our advisers. Prevailing tax rates and reliefs are dependent on your individual circumstances and are subject to change. Past performance is not a guide to future performance.'}
    </p>
  </div>
);

export default FooterDisclaimer;
