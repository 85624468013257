import { combineEpics } from 'redux-observable';

import { mergeMap } from 'rxjs/operators';
import { APP_INIT } from '../modules/app';

const appInitDataLoad = (action$) => {
  return action$.ofType(APP_INIT)
    .pipe(mergeMap(() => {
      const actions = [];
      return actions;
    }));
};

export default combineEpics(
  appInitDataLoad,
);
