import React, { useEffect, useRef } from 'react';

const TrustBoxWidget = ({ dataTemplateId, width, height }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!document) return;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.async = true;
    document.body.appendChild(script);
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  /*
   * Source: Trustpilot Website widgets - Mini)
   * Supported sizes:
   * Responsive (min. 120px x 90px, max. 100% x 130px)
   * Mobile, tablet and desktop ready
   */

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id={dataTemplateId}
      data-businessunit-id="57ff44480000ff000595f84b"
      data-style-height={height}
      data-style-width={width}
      data-theme="dark"
      data-font-family="Arimo"
    >
      <a
        href="https://uk.trustpilot.com/review/www.profilepensions.co.uk"
        target="_blank"
        rel="noopener noreferrer"
      >
        {'Trustpilot'}
      </a>
    </div>
  );
};

TrustBoxWidget.defaultProps = {
  dataTemplateId: '53aa8807dec7e10d38f59f32',
  width: '100%',
  height: '100px',
};

export default TrustBoxWidget;
