import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';

import styles from './LegacyPlanModal.css';
import Modal from '../Modal/Modal';
import GlanceCard from '../GlanceCard/GlanceCard';
import GlanceCardContent from '../GlanceCard/GlanceCardContent';

import closeIcon from '../../assets/images/rebranding/cross-icon-in-circle.svg';

function LegacyPlanModal({
  show, onClose, subHeadings, isOngoingCdsAllowed,
}) {
  return (
    <Modal show={show} backDropClick={onClose}>
      <GlanceCard>
        <GlanceCardContent wrapperStyles={{ maxHeight: '90vh' }}>
          <div className={styles.closeWrapper} onClick={() => onClose()} onKeyUp={() => {}} role="button" tabIndex="0">
            <img src={closeIcon} alt="Close icon" />
            {' '}
            <span className={styles.closeWrapperText}>{'Close'}</span>
          </div>
          <h3 className={styles.heading}>{isOngoingCdsAllowed ? 'We need some information' : 'You need to update your pension plan'}</h3>
          {subHeadings && (
            <div className={styles.yourPensionPlan}>
              {subHeadings.map((subHeading) => {
                return (
                  <React.Fragment>
                    <p className={styles.subHeadingTitle}>{subHeading.title}</p>
                    <p className={styles.subHeadingContent}>{subHeading.content}</p>
                  </React.Fragment>
                );
              })}
            </div>
          )}
          <p className={styles.body}>{'You need to update your pension plan before you can transfer another policy, add a regular contribution or pay a lump sum amount.'}</p>
          {isOngoingCdsAllowed ? (
            <Button
              size="medium"
              label="Update my goals"
              to="/your-latest-information"
            />
          ) : (
            <Button label="Message us" to="/inbox/new-message" />
          )}
        </GlanceCardContent>
      </GlanceCard>
    </Modal>
  );
}

LegacyPlanModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LegacyPlanModal;
