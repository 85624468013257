import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import once from 'lodash/once';
import classnames from 'classnames';
import styles from './AddPensionsPage.css';
import { currencyString } from '../../util/currency';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import ContactUsContent from '../../components/ContactUsContent/ContactUsContent';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';
import ProgressStepper from '../../components/ProgressStepper/ProgressStepper';
import BaseGrid from '../../components/BaseGrid/BaseGrid';
import {
  returnLinkClickedAction,
  messageMeClickedAction,
  pensionValueSkipStepClickedAction,
  pensionValueSubmitClickedAction,
  pensionValueStepLandedAction,
  pensionValueChangedAction,
  tryFindAndCombineServiceClickedAction,
} from '../../redux/modules/pensions';
import { transferOptions } from '../../util/constants';

const PensionValueStep = ({
  setFormData,
  setStep,
  amendMode,
  step,
  totalSteps,
  dispatchReturnLinkClicked,
  dispatchMessageMeClicked,
  dispatchSkipStepClicked,
  isCustomerOngoing,
  dispatchPensionValueSubmitClicked,
  dispatchPensionValueStepLanded,
  dispatchPensionValueChanged: _dispatchPensionValueChanged,
  dispatchTryFindAndCombineServiceClicked,
  formData,
  resetFormData,
}) => {
  const [estimatedValue, setEstimatedValue] = useState(formData.estimatedValue || 0);
  const [dispatchPensionValueChanged] = useState(() => once(_dispatchPensionValueChanged));
  const isTransferBasic = formData.transferOption === transferOptions.TRANSFER_BASIC;
  const isTransferWithCheck = formData.transferOption === transferOptions.TRANSFER_WITH_CHECK;

  const _placeholder = `Enter approximate value ${isTransferWithCheck ? 'if known' : ''}`;

  const defaultEstimatedMask = currencyString(parseInt(estimatedValue, 10));

  const [estimatedValueMask, setEstimatedValueMask] = useState(defaultEstimatedMask);
  const [isInputFocussed, setIsInputFocussed] = useState(false);

  const toggleInputFocus = () => setIsInputFocussed((isFocussed) => !isFocussed);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchPensionValueStepLanded(isCustomerOngoing, formData.transferOption);
  }, []);

  return (
    <BaseGrid tabletTwelveColumn addPensionsTheme>
      <div className={styles.pensionValueWrapper}>
        <div className={styles.progressContainer}>
          <ProgressStepper maxStep={totalSteps} step={step + 1} />
        </div>
        {!amendMode && (
          <ButtonLink
            onClick={(event) => {
              dispatchReturnLinkClicked(isCustomerOngoing, formData.transferOption);
              setStep((_step) => _step - 1);
              event.stopPropagation();
            }}
            label="← Back"
          />
        )}
        <h2 className={styles.approxValueHeading}>
          {'Please tell us the approximate value of this pension'}
          {isTransferBasic && ('*')}
        </h2>
        <input
          className={styles.estimatedValueInput}
          placeholder={_placeholder}
          value={(isInputFocussed || estimatedValue) ? estimatedValueMask : _placeholder}
          onFocus={toggleInputFocus}
          onBlur={toggleInputFocus}
          onChange={(event) => {
            dispatchPensionValueChanged(isCustomerOngoing, formData.transferOption);
            const strippedInput = event.target.value.replace(/\D/g, '');
            if (strippedInput) {
              const _estimatedValue = parseInt(strippedInput, 10);
              const _estimatedValueMask = currencyString(_estimatedValue);
              setEstimatedValue(_estimatedValue);
              setEstimatedValueMask(_estimatedValueMask);
            } else {
              setEstimatedValue(0);
              setEstimatedValueMask('£0');
            }
          }}
        />
        <div
          className={styles.submitContainer}
        >
          <div
            className={styles.valueSubmitButtonWrapper}
          >
            <Button
              size="large"
              disabled={!estimatedValue}
              label={(amendMode) ? 'Confirm' : 'Submit'}
              onClick={() => {
                dispatchPensionValueSubmitClicked(isCustomerOngoing, formData.transferOption);
                setFormData((_formData) => ({
                  ..._formData,
                  estimatedValue,
                }));
                setStep((_step) => _step + 1);
              }}
            />
          </div>
          {isTransferWithCheck && (
            <ButtonLink
              variant="primary"
              label={<span className={styles.employerLinkText}>{'Skip this step'}</span>}
              onClick={() => {
                dispatchSkipStepClicked(isCustomerOngoing, formData.transferOption);
                setFormData((_formData) => ({
                  ..._formData,
                  estimatedValue: undefined,
                }));
                setStep((_step) => _step + 1);
              }}
            />
          )}
          {isTransferBasic && (
            <ButtonLink
              label={(
                <div>
                  <span className={classnames(styles.employerLinkText, styles.inlineBlock)}>{'Don’t have this information?'}</span>
                  <span className={classnames(styles.employerLinkText, styles.inlineBlock)}>{'Try our “find, check and transfer” service'}</span>
                </div>
              )}
              onClick={() => {
                dispatchTryFindAndCombineServiceClicked(isCustomerOngoing, formData.transferOption);
                resetFormData();
                setStep(0);
              }}
            />
          )}
        </div>
        <div className={styles.pensionAdviserCard}>
          <ContactUsContent
            source={'PensionValueStep'}
            noExpertContentWrapper={({ children }) => (
              <div
                style={{
                  display: 'flex', alignItems: 'center', flexShrink: 'initial', padding: '10px', flexWrap: 'wrap',
                }}
              >
                {children}
              </div>
            )}
            customMessage={(
              <div
                className={styles.contactUsInnerContent}
              >
                {'We use this information to help locate your pension. If you have any questions, please feel free to '}
                <ButtonLink
                  label="message me"
                  variant="primary"
                  to="/inbox/new-message"
                  font="inherit"
                  onClick={() => dispatchMessageMeClicked(
                    isCustomerOngoing,
                    formData.transferOption,
                  )}
                />
                {'.'}
              </div>
            )}
            showButton={false}
          />
        </div>
      </div>
    </BaseGrid>
  );
};

const mapStateToProps = (state) => ({
  isCustomerOngoing: getIsCustomerOngoing(state),
});

const mapDispatchToProps = {
  dispatchReturnLinkClicked: returnLinkClickedAction,
  dispatchMessageMeClicked: messageMeClickedAction,
  dispatchSkipStepClicked: pensionValueSkipStepClickedAction,
  dispatchPensionValueSubmitClicked: pensionValueSubmitClickedAction,
  dispatchPensionValueStepLanded: pensionValueStepLandedAction,
  dispatchPensionValueChanged: pensionValueChangedAction,
  dispatchTryFindAndCombineServiceClicked: tryFindAndCombineServiceClickedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PensionValueStep);
