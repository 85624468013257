exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css"), undefined);

// module
exports.push([module.id, ".-FAQ-frequentlyAskedQuestionsContainer-3yFbZ {\n  margin: 0;\n}\n.-FAQ-subHeading-r_o6M {\n  margin: 0 0 20px;\n}\n.-FAQ-question-2GsXW {\n  text-align: left;\n  color: " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + ";\n  margin: 0 10px 0 0;\n}\n.-FAQ-answer-12APo {\n  margin: 15px 0 0 0;\n  padding: 0;\n}\n.-FAQ-frequentlyAskedQuestionRow-25z7e {\n  margin: 0 0 15px 0;\n  padding: 0 0 15px 0;\n  border-bottom: 1px solid " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["fog"] + ";\n}\n.-FAQ-frequentlyAskedQuestionRow-25z7e button {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 100%;\n  cursor: pointer;\n  border: none;\n  background: none;\n  margin: 0;\n  padding: 0;\n}\n.-FAQ-upArrow-3X-Zp {\n  -webkit-transform: rotate(180deg);\n          transform: rotate(180deg);\n}\n.-FAQ-sourceHeading-1PfjU {\n  font-family: 'Lato';\n  font-weight: 700;\n  font-size: 14px;\n  line-height: 16px;\n  margin: 0;\n}\n.-FAQ-source-37PYL {\n  display: block;\n  font-family: 'Lato';\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 16px;\n  margin: 0;\n}\n.-FAQ-sourcesContainer-2ntL0 {\n  margin: 25px 0 0 0;\n}", ""]);

// exports
exports.locals = {
	"typography": "\"../../theme/typography.css\"",
	"bodyOne": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"h5Website": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h5Website"] + "",
	"h6Website": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h6Website"] + "",
	"colors": "\"../../theme/colors.css\"",
	"textTitleRegular": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["textTitleRegular"] + "",
	"fog": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/colors.css").locals["fog"] + "",
	"frequentlyAskedQuestionsContainer": "-FAQ-frequentlyAskedQuestionsContainer-3yFbZ",
	"subHeading": "-FAQ-subHeading-r_o6M " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h5Website"] + "",
	"question": "-FAQ-question-2GsXW " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h6Website"] + "",
	"answer": "-FAQ-answer-12APo " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"frequentlyAskedQuestionRow": "-FAQ-frequentlyAskedQuestionRow-25z7e",
	"upArrow": "-FAQ-upArrow-3X-Zp",
	"sourceHeading": "-FAQ-sourceHeading-1PfjU",
	"source": "-FAQ-source-37PYL",
	"sourcesContainer": "-FAQ-sourcesContainer-2ntL0"
};