import React from 'react';
import { connect } from 'react-redux';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { SERPS_PENSION_FOUND_UPDATE_DISMISSED, serpsPensionFoundNotificationDismissed } from '../../redux/modules/notificationTracking';
import { getFirebaseUid } from '../../redux/selectors';

const SerpsPensionFoundNotification = ({
  uid,
  dispatchSerpsPensionFoundNotificationDismissed,
}) => {
  return (
    <NotificationBanner
      alertCategory={'Blue'}
      alertTitle={'SERPS Update'}
      alertDescription={'Good news HMRC has confirmed you have the following pensions. Check & Transfer them into your Profile Pensions plan.'}
      ctaButtonOptions={{
        label: 'Transfer pension',
        link: '/plan-activity/#pensionTransfers',
        event: SERPS_PENSION_FOUND_UPDATE_DISMISSED,
        handleClick: () => dispatchSerpsPensionFoundNotificationDismissed(uid),
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
});

const mapDispatchToProps = ({
  dispatchSerpsPensionFoundNotificationDismissed: serpsPensionFoundNotificationDismissed,
});

export default connect(mapStateToProps, mapDispatchToProps)(SerpsPensionFoundNotification);
