import React from 'react';
import { ButtonVariants, ButtonSize } from './ButtonTypes';
import withLinkTo from '../../util/withLinkTo';
import styles from './Button.css';

const Button = ({
  variant = ButtonVariants.primary,
  size = ButtonSize.small,
  disabled = false,
  label = '',
  onClick,
  loading = false,
  children,
}) => {
  return (
    <button type="submit" className={`${styles.button} ${styles[variant]} ${styles[size]}`} onClick={onClick} disabled={disabled || loading}>
      {loading && <span className={styles.spinner} />}
      {label}
      {children}
    </button>
  );
};

export default withLinkTo(Button);
