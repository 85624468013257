import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { statusNotificationInstructionCompletedClosed } from '../../redux/modules/notificationTracking';
import { getFirebaseUid } from '../../redux/selectors';
import { getCompleteAndUnseenContributionInstructions } from '../../redux/selectors/investmentAdvice';
import { getIsCustomerOngoing } from '../../redux/selectors/experience';

const RegularContributionsUpdateNotification = ({
  uid,
  hasCompleteAndUnseenContributionInstructions,
  isCustomerOngoing,
  dispatchStatusNotificationInstructionCompletedClosed,
}) => {
  // TODO's when the dashboard is ready:
  // Update auto-message with plan activity link
  //    -> instructionIsForRegularContribution? message body in comms-controller

  const dispatchCallback = () => {
    dispatchStatusNotificationInstructionCompletedClosed(
      uid,
      get(hasCompleteAndUnseenContributionInstructions, '0._id', isCustomerOngoing),
    );
  };

  return (
    <NotificationBanner
      alertCategory={'Green'}
      alertTitle={'Regular contributions setup complete'}
      expandableAlertDescription={'We have sent a direct debit instruction to your bank to set up regular contributions into your pension.'}
      // if scroll to a section is required, use: /plan-activity/#otherInstructions
      ctaButtonOptions={{
        label: 'View my plan activity',
        link: '/plan-activity',
        handleClick: dispatchCallback,
      }}
      closeButtonOptions={{
        handleClick: dispatchCallback,
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  uid: getFirebaseUid(state),
  isCustomerOngoing: getIsCustomerOngoing(state),
  hasCompleteAndUnseenContributionInstructions: getCompleteAndUnseenContributionInstructions(state),
});

const mapDispatchToProps = {
  dispatchStatusNotificationInstructionCompletedClosed:
      statusNotificationInstructionCompletedClosed,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegularContributionsUpdateNotification);
