import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { Redirect } from 'react-router';
import RecommendedPensionPlanCard from '../../../../components/RecommendedPensionPlanCard/RecommendedPensionPlanCard';

import Testimonials from '../../../../components/Testimonials/Testimonials';
import SERPSNextSteps from '../../../../components/SERPSCard/SERPSNextSteps';
import SERPSAtRetirementCard from '../../../../components/SERPSCard/SERPSAtRetirementCard';
import SerpsCtaTile from './SerpsCtaTile';
import { serpsDashboardViewed } from '../../../../redux/modules/serps';

import { planSummaryCtaClickedAction } from '../../../../redux/modules/investmentAdvice';
import { getAuthorisedSerpsPensions, getAllEligibleSerpsPensions } from '../../../../redux/selectors/pensions';
import {
  getHasSerpsAuthority,
  getCustomerHmrcResponseFields,
  getHasPensionsWithHmrcSource,
  getPensionsWithHmrcResponseEligibleYes,
  getAreAllPensionsWithHmrcResponseEligibleNo,
  getHasPensionsWithHmrcResponseEligibleCheck,
  getWasSerpsTraceRequested,
  getCustomerEligibleForSerps,
  getIsSerpsFirstSession,
} from '../../../../redux/selectors/serps';

import styles from './OnboardingSerps.css';

const OnboardingSerps = ({
  dispatchPlanSummaryCtaClickedAction,
  hasSerpsAuthority,
  customerHmrcResponseFields,
  hasPensionsWithHmrcSource,
  pensionsWithHmrcResponseEligibleYes,
  hasPensionsWithHmrcResponseEligibleCheck,
  authorisedSerpsPensions,
  allEligibleSerpsPensions,
  serpsTrace,
  customerEligibleForSerps,
  dispatchSerpsDashboardViewed,
  areAllPensionsWithHmrcResponseEligibleNo,
  isSerpsFirstSession,
}) => {
  useEffect(() => {
    dispatchSerpsDashboardViewed();
    window.scrollTo(0, 0);
  }, []);
  const hasPensionsWithHmrcResponseEligibleYes = pensionsWithHmrcResponseEligibleYes.length > 0;

  const hasAuthorisedAllSerpsPensions = authorisedSerpsPensions.length
    === allEligibleSerpsPensions.length && authorisedSerpsPensions.length > 0;
  const hmrcResponseReceivedDate = get(customerHmrcResponseFields, 'hmrcResponseReceivedDate');
  const hmrcResponse = get(customerHmrcResponseFields, 'hmrcResponse');

  const missingDetailsComplete = serpsTrace && hasSerpsAuthority;
  const negativeHmrcResponse = hmrcResponse
    && hmrcResponseReceivedDate
    && moment.utc().diff(hmrcResponseReceivedDate, 'days') > 5
    && (!hasPensionsWithHmrcSource || areAllPensionsWithHmrcResponseEligibleNo);

  const noHmrcPensionsFound = hmrcResponse
    && hmrcResponseReceivedDate
    && moment.utc().diff(hmrcResponseReceivedDate, 'days') > 5
    && !hasPensionsWithHmrcSource;

  // (1) Missing details CTA
  const showMissingDetailsCta = !missingDetailsComplete && customerEligibleForSerps;
  // (2) Missing details complete and waiting for HMRC response
  const showWaitingForHmrcResponseCta = customerEligibleForSerps
    && missingDetailsComplete
    && !negativeHmrcResponse
    && (!hasPensionsWithHmrcResponseEligibleYes || hasPensionsWithHmrcResponseEligibleCheck);
  // (3) Missing details complete and HMRC responded, waiting for all pensions to complete vetting
  const showHmrcResponsePendingPensionsVettingCta = customerEligibleForSerps
    && missingDetailsComplete
    && !negativeHmrcResponse
    && hasPensionsWithHmrcResponseEligibleCheck
    && !hasAuthorisedAllSerpsPensions;
  // (3) Missing details complete and HMRC responded with yes for eligible pensions
  const showHmrcResponseEligiblePensionsYesCta = customerEligibleForSerps
    && missingDetailsComplete
    && hasPensionsWithHmrcResponseEligibleYes
    && !negativeHmrcResponse
    && !hasPensionsWithHmrcResponseEligibleCheck
    && !hasAuthorisedAllSerpsPensions;
  // (4) Missing details complete and HMRC responsed, customer gave authorisation for SERPS pensions
  const showPostSerpsPensionsAuthorisationCta = customerEligibleForSerps
    && missingDetailsComplete
    && !negativeHmrcResponse
    && hasAuthorisedAllSerpsPensions;
  // (5) Missing details complete and HMRC responded with no for eligible pensions
  const showNegativeHmrcResponse = customerEligibleForSerps
    && missingDetailsComplete
    && negativeHmrcResponse;
  // (6) Missing details complete but customer is not eligible for serps trace
  const showNotEligibleForSerpsTraceCta = !customerEligibleForSerps;
  if (isSerpsFirstSession) {
    return (<Redirect to={'/serps-request'} />);
  }

  return (
    <React.Fragment>
      <SerpsCtaTile
        showMissingDetailsCta={showMissingDetailsCta}
        showWaitingForHmrcResponseCta={showWaitingForHmrcResponseCta}
        showHmrcResponseEligiblePensionsYesCta={showHmrcResponseEligiblePensionsYesCta}
        showHmrcResponsePendingPensionsVettingCta={showHmrcResponsePendingPensionsVettingCta}
        showPostSerpsPensionsAuthorisationCta={showPostSerpsPensionsAuthorisationCta}
        showNegativeHmrcResponse={showNegativeHmrcResponse}
        showNotEligibleForSerpsTraceCta={showNotEligibleForSerpsTraceCta}
      />
      <div className={styles.serpsNextStepsWrapper}>
        <SERPSNextSteps
          showMissingDetailsCta={showMissingDetailsCta}
          showWaitingForHmrcResponseCta={showWaitingForHmrcResponseCta}
          showHmrcResponseEligiblePensionsYesCta={showHmrcResponseEligiblePensionsYesCta}
          showPostSerpsPensionsAuthorisationCta={showPostSerpsPensionsAuthorisationCta}
          showNegativeHmrcResponse={showNegativeHmrcResponse}
          showNotEligibleForSerpsTraceCta={showNotEligibleForSerpsTraceCta}
          noHmrcPensionsFound={noHmrcPensionsFound}
          showPensionAdvisorContent
        />
      </div>
      <div className={styles.pensionPlanWrapper}>
        <RecommendedPensionPlanCard
          onClick={() => dispatchPlanSummaryCtaClickedAction(false)}
        />
      </div>
      <div className={styles.retirementWrapper}>
        <SERPSAtRetirementCard />
      </div>
      <div className={styles.testimonialsSection}>
        <Testimonials />
      </div>
    </React.Fragment>
  );
};

OnboardingSerps.propTypes = {
  serpsTrace: PropTypes.bool,
  hasSerpsAuthority: PropTypes.bool,
  authorisedSerpsPensions: PropTypes.arrayOf(PropTypes.shape({
    policyName: PropTypes.string,
  })),
  pensionsWithHmrcResponseEligibleYes: PropTypes.arrayOf(PropTypes.shape({
    policyName: PropTypes.string,
  })),
  hasPensionsWithHmrcResponseEligibleCheck: PropTypes.bool,
  hasPensionsWithHmrcSource: PropTypes.bool,
  customerHmrcResponseFields: PropTypes.shape({
    hmrcResponseReceivedDate: PropTypes.string,
    hmrcResponse: PropTypes.string,
  }),
  customerEligibleForSerps: PropTypes.bool,
};

OnboardingSerps.defaultProps = {
  serpsTrace: false,
  hasSerpsAuthority: false,
  authorisedSerpsPensions: [],
  pensionsWithHmrcResponseEligibleYes: [],
  hasPensionsWithHmrcResponseEligibleCheck: false,
  hasPensionsWithHmrcSource: false,
  customerHmrcResponseFields: {
    hmrcResponseReceivedDate: null,
    hmrcResponse: null,
  },
  customerEligibleForSerps: false,
};

const mapStateToProps = (state) => ({
  allEligibleSerpsPensions: getAllEligibleSerpsPensions(state),
  serpsTrace: getWasSerpsTraceRequested(state),
  hasSerpsAuthority: getHasSerpsAuthority(state),
  authorisedSerpsPensions: getAuthorisedSerpsPensions(state),
  pensionsWithHmrcResponseEligibleYes: getPensionsWithHmrcResponseEligibleYes(state),
  areAllPensionsWithHmrcResponseEligibleNo: getAreAllPensionsWithHmrcResponseEligibleNo(state),
  hasPensionsWithHmrcResponseEligibleCheck: getHasPensionsWithHmrcResponseEligibleCheck(state),
  hasPensionsWithHmrcSource: getHasPensionsWithHmrcSource(state),
  customerHmrcResponseFields: getCustomerHmrcResponseFields(state),
  customerEligibleForSerps: getCustomerEligibleForSerps(state),
  isSerpsFirstSession: getIsSerpsFirstSession(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchPlanSummaryCtaClickedAction: () => dispatch(planSummaryCtaClickedAction(false)),
    dispatchSerpsDashboardViewed: () => dispatch(serpsDashboardViewed()),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(OnboardingSerps);
