import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import BasePage from '../../components/BasePage/BasePage';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Button from '../../components/Button/Button';

import { getPensionExpert } from '../../redux/selectors/investmentAdvice';
import { lumpSumWithdrawalAdviserPageVisitedAction } from '../../redux/modules/withdrawal';

import styles from './WithdrawalAdviserPage.css';

const WithdrawalAdviserPage = ({
  pensionExpert,
  dispatchLumpSumWithdrawalAdviserPageVisited,
}) => {
  const adviserImageUrl = get(pensionExpert, 'imageUrl');

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchLumpSumWithdrawalAdviserPageVisited();
  }, []);

  return (
    <BasePage messageWidget>
      <ButtonLink
        link="/setup-withdrawal-lump-sum?step=1"
        label="← Back"
        secondary
        mid
      />
      <div className={styles.container}>
        <h2 className={styles.heading}>{'You need to contact your Pension Adviser'}</h2>
        {adviserImageUrl && <img className={styles.expertImage} src={adviserImageUrl} alt="Pension adviser" />}
        <p className={styles.body}>
          {' '}
          {'As you have requested a significant withdrawal, we won’t be able to accept this request online. We would like to discuss this with you to ensure that it is in your best interests and to make sure you understand any potential tax implications. Please message your Pension Adviser to let them know the best time to contact you.'}
          {' '}
        </p>
        <div className={styles.contactRow}>
          <Button
            variant="primary"
            link="/inbox/new-message?subject=Withdrawal%20Request"
            label="Message me"
            font="bodyTwo"
          />
        </div>
        <div>
          <ButtonLink
            to="/"
            label="Back to Dashboard"
            secondary
            mid
          />
        </div>
      </div>
    </BasePage>
  );
};

const mapDispatchToProps = {
  dispatchLumpSumWithdrawalAdviserPageVisited: lumpSumWithdrawalAdviserPageVisitedAction,
};

const mapStateToProps = (state) => ({
  pensionExpert: getPensionExpert(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalAdviserPage);
