exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css"), undefined);

// module
exports.push([module.id, ".-MentionMeTile-mentionMeContainerImg-xALy9 {\n  height: 75px;\n  margin-bottom: 15px;\n}\n.-MentionMeTile-mentionMeContainerHeading-2uAiF {\n  margin: 0 0 10px;\n}\n.-MentionMeTile-mentionMeContainerBody-1ab3q {\n  margin: 0;\n}\n", ""]);

// exports
exports.locals = {
	"typography": "\"../../theme/typography.css\"",
	"h3": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h3"] + "",
	"bodyOne": "" + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + "",
	"mentionMeContainerImg": "-MentionMeTile-mentionMeContainerImg-xALy9",
	"mentionMeContainerHeading": "-MentionMeTile-mentionMeContainerHeading-2uAiF " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["h3"] + "",
	"mentionMeContainerBody": "-MentionMeTile-mentionMeContainerBody-1ab3q " + require("-!../../../node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!../../../node_modules/postcss-loader/src/index.js!../../theme/typography.css").locals["bodyOne"] + ""
};