import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import get from 'lodash/get';
import classNames from 'classnames';

import Button from '../Button/Button';
import TrustpilotAndFSCSWidget from '../TrustpilotAndFSCSWidget/TrustpilotAndFSCSWidget';
import ContactUsContent from '../ContactUsContent/ContactUsContent';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import ButtonLink from '../ButtonLink/ButtonLink';

import ThreeCircles from '../../assets/images/rebranding/tri-tone-overlapping-circles.svg';
import MoneyCirclePlus from '../../assets/images/rebranding/add-money.svg';
import ExplainerAnimationPoster from '../../assets/images/explainer-animation-poster.svg';
import PlayButtonIcon from '../../assets/images/play-button-icon.png';

import {
  getFirstName,
  getUserProfileCreatedBeforeMillis,
} from '../../redux/selectors';
import {
  getPensionExpert,
} from '../../redux/selectors/investmentAdvice';

import {
  initialSignupPageViewAction,
  postSignUpPageView,
  signUpCdsCTAClicked,
  messageMeCTAClicked,
  animationVideoStarted,
  animationVideoEnded,
  animationVideoInterrupted,
} from '../../redux/modules/dashboard';

import { twoHoursInMilliseconds } from '../../util/constants';

import styles from './PostSignUpAnimatedVideo.css';

const PostSignUpAnimatedVideo = ({
  customerFirstName,
  pensionExpert,
  dispatchInitialSignupPageView,
  dispatchPostSignUpPageView,
  dispatchSignUpCdsCTAClicked,
  dispatchMessageMeCTAClicked,
  dispatchAnimationVideoStarted,
  dispatchAnimationVideoEnded,
  dispatchAnimationVideoInterrupted,
  isFirstSession,
  showMsmTile,
}) => {
  useEffect(() => {
    dispatchInitialSignupPageView();
    dispatchPostSignUpPageView(isFirstSession);
  }, []);

  const isDesktop = useMediaQuery({
    query: '(min-width: 1280px)',
  });

  const MSMCopy = () => {
    return (
      <div
        className={
          classNames(
            styles.msmContainer,
            {
              [styles.msmContainerFirstSession]: isFirstSession,
            },
          )
        }
      >
        <h3 className={styles.msmHeading}>{'Create your personalised pension plan'}</h3>
        <p className={styles.msmBody}>{'Please complete a quick questionnaire to receive your personalised pension plan. Your answers will allow us to find the best pension funds for you from the whole of the market.'}</p>
      </div>
    );
  };

  const GetStartedCTA = () => {
    return (
      <div
        className={
        classNames(
          styles.getStartedCtaContainer,
          {
            [styles.getStartedCtaContainerFirstSession]: isFirstSession,
          },
        )
      }
      >
        <Button
          onClick={() => dispatchSignUpCdsCTAClicked(isFirstSession)}
          label={'Get started'}
          to="/getting-to-know-you"
        />
      </div>
    );
  };

  const MainSection = () => {
    const videoPlayer = (
      <div className={styles.videoPlayerContainer}>
        <VideoPlayer
          url={'//videos.ctfassets.net/kh82idq12nwl/4DSGbW0ceNZfEaaYi1nUdk/ebf0cab0b7f8e6bc95d9894e98d14ed3/Explainer_animation__latest.mp4'}
          previewImage={ExplainerAnimationPoster}
          playIcon={<img style={{ position: 'relative', top: '-28px' }} src={PlayButtonIcon} alt="play button" />}
          muted
          withCustomControls={isDesktop}
          onStartEvent={() => dispatchAnimationVideoStarted(isFirstSession)}
          onEndedEvent={(
            timeElapsed,
          ) => dispatchAnimationVideoEnded(isFirstSession, timeElapsed)}
          onInterruptEvent={(
            timeElapsed,
          ) => dispatchAnimationVideoInterrupted(isFirstSession, timeElapsed)}
        />
      </div>
    );

    return (
      <section
        className={
          classNames(
            styles.mainSection,
            {
              [styles.mainSectionFirstSession]: isFirstSession,
            },
          )
        }
      >
        <h3 className={styles.mainSectionHeading}>{'How it works'}</h3>
        {videoPlayer}
        <TrustpilotAndFSCSWidget />
      </section>
    );
  };

  const DetailsAndContactUsSidebar = () => {
    const detailsSection = (
      <div className={styles.detailsContainer}>
        <div className={styles.numberIcon}>{'1'}</div>
        <div className={styles.stepWrapper}>
          <div className={styles.stepHeadingWrapper}>
            <h6 className={styles.stepHeading}>{'Get your pension plan'}</h6>
          </div>
          <p className={styles.stepContent}>{'Complete a short questionnaire to help us identify the best pension funds for your needs.'}</p>
        </div>
        <div className={styles.numberIcon}>{'2'}</div>
        <div className={styles.stepWrapper}>
          <div className={styles.stepHeadingWrapper}>
            <img className={styles.stepIcon} src={ThreeCircles} alt="Three circles" />
            <h6 className={styles.stepHeading}>{'Find & combine old pensions '}</h6>
          </div>
          <p className={styles.stepContent}>{'Tell us about your old pensions you’d like to combine into your plan, including ones you want us to track down.'}</p>
        </div>
        <h6 className={styles.stepHeading} id={styles.orSeparator}>{'OR'}</h6>
        <div className={styles.stepWrapper}>
          <div className={styles.stepHeadingWrapper}>
            <img className={styles.stepIcon} src={MoneyCirclePlus} alt="Money circle plus" />
            <h6 className={styles.stepHeading}>{'Start saving in your recommended pension plan'}</h6>
          </div>
          <p className={styles.stepContent}>{'Add money to your pension with either regular contributions via direct debit or a one-off payment.'}</p>
        </div>
      </div>
    );

    const contactUs = (
      <div className={styles.contactUsContainer}>
        <ContactUsContent
          heading={`Hello, I’m ${get(pensionExpert, 'firstName')}, your Adviser`}
          customMessage={(
            <div
              className={styles.contactUsInnerContent}
            >
              {'If you need any help completing the setup of your online account, finding and combining old pensions, or making contributions to your pension pot, please feel free to '}
              <ButtonLink
                label="message me"
                variant="primary"
                to="/inbox/new-message"
                font="inherit"
                onClick={() => dispatchMessageMeCTAClicked(isFirstSession)}
              />
              {'.'}
            </div>
              )}
          customerFirstName={customerFirstName}
          showButton={false}
          source={'Dashboard'}
        />
      </div>
    );

    return (
      <section className={styles.detailsAndContactUsSidebarContainer}>
        {detailsSection}
        {contactUs}
      </section>
    );
  };

  return (
    <React.Fragment>
      {showMsmTile && <MSMCopy />}
      <GetStartedCTA />
      <MainSection />
      {!isFirstSession && <DetailsAndContactUsSidebar />}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  customerFirstName: getFirstName(state),
  pensionExpert: getPensionExpert(state),
  isFirstSession: getUserProfileCreatedBeforeMillis(state, twoHoursInMilliseconds),
});

const mapDispatchToProps = {
  dispatchInitialSignupPageView: initialSignupPageViewAction,
  dispatchPostSignUpPageView: postSignUpPageView,
  dispatchSignUpCdsCTAClicked: signUpCdsCTAClicked,
  dispatchMessageMeCTAClicked: messageMeCTAClicked,
  dispatchAnimationVideoStarted: animationVideoStarted,
  dispatchAnimationVideoEnded: animationVideoEnded,
  dispatchAnimationVideoInterrupted: animationVideoInterrupted,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostSignUpAnimatedVideo);
