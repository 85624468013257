import React from 'react';
import PropTypes from 'prop-types';
import styles from './FieldLabel.css';

const FieldLabel = (props) => {
  const {
    label,
    required,
    disabled,
    largeHeadings,
  } = props;

  const wrapperClasses = [
    styles.wrapper,
    largeHeadings && styles.largeHeadings,
    disabled && styles.disabled,
  ].join(' ');

  return (
    <div className={wrapperClasses}>
      <div>
        {label}
        {required && <span>{'*'}</span>}
      </div>
    </div>
  );
};

FieldLabel.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

FieldLabel.defaultProps = {
  required: false,
  disabled: false,
  label: null,
};

export default FieldLabel;
