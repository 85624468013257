import get from 'lodash/get';

const getProfilePortfolio = (ivuCollection, portfolioReference, mappedFunds) => {
  const profilePortfolio = {
    planName: 'Mixed Plan',
    esg: undefined,
    icStatus: null,
  };

  const recentPortfolio = ivuCollection.find((p) => portfolioReference && portfolioReference === get(p, 'reference'));
  if (recentPortfolio) {
    profilePortfolio.planName = recentPortfolio.displayName;
    profilePortfolio.esg = recentPortfolio.environmentalSocialGovernance;
    profilePortfolio.icStatus = recentPortfolio.icStatus;
    return profilePortfolio;
  }
  const legacyPortfolio = ivuCollection.find((p) => get(p.funds, '0.isin') === get(mappedFunds, '0.isin'));
  if (mappedFunds.length === 1 && legacyPortfolio) {
    profilePortfolio.planName = legacyPortfolio.displayName;
    profilePortfolio.icStatus = legacyPortfolio.icStatus;
  }

  return profilePortfolio;
};

export default getProfilePortfolio;
